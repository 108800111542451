/**
 * Text Field Password Preview component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	Button,
	ClassList,
	IBaseProps,
	ICON_NAME} from "@hexio_io/hae-lib-components";
import { TEXT_FIELD_TYPE } from "../../Enums/TEXT_FIELD_TYPE";

/**
 * Text Field Password Preview props
 */
export interface ITextFieldPasswordPreviewProps extends IBaseProps {
	/** Type value */
	typeValue: TEXT_FIELD_TYPE;

	/** Set type value */
	setTypeValue: React.Dispatch<React.SetStateAction<TEXT_FIELD_TYPE>>;
}

/**
 * Text Field Password Preview component
 */
export const TextFieldPasswordPreview: React.FunctionComponent<ITextFieldPasswordPreviewProps> = (props) => {
	const { typeValue, setTypeValue, componentPath, componentMode } = props;

	const hidden = typeValue === TEXT_FIELD_TYPE.PASSWORD;

	// Event handler

	const _clickHandler = React.useCallback(() => {
		setTypeValue(hidden ? TEXT_FIELD_TYPE.DEFAULT : TEXT_FIELD_TYPE.PASSWORD);
	}, [ hidden ]);

	return (
		<Button
			labelIcon={{ source: hidden ? ICON_NAME.EYE : ICON_NAME.EYE_OFF, size: "SMALL" }}
			style="CLEAR"
			classList={new ClassList("cmp-field__password-preview")}
			componentPath={componentPath}
			componentMode={componentMode}
			onClick={_clickHandler}
		/>
	);
}
