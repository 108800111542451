/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const manifestTerms = {
	categories: {
		constants: {
			label: "manifest.categories:constants#label",
			description: "manifest.categories:constants#description",
		},
		services: {
			label: "manifest.categories:services#label",
			description: "manifest.categories:services#description",
		},
		system: {
			label: "manifest.categories:system#label",
			description: "manifest.categories:system#description",
		},
		visual: {
			label: "manifest.categories:visual#label",
			description: "manifest.categories:visual#description",
		}
	},
	spec: {
		label: "manifest#label",
		description: "manifest#description",
		// TODO: remove this in next major release
		blueprintsPath: {
			label: "manifest:blueprintsPath#label",
			description: "manifest:blueprintsPath#description"
		},
		application: {
			label: "manifest:application#label",
			description: "manifest:application#description",
			name: {
				label: "manifest:application.name#label",
				description: "manifest:application.name#description"
			}
		},
		ssr: {
			label: "manifest:ssr#label",
			description: "manifest:ssr#description",
			enabled: {
				label: "manifest:ssr.enabled#label",
				description: "manifest:ssr.enabled#description"
			}
		},
		session: {
			label: "manifest:session#label",
			description: "manifest:session#description",
			expiration: {
				label: "manifest:session.expiration#label",
				description: "manifest:session.expiration#description",
				cookie: {
					label: "manifest:session.expiration.cookie#label",
					description: "manifest:session.expiration.cookie#description"
				},
				storage: {
					label: "manifest:session.expiration.storage#label",
					description: "manifest:session.expiration.storage#description"
				},
				lastLogin: {
					label: "manifest:session.expiration.lastLogin#label",
					description: "manifest:session.expiration.lastLogin#description"
				}
			},
			storageType: {
				label: "manifest:session.sessionType#label",
				description: "manifest:session.sessionType#description"
			}
		},
		authentication: {
			label: "manifest:authentication#label",
			description: "manifest:authentication#description",
			providers: {
				label: "manifest:authentication.providers#label",
				description: "manifest:authentication.providers#description"
			},
			authType: {
				label: "manifest:authentication.authType#label",
				description: "manifest:authentication.authType#description",
				custom: {
					label: "manifest:authentication.authType.custom#label",
					description: "manifest:authentication.authType.custom#description",
					devAutoLogin: {
						label: "manifest:authentication.authType.custom.devAutoLogin#label",
						description: "manifest:authentication.authType.custom.devAutoLogin#description",
						action: {
							label: "manifest:authentication.authType.custom.devAutoLogin.action#label",
							description:
								"manifest:authentication.authType.custom.devAutoLogin.action#description"
						}
					},
					getAccount: {
						label: "manifest:authentication.authType.custom.getAccount#label",
						description: "manifest:authentication.authType.custom.getAccount#description",
						action: {
							label: "manifest:authentication.authType.custom.getAccount.action#label",
							description:
								"manifest:authentication.authType.custom.getAccount.action#description"
						},
						cache: {
							label: "manifest:authentication.authType.custom.getAccount.cache#label",
							description:
								"manifest:authentication.authType.custom.getAccount.cache#description",
							expiresInMilliseconds: {
								label: "manifest:authentication.authType.custom.getAccount.cache.expiresInMilliseconds#label",
								description:
									"manifest:authentication.authType.custom.getAccount.cache.expiresInMilliseconds#description"
							},
							maxReadCount: {
								label: "manifest:authentication.authType.custom.getAccount.cache.maxReadCount#label",
								description:
									"manifest:authentication.authType.custom.getAccount.cache.maxReadCount#description"
							}
						}
					}
				},
				adapptio: {
					label: "manifest:authentication.authType.adapptio#label",
					description: "manifest:authentication.authType.adapptio#description"
				}
			},
			opts: {
				label: "manifest:authentication.opts#label",
				description: "manifest:authentication.opts#description",
				redirect: {
					label: "manifest:authentication.opts.redirect#label",
					description: "manifest:authentication.opts.redirect#description",
					logoutUrl: {
						label: "manifest:authentication.opts.redirect.logoutUrl#label",
						description: "manifest:authentication.opts.redirect.logoutUrl#description"
					},
					loginFailedUrl: {
						label: "manifest:authentication.opts.redirect.loginFailedUrl#label",
						description: "manifest:authentication.opts.redirect.loginFailedUrl#description"
					},
					loginSucceedUrl: {
						label: "manifest:authentication.opts.redirect.loginSucceedUrl#label",
						description: "manifest:authentication.opts.redirect.loginSucceedUrl#description"
					}
				}
			}
		},
		routes: {
			label: "manifest:routes#label",
			description: "manifest:routes#description",
			value: {
				_label: "manifest:routes.value#label",
				description: "manifest:routes.value#description",
				label: {
					label: "manifest:routes.value.label#label",
					description: "manifest:routes.value.label#description"
				},
				path: {
					label: "manifest:routes.value.path#label",
					description: "manifest:routes.value.path#description"
				}
			}
		},
		secrets: {
			label: "manifest:secrets#label",
			description: "manifest:secrets#description",
			provider: {
				label: "manifest:secrets.provider#label",
				description: "manifest:secrets.provider#description"
			}
		},
		constants: {
			label: "manifest:secrets.constants#label",
			description: "manifest:secrets.constants#description",
			types: {
				constant: {
					label: "manifest:secrets.constants.constant#label",
					description: "manifest:secrets.constants.constant#description",
					value: {
						label: "manifest:secrets.constants.constant.value#label",
						description: "manifest:secrets.constants.constant.value#description"
					},
					public: {
						label: "manifest:secrets.constants.constant.public#label",
						description: "manifest:secrets.constants.constant.public#description"
					}
				},
				envvar: {
					label: "manifest:secrets.constants.envvar#label",
					description: "manifest:secrets.constants.envvar#description",
					value: {
						name: {
							label: "manifest:secrets.constants.envvar.value.name#label",
							description: "manifest:secrets.constants.envvar.value.name#description"
						},
						type: {
							label: "manifest:secrets.constants.envvar.value.type#label",
							description: "manifest:secrets.constants.envvar.value.type#description",
							value: {
								label: "manifest:secrets.constants.envvar.value.type.value#label",
								description: "manifest:secrets.constants.envvar.value.type.value#description"
							}
						}
					},
					default: {
						label: "manifest:secrets.constants.envvar.default#label",
						description: "manifest:secrets.constants.envvar.default#description"
					},
					public: {
						label: "manifest:secrets.constants.envvar.public#label",
						description: "manifest:secrets.constants.envvar.public#description"
					}
				}
			}
		},
		telemetry: {
			label: "manifest:telemetry#label",
			description: "manifest:telemetry#description",
			googleAnalytics: {
				label: "manifest:telemetry.googleAnalytics#label",
				description: "manifest:telemetry.googleAnalytics#description",
				trackingId: {
					label: "manifest:telemetry.googleAnalytics.trackingId#label",
					description: "manifest:telemetry.googleAnalytics.trackingId#description"
				}
			},
			hotJar: {
				label: "manifest:telemetry.hotJar#label",
				description: "manifest:telemetry.hotJar#description",
				siteId: {
					label: "manifest:telemetry.hotJar.siteId#label",
					description: "manifest:telemetry.hotJar.siteId#description"
				}
			}
		},
		externalServices: {
			label: "manifest:externalServices#label",
			description: "manifest:externalServices#description",
			intercom: {
				label: "manifest:externalServices.intercom#label",
				description: "manifest:externalServices.intercom#description",
				appId: {
					label: "manifest:externalServices.intercom.appId#label",
					description: "manifest:externalServices.intercom.appId#description"
				}
			}
		},
		theme: {
			label: "manifest:theme#label",
			description: "manifest:theme#description"
		},
		favicon: {
			label: "manifest:favicon#label",
			description: "manifest:favicon#description",
			type: {
				custom: {
					label: "manifest:favicon.type.custom#label",
					description: "manifest:favicon.type.custom#description"
				}
			},
			icon: {
				label: "manifest:favicon.icon#label",
				description: "manifest:favicon.icon#description"
			},
			pngIcons: {
				label: "manifest:favicon.pngIcons#label",
				description: "manifest:favicon.pngIcons#description",
				path: {
					label: "manifest:favicon.pngIcons.path#label",
					description: "manifest:favicon.pngIcons.path#description"
				},
				size: {
					label: "manifest:favicon.pngIcons.size#label",
					description: "manifest:favicon.pngIcons.size#description"
				}
			},
			appleTouchIcons: {
				label: "manifest:favicon.appleTouchIcons#label",
				description: "manifest:favicon.appleTouchIcons#description",
				path: {
					label: "manifest:favicon.appleTouchIcons.path#label",
					description: "manifest:favicon.appleTouchIcons.path#description"
				},
				size: {
					label: "manifest:favicon.appleTouchIcons.size#label",
					description: "manifest:favicon.appleTouchIcons.size#description"
				}
			}
		}
	},
	template: {
		label: "manifest:template#label",
		description: "manifest:template#description",
		header: {
			label: "manifest:template.header#label",
			description: "manifest:template.header#description"
		},
		footer: {
			label: "manifest:template.footer#label",
			description: "manifest:template.footer#description"
		}
	}
};
