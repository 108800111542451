/**
 * Field HAE component events
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { termsEditor } from "../../terms";

export const HAEComponentField_Events = {
	change: {
		...termsEditor.schemas.field.events.change
	}
};
