/**
 * Text Field HAE component definition
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, TComponentUpdateStateFunction, Type } from "@hexio_io/hae-lib-blueprint";

import {
	isBrowser,
	isValidObject,
	isValidValue
} from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";
import { IFieldSelection, initialSelection } from "./selection";
import { IFieldState } from "./state";
import { getFieldStateProps } from "./useField";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export interface HAEComponentTextField_State extends IFieldState {
	initialValue: string;
	value: string;
	selection: IFieldSelection;
}

export const definition = {

	...termsEditor.components.textField.component,

	name: "textField",

	category: "form",

	icon: "mdi/form-textbox",

	docUrl: "...",

	order: 10,

	resolve: (
		spec: any,
		state: HAEComponentTextField_State,
		updateStateAsync: TComponentUpdateStateFunction<HAEComponentTextField_State>
	): HAEComponentTextField_State => {
		const initialValue = isValidValue(spec.value) ? spec.value : state?.initialValue;
		const value = state?.initialValue === initialValue ?
			(isValidValue(state?.value) ? state.value : spec.value) :
			spec.value;

		const selection = isValidObject(state?.selection) ? {
			caret: state.selection.caret ?? initialSelection.caret,
			start: state.selection.start ?? initialSelection.start,
			end: state.selection.end ?? initialSelection.end,
			direction: state.selection.direction ?? initialSelection.direction
		} : initialSelection;

		function setValue(value: string) {
			updateStateAsync((prevState) => ({ ...prevState, value }));
		}

		function clearValue(initial = false) {
			updateStateAsync((prevState) => ({ ...prevState, value: !initial ? "" : initialValue }));
		}

		return {
			value,
			initialValue,
			...getFieldStateProps(value, initialValue, state, spec.validate),
			selection,
			setValue,
			clearValue
		};
	},

	getScopeData: (spec: any, state: HAEComponentTextField_State): any => {
		return {
			initialValue: spec.value,
			value: state.value,
			valid: state.valid,
			selection: state.selection,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec: any, state: HAEComponentTextField_State, props: any): any => {
		return Type.Object({
			props: {
				initialValue: Type.String({ ...termsEditor.schemas.textField.initialValue }),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.schemas.field.valid }),
				selection: Type.Object({
					...termsEditor.schemas.field.selection,
					props: {
						caret: Type.Integer({ ...termsEditor.schemas.field.selectionCaret }),
						start: Type.Integer({ ...termsEditor.schemas.field.selectionStart }),
						end: Type.Integer({ ...termsEditor.schemas.field.selectionEnd }),
						direction: Type.String({ ...termsEditor.schemas.field.selectionDirection })
					}
				}),
				setValue: Type.Method({
					...termsEditor.schemas.field.setValue,
					argRequiredCount: 1,
					argSchemas: [ BP.String({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.schemas.field.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}

};
