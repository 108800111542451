/**
 * Container Role enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum CONTAINER_ROLE {
	NONE = "none",
	FORM = "form",
	SECTION = "section",
	ARTICLE = "article",
	HEADER = "header",
	HEADER_BANNER = "header-banner",
	FOOTER = "footer",
	FOOTER_CONTENTINFO = "footer-contentinfo"
}

export type CONTAINER_ROLE_string = keyof typeof CONTAINER_ROLE;

export const CONTAINER_ROLE_default: CONTAINER_ROLE_string = "NONE";
