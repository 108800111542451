/**
 * QR Code component terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const qrCode = {
	component: {
		label: "component:qrCode#label",
		description: "component:qrCode#description"
	}
};
