/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { isBoolean, isNonEmptyObject, isString, isUndefined } from "@hexio_io/hae-lib-shared";
import { stringify as stringifyQs } from "qs";
import { ISerializableValue } from "../ISerializableValue";
import { IWebServerErrorBody } from "../WebServer/WebServerErrors";

/**
 * HTTP Methods
 */
export enum HTTP_METHOD {
	HEAD = "HEAD",
	GET = "GET",
	POST = "POST",
	PUT = "PUT",
	PATCH = "PATCH",
	DELETE = "DELETE",
	OPTIONS = "OPTIONS"
}

/**
 * HTTP Response statuses
 */
export enum HTTP_STATUS {
	OK = "OK",
	CREATED = "CREATED",
	NO_CONTENT = "NO_CONTENT",
	BAD_REQUEST = "BAD_REQUEST",
	UNAUTHORIZED = "UNAUTHROIZED",
	FORBIDDEN = "FORBIDDEN",
	NOT_FOUND = "NOT_FOUND",
	ERROR = "ERROR",
	_ABORTED = "ABORTED",
	_TIMEOUT = "TIMEOUT",
	_PARSE_ERROR = "PARSE_ERROR",
	//_UNSUPPORTED_CONTENT_TYPE = "UNSUPPORTED_CONTENT_TYPE",
	_REQUEST_FAILED = "REQUEST_FAILED"
}

/**
 * HTTP Ready states
 */
export enum HTTP_READY_STATE {
	UNSENT = "UNSENT",
	OPENED = "OPENED",
	HEADERS_RECEIVED = "HEADERS_RECEIVED",
	LOADING = "LOADING",
	DONE = "DONE"
}

export type TRequestBody = FormData | File | ISerializableValue;
export type TRequestQuery = ISerializableValue;

/**
 * HTTP Content types
 */
export enum HTTP_CONTENT_TYPE {
	JSON = "application/json",
	JSON_PROBLEM = "application/problem+json"
}

/**
 * HTTP Response interface
 */
export interface IHttpResponse<TResBody = never> {
	request: HttpRequest<TResBody>;
	status: HTTP_STATUS;
	body?: TResBody;
	responseText?: string;
}

/**
 * Request / responde headers
 */
export interface IHttpHeaders {
	[K: string]: string;
}

/**
 * HTTP Response class
 */
export class HttpResponse<TResBody> implements IHttpResponse<TResBody> {
	public constructor(
		public readonly request: HttpRequest<TResBody>,
		public readonly status: HTTP_STATUS,
		public readonly body?: TResBody,
		public readonly responseText?: string
	) {}
}

/**
 * HTTP Error class
 */
export class HttpError<TResBody> extends Error {

	public readonly request: HttpRequest<TResBody>;
	public readonly status: HTTP_STATUS;
	public readonly responseText?: string
	public readonly errorObject: IWebServerErrorBody;

	public readonly name: string;
	public readonly type: string;
	public readonly title: string;
	public readonly detail?: string;
	public readonly instance?: string;
	public readonly reqId?: string;
	public readonly traceId?: string;
	public readonly date: Date;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public readonly additionalProps: { [K: string]: any; };

	public constructor(
		status: HTTP_STATUS,
		request: HttpRequest<TResBody>,
		errorObject?: IWebServerErrorBody,
		responseText?: string,
		message?: string
	) {

		super(errorObject?.title || message || responseText || String(status));

		this.status = status;
		this.request = request;
		this.errorObject = errorObject;
		this.responseText = responseText;

		this.name = errorObject?.name || String(status);
		this.type = errorObject?.type || null;
		this.title = errorObject?.title || null;
		this.detail = errorObject?.detail || null;
		this.instance = errorObject?.instance || null;
		this.reqId = errorObject?.reqId || null;
		this.traceId = errorObject?.traceId || null;
		this.date = errorObject?.date || null;

		this.additionalProps = {};

		if (errorObject) {
			for (const k in errorObject) {
				if (!Object.keys(this).includes(k)) {
					this.additionalProps[k] = errorObject[k];
				}
			}
		}

	}

}

/**
 * HTTP Request class
 */
export class HttpRequest<TResBody> {
	/** XHR Request */
	private xhr: XMLHttpRequest = new XMLHttpRequest();

	/** HTTP method */
	private method: HTTP_METHOD;

	/** URL */
	private url: string;

	/** Query string */
	private queryString: string;

	/** Request body */
	private body: string | FormData | File;

	/** Request headers */
	private headers: IHttpHeaders = {};

	/** Response instance */
	public response: IHttpResponse<TResBody> = null;

	/** Default request timeout */
	public static DEFAULT_TIMEOUT = 10000;

	/**
	 * HTTP Request class constructor
	 *
	 * @param method Method of request
	 * @param url Url or relative path of request
	 * @param query Query of request
	 * @param body Body (data) to send
	 * @param headers Request headers
	 * @param timeout Request timeout
	 */
	public constructor(
		method: HTTP_METHOD,
		url: string,
		query?: TRequestQuery,
		body?: TRequestBody,
		headers?: IHttpHeaders,
		timeout?: number
	) {
		this.xhr.timeout = timeout || HttpRequest.DEFAULT_TIMEOUT;

		this.method = method;

		this.url = url;

		const queryStringValue = query ? stringifyQs(query) : "";

		this.queryString = queryStringValue !== "" ? "?" + queryStringValue : "";

		if (!isUndefined(body)) {
			this.body = (body instanceof FormData || body instanceof File) ? body : JSON.stringify(body);
		}

		if (isString(this.body)) {
			this.headers["Content-Type"] = HTTP_CONTENT_TYPE.JSON;
		}
		else if (this.body instanceof File) {
			this.headers["Content-Type"] = this.body.type;
		}

		if (isNonEmptyObject(headers)) {
			this.headers = { ...this.headers, ...headers };
		}
	}

	/**
	 * Parses status of XMLHttpRequest
	 *
	 * @param xhr Source request
	 */
	private static parseStatus(xhr: XMLHttpRequest): HTTP_STATUS {
		switch (xhr.status) {
			case 200:
				return HTTP_STATUS.OK;
			case 201:
				return HTTP_STATUS.CREATED;
			case 204:
				return HTTP_STATUS.NO_CONTENT;
			case 400:
				return HTTP_STATUS.BAD_REQUEST;
			case 401:
				return HTTP_STATUS.UNAUTHORIZED;
			case 403:
				return HTTP_STATUS.FORBIDDEN;
			case 404:
				return HTTP_STATUS.NOT_FOUND;
			case 0:
				return HTTP_STATUS._REQUEST_FAILED;
			default:
				return HTTP_STATUS.ERROR;
		}
	}

	/**
	 * Parses ready state of XMLHttpRequest
	 *
	 * @param xhr Source request
	 */
	private static parseReadyState(xhr: XMLHttpRequest): HTTP_READY_STATE {
		switch (xhr.readyState) {
			case 0:
				return HTTP_READY_STATE.UNSENT;
			case 1:
				return HTTP_READY_STATE.OPENED;
			case 2:
				return HTTP_READY_STATE.HEADERS_RECEIVED;
			case 3:
				return HTTP_READY_STATE.LOADING;
			case 4:
				return HTTP_READY_STATE.DONE;
		}
	}

	/**
	 * Sends request
	 */
	public send(): Promise<IHttpResponse<TResBody>> {
		if (this.isPending()) {
			throw new Error("Request has already been sent.");
		}

		return new Promise((resolve, reject) => {
			this.xhr.onreadystatechange = () => {
				if (HttpRequest.parseReadyState(this.xhr) === HTTP_READY_STATE.DONE) {
					const status = HttpRequest.parseStatus(this.xhr);
					const { responseText } = this.xhr;

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					let responseObject: any = undefined;

					if (
						status === HTTP_STATUS._ABORTED ||
						status === HTTP_STATUS._REQUEST_FAILED ||
						status === HTTP_STATUS._TIMEOUT
					) {
						reject(new HttpError(
							status,
							this,
							null,
							responseText,
							`Failed to process request: ${status}.`
						));

						return;
					}

					if (this.method !== HTTP_METHOD.HEAD) {
						const contentTypes = this.xhr.getResponseHeader("Content-Type");
						const contentType = contentTypes ? contentTypes.split(";")[0] : null;

						if (
							contentType === HTTP_CONTENT_TYPE.JSON ||
							contentType === HTTP_CONTENT_TYPE.JSON_PROBLEM
						) {
							try {
								responseObject = JSON.parse(responseText);
							}
							catch (error) {
								reject(new HttpError(
									HTTP_STATUS._PARSE_ERROR,
									this,
									null,
									responseText,
									"Failed to parse response body as JSON."
								));

								return;
							}
						}
					}

					switch (status) {
						case HTTP_STATUS.OK:
						case HTTP_STATUS.CREATED:
						case HTTP_STATUS.NO_CONTENT:
							this.response = new HttpResponse<TResBody>(
								this,
								status,
								responseObject,
								responseText
							);

							resolve(this.response);

							return;

						default:
							reject(new HttpError(
								status,
								this,
								responseObject,
								responseText
							));

							return;
					}
				}
			};

			this.xhr.ontimeout = () => {
				const status = HttpRequest.parseStatus(this.xhr);

				reject(new HttpError(
					status,
					this,
					null,
					null,
					"Request timed-out."
				));
			};

			this.xhr.onabort = () => {
				reject(new HttpError(
					HTTP_STATUS._ABORTED,
					this,
					null,
					null,
					"Request has been aborted."
				));
			};

			this.xhr.onerror = () => {
				// @todo if the same error
				console.log("onerror", HttpRequest.parseStatus(this.xhr));
			};

			this.xhr.open(this.method, this.url + this.queryString, true);

			Object.keys(this.headers).forEach((item) => {
				this.xhr.setRequestHeader(item, this.headers[item]);
			});

			this.xhr.send(this.body);
		});
	}

	/**
	 * Checks whether the request is still pending
	 */
	public isPending(): boolean {
		const readyState = HttpRequest.parseReadyState(this.xhr);

		return (
			readyState !== HTTP_READY_STATE.UNSENT &&
			readyState !== HTTP_READY_STATE.DONE
		);
	}

	/**
	 * Aborts the request
	 */
	public abort(): void {
		if (this.isPending()) {
			this.xhr.abort();
		}
	}

	/**
	 * On progress event handling
	 */
	public onUploadProgress(callback: ((value: number) => void)): void {
		const { upload } = this.xhr;

		if (!upload) {
			return;
		}

		upload.onloadstart = () => {
			callback(0);
		};

		upload.onprogress = (event: ProgressEvent<EventTarget>) => {
			if (event.lengthComputable) {
				callback(event.loaded / event.total);
			}
		};

		upload.onloadend = () => {
			callback(1);
		};
	}
}
