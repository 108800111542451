/**
 * Background Size enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum BACKGROUND_SIZE {
	CONTAIN = "contain",
	COVER = "cover"
}

export type BACKGROUND_SIZE_string = keyof typeof BACKGROUND_SIZE;

export const BACKGROUND_SIZE_default: BACKGROUND_SIZE_string = "CONTAIN";
