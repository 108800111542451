/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IIntegrationErrorDetails } from ".";
import { IntegrationError } from "./IntegrationError";

/**
 * Service Error
 */
export class ServiceError extends IntegrationError {

	public constructor(
		name: string,
		message: string,
		errorDetails?: IIntegrationErrorDetails,
		sourceError?: any
	) {
		super(name, message, errorDetails, sourceError);
	}

}

/**
 * Vault Error
 */
export class VaultError extends ServiceError { }
