/**
 * Hexio App Engine Shared library.
 *
 * @package hae-lib-shared
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const capitalizeFirstLetter = (string: string) => {
	if (string?.length) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return '';
}
