/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP } from "@hexio_io/hae-lib-blueprint";
import { createBlueprintSchema } from "./BlueprintBase";
import { DOC_TYPES } from "./DocTypes";

export const BlueprintDirectory = createBlueprintSchema(DOC_TYPES.DICTIONARY_V1, BP.Void({}));

export type TBlueprintDirectorySchema = typeof BlueprintDirectory;