/**
 * Hexio App Engine Core
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	HttpClient,
	IActionParams,
	IApiResponse,
	IApiSessionResponse,
	IViewApiResponse,
	TActionResult
} from "@hexio_io/hae-lib-core";

/**
 * Client to HAE App API
 */
export class ApiApp {

	private httpClient: HttpClient;

	/**
	 * Constructs API client
	 *
	 * @param serverUrl Base server URL
	 */
	public constructor(serverUrl: string) {

		this.httpClient = new HttpClient(`${serverUrl}/api/app/v1`);

	}

	/**
	 * Returns view by ID
	 *
	 * @param viewId View ID
	 */
	public async getView(viewId: string): Promise<IViewApiResponse> {
		return (
			await this.httpClient.get<IApiResponse<IViewApiResponse>>(`/view/${encodeURIComponent(viewId)}`)
		).body.data;
	}

	/**
	 * Invokes an action
	 *
	 * @param actionId Action ID
	 * @param params Action params
	 * @param opts Options
	 */
	public async invokeAction(actionId: string, params: IActionParams, opts: {
		withTypeDescriptor?: boolean,
		debug?: boolean
	}): Promise<TActionResult> {
		return (
			await this.httpClient.post<IApiResponse<TActionResult>>(`/action/invoke/${encodeURIComponent(actionId)}`, opts, params)
		).body.data;
	}

	/**
	 * Returns session
	 */
	public async getSession(): Promise<IApiSessionResponse> {
		return (
			await this.httpClient.get<IApiResponse<IApiSessionResponse>>(`/session`)
		).body.data;
	}

}