/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Default values for application config
 */
export const APP = {
	ASSETS: {
		MAX_UPLOAD_FILE_SIZE: 100 * 1000 * 1000 // 100 Mb
	},
	RESOURCES: {
		BLUEPRINT_VIEWS_LIMIT: -1,
		BLUEPRINT_INTEGRATIONS_LIMIT: -1,
		BLUEPRINT_ACTIONS_LIMIT: -1
	}
};