/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE, IBlueprintIDTList, TBlueprintIDTNode, TBlueprintIDTNodePath } from "../../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../../Schema/IBlueprintSchema";
import { IModelNode, IModelNodeCompileResult, IModelNodeInfo, MODEL_CHANGE_TYPE } from "../../Schema/IModelNode";
import { ModelNodeManipulationError } from "../../Schema/ModelNodeManipulationError";
import {
	applyRuntimeValidators,
	assignParentToArrItems,
	cloneModelNode,
	compileRuntimeValidators,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateDefaultValue,
	validateParsedValueAndReport
} from "../../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { IScope } from "../../Shared/Scope";
import { DesignContext } from "../../Context/DesignContext";
import { RuntimeContext } from "../../Context/RuntimeContext";
import { TModelPath } from "../../Shared/TModelPath";
import { IValidatorArrayOpts, ValidatorArray } from "../../validators/ValidatorArray";
import { IBlueprintSchemaValidationError, IBlueprintSchemaValidatorHandler } from "../../Validator/IBlueprintSchemaValidator";
import { applyCodeArg, inlineValue } from "../../Context/CompileUtil";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { TypeDescArray } from "../../Shared/ITypeDescriptor";
import { CMPL_ITEM_KIND } from "../../Shared/ICompletionItem";
import { isNil } from "@hexio_io/hae-lib-shared";

/**
 * Schema spec
 */
export type ISchemaConstArraySpec<TItemsSchema extends TGenericBlueprintSchema> = Array<
	TGetBlueprintSchemaSpec<TItemsSchema>
>;

/**
 * Schema model
 */
export interface ISchemaConstArrayModel<TItemsSchema extends TGenericBlueprintSchema>
	extends IModelNode<ISchemaConstArray<TItemsSchema>> {
	items: Array<TGetBlueprintSchemaModel<TItemsSchema>>;
}

/**
 * Schema options
 */
export interface ISchemaConstArrayOpts<TItemsSchema extends TGenericBlueprintSchema>
	extends IBlueprintSchemaOpts {
	/** Schema representing each item */
	items: TItemsSchema;
	/** Default value for a new node */
	default?: Array<TGetBlueprintSchemaDefault<TItemsSchema>>;
	/** Base validation constraints */
	constraints?: IValidatorArrayOpts;
	/** Custom validators */
	validators?: IBlueprintSchemaValidatorHandler<ISchemaConstArraySpec<TItemsSchema>>[];
	/** Fallback value to return when validation fails */
	fallbackValue?: ISchemaConstArraySpec<TItemsSchema>;
	/** If and how to display child nodes in editor outline */
	outlineOptions?: {
		displayChildren?: boolean;
		allowAddElement?: boolean;
	};
	/** Function to return human friendly item label */
	getElementModelNodeInfo?: (
		modelNode: TGetBlueprintSchemaModel<TItemsSchema>,
		index: number
	) => IModelNodeInfo;
}

/**
 * Default value
 */
export type TSchemaConstArrayDefault<TItemsSchema extends TGenericBlueprintSchema> = Array<
	TGetBlueprintSchemaDefault<TItemsSchema>
>;

export interface ISchemaConstArray<TItemsSchema extends TGenericBlueprintSchema>
	extends IBlueprintSchema<
		ISchemaConstArrayOpts<TItemsSchema>,
		ISchemaConstArrayModel<TItemsSchema>,
		ISchemaConstArraySpec<TItemsSchema>,
		TSchemaConstArrayDefault<TItemsSchema>
	> {
	addElement: (
		modelNode: ISchemaConstArrayModel<TItemsSchema>,
		itemDefault?: TGetBlueprintSchemaDefault<TItemsSchema>,
		notify?: boolean
	) => TGetBlueprintSchemaModel<TItemsSchema>;
	addElementModel: (
		modelNode: ISchemaConstArrayModel<TItemsSchema>,
		valueModel: TGetBlueprintSchemaModel<TItemsSchema>,
		index?: number,
		notify?: boolean
	) => TGetBlueprintSchemaModel<TItemsSchema>;
	removeElement: (modelNode: ISchemaConstArrayModel<TItemsSchema>, index: number, notify?: boolean) => void;
	moveElement: (
		modelNode: ISchemaConstArrayModel<TItemsSchema>,
		currentIndex: number,
		newIndex: number,
		notify?: boolean
	) => void;
	getElementModelNodeInfo: (
		modelNode: ISchemaConstArrayModel<TItemsSchema>,
		index: number
	) => IModelNodeInfo;
}

export type TGetSchemaConstArrayItemSchema<TConstArraySchema> = TConstArraySchema extends ISchemaConstArray<
	infer TItemSchema
>
	? TItemSchema
	: never;

/**
 * Schema: Array
 *
 * @param opts Schema options
 */
export function SchemaConstArray<TItemsSchema extends TGenericBlueprintSchema>(
	opts: ISchemaConstArrayOpts<TItemsSchema>
): ISchemaConstArray<TItemsSchema> {
	type TItemModel = TGetBlueprintSchemaModel<TItemsSchema>;
	type TItemSpec = TGetBlueprintSchemaSpec<TItemsSchema>;
	type TModel = ISchemaConstArrayModel<TItemsSchema>;
	type TSpec = ISchemaConstArraySpec<TItemsSchema>;

	const defaultValidator = ValidatorArray(opts.constraints || {});
	const validators: IBlueprintSchemaValidatorHandler<TSpec>[] = opts.validators || [];

	const errSeverity =
		opts.fallbackValue !== undefined ? DOC_ERROR_SEVERITY.WARNING : DOC_ERROR_SEVERITY.ERROR;

	const schema = createEmptySchema<ISchemaConstArray<TItemsSchema>>("constArray", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToArrItems(srcModel, srcModel.items);
	};

	const createModel = (
		dCtx: DesignContext,
		items: TGetBlueprintSchemaModel<TItemsSchema>[],
		parent: TBlueprintSchemaParentNode,
		validationErrors: IBlueprintSchemaValidationError[]
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, validationErrors, {
			items: items
		});

		return assignParentToChildrenOf(modelNode);
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		// Only default array validator can be used because we are not able to get final child values here
		const errors = validateDefaultValue(schema, [ defaultValidator ], defaultValue);

		const _items =
			defaultValue instanceof Array
				? defaultValue.map((item) => opts.items.createDefault(dCtx, null, item) as TItemModel)
				: [];

		return createModel(dCtx, _items, parent, errors);
	};

	schema.clone = (dCtx, modelNode: TModel, parent): TModel => {
		const clonedItems = modelNode.items.map((item) => opts.items.clone(dCtx, item, null) as TItemModel);

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			items: clonedItems
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode: TModel): void => {
		modelNode.items.forEach((item) => opts.items.destroy(item));
		modelNode.items = [];
		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx: DesignContext, idtNode: TBlueprintIDTNode, parent): TModel => {
		// Check null
		if (
			!idtNode ||
			(idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL)
		) {
			return schema.createDefault(dCtx, parent);
		}

		if (idtNode.type !== BP_IDT_TYPE.LIST) {
			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.LIST_NOT_LIST,
					message: "Expecting a list",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		const itemSchema = opts.items;

		const items = idtNode.items
			.map((item) => {
				if (idtNode.parseInfo && itemSchema.provideCompletion) {
					itemSchema.provideCompletion(
						dCtx,
						idtNode.parseInfo.loc,
						idtNode.parseInfo.loc.range.start.col + 2,
						item
					);
				}

				if (item) {
					return itemSchema.parse(dCtx, item, null) as TItemModel;
				} else {
					return null;
				}
			})
			.filter((item) => item !== null);

		const errors = validateParsedValueAndReport(dCtx, idtNode, [ defaultValidator ], items);

		return createModel(dCtx, items, parent, errors);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {
		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => [
			{
				kind: CMPL_ITEM_KIND.Text,
				label: "(list element)",
				insertText: `- `
			}
		]);
	};

	schema.serialize = (modelNode: TModel, path: TBlueprintIDTNodePath): TBlueprintIDTNode => {
		let items = [];

		const schema = opts.items;
		items = modelNode.items.map((item, index) => schema.serialize(item, path.concat([ `[${index}]` ])));

		return {
			type: BP_IDT_TYPE.LIST,
			path,
			items
		} as IBlueprintIDTList;
	};

	schema.render = (
		rCtx: RuntimeContext,
		modelNode: TModel,
		path: TModelPath,
		scope: IScope,
		prevSpec?: TSpec
	): TSpec => {
		modelNode.lastScopeFromRender = scope;

		const itemSchema = opts.items;
		const value = modelNode.items.map(
			(item, index) =>
				itemSchema.render(
					rCtx,
					item,
					path.concat([ index ]),
					scope,
					prevSpec && Array.isArray(prevSpec) ? prevSpec[index] : undefined
				) as TItemSpec
		);

		const isValid = applyRuntimeValidators<TSpec>(
			rCtx,
			path,
			modelNode.nodeId,
			[ defaultValidator ].concat(validators),
			errSeverity,
			value
		);

		if (isValid) {
			return value;
		} else {
			return opts.fallbackValue || [];
		}
	};

	schema.compileRender = (cCtx, modelNode, path): IModelNodeCompileResult => {
		// Pre-validate
		if (!modelNode.isValid) {
			cCtx.logValidationErrors(path, modelNode.nodeId, errSeverity, modelNode.validationErrors);

			return {
				code: inlineValue(opts.fallbackValue || []),
				isScoped: false
			};
		}

		let isScoped = false;
		let compiledItems = [];

		const itemSchema = opts.items;

		compiledItems = modelNode.items.map((item, index) => {
			const compiledElement = itemSchema.compileRender(cCtx, item, path.concat([ index ]));
			isScoped = isScoped || compiledElement.isScoped;

			return applyCodeArg(compiledElement, `pv?pv[${index}]:undefined`, `pt.concat([${index}])`);
		});

		const code = compiledItems.join(",");

		const additionalValidators = compileRuntimeValidators(
			cCtx,
			path,
			modelNode.nodeId,
			validators,
			errSeverity
		);

		if (additionalValidators !== null) {
			return {
				isScoped: true,
				// eslint-disable-next-line max-len
				code: `(s,pv,pt)=>{const _v=[${code}];const _iv=${additionalValidators}(_v,pt);return _iv?_v:${inlineValue(
					opts.fallbackValue || []
				)}}`
			};
		} else {
			return {
				isScoped,
				code: isScoped ? `(s,pv,pt)=>([${code}])` : `[${code}]`
			};
		}
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		// Validate base
		const selfValid = applyRuntimeValidators<TSpec>(
			rCtx,
			path,
			modelNodeId,
			[ defaultValidator ].concat(validators),
			errSeverity,
			value
		);
		let childValid = true;

		// Call child validators
		if (validateChildren && value instanceof Array) {
			childValid = value
				.map((item, index) =>
					opts.items.validate(rCtx, path.concat([ index ]), modelNodeId, item, true)
				)
				.reduce((stack, current) => stack && current, childValid);
		}

		return selfValid && childValid;
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		const expressions = [];

		const selfValidator = compileRuntimeValidators(
			cCtx,
			path,
			modelNodeId,
			[ defaultValidator ].concat(validators),
			errSeverity
		);

		if (selfValidator !== null) {
			expressions.push(`(${selfValidator})(v,pt)`);
		}

		if (validateChildren) {
			const itemValidator = opts.items.compileValidate(
				cCtx,
				path.concat([ "$item" ]),
				modelNodeId,
				validateChildren
			);

			if (itemValidator) {
				expressions.push(
					`(v instanceof Array?v.map((x,i)=>(${itemValidator})(x,pt.concat([i]))).reduce((s,c)=>s&&c,true):true)`
				);
			}
		}

		if (expressions.length === 0) {
			return null;
		} else {
			return cCtx.addGlobalValue(`(v,pt)=>${expressions.join("&&")}`);
		}
	};

	schema.addElement = (
		modelNode: TModel,
		itemDefault?: TGetBlueprintSchemaDefault<TItemsSchema>,
		notify?: boolean
	) => {
		const element = opts.items.createDefault(modelNode.ctx, modelNode, itemDefault) as TItemModel;
		modelNode.items.push(element);

		// Only default array validator can be used because we are not able to get final child values here
		modelNode.validationErrors = defaultValidator.validate(modelNode.items);
		modelNode.isValid = modelNode.validationErrors.length === 0 ? true : false;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return element;
	};

	schema.addElementModel = (
		modelNode: TModel,
		valueModel: TGetBlueprintSchemaModel<TItemsSchema>,
		index?: number,
		notify?: boolean
	) => {
		if (isNil(index) || index > modelNode.items.length) {
			modelNode.items.push(valueModel);
		} else {
			modelNode.items.splice(index, 0, valueModel);
		}

		valueModel.schema.assignParent(valueModel, modelNode, false);

		// Only default array validator can be used because we are not able to get final child values here
		modelNode.validationErrors = defaultValidator.validate(modelNode.items);
		modelNode.isValid = modelNode.validationErrors.length === 0 ? true : false;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return valueModel;
	};

	schema.removeElement = (modelNode: TModel, index: number, notify?: boolean) => {
		if (index < 0 || index >= modelNode.items.length) {
			throw new ModelNodeManipulationError(schema.name, schema.opts, `index ${index} out of range`);
		}

		opts.items.destroy(modelNode.items[index]);
		modelNode.items.splice(index, 1);

		// Only default array validator can be used because we are not able to get final child values here
		modelNode.validationErrors = defaultValidator.validate(modelNode.items);
		modelNode.isValid = modelNode.validationErrors.length === 0 ? true : false;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	schema.moveElement = (
		modelNode: TModel,
		currentIndex: number,
		newIndex: number,
		notify?: boolean
	): void => {
		if (currentIndex < 0 || currentIndex >= modelNode.items.length) {
			throw new ModelNodeManipulationError(
				schema.name,
				schema.opts,
				`currentIndex ${currentIndex} out of range`
			);
		}

		if (newIndex < 0 || newIndex >= modelNode.items.length) {
			throw new ModelNodeManipulationError(
				schema.name,
				schema.opts,
				`newIndex ${newIndex} out of range`
			);
		}

		const element = modelNode.items.splice(currentIndex, 1);
		modelNode.items.splice(newIndex, 0, element[0]);

		// Only default array validator can be used because we are not able to get final child values here
		modelNode.validationErrors = defaultValidator.validate(modelNode.items);
		modelNode.isValid = modelNode.validationErrors.length === 0 ? true : false;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaConstArray", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		return TypeDescArray({
			items: modelNode
				? modelNode.items.map((item) => opts.items.getTypeDescriptor(item))
				: [ opts.items.getTypeDescriptor() ],
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.getChildNodes = (modelNode) => {
		return modelNode.items.map((item, index) => ({
			key: "items." + index,
			node: item
		}));
	};

	schema.getElementModelNodeInfo = (modelNode, index) => {
		const item = modelNode.items[index];

		if (!item) {
			throw new Error("Item index out of range.");
		}

		if (opts.getElementModelNodeInfo) {
			return opts.getElementModelNodeInfo(item, index);
		} else {
			return {
				icon: "mdi/arrow-right",
				label: "#" + (index + 1)
			};
		}
	};

	return schema;
}
