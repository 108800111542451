/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE, IBlueprintIDTScalar } from "../../IDT/ISchemaIDT";
import { IBlueprintSchemaOpts, IBlueprintSchemaScalar, TBlueprintSchemaParentNode } from "../../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../../Schema/IModelNode";
import {
	applyRuntimeValidators,
	cloneModelNode,
	compileRuntimeValidators,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateDefaultValue,
	validateParsedValueAndReport,
	validateValueAndUpdateModel
} from "../../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { DesignContext } from "../../Context/DesignContext";
import { IBlueprintSchemaValidationError, IBlueprintSchemaValidatorHandler } from "../../Validator/IBlueprintSchemaValidator";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { TypeDescString } from "../../Shared/ITypeDescriptor";
import { escapeString } from "../../Context/CompileUtil";
import { IValidatorSecretOpts, ValidatorSecret } from "../../validators/ValidatorSecret";
import { ISecretsResolver } from "../../Resolvers";
import { uuid } from "@hexio_io/hae-lib-shared";

/**
 * Schema value type
 */
type TSpecType = string;

/**
 * Schema options
 */
export interface ISchemaConstSecretOpts extends IBlueprintSchemaOpts {
	/** Base validation constraints */
	constraints?: IValidatorSecretOpts;
	/** Custom validators */
	validators?: IBlueprintSchemaValidatorHandler<TSpecType>[];
	/** Fallback value to return when validation fails */
	fallbackValue?: TSpecType;
	/** If specified the env parameter for resolver will be set from configured scope's metadata property. */
	setEnvFromScopeMetaProp?: string;

	multiline?: boolean;
}

/**
 * Schema type
 */
export type ISchemaConstSecret = IBlueprintSchemaScalar<ISchemaConstSecretOpts, ISchemaConstSecretModel, TSpecType>;

/**
 * Schema model
 */
export interface ISchemaConstSecretModel extends IModelNode<ISchemaConstSecret> {
	value: TSpecType;
}

/**
 * Schema: Secret scalar constant
 *
 * @param opts Schema options
 */
export function SchemaConstSecret(opts: ISchemaConstSecretOpts): ISchemaConstSecret {

	const validators: IBlueprintSchemaValidatorHandler<TSpecType>[] = [
		ValidatorSecret(opts.constraints || {})
	].concat(opts.validators || []);

	const errSeverity = opts.fallbackValue !== undefined ? DOC_ERROR_SEVERITY.WARNING : DOC_ERROR_SEVERITY.ERROR;

	const schema = createEmptySchema<ISchemaConstSecret>("constSecret", opts);

	const createModel = (dCtx: DesignContext, value: TSpecType, parent: TBlueprintSchemaParentNode,
		validationErrors: IBlueprintSchemaValidationError[]) => {

		const _value = value !== null && value !== undefined ? value : uuid();

		return createModelNode(schema, dCtx, parent, validationErrors, {
			value: _value
		});
	};

	schema.createDefault = (dCtx, parent, defaultValue?) => {

		const value = defaultValue !== null && defaultValue !== undefined ? defaultValue : null;
		const errors = validateDefaultValue(schema, validators, value);

		return createModel(
			dCtx,
			value,
			parent,
			errors
		);

	}

	schema.clone = (dCtx, modelNode, parent) => {
		return cloneModelNode(dCtx, modelNode, parent, {
			value: modelNode.value
		});
	};

	schema.destroy = (modelNode) => {

		modelNode.value = undefined;
		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent) => {

		// Check null
		if (!idtNode || (idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL)) {
			return schema.createDefault(dCtx, parent);
		}

		if (
			idtNode.type !== BP_IDT_TYPE.SCALAR || (
				idtNode.type == BP_IDT_TYPE.SCALAR &&
				idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.STRING &&
				idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.NULL
			) || (idtNode.value !== null && typeof idtNode.value !== "string")
		) {
			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.STR_NOT_STRING,
					message: "Expecting an string",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		const errors = validateParsedValueAndReport(dCtx, idtNode, validators, idtNode.value as TSpecType);

		return createModel(dCtx, idtNode.value as TSpecType, parent, errors);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			return null;
		});

	};

	schema.serialize = (modelNode, path) => {

		if (modelNode.value !== null) {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.STRING,
				path: path,
				value: modelNode.value
			} as IBlueprintIDTScalar;

		} else {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.NULL,
				path: path,
				value: null
			} as IBlueprintIDTScalar;

		}

	};

	schema.render = (rCtx, modelNode, path, scope) => {

		modelNode.lastScopeFromRender = scope;

		const resolver = rCtx.getResolver<ISecretsResolver>("secret");

		if (resolver) {
			const env = opts.setEnvFromScopeMetaProp
				? scope.metaData?.[opts.setEnvFromScopeMetaProp] as string
				: undefined;

			return resolver.getSecret(modelNode.value, env);
		} else {
			return null;
		}

	};

	schema.compileRender = (cCtx, modelNode) => {

		let envCode: string;

		if (opts.setEnvFromScopeMetaProp) {
			// eslint-disable-next-line max-len
			envCode = `,s.metaData?s.metaData["${escapeString(opts.setEnvFromScopeMetaProp)}"]:undefined`;
		} else {
			envCode = ``;
		}

		return {
			code:
				modelNode.value === null ?
					`(()=>null)()` :
					[
						`(()=>{`,
						`const _r=rCtx.getResolver("secret");`,
						// eslint-disable-next-line max-len
						`if(_r){return _r.getSecret("${escapeString(modelNode.value)}"${envCode})}else{console.warn("Secret resolver is not available.");return null}`,
						`})()`
					].join("")
			,
			isScoped: false
		}

	};

	schema.validate = (rCtx, path, modelNodeId, value) => {
		return applyRuntimeValidators(rCtx, path, modelNodeId, validators, errSeverity, value);
	};

	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileRuntimeValidators(cCtx, path, modelNodeId, validators, errSeverity);
	};

	schema.castSpec = (_rCtx, _path, _modelNodeId, value) => {

		if (typeof value !== "string") {
			return null;
		}

		return value;

	};

	schema.compileCastSpec = () => {
		return `(v,pt) => typeof v === "string" ? v : null`
	};

	schema.setValue = (modelNode, value, notify) => {

		validateValueAndUpdateModel(modelNode, validators, value);
		modelNode.value = value;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}

	};

	schema.getValue = (modelNode) => {
		return modelNode.value;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaConstSecret", [opts]);
	};

	schema.getTypeDescriptor = () => {
		return TypeDescString({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = () => {
		return [];
	}

	return schema;

}
