/**
 * Hexio App Engine Shared Library
 *
 * @package hae-lib-shared
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

// Check if Node.js (or other browser compatible environment)
// const isNode = new Function("try {return this===global;}catch(e){return false;}");

// Returns get high resolution time function based on environment
// Is in "eval" block to avoid webpack/parcel to parse Node's dependencies 🤦‍♂️
const resolvePerfFn = new Function(`
	try {
		if (process.hrtime) { // Is node
			return () => {
				const hrTime = process.hrtime();
				return (hrTime[0] * 1000000000 + hrTime[1]) / 1000000;
			};
		} else {
			return () => window.performance.now();
		}
	}catch(e){
		return () => window.performance.now();
	}
`);

let perfTimeFn: () => number = undefined;

/**
 * Returns performance time in ms
 */
export function getPerfTime(): number {

	if (perfTimeFn === undefined) {
		perfTimeFn = resolvePerfFn();
	}

	return perfTimeFn();

}

/**
 * Profiler Flamegraph Node
 */
export interface IProfilerFlameGraphNode<TNodeMetaData> {
	name: string;
	value: number;
	children: IProfilerFlameGraphNode<TNodeMetaData>[];
	metaData: TNodeMetaData;
	__start: number;
	__parent: IProfilerFlameGraphNode<TNodeMetaData>;
}

/**
 * Profile custom flamegraph node formatter
 */
export type TProfilerFormatter<TNodeMetaData> = (node: IProfilerFlameGraphNode<TNodeMetaData>) => string;

/**
 * Profile options
 */
export interface IProfilerOpts<TNodeMetaData> {
	formatter?: TProfilerFormatter<TNodeMetaData>;
}

/**
 * Profiler class
 */
export class Profiler<TNodeMetaData = null> {

	private formatter: TProfilerFormatter<TNodeMetaData>;

	private flameGraphRoot: IProfilerFlameGraphNode<TNodeMetaData> = null;
	private flameGraphCurrent: IProfilerFlameGraphNode<TNodeMetaData> = null;

	private static singletonInstances: { [K: string]: Profiler<unknown> } = {};

	public constructor(opts: IProfilerOpts<TNodeMetaData>) {

		this.formatter = opts.formatter;

	}

	/**
	 * Returns a singleton profiler instance
	 *
	 * @param name Instance name
	 * @param opts Create options (if createIfNotExists is enabled)
	 * @param createIfNotExists Create a new instance if not exists
	 */
	public static getInstance<TNodeMetaData = null>(
		name: string,
		opts?: IProfilerOpts<TNodeMetaData>,
		createIfNotExists?: boolean
	): Profiler<TNodeMetaData> {

		if (Profiler.singletonInstances[name]) {
			return Profiler.singletonInstances[name] as Profiler<TNodeMetaData>;
		} else if (createIfNotExists !== false) {
			return Profiler.singletonInstances[name] = new Profiler<TNodeMetaData>(opts || {});
		} else {
			return null;
		}

	}

	/**
	 * Starts the profiling
	 *
	 * @param nodeName Graph node name
	 */
	public start(nodeName: string, metaData: TNodeMetaData = null): void {

		const graphNode: IProfilerFlameGraphNode<TNodeMetaData> = {
			name: nodeName,
			value: null,
			children: [],
			metaData: metaData,
			__start: getPerfTime(),
			__parent: this.flameGraphCurrent || null
		};

		if (this.flameGraphCurrent !== null) {
			this.flameGraphCurrent.children.push(graphNode);
		} else {
			this.flameGraphRoot = graphNode;
		}

		this.flameGraphCurrent = graphNode;

	}

	/**
	 * Stops the current node profiling
	 */
	public stop(): void {

		const now = getPerfTime();

		if (this.flameGraphCurrent === null) {
			throw new Error("Profiler not started.");
		}

		this.flameGraphCurrent.value = now - this.flameGraphCurrent.__start;

		if (this.formatter) {
			this.flameGraphCurrent.name = this.formatter(this.flameGraphCurrent);
		}

		this.flameGraphCurrent = this.flameGraphCurrent.__parent;

	}

	/**
	 * Returns flamegraph
	 */
	public getFlameGraph(): IProfilerFlameGraphNode<TNodeMetaData> {

		return this.flameGraphRoot;

	}

	/**
	 * Resets the profiler
	 */
	public reset(): void {

		this.flameGraphRoot = null;
		this.flameGraphCurrent = null;

	}

}