/**
 * Hexio App Engine Core
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";

import {
	DependencyContainer,
	IAppClient,
	IAppClientDependencies,
	Main,
	registerCoreDataSources,
	TAppClientRegisterFunction,
	TAppTranslationsRegisterFunction,
	TranslationManager
} from "@hexio_io/hae-lib-core";
import { isProdBuild, ItemRegistry, IViewParamsSpec } from "@hexio_io/hae-lib-shared";
import { ClientRenderContext } from "./lib/ClientRenderContext";
import { ErrorTracker, HAE_RENDER_MODE } from "@hexio_io/hae-lib-components";
import { IAppState } from "../shared/IAppState";
import { ApiApp } from "./lib/ApiApp";
import { ViewRepository } from "./lib/ViewRepository";
import { IAppClientConfig } from "../shared/IAppClientConfig";
import { ActionRepository } from "./lib/ActionRepository";
import { RoutingManager } from "./lib/RoutingManager";
import { SessionProvider } from "./lib/SessionProvider";

/**
 * Add globals typing
 */
declare global {
	interface Window {
		APP_CONFIG: IAppClientConfig;
		APP_STATE: IAppState;
		APP_SSR_RENDERED: boolean;
		APP_SSR_ERRORS: boolean;
		APP_DEBUG: boolean;
	}
}

/**
 * Local app instance dependencies
 */
interface IAppClientInstanceDependencies extends IAppClientDependencies {
	apiApp: ApiApp;
	viewRepository: ViewRepository;
	actionRepository: ActionRepository;
	routingManager: RoutingManager;
	sessionProvider: SessionProvider;
}

/**
 * Application Client
 */
export class AppClient extends DependencyContainer<IAppClientInstanceDependencies> implements IAppClient {
	/** App Client Config */
	private config: IAppClientConfig;

	/** Render context */
	private renderCtx: ClientRenderContext;

	/**
	 * Application constructor
	 */
	public constructor() {
		super();

		this.config = window.APP_CONFIG;

		const translationManager = new TranslationManager({});
		translationManager.importDomainTranslations("app", this.config.appTranslations);

		this.register("apiApp", new ApiApp(this.config.serverUrl));

		this.register("componentRegistry", new ItemRegistry());
		this.register("dataSourceRegistry", new ItemRegistry());
		this.register("functionRegistry", new ItemRegistry());
		this.register("translationManager", translationManager);

		this.register("viewRepository", new ViewRepository(this.get("apiApp")));

		this.register(
			"actionRepository",
			new ActionRepository(this.get("apiApp"), {
				actionOpts: {
					debug: false,
					withTypeDescriptor: false
				}
			})
		);

		this.register("routingManager", new RoutingManager(this.config.routesPathMap));

		this.register(
			"sessionProvider",
			new SessionProvider(this.get("apiApp"), {
				reloadIntervalMs: 30000
			})
		);

		registerCoreDataSources(this.get("dataSourceRegistry"));
	}

	/**
	 * Registers extension
	 *
	 * @param registerFunction Register function
	 */
	public useExtension(registerFunction: TAppClientRegisterFunction): void {
		registerFunction(this);
	}

	/**
	 * Registers translations
	 *
	 * @param registerFunction Register function
	 */
	public useTranslations(registerFunction: TAppTranslationsRegisterFunction): void {
		registerFunction(this.get("translationManager"));
	}

	/**
	 * Initializes application
	 */
	public async init(): Promise<void> {
		// init editor error tracker
		new ErrorTracker({
			dsn: "https://ddfd55aa35fa4411a99f155e7be6b222@o1019000.ingest.sentry.io/5984885",
			disabled: !isProdBuild()
		});

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const loadingEl = document.getElementById("loading-overlay");
		const loadingErrorEl = document.getElementById("loading-error-message");
		const loadingErrorContentEl = document.getElementById("loading-error-message-content");

		const viewName = window.APP_STATE?.viewName ?? "main";
		const viewParams: IViewParamsSpec = window.APP_STATE?.viewParams ?? {};

		this.renderCtx = new ClientRenderContext({
			componentRegistry: this.get("componentRegistry"),
			dataSourceRegistry: this.get("dataSourceRegistry"),
			functionRegistry: this.get("functionRegistry"),
			viewRepository: this.get("viewRepository"),
			actionRepository: this.get("actionRepository"),
			translationManager: this.get("translationManager"),
			sessionProvider: this.get("sessionProvider"),
			routingManager: this.get("routingManager"),
			iconPackageUrlMapping: this.config.iconPackageUrlMapping,
			appId: this.config.appId,
			appEnvId: this.config.appEnvId,
			appName: this.config.appName,
			constants: this.config.constants,
			_app: this.config._app,
			viewName,
			viewParams
		});

		// Loading error
		const displayLoadingError = (reason: string) => {
			loadingErrorContentEl.innerText = reason;
			loadingErrorEl.classList.add("show");
		};

		// Inject app state
		if (window.APP_STATE) {
			if (window.APP_DEBUG) {
				console.debug("[App] Injecting app state", window.APP_STATE);
			}

			try {
				this.renderCtx.injectAppState(window.APP_STATE);
			} catch (err) {
				console.error("[App] Failed to inject app state:", err);
			}
		}

		const tModelRender = performance.now();

		// Do runtime model render
		this.renderCtx
			.getRuntimeContext()
			.renderAsync(true)
			.then(
				() => {
					const tReactRender = performance.now();

					console.info(`Model render took ${tReactRender - tModelRender}ms`);

					const renderCallback = () => {
						loadingEl.classList.remove("show");

						const tReactRenderEnd = performance.now();

						console.info(`React render took ${tReactRenderEnd - tReactRender}ms`);

						if (loadingEl.classList.contains("active")) {
							setTimeout(() => loadingEl.parentElement.removeChild(loadingEl), 600);
						} else {
							setTimeout(() => loadingEl.parentElement.removeChild(loadingEl), 400);
						}
					};

					// Render content
					if (window.APP_SSR_RENDERED) {
						console.info("[App] Hydrating...");

						const appRoot = hydrateRoot(
							document.getElementById("app-container"),
							<Main
								renderContext={this.renderCtx}
								renderMode={HAE_RENDER_MODE.BROWSER}
								wasRenderedWithSsr={true}
								appRootId="app-root"
								ref={renderCallback}
							/>
						);
					} else {
						if (window.APP_SSR_ERRORS) {
							console.error("[App] Failed to render server-side, not hydrating...");
						}

						const appRoot = createRoot(document.getElementById("app-container"));

						appRoot.render(
							<Main
								renderContext={this.renderCtx}
								renderMode={HAE_RENDER_MODE.BROWSER}
								wasRenderedWithSsr={false}
								appRootId="app-root"
								ref={renderCallback}
							/>
						);
					}

					// Set action delegates as ready because now the app is hydrated and
					// there won't be any state conflicts
					this.get("actionRepository").setReadyAfterInjection();
				},
				(err) => {
					console.error("[App] Failed to render model on init:", err);
					displayLoadingError("ERR_INIT_MODEL_RENDER");
				}
			);
	}
}
