/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Error Names
 */
export const ERROR_NAMES = {
	SERVICE: {
		INIT: "serviceInitializationError"
	},
	INTEGRATION: {
		INIT: "integrationInitializationError",
		EXEC: "integrationExecutionError",
		TEST: "integrationTestFailed"
	},
	SECRETS: "secretsManagerError",
	SQL: {
		SYNTAX: "sqlDatabaseSyntaxError",
		CONNECTION: "sqlDatabaseConnectionError"
	},
	INFLUX: {
		SYNTAX: "influxDbSyntaxError",
		SERVER: "influxDbServerError",
		CONFIG: "influxDbConfigError"
	},
	IN_MEM: {
		SYNTAX: "inMemoryDbSyntaxError",
		SERVICE: "inMemoryDbServiceError"
	},
	NETWORK: "networkError",
	API: {
		SERVER: "jsonApiServerError",
		REQUEST: "jsonApiRequestError",
		CONFIG: "jsonApiConfigurationError",
	},
	VAULT: {
		SERVER: "vaultServerError",
		PROVIDER: "vaultProviderError"
	},
	PG: {
		CONFIG: "postgresqlConfigError",
		SERVER: "postgresqlServerError",
	},
	MYSQL: {
		CONFIG: "mySqlConfigError",
		SERVER: "mySqlServerError",
	},
	MSSQL: {
		CONFIG: "msSqlConfigError",
		SERVER: "msSqlServerError",
	},
	ORACLE: {
		CONFIG: "oracleDbConfigError",
		SERVER: "oracleDbServerError",
	},
	GRAPHQL: {
		CONFIG: "graphqlConfigError",
		REQUEST: "graphqlRequestError",
		SERVER: "graphqlServerError"
	},
	GRPC: {
		CONFIG: "grpcConfigError",
		REQUEST: "grpcRequestError",
		SERVER: "grpcServerError"
	},
	SMTP: {
		CONFIG: "smtpConfigError",
		REQUEST: "smtpRequestError",
		SERVER: "smtpRequestError"
	},
	SSH_TUNNEL: {
		CONFIG: "sshTunnelConfigError",
		INIT: "sshTunnelInitError",
		CONNECTION: "sshTunnelConnectionError"
	},
	SSH_CLIENT: {
		CONFIG: "sshClientConfigError"
	},
	STATIC: {
		JSON: {
			CONFIG: "jsonConfigError",
			PARSING: "jsonParsingError"
		},
		YAML: {
			CONFIG: "yamlConfigError",
			PARSING: "yamlParsingError"
		},
		XML: {
			CONFIG: "xmlConfigError",
			PARSING: "xmlParsingError"
		},
		CSV: {
			CONFIG: "csvConfigError",
			PARSING: "csvParsingError"
		}
	},
	S3: {
		CONFIG: "s3StorageConfigError",
		REQUEST: "s3StorageRequestError",
		SERVER: "s3StorageServerError"
	},
	CONVERTER: {
		CONFIG: "converterConfigError",
		REQUEST: "converterRequestError"
	},
	FIRESTORE: {
		CONFIG: "firestoreConfigError",
		REQUEST: "firestoreRequestError",
		SERVER: "firestoreServerError"
	},
	MONGODB: {
		CONFIG: "mongoDbConfigError",
		REQUEST: "mongoDbRequestError",
		SERVER: "mongoDbServerError"
	}
};