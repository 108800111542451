/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP } from "@hexio_io/hae-lib-blueprint";

/**
 * Hexio Authentication Options Schema
 */
export const HexioAuthenticationOptionsSchema = BP.Object({
	props: {
		password: BP.Prop(BP.Object({
			// label: translations.manifest.authentication.hexio.password.label,
			props: {
				minLength: BP.Prop(BP.Const.Integer({
					// label: translations.manifest.authentication.hexio.password.minLength.label,
					// description: translations.manifest.authentication.hexio.password.minLength.description,
					constraints: {
						min: 8
					}
				}))
			}
		}))
	}
});
