/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TBlueprintIDTNode } from "../IDT/ISchemaIDT";
import { IParseInfo } from "../Shared/IParseInfo";
import { nodePathToString, parseInfoToString } from "../Context/ParseUtil";

/**
 * Blueprint parser error
 */
export class BlueprintParseError extends Error {

	public path: Array<string>;
	public parseInfo: IParseInfo;
	public errorMessage: string;

	public constructor(
		idtNode: TBlueprintIDTNode,
		message: string
	) {

		super(
			`Failed to parse blueprint at path '${nodePathToString(idtNode.path)}'`
			+ ( idtNode.parseInfo ? `, in ${parseInfoToString(idtNode.parseInfo)}` : "" )
			+ `: ${message}`
		);

		this.path = idtNode.path;
		this.parseInfo = idtNode.parseInfo;
		this.errorMessage = message;

	}

}