/**
 * QR Code schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const qrBarReader = {
	valueData: {
		label: "schema:qrBarReader.valueData#label",
		description: "schema:qrBarReader.valueData#description"
	},
	events: {
		scaned: {
			label: "schema:qrBarReader.event.onScanned#label"
		}
	},
	valueDataValues: {
		qrbar: {
			label: "schema:qrBarReader.valueData#values.qrbar#label"
		},
		qr: {
			label: "schema:qrBarReader.valueData#values.qr#label"
		},
		bar: {
			label: "schema:qrBarReader.valueData#values.bar#label"
		}
	}
};
