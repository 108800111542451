/**
 * Composed Chart Axis Type enum
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum COMPOSED_CHART_AXIS_TYPE {
	VALUE = "value",
	CATEGORY = "category",
	DATETIME = "datetime"
}

export const COMPOSED_CHART_AXIS_TYPE_default: keyof typeof COMPOSED_CHART_AXIS_TYPE = "VALUE";
