/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IRouteListEntry, IRouteResolver } from "@hexio_io/hae-lib-blueprint";
import { createEventEmitter, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IResourceRegistry } from "../registries";
import { IRoutesResourceProps, RESOURCE_TYPES } from "../resources";

/**
 * Route resolver
 */
export class RouteResolver implements IRouteResolver {

	/**
	 * Invalidate event - emitted when route list has changed
	 */
	public onInvalidate: TSimpleEventEmitter<void>;

	/**
	 * Constructor
	 *
	 * @param applicationContainer Application Container
	 */
	public constructor(protected resourceRegistry: IResourceRegistry) {

		this.onInvalidate = createEventEmitter();

	}

	/**
	 * Returns route by key
	 *
	 * @param routeName Route name
	 */
	public getRouteByName(routeKey: string): IRouteListEntry {

		const route = this.getRouteList().filter((route) => route.key === routeKey)[0];

		if (route) {
			return route;
		}

		return null;

	}

	/**
	 * Returns route by name
	 *
	 * @param routeName Route name
	 */
	public getRouteList(): IRouteListEntry[] {

		return getRouteList(this.resourceRegistry);

	}

}

export function getRouteList(resources: IResourceRegistry): IRouteListEntry[] {

	return resources.getItemList()
		.filter((resource) => resource.resourceType === RESOURCE_TYPES.ROUTES)
		.reduce((acc, resource: IRoutesResourceProps) => {
			if (resource.parsedData.routes) {
				for (const [key, route] of Object.entries(resource.parsedData.routes)) {
					if (route) {
						acc.push({
							key,
							name: key,
							path: route?.path,
							label: route?.label
						});
					}
				}
			}

			return acc;
		}, []);

}
