/**
 * Enum helpers
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstEnumOptionEntry, TSchemaConstEnumValueSchema } from "@hexio_io/hae-lib-blueprint";
import { isNonEmptyObject, isString, isUndefined } from "@hexio_io/hae-lib-shared";
import { getCSSPropertyValue } from "..";

/**
 * Enum default value
 */
export const ENUM_DEFAULT_VALUE = "DEFAULT";

/**
 * Return union of all string keys of T.
 *
 * Example:
 *
 * enum Abc {
 *     A = 'a',
 *     B = 'b',
 *     C = 'c'
 * }
 *
 * type Foo = StringKeyOf<Abc>  // "A" | "B" | "C"
 */
type StringKeyOf<T> = Extract<keyof T, string>;

/**
 * Return enum's key by value
 *
 * @param enumObject Enum to get key from
 * @param value String value
 */
export function getStringEnumKeyByValue<T extends { [name: string]: string }>(
	enumObject: T,
	value: string
): keyof T | null {
	const index = Object.values(enumObject).indexOf(value);

	if (index >= 0) {
		return Object.keys(enumObject)[index];
	}

	return null;
}

/**
 * Terms object
 */
type ITermsObject<T extends Record<keyof T, string>> = {
	[K in Lowercase<StringKeyOf<T>>]: {
		label: string;
		description?: string;
	};
};

/**
 * Icons object
 */
type IEnumOptsObject<T extends Record<keyof T, string>> = {
	[K in Lowercase<StringKeyOf<T>>]: Partial<ISchemaConstEnumOptionEntry<TSchemaConstEnumValueSchema>>;
};

/**
 * Returns values array from enum
 *
 * @param enumObject Enum to get values from
 * @param termsObject Terms object
 * @param optsObject Opts object
 */
export function getValuesFromStringEnum<T extends Record<keyof T, string>>(
	enumObject: T,
	termsObject?: ITermsObject<T>,
	optsObject?: IEnumOptsObject<T>
): Array<{ label: string; description?: string; icon?: string; value: keyof T }> {
	const enumKeys = Object.keys(enumObject);

	return enumKeys.map((item) => {
		const terms = isNonEmptyObject(termsObject) && termsObject[item.toLowerCase()] || { label: item };

		return {
			...terms,
			...optsObject?.[item.toLowerCase()],
			value: item,
		};
	});
}

/**
 * Returns string enum's value
 *
 * @param enumObject Enum object
 * @param value Value
 * @param defaultValue Default value
 */
export function getStringEnumValue<T extends Record<keyof T, string>>(
	enumObject: T,
	value: string,
	defaultValue: string = ENUM_DEFAULT_VALUE
): T[keyof T] {
	return (value in enumObject ? enumObject[value] : enumObject[defaultValue]) || "";
}

/**
 * Returns string enum's CSS value
 *
 * @param enumObject Enum object
 * @param value Value
 * @param prefix Prefix for CSS variable, empty string forces css variable
 * @param suffix Suffix for CSS variable, empty string forces css variable
 * @param fallbackValue Fallback value
 * @param compute Force to get computed value (browser only), can be target element as well
 */
export function getStringEnumCssValue<T extends { [name: string]: string }>(
	enumObject: T,
	value: string,
	prefix?: string,
	suffix?: string,
	fallbackValue?: string,
	compute: boolean | HTMLElement = false
): string {
	if (isString(value)) {
		if (value.includes(" ") && (value.search(/\w/) >= 0)) {
			// eslint-disable-next-line max-len
			return value.split(" ").map((item) => getStringEnumCssValue(enumObject, item, prefix, suffix, fallbackValue, compute)).join(" ");
		}

		if (value in enumObject) {
			if (isString(prefix) || isString(suffix)) {
				const baseVariableName = `--${prefix || ""}${enumObject[value]}`;
				const fullVariableName = suffix ? `${baseVariableName}${suffix}` : baseVariableName;

				if (fullVariableName && compute && !isUndefined(window)) {
					return getCSSPropertyValue(fullVariableName, compute instanceof HTMLElement ? compute : undefined);
				}

				if (fullVariableName !== baseVariableName) {
					return `var(${fullVariableName}, var(${baseVariableName}))`;
				}

				return `var(${fullVariableName})`;
			}

			return enumObject[value];
		}
	}

	if (isString(fallbackValue)) {
		return fallbackValue;
	}

	return value;
}
