/**
 * View Overlay
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	IBaseProps,
	Container,
	getStringEnumKeyByValue,
	SPACING,
	ClassList,
	OVERFLOW
} from "../..";

import { CONTAINER_DEFAULT_HORIZONTAL_ALIGN, CONTAINER_DEFAULT_VERTICAL_ALIGN } from "../../SharedSchemas/Container";

import {
	IViewOverlayBase,
} from "./Overlay";
import { IOverlayComponent } from "./IOverlayComponent";
import { offEvent, onEvent } from "@hexio_io/hae-lib-shared/src";

/**
 * Info Dialog Overlay props
 */
interface IViewOverlayProps extends IBaseProps, IOverlayComponent {
	/** Data */
	data: IViewOverlayBase;
}

/**
 * Info Dialog Overlay component
 */
export const ViewOverlay: React.FunctionComponent<IViewOverlayProps> = (props) => {
	const { componentPath } = props;
	const { viewRuntimeContext } = props.data;

	const [ componentListSpec, setComponentListSpec ] = React.useState({
		content: viewRuntimeContext.getLastSpec()
	});

	React.useEffect(() => {
		function _runtimeContextRenderHandler() {
			setComponentListSpec({
				content: viewRuntimeContext.getLastSpec()
			});
		}

		onEvent(viewRuntimeContext.renderEvent, _runtimeContextRenderHandler);

		return () => {
			offEvent(viewRuntimeContext.renderEvent, _runtimeContextRenderHandler);
		};
	}, [ viewRuntimeContext ]);

	return componentListSpec.content ?
		<Container
			content={componentListSpec.content}
			horizontalAlign={CONTAINER_DEFAULT_HORIZONTAL_ALIGN}
			verticalAlign={CONTAINER_DEFAULT_VERTICAL_ALIGN}
			padding={getStringEnumKeyByValue(SPACING, SPACING.WIDE)}
			overflow={getStringEnumKeyByValue(OVERFLOW, OVERFLOW.VERTICAL)}
			componentPath={[ ...componentPath, "view-container" ]}
			componentMode={props.componentMode}
			classList={new ClassList("overlay-item__container")}
		/> :
		<div className="overlay-item__content">
			<p>Loading root view...</p>
		</div>;
}
