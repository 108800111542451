/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportValidator } from "../ExportImportSchema/ExportSchema";
import {
	IBlueprintSchemaValidationError,
	IBlueprintSchemaValidator,
	IBlueprintSchemaValidatorHandler,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { ValidatorDeclarationError } from "../Shared/ValidatorDeclarationError";

type TValidatorStringHandler = IBlueprintSchemaValidatorHandler<string>;
type TValidatorString<TOpts> = IBlueprintSchemaValidator<string, TOpts>;

/**
 * String validator options
 */
export interface IValidatorStringOpts {
	/** Required value */
	required?: boolean;
	/** Constant value */
	const?: string;
	/** Minimum length */
	min?: number;
	/** Maximum length */
	max?: number;
	/** RegExp string */
	pattern?: string;
	/** Error messages displayed when pattern does not match (instead of printing regexp). */
	patternErrorMessage?: string;
}

const VALIDATOR_NAME = "ValidatorString";

/**
 * String validator
 */
export const ValidatorString: TValidatorString<IValidatorStringOpts> =
	(opts: IValidatorStringOpts): TValidatorStringHandler => {

		if (opts.required !== undefined && opts.required !== null && typeof opts.required !== "boolean") {
			throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `required` to be a boolean");
		}

		if (opts.const !== undefined && opts.const !== null && typeof opts.const !== "string") {
			throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `const` to be a string");
		}

		if (opts.min !== undefined && opts.min !== null && typeof opts.min !== "number") {
			throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `min` to be a number");
		}

		if (opts.max !== undefined && opts.max !== null && typeof opts.max !== "number") {
			throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `max` to be a number");
		}

		let regExp;
		if (opts.pattern !== undefined && opts.pattern !== null && typeof opts.pattern !== "string") {

			throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `pattern` to be a string");

		} else if (opts.pattern !== undefined && opts.pattern !== null) {

			try {
				regExp = new RegExp(opts.pattern, "u");
			} catch (error) {
				throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `pattern` to be a valid regular expression");
			}

		}

		return {

			validate: (value: string): IBlueprintSchemaValidationError[] => {

				const errors = [];

				if (
					(opts.required === true && typeof value !== "string") ||
					(!opts.required && (typeof value !== "string" && (value !== null && value !== undefined)))
				) {
					errors.push({
						type: SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED,
						message: "Should be a string"
					});
				}

				if (opts.const !== undefined && opts.const !== null && (typeof value === "string" && value !== opts.const)) {
					errors.push({
						type: SCHEMA_VALIDATION_ERROR_TYPE.CONST,
						message: `Should be a equal to const '${opts.const}'`
					});
				}

				if (opts.min !== undefined && opts.min !== null && (typeof value === "string" && value.length < opts.min)) {
					errors.push({
						type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
						message: `Shouldn't have less than ${opts.min} characters`
					});
				}

				if (opts.max !== undefined && opts.max !== null && (typeof value === "string" && value.length > opts.max)) {
					errors.push({
						type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
						message: `Shouldn't have more than ${opts.max} characters`
					});
				}

				if (regExp !== undefined && regExp !== null && (typeof value === "string" && value !== "" && !regExp.test(value))) {
					errors.push({
						type: SCHEMA_VALIDATION_ERROR_TYPE.PATTERN,
						// eslint-disable-next-line max-len
						message: `Should match ${opts.patternErrorMessage ? opts.patternErrorMessage : "pattern '" + opts.pattern.toString() + "'"}`
					});
				}

				return errors;

			},

			compile: (): string => {

				const parts = [];

				if (opts.required === true) {
					// eslint-disable-next-line max-len
					parts.push(`if(typeof value !== "string"){errors.push({type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message:"Should be a string"});}`);
				}

				if (!opts.required) {
					// eslint-disable-next-line max-len
					parts.push(`if(typeof value !== "string" && (value !== null && value !== undefined)){errors.push({type:"${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message:"Should be a string"});}`);
				}

				if (opts.const !== undefined && opts.const !== null) {
					// eslint-disable-next-line max-len
					parts.push(`if(typeof value === "string" && value !== "${opts.const}"){errors.push({type:"${SCHEMA_VALIDATION_ERROR_TYPE.CONST}",message:"Should be a equal to const '${opts.const}'"});}`);
				}

				if (opts.min !== undefined && opts.min !== null) {
					// eslint-disable-next-line max-len
					parts.push(`if(typeof value === "string" && value.length < ${opts.min}){errors.push({type:"${SCHEMA_VALIDATION_ERROR_TYPE.RANGE}",message:"Shouldn't have less than ${opts.min} characters"});}`);
				}

				if (opts.max !== undefined && opts.max !== null) {
					// eslint-disable-next-line max-len
					parts.push(`if(typeof value === "string" && value.length > ${opts.max}){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.RANGE}",message:"Shouldn't have more than ${opts.max} characters"});}`);
				}

				if (regExp !== undefined && regExp !== null) {
					// eslint-disable-next-line max-len
					parts.push(`if(typeof value === "string" && value !== "" && !${regExp.toString()}.test(value)) { errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.PATTERN}",message:"Should match ${opts.patternErrorMessage ? opts.patternErrorMessage : "pattern '" + opts.pattern.toString() + "'"}"});}`);
				}

				// Minification
				const code = parts.join(" ")
					.replace(/{ /g, "{")
					.replace(/ }/g, "}")
					.replace(/: "/g, ":\"")
					.replace(/ ,/g, ",")
					.replace(/ < /g, "<")
					.replace(/ > /g, ">")
					.replace(/ <= /g, "<=")
					.replace(/ >= /g, ">=")
					.replace(/ === /g, "===")
					.replace(/ && /g, "&&")
					.replace(/ !== /g, "!==")
					.replace(/"string"/g, "s")
					.replace(/value/g, "v")
					.replace(/errors.push/g, "e.push");

				return `const e=[];const s="string";${code} return e;`;

			},

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			export: (): any => {
				return exportValidator(VALIDATOR_NAME, [opts]);
			}

		}
	};
