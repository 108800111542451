/*
 * Use Did Update Effect hook
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { isFunction } from "@hexio_io/hae-lib-shared";

/**
 * Effect that only fires on update (componentDidUpdate), not on mount (componentDidMount)
 *
 * @param effect Effect callback
 * @param dependencies Dependencies
 */
export function useDidUpdateEffect(effect: React.EffectCallback, dependencies?: React.DependencyList) {
	const mountedRef = React.useRef(false);

	React.useEffect(() => {
		if (!mountedRef.current) {
			mountedRef.current = true;

			return;
		}

		if (isFunction(effect)) {
			effect();
		}
	}, dependencies);
}

/**
 * Effect that only fires on layout update (componentDidUpdate), not on mount (componentDidMount)
 *
 * @param effect Effect callback
 * @param dependencies Dependencies
 */
export function useDidUpdateLayoutEffect(effect: React.EffectCallback, dependencies?: React.DependencyList) {
	const mountedRef = React.useRef(false);

	React.useLayoutEffect(() => {
		if (!mountedRef.current) {
			mountedRef.current = true;

			return;
		}

		if (isFunction(effect)) {
			effect();
		}
	}, dependencies);
}
