/**
 * Hexio App Engine Icon Pack - Material Design Icons
 *
 * @package hae-ext-integrations-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAppClient } from "@hexio_io/hae-lib-core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const registerExtension = (application: IAppClient): void => {
	// Icons are registered only server-side
};