/**
 * Confirmation Dialog Overlay
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	Button,
	Text,
	IBaseProps,
	ClassList
} from "../..";

import {
	IConfirmationDialogOverlay, OVERLAY_CANCEL_BUTTON_ID, OVERLAY_CONFIRM_BUTTON_ID,
} from "./Overlay";
import { IOverlayComponent } from "./IOverlayComponent";

/**
 * Confirmation Dialog Overlay props
 */
interface IConfirmationDialogOverlayProps extends IBaseProps, IOverlayComponent {
	/** Data */
	data: IConfirmationDialogOverlay;
}

/**
 * Confirmation Dialog Overlay component
 */
export const ConfirmationDialogOverlay: React.FunctionComponent<IConfirmationDialogOverlayProps> = (props) => {
	const { id, remove, componentPath, componentMode } = props;
	const { text, confirmValue, confirmButton, cancelButton } = props.data;

	const [ confirmButtonEnabled, setConfirmButtonEnabled ] = React.useState(confirmValue ? false : true);

	let _inputChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;

	if (confirmValue) {
		_inputChangeHandler = (event) => {
			const { value } = event.currentTarget;

			setConfirmButtonEnabled(value === confirmValue);
		}
	}

	const _confirmClickHandler = React.useCallback(() => {
		remove(undefined, { overlayId: id, buttonId: OVERLAY_CONFIRM_BUTTON_ID, customData: null });
	}, [ id ]);

	const _cancelClickHandler = React.useCallback(() => {
		remove(undefined, { overlayId: id, buttonId: OVERLAY_CANCEL_BUTTON_ID, customData: null });
	}, [ id ]);

	return (
		<div className="overlay-item__content">
			<Text {...text} componentPath={[ ...componentPath, "confirmation-dialog-text" ]} componentMode={componentMode} />

			{
				confirmValue ?
					<div className="overlay-item__field cmp-field">
						<input
							type="text"
							className="cmp-field__input"
							defaultValue=""
							onChange={_inputChangeHandler}
						/>
					</div> :
					null
			}

			<div className="overlay-item__buttons">
				<Button
					labelText={confirmButton.labelText}
					labelIcon={confirmButton.labelIcon}
					backgroundColor={confirmButton.backgroundColor}
					foregroundColor={confirmButton.foregroundColor}
					width={confirmButton.width}
					enabled={confirmButtonEnabled}
					componentPath={[ ...componentPath, OVERLAY_CONFIRM_BUTTON_ID ]}
					componentMode={componentMode}
					classList={new ClassList("button--layout")}
					onClick={_confirmClickHandler}
				/>

				<Button
					labelText={cancelButton.labelText}
					labelIcon={cancelButton.labelIcon}
					backgroundColor={cancelButton.backgroundColor}
					foregroundColor={cancelButton.foregroundColor}
					width={cancelButton.width}
					componentPath={[ ...componentPath, OVERLAY_CANCEL_BUTTON_ID ]}
					componentMode={componentMode}
					classList={new ClassList("button--layout")}
					onClick={_cancelClickHandler}
				/>
			</div>
		</div>
	);
}
