/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IDocumentRange } from "./IDocumentRange";
import { IDocumentLocation } from "./IDocumentLocation";
import { TParsePath } from "./TParsePath";

/**
 * Error severity
 */
export enum DOC_ERROR_SEVERITY {
	ERROR = 1,
	WARNING = 2,
	INFO = 3,
	HINT = 4
}

export enum DOC_ERROR_NAME {
	PARSE_ERROR = "PARSE_ERROR",
	INVALID_VALUE = "INVALID_VALUE",
	INVALID_NODE = "INVALID_NODE",
	INVALID_KEY = "INVALID_KEY",
	FUNC_INVALID_ARGUMENTS = "INVALID_ARGUMENTS",
	FUNC_NOT_FUNC = "FUNC_NOT_FUNC",
	OBJ_NOT_MAP = "OBJ_NOT_MAP",
	OBJ_UNKNOWN_PROPERTY = "OBJ_UNKNOWN_PROPERTY",
	OBJ_MISSING_PROPERTY = "OBJ_MISSING_PROPERTY",
	MAP_NOT_MAP = "MAP_NOT_MAP",
	INT_NOT_NUMBER = "INT_NOT_NUMBER",
	FLOAT_NOT_NUMBER = "FLOAT_NOT_NUMBER",
	STR_NOT_STRING = "STR_NOT_STRING",
	ENUM_OUT_OF_RANGE = "ENUM_OUT_OF_RANGE",
	DATE_NOT_STRING = "DATE_NOT_STRING",
	DATE_NOT_DATE = "DATE_NOT_DATE",
	BOOL_NOT_BOOLEAN = "BOOL_NOT_BOOLEAN",
	LIST_NOT_LIST = "LIST_NOT_LIST",
	NOT_SINGLE_PROP = "NOT_SINGLE_PROP",
	REF_INVALID_FORMAT = "REF_INVALID_FORMAT",
	REF_NOT_LIST = "REF_NOT_LIST",
	CMP_MISSING_NAME = "CMP_MISSING_NAME",
	CMP_MISSING_PROP = "CMP_MISSING_PROP",
	CMP_UNKNOWN = "CMP_UNKNOWN",
	CMP_RUNTIME_ERROR = "CMP_RUNTIME_ERROR",
	DS_UNKNOWN = "DS_UNKNOWN",
	DS_RUNTIME_ERROR = "DS_RUNTIME_ERROR",
	RENDER_LOOP = "RENDER_LOOP",
	RENDER_UNEXPECTED_ERROR = "RENDER_UNEXPECTED_ERROR",
	DUPLICATE_SCOPE_ID = "DUPLICATE_COMPONENT_ID",
	INVALID_REF = "INVALID_REF",
	DYNAMIC_VALUE_NOTICE = "DYNAMIC_VALUE_NOTICE",
	EVENT_ERROR = "EVENT_ERROR",
	VIEW_LOAD_ERROR = "VIEW_LOAD_ERROR",
	CAST_ERROR = "CAST_ERROR",
	UNAVAILABLE_RESOLVER = "UNAVAILABLE_RESOLVER",
	NOT_SUPPORTED = "NOT_SUPPORTED" // some feature is not supported
}

/**
 * Error related information item
 */
export interface IDocumentErrorRelatedInformation {
	/** Document location */
	location: IDocumentLocation;

	/** Message */
	message: string;

	/** Custom message meta-data */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	metaData?: { [K: string]: any; }
}

/**
 * Document error
 */
export interface IDocumentError {
	/** Parse range where error occured */
	range: IDocumentRange;

	/** Severity */
	severity: DOC_ERROR_SEVERITY;

	/** Error name */
	name: string;

	/** Error message - used for better error reporting */
	message: string;

	/** Related information */
	relatedInformation?: Array<IDocumentErrorRelatedInformation>;

	/** Parse path */
	parsePath?: TParsePath;

	/** Custom error meta-data */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	metaData?: { [K: string]: any; }
}

/**
 * Map of errors to specific documents
 */
export interface IDocumentErrorMap {
	[documentUri: string]: Array<IDocumentError>;
}