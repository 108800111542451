/**
 * Iframe Loading Enum
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum IFRAME_LOADING {
	EAGER = "eager",
	LAZY = "lazy"
}

export type IFRAME_LOADING_string = keyof typeof IFRAME_LOADING;

export const IFRAME_LOADING_default: IFRAME_LOADING_string = "EAGER";
