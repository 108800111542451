/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	IBlueprintSchema,
	IBlueprintSchemaOpts, TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange
} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";

/**
 * Schema model
 */
export interface ISchemaOverrideModel<
	TValueSchema extends TGenericBlueprintSchema
> extends IModelNode<ISchemaOverride<TValueSchema>> {
	/** Reference to an original value model */
	originalValue: TGetBlueprintSchemaModel<TValueSchema>;
	/** Overriden value model instance */
	overridenValue: TGetBlueprintSchemaModel<TValueSchema>;
	/** Indicates if a value is overriden or not */
	isOverriden: boolean;
}

/**
 * Schema options
 */
export interface ISchemaOverrideOpts extends IBlueprintSchemaOpts {}

/**
 * Schema type
 */
export interface ISchemaOverride<
	TValueSchema extends TGenericBlueprintSchema
> extends IBlueprintSchema<
	ISchemaOverrideOpts,
	ISchemaOverrideModel<TValueSchema>,
	TGetBlueprintSchemaSpec<TValueSchema>,
	TGetBlueprintSchemaDefault<TValueSchema>
> {
	/**
	 * Assigns original value
	 *
	 * @param isOverriden If the value is overriden
	 * @param notify If to emit change event
	 */
	__assignOriginalValue: (
		modelNode: ISchemaOverrideModel<TValueSchema>,
		originalValueNode: TGetBlueprintSchemaModel<TValueSchema>
	) => void;

	/**
	 * Set whenever the value is overriden
	 *
	 * @param isOverriden If the value is overriden
	 * @param notify If to emit change event
	 */
	setOverriden: (modelNode: ISchemaOverrideModel<TValueSchema>, isOverriden: boolean, notify?: boolean) => void;
}

/**
 * Schema: Override
 * Allows to overriden existing model. Should be used internally due to specific construction functions.
 *
 * @param opts Schema options
 */
export function SchemaOverride<
	TValueSchema extends TGenericBlueprintSchema
// eslint-disable-next-line @typescript-eslint/no-unused-vars
>(valueSchema: TValueSchema, opts: ISchemaOverrideOpts): ISchemaOverride<TValueSchema> {

	type TOverridenValueModel = TGetBlueprintSchemaModel<TValueSchema>;

	const schema = createEmptySchema<ISchemaOverride<TValueSchema>>("override", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, "overridenValue")
	}

	const createModel = (
		dCtx: DesignContext,
		overridenValue: TOverridenValueModel,
		isOverriden: boolean,
		parent: TBlueprintSchemaParentNode
	) => {

		const model = createModelNode(schema, dCtx, parent, [], {
			originalValue: null,
			overridenValue: overridenValue,
			isOverriden: isOverriden
		});

		return assignParentToChildrenOf(model)
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		const overridenValue = valueSchema.createDefault(dCtx, null,defaultValue) as TOverridenValueModel;

		// Create model
		return createModel(dCtx, overridenValue, defaultValue !== undefined ? true : false, parent);

	};

	schema.parse = (dCtx, idtNode, parent) => {

		if (idtNode) {

			const overridenValue = valueSchema.parse(dCtx, idtNode, null) as TOverridenValueModel;
			return createModel(dCtx, overridenValue, true, parent);

		} else {

			const overridenValue = valueSchema.createDefault(dCtx, null) as TOverridenValueModel;
			return createModel(dCtx, overridenValue, false, parent);

		}

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		if (valueSchema.provideCompletion) {
			valueSchema.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}

	};

	schema.serialize = (modelNode, path) => {

		if (modelNode.isOverriden) {
			return modelNode.overridenValue.schema.serialize(modelNode.overridenValue, path);
		} else {
			return null;
		}

	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	schema.clone = (dCtx, modelNode, parent) => {

		const clone = cloneModelNode(dCtx, modelNode, parent,{
			originalValue: null,
			overridenValue: valueSchema.clone(dCtx, modelNode.overridenValue, null) as TOverridenValueModel,
			isOverriden: modelNode.isOverriden
		});

		return assignParentToChildrenOf(clone)
	};

	schema.destroy = (modelNode) => {

		modelNode.overridenValue.schema.destroy(modelNode.overridenValue);
		destroyModelNode(modelNode);

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		if (modelNode.isOverriden) {
			return modelNode.overridenValue.schema.render(rCtx, modelNode.overridenValue, path, scope, prevSpec);
		} else {
			return undefined;
		}

	},

	schema.compileRender = (cCtx, modelNode, path) => {

		if (modelNode.isOverriden) {
			return modelNode.overridenValue.schema.compileRender(cCtx, modelNode.overridenValue, path);
		} else {
			return null;
		}

	};

	// @todo Test and check if to replace with validateAsNotSupported(...)
	// No need for validation because validation is done via value nodes
	schema.validate = () => {
		return true;
	};

	schema.compileValidate = (): string => {
		return null;
	};

	schema.__assignOriginalValue = (
		modelNode: ISchemaOverrideModel<TValueSchema>,
		originalValueNode: TGetBlueprintSchemaModel<TValueSchema>
	) => {

		modelNode.originalValue = originalValueNode;
		modelNode.originalValue.schema.assignParent(modelNode.originalValue, modelNode, false)

	}

	schema.setOverriden = (modelNode: ISchemaOverrideModel<TValueSchema>, isOverriden: boolean, notify?: boolean) => {

		modelNode.isOverriden = isOverriden;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	};

	schema.getChildNodes = (modelNode) => {
		return [{
			key: "overridenValue",
			node: modelNode.overridenValue
		}];
	}

	return schema;

}
