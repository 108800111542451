/**
 * Custom HTML HAE component
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, defineElementaryComponent, Type } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	THAEComponentDefinition,
	THAEComponentReact,
	propGroups,
	useStyleSheetRegistry,
	StyleSheet
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";

interface HAEComponentCustomHtml_State {}

/**
 * Custom HTML props
 */
const HAEComponentCustomHtml_Props = {

	markup: BP.Prop(BP.String({
		...termsEditor.schemas.customHtml.markup,
		default: ""
	}), 0, propGroups.common),

	script: BP.Prop(BP.String({
		...termsEditor.schemas.customHtml.script,
		default: ""
	}), 10, propGroups.common)

};

const HAEComponentCustomHtml_Events = {};

const HAEComponentCustomHtml_Definition = defineElementaryComponent<
	typeof HAEComponentCustomHtml_Props,
	HAEComponentCustomHtml_State,
	typeof HAEComponentCustomHtml_Events
>({
	...termsEditor.components.customHtml.component,

	name: "customhtml",

	category: "logic",

	icon: "mdi/code-tags",

	docUrl: "...",

	order: 1000,

	props: HAEComponentCustomHtml_Props,

	events: HAEComponentCustomHtml_Events,

	hidden: true,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentCustomHtml_React: THAEComponentReact<typeof HAEComponentCustomHtml_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const { script } = props;

	const elementRef = React.useRef<HTMLDivElement>();

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-custom-html", componentInstance.safePath, { componentClassList: reactComponentClassList }
	);

	// Markup

	const markup = React.useMemo(() => {
		return (<div className="cmp-custom-html__markup" dangerouslySetInnerHTML={{ __html: props.markup }} />);
	}, [ props.markup ]);

	// Script

	React.useEffect(() => {
		if (!elementRef.current || !script) {
			return;
		}

		let scriptElement: HTMLScriptElement;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function _windowCustomHtmlScriptValidHandler(event: any) {
			if (event.detail?.idClassName === idClassName) {
				scriptElement = document.createElement("script");

				scriptElement.appendChild(document.createTextNode(`try{${script}}catch(e){}`));

				elementRef.current.appendChild(scriptElement);
			}
		}

		window.addEventListener("hae_component_customhtml_script_valid", _windowCustomHtmlScriptValidHandler);

		const iframeElement = document.createElement("iframe");

		iframeElement.srcdoc = `<!DOCTYPE html><meta charset="utf-8"><script>
			window.addEventListener("error", (event) => {
				if (typeof event === "object" && event !== null) {
					event.preventDefault();

					console.warn("Custom HTML script:", event.message || "UNKNOWN");
				}
			});
		</script>
		<script>
			${script};

			window.parent?.dispatchEvent(new CustomEvent("hae_component_customhtml_script_valid", {
				detail: { idClassName: "${idClassName}" }
			}));
		</script>`;

		elementRef.current.appendChild(iframeElement);

		return () => {
			if (scriptElement?.parentElement === elementRef.current) {
				elementRef.current.removeChild(scriptElement);
			}

			if (iframeElement.parentElement === elementRef.current) {
				elementRef.current.removeChild(iframeElement);
			}

			window.removeEventListener("hae_component_customhtml_script_valid", _windowCustomHtmlScriptValidHandler);
		};
	}, [ script, idClassName ]);

	return (
		<div ref={elementRef} className={classList.toClassName()}>
			{markup}
		</div>
	);
};

export const HAEComponentCustomHtml: THAEComponentDefinition<typeof HAEComponentCustomHtml_Definition> = {
	...HAEComponentCustomHtml_Definition,
	reactComponent: HAEComponentCustomHtml_React
};
