/**
 * hae-ext-components-pro
 *
 * Hexio App Engine: Set of pro components
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { ITranslationManager } from "@hexio_io/hae-lib-core";
import {
	TGenericHAEComponentDefinition
} from "@hexio_io/hae-lib-components";

import { termsEditor, termsRuntime } from "./terms";

import { HAEComponentCustomChart } from "./Components/CustomChart/CustomChart";
import { HAEComponentCustomHtml } from "./Components/CustomHtml/CustomHtml";
import { HAEComponentCustomComponent } from "./Components/CustomComponent/CustomComponent";

export function registerComponents(
	registry: ItemRegistry<TGenericHAEComponentDefinition>,
	translationManager: ITranslationManager
): void {
	// Logic components
	registry.register(HAEComponentCustomHtml);

	// Charts components
	registry.register(HAEComponentCustomChart);

	// Other components
	registry.register(HAEComponentCustomComponent);

	// Register translation terms
	translationManager.registerTerms("editor", termsEditor, "hae-ext-components-pro");
	translationManager.registerTerms("runtime", termsRuntime, "hae-ext-components-pro");
	//translationManager.registerTerms("editor", libTermsEditor, "hae-ext-components-pro#lib");
	//translationManager.registerTerms("runtime", libTermsRuntime, "hae-ext-components-pro#lib");
}
