/**
 * Color
 * Contains media queries as well
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Color enums
 */
export enum COLOR_ADVANCED {
	INHERIT = "inherit",
	TRANSPARENT = "transparent"
}

export enum COLOR_BASE {
	BACKGROUND = "background",
	TEXT = "text",
	ICON = "icon",
	FIELD_BACKGROUND = "field-background"
}

export enum COLOR_MAIN {
	BLACK = "black",
	WHITE = "white",
	GRAY = "gray",
	LIGHT_GRAY = "lightgray",
	RED = "red",
	GREEN = "green",
	BLUE = "blue",
	YELLOW = "yellow",
	ORANGE = "orange",
	PRIMARY = "primary",
	SECONDARY = "secondary",
	SUCCESS = "success",
	WARNING = "warning",
	ERROR = "error",
	DANGER = "danger"
}

export const COLOR = { ...COLOR_ADVANCED, ...COLOR_MAIN, ...COLOR_BASE };
