/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IBlueprintContentMetadata, IBlueprintMetadata, TEditorApiBlueprintHistory } from "../api";
import { IAppServer, IAppServerErrorReport } from "../app";
import { BlueprintBase, DOC_TYPES, MANIFEST } from "../blueprints";
import { ERROR_REPORT_TYPE } from "../errors";
import { IResourceProps, RESOURCE_PERMISSIONS } from "../registries";
import { Service } from "../services";
import { IApplicationManifest } from "./IManifest";
import { IResourceDescription, RESOURCE_TYPES } from "./IResource";
import { IRawResource, IRawResourceErrorDetails, IResourceAdapter } from "./IResourceAdapter";
import { IResourceLock } from "./IResourceLock";
import { TDefaultResourceType } from "./IResourceType";
import { Resource } from "./Resource";
import { IAssetResourceProps, IDirectoryResourceProps, IIntegrationResourceProps } from "./resourceTypes";
import {
	BlueprintConflictError,
	ConflictError,
	ForbiddenLockedError,
	ForbiddenProtectedError,
	NotFoundError,
	UnprocessableEntityError
} from "../WebServer";
import {
	CompileContext,
	createEmptyScope,
	createRenderFunctionFromModel,
	DesignContext,
	DESIGN_CONTEXT_READ_MODE,
	DOC_ERROR_SEVERITY,
	IDocumentError,
	RuntimeContext,
	RUNTIME_CONTEXT_MODE,
	TGenericBlueprintSchema,
	TGenericModelNode,
	TGetBlueprintSchemaSpec,
} from "@hexio_io/hae-lib-blueprint";
import {
	createEventEmitter,
	emitEvent,
	isDeepEqual,
	ItemRegistry,
	TSimpleEventEmitter
} from "@hexio_io/hae-lib-shared";
import {
	IResourceErrorReport,
	IResourceManager,
	IResourceManagerResourcesStats,
	IResourceOnEventData,
	RESOURCE_ON_EVENT_TYPE,
	TResourceManagerLockMetadata
} from "./IResourceManager";
import { getRouteList } from "../resolvers";
import { CONST } from "../constants";
import { createDefaultManifest } from "./createManifest";
import { IAppEnvs } from "../envvars";

export type TBlueprintHeader = Partial<Pick<IBlueprintMetadata, "id" | "resourceType" | "attributes" | "parseData">>;

/**
 * Provider Options
 */
export interface IResourceManagerOptions {
	/** App environment name */
	appEnv?: string;
	/** Fail on Error flag */
	failOnError: boolean;
	/** Initial profile id */
	profileId?: string;
	/** Report app error */
	reportAppError?: (error: IAppServerErrorReport) => void;
	/** Log errors to the console */
	logErrorsToConsole?(errors: IResourceErrorReport[]): void;
	/** Check resource list in milliseconds */
	checkResourceListInterval?: number;
}

/**
 * Resource Provider
 *
 * Provides defined component instances.
 */
export class ResourceManager extends Service implements IResourceManager {

	public onResource: TSimpleEventEmitter<IResourceOnEventData>;

	protected profilesId: string;
	protected manifest: { [K: string]: IApplicationManifest };
	protected resourceTypes: ItemRegistry<TDefaultResourceType> = new ItemRegistry<TDefaultResourceType>();
	protected appEnv: string;
	private lastResourceListCheck: number;

	public constructor(
		protected config: IResourceManagerOptions,
		protected app: IAppServer,
		public adapter: IResourceAdapter,
		protected resourceLock: IResourceLock
	) {

		super(app.get("logger"));

		this.logger = app.get("logger").facility("resource-manager");
		this.appEnv = config.appEnv;
		this.onResource = createEventEmitter<IResourceOnEventData>();
		this.profilesId = this.config.profileId;

	}

	/**
	 * Initializes manager
	 */
	public async init(): Promise<void> {

		this.logger.debug(`Initialize.`);

		if (this.initialized) {
			this.logger.info("Skip, already initialized.");
			return;
		}

		await this.adapter.init();
		this.initialized = true;

	}

	/**
	 * Disposes manager
	 */
	public async dispose(): Promise<void> {

		this.logger.info("Disposing...");

		this.initialized = false;
		await this.adapter.dispose();
		this.app.get("resourceRegistry").flush();

	}

	public setProfileId(profileId: string): void {
		this.profilesId = profileId;
	}

	/**
	 * Registers Resource type
	 * @param resourceType Resource Type
	 */
	public registerResourceType(resourceType: TDefaultResourceType): void {

		this.logger.info(`Registering resource type: '${resourceType.name}'`);
		this.resourceTypes.register(resourceType);

	}

	public throwError(
		message: IAppServerErrorReport["message"],
		details?: IAppServerErrorReport["details"]
	): void {
		this.reportError(message, details);
		if (this.config.failOnError === true) {
			throw new Error(message);
		}
	}

	public reportError(
		message: IAppServerErrorReport["message"],
		details?: IAppServerErrorReport["details"]
	): void {
		this.logger.warn(message);
		this.logger.debug("Details:", details);

		if (this.config.reportAppError) {
			this.config.reportAppError({
				message,
				details,
				type: ERROR_REPORT_TYPE.RESOURCE,
			});
		}

	}

	public createDCtx(scan = false): DesignContext {
		return new DesignContext({
			resolvers: this.app.get("resolversRegistry").getAll(),
			readMode: scan === true ? DESIGN_CONTEXT_READ_MODE.SCAN : DESIGN_CONTEXT_READ_MODE.FULL
		});
	}

	public createCCtx(): CompileContext {
		return new CompileContext({ resolvers: this.app.get("resolversRegistry").getAll() });
	}

	protected logAstErrors(dCtx: DesignContext, uri: string, errors: IRawResourceErrorDetails): void {

		errors.map((e) => {
			dCtx.logParseError(uri, {
				severity: DOC_ERROR_SEVERITY.ERROR,
				name: e.name,
				message: e.message,
				range: {
					start: { line: e.mark.line, col: e.mark.column },
					end: { line: e.mark.line, col: e.mark.column + e.mark.buffer.length }
				},
				parsePath: []
			})
		});

	}

	public async parseModel<TResource extends IResourceProps>(
		resource: TResource,
		dCtx: DesignContext,
		schema: TGenericBlueprintSchema): Promise<TResource> {

		let model;

		try {

			/** Model should be removed. Resource can have model from previous scan phase. */
			resource.parsedData.model = undefined;

			const { idt } = resource;

			model = schema.parse(dCtx, idt, null);
			await dCtx.waitForPendingOperations();

			/** Update dependencies */
			const refs = dCtx.getRefsIndex();
			if (Object.keys(refs).length > 0) {
				const ids = Object.values(refs).reduce((acc, val) => acc.concat(Object.keys(val)), []);
				resource.dependencies = resource.dependencies.concat(ids.map((id) => ({ id })));
			}

			resource.reportParsingErrors(dCtx);

			if (dCtx.hasFatalErrors()) {


				this.logger.warn(`Cannot parse resource '${resource.uri}'.`);
				const details = dCtx.getParseErrors();
				this.logger.debug(details);
				return resource;

			}

			resource.parsedData.model = model;
			resource.parsingDetails.isValidModel = true;

		} catch (error) {

			this.logger.debug({ error, message: error?.message });
			this.reportError("Can't parse model because an unexpected error occurred.", error);

		}

		return resource;

	}

	public async renderSpec<TResource extends IResourceProps>(resource: TResource): Promise<TResource> {

		let rCtx: RuntimeContext;
		let spec: TGetBlueprintSchemaSpec<TGenericBlueprintSchema>;

		try {

			/** Remove spec if there's one. */
			resource.parsedData.spec = undefined;

			const { uri } = resource;
			const { model } = resource.parsedData;

			rCtx = this.createRCtx(model);

			try {
				spec = await rCtx.renderAsync(true);
			} catch (error) {
				this.reportError("Can't render spec from model.", error);
				return resource;
			}

			this.reportRuntimeErrors(uri, rCtx);

			if (rCtx.hasFatalErrors()) {
				this.logger.warn("Failed to render spec from model.");
				this.logger.debug(rCtx.getRuntimeErrors());
				return resource;
			}

			resource.parsingDetails.isValidSpec = true;
			resource.attributes = spec.attributes;
			resource.metadata = spec.metadata;
			resource.parsedData.spec = spec;

		} finally {

			if (rCtx) {
				rCtx.destroy();
			}

		}

		return resource;

	}

	public async loadManifest(): Promise<void> {

		this.logger.info("Load manifest.");

		/** Create default manifest in case that Manifest blueprint will be invalid. */
		this.manifest = { "default": createDefaultManifest() };

		let raw: IRawResource;
		try {

			raw = await this.adapter.loadManifest();

		} catch (error) {

			this.logger.debug({ error: error?.message });
			this.throwError("Can't load manifest.", error);
			return;

		}

		if (!raw || !raw.isValid) {
			this.throwError("Can't parse manifest. Manifest is invalid.");
			return;
		}

		let resource = await this.loadResource(raw);
		this.updateResource(resource);

		if (resource.parsingDetails.isRegistered) {

			const resourceType = this.resourceTypes.get(resource.docType);
			resource = await resourceType.scan(resource);
			this.updateResource(resource);

			resource = await resourceType.parse(resource);
			this.updateResource(resource);

		} else {
			this.logger.debug(`Resource type ${resource.docType} not registered.`);
		}

		const spec = resource?.parsedData?.spec?.spec;
		if (!spec) {
			this.throwError("Can't render manifest spec.");
			return;
		}

		this.manifest = spec;
		emitEvent(this.onResource, {
			type: RESOURCE_ON_EVENT_TYPE.LOAD,
			resource
		});

	}

	public getResourceById(id: string): IResourceProps {
		return this.app.get("resourceRegistry").getItemList().filter((item) => item.id === id)[0];
	}

	public getResourcesByProperties(conditions: { [K: string]: unknown }): IResourceProps[] {

		return this.app.get("resourceRegistry").getItemList().filter((item) => {
			for (const [name, value] of Object.entries(conditions)) {
				if (item[name] !== value) {
					return false;
				}
			}
			return true;
		});

	}

	public getResourceListByType(docType: string): IResourceProps[] {
		return this.app.get("resourceRegistry").getItemList().filter((item) => item.docType === docType);
	}

	protected async parseResource(resource: IResourceProps): Promise<IResourceProps> {

		this.logger.debug(`Parse resource '${resource?.uri}'.`);

		if (!resource) {
			this.reportError("Can't parse resource. Resource not found.");
			return resource;
		}

		const { uri } = resource;

		if (!resource.parsingDetails.isValidRaw) {
			this.logger.warn("Can't parse resource. Resource not found.");
			this.logger.debug({ uri });
			return resource;
		}

		const { errors } = resource;

		const dCtx = this.createDCtx();
		let model;

		if (errors?.length > 0) {
			this.logAstErrors(dCtx, uri, errors);
		} else {
			resource.parsingDetails.isValidIdt = true;

		}

		try {

			resource = await this.parseModel(resource, dCtx, BlueprintBase);
			model = resource.parsedData.model;
			if (!model) {
				this.logger.warn("Can't parse resource model.");
				this.logger.debug({ uri });
				return resource;
			}

			resource.parsingDetails.isValidModel = true;
			resource = await this.renderSpec(resource);
			const spec = resource.parsedData.spec as TGetBlueprintSchemaSpec<typeof BlueprintBase>;

			if (!spec) {
				this.logger.warn("Can't render resource spec.");
				this.logger.debug({ uri });
				return resource;
			}

			resource.docType = spec.doctype as DOC_TYPES;
			resource.id = spec.id;
			resource.parsedData.spec = spec;
			resource.attributes = spec.attributes;
			resource.metadata = spec.metadata;
			resource.parsingDetails.isValidSpec = true;

			return resource;

		} finally {

			if (model) {
				delete resource.parsedData.model;
				model.schema.destroy(model);
			}

			if (dCtx) {
				dCtx.destroy();
			}

		}

	}

	public resToDesc<T extends IResourceProps>(resource: T): IResourceDescription {

		return {
			id: resource.id,
			name: resource.label,
			resourceType: resource.resourceType as RESOURCE_TYPES,
			basePath: resource.basePath,
			parseData: {
				paramsSchema: resource.parsedData.paramsSchema || {},
				valid: resource.isValid,
				integrationType: resource.parsedData.type as string,
				styles: resource.parsedData?.styles,
			},
			lastModified: resource.changedTime?.getTime(),
			attributes: resource.attributes,
			metadata: this.getResourceMetadata(resource)
		};

	}

	protected getResourceMetadata(resource: IResourceProps): IResourceProps["metadata"] {

		let metadata = resource.metadata;
		if (resource.docType === DOC_TYPES.ASSET_V1) {
			metadata = { assetUrl: (resource as IAssetResourceProps).parsedData.assetUrl, ...metadata };
		}

		return metadata;

	}

	public async getBlueprintMeta<T extends IResourceProps>(resource: T): Promise<IBlueprintMetadata> {

		this.logger.debug(`Get blueprint meta '${resource?.uri}'.`);

		const lock = this.resourceLock.getLock(resource.uri);

		const revisionInfo = await this.adapter.getLastRevision(resource.uri);
		resource.modifiedAt = revisionInfo.modifiedAt;
		resource.modifiedBy = revisionInfo.modifiedBy;
		resource.revision = revisionInfo.revision;

		return {
			id: resource.id,
			name: resource.label,
			resourceType: resource.resourceType as RESOURCE_TYPES,
			basePath: resource.basePath,
			parseData: {
				paramsSchema: resource.parsedData.paramsSchema || {},
				valid: resource.isValid,
				integrationType: resource.parsedData.type as string
			},
			lastModified: resource.changedTime?.getTime(),
			attributes: resource.attributes,
			metadata: this.getResourceMetadata(resource),
			lockedBy: lock?.owner || null,
			lockMetadata: lock?.metadata || {},
			modifiedBy: revisionInfo.modifiedBy,
			modifiedAt: revisionInfo.modifiedAt,
			revision: revisionInfo.revision
		};

	}

	/**
	 * Return resources
	 * @returns
	 */
	public getResources(): IResourceProps[] {
		return this.app.get("resourceRegistry").getItemList();
	}

	protected updateDependencies(): void {

		this.app.get("resourceRegistry").getItemList().forEach((resource) => {

			if (resource.dependencies) {
				resource.dependencies = resource.dependencies.map((dep) => {
					if (dep.id && !dep.uri) {
						const res = this.getResourceById(dep.id);
						if (res) {
							dep.uri = res.uri;
						}
					}
					return dep;
				});
				this.updateResource(resource);
			}
		});

	}

	public async loadResourceFromString(content: string, name: string): Promise<IResourceProps> {

		this.logger.debug(`Load resource from string '${name}'.`);

		const raw = await this.adapter.loadResourceFromString(content, name);
		let resource: IResourceProps;

		if (raw.isValid) {

			resource = this.createResource(raw);
			resource = await this.parseResource(resource);
			const { docType } = resource;

			const resourceType = this.resourceTypes.get(docType);

			if (resourceType) {
				resource = resourceType.setup(resource);
			}

		}

		return resource;

	}

	protected unregisterResource(uri: string): void {
		try {
			this.app.get("resourceRegistry").unregister(uri);
		} catch (error) {
			/** Ignore error. */
		}
	}

	protected async loadResource(raw: IRawResource): Promise<IResourceProps> {

		let resource: IResourceProps;

		try {

			const { uri } = raw;

			if (this.app.get("resourceRegistry").get(uri)) {
				this.logger.debug(`Replace existing resource '${uri}'.`);
				this.unregisterResource(uri);
				this.registerRawResource(raw);
			} else {
				this.registerRawResource(raw);
			}

			if (raw.isValid) {
				resource = this.app.get("resourceRegistry").get(uri);
				resource = await this.parseResource(resource);
			}

			resource = this.app.get("resourceRegistry").get(uri);
			if (!resource) {
				this.reportError("Can't parse resource. Resource wasn't registered.", { uri });
				return resource;
			}

			const { docType, id } = resource;
			const resourceType = this.resourceTypes.get(docType);

			if (!resourceType) {

				const message = "Can't parse unknown resource.";
				this.logger.warn(message);
				this.logger.debug({ docType });

				this.reportParsingErrors({
					getParseErrors: () => ({
						[uri]: [
							{
								range: { start: { line: 0, col: 0 }, end: { line: 0, col: 7 } },
								name: "Unknown resource type",
								message,
								severity: DOC_ERROR_SEVERITY.ERROR,
								metaData: { docType, id, uri }
							} as IDocumentError
						]
					})
				} as DesignContext);

				return resource;

			}

			resource = resourceType.setup(resource);

			return resource;

		} catch (error) {

			this.logger.warn(`Can't load resource '${raw.uri}'.`);
			this.logger.debug({ error: error?.message });

		}
	}

	/**
	 * Clean up resource
	 */
	protected cleanupResource(resource: IResourceProps): IResourceProps {
		delete resource?.idt;
		delete resource?.parsedData?.model;
		delete resource?.parsedData?.spec;
		return resource;
	}

	protected async checkAndReloadResourceList(): Promise<void> {

		this.logger.debug("check resource list");

		const current = this.app.get("resourceRegistry")
			.getItemList()
			.filter((res) => res.docType !== DOC_TYPES.MANIFEST_V1)
			.map((res) => res.uri);

		const actual = (await (await this.adapter.loadResources()).map((res) => res.uri));

		if (current.length === actual.length && current.every((val, idx) => val === actual[idx])) {
			this.logger.debug("resource list synced");
		} else {

			const now = Date.now();
			if (now - this.lastResourceListCheck > CONST.RESOURCE.MANAGER.RELOAD_RESOURCE_LIST_MIN) {
				this.lastResourceListCheck = now;
				this.logger.warn("Resource list isn't synced. Reload.");
				await this.reloadAllResources();
			} else {
				this.logger.warn(`Resource list isn't synced. Can't reload because it was reloaded less than ${CONST.RESOURCE.MANAGER.RELOAD_RESOURCE_LIST_MIN} milliseconds ago.`);
			}

		}

	}

	/**
	 * Loads resources
	 */
	protected async load(): Promise<void> {

		this.logger.info('Loading resources...');
		let rawResources: IRawResource[];

		try {
			rawResources = await this.adapter.loadResources();
		} catch (error) {
			this.throwError("Can't load raw resources.", { error });
			return;
		}

		for (const raw of rawResources) {
			const resource = await this.loadResource(raw);
			this.updateResource(resource);
		}

		for (let resource of this.app.get("resourceRegistry").getItemList()) {
			if (resource.parsingDetails.isRegistered) {

				const resourceType = this.resourceTypes.get(resource.docType);
				resource = await resourceType.scan(resource);
				this.updateResource(resource);

			} else {
				this.logger.debug(`Resource not registered '${resource.uri}'.`);
			}
		}

		for (let resource of this.app.get("resourceRegistry").getItemList()) {
			if (resource.parsingDetails.isRegistered) {

				const resourceType = this.resourceTypes.get(resource.docType);
				resource = await resourceType.parse(resource);

				/** Update manifest value with latest version. */
				if (resource.docType === DOC_TYPES.MANIFEST_V1) {
					this.manifest = resource.parsedData.spec?.spec;
				}

				this.updateResource(resource);

			}
		}

		for (let resource of this.app.get("resourceRegistry").getItemList()) {
			if (resource.parsingDetails.isRegistered) {

				const resourceType = this.resourceTypes.get(resource.docType);

				/** Call after hook. */
				if (resourceType.after) {
					resource = await resourceType.after(resource);
				}

				this.updateResource(resource);

			}
		}

		for (let resource of this.app.get("resourceRegistry").getItemList()) {
			if (resource.parsingDetails.isRegistered) {

				resource = this.cleanupResource(resource);
				this.updateResource(resource);

				emitEvent(this.onResource, {
					type: RESOURCE_ON_EVENT_TYPE.LOAD,
					resource
				});

			}
		}

	}

	public getDependenciesToReload(resource: IResourceProps, eventType?: RESOURCE_ON_EVENT_TYPE): string[] {

		this.logger.debug("Get dependencies to reload.");

		if (!resource) {
			return [];
		}

		const resourceType = this.resourceTypes.get(resource.docType);

		if (resourceType) {
			return resourceType.getDependenciesToReload(resource, eventType);
		}

		return [];

	}

	public async reloadDependencies(dependenciesToReload: string[]): Promise<void> {

		if (!dependenciesToReload) {
			return;
		}

		this.logger.debug("Dependencies to reload:", dependenciesToReload.length);

		for (const uri of dependenciesToReload) {

			/** First unregister dependency */
			this.unregisterResource(uri);
			/** Reload resource */
			const dependency = await this.reloadResourceByUri(uri);

			emitEvent(this.onResource, {
				type: RESOURCE_ON_EVENT_TYPE.RELOAD,
				resource: dependency
			});

		}

	}

	public async reloadResourceBySecretKey(key: string): Promise<IResourceProps> {

		this.logger.info(`Reload resource by secret key: '${key}'.`);

		const resources = this.app.get("resourceRegistry").getItemList()
			.filter((resource) => resource.docType === DOC_TYPES.INTEGRATION_V1) as IIntegrationResourceProps[];

		let integration;
		for (const resource of resources) {
			for (const secretKey of Object.keys(resource.parsedData.exportSecrets || {})) {
				if (secretKey === key) {
					integration = resource;
					break;
				}
			}
		}

		if (integration) {

			this.logger.debug("Resource found, reloading...");
			return this.reloadResourceByUri(integration.uri);

		} else {
			this.logger.debug("Resource not found, nothing to reload.");
		}

		return;

	}

	public async reloadResourceById(id: string): Promise<IResourceProps> {

		this.logger.info(`Reload resource by id: '${id}'.`);

		const resource = this.app.get("resourceRegistry").getItemList().filter((res) => res.id === id)[0];

		if (resource?.uri) {
			return this.reloadResourceByUri(resource.uri);
		}

	}

	public async reloadResourceByUri(uri: string): Promise<IResourceProps> {

		this.logger.info(`Reload resource by uri: '${uri}'.`);

		if (!uri) {
			return;
		}

		let raw: IRawResource;

		try {

			raw = await this.adapter.loadResource(uri);
			let resource = await this.loadResource(raw);
			this.updateResource(resource);

			if (resource.parsingDetails.isRegistered) {

				const resourceType = this.resourceTypes.get(resource.docType);

				if (!resourceType) {
					return;
				}

				resource = await resourceType.scan(resource);
				this.updateResource(resource);

				resource = await resourceType.parse(resource);

				/** Call after hook. */
				if (resourceType.after) {
					resource = await resourceType.after(resource);
				}

				resource = this.cleanupResource(resource);
				this.updateResource(resource);

			}

			return resource;

		} catch (error) {
			this.reportError("Can't re-load resource.", error);
		}

	}

	/**
	 * Updates resource
	 *
	 * @param uri Resource uri
	 * @param resource Resource props
	 */
	protected updateResource(resource: IResourceProps): void {

		if (resource) {
			this.unregisterResource(resource.uri);
			this.app.get("resourceRegistry").register(new Resource(resource));
		}

	}

	public getResourceErrors(): { [K: string]: IResourceErrorReport } {

		const resourcesWithErrors = this.app.get("resourceRegistry").getItemList().filter((resource) =>
			resource.errors?.parseErrors.length > 0
			|| resource.errors?.runtimeErrors.length > 0
			|| resource.errors?.compileErrors.length > 0
		);

		return resourcesWithErrors.reduce((list, resource) => {
			resource.errors.uri = resource.location;
			resource.errors.id = resource.id;
			list[resource.uri] = resource.errors;
			return list;
		}, {});

	}

	protected async loadResources(): Promise<void> {

		this.logger.info("Load resources.");

		delete this.manifest;
		this.app.get("resourceRegistry").flush();

		try {

			await this.loadManifest();
			await this.load();
			this.updateDependencies();

			emitEvent(this.onResource, { type: RESOURCE_ON_EVENT_TYPE.ALL_LOADED });

		} catch (error) {

			this.logger.warn("Failed to load resources");
			this.logger.debug({ error, message: error?.message });
			return;

		} finally {
			if (this.config.logErrorsToConsole) {
				this.config.logErrorsToConsole([]);
			}
		}

	}

	/**
	 * Reloads blueprints
	 */
	public async reloadAllResources(): Promise<void> {

		this.logger.info("Reloading all resources...");

		const start = Date.now();

		try {
			await this.loadResources();
		} catch (error) {
			this.logger.warn("Error during reloading all resources.");
			this.logger.debug({ error: error?.message });
		}

		this.logger.info(`Reloading time: ${Date.now() - start}.`);

	}

	protected createResource(rawResource: IRawResource): IResourceProps {

		return new Resource({
			name: rawResource.uri,
			label: rawResource.label,
			uri: rawResource.uri,
			location: rawResource.location,
			basePath: rawResource.basePath,
			docType: null,
			resourceType: RESOURCE_TYPES.UNKNOWN,
			idt: rawResource.idt,
			parsingDetails: {
				isRegistered: false,
				isValidRaw: rawResource.isValid,
				isValidIdt: false,
				isValidModel: false,
				isValidSpec: false
			},
			changedTime: new Date(),
			dependencies: rawResource.dependencies ? rawResource.dependencies.map((dep) => ({ uri: dep })) : [],
		});

	}

	/**
	 * Registers resource
	 * @param rawResource Raw resource
	 */
	protected registerRawResource(rawResource: IRawResource): IResourceProps {

		const resource = this.createResource(rawResource);
		this.app.get("resourceRegistry").register(resource);
		return resource;

	}

	public createRCtx<TModel extends TGenericModelNode>(modelNode: TModel): RuntimeContext<TModel["schema"]> {

		let constants = {};
		if (this.app.has("constantsManager")) {
			constants = this.app.get("constantsManager").getAllConstants();
		}

		const _app: IAppEnvs = {
			envs: {},
			theme: this.getManifest()?.defaultTheme || { themeId: null, styleName: null }
		};
		if (this.app.has("envVarManager")) {
			_app.envs = this.app.get("envVarManager").getPublicVars();
		}

		const rCtx = new RuntimeContext(
			{
				mode: RUNTIME_CONTEXT_MODE.NORMAL,
				resolvers: this.app.get("resolversRegistry").getAll()
			},
			createRenderFunctionFromModel(modelNode),
			createEmptyScope({ constants, _app })
		);

		return rCtx;

	}

	protected upsertResourceErrors(uri = "unspecified"): IResourceErrorReport {

		const resource = this.app.get("resourceRegistry").get(uri) || {} as IResourceErrorReport;

		return resource?.errors ? resource.errors : resource.errors = {
			type: ERROR_REPORT_TYPE.BLUEPRINT,
			uri: uri,
			id: resource?.id || null,
			parseErrors: [],
			runtimeErrors: [],
			compileErrors: []
		};

	}

	protected reportParsingErrors(dCtx: DesignContext): void {

		const parseErrors = dCtx.getParseErrors();
		for (const uri in parseErrors) {
			const resourceErrors = this.upsertResourceErrors(uri);
			resourceErrors.parseErrors = resourceErrors.parseErrors.concat(parseErrors[uri]);
		}

	}

	protected reportCompileErrors(uri: string, cCtx: CompileContext): void {

		const compileErrors = cCtx.getCompileErrors();

		const docErrors = this.upsertResourceErrors(uri);
		docErrors.compileErrors = docErrors.compileErrors.concat(compileErrors);

	}

	protected reportRuntimeErrors(uri: string, rCtx: RuntimeContext): void {

		const runtimeErrors = rCtx.getRuntimeErrors();
		const docErrors = this.upsertResourceErrors(uri);
		docErrors.runtimeErrors = docErrors.runtimeErrors.concat(runtimeErrors);

	}

	protected async reloadParentDirectory(resource: IResourceProps): Promise<void> {

		if (!resource) {
			return;
		}

		const parentDir = getDirname(resource.uri);

		this.logger.debug(`Reload parent directory '${parentDir}'.`);
		await this.reloadResourceByUri(parentDir);

	}

	public getManifest(): IApplicationManifest {

		const profileId = this.profilesId;

		if (this.manifest?.[profileId]) {
			this.logger.debug(`Get manifest for the profile '${profileId}'.`);
			return this.manifest[profileId];
		}

		this.logger.debug("Get default manifest.");

		if (this.manifest) {
			return {
				...this.manifest[MANIFEST.FALLBACK_APP_ENV_ID],
				routes: getRouteList(this.app.get("resourceRegistry"))
			};
		}

		return null;

	}

	/**
	 * Creates directory
	 *
	 * @override
	 * @param params Params
	 * @returns
	 */
	public async createDir(params: { dirName: string }): Promise<void> {

		this.logger.debug("Create dir", params);

		const raw = await this.adapter.createDir(params);
		const resource = await this.reloadResourceByUri(raw.uri);

		emitEvent(this.onResource, {
			type: RESOURCE_ON_EVENT_TYPE.CREATE,
			resource
		});

	}

	/**
	 * Rename directory
	 *
	 * @override
	 * @param params Params
	 * @returns
	 */
	public async renameDir(params: {
		dirName: string,
		newName: string,
		basePath: string
	}): Promise<void> {

		const { dirName, newName } = params;

		this.logger.debug(`Rename dir '${dirName}' -> ${newName}.`);

		const uri = this.adapter.getDirectoryUriFromPath(dirName);
		const oldResource = this.app.get("resourceRegistry").get(uri) as IDirectoryResourceProps;
		const errorMessage = `Can't rename directory '${dirName}'.`;

		if (!oldResource) {
			this.logger.warn(errorMessage, `Directory '${uri}' not found.`);
			throw new NotFoundError(errorMessage, `Directory '${dirName}' not found.`);
		}

		if (!oldResource.attributes.allowRename) {
			this.logger.warn(errorMessage, `Directory '${uri}' is protected.`);
			throw new ForbiddenProtectedError(errorMessage, `Directory '${dirName}' is protected.`);
		}

		if (this.resourceLock.isLocked(oldResource.dependencies.map((dep) => dep.uri) || [])) {
			this.logger.warn(errorMessage, `One or more nested blueprints is/are locked.`);
			throw new ForbiddenLockedError(errorMessage, "Directory locked");
		}

		const raw = await this.adapter.renameDir(params);

		this.unregisterResource(oldResource.uri);
		/** Unregister all dependencies, because path changed. */
		for (const { uri } of oldResource.dependencies) {
			this.unregisterResource(uri);
		}

		const newResource = await this.reloadResourceByUri(raw.uri);
		await this.reloadDependencies(this.getDependenciesToReload(newResource, RESOURCE_ON_EVENT_TYPE.RENAME));

		this.logger.debug("dir renamed");

		emitEvent(this.onResource, {
			type: RESOURCE_ON_EVENT_TYPE.RENAME,
			resource: newResource
		});

	}

	protected hasRemoveProtectedResource(uriList: string[], recursively = false, counter = 0): boolean {

		if (counter > CONST.RESOURCE.MANAGER.CIRCULAR_DEPENDENCIES_LIMIT) {
			throw new Error("Circular dependency. Can't check if directory has protected resource.");
		}

		if (!uriList || uriList.length === 0) {
			return false;
		}

		for (const uri of uriList) {

			const resource = this.app.get("resourceRegistry").get(uri);

			if (resource.attributes?.allowRemove === false) {
				this.logger.debug(`Resource '${resource.uri}' is remove protected.`);
				return true;
			}

			/** Recursively check all sub-directories. */
			if (
				resource.docType === DOC_TYPES.DICTIONARY_V1
				&& this.hasRemoveProtectedResource(resource.dependencies.map((dep) => dep.uri), recursively, ++counter)
			) {
				return true;
			}

		}

		return false;

	}

	/**
	 * Removes directory
	 *
	 * @override
	 * @param params Params
	 * @returns
	 */
	public async removeDir(params: {
		dirName: string
	}): Promise<void> {

		const { dirName } = params;

		this.logger.debug(`Remove dir '${dirName}'.`);

		const errorMessage = `Can't remove directory '${dirName}'.`;
		const uri = this.adapter.getDirectoryUriFromPath(dirName);

		const resource = this.app.get("resourceRegistry").get(uri) as IDirectoryResourceProps;

		if (!resource) {
			this.logger.warn(errorMessage, `Directory '${uri}' not found.`);
			throw new NotFoundError(errorMessage, `Directory '${dirName}' not found.`);
		}

		if (resource.resourceType !== "directory") {
			this.logger.warn(errorMessage, `Invalid resource type '${resource.resourceType}'.`);
			throw new NotFoundError(errorMessage, `Invalid resource type '${resource.resourceType}'.`);
		}

		if (!resource.attributes.allowRemove) {
			this.logger.warn(errorMessage, `Directory '${uri}' is protected.`);
			throw new ForbiddenProtectedError(errorMessage, `Directory is protected.`);
		}

		if (this.resourceLock.isLocked(resource.dependencies.map((dep) => dep.uri) || [])) {
			this.logger.warn(errorMessage, `One or more nested blueprints is/are locked.`);
			throw new ForbiddenLockedError(errorMessage, "Directory locked");
		}

		if (this.hasRemoveProtectedResource([uri])) {
			this.logger.warn(errorMessage, `Directory contains protected resource(s).`);
			throw new ForbiddenProtectedError(errorMessage, `Directory contains protected resource(s).`);
		}

		await this.adapter.removeDir(params);
		this.unregisterResource(resource.uri);

		resource.dependencies?.map(({ uri }) => {
			try {
				this.unregisterResource(uri);
			} catch (error) {
				// ignore
				this.logger.debug({ error: error?.message });
			}
		});

		emitEvent(this.onResource, {
			type: RESOURCE_ON_EVENT_TYPE.DELETE,
			resource: resource
		});

	}

	public getResourceUriFromPath(name: string, basePath?: string): string {
		return this.adapter.getResourceUriFromPath(name, basePath);
	}

	protected hasPermissions(docType: DOC_TYPES, permissions: RESOURCE_PERMISSIONS[]): boolean {

		this.logger.debug("Check resource permissions", { docType, permissions });

		const resourceType = this.resourceTypes.get(docType);

		if (!resourceType) {
			const errorMessage = "Can't check resource permissions.";
			this.logger.warn(errorMessage, `Invalid docType or unknown resource type '${docType}'.`);
			throw new ConflictError(errorMessage, `Blueprint has an invalid docType or it's unknown resource type.`);
		}

		this.logger.debug("ResourceType permissions", { permissions: resourceType.permissions });

		if (!permissions || permissions.length === 0) {
			return false;
		}

		return permissions.filter((permission) => !resourceType.permissions.includes(permission)).length === 0;

	}

	/**
	 * Creates blueprint
	 *
	 * @param params Params
	 * @returns
	 */
	public async createBlueprint(params: {
		content?: string;
		basePath: string;
		name: string;
		author: string;
	}): Promise<IBlueprintMetadata> {

		const { name, basePath } = params;

		this.logger.debug(`Create blueprint '${name}'.`);

		const uri = this.adapter.getResourceUriFromPath(name, basePath);
		const errorMessage = "Can't create blueprint.";

		if (this.app.get("resourceRegistry").get(uri)) {
			this.logger.warn(errorMessage, `Blueprint '${name}' already exists.`);
			throw new ConflictError(errorMessage, `Blueprint '${name}' already exists.`);
		}

		const preParsedResource = await this.loadResourceFromString(params.content, "");

		if (!this.hasPermissions(preParsedResource.docType, [RESOURCE_PERMISSIONS.CREATE])) {
			const errorDetails = `Resource type '${preParsedResource.docType}' can't be created.`;
			this.logger.warn(errorMessage, errorDetails);
			throw new ForbiddenProtectedError(errorMessage, errorDetails);
		}

		const raw = await this.adapter.createBlueprint(params);
		const resource = await this.reloadResourceByUri(raw.uri);
		await this.reloadDependencies(this.getDependenciesToReload(resource, RESOURCE_ON_EVENT_TYPE.CREATE));

		await this.reloadParentDirectory(resource);

		emitEvent(this.onResource, {
			type: RESOURCE_ON_EVENT_TYPE.CREATE,
			resource
		});

		return this.getBlueprintMeta(resource);

	}

	/**
	 * Creates asset's blueprint
	 *
	 * @param params Params
	 * @returns
	 */
	public async createAssetBlueprint(params: {
		content?: string;
		basePath: string;
		name: string;
		author: string;
	}): Promise<IBlueprintMetadata> {

		const { name, basePath } = params;

		this.logger.debug(`Create asset '${name}'.`);

		const uri = this.adapter.getResourceUriFromPath(name, basePath);
		const errorMessage = "Can't create blueprint.";

		if (this.app.get("resourceRegistry").get(uri)) {
			this.logger.warn(errorMessage, `Blueprint '${name}' already exists.`);
			throw new ConflictError(errorMessage, `Blueprint '${name}' already exists.`);
		}

		const preParsedResource = await this.loadResourceFromString(params.content, "");

		if (preParsedResource.docType !== DOC_TYPES.ASSET_V1) {
			const errorDetails = `Can't create asset. Resource expected to be an '${DOC_TYPES.ASSET_V1}' type.`;
			this.logger.warn(errorMessage, errorDetails);
			throw new ForbiddenProtectedError(errorMessage, errorDetails);
		}

		const raw = await this.adapter.createBlueprint(params);
		const resource = await this.reloadResourceByUri(raw.uri);
		await this.reloadParentDirectory(resource);

		emitEvent(this.onResource, {
			type: RESOURCE_ON_EVENT_TYPE.CREATE,
			resource
		});

		return this.getBlueprintMeta(resource);

	}

	/**
	 * Updates blueprint content
	 *
	 * @param params Params
	 * @returns
	 */
	public async updateBlueprintContent(params: {
		path: string;
		content: string;
		userId: string;
		sourceRevision?: string;
		author?: string;
	}): Promise<IResourceProps> {

		const { path, userId } = params;

		this.logger.debug(`Update blueprint '${path}'.`);

		const uri = this.adapter.getResourceUriFromPath(path);
		const errorMessage = `Can't update blueprint.`;

		const resource = this.app.get("resourceRegistry").get(uri) as IResourceProps;

		if (!resource) {
			this.logger.warn(errorMessage, `Blueprint '${uri}' not found.`);
			throw new NotFoundError(errorMessage, `Blueprint '${path}' not found.`);
		}

		if (!resource.attributes.allowWrite) {
			this.logger.warn(errorMessage, `'${uri}' is protected.`);
			throw new ForbiddenProtectedError(errorMessage);
		}

		if (this.isLocked(resource.uri, userId)) {
			this.logger.warn(errorMessage, `Can't update blueprint content '${resource.uri}'. Blueprint is locked.`)
			throw new ForbiddenLockedError(errorMessage, `Blueprint is locked.`);
		}

		if (!this.hasPermissions(resource.docType, [RESOURCE_PERMISSIONS.UPDATE])) {
			const errorDetails = `Resource type '${resource.docType}' can't be updated.`;
			this.logger.warn(errorMessage, errorDetails);
			throw new ForbiddenProtectedError(errorMessage, errorDetails);
		}

		const preParsedResource = await this.loadResourceFromString(params.content, uri);
		if (preParsedResource && preParsedResource.isValid) {

			// Trying to change resource id
			if (preParsedResource.id !== resource.id) {
				const message = `Blueprint's id '${resource.id}' changed. Resource id can't be modified.`;
				this.logger.warn(errorMessage, message);
				throw new BlueprintConflictError(errorMessage, message);
			}

			// Trying to change metadata
			if (!isDeepEqual(preParsedResource.metadata, resource.metadata)) {
				if (!this.hasPermissions(resource.docType, [RESOURCE_PERMISSIONS.METADATA_UPDATE])) {
					const errorDetails = `Blueprint's metadata changed. Resource '${resource.docType}' metadata can't be modified.`;
					this.logger.warn(errorMessage, errorDetails);
					throw new BlueprintConflictError(errorMessage, errorDetails);
				}
			}

		}

		const raw = await this.adapter.updateBlueprintContent(params);
		this.unregisterResource(resource.uri);
		const newResource = await this.reloadResourceByUri(raw.uri);

		await this.reloadDependencies(this.getDependenciesToReload(newResource, RESOURCE_ON_EVENT_TYPE.UPDATE));

		emitEvent(this.onResource, {
			type: RESOURCE_ON_EVENT_TYPE.UPDATE,
			resource: newResource
		});

		return newResource;

	}

	/**
	 * Updates Manifest content
	 *
	 * @param params
	 * @returns
	 */
	public async updateManifestContent(params: {
		content: string;
		userId: string;
		sourceRevision?: string;
		author?: string;
	}): Promise<IResourceProps> {

		this.logger.debug("Update manifest.");
		const errorMessage = `Can't update manifest.`;

		const { userId } = params;

		const uri = this.adapter.manifestUri;
		const resource = this.app.get("resourceRegistry").get(uri) as IResourceProps;

		if (this.isLocked(resource.uri, userId)) {
			this.logger.warn(errorMessage, `Can't update blueprint content '${resource.uri}'. Blueprint is locked.`)
			throw new ForbiddenLockedError(errorMessage, `Blueprint is locked.`);
		}

		const preParsedResource = await this.loadResourceFromString(params.content, uri);

		if (!preParsedResource.docType) {
			const errorDetails = "Can't parse blueprint docType";
			this.logger.warn(errorMessage, errorDetails);
			throw new UnprocessableEntityError(errorMessage, errorDetails);
		}

		try {

			const raw = await this.adapter.updateManifestContent(params);
			const newResource = await this.reloadResourceByUri(raw.uri);

			emitEvent(this.onResource, {
				type: RESOURCE_ON_EVENT_TYPE.UPDATE,
				resource: newResource
			});

			return newResource;

		} finally {

			/** Emit update event even if manifest is invalid. */
			emitEvent(this.onResource, {
				type: RESOURCE_ON_EVENT_TYPE.UPDATE,
				resource: resource
			});

		}

	}

	/**
	 * Renames blueprint
	 *
	 * @param params Params
	 */
	public async renameBlueprint(params: {
		path: string;
		basePath: string;
		name: string
		author?: string;
		userId?: string;
	}): Promise<IResourceProps> {

		const { path, userId } = params;

		this.logger.debug(`Rename blueprint '${path}'.`);

		const uri = this.adapter.getResourceUriFromPath(path);
		const errorMessage = `Can't rename blueprint.`;

		const resource = this.app.get("resourceRegistry").get(uri) as IResourceProps;

		if (!resource) {
			this.logger.warn(errorMessage, `Blueprint '${uri}' not found.`);
			throw new NotFoundError(errorMessage, `${errorMessage}. Blueprint '${path}' not found.`);
		}

		if (!resource.attributes.allowRemove) {
			this.logger.warn(errorMessage, `'${uri}' is protected.`);
			throw new ForbiddenProtectedError(errorMessage);
		}

		if (this.isLocked(uri, userId)) {
			this.logger.warn(`Can't rename blueprint content '${uri}'. Blueprint is locked.`)
			throw new ForbiddenLockedError("Blueprint locked.", `Can't rename blueprint '${path}'.`);
		}

		if (!this.hasPermissions(resource.docType, [RESOURCE_PERMISSIONS.RENAME])) {
			const errorDetails = `Resource type '${resource.docType}' can't be renamed.`;
			this.logger.warn(errorMessage, errorDetails);
			throw new ForbiddenProtectedError(errorMessage, errorDetails);
		}

		const raw = await this.adapter.renameBlueprint(params);
		const newResource = await this.reloadResourceByUri(raw.uri);

		this.unregisterResource(resource.uri);

		await this.reloadParentDirectory(resource);
		await this.reloadParentDirectory(newResource);

		emitEvent(this.onResource, {
			type: RESOURCE_ON_EVENT_TYPE.RENAME,
			resource: newResource
		});

		return newResource;

	}

	/**
	 * Removes blueprint
	 *
	 * @param params Params
	 */
	public async removeBlueprint(params: {
		path: string;
		userId: string;
		author?: string;
	}): Promise<void> {

		const { path, userId, author } = params;

		this.logger.debug(`Remove blueprint '${path}'.`);

		const uri = this.adapter.getResourceUriFromPath(path);
		const errorMessage = `Can't delete blueprint '${path}'.`;

		const resource = this.app.get("resourceRegistry").get(uri) as IResourceProps;

		if (!resource) {
			this.logger.warn(errorMessage, `Blueprint '${uri}' not found.`);
			throw new NotFoundError(errorMessage, `Blueprint '${path}' not found.`);
		}

		if (this.isLocked(uri, userId)) {
			this.logger.warn(errorMessage, `Blueprint '${uri}' is locked.`)
			throw new ForbiddenLockedError(errorMessage, "Blueprint is locked.");
		}

		if (!resource.attributes.allowRemove) {
			this.logger.warn(errorMessage, `'${uri}' is protected.`);
			throw new ForbiddenProtectedError(errorMessage);
		}

		if (!this.hasPermissions(resource.docType, [RESOURCE_PERMISSIONS.DELETE])) {
			const errorDetails = `Resource type '${resource.docType}' can't be deleted.`;
			this.logger.warn(errorMessage, errorDetails);
			throw new ForbiddenProtectedError(errorMessage, errorDetails);
		}

		if (resource.docType === DOC_TYPES.ASSET_V1 && this.app.has("assetManager")) {
			await this.app.get("assetManager").remove(resource.id, userId, author);
		}

		const dependenciesToReload = this.getDependenciesToReload(resource, RESOURCE_ON_EVENT_TYPE.DELETE);

		await this.adapter.removeBlueprint(params);
		this.unregisterResource(uri);

		emitEvent(this.onResource, {
			type: RESOURCE_ON_EVENT_TYPE.DELETE,
			resource
		});

		await this.reloadDependencies(dependenciesToReload);

		await this.reloadParentDirectory(resource);

	}

	/**
	 * Synchronize repository
	 *
	 * @param overwrite Overwrite local changes
	 */
	public async syncRepository(params: {
		overwrite?: boolean,
		author?: string
	}): Promise<void> {

		this.logger.debug(`Sync repository.`);
		return this.adapter.syncRepository(params);

	}

	/**
	 * Locks resource
	 *
	 * @param params Params
	 */
	public async lockBlueprint(params: {
		path: string,
		userId: string,
		metadata?: TResourceManagerLockMetadata
	}): Promise<void> {

		const { path, userId, metadata } = params;

		this.logger.debug(`Lock blueprint '${path}' by user '${userId}'.`);

		const uri = this.adapter.getResourceUriFromPath(path);
		const errorMessage = `Can't lock blueprint '${path}'.`;
		const resource = this.app.get("resourceRegistry").get(uri);

		if (!resource) {

			this.logger.warn(errorMessage, "Blueprint not found.");
			this.logger.debug({ path, uri });
			throw new NotFoundError(errorMessage, "Blueprint not found.");

		}

		if (!this.hasPermissions(resource.docType, [RESOURCE_PERMISSIONS.LOCK])) {
			const errorDetails = `Resource type '${resource.docType}' can't be locked.`;
			this.logger.warn(errorMessage, errorDetails);
			throw new ForbiddenProtectedError(errorMessage, errorDetails);
		}

		const lock = this.resourceLock.getLock(uri);

		if (lock !== null && lock.owner !== userId) {
			this.logger.warn(errorMessage, "Blueprint is locked.");
			throw new ForbiddenLockedError(errorMessage, "Blueprint is locked.");
		}

		if (this.isBlueprintProtected(resource)) {
			this.logger.warn(errorMessage, "Blueprint is protected.");
			throw new ForbiddenProtectedError(errorMessage, "Blueprint is protected.");
		}

		try {
			this.resourceLock.lock(uri, userId, metadata);
		} catch (error) {
			this.logger.warn(errorMessage);
			this.logger.debug({ error: error?.message });
			throw new Error(errorMessage);
		}

	}

	/**
	 * Unlocks resource
	 *
	 * @param params Params
	 */
	public async unlockBlueprint(params: {
		path: string,
		userId: string
	}): Promise<void> {

		const { path, userId } = params;
		this.logger.debug(`Unlock blueprint '${path}' by user '${userId}'.`);

		const uri = this.adapter.getResourceUriFromPath(path);
		const errorMessage = `Can't unlock blueprint '${path}'.`;
		const lock = this.resourceLock.getLock(uri);

		if (lock !== null && lock.owner !== userId) {
			this.logger.warn(errorMessage, `Blueprint '${uri}' is locked by '${lock.owner}'.`);
			throw new ForbiddenLockedError(errorMessage, `Blueprint locked by other user.`);
		}

		try {
			this.resourceLock.unlock(uri, userId);
		} catch (error) {
			this.logger.warn(errorMessage);
			this.logger.debug({ error: error?.message });
			throw new Error(errorMessage);
		}

	}

	/**
	 * Touch resource
	 *
	 * @param params Params
	 */
	public async touchBlueprint(params: {
		path: string,
		userId: string
	}): Promise<void> {

		const { path, userId } = params;

		this.logger.debug(`Touch blueprint '${path}' by user '${userId}'.`);

		const uri = this.adapter.getResourceUriFromPath(path);
		const errorMessage = `Can't touch blueprint '${path}'.`;

		const resource = this.app.get("resourceRegistry").get(uri);

		if (!this.hasPermissions(resource.docType, [RESOURCE_PERMISSIONS.LOCK])) {
			const errorDetails = `Resource type '${resource.docType}' can't be locked.`;
			this.logger.warn(errorMessage, errorDetails);
			throw new ForbiddenProtectedError(errorMessage, errorDetails);
		}

		const lock = this.resourceLock.getLock(uri);

		if (lock !== null && lock.owner !== userId) {
			this.logger.warn(errorMessage, "Blueprint is locked.");
			throw new ForbiddenLockedError(errorMessage, "Blueprint is locked.");
		}

		try {
			this.resourceLock.touch(uri, userId);
		} catch (error) {

			this.logger.warn(errorMessage, error);
			this.logger.debug({ error: error?.message });
			throw new Error(errorMessage);

		}

	}

	public async makeRelease(params: {
		tag?: string,
		releaseNotes?: string
	}): Promise<string> {

		this.logger.debug(`Make release '${params.tag}'.`);
		return this.adapter.makeRelease(params);

	}

	/**
	 * Returns blueprint content
	 * @param params Params
	 * @returns
	 */
	public async getBlueprintContent(params: {
		path: string
	}): Promise<string> {

		const { path } = params;

		this.logger.debug(`Get blueprint content '${params.path}'.`);

		const uri = this.adapter.getResourceUriFromPath(path);
		const errorMessage = `Can't get blueprint content '${path}'.`;

		const resource = this.app.get("resourceRegistry").get(uri) as IResourceProps;

		if (!this.hasPermissions(resource.docType, [RESOURCE_PERMISSIONS.READ])) {
			const errorDetails = `Resource type '${resource.docType}' can't return content.`;
			this.logger.warn(errorMessage, errorDetails);
			throw new ForbiddenProtectedError(errorMessage, errorDetails);
		}

		if (!resource) {
			this.logger.warn(errorMessage, `Blueprint '${uri}' not found.`);
			throw new NotFoundError(errorMessage, `Blueprint '${path}' not found.`);
		}

		return this.adapter.getBlueprintContent(params);

	}

	/**
	 * Returns manifest content
	 * @param params Params
	 * @returns
	 */
	public async getManifestContentMetadata(): Promise<IBlueprintContentMetadata> {

		this.logger.debug("Get manifest content.");

		const resource = this.app.get("resourceRegistry").get(this.adapter.manifestUri);
		const meta = await this.getBlueprintMeta(resource);
		const content = await this.adapter.getManifestContent();

		return {
			...meta,
			content
		};

	}

	/**
	 * Returns resource
	 * @param params Params
	 * @returns
	 */
	public async getResource(params: { path: string }): Promise<IResourceProps> {

		const { path } = params;

		this.logger.debug(`Get resource '${path}'.`);

		const uri = this.adapter.getResourceUriFromPath(path);
		const errorMessage = `Can't get blueprint metadata '${path}'.`;
		const resource = this.app.get("resourceRegistry").get(uri) as IResourceProps;

		if (!resource) {
			this.logger.warn(errorMessage, `Blueprint '${uri}' not found.`);
			throw new NotFoundError(errorMessage, `Blueprint '${path}' not found.`);
		}

		if (!this.hasPermissions(resource.docType, [RESOURCE_PERMISSIONS.READ])) {
			const errorDetails = `Resource type '${resource.docType}' can't return content.`;
			this.logger.warn(errorMessage, errorDetails);
			throw new ForbiddenProtectedError(errorMessage, errorDetails);
		}

		const lock = this.resourceLock.getLock(resource.uri);
		if (lock) {
			resource.lockedBy = lock.owner;
		}

		return resource;

	}

	/**
	 * Returns blueprint history
	 *
	 * @param path Blueprint path
	 */
	public async getBlueprintHistory(params: { path: string }): Promise<TEditorApiBlueprintHistory> {

		const { path } = params;

		this.logger.debug(`Get blueprint history '${path}'.`);

		const uri = this.adapter.getResourceUriFromPath(path);
		const errorMessage = `Can't get blueprint history '${path}'.`;

		const resource = this.app.get("resourceRegistry").get(uri) as IResourceProps;

		if (!resource) {
			this.logger.warn(errorMessage, `Blueprint '${uri}' not found.`);
			throw new NotFoundError(errorMessage, `Blueprint '${path}' not found.`);
		}

		return this.adapter.getBlueprintHistory(params);

	}

	/**
	 * Returns blueprint's revision
	 *
	 * @param param Params
	 */
	public async getBlueprintRevision(params: { commitSha: string, path: string }): Promise<string> {

		const { path } = params;

		this.logger.debug(`Get blueprint revision '${path}'.`);

		const uri = this.adapter.getResourceUriFromPath(path);
		const errorMessage = `Can't get blueprint revision '${path}'.`;

		const resource = this.app.get("resourceRegistry").get(uri) as IResourceProps;

		if (!resource) {
			this.logger.warn(errorMessage, `Blueprint '${uri}' not found.`);
			throw new NotFoundError(errorMessage, `Blueprint '${path}' not found.`);
		}

		return this.adapter.getBlueprintRevision(params);

	}

	public hasResourceType(name: string): boolean {
		return !!this.resourceTypes.get(name);
	}

	public getResourceType(name: string): TDefaultResourceType {
		return this.resourceTypes.get(name);
	}

	protected isLocked(uri: string, userId: string): boolean {

		const lock = this.resourceLock.getLock(uri);

		if (lock && lock.owner !== userId) {
			return true;
		}

		return false;

	}

	protected isBlueprintProtected(resource: IResourceProps): boolean {

		return resource?.attributes?.allowWrite === false ||
			resource?.attributes?.allowRemove === false ||
			resource?.attributes?.allowRename === false
			? true : false;
	}

	public getStats(): IResourceManagerResourcesStats {

		const {
			blueprintIntegrationsLimit,
			blueprintViewsLimit,
			storage
			// eslint-disable-next-line no-unsafe-optional-chaining
		} = this.app?.appMetadata?.subscription?.tariff || {};

		const stats: IResourceManagerResourcesStats = {
			limits: {
				assetsSizeMb: storage,
				blueprintIntegrationsLimit: blueprintIntegrationsLimit,
				blueprintViewsLimit: blueprintViewsLimit
			},
			amount: {}
		};

		const docTypeStatsNameMapping: { [K: string]: string } = {};
		for (const docType of Object.values(DOC_TYPES)) {
			const resourceType = this.resourceTypes.get(docType);
			if (resourceType) {
				docTypeStatsNameMapping[docType] = resourceType.statsName;
			}
		}

		for (const statsName of Object.values(docTypeStatsNameMapping)) {
			stats.amount[statsName] = 0;
		}

		this.app.get("resourceRegistry").getItemList().reduce((acc, val) => {

			const statsName = docTypeStatsNameMapping[val.docType];

			if (statsName) {
				acc[docTypeStatsNameMapping[val.docType]]++;
			}

			if (val.docType === DOC_TYPES.ASSET_V1) {
				acc.assetsSizeMb += (val as IAssetResourceProps).metadata.assetSize;
			}

			return acc;

		}, stats.amount);

		return stats;

	}

}

export function getDirname(path: string): string {

	if (typeof path !== "string") {
		return "";
	}

	const parts = path.split('/');
	parts.pop();
	const name = parts.join('/');

	return [".", ".."].includes(name) ? "" : name;

}
