/**
 * Field component terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const checkboxField = {
	component: {
		label: "component:checkboxField#label",
		description: "component:checkboxField#description"
	}
};

export const colorField = {
	component: {
		label: "component:colorField#label",
		description: "component:colorField#description"
	}
};

export const datetimeField = {
	component: {
		label: "component:datetimeField#label",
		description: "component:datetimeField#description"
	}
};

export const fileField = {
	component: {
		label: "component:fileField#label",
		description: "component:fileField#description"
	}
};

export const textField = {
	component: {
		label: "component:textField#label",
		description: "component:textField#description"
	}
};

export const textAreaField = {
	component: {
		label: "component:textAreaField#label",
		description: "component:textAreaField#description"
	}
};

export const numberField = {
	component: {
		label: "component:numberField#label",
		description: "component:numberField#description"
	}
};

export const rangeField = {
	component: {
		label: "component:rangeField#label",
		description: "component:rangeField#description"
	}
};

export const optionsField = {
	component: {
		label: "component:optionsField#label",
		description: "component:optionsField#description"
	}
};
