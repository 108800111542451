/**
 * Composed Chart HAE component events
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { termsEditor } from "../../terms";

export const HAEComponentComposedChart_Events = {
	/*dataPointClick: {
		...termsEditor.schemas.composedChart.events.dataPointClick,
		icon: "mdi/gesture-tap"
	},*/

	/*rangeChange: {
		...termsEditor.schemas.composedChart.events.rangeChange,
	}*/
};
