/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	IFunctionResolver, TFunctionDefinitionMap, TGenericFunctionDefinition,
} from "@hexio_io/hae-lib-blueprint";
import { createEventEmitter, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IFunctionDefRegistry } from "../registries/IFunctionDefRegistry";

/**
 * Function Resolver
 */
export class FunctionResolver implements IFunctionResolver {

	/**
	 * Invalidate event - emitted when action list has changed (and param schemas must be re-resolved)
	 */
	public onInvalidate: TSimpleEventEmitter<void>;

	/**
	 * Constructor
	 */
	public constructor(private functionRegistry: IFunctionDefRegistry) {
		this.onInvalidate = createEventEmitter();
	}

	/**
	 * Returns function definition by name
	 */
	public getByName(name: string): TGenericFunctionDefinition {

		return this.functionRegistry.get(name);

	}

	/**
	 * Returns a map of registered functions filtered by allowed categories
	 */
	public getFunctionMap(): TFunctionDefinitionMap {

		return this.functionRegistry.getItems();

	}

}