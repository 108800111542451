/**
 * Icon schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstEnum,
	ISchemaConstObject,
	ISchemaConstString,
	ISchemaIconName,
	ISchemaIconNameOpts,
	ISchemaValue,
	ISchemaValueEnumStringOpts,
	ISchemaValueObjectOpts,
	TSchemaConstObjectProps,
	TSchemaConstObjectPropsSpec,
} from "@hexio_io/hae-lib-blueprint";

import { getValuesFromStringEnum } from "../Functions/enumHelpers";
import { ICON_SIZE_CLASS, ICON_SIZE_CLASS_default } from "../Enums/ICON_SIZE_CLASS";

import { ForegroundColorSchema } from "./Color";
import { termsEditor } from "../terms";
import { ICON_NAME } from "../Enums/ICON_NAME";
import { propGroups } from "./propGroups";

/**
 * Icon props
 */
export const IconProps = {

	source: BP.Prop(IconSourceSchema(), 0, propGroups.__primary__),

	size: BP.Prop(IconSizeSchema(), 10),

	foregroundColor: BP.Prop(ForegroundColorSchema({
		alias: "foregroundColor_icon",
		default: "ICON"
	}), 20)

};

/**
 * Icon props type
 */
export type TIconProps = Partial<TSchemaConstObjectPropsSpec<typeof IconProps>>;

/**
 * Icon schema
 *
 * @param opts Options
 */
export function IconSchema(
	opts: Partial<ISchemaValueObjectOpts<TSchemaConstObjectProps>> = { alias: "icon" }
): ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>> {
	return BP.Object({
		...termsEditor.schemas.icon.schema,
		...opts,
		props: {
			...IconProps,
			...opts.props
		}
	});
}

/**
 * Icon source schema
 *
 * @param opts Options
 */
export function IconSourceSchema(
	opts: Partial<ISchemaIconNameOpts> = { alias: "iconSource" }
): ISchemaIconName {
	return BP.IconName({
		...termsEditor.schemas.icon.source,
		default: null,
		fallbackValue: null,
		...opts,
		editorOptions: {
			layoutType: "noHeader",
			...opts.editorOptions
		}
	});
}

/**
 * Icon size schema
 * 
 * @param opts Options
 */
export function IconSizeSchema(
	opts: Partial<ISchemaValueEnumStringOpts> = { alias: "iconSize" }
): ISchemaValue<ISchemaConstEnum<ISchemaConstString>> {
	return BP.Enum.String({
		...termsEditor.schemas.icon.size,
		options: getValuesFromStringEnum(ICON_SIZE_CLASS, termsEditor.schemas.icon.sizeValues),
		default: ICON_SIZE_CLASS_default,
		...opts
	});
}

/**
 * Icon source fallback value
 */
export const ICON_SOURCE_FALLBACK_VALUE = ICON_NAME.CANCEL;
