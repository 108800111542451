/**
 * Gauge Chart Style enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum GAUGE_CHART_STYLE {
	ANGULAR = "angular",
	SOLID = "solid"
}

export type GAUGE_CHART_STYLE_string = keyof typeof GAUGE_CHART_STYLE;

export const GAUGE_CHART_STYLE_default: GAUGE_CHART_STYLE_string = "ANGULAR";
