/**
 * Overflow enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum OVERFLOW {
	NONE = "none",
	VERTICAL = "vertical",
	HORIZONTAL = "horizontal",
	BOTH = "both",
	VISIBLE = "visible"
}

export type OVERFLOW_string = keyof typeof OVERFLOW;

export const OVERFLOW_default: OVERFLOW_string = "NONE";
