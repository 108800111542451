/**
 * CSS Media
 * Contains media queries as well
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * CSS Media Class enum
 */
export enum CSS_MEDIA_CLASS {
	SCREEN_BASE = "media--screen-base",
	SCREEN_LARGE = "media--screen-large",
	SCREEN_TABLET = "media--screen-tablet",
	SCREEN_PHONE = "media--screen-phone",
	PRINT = "media--print",

	NONE = "media--none"
}

export type CSS_MEDIA_CLASS_string = keyof typeof CSS_MEDIA_CLASS;

/**
 * CSS Media ClassNames
 */
export const CSS_MEDIA_classNames = {
	screenBase: CSS_MEDIA_CLASS.SCREEN_BASE,
	screenLarge: CSS_MEDIA_CLASS.SCREEN_LARGE,
	screenTablet: CSS_MEDIA_CLASS.SCREEN_TABLET,
	screenPhone: CSS_MEDIA_CLASS.SCREEN_PHONE,
	print: CSS_MEDIA_CLASS.PRINT,

	none: CSS_MEDIA_CLASS.NONE
};
