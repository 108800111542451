/**
 * Label schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstObject,
	ISchemaValue,
	ISchemaValueObjectOpts,
	TSchemaConstObjectProps,
	TSchemaConstObjectPropsSpec
} from "@hexio_io/hae-lib-blueprint";
import { termsEditor } from "../terms";
import { IconSchema } from "./Icon";
import { TextSchema } from "./Text";

/**
 * Label props
 */
export const LabelProps = {
	text: BP.Prop(TextSchema(), 0),

	icon: BP.Prop(IconSchema(), 10)
};

/**
 * Label props type
 */
export type TLabelProps = Partial<TSchemaConstObjectPropsSpec<typeof LabelProps>>;

/**
 * Label schema
 *
 * @param opts Options
 */
export function LabelSchema(
	opts: Partial<ISchemaValueObjectOpts<TSchemaConstObjectProps>> = { alias: "label" }
): ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>> {
	return BP.Object({
		...termsEditor.schemas.label.schema,
		...opts,
		props: {
			...LabelProps,
			...opts.props
		}
	});
}
