/**
 * Svg Image React component
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

import SVG from "react-inlinesvg";

/**
 * Svg Image props
 */
export interface ISvgImageProps {
	/** Source */
	source: string;
}

/**
 * Svg Image component
 */
export const SvgImage: React.FunctionComponent<ISvgImageProps> = (props) => {
	return (
		<SVG
			src={props.source}
			cacheRequests={true}
			className="svg-image--ready"
			loader={<img src={props.source} alt="" className="svg-image--loading" />}
		/>
	);
}
