/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	safeLoad,
	YAMLNode,
	Kind,
	LoadOptions,
	YAMLSequence,
	determineScalarType,
	YAMLScalar,
	ScalarType,
	parseYamlBoolean,
	parseYamlFloat,
	parseYamlInteger,
} from "hae-yaml-ast-parser";

import {
	TBlueprintIDTNode,
	IBlueprintIDTScalar,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTList,
	BP_IDT_SCALAR_SUBTYPE
} from "./ISchemaIDT";

/**
 * Parses YAML to IDT
 *
 * @param yamlContents YAML contents
 * @param opts YAML load options
 */
export function parseYAMLToIDT(yamlContents: string, documentUri: string, opts: LoadOptions = {}): {
	idt: TBlueprintIDTNode, ast: YAMLNode
} {

	const ast = safeLoad(yamlContents, opts);
	const idt = convertYamlASTtoIDT(ast, ["$"], documentUri);

	return {
		idt: idt,
		ast: ast
	}

}

/**
 * Parse AST to IDT result
 */
export type TParseAstToIdtResult = { idt: TBlueprintIDTNode, ast: YAMLNode };

/**
 * Parses AST to IDT
 *
 * @param yamlNode YAML AST node
 * @param opts YAML load options
 */
export function parseAstToIDT(yamlNode: YAMLNode): TParseAstToIdtResult {

	const idt = convertYamlASTtoIDT(yamlNode, ["$"]);

	return {
		idt: idt,
		ast: yamlNode
	}

}

/**
 * Converts YAML AST node to IDT
 *
 * @param node Node
 * @param path Path
 * @param lineIndex Line index
 */
function convertYamlASTtoIDT(node: YAMLNode, path: Array<string>, documentUri?: string): TBlueprintIDTNode {

	if (!node) {
		return null;
	}

	switch (node.kind) {

		case Kind.SCALAR: {
			const scalarType = determineScalarType(node as YAMLScalar);

			let value;
			let subType: BP_IDT_SCALAR_SUBTYPE;

			switch (scalarType) {
				case ScalarType.bool:
					value = parseYamlBoolean(node.value);
					subType = BP_IDT_SCALAR_SUBTYPE.BOOLEAN;
					break;
				case ScalarType.float:
					value = parseYamlFloat(node.value);
					subType = BP_IDT_SCALAR_SUBTYPE.FLOAT;
					break;
				case ScalarType.int:
					value = parseYamlInteger(node.value);
					subType = BP_IDT_SCALAR_SUBTYPE.INTEGER;
					break;
				case ScalarType.string:
					value = node.value;
					subType = BP_IDT_SCALAR_SUBTYPE.STRING;
					break;
				case ScalarType.null:
					value = null;
					subType = BP_IDT_SCALAR_SUBTYPE.NULL;
					break;
			}

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: subType,
				path: path,
				parseInfo: {
					loc: {
						uri: node.documentUri || documentUri,
						range: {
							start: node.start,
							end: node.end,
						}
					}
				},
				value: value,
			} as IBlueprintIDTScalar;
		}

		case Kind.MAP: {
			return {
				type: BP_IDT_TYPE.MAP,
				path: path,
				parseInfo: {
					loc: {
						uri: node.documentUri || documentUri,
						range: {
							start: node.start,
							end: node.end,
						}
					}
				},
				items: node.mappings.map((item) => convertYamlASTtoIDT(item, path, documentUri))
			} as IBlueprintIDTMap;
		}

		case Kind.MAPPING: {
			const _keyNode = convertYamlASTtoIDT(node.key, path.concat(["{" + node.key.value + "}"]), documentUri);
			const _valueNode: TBlueprintIDTNode = node.value !== null
				? convertYamlASTtoIDT(node.value, path.concat([node.key.value]), documentUri)
				: {
					type: BP_IDT_TYPE.SCALAR,
					subType: BP_IDT_SCALAR_SUBTYPE.NULL,
					path: path.concat([node.key.value]),
					value: null,
					parseInfo: {
						loc: {
							uri: node.documentUri || documentUri,
							range: {
								start: node.start,
								end: node.end,
							}
						}
					}
				};

			if (_valueNode) {
				_valueNode.parentKeyParseInfo = _keyNode.parseInfo;
			}

			return {
				type: BP_IDT_TYPE.MAP_ELEMENT,
				path: path.concat(["[" + node.key.value + "]"]),
				parseInfo: {
					loc: {
						uri: node.documentUri || documentUri,
						range: {
							start: node.start,
							end: node.end,
						}
					}
				},
				key: _keyNode,
				value: _valueNode
			} as IBlueprintIDTMapElement;
		}

		case Kind.SEQ: {
			return {
				type: BP_IDT_TYPE.LIST,
				path: path,
				parseInfo: {
					loc: {
						uri: node.documentUri || documentUri,
						range: {
							start: node.start,
							end: node.end,
						}
					}
				},
				items: (node as YAMLSequence).items.map(
					(item, index) => convertYamlASTtoIDT(item, path.concat([String(index)]), documentUri)
				)
			} as IBlueprintIDTList;
		}

		default:
			throw new Error(`Unsupported YAML AST node kind: ${node.kind}`);

	}

}