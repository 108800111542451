/**
 * Options Field Type enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum OPTIONS_FIELD_TYPE {
	DEFAULT = "select",
	RADIO_GROUP = "radio-group"
}

export type OPTIONS_FIELD_TYPE_string = keyof typeof OPTIONS_FIELD_TYPE;
