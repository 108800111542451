/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { SchemaConstArray } from "../schemas/const/SchemaConstArray";
import { SchemaConstBoolean } from "../schemas/const/SchemaConstBoolean";
import { SchemaConstDate } from "../schemas/const/SchemaConstDate";
import { SchemaConstEnum } from "../schemas/const/SchemaConstEnum";
import { SchemaConstFloat } from "../schemas/const/SchemaConstFloat";
import { SchemaConstInteger } from "../schemas/const/SchemaConstInteger";
import { SchemaConstMap } from "../schemas/const/SchemaConstMap";
import { SchemaConstObject } from "../schemas/const/SchemaConstObject";
import { SchemaConstString } from "../schemas/const/SchemaConstString";
import { SchemaConstData } from "../schemas/const/SchemaConstData";
import { SchemaConstAny } from "../schemas/const/SchemaConstAny";
import { SchemaConstSecret } from "../schemas/const/SchemaConstSecret";

import { SchemaValue } from "../schemas/value/SchemaValue";
import { SchemaValueArray } from "../schemas/value/SchemaValueArray";
import { SchemaValueBoolean } from "../schemas/value/SchemaValueBoolean";
import { SchemaValueDate } from "../schemas/value/SchemaValueDate";
import { SchemaValueEnumFloat, SchemaValueEnumInteger, SchemaValueEnumString } from "../schemas/value/SchemaValueEnum";
import { SchemaValueFloat } from "../schemas/value/SchemaValueFloat";
import { SchemaValueInteger } from "../schemas/value/SchemaValueInteger";
import { SchemaValueMap } from "../schemas/value/SchemaValueMap";
import { SchemaValueObject } from "../schemas/value/SchemaValueObject";
import { SchemaValueString } from "../schemas/value/SchemaValueString";

import { ValidatorArray } from "../validators/ValidatorArray";
import { ValidatorBoolean } from "../validators/ValidatorBoolean";
import { ValidatorDate } from "../validators/ValidatorDate";
import { ValidatorEnum } from "../validators/ValidatorEnum";
import { ValidatorMap } from "../validators/ValidatorMap";
import { ValidatorNumber } from "../validators/ValidatorNumber";
import { ValidatorObject } from "../validators/ValidatorObject";
import { ValidatorString } from "../validators/ValidatorString";

import { SchemaBuilderAny } from "../schemas/builder/SchemaBuilderAny";
import { SchemaBuilderArray } from "../schemas/builder/SchemaBuilderArray";
import { SchemaBuilderBoolean } from "../schemas/builder/SchemaBuilderBoolean";
import { SchemaBuilderData } from "../schemas/builder/SchemaBuilderData";
import { SchemaBuilderDate } from "../schemas/builder/SchemaBuilderDate";
import { SchemaBuilderEnum } from "../schemas/builder/SchemaBuilderEnum";
import { SchemaBuilderFloat } from "../schemas/builder/SchemaBuilderFloat";
import { SchemaBuilderInteger } from "../schemas/builder/SchemaBuilderInteger";
import { SchemaBuilderMap } from "../schemas/builder/SchemaBuilderMap";
import { SchemaBuilderObject } from "../schemas/builder/SchemaBuilderObject";
import { SchemaBuilderString } from "../schemas/builder/SchemaBuilderString";

import { SchemaExpression } from "../schemas/SchemaExpression";
import { SchemaOptionalGroup } from "../schemas/SchemaOptionalGroup";
import { SchemaOneOf } from "../schemas/SchemaOneOf";
import { SchemaResponsiveValue } from "../schemas/SchemaResponsiveValue";
import { SchemaComponent } from "../schemas/SchemaComponent";
import { SchemaComponentList } from "../schemas/SchemaComponentList";
import { SchemaDataSource } from "../schemas/SchemaDataSource";
import { SchemaDataSourceList } from "../schemas/SchemaDataSourceList";
import { SchemaComments } from "../schemas/SchemaComments";
import { SchemaNamedConfig } from "../schemas/SchemaNamedConfig";
import { SchemaIconName } from "../schemas/SchemaIconName";
import { SchemaActionRef } from "../schemas/SchemaActionRef";
import { SchemaViewRef } from "../schemas/SchemaViewRef";
import { SchemaIntegrationRef } from "../schemas/SchemaIntegrationRef";
import { SchemaRouteRef } from "../schemas/SchemaRouteRef";
import { SchemaRecursive } from "../schemas/SchemaRecursive";
import { SchemaScopedTemplate } from "../schemas/SchemaScopedTemplate";
import { SchemaStringWithConst } from "../schemas/SchemaStringWithConst";
import { SchemaVoid } from "../schemas/SchemaVoid";
import { SchemaConstPassword } from "../schemas/const/SchemaConstPassword";
import { SchemaValuePassword } from "../schemas/value/SchemaValuePassword";
import { SchemaBuilderPassword } from "../schemas/builder/SchemaBuilderPassword";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValidators = (): { [K: string]: any } => {

	return {
		ValidatorArray,
		ValidatorBoolean,
		ValidatorDate,
		ValidatorEnum,
		ValidatorMap,
		ValidatorNumber,
		ValidatorObject,
		ValidatorString,
	};

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSchemas = (): { [K: string]: any } => {

	return {

		/** Const */
		SchemaConstArray,
		SchemaConstBoolean,
		SchemaConstDate,
		SchemaConstEnum,
		SchemaConstFloat,
		SchemaConstInteger,
		SchemaConstMap,
		SchemaConstObject,
		SchemaConstPassword,
		SchemaConstString,
		SchemaConstData,
		SchemaConstAny,
		SchemaConstSecret,

		/** Value */
		SchemaValueArray,
		SchemaValueBoolean,
		SchemaValueDate,
		SchemaValueEnumInteger,
		SchemaValueEnumFloat,
		SchemaValueEnumString,
		SchemaValueFloat,
		SchemaValueInteger,
		SchemaValueMap,
		SchemaValueObject,
		SchemaValuePassword,
		SchemaValueString,

		/** Builder */
		SchemaBuilderAny,
		SchemaBuilderArray,
		SchemaBuilderBoolean,
		SchemaBuilderData,
		SchemaBuilderDate,
		SchemaBuilderEnum,
		SchemaBuilderFloat,
		SchemaBuilderInteger,
		SchemaBuilderMap,
		SchemaBuilderObject,
		SchemaBuilderPassword,
		SchemaBuilderString,

		/** Other */
		SchemaExpression,
		SchemaValue,
		SchemaOneOf,
		SchemaResponsiveValue,
		SchemaOptionalGroup,
		SchemaComponent,
		SchemaComponentList,
		SchemaDataSource,
		SchemaDataSourceList,
		SchemaComments,
		SchemaNamedConfig,
		SchemaIconName,
		SchemaActionRef,
		SchemaViewRef,
		SchemaIntegrationRef,
		SchemaRouteRef,
		SchemaRecursive,
		SchemaScopedTemplate,
		SchemaStringWithConst,
		SchemaVoid

	};

}
