/**
 * File Field Url enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum FILE_FIELD_URL {
	STATIC = "static",
	ACTION = "action"
}

export type FILE_FIELD_URL_string = keyof typeof FILE_FIELD_URL;

export const FILE_FIELD_URL_default: FILE_FIELD_URL_string = "STATIC";
