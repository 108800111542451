/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Terms for Auth
 */
export const authTerms  ={
	authProvider:{
		options:{
			ssoProfileType: {
				label: "blueprint:auth.provider.options.ssoProfileType#label",
				description: "blueprint:auth.provider.options.ssoProfileType#description"
			}
		}
	}
};