/**
 * hae-ext-components-pro
 *
 * Hexio App Engine: Set of pro components
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAppClient, ITranslationManager } from "@hexio_io/hae-lib-core"
import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { TGenericHAEComponentDefinition } from "@hexio_io/hae-lib-components";
import { registerComponents } from "./index";

export const registerExtension = (application: IAppClient): void => {

	const registry = application.get("componentRegistry");
	const translationManager = application.get("translationManager");

	registerComponents(registry, translationManager);

	// Register server/client implementation specific components
	//registry.register(HAEComponentPro);

};

export const registerStandalone = (
	registry: ItemRegistry<TGenericHAEComponentDefinition>,
	translationManager: ITranslationManager
): void => {

	registerComponents(registry, translationManager);

	// Register server/client implementation specific components
	//registry.register(HAEComponentPro);

};