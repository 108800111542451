/**
 * Gauge Chart HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP, ISchemaConstObjectOptsGroup, SCHEMA_CONST_ANY_VALUE_TYPE, SCHEMA_VALUE_TYPE, TSchemaConstObjectPropsSpec
} from "@hexio_io/hae-lib-blueprint";

import {
	propGroups,
	ForegroundColorSchema,
	termsEditor as HAELibComponentsTerms,
	getValuesFromStringEnum,
	PercentageSchema,
	COLOR_MAIN,
	BackgroundColorSchema,
	SPACING,
	dimensionWithConstantPattern,
	dimensionInPixelsOrPercentsWithConstantPattern,
	ENUM_DEFAULT_VALUE,
	dimensionInPixelsOrPercentsPattern,
	dimensionInPixelsWithConstantPattern,
	VERTICAL_ALIGN,
	VERTICAL_ALIGN_opts,
	VERTICAL_ALIGN_bottom,
	getStringEnumValue
} from "@hexio_io/hae-lib-components";
import { isValidObject } from "@hexio_io/hae-lib-shared";
import { GAUGE_CHART_RANGE_TYPE } from "../../Enums/GAUGE_CHART_RANGE_TYPE";
import { GAUGE_CHART_STYLE_default } from "../../Enums/GAUGE_CHART_STYLE";
import { termsEditor } from "../../terms";

/**
 * Gauge Chart props
 */
export const HAEComponentGaugeChart_Props = {

	value: BP.Prop(BP.Float({
		...termsEditor.schemas.gaugeChart.value,
		default: null
	}), 0, propGroups.common),

	min: BP.Prop(BP.Float({
		...termsEditor.schemas.gaugeChart.min,
		default: 0,
		fallbackValue: 0,
		constraints: {
			required: true
		}
	}), 10, propGroups.common),

	max: BP.Prop(BP.Float({
		...termsEditor.schemas.gaugeChart.max,
		default: 100,
		fallbackValue: 100,
		constraints: {
			required: true
		}
	}), 20, propGroups.common),

	startAngle: BP.Prop(BP.Float({
		...termsEditor.schemas.gaugeChart.startAngle,
		default: 180,
		fallbackValue: 180,
		constraints: {
			required: true
		}
	}), 30, propGroups.common),

	endAngle: BP.Prop(BP.Float({
		...termsEditor.schemas.gaugeChart.endAngle,
		default: 360,
		fallbackValue: 360,
		constraints: {
			required: true
		}
	}), 40, propGroups.common),

	ranges: BP.Prop(BP.Array({
		...termsEditor.schemas.gaugeChart.ranges,
		default: [],
		items: BP.Const.Object({
			props: {
				typeData: BP.Prop(BP.OneOf({
					...termsEditor.schemas.gaugeChart.rangeTypeData,
					typeValueOpts: {
						...termsEditor.schemas.gaugeChart.rangeTypeData
					},
					defaultType: ENUM_DEFAULT_VALUE,
					types: {
						"DEFAULT": {
							...termsEditor.schemas.gaugeChart.rangeTypeDataValues.default,
							value: BP.Object({
								props: {
									startValue: BP.Prop(BP.Float({
										...termsEditor.schemas.gaugeChart.rangeStartValue,
										default: 0
									}), 0),
								
									endValue: BP.Prop(BP.Float({
										...termsEditor.schemas.gaugeChart.rangeEndValue,
										default: 100
									}), 10)
								},
								editorOptions: {
									layoutType: "passthrough"
								}
							})
						},
						"NULL": {
							...termsEditor.schemas.gaugeChart.rangeTypeDataValues.null,
							value: BP.Void({})
						}
					}
				}), 0),

				// @todo David & Jirka ScopeTemplate is not updated (isDeepEqual in definition)
				formatter: BP.Prop(BP.OptGroup({
					...termsEditor.schemas.gaugeChart.formatter,
					enabledOpts: {
						default: false,
						fallbackValue: false
					},
					value: BP.ScopedTemplate({
						template: BP.Expression({
							default: "value",
							placeholder: "value"
						})
					})
				}), 10),

				backgroundColor: BP.Prop(BackgroundColorSchema({
					...termsEditor.schemas.gaugeChart.color,
					alias: "backgroundColor_gaugeChartRange",
					default: "PRIMARY",
					constants: getValuesFromStringEnum(COLOR_MAIN, HAELibComponentsTerms.schemas.color.values)
				}), 20),

				foregroundColor: BP.Prop(ForegroundColorSchema({
					alias: "foregroundColor_gaugeChartRange",
					default: "TEXT",
					constants: getValuesFromStringEnum(COLOR_MAIN, HAELibComponentsTerms.schemas.color.values)
				}), 30)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		}),
		getElementModelNodeInfo: (modelNode) => {
			let label = null;

			const { typeData } = modelNode.props;

			if (typeData.type.type === SCHEMA_VALUE_TYPE.CONST) {
				const typeValue = getStringEnumValue(GAUGE_CHART_RANGE_TYPE, typeData.type.constant.value);

				if (typeValue === GAUGE_CHART_RANGE_TYPE.DEFAULT) {
					const typeDataValue = typeData.value[typeData.type.constant.value];

					if (typeDataValue?.type === SCHEMA_VALUE_TYPE.CONST && isValidObject(typeDataValue.constant.props)) {
						const { startValue, endValue } = typeDataValue.constant.props;

						if (
							startValue?.type === SCHEMA_VALUE_TYPE.CONST &&
							endValue?.type === SCHEMA_VALUE_TYPE.CONST
						) {
							label = `${startValue.constant.value}–${endValue.constant.value}`;
						}
					}
				}
				else if (typeValue === GAUGE_CHART_RANGE_TYPE.NULL) {
					label = "null";
				}
			}

			return {
				label,
				icon: null
			};
		}
	}), 50, propGroups.common),

	position: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.gaugeChart.position,
		enabledOpts: {
			default: false,
			fallbackValue: false
		},
		value: BP.Object({
			props: {
				x: BP.Prop(BP.String({
					...termsEditor.schemas.gaugeChart.positionX,
					default: "0%",
					constraints: {
						...HAELibComponentsTerms.schemas.common.spacingConstraints,
						pattern: dimensionInPixelsOrPercentsPattern
					}
				}), 0),

				y: BP.Prop(BP.String({
					...termsEditor.schemas.gaugeChart.positionY,
					default: "0%",
					constraints: {
						...HAELibComponentsTerms.schemas.common.spacingConstraints,
						pattern: dimensionInPixelsOrPercentsPattern
					}
				}), 0)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 60, propGroups.common),

	styleData: BP.Prop(BP.OneOf({
		...termsEditor.schemas.gaugeChart.styleData,
		typeValueOpts: {
			...termsEditor.schemas.gaugeChart.styleData
		},
		defaultType: GAUGE_CHART_STYLE_default,
		types: {
			"ANGULAR": {
				...termsEditor.schemas.gaugeChart.styleDataValues.angular,
				value: BP.Void({})
			},
			"SOLID": {
				...termsEditor.schemas.gaugeChart.styleDataValues.solid,
				value: BP.Object({
					props: {
						backgroundColor: BP.Prop(BackgroundColorSchema({
							...termsEditor.schemas.gaugeChart.color,
							alias: "backgroundColor_gaugeChartSolidStyle",
							default: "PRIMARY",
							constants: getValuesFromStringEnum(COLOR_MAIN, HAELibComponentsTerms.schemas.color.values)
						}), 0),
					},
					editorOptions: {
						layoutType: "passthrough"
					}
				})
			}
		}
	}), 0, propGroups.style),

	backgroundColor: BP.Prop(BackgroundColorSchema({
		alias: "backgroundColor_gaugeChart",
		default: "LIGHT_GRAY",
		constants: getValuesFromStringEnum(COLOR_MAIN, HAELibComponentsTerms.schemas.color.values)
	}), 10, propGroups.style),

	foregroundColor: BP.Prop(ForegroundColorSchema({
		alias: "foregroundColor_gaugeChart",
		default: "TEXT",
		constants: getValuesFromStringEnum(COLOR_MAIN, HAELibComponentsTerms.schemas.color.values)
	}), 20, propGroups.style),

	size: BP.Prop(BP.StringWithConst({
		...termsEditor.schemas.gaugeChart.size,
		constants: getValuesFromStringEnum(SPACING, HAELibComponentsTerms.schemas.common.spacingValues),
		default: "WIDE",
		constraints: {
			...HAELibComponentsTerms.schemas.common.spacingConstraints,
			pattern: dimensionInPixelsOrPercentsWithConstantPattern,
			required: true
		}
	}), 30, propGroups.style),

	hand: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.gaugeChart.hand,
		enabledOpts: {
			default: true,
			fallbackValue: true
		},
		value: BP.Object({
			props: {
				foregroundColor: BP.Prop(ForegroundColorSchema({
					alias: "foregroundColor_gaugeChartHand",
					default: "BLACK",
					constants: getValuesFromStringEnum(COLOR_MAIN, HAELibComponentsTerms.schemas.color.values)
				}), 0),

				offset: BP.Prop(BP.StringWithConst({
					...termsEditor.schemas.gaugeChart.handOffset,
					constants: getValuesFromStringEnum(SPACING, HAELibComponentsTerms.schemas.common.spacingValues),
					default: "NONE",
					constraints: {
						...HAELibComponentsTerms.schemas.common.spacingConstraints,
						pattern: dimensionInPixelsOrPercentsWithConstantPattern
					}
				}), 10),

				innerRadius: BP.Prop(BP.String({
					...termsEditor.schemas.gaugeChart.handInnerRadius,
					default: "",
					constraints: {
						...HAELibComponentsTerms.schemas.common.spacingConstraints,
						pattern: dimensionInPixelsOrPercentsPattern
					}
				}), 20),

				size: BP.Prop(BP.StringWithConst({
					...termsEditor.schemas.gaugeChart.handSize,
					constants: getValuesFromStringEnum(SPACING, HAELibComponentsTerms.schemas.common.spacingValues),
					default: "NARROW",
					constraints: {
						...HAELibComponentsTerms.schemas.common.spacingConstraints,
						pattern: dimensionInPixelsWithConstantPattern
					}
				}), 30)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 40, propGroups.style),

	ticks: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.gaugeChart.ticks,
		enabledOpts: {
			default: true,
			fallbackValue: true
		},
		value: BP.Object({
			props: {
				values: BP.Prop(BP.Boolean({
					...termsEditor.schemas.gaugeChart.ticksValues,
					default: true
				}), 0),

				lines: BP.Prop(BP.Boolean({
					...termsEditor.schemas.gaugeChart.ticksLines,
					default: true
				}), 10)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 50, propGroups.style),

	label: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.gaugeChart.label,
		enabledOpts: {
			default: true,
			fallbackValue: true
		},
		value: BP.Object({
			props: {
				verticalAlign: BP.Prop(BP.Enum.String({
					...HAELibComponentsTerms.schemas.common.verticalAlign,
					options: getValuesFromStringEnum(
						{
							TOP: VERTICAL_ALIGN.TOP,
							MIDDLE: VERTICAL_ALIGN.MIDDLE,
							BOTTOM: VERTICAL_ALIGN.BOTTOM
						},
						HAELibComponentsTerms.schemas.common.verticalAlignValues,
						VERTICAL_ALIGN_opts
					),
					default: VERTICAL_ALIGN_bottom,
					editorOptions: {
						controlType: "buttons"
					},
				}), 0),

				formatter: BP.Prop(BP.OptGroup({
					...termsEditor.schemas.gaugeChart.formatter,
					enabledOpts: {
						default: false,
						fallbackValue: false
					},
					value: BP.ScopedTemplate({
						template: BP.Expression({
							default: "value",
							placeholder: "value"
						})
					})
				}), 10)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 60, propGroups.style)

	/*grid: BP.Prop(BP.Boolean({
		...termsEditor.schemas.composedChart.axisGrid,
		default: true,
		fallbackValue: true,
		constraints: {
			required: true
		}
	}), )*/

};

/**
 * Gauge Chart props type
 */
export type THAEComponentGaugeChart_Props = Partial<TSchemaConstObjectPropsSpec<typeof HAEComponentGaugeChart_Props>>;
