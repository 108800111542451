/**
 * QR Code Error Correction Level enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum QR_CODE_ERROR_CORRECTION_LEVEL {
	L = "L",
	M = "M",
	Q = "Q",
	H = "H"
}

export type QR_CODE_ERROR_CORRECTION_LEVEL_string = keyof typeof QR_CODE_ERROR_CORRECTION_LEVEL;

export const QR_CODE_ERROR_CORRECTION_LEVEL_default: QR_CODE_ERROR_CORRECTION_LEVEL_string = "M";
