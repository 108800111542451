/**
 * Label HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useCallback, useMemo } from "react";

import {
	Type,
	defineElementaryComponent,
	BP,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	createSubScope,
} from "@hexio_io/hae-lib-blueprint";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	ClassList,
	propGroups,
	IconSourceSchema,
	BackgroundColorSchema,
	ForegroundColorSchema,
	Icon
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";

interface HAEComponentPixelGrid_State { }

const GridIconItemSchema_props = {
	title: BP.Prop(BP.String({
		...termsEditor.components.pixelGrid.schema.itemMappingItemTitle
	})),
	value: BP.Prop(BP.Any({
		...termsEditor.components.pixelGrid.schema.itemMappingItemValue,
		defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER,
		allowedTypes: {
			[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
			[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true,
			[SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN]: true
		}
	})),
	iconColor: BP.Prop(ForegroundColorSchema({
		...termsEditor.components.pixelGrid.schema.itemMappingItemIconColor,
		default: "black",
		fallbackValue: "black",
	})),
	icon: BP.Prop(IconSourceSchema())
};

const GridItemSchema_props = {
	...GridIconItemSchema_props,
	backgroundColor: BP.Prop(BackgroundColorSchema({
		...termsEditor.components.pixelGrid.schema.itemMappingItemBackgroundColor,
		default: "transparent",
		fallbackValue: "transparent",
	}))
};

const HAEComponentPixelGrid_Props = {

	data: BP.Prop(BP.Array({
		...termsEditor.components.pixelGrid.schema.data,
		items: BP.Array({
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER,
				allowedTypes: {
					[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
					[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true,
					[SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN]: true
				}
			})
		})
	}), 10, propGroups.common),

	iconData: BP.Prop(BP.Array({
		...termsEditor.components.pixelGrid.schema.iconData,
		items: BP.Array({
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER,
				allowedTypes: {
					[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
					[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true,
					[SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN]: true
				}
			})
		})
	}), 12, propGroups.common),

	itemSize: BP.Prop(BP.Integer({
		...termsEditor.components.pixelGrid.schema.itemSize,
		constraints: {
			required: true
		},
		default: 5,
		fallbackValue: 5
	}), 20, propGroups.common),

	itemMapping: BP.Prop(BP.Array({
		...termsEditor.components.pixelGrid.schema.itemMapping,
		constraints: {
			required: true
		},
		items: BP.Object({
			...termsEditor.components.pixelGrid.schema.itemMappingItem,
			props: GridItemSchema_props
		}),
		getElementModelNodeInfo: (modelNode) => {
			return {
				label: modelNode.constant?.props.title.constant?.value ?? null,
				icon: "mdi/arrow-right"
			}
		}
	}), 30, propGroups.common),

	iconMapping: BP.Prop(BP.Array({
		...termsEditor.components.pixelGrid.schema.iconMapping,
		constraints: {
			required: false
		},
		items: BP.Object({
			...termsEditor.components.pixelGrid.schema.itemMappingItem,
			props: GridIconItemSchema_props
		}),
		getElementModelNodeInfo: (modelNode) => {
			return {
				label: modelNode.constant?.props.title.constant?.value ?? null,
				icon: "mdi/arrow-right"
			}
		}
	}), 31, propGroups.common),

	defaultItem: BP.Prop(BP.Object({
		...termsEditor.components.pixelGrid.schema.itemMappingDefault,
		props: {
			title: GridItemSchema_props.title,
			backgroundColor: GridItemSchema_props.backgroundColor,
			iconColor: GridItemSchema_props.iconColor,
			icon: GridItemSchema_props.icon
		}
	}), 50, propGroups.common),

	selection: BP.Prop(BP.Array({
		...termsEditor.components.pixelGrid.schema.selection,
		constraints: {
			required: false
		},
		items: BP.Object({
			...termsEditor.components.pixelGrid.schema.selectionItem,
			props: {
				x: BP.Prop(BP.Integer({
					...termsEditor.components.pixelGrid.schema.selectionItemX,
					constraints: {
						required: true
					}
				})),
				y: BP.Prop(BP.Integer({
					...termsEditor.components.pixelGrid.schema.selectionItemX,
					constraints: {
						required: true
					}
				}))
			}
		})
	}), 50, propGroups.common)
};

const HAEComponentPixelGrid_Events = {
	onSelect: {
		...termsEditor.components.pixelGrid.events.onSelect,
		icon: "mdi/gesture-tap"
	}
};

const HAEComponentPixelGrid_Definition = defineElementaryComponent<
	typeof HAEComponentPixelGrid_Props,
	HAEComponentPixelGrid_State,
	typeof HAEComponentPixelGrid_Events
>({

	...termsEditor.components.pixelGrid.component,

	name: "pixelGrid",

	category: "charts",

	icon: "mdi/dots-grid",

	docUrl: "...",

	order: 100,

	props: HAEComponentPixelGrid_Props,

	events: HAEComponentPixelGrid_Events,

	resolve: (_spec, state) => {
		return state || {};
	},

	getScopeData: (_spec, _state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}

});

const HAEComponentPixelGrid_React: THAEComponentReact<typeof HAEComponentPixelGrid_Definition> = ({
	props, componentInstance, reactComponentClassList
}) => {
	const { data, iconData, itemSize, itemMapping, iconMapping, defaultItem, selection } = props;

	const itemMapperFn = useCallback((value, iconValue) => {
		let item;

		if (itemMapping) {
			for (let i = 0; i < itemMapping.length; i++) {
				if (itemMapping[i].value === value) {
					item = itemMapping[i];
					break;
				}
			}
		}

		if (iconMapping !== undefined && iconMapping !== null && item !== undefined && iconValue !== undefined) {
			for (let i = 0; i < iconMapping.length; i++) {
				if (iconMapping[i].value === iconValue) {
					item.icon = iconMapping[i].icon;
					item.iconColor = iconMapping[i].iconColor;
				}
			}
		}

		if (item !== undefined) {
			return item;
		}

		return defaultItem;
	}, [itemMapping, iconMapping, defaultItem]);

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"cmp-pixel-grid", componentInstance.safePath, { componentClassList: reactComponentClassList }
	);

	classList.addModifiers({
		"can-select": componentInstance.eventEnabled.onSelect
	});

	const content = useMemo(() => {

		const handleSelect = (x: number, y: number, value: number) => {
			componentInstance.eventTriggers.onSelect((parentScope) => createSubScope(parentScope, {
				selection: {
					x: x,
					y: y,
					value: value
				}
			}, {
				selection: Type.Object({
					props: {
						x: Type.Integer({}),
						y: Type.Integer({}),
						value: Type.Any({})
					}
				})
			}))
		};

		const selectionMap = new Map<string, boolean>();

		if (selection instanceof Array) {
			for (let j = 0; j < selection.length; j++) {
				selectionMap.set(`${selection[j].x}:${selection[j].y}`, true);
			}
		}

		const _items: Array<React.ReactElement> = [];
		let gridWidth = 0;
		let gridHeight = 0;

		const itemClassName = "cmp-pixel-grid__item";

		if (data instanceof Array) {
			for (let ri = 0; ri < data.length; ri++) {
				const row = data[ri];

				if (ri > gridHeight) {
					gridHeight = ri;
				}

				if (row instanceof Array) {
					for (let ci = 0; ci < row.length; ci++) {

						const background = row[ci];
						let icon;

						if (iconData !== undefined && iconData !== null && iconData instanceof Array) {
							if (iconData.length > 0 && ri < iconData.length) {
								const iconRow = iconData[ri];
								if (iconRow && iconRow.length > 0 && ci < iconRow.length) {
									icon = iconRow[ci];
								}
							}
						}

						const cellStyle = itemMapperFn(background, icon);

						if (ci > gridWidth) {
							gridWidth = ci;
						}

						const onItemSelect = componentInstance.eventEnabled.onSelect
							? () => handleSelect(ci, ri, row[ci])
							: null

						let _className = itemClassName;

						if (selectionMap.has(`${ci}:${ri}`)) {
							_className += " " + itemClassName + "--selected";
						}

						_items.push(
							<span
								className={_className}
								title={cellStyle.title}
								style={{
									left: (ci * itemSize) + "px",
									top: (ri * itemSize) + "px",
									width: (itemSize) + "px",
									height: (itemSize) + "px",
									background: cellStyle.backgroundColor
								}}
								onClick={onItemSelect}
							>
								{
									cellStyle.icon
										? <Icon
											source={cellStyle.icon}
											componentPath={componentInstance.safePath.concat([String(ri), String(ci)])}
											componentMode={componentInstance.componentMode}
											foregroundColor={cellStyle.iconColor}
										/>
										: null
								}
							</span>
						);
					}
				}
			}
		}

		return <div
			className={"cmp-pixel-grid__container"}
			style={{
				width: ((gridWidth + 1) * itemSize) + "px",
				height: ((gridHeight + 1) * itemSize) + "px"
			}}
		>
			{_items}
		</div>;

	}, [componentInstance.rev]);

	return (
		<div className={classList.toClassName()}>
			{content}
		</div>
	);
};

export const HAEComponentPixelGrid: THAEComponentDefinition<typeof HAEComponentPixelGrid_Definition> = {
	...HAEComponentPixelGrid_Definition,
	reactComponent: HAEComponentPixelGrid_React
};