/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstSecretOpts, SchemaConstSecret, ISchemaConstSecret } from "../const/SchemaConstSecret";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueSecretOpts extends ISchemaValueOpts<ISchemaConstSecret>, ISchemaConstSecretOpts {}

/**
 * Schema: Secret value
 *
 * @param opts Schema options
 */
export function SchemaValueSecret(opts: ISchemaValueSecretOpts): ISchemaValue<ISchemaConstSecret> {

	return SchemaValue(SchemaConstSecret(opts), opts);

}
