/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IDocumentPosition } from "../Shared/IDocumentPosition";

export enum NODE_KIND {
	COMMENT = "comment",
	NULL_LITERAL = "null",
	BOOLEAN_LITERAL = "booleanLiteral",
	STRING_LITERAL = "stringLiteral",
	NUMBER_LITERAL = "numberLiteral",
	STRING_TEMPLATE = "stringTemplate",
	IDENTIFIER = "identifier",
	VALUE_REF = "valueRef",
	INDEX_ACCESSOR = "indexAccessor",
	ARGUMENT_LIST = "argumentList",
	FUNCTION_CALL = "functionCall",
	OPERATOR = "operator",
	PREFIX_OPERATOR = "prefixOperator",
	LIST = "list",
	OBJECT = "object",
	OBJECT_PROPERTY = "objectProperty"
}

export enum OPERATOR_TYPE {
	ART_PLUS = "artPlus",
	ART_MINUS = "artMinus",
	ART_MULTIPLY = "artMultiply",
	ART_DIVIDE = "artDivide",
	ART_MODULO = "artModulo",
	CMP_LOWER_THAN_EQL = "cmpLte",
	CMP_LOWER_THAN = "cmpLt",
	CMP_GREATER_THAN_EQL = "cmpGte",
	CMP_GREATER_THAN = "cmpGt",
	CMP_EQUAL = "cmpEq",
	CMP_NOT_EQUAL = "cmpNeq",
	LOGIC_OR = "logicOr",
	LOGIC_AND = "logicAnd",
	STR_CONCAT = "strConcat"
}

export enum PREFIX_OPERATOR_TYPE {
	NOT = "not",
	ART_PLUS = "artPlus",
	ART_MINUS = "artMinus",
}

export interface IExpAstPosition extends IDocumentPosition {
	offset: number;
}

export interface IExpAstRange {
	start: IExpAstPosition;
	end: IExpAstPosition;
}

export interface IExpAst_Node {
	kind: NODE_KIND;
	range: IExpAstRange;
	comment?: IExpAst_Comment;
}

export interface IExpAst_Comment extends IExpAst_Node {
	kind: NODE_KIND.COMMENT;
	value: string;
}

export interface IExpAst_StringLiteral extends IExpAst_Node {
	kind: NODE_KIND.STRING_LITERAL;
	value: string;
	metaDataId?: number;
}

export interface IExpAst_NumberLiteral extends IExpAst_Node {
	kind: NODE_KIND.NUMBER_LITERAL;
	value: number;
	metaDataId?: number;
}

export interface IExpAst_StringTemplate extends IExpAst_Node {
	kind: NODE_KIND.STRING_TEMPLATE;
	elements: Array<TExpAst_Expression>;
	metaDataId?: number;
}

export interface IExpAst_BooleanLiteral extends IExpAst_Node {
	kind: NODE_KIND.BOOLEAN_LITERAL;
	value: boolean;
	metaDataId?: number;
}

export interface IExpAst_NullLiteral extends IExpAst_Node {
	kind: NODE_KIND.NULL_LITERAL;
	metaDataId?: number;
}

export interface IExpAst_Identifier extends IExpAst_Node {
	kind: NODE_KIND.IDENTIFIER;
	value: string;
}

export interface IExpAst_ValueReference extends IExpAst_Node {
	kind: NODE_KIND.VALUE_REF;
	identifier: IExpAst_Identifier;
	metaDataId?: number;
}

export interface IExpAst_IndexAccessor extends IExpAst_Node {
	kind: NODE_KIND.INDEX_ACCESSOR;
	baseReference: TExpAst_Expression;
	index: TExpAst_Expression|IExpAst_Identifier;
	metaDataId?: number;
}

export interface IExpAst_ArgumentList extends IExpAst_Node {
	kind: NODE_KIND.ARGUMENT_LIST;
	arguments: TExpAst_Expression[];
}

export interface IExpAst_FunctionCall extends IExpAst_Node {
	kind: NODE_KIND.FUNCTION_CALL;
	reference: TExpAst_Expression;
	argList: IExpAst_ArgumentList;
	metaDataId?: number;
}

export interface IExpAst_Operator extends IExpAst_Node {
	kind: NODE_KIND.OPERATOR;
	type: OPERATOR_TYPE;
	left: TExpAst_Expression;
	right: TExpAst_Expression;
	opRange: IExpAstRange;
	metaDataId?: number;
}

export interface IExpAst_PrefixOperator extends IExpAst_Node {
	kind: NODE_KIND.PREFIX_OPERATOR;
	type: PREFIX_OPERATOR_TYPE;
	right: TExpAst_Expression;
	opRange: IExpAstRange;
	metaDataId?: number;
}

export interface IExpAst_List extends IExpAst_Node {
	kind: NODE_KIND.LIST;
	elements: TExpAst_Expression[];
	metaDataId?: number;
}

export interface IExpAst_ObjectProperty extends IExpAst_Node {
	kind: NODE_KIND.OBJECT_PROPERTY;
	key: TExpAst_Expression|IExpAst_Identifier; // Beware: can be null
	value: TExpAst_Expression; // Beware: can be null
	spread: boolean;
}

export interface IExpAst_Object extends IExpAst_Node {
	kind: NODE_KIND.OBJECT;
	properties: IExpAst_ObjectProperty[];
	metaDataId?: number;
}

export type TExpAst_Expression =
	IExpAst_NullLiteral|
	IExpAst_StringLiteral|
	IExpAst_NumberLiteral|
	IExpAst_BooleanLiteral|
	IExpAst_StringTemplate|
	IExpAst_ValueReference|
	IExpAst_IndexAccessor|
	IExpAst_FunctionCall|
	IExpAst_Operator |
	IExpAst_PrefixOperator |
	IExpAst_List |
	IExpAst_Object;

export type TExpAstNode = 
	IExpAst_NullLiteral|
	IExpAst_StringLiteral|
	IExpAst_NumberLiteral|
	IExpAst_BooleanLiteral|
	IExpAst_StringTemplate|
	IExpAst_ValueReference|
	IExpAst_IndexAccessor|
	IExpAst_FunctionCall|
	IExpAst_FunctionCall |
	IExpAst_Operator |
	IExpAst_PrefixOperator |
	IExpAst_Identifier |
	IExpAst_ArgumentList |
	IExpAst_List |
	IExpAst_ObjectProperty |
	IExpAst_Object;