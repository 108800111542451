/**
 * Property Item component runtime terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const propertyItem = {
	copy: {
		label: "component:propertyItem.copy#label",
		message: "component:propertyItem.copy#message"
	},
	showValue: {
		label: "component:propertyItem.showValue#label"
	},
	hideValue: {
		label: "component:propertyItem.hideValue#label"
	}
};
