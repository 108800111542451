/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	DESIGN_CONTEXT_READ_MODE,
	TGetBlueprintSchemaSpec,
	applyCodeArg,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	RUNTIME_CONTEXT_MODE,
	TSchemaComponentEvents
} from "@hexio_io/hae-lib-blueprint";
import { createBlueprintSchema } from "./BlueprintBase";

import { termsEditor } from "../terms";
import { DOC_TYPES } from "./DocTypes";
import { ContainerContentSchema, propGroups } from "@hexio_io/hae-lib-components";
import { CoreComponentEventNodeTypes } from "../ui/EventNodeTypes";

const BlueprintView_Inner = BP.Const.Object({
	label: termsEditor.blueprints.view.root.label,
	constraints: {
		required: true
	},
	props: {
		// Title
		title: BP.Prop(BP.Conditional({
			condition: (dCtx) => dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL,
			value: BP.String({
				label: termsEditor.blueprints.view.title.label,
				description: termsEditor.blueprints.view.title.description,
				default: null,
				fallbackValue: null
			})
		}), 10, propGroups.common),
		// Description
		description: BP.Prop(BP.Conditional({
			condition: (dCtx) => dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL,
			value: BP.String({
				label: termsEditor.blueprints.view.description.label,
				description: termsEditor.blueprints.view.description.description,
				default: null,
				fallbackValue: null
			})
		}), 20, propGroups.common),
		// Require auth user
		requireAuthenticatedUser: BP.Prop(BP.Const.Boolean({
			label: termsEditor.blueprints.view.requireAuthenticatedUser.label,
			description: termsEditor.blueprints.view.requireAuthenticatedUser.description,
			default: true,
			fallbackValue: true,
		}), 30, propGroups.common),
		// Auth condition
		authCondition: BP.Prop(BP.Conditional({
			condition: (dCtx) => dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL,
			value: BP.Boolean({
				label: termsEditor.blueprints.view.authCondition.label,
				description: termsEditor.blueprints.view.authCondition.description,
				default: true,
				fallbackValue: false
			})
		}), 40, propGroups.common),
		// View params
		params: BP.Prop(BP.Builder.Object({
			label: termsEditor.blueprints.view.params.label,
			description: termsEditor.blueprints.view.params.description,
			constantOnly: false
		}), 50, propGroups.common),
		// Data
		dataSources: BP.Prop(BP.Conditional({
			condition: (dCtx) => dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL,
			value: BP.DataSourceList({
				label: termsEditor.blueprints.view.dataSources.label,
				description: termsEditor.blueprints.view.dataSources.description
			})
		})),
		// Content components
		content: BP.Prop(BP.Conditional({
			condition: (dCtx) => dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL,
			value: ContainerContentSchema({
				label: termsEditor.blueprints.view.content.label,
				description: termsEditor.blueprints.view.content.description,
			})
		})),
		// Outlets
		outlets: BP.Prop(BP.Conditional({
			condition: (dCtx) => dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL,
			value: BP.Map({
				label: termsEditor.blueprints.view.outlets.root.label,
				description: termsEditor.blueprints.view.outlets.root.description,
				keyOpts: {
					label: termsEditor.blueprints.view.outlets.propName.label,
					description: termsEditor.blueprints.view.outlets.propName.description,
					placeholder: termsEditor.blueprints.view.outlets.propName.placeholder,
				},
				value: BP.Any({
					label: termsEditor.blueprints.view.outlets.value.label,
					description: termsEditor.blueprints.view.outlets.value.description,
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
				}),
				fallbackValue: {}
			})
		})),
		// Events
		events: BP.Prop(BP.Conditional({
			condition: (dCtx) => dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL,
			value: BP.Const.Object({
				label: termsEditor.blueprints.view.events.root.label,
				props: {
					onInit: BP.Prop(BP.Const.Object({
						label: termsEditor.blueprints.view.events.onInit.label,
						description: termsEditor.blueprints.view.events.onInit.description,
						icon: "mdi/file-refresh",
						alias: "componentEvent",
						props: {
							nodes: BP.Prop(BP.ScopedTemplate({
								template: BP.Special.FlowNodeList({
									label: "Event flow",
									nodeTypes: CoreComponentEventNodeTypes,
									entryNode: {
										id: "eventStart",
										type: "eventStart",
										defaultPosition: {
											x: 0,
											y: 0
										},
										defaultOpts: {}
									}
								})
							}))
						}
					}), 10),
					onParamsChanged: BP.Prop(BP.Const.Object({
						label: termsEditor.blueprints.view.events.onParamsChanged.label,
						description: termsEditor.blueprints.view.events.onParamsChanged.description,
						icon: "mdi/table-refresh",
						alias: "componentEvent",
						props: {
							nodes: BP.Prop(BP.ScopedTemplate({
								template: BP.Special.FlowNodeList({
									label: "Event flow",
									nodeTypes: CoreComponentEventNodeTypes,
									entryNode: {
										id: "eventStart",
										type: "eventStart",
										defaultPosition: {
											x: 0,
											y: 0
										},
										defaultOpts: {}
									}
								})
							}))
						}
					}), 20)
				}
			}) as unknown as TSchemaComponentEvents
		}), 70, propGroups.common),
		// Re-init in params change
		reInitOnParamsChanged: BP.Prop(BP.Const.Boolean({
			label: termsEditor.blueprints.view.reInitOnParamsChanged.label,
			description: termsEditor.blueprints.view.reInitOnParamsChanged.description,
			default: false,
			fallbackValue: false,
		}), 60, propGroups.common),
	}
});

export type TBlueprintViewSpec = TGetBlueprintSchemaSpec<typeof BlueprintView_Inner> & {
	/** If a view access was authorized */
	isAuthorized: boolean;
};

/**
 * Integration blueprint "spec" property schema
 * 
 * Developer note: Must be wrapped into Runtime Wrapper so we can render content only when auth pass.
 */
export const BlueprintViewSpec = BP.Special.RWP<
	typeof BlueprintView_Inner,
	TBlueprintViewSpec
>({
	value: BlueprintView_Inner,

	// Custom render wrapper
	render: (rCtx, modelNode, path, scope, prevSpec) => {

		let dataSources;
		let content;
		let outlets;
		let events;

		const params = modelNode.props.params
			? modelNode.props.params.schema.render(rCtx, modelNode.props.params, path.concat(["params"]), scope, prevSpec?.params)
			: null;

		const title = modelNode.props.title
			? modelNode.props.title.schema.render(rCtx, modelNode.props.title, path.concat(["title"]), scope, prevSpec?.title)
			: null;

		const description = modelNode.props.description
			// eslint-disable-next-line max-len
			? modelNode.props.description.schema.render(rCtx, modelNode.props.description, path.concat(["description"]), scope, prevSpec?.description)
			: null;

		const requireAuthenticatedUser = modelNode.props.requireAuthenticatedUser
			// eslint-disable-next-line max-len
			? modelNode.props.requireAuthenticatedUser.schema.render(rCtx, modelNode.props.requireAuthenticatedUser, path.concat(["requireAuthenticatedUser"]), scope, prevSpec?.requireAuthenticatedUser)
			: null;

		const reInitOnParamsChanged = modelNode.props.reInitOnParamsChanged
			// eslint-disable-next-line max-len
			? modelNode.props.reInitOnParamsChanged.schema.render(rCtx, modelNode.props.reInitOnParamsChanged, path.concat(["reInitOnParamsChanged"]), scope, prevSpec?.reInitOnParamsChanged)
			: null;

		const authCondition = modelNode.props.authCondition
			// eslint-disable-next-line max-len
			? modelNode.props.authCondition.schema.render(rCtx, modelNode.props.authCondition, path.concat(["authCondition"]), scope, prevSpec?.authCondition)
			: null;

		const isAuthorized = (
			(!requireAuthenticatedUser || (requireAuthenticatedUser && scope.globalData["currentUser"])) && authCondition
		);

		if (
			isAuthorized || rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR
		) {
			// eslint-disable-next-line max-len
			content = modelNode.props.content.schema.render(rCtx, modelNode.props.content, path.concat(["content"]), scope, prevSpec?.content);

			// eslint-disable-next-line max-len
			dataSources = modelNode.props.dataSources.schema.render(rCtx, modelNode.props.dataSources, path.concat(["dataSources"]), scope, prevSpec?.dataSources);

			// eslint-disable-next-line max-len
			outlets = modelNode.props.outlets.schema.render(rCtx, modelNode.props.outlets, path.concat(["outlets"]), scope, prevSpec?.outlets);

			// eslint-disable-next-line max-len
			events = modelNode.props.events.schema.render(rCtx, modelNode.props.events, path.concat(["events"]), scope, prevSpec?.events);
		} else {
			dataSources = null;
			content = null;
			outlets = null;
		}

		return {
			params: params,
			title: title,
			description: description,
			requireAuthenticatedUser: requireAuthenticatedUser,
			authCondition: authCondition,
			dataSources: dataSources,
			content: content,
			outlets: outlets,
			isAuthorized: isAuthorized,
			events: events,
			reInitOnParamsChanged: reInitOnParamsChanged
		};

	},

	// Custom compile render wrapper
	compileRender: (cCtx, modelNode, path) => {

		const params = modelNode.props.params
			? modelNode.props.params.schema.compileRender(cCtx, modelNode.props.params, path.concat(["params"]))
			: null;

		const title = modelNode.props.title
			? modelNode.props.title.schema.compileRender(cCtx, modelNode.props.title, path.concat(["title"]))
			: null;

		const description = modelNode.props.description
			// eslint-disable-next-line max-len
			? modelNode.props.description.schema.compileRender(cCtx, modelNode.props.description, path.concat(["description"]))
			: null;

		const requireAuthenticatedUser = modelNode.props.requireAuthenticatedUser
			// eslint-disable-next-line max-len
			? modelNode.props.requireAuthenticatedUser.schema.compileRender(cCtx, modelNode.props.requireAuthenticatedUser, path.concat(["requireAuthenticatedUser"]))
			: null;

		const authCondition = modelNode.props.authCondition
			// eslint-disable-next-line max-len
			? modelNode.props.authCondition.schema.compileRender(cCtx, modelNode.props.authCondition, path.concat(["authCondition"]))
			: null;	

		const dataSources = modelNode.props.dataSources
			? modelNode.props.dataSources.schema.compileRender(cCtx, modelNode.props.dataSources, path.concat(["dataSources"]))
			: null;

		const content = modelNode.props.content
			? modelNode.props.content.schema.compileRender(cCtx, modelNode.props.content, path.concat(["content"]))
			: null;

		const outlets = modelNode.props.outlets
			? modelNode.props.outlets.schema.compileRender(cCtx, modelNode.props.outlets, path.concat(["outlets"]))
			: null;

		const events = modelNode.props.events
			? modelNode.props.events.schema.compileRender(cCtx, modelNode.props.events, path.concat(["events"]))
			: null;

		const reInitOnParamsChanged = modelNode.props.reInitOnParamsChanged
			// eslint-disable-next-line max-len
			? modelNode.props.reInitOnParamsChanged.schema.compileRender(cCtx, modelNode.props.reInitOnParamsChanged, path.concat(["reInitOnParamsChanged"]))
			: null;

		return {
			isScoped: true,
			code: `(s,pv,pt)=>{${[
				/* eslint-disable max-len, indent */
				`let _dataSources;`,
				`let _content;`,
				`let _outlets;`,
				`let _events;`,
				`let _isAuthorized=false;`,
				`const _params=${params ? applyCodeArg(params, `pv&&typeof pv==="object"?pv.params:undefined`, `pt.concat(["params"])`) : `null`};`,
				`const _title=${title ? applyCodeArg(title, `pv&&typeof pv==="object"?pv.title:undefined`, `pt.concat(["title"])`) : `null`};`,
				`const _description=${description ? applyCodeArg(description, `pv&&typeof pv==="object"?pv.description:undefined`, `pt.concat(["description"])`) : `null`};`,
				`const _requireAuthenticatedUser=${requireAuthenticatedUser ? applyCodeArg(requireAuthenticatedUser, `pv&&typeof pv==="object"?pv.requireAuthenticatedUser:undefined`, `pt.concat(["requireAuthenticatedUser"])`) : `null`};`,
				`const _authCondition=${authCondition ? applyCodeArg(authCondition, `pv&&typeof pv==="object"?pv.authCondition:undefined`, `pt.concat(["authCondition"])`) : `null`};`,
				`const _reInitOnParamsChanged=${reInitOnParamsChanged ? applyCodeArg(reInitOnParamsChanged, `pv&&typeof pv==="object"?pv.reInitOnParamsChanged:undefined`, `pt.concat(["reInitOnParamsChanged"])`) : `null`};`,
				`if((!_requireAuthenticatedUser||(_requireAuthenticatedUser&&s.globalData["currentUser"]))&&_authCondition){`,
					`_content=${content ? applyCodeArg(content, `pv&&typeof pv==="object"?pv.content:undefined`, `pt.concat(["content"])`) : `null`};`,
					`_dataSources=${dataSources ? applyCodeArg(dataSources, `pv&&typeof pv==="object"?pv.dataSources:undefined`, `pt.concat(["dataSources"])`) : `null`};`,
					`_outlets=${outlets ? applyCodeArg(outlets, `pv&&typeof pv==="object"?pv.outlets:undefined`, `pt.concat(["outlets"])`) : `null`};`,
					`_events=${events ? applyCodeArg(events, `pv&&typeof pv==="object"?pv.events:undefined`, `pt.concat(["events"])`) : `null`};`,
					`_isAuthorized=true;`,
				`}`,
				`return {params:_params,title:_title,description:_description,requireAuthenticatedUser:_requireAuthenticatedUser,authCondition:_authCondition,dataSources:_dataSources,content:_content,outlets:_outlets,events:_events,reInitOnParamsChanged:_reInitOnParamsChanged,isAuthorized:_isAuthorized}`
				/* eslint-enable max-len, indent */
			].join("")}}`
		}

	},

	// Custom type descriptor wrapper
	getTypeDescriptor: (modelNode) => {
		return modelNode.schema.getTypeDescriptor(modelNode);
	}
});

export type TBlueprintViewSpecSchema = typeof BlueprintViewSpec;

/**
 * View Blueprint Schema
 */
export const BlueprintView = createBlueprintSchema(DOC_TYPES.VIEW_V1, BlueprintViewSpec)

export type TBlueprintViewSchema = typeof BlueprintView;