/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Export types
 */
export enum ExportTypes {
	NAN = "NaN",
	DATE = "Date",
	SCHEMA = "Schema",
	VALIDATOR = "Validator"
}

export interface ISchemaImportExport {
	_type: string;
	_name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	_values: any[];
}