/**
 * Data Table HAE component CSV Export
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	COMPONENT_MODE,
	TComponentNodePath
} from "@hexio_io/hae-lib-blueprint";

import { IResolvedItem, TResolvedItemValue } from "./state";
import { isValidValue } from "@hexio_io/hae-lib-shared";
import { ClassList, ICON_NAME, Label, useTranslate } from "@hexio_io/hae-lib-components";

import { termsRuntime } from "../../terms";

/**
 * Data Table CSV Export props
 */
export interface IDataTableCsvExportProps {
	/** Resolved items */
	items: IResolvedItem[];

	/** File name */
	fileName: string;

	/** Include header */
	header: string[];

	/** Component path */
	componentPath: TComponentNodePath;

	/** Component mode */
	componentMode: COMPONENT_MODE;
}

/**
 * Data Table CSV Export component
 */
export const DataTableCsvExport: React.FunctionComponent<IDataTableCsvExportProps> = (props) => {
	const { items, fileName, header, componentPath, componentMode } = props;

	const elementRef = React.useRef<HTMLAnchorElement>();

	const [ content, setContent ] = React.useState("#");
	const ready = content !== "#";

	const t = useTranslate();

	let _linkClickHandler: (event: React.MouseEvent<HTMLAnchorElement>) => void;

	if (!ready) {
		_linkClickHandler = (event) => {
			event.preventDefault();

			if (componentMode !== COMPONENT_MODE.NORMAL) {
				return;
			}

			let newCsvContent = "data:text/csv;charset=utf-8,";
			let lines = items.map((item) => Object.values(item.data).map((valueItem) => valueItem.value));

			if (header) {
				lines = [ header, ...lines ];
			}

			newCsvContent += lines.map((lineItem) => {
				return lineItem.map((item) => {
					if (isValidValue(item)) {
						let itemString = `${item}`;

						if (itemString.includes(",")) {
							itemString = `"${item}"`;
						}

						return itemString;
					}

					return "";
				}).join(",");
			}).join("\r\n");

			setContent(encodeURI(newCsvContent));
		}
	}

	React.useEffect(() => {
		if (ready && elementRef.current) {
			elementRef.current.click();
		}
	}, [ ready ]);

	React.useEffect(() => {
		if (ready) {
			setContent("#");
		}
	}, [ items ]);

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"button", componentPath, { componentMode }
	);

	classList.addModifiers({
		type: "default",
		style: "clear",
		enabled: true
	});

	return (
		<a
			ref={elementRef}
			href={content}
			download={ready ? `${fileName}.csv` : undefined}
			className={classList.toClassName()}
			onClick={_linkClickHandler}
		>
			<Label
				text={{ value: t("runtime", termsRuntime.components.dataTable.export.label) }}
				icon={{ source: ICON_NAME.FILE_EXPORT }}
				componentPath={[ ...componentPath, "button-label" ]}
				componentMode={componentMode}
			/>
		</a>
	);
}
