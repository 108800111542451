/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import { isBrowser } from "./isBrowser";

/**
 * Function to encode string to base64.
 * Must work with node and browser.
 *
 * @param value
 */
export function toBase64(value: string): string {
	if (isBrowser()) {
		return btoa(value);
	}

	return Buffer?.from(value).toString("base64");
}

/**
 * Function to decode string from base64.
 * Must work with node and browser.
 *
 * @param value
 */
export function fromBase64<ResultType extends string = string>(value: string): ResultType {
	if (isBrowser()) {
		return atob(value) as ResultType;
	}

	return Buffer?.from(value, "base64").toString("ascii") as ResultType;
}
