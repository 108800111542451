/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";
import {
	ISchemaConstPassword,
	ISchemaConstPasswordOpts,
	SchemaConstPassword
} from "../const/SchemaConstPassword";

export interface ISchemaValuePasswordOpts
	extends ISchemaValueOpts<ISchemaConstPassword>,
		ISchemaConstPasswordOpts {}

/**
 * Schema: Password value
 *
 * @param opts Schema options
 */
export function SchemaValuePassword(opts: ISchemaValuePasswordOpts): ISchemaValue<ISchemaConstPassword> {

	return SchemaValue(SchemaConstPassword(opts), opts);

}
