/**
* Hexio App Engine Core library.
*
* @package hae-lib-core
* @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
* @license Commercial
*
* See LICENSE file distributed with this source code for more information.
*/

import { BP } from "@hexio_io/hae-lib-blueprint";
import { createBlueprintSchema } from "./BlueprintBase";
import { DOC_TYPES } from "./DocTypes";

/**
 * Integration blueprint "spec" property schema
 */
export const BlueprintIntegrationSpec = BP.NamedConfig({
	constraints: {
		required: true
	},
	resolverName: "integrationParams"
});

export type TBlueprintIntegrationSpecSchema = typeof BlueprintIntegrationSpec;

/**
 * Integration Blueprint Schema
 */
export const BlueprintIntegration = createBlueprintSchema(DOC_TYPES.INTEGRATION_V1, BlueprintIntegrationSpec);

export type TBlueprintIntegrationSchema = typeof BlueprintIntegration;
