/**
 * QR Code schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const qrCode = {
	valueData: {
		label: "schema:qrCode.valueData#label",
		description: "schema:qrCode.valueData#description"
	},
	valueDataValues: {
		text: {
			label: "schema:qrCode.valueData#values.text#label"
		},
		link: {
			label: "schema:qrCode.valueData#values.link#label"
		}
	},
	errorCorrectionLevel: {
		label: "schema:qrCode.errorCorrectionLevel#label",
		description: "schema:qrCode.errorCorrectionLevel#description"
	}
};
