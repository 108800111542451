/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstEnumOpts, SchemaConstEnum, ISchemaConstEnum, TSchemaConstEnumValueSchema } from "../const/SchemaConstEnum";
import { ISchemaConstFloatOpts, SchemaConstFloat } from "../const/SchemaConstFloat";
import { ISchemaConstIntegerOpts, SchemaConstInteger, ISchemaConstInteger } from "../const/SchemaConstInteger";
import { ISchemaConstStringOpts, SchemaConstString, ISchemaConstString } from "../const/SchemaConstString";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

type TSchemaValueIntegerBase = ISchemaConstInteger;
type TSchemaValueFloatBase = ISchemaConstInteger;
type TSchemaValueStringBase = ISchemaConstString;

/**
 * Schema: Generic Enum
 *
 * @param opts Schema options
 */
export function SchemaValueGenericEnum<
	TValueSchema extends TSchemaConstEnumValueSchema
>(opts: ISchemaConstEnumOpts<TValueSchema>): ISchemaValue<ISchemaConstEnum<TValueSchema>> {

	return SchemaValue(SchemaConstEnum({
		...opts
	}), opts);

}

/**
 * Integer-based enum value schema options
 */
export interface ISchemaValueEnumIntegerOpts extends
	ISchemaValueOpts<ISchemaConstEnum<TSchemaValueIntegerBase>>,
	Omit<ISchemaConstEnumOpts<TSchemaValueIntegerBase>,"value"> {
		/** Default value for a new node */
		default?: number;
		/** Options for a value schema */
		valueOpts?: ISchemaConstIntegerOpts
	}

/**
 * Schema: Integer-based Enum
 *
 * @param opts Schema options
 */
export function SchemaValueEnumInteger(opts: ISchemaValueEnumIntegerOpts): ISchemaValue<ISchemaConstEnum<TSchemaValueIntegerBase>> {

	return SchemaValue(SchemaConstEnum({
		value: SchemaConstInteger({
			default: opts.default,
			...opts.valueOpts || {}
		}),
		...opts
	}), opts);

}

/**
 * Float-based enum value schema options
 */
export interface ISchemaValueEnumFloatOpts extends
	ISchemaValueOpts<ISchemaConstEnum<TSchemaValueFloatBase>>,
	Omit<ISchemaConstEnumOpts<TSchemaValueFloatBase>,"value"> {
		/** Default value for a new node */
		default?: number;
		/** Options for a value schema */
		valueOpts?: ISchemaConstFloatOpts
	}

/**
 * Schema: Float-based Enum
 *
 * @param opts Schema options
 */
export function SchemaValueEnumFloat(opts: ISchemaValueEnumFloatOpts): ISchemaValue<ISchemaConstEnum<TSchemaValueFloatBase>> {

	return SchemaValue(SchemaConstEnum({
		value: SchemaConstFloat({
			default: opts.default,
			...opts.valueOpts || {}
		}),
		...opts
	}), opts);

}

/**
 * String-based enum value schema options
 */
export interface ISchemaValueEnumStringOpts extends
	ISchemaValueOpts<ISchemaConstEnum<TSchemaValueStringBase>>,
	Omit<ISchemaConstEnumOpts<TSchemaValueStringBase>,"value"> {
		/** Default value for a new node */
		default?: string;
		/** Options for a value schema */
		valueOpts?: ISchemaConstStringOpts;
	}

/**
 * Schema: String-based Enum
 *
 * @param opts Schema options
 */
export function SchemaValueEnumString(opts: ISchemaValueEnumStringOpts): ISchemaValue<ISchemaConstEnum<TSchemaValueStringBase>> {

	return SchemaValue(SchemaConstEnum({
		value: SchemaConstString({
			default: opts.default,
			...opts.valueOpts || {}
		}),
		...opts
	}), opts);

}