import { AppClient } from "@hexio_io/hae-core/src/client";

import { registerExtension as ExtIntegrationsBase } from "@hexio_io/hae-ext-integrations-base/src/client";
import { registerExtension as ExtFunctionsBase } from "@hexio_io/hae-ext-functions-base/src/client";
import { registerExtension as ExtComponentsBase } from "@hexio_io/hae-ext-components-base/src/client";
import { registerExtension as ExtComponentsPro } from "@hexio_io/hae-ext-components-pro/src/client";
// import { registerExtension as ExtComponentsTest } from "@hexio_io/hae-ext-components-test/src/client";
import { registerExtension as ExtIconsMdi } from "@hexio_io/hae-ext-icons-mdi/src/client";
import { registerRuntimeTranslations as RuntimeTranslations } from "@hexio_io/hae-translations/src/runtime";

import "./main.scss";
//import "./custom.scss";

declare global {
	interface Window {
		APP_DEBUG: boolean;
		/* @todo !!! temporary !!! */
		__appInstance: AppClient;
	}
}

if (location.hash === "#__debug") {
	window.APP_DEBUG = true;
}
//window.APP_DEBUG = true;

const app = new AppClient();
window.__appInstance = app;

app.useExtension(ExtIntegrationsBase);
app.useExtension(ExtFunctionsBase);
app.useExtension(ExtComponentsBase);
app.useExtension(ExtComponentsPro);
app.useExtension(ExtIconsMdi);

app.useTranslations(RuntimeTranslations);

app.init();