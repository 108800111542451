/**
 * Field Label Info component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

/**
 * Field Label Info props
 */
export interface IFieldLabelInfoProps {
	/** Field required */
	required: boolean;
}

/**
 * Field Label Info component
 */
export const FieldLabelInfo: React.FunctionComponent<IFieldLabelInfoProps> = (props) => {
	return props.required ?
		<span className="field-label__info field-label__info--required">{"*"}</span> :
		null;
}
