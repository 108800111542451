/**
 * Translations for core HAE terms.
 *
 * @package hae-translations
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ITranslationManager } from "@hexio_io/hae-lib-core";

import runtime_en_US from "../translations/runtime/runtime_en_US.json";
import runtime_cs_CZ from "../translations/runtime/runtime_cs_CZ.json";

/**
 * Registers System translations
 *
 * @param translationManager Translation Manager Instance
 */
export function registerRuntimeTranslations(translationManager: ITranslationManager) {
	
	translationManager.registerTranslations("runtime", "en_US", runtime_en_US, "hae-translations");
	translationManager.registerTranslations("runtime", "cs_CZ", runtime_cs_CZ, "hae-translations");

}