/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { parse as parsePath } from "path-to-regexp";

import { exportSchema } from "../ExportImportSchema/ExportSchema";
import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar,
	TBlueprintIDTNodePath
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaChildNode,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	applyRuntimeValidators,
	assignParentToModelProps,
	compileRuntimeValidators,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	validateDefaultValue
} from "../Schema/SchemaHelpers";
import { applyCodeArg } from "../Context/CompileUtil";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import {
	extractAndValidateIDTMapProperties,
	provideIDTMapRootCompletions,
	validateIDTNode
} from "../Context/ParseUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { TypeDescObject } from "../Shared/ITypeDescriptor";
import { ValidatorObject } from "../validators/ValidatorObject";
import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";
import { ISchemaValue, SCHEMA_VALUE_TYPE } from "./value/SchemaValue";
import { ISchemaConstString } from "./const/SchemaConstString";
import { ISchemaConstObject, Prop, TSchemaConstObjectProps } from "./const/SchemaConstObject";
import { IRouteListEntry, IRouteResolver } from "../Resolvers/IRouteResolver";
import { ISchemaConstMap } from "./const/SchemaConstMap";
import { ISchemaConstAny, SCHEMA_CONST_ANY_VALUE_TYPE } from "./const/SchemaConstAny";
import { SchemaValueString } from "./value/SchemaValueString";
import { SchemaValueMap } from "./value/SchemaValueMap";
import { SchemaValueAny } from "./value/SchemaValueAny";
import { SchemaValueObject } from "./value/SchemaValueObject";
import { ISchemaDynamic, SchemaDynamic } from "./SchemaDynamic";
import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";
import { IDocumentLocation } from "../Shared/IDocumentLocation";
import { ISchemaConstBoolean } from "./const/SchemaConstBoolean";
import { SchemaValueBoolean } from "./value/SchemaValueBoolean";

type TSchemaRouteNameSchema = ISchemaValue<ISchemaConstString>;
type TSchemaRouteResolvedParamsSchema = ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>>;
type TSchemaRouteGenericParamsSchema = ISchemaValue<ISchemaConstMap<ISchemaValue<ISchemaConstAny>>>;
type TSchemaRouteQueryParamsSchema = ISchemaValue<ISchemaConstMap<ISchemaValue<ISchemaConstString>>>;
type TSchemaRouteResolvedQueryParamsSchema = ISchemaValue<ISchemaConstMap<ISchemaValue<ISchemaConstString>>>;
type TSchemaRouteUnionParamsSchema = TSchemaRouteResolvedParamsSchema | TSchemaRouteGenericParamsSchema;
type TSchemaQueryUnionParamsSchema = TSchemaRouteQueryParamsSchema | TSchemaRouteResolvedQueryParamsSchema;
type TSchemaSerializeNullSchema = ISchemaValue<ISchemaConstBoolean>;

type TSchemaRouteParamsSchema = ISchemaDynamic<IParamsResolveParams, TSchemaRouteUnionParamsSchema>;

type TSchemaQueryParamsSchema = ISchemaDynamic<IParamsResolveParams, TSchemaQueryUnionParamsSchema>;

interface IParamsResolveParams {
	routeName: string;
}

/**
 * Schema model
 */
export interface ISchemaRouteRefModel extends IModelNode<ISchemaRouteRef> {
	/** Route name */
	routeName: TGetBlueprintSchemaModel<TSchemaRouteNameSchema>;
	/** Route params - can be null! */
	params: TGetBlueprintSchemaModel<TSchemaRouteParamsSchema>;
	/** Route params - can be null! */
	queryParams: TGetBlueprintSchemaModel<TSchemaQueryParamsSchema>;
	/** Route serialize null params */
	serializeNullParams: TGetBlueprintSchemaModel<TSchemaSerializeNullSchema>;
	/** Change event handler bound to model */
	__changeHandler: () => void;
	/** Resolver invalidate event bound to model */
	__invalidateHandler: () => void;
	/** If the route name constant change event has been bound */
	__routeNameConstantChangeEventBound: boolean;
	/** Last available route name - for ref counting */
	__lastRouteName: string;
}

/**
 * Schema options
 */
export interface ISchemaRouteRefOpts extends IBlueprintSchemaOpts {
	/** Validation constraints */
	constraints?: {
		/** If required */
		required?: boolean;
	};
}

/**
 * Default value
 */
export interface ISchemaRouteRefDefault {
	/** Route name */
	name: TGetBlueprintSchemaDefault<TSchemaRouteNameSchema>;
	/** Route params - can be null */
	params: TGetBlueprintSchemaDefault<TSchemaRouteParamsSchema>;
	/** Route params - can be null */
	queryParams: TGetBlueprintSchemaDefault<TSchemaQueryParamsSchema>;
	serializeNullParams: TGetBlueprintSchemaDefault<TSchemaSerializeNullSchema>;
}

/**
 * Schema spec
 */
export interface ISchemaRouteRefSpec {
	/** Route name */
	name: TGetBlueprintSchemaSpec<TSchemaRouteNameSchema>;

	/** Route params - can be null! */
	params: TGetBlueprintSchemaSpec<TSchemaRouteParamsSchema>;

	/** Route params - can be null! */
	queryParams: TGetBlueprintSchemaSpec<TSchemaQueryParamsSchema>;

	serializeNullParams: TGetBlueprintSchemaSpec<TSchemaSerializeNullSchema>;
}

/**
 * Schema type
 */
export interface ISchemaRouteRef
	extends IBlueprintSchema<
		ISchemaRouteRefOpts,
		ISchemaRouteRefModel,
		ISchemaRouteRefSpec,
		ISchemaRouteRefDefault
	> {
	/**
	 * Returns a list of available configuration names
	 */
	getRouteList: (modelNode: ISchemaRouteRefModel) => IRouteListEntry[];
}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaRouteRef(opts: ISchemaRouteRefOpts): ISchemaRouteRef {
	type TRouteNameModel = TGetBlueprintSchemaModel<TSchemaRouteNameSchema>;
	type TParamsModel = TGetBlueprintSchemaModel<TSchemaRouteParamsSchema>;
	type TQueryParamsModel = TGetBlueprintSchemaModel<TSchemaQueryParamsSchema>;
	type TSerializeNullParamsModel = TGetBlueprintSchemaModel<TSchemaSerializeNullSchema>;

	const rootValidator = ValidatorObject({ required: opts.constraints?.required });

	const resolvedParamsCache = new Map();

	const getResolvedParamsSchema = (
		resolver: IRouteResolver,
		params: IParamsResolveParams
	): TSchemaRouteResolvedParamsSchema => {
		if (params.routeName) {
			const route = resolver.getRouteByName(params.routeName);

			if (!route) {
				throw new Error(`Unknown route name '${params.routeName}'.`);
			}

			if (resolvedParamsCache.has(route.path)) {
				return resolvedParamsCache.get(route.path);
			}

			// Parse path params
			const paramTokens = parsePath(route.path);
			const props: TSchemaConstObjectProps = {};

			for (let i = 0; i < paramTokens.length; i++) {
				const token = paramTokens[i];

				if (token instanceof Object && token.name) {
					props[token.name] = Prop(
						SchemaValueString({
							label: String(token.name),
							constraints: {
								required: true
							},
							allowTranslate: false,
							fallbackValue: ""
						})
					);
				}
			}

			const retSchema = SchemaValueObject({
				label: "Route Parameters",
				constraints: {
					required: true
				},
				props: props
			});

			resolvedParamsCache.set(route.path, retSchema);
			return retSchema;
		} else {
			return null;
		}
	};

	const resolvedQueryParamsCache = new Map();

	const getResolvedQueryParamsSchema = (
		resolver: IRouteResolver,
		params: IParamsResolveParams
	): TSchemaRouteResolvedQueryParamsSchema => {
		if (params.routeName) {
			const route = resolver.getRouteByName(params.routeName);

			if (!route) {
				throw new Error(`Unknown route name '${params.routeName}'.`);
			}

			if (resolvedQueryParamsCache.has(route.path)) {
				return resolvedQueryParamsCache.get(route.path);
			}

			// Parse path params
			const paramTokens = parsePath(route.path);
			const props: TSchemaConstObjectProps = {};

			for (let i = 0; i < paramTokens.length; i++) {
				const token = paramTokens[i];

				if (token instanceof Object && token.name) {
					props[token.name] = Prop(
						SchemaValueString({
							label: String(token.name),
							constraints: {
								required: true
							},
							allowTranslate: false,
							fallbackValue: ""
						})
					);
				}
			}

			const retSchema = SchemaValueMap({
				label: "Query Parameters",
				constraints: {
					required: true
				},
				value: SchemaValueString({})
			});

			resolvedQueryParamsCache.set(route.path, retSchema);
			return retSchema;
		} else {
			return null;
		}
	};

	const resolveParamsFromDesignCtx = (dCtx: DesignContext, params: IParamsResolveParams) => {
		return getResolvedParamsSchema(dCtx.getResolver<IRouteResolver>("route"), params);
	};

	const resolveQueryParamsFromDesignCtx = (dCtx: DesignContext, params: IParamsResolveParams) => {
		return getResolvedQueryParamsSchema(dCtx.getResolver<IRouteResolver>("route"), params);
	};

	const nameSchema: TSchemaRouteNameSchema = SchemaValueString({
		label: "Route Name",
		constraints: {
			required: opts?.constraints?.required,
			min: 1
		},
		allowTranslate: false,
		icon: "mdi/routes",
		editorOptions: {
			controlType: "routeSelector"
		}
	});

	const paramsSchema = SchemaDynamic<IParamsResolveParams, TSchemaRouteUnionParamsSchema>({
		resolveSchemaDesign: resolveParamsFromDesignCtx,
		defaultSchema: SchemaValueMap({
			label: "Route Parameters",
			constraints: {
				required: true
			},
			value: SchemaValueAny({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			})
		})
	});

	const queryParamsSchema = SchemaDynamic<IParamsResolveParams, TSchemaQueryUnionParamsSchema>({
		resolveSchemaDesign: resolveQueryParamsFromDesignCtx,
		defaultSchema: SchemaValueMap({
			label: "Query Parameters",
			constraints: {
				required: true
			},
			value: SchemaValueString({})
		})
	});

	const serializeNullParamsSchema = SchemaValueBoolean({
		label: "Serialize null params",
		default: false
	});

	const keyPropRules = {
		name: {
			required: true,
			provideCompletion: (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {
				const routeList = dCtx.getResolver<IRouteResolver>("route").getRouteList();

				dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
					const items: ICompletionItem[] = routeList.map((item) => ({
						kind: CMPL_ITEM_KIND.Reference,
						label: item.label,
						insertText: item.label
					}));

					return items;
				});
			}
		},
		params: {
			required: true
		},
		queryParams: {
			required: false
		},
		serializeNullParams: {
			required: false
		}
	};

	const updateRef = (dCtx: DesignContext, prevId: string, newId: string) => {
		if (prevId !== null) {
			dCtx.__removeRef("route", prevId);
		}

		if (newId !== null) {
			dCtx.__addRef("route", newId);
		}
	};

	const schema = createEmptySchema<ISchemaRouteRef>("routeRef", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [
			"routeName",
			"params",
			"queryParams",
			"serializeNullParams"
		]);
	};

	const createModel = (
		dCtx: DesignContext,
		nameModel: TRouteNameModel,
		paramsModel: TParamsModel,
		queryParamsModel: TQueryParamsModel,
		serializeNullParamsModel: TSerializeNullParamsModel,
		validationErrors: IBlueprintSchemaValidationError[],
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, validationErrors, {
			routeName: nameModel,
			params: paramsModel,
			queryParams: queryParamsModel,
			serializeNullParams: serializeNullParamsModel,
			__changeHandler: null,
			__invalidateHandler: null,
			__routeNameConstantChangeEventBound: false,
			__lastRouteName: null
		});

		const model = assignParentToChildrenOf(modelNode);

		const handleChange = (wasInvalidated?: boolean) => {
			if (model.routeName.type === SCHEMA_VALUE_TYPE.CONST) {
				if (!model.__routeNameConstantChangeEventBound) {
					onEvent(model.routeName.constant.changeEvent, model.__changeHandler);
					model.__routeNameConstantChangeEventBound = true;
				}

				model.params.schema.resolve(
					model.params,
					{
						routeName: model.routeName.constant.value
					},
					!wasInvalidated,
					wasInvalidated
				);

				model.queryParams.schema.resolve(
					model.queryParams,
					{
						routeName: model.routeName.constant.value
					},
					!wasInvalidated,
					wasInvalidated
				);

				updateRef(dCtx, model.__lastRouteName, model.routeName.constant.value);
				model.__lastRouteName = model.routeName.constant.value;
			} else {
				model.params.schema.reset(model.params, !wasInvalidated);
				model.queryParams.schema.reset(model.queryParams, !wasInvalidated);

				updateRef(dCtx, model.__lastRouteName, null);
				model.__lastRouteName = null;
			}
		};

		model.__changeHandler = () => handleChange(false);
		model.__invalidateHandler = () => handleChange(true);

		onEvent(model.routeName.changeEvent, model.__changeHandler);
		onEvent(dCtx.getResolver<IRouteResolver>("route").onInvalidate, model.__invalidateHandler);

		if (model.routeName.type === SCHEMA_VALUE_TYPE.CONST) {
			onEvent(model.routeName.constant.changeEvent, model.__changeHandler);
			model.__routeNameConstantChangeEventBound = true;

			updateRef(dCtx, null, model.routeName.constant.value);
			model.__lastRouteName = model.routeName.constant.value;
		}

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const errors = validateDefaultValue(schema, [rootValidator as any], defaultValue);

		const nameModel = nameSchema.createDefault(dCtx, null, defaultValue?.name);
		const serializeNullParamsModel = serializeNullParamsSchema.createDefault(
			dCtx,
			null,
			defaultValue?.serializeNullParams
		);
		let paramsModel: TParamsModel;
		let queryParamsModel: TQueryParamsModel;

		if (nameModel.type === SCHEMA_VALUE_TYPE.CONST) {
			paramsModel = paramsSchema.createDefault(dCtx, null, defaultValue?.params, {
				resolveParams: { routeName: nameModel.constant.value }
			}) as TParamsModel;

			queryParamsModel = queryParamsSchema.createDefault(dCtx, null, defaultValue?.queryParams, {
				resolveParams: { routeName: nameModel.constant.value }
			}) as TQueryParamsModel;
		} else {
			paramsModel = paramsSchema.createDefault(dCtx, null, defaultValue?.params) as TParamsModel;
			queryParamsModel = queryParamsSchema.createDefault(
				dCtx,
				null,
				defaultValue?.queryParams
			) as TQueryParamsModel;
		}

		return createModel(
			dCtx,
			nameModel,
			paramsModel,
			queryParamsModel,
			serializeNullParamsModel,
			errors,
			parent
		);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedName = modelNode.routeName.schema.clone(dCtx, modelNode.routeName, null);
		const clonedParams = modelNode.params.schema.clone(dCtx, modelNode.params, null);
		const clonedQueryParams = modelNode.queryParams.schema.clone(dCtx, modelNode.queryParams, null);
		const clonedSerializeNullParams = modelNode.serializeNullParams.schema.clone(
			dCtx,
			modelNode.serializeNullParams,
			null
		);

		return createModel(
			dCtx,
			clonedName,
			clonedParams,
			clonedQueryParams,
			clonedSerializeNullParams,
			modelNode.validationErrors,
			parent
		);
	};

	schema.destroy = (modelNode) => {
		modelNode.routeName.schema.destroy(modelNode.routeName);
		modelNode.params.schema.destroy(modelNode.params);
		modelNode.queryParams.schema.destroy(modelNode.queryParams);
		modelNode.serializeNullParams.schema.destroy(modelNode.serializeNullParams);

		offEvent(
			modelNode.ctx.getResolver<IRouteResolver>("route").onInvalidate,
			modelNode.__invalidateHandler
		);

		updateRef(modelNode.ctx, modelNode.__lastRouteName, null);
		modelNode.__lastRouteName = null;

		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return schema.createDefault(dCtx, parent);
		}

		// Extract keys
		const {
			keys,
			isValid: isRootKeysValid,
			keysValid: rootKeysValidity
		} = extractAndValidateIDTMapProperties(dCtx, rootNode, keyPropRules);

		const nameModel = rootKeysValidity.name ? nameSchema.parse(dCtx, keys["name"].value, null) : null;
		const serializeNullParamsModel = serializeNullParamsSchema.parse(
			dCtx,
			keys["serializeNullParams"]?.value,
			null
		);

		if (keys.params && keys.params.key && idtNode.parseInfo && keys.params.key.parseInfo) {
			paramsSchema.provideCompletion(
				dCtx,
				{
					uri: idtNode.parseInfo.loc.uri,
					range: {
						start: {
							line: keys.params.key.parseInfo.loc.range.end.line,
							col: keys.params.key.parseInfo.loc.range.end.col + 2
						},
						end: keys.params.parseInfo.loc.range.end
					}
				},
				idtNode.parseInfo.loc.range.start.col + 1,
				keys.params.value,
				nameModel && nameModel.type === SCHEMA_VALUE_TYPE.CONST
					? {
						resolveParams: { routeName: nameModel ? nameModel.constant.value : null }
					}
					: undefined
			);
		}

		if (!isRootKeysValid) {
			return schema.createDefault(dCtx, parent);
		}

		let paramsModel: TParamsModel;
		let queryParamsModel: TQueryParamsModel;

		if (nameModel.type === SCHEMA_VALUE_TYPE.CONST) {
			paramsModel = paramsSchema.parse(dCtx, keys["params"].value, null, {
				resolveParams: { routeName: nameModel.constant.value }
			}) as TParamsModel;

			queryParamsModel = queryParamsSchema.parse(dCtx, keys["queryParams"]?.value, null, {
				resolveParams: { routeName: nameModel.constant.value }
			}) as TQueryParamsModel;
		} else {
			paramsModel = paramsSchema.parse(dCtx, keys["params"].value, null) as TParamsModel;
			queryParamsModel = queryParamsSchema.parse(
				dCtx,
				keys["queryParams"]?.value,
				null
			) as TQueryParamsModel;
		}

		if (paramsModel.hasError) {
			if (keys["name"].value.parseInfo) {
				dCtx.logParseError(keys["name"].value.parseInfo.loc.uri, {
					severity: DOC_ERROR_SEVERITY.WARNING,
					name: DOC_ERROR_NAME.INVALID_REF,
					message: `Cannot resolve route: ${paramsModel.error}`,
					parsePath: keys["name"].value.path,
					range: keys["name"].value.parseInfo.loc.range,
					metaData: {
						errorInstance: paramsModel.errorInstance,
						stack:
							paramsModel.errorInstance instanceof Error
								? paramsModel.errorInstance.stack
								: null
					}
				});
			}
		}

		return createModel(
			dCtx,
			nameModel,
			paramsModel,
			queryParamsModel,
			serializeNullParamsModel,
			[],
			parent
		);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);
	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {
		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// name
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[name]"]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["{name}"]),
						value: "name"
					} as IBlueprintIDTScalar,
					value: modelNode.routeName.schema.serialize(modelNode.routeName, path.concat(["name"]))
				} as IBlueprintIDTMapElement,
				// opts
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[params]"]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["{params}"]),
						value: "params"
					} as IBlueprintIDTScalar,
					value: modelNode.params.schema.serialize(modelNode.params, path.concat(["params"]))
				} as IBlueprintIDTMapElement,
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[queryParams]"]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["{queryParams}"]),
						value: "queryParams"
					} as IBlueprintIDTScalar,
					value: modelNode.queryParams.schema.serialize(
						modelNode.queryParams,
						path.concat(["queryParams"])
					)
				} as IBlueprintIDTMapElement,
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[serializeNullParams]"]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["{serializeNullParams}"]),
						value: "serializeNullParams"
					} as IBlueprintIDTScalar,
					value: modelNode.serializeNullParams.schema.serialize(
						modelNode.serializeNullParams,
						path.concat(["serializeNullParams"])
					)
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		const routeName = modelNode.routeName.schema.render(
			rCtx,
			modelNode.routeName,
			path.concat(["name"]),
			scope,
			prevSpec?.name
		);
		const params = modelNode.params.schema.render(
			rCtx,
			modelNode.params,
			path.concat(["params"]),
			scope,
			prevSpec?.params
		);
		const queryParams = modelNode.queryParams.schema.render(
			rCtx,
			modelNode.queryParams,
			path.concat(["queryParams"]),
			scope,
			prevSpec?.queryParams
		);
		const serializeNullParams = modelNode.serializeNullParams.schema.render(
			rCtx,
			modelNode.serializeNullParams,
			path.concat(["serializeNullParams"]),
			scope,
			prevSpec?.serializeNullParams
		);

		return {
			name: routeName,
			params: params,
			queryParams: queryParams,
			serializeNullParams: serializeNullParams
		};
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// Pre-validate params resolve state
		if (modelNode.params.hasError) {
			cCtx.logCompileError({
				severity: DOC_ERROR_SEVERITY.WARNING,
				name: DOC_ERROR_NAME.INVALID_REF,
				message: `Cannot resolve route: ${modelNode.params.error}`,
				metaData: {
					// @todo from translation table
					translationTerm: "schema:routeRef#errors.cannotResolve",
					routeName: modelNode.params.__lastParams.routeName
				},
				modelNodeId: modelNode.nodeId,
				modelPath: path
			});
		}

		if (modelNode.queryParams.hasError) {
			cCtx.logCompileError({
				severity: DOC_ERROR_SEVERITY.WARNING,
				name: DOC_ERROR_NAME.INVALID_REF,
				message: `Cannot resolve route: ${modelNode.queryParams.error}`,
				metaData: {
					// @todo from translation table
					translationTerm: "schema:routeRef#errors.cannotResolve",
					routeName: modelNode.queryParams.__lastParams.routeName
				},
				modelNodeId: modelNode.nodeId,
				modelPath: path
			});
		}

		// Log notice when dynamic routeName
		if (modelNode.routeName.type !== SCHEMA_VALUE_TYPE.CONST) {
			cCtx.logCompileError({
				severity: DOC_ERROR_SEVERITY.HINT,
				name: DOC_ERROR_NAME.DYNAMIC_VALUE_NOTICE,
				// eslint-disable-next-line max-len
				message: `Route name is set dynamically. This prevents validation of route parameters at design time and may result in runtime errors when configured incorrectly.`,
				metaData: {
					// @todo from translation table
					translationTerm: "schema:routeRef#errors.routeNameSetDynamically"
				},
				modelNodeId: modelNode.nodeId,
				modelPath: path
			});
		}

		const nameCmp = modelNode.routeName.schema.compileRender(
			cCtx,
			modelNode.routeName,
			path.concat("name")
		);
		const paramsCmp = modelNode.params.schema.compileRender(
			cCtx,
			modelNode.params,
			path.concat("params")
		);
		const queryParamsCmp = modelNode.queryParams.schema.compileRender(
			cCtx,
			modelNode.queryParams,
			path.concat("queryParams")
		);
		const serializeNullParamsCmp = modelNode.serializeNullParams.schema.compileRender(
			cCtx,
			modelNode.serializeNullParams,
			path.concat("serializeNullParams")
		);

		const idCode = applyCodeArg(
			nameCmp,
			`typeof pv==="object"&&pv!==null?pv.name:undefined`,
			`pt.concat(["name"])`
		);
		const paramsCode = applyCodeArg(
			paramsCmp,
			`typeof pv==="object"&&pv!==null?pv.params:undefined`,
			`pt.concat(["params"])`
		);
		const queryParamsCode = applyCodeArg(
			queryParamsCmp,
			`typeof pv==="object"&&pv!==null?pv.queryParams:undefined`,
			`pt.concat(["queryParams"])`
		);
		const serializeNullParamsCode = applyCodeArg(
			serializeNullParamsCmp,
			`typeof pv==="object"&&pv!==null?pv.serializeNullParams:undefined`,
			`pt.concat(["serializeNullParams"])`
		);

		return {
			isScoped: true,
			code: `(s,pv,pt)=>({name:${idCode},params:${paramsCode},queryParams:${queryParamsCode},serializeNullParams:${serializeNullParamsCode}})`
		};
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		let isValid = true;

		// Validate self as object
		isValid =
			applyRuntimeValidators<{ [K: string]: unknown }>(
				rCtx,
				path,
				modelNodeId,
				[rootValidator],
				DOC_ERROR_SEVERITY.ERROR,
				value as unknown as { [K: string]: unknown }
			) && isValid;

		if (validateChildren && value instanceof Object) {
			isValid =
				nameSchema.validate(rCtx, path.concat(["name"]), modelNodeId, value.name, true) && isValid;
			isValid =
				paramsSchema.validate(rCtx, path.concat(["params"]), modelNodeId, value.params, true, {
					resolveParams: {
						routeName: value?.name
					}
				}) && isValid;
			isValid =
				queryParamsSchema.validate(
					rCtx,
					path.concat(["queryParams"]),
					modelNodeId,
					value.queryParams,
					true,
					{
						resolveParams: {
							routeName: value?.name
						}
					}
				) && isValid;
			isValid =
				serializeNullParamsSchema.validate(
					rCtx,
					path.concat(["serializeNullParams"]),
					modelNodeId,
					value.serializeNullParams,
					true
				) && isValid;
		}

		return isValid;
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		const expressions = [];

		const rootValidatorCmp = compileRuntimeValidators(
			cCtx,
			path,
			modelNodeId,
			[rootValidator],
			DOC_ERROR_SEVERITY.ERROR
		);

		if (rootValidatorCmp !== null) {
			expressions.push(`_vd=(${rootValidatorCmp})(v,pt)&&_vd;`);
		}

		if (validateChildren) {
			expressions.push(`if(typeof v==="object"&&v!==null){`);

			const nameValid = nameSchema.compileValidate(cCtx, path.concat(["name"]), modelNodeId, true);
			const paramsValid = paramsSchema.compileValidate(
				cCtx,
				path.concat(["params"]),
				modelNodeId,
				true
			);
			const queryParamsValid = queryParamsSchema.compileValidate(
				cCtx,
				path.concat(["queryParams"]),
				modelNodeId,
				true
			);
			const serializeNullParamsValid = serializeNullParamsSchema.compileValidate(
				cCtx,
				path.concat(["serializeNullParams"]),
				modelNodeId,
				true
			);

			if (nameValid) {
				expressions.push(`_vd=(${nameValid})(v.name,pt)&&_vd;`);
			}

			if (paramsValid) {
				expressions.push(`_vd=(${paramsValid})(v.params,pt)&&_vd;`);
			}

			if (queryParamsValid) {
				expressions.push(`_vd=(${queryParamsValid})(v.queryParams,pt)&&_vd;`);
			}

			if (serializeNullParamsValid) {
				expressions.push(`_vd=(${serializeNullParamsValid})(v.serializeNullParams,pt)&&_vd;`);
			}

			expressions.push(`}`);
		}

		if (expressions.length === 0) {
			return null;
		} else {
			return cCtx.addGlobalValue(`(v,pt)=>{let _vd=true;${expressions.join("")}return _vd}`);
		}
	};

	schema.export = (): ISchemaImportExport => {
		return exportSchema("SchemaRouteRef", [opts]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		return TypeDescObject({
			label: opts.label,
			description: opts.description,
			props: {
				name:
					modelNode?.routeName.schema.getTypeDescriptor(modelNode.routeName) ||
					nameSchema.getTypeDescriptor(),
				params:
					modelNode?.params.schema.getTypeDescriptor(modelNode.params) ||
					paramsSchema.getTypeDescriptor(),
				queryParams:
					modelNode?.queryParams.schema.getTypeDescriptor(modelNode.queryParams) ||
					queryParamsSchema.getTypeDescriptor(),
				serializeNullParams:
					modelNode?.serializeNullParams.schema.getTypeDescriptor(modelNode.serializeNullParams) ||
					serializeNullParamsSchema.getTypeDescriptor()
			},
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.getRouteList = (modelNode: ISchemaRouteRefModel) => {
		return modelNode.ctx.getResolver<IRouteResolver>("route").getRouteList();
	};

	schema.getChildNodes = (modelNode) => {
		const children: IBlueprintSchemaChildNode[] = [
			{
				key: "routeName",
				node: modelNode.routeName
			}
		];

		if (modelNode.params) {
			children.push({
				key: "params",
				node: modelNode.params
			});
		}

		if (modelNode.queryParams) {
			children.push({
				key: "queryParams",
				node: modelNode.queryParams
			});
		}

		if (modelNode.serializeNullParams) {
			children.push({
				key: "serializeNullParams",
				node: modelNode.serializeNullParams
			});
		}

		return children;
	};

	return schema;
}
