/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { OBJECT_TYPE, OBJECT_TYPE_PROP_NAME } from "../constants";
import { CompileContext } from "../Context/CompileContext";
import { escapeString, inlineValue } from "../Context/CompileUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import {
	IExpAst_BooleanLiteral,
	IExpAst_FunctionCall,
	IExpAst_IndexAccessor,
	IExpAst_List,
	IExpAst_NullLiteral,
	IExpAst_NumberLiteral,
	IExpAst_Object,
	IExpAst_Operator,
	IExpAst_PrefixOperator,
	IExpAst_StringLiteral,
	IExpAst_StringTemplate,
	IExpAst_ValueReference,
	NODE_KIND,
	OPERATOR_TYPE,
	PREFIX_OPERATOR_TYPE,
	TExpAst_Expression
} from "./ExpAst";

export interface IExpCompileOutput {
	code: string;
	isPartial: boolean;
}

let _toEmitMetaData = true;
let _lastMetaDataId: number;
let _cCtx: CompileContext;
let _modelNodeId: number;
let _source: string;

function resetState() {
	_lastMetaDataId = -1;
	_cCtx = null;
	_modelNodeId = -1;
	_source = "";
}

function getMetaDataId() {
	return ++_lastMetaDataId;
}

function wrapMeta(metaDataId: number, code: string) {
	if (_toEmitMetaData) {
		return `(md[${metaDataId}]=${code})`;
	} else {
		return code;
	}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function compileNullLiteral(node: IExpAst_NullLiteral): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	return {
		code: wrapMeta(node.metaDataId, `null`),
		isPartial: false
	};
}

function compileBooleanLiteral(node: IExpAst_BooleanLiteral): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	return {
		code: wrapMeta(node.metaDataId, node.value === true ? "true" : "false"),
		isPartial: false
	};
}

function compileNumberLiteral(node: IExpAst_NumberLiteral): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	return {
		code: wrapMeta(node.metaDataId, String(node.value)),
		isPartial: false
	};
}

function compileStringLiteral(node: IExpAst_StringLiteral): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	return {
		code: wrapMeta(node.metaDataId, `"${escapeString(node.value)}"`),
		isPartial: false
	};
}

function compileStringTemplate(node: IExpAst_StringTemplate): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	const parts: string[] = [];
	let isPartial = false;

	for (let i = 0; i < node.elements.length; i++) {
		if (!node.elements[i]) {
			isPartial = true;
			continue;
		}

		if (node.elements[i].kind === NODE_KIND.STRING_LITERAL) {
			const elementRes = compileStringLiteral(node.elements[i] as IExpAst_StringLiteral);

			parts.push(elementRes.code);
			isPartial ||= elementRes.isPartial;
		} else {
			const elementRes = compileExpression(node.elements[i]);

			parts.push(`String(${elementRes.code})`);
			isPartial ||= elementRes.isPartial;
		}
	}

	return {
		code: wrapMeta(node.metaDataId, `(${parts.join("+")})`),
		isPartial: isPartial
	};
}

function compileValueReference(node: IExpAst_ValueReference): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	const identifierCode = `"${escapeString(node.identifier.value)}"`;

	return {
		code: wrapMeta(
			node.metaDataId,
			// eslint-disable-next-line max-len
			`(s.globalData[${identifierCode}]!==undefined?s.globalData[${identifierCode}]:(s.globalData["globals"]!==undefined&&s.globalData["globals"][${identifierCode}]!==undefined?s.globalData["globals"][${identifierCode}]:null))`
		),
		isPartial: false
	};
}

function compileIndexAccessor(node: IExpAst_IndexAccessor): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	const isObject = _cCtx.addGlobalValue(`(v)=>{${[
		/* eslint-disable indent */
		`if(typeof v==="object"&&v!==null){`,
			`if(v["${escapeString(OBJECT_TYPE_PROP_NAME)}"]==="${escapeString(OBJECT_TYPE.DATASOURCE)}"){`,
				`rCtx.__logLoadingState(v["isLoading"]||false,v["hasData"]||false)`,
			`}`,
			`return true`,
		`}else{`,
			`return false`,
		`}`
		/* eslint-enable indent */
	].join("")}}`);

	const baseRef = compileExpression(node.baseReference);
	let index: IExpCompileOutput;

	if (!node.index) {
		return {
			code: wrapMeta(node.metaDataId, `null`),
			isPartial: true
		};
	}

	if (node.index.kind === NODE_KIND.IDENTIFIER) {
		index = {
			code: `"${escapeString(node.index.value)}"`,
			isPartial: false
		};
	} else {
		index = compileExpression(node.index);
	}

	return {
		code: wrapMeta(node.metaDataId, [
			/* eslint-disable indent */
			`(()=>{`,
				`const _b=${baseRef.code};`,
				`if(${isObject}(_b)){`,
					`const _r=_b[${index.code}];`,
					`return _r!==undefined?_r:null`,
				`}else{return null}`,
			`})()`
			/* eslint-enable indent */
		].join("")),
		isPartial: baseRef.isPartial || index.isPartial
	};
}

function compileFunctionCall(node: IExpAst_FunctionCall): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	const isFunction = _cCtx.addGlobalValue(`(v)=>${[
		/* eslint-disable indent */
		`(`,
			`typeof v==="function"`,
			`&&`,
			`v["${escapeString(OBJECT_TYPE_PROP_NAME)}"]==="${escapeString(OBJECT_TYPE.FUNCTION)}"`,
		`)?true:false`
		/* eslint-enable indent */
	].join("")}`);

	const logIsNotFunction = _cCtx.addGlobalValue(`(rng,sid)=>{${[
		/* eslint-disable indent */
		`rCtx.logRuntimeError({`,
			`severity:${inlineValue(DOC_ERROR_SEVERITY.WARNING)},`,
			`name:${inlineValue(DOC_ERROR_NAME.FUNC_NOT_FUNC)},`,
			`message:"Referenced value '"+sid+"' is not a function (at "+rng.start.line+":"+rng.start.col+").",`,
			`modelPath:pt,`,
			`modelNodeId:${inlineValue(_modelNodeId)},`,
			// @todo add from terms file
			// eslint-disable-next-line max-len
			`metaData:{translationTerm:"function:errors.notFunction",startLine:rng.start.line,startCol:rng.start.col,endLine:rng.end.line,endCol:rng.end.col}`,
		`})`,
		/* eslint-enable indent */
	].join("")}}`);

	const logUnexpectedError = _cCtx.addGlobalValue(`(rng,msg)=>{${[
		/* eslint-disable indent */
		`rCtx.logRuntimeError({`,
			`severity:${inlineValue(DOC_ERROR_SEVERITY.WARNING)},`,
			`name:${inlineValue(DOC_ERROR_NAME.FUNC_NOT_FUNC)},`,
			`message:"Failed to execute function (at "+rng.start.line+":"+rng.start.col+"): "+msg,`,
			`modelPath:pt,`,
			`modelNodeId:${inlineValue(_modelNodeId)},`,
			// @todo add from terms file
			// eslint-disable-next-line max-len
			`metaData:{translationTerm:"function:errors.execFailed",startLine:rng.start.line,startCol:rng.start.col,endLine:rng.end.line,endCol:rng.end.col}`,
		`})`,
		/* eslint-enable indent */
	].join("")}}`);

	const refCmp = compileExpression(node.reference);
	const argsCmp = node.argList
		? node.argList.arguments
			.filter((arg) => arg)
			.map((arg) => {
				return compileExpression(arg);
			})
		: [];

	let isArgsPartial = false;
	const argsCode: string[] = [];

	for (let i = 0; i < argsCmp.length; i++) {
		argsCode.push(`((s)=>${argsCmp[i].code})`);
		isArgsPartial ||= argsCmp[i].isPartial;
	}

	return {
		code: wrapMeta(node.metaDataId, [
			/* eslint-disable indent */
			`(()=>{`,
				`const _r=${refCmp.code};`,
				`if(${isFunction}(_r)){`,
					`try{`,
						`return _r(rCtx,pt,${_modelNodeId},s,[${argsCode.join(",")}],${inlineValue(node.range)});`,
					`}catch(_e){`,
						`${logUnexpectedError}(${inlineValue(node.reference.range)},String(_e))`,
					`}`,
				`}else{`,
					// eslint-disable-next-line max-len
					`${logIsNotFunction}(${inlineValue(node.reference.range)},"${escapeString(_source.substr(node.reference.range.start.offset, node.reference.range.end.offset - node.reference.range.start.offset))}");`,
					`return null`,
				`}`,
			`})()`
			/* eslint-enable indent */
		].join("")),
		isPartial: refCmp.isPartial || isArgsPartial
	};
}

function compileSimpleOperator(
	node: IExpAst_Operator, opChar: string
): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	if (!node.left || !node.right) {
		return {
			code: wrapMeta(node.metaDataId, `null`),
			isPartial: true
		};
	}

	const left = compileExpression(node.left);
	const right = compileExpression(node.right);

	return {
		code: _toEmitMetaData // when emitting meta-data we need to evaluate each side first before performing operation
			? wrapMeta(node.metaDataId, `(()=>{const _l=${left.code};const _r=${right.code};return _l${opChar}_r})()`)
			: `(${left.code}${opChar}${right.code})`,
		isPartial: left.isPartial || right.isPartial
	};
}

function compileStringConcat(node: IExpAst_Operator): IExpCompileOutput {

	/* eslint-disable max-len */
	const castString = _cCtx.addGlobalValue(`(v,pt)=>{${[
		`if(v===null||v===undefined){`,
		`return v`,
		`}else if(typeof v==="string"){`,
		`return v`,
		`}else if(typeof v==="function"){`,
		`rCtx.logRuntimeError({severity:${inlineValue(DOC_ERROR_SEVERITY.WARNING)},name:${inlineValue(DOC_ERROR_NAME.CAST_ERROR)},message:"Cannot cast function to a string.",modelPath:pt,modelNodeId:${inlineValue(_modelNodeId)},metaData:{translationTerm:"schema:constString#errors.cannotCastFunction"}});`,
		`return v`,
		`}else if(v instanceof Date){`,
		`return v.toLocaleString()`,
		`}else if(typeof v==="object"){`,
		`try{`,
		`return JSON.stringify(v)`,
		`}catch(_e){`,
		`rCtx.logRuntimeError({severity:${inlineValue(DOC_ERROR_SEVERITY.WARNING)},name:${inlineValue(DOC_ERROR_NAME.CAST_ERROR)},message:"Cannot cast value to a string.",modelPath:pt,modelNodeId:${inlineValue(_modelNodeId)},metaData:{translationTerm:"schema:constString#errors.cannotCastJson"}});`,
		`}`,
		`}else{`,
		`return String(v)`,
		`}`
	].join("")}}`);
	/* eslint-enable max-len */

	node.metaDataId = getMetaDataId();

	if (!node.left || !node.right) {
		return {
			code: wrapMeta(node.metaDataId, `null`),
			isPartial: true
		};
	}

	const left = compileExpression(node.left);
	const right = compileExpression(node.right);

	return {
		code: wrapMeta(node.metaDataId, `(${castString}(${left.code},pt)+${castString}(${right.code},pt))`),
		isPartial: left.isPartial || right.isPartial
	};

}

function compileOperator(node: IExpAst_Operator): IExpCompileOutput {
	switch (node.kind) {
		case NODE_KIND.OPERATOR: {
			switch (node.type) {
				case OPERATOR_TYPE.ART_PLUS:
					return compileSimpleOperator(node, "+");
				case OPERATOR_TYPE.ART_MINUS:
					return compileSimpleOperator(node, "-");
				case OPERATOR_TYPE.ART_MULTIPLY:
					return compileSimpleOperator(node, "*");
				case OPERATOR_TYPE.ART_DIVIDE:
					return compileSimpleOperator(node, "/");
				case OPERATOR_TYPE.ART_MODULO:
					return compileSimpleOperator(node, "%");
				case OPERATOR_TYPE.CMP_LOWER_THAN_EQL:
					return compileSimpleOperator(node, "<=");
				case OPERATOR_TYPE.CMP_LOWER_THAN:
					return compileSimpleOperator(node, "<");
				case OPERATOR_TYPE.CMP_GREATER_THAN_EQL:
					return compileSimpleOperator(node, ">=");
				case OPERATOR_TYPE.CMP_GREATER_THAN:
					return compileSimpleOperator(node, ">");
				case OPERATOR_TYPE.CMP_EQUAL:
					return compileSimpleOperator(node, "==");
				case OPERATOR_TYPE.CMP_NOT_EQUAL:
					return compileSimpleOperator(node, "!=");
				case OPERATOR_TYPE.LOGIC_OR:
					return compileSimpleOperator(node, "||");
				case OPERATOR_TYPE.LOGIC_AND:
					return compileSimpleOperator(node, "&&");
				case OPERATOR_TYPE.STR_CONCAT: {
					return compileStringConcat(node);
				}
			}
		}
	}
}

function compilePrefixOperator(node: IExpAst_PrefixOperator): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	const right = compileExpression(node.right);

	switch (node.kind) {
		case NODE_KIND.PREFIX_OPERATOR: {
			switch (node.type) {
				case PREFIX_OPERATOR_TYPE.NOT:
					return {
						code: wrapMeta(node.metaDataId, `(!(${right.code}))`),
						isPartial: right.isPartial
					};
				case PREFIX_OPERATOR_TYPE.ART_PLUS:
					return {
						code: wrapMeta(node.metaDataId, `(+${right.code})`),
						isPartial: right.isPartial
					};
				case PREFIX_OPERATOR_TYPE.ART_MINUS:
					return {
						code: wrapMeta(node.metaDataId, `(-${right.code})`),
						isPartial: right.isPartial
					};
			}
		}
	}
}

function compileList(node: IExpAst_List): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	const elements = node.elements
		.filter((arg) => arg)
		.map((arg) => {
			return compileExpression(arg);
		});

	let areElementsPartial = false;

	const elementsCode = elements.map((el) => {
		if (el.isPartial) {
			areElementsPartial = true;
		}

		return el.code;
	}).join(",");

	return {
		code: wrapMeta(node.metaDataId, `[${elementsCode}]`),
		isPartial: areElementsPartial
	};
}

function compileObject(node: IExpAst_Object): IExpCompileOutput {
	node.metaDataId = getMetaDataId();

	let arePropsPartial = false;

	const propsCode = node.properties
		.filter((prop) => {
			return prop.value && !(!prop.key && !prop.spread);
		})
		.map((prop) => {
			if (prop.spread) {

				const value = compileExpression(prop.value);
				arePropsPartial ||= value.isPartial;

				return `...${value.code}`;

			} else {

				let keyCode: string;

				if (
					prop.key.kind === NODE_KIND.IDENTIFIER ||
					prop.key.kind === NODE_KIND.STRING_LITERAL ||
					prop.key.kind === NODE_KIND.NUMBER_LITERAL
				) {
					keyCode = `"${escapeString(String(prop.key.value))}"`;
				} else {
					const keyCmp = compileExpression(prop.key);
					arePropsPartial ||= keyCmp.isPartial;

					keyCode = `[${keyCmp.code}]`;
				}

				const value = compileExpression(prop.value);
				arePropsPartial ||= value.isPartial;

				return `${keyCode}:${value.code}`;

			}
		})
		.join(",");

	return {
		code: wrapMeta(node.metaDataId, `({${propsCode}})`),
		isPartial: arePropsPartial
	};
}

function compileExpression(node: TExpAst_Expression): IExpCompileOutput {
	switch (node.kind) {
		case NODE_KIND.NULL_LITERAL:
			return compileNullLiteral(node);
		case NODE_KIND.BOOLEAN_LITERAL:
			return compileBooleanLiteral(node);
		case NODE_KIND.NUMBER_LITERAL:
			return compileNumberLiteral(node);
		case NODE_KIND.STRING_LITERAL:
			return compileStringLiteral(node);
		case NODE_KIND.STRING_TEMPLATE:
			return compileStringTemplate(node);
		case NODE_KIND.VALUE_REF:
			return compileValueReference(node);
		case NODE_KIND.INDEX_ACCESSOR:
			return compileIndexAccessor(node);
		case NODE_KIND.FUNCTION_CALL:
			return compileFunctionCall(node);
		case NODE_KIND.OPERATOR:
			return compileOperator(node);
		case NODE_KIND.PREFIX_OPERATOR:
			return compilePrefixOperator(node);
		case NODE_KIND.LIST:
			return compileList(node);
		case NODE_KIND.OBJECT:
			return compileObject(node);
	}
}

export function compile(
	cCtx: CompileContext, modelNodeId: number, node: TExpAst_Expression, source: string, emitMetaData = false
): IExpCompileOutput {

	resetState();

	_toEmitMetaData = emitMetaData;
	_cCtx = cCtx;
	_modelNodeId = modelNodeId;
	_source = source;

	const expCode = compileExpression(node);

	_cCtx = null;
	_source = null;

	const res = {
		code: [
			/* eslint-disable indent */
			`(s,pt)=>{`,
				`const md=[];`,
				`const _r=${expCode.code};`,
				`return {result:_r,metaData:md}`,
			`}`
			/* eslint-enable indent */
		].join(""),
		isPartial: expCode.isPartial
	};

	resetState();

	return res;

}