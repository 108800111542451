/**
 * Background Images schema
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstArray,
	ISchemaValue,
	ISchemaValueArrayOpts,
	TGenericBlueprintSchema,
	TSchemaConstObjectPropsSpec
} from "@hexio_io/hae-lib-blueprint";
import { BACKGROUND_SIZE, BACKGROUND_SIZE_default, getValuesFromStringEnum } from "..";
import { BACKGROUND_ATTACHMENT, BACKGROUND_ATTACHMENT_default } from "../Enums/BACKGROUND_ATTACHMENT";

import { termsEditor } from "../terms";

export const BACKGROUND_IMAGE_DEFAULT_POSITION = "center";
export const BACKGROUND_IMAGE_DEFAULT_REPEAT = "no-repeat";

/**
 * Background Images Url props
 */
const BackgroundImagesUrlProps = {
	source: BP.Prop(BP.String({
		...termsEditor.schemas.common.backgroundImageSource,
		default: "",
		fallbackValue: "",
		constraints: {
			required: true
		}
	}), 0),

	size: BP.Prop(BP.StringWithConst({
		...termsEditor.schemas.common.backgroundImageSize,
		constants: getValuesFromStringEnum(
			BACKGROUND_SIZE,
			termsEditor.schemas.common.backgroundImageSizeValues
		),
		default: BACKGROUND_SIZE_default
	}), 10),

	position: BP.Prop(BP.String({
		...termsEditor.schemas.common.backgroundImagePosition,
		default: BACKGROUND_IMAGE_DEFAULT_POSITION
	}), 20),

	repeat: BP.Prop(BP.String({
		...termsEditor.schemas.common.backgroundImageRepeat,
		default: BACKGROUND_IMAGE_DEFAULT_REPEAT
	}), 30),

	attachment: BP.Prop(BP.Enum.String({
		...termsEditor.schemas.common.backgroundImageAttachment,
		options: getValuesFromStringEnum(
			BACKGROUND_ATTACHMENT,
			termsEditor.schemas.common.backgroundImageAttachmentValues
		),
		default: BACKGROUND_ATTACHMENT_default,
		fallbackValue: BACKGROUND_ATTACHMENT_default,
		constraints: {
			required: true
		}
	}), 40)
};

/**
 * Background Images Url props type
 */
export type TBackgroundImagesUrlProps = Partial<TSchemaConstObjectPropsSpec<typeof BackgroundImagesUrlProps>>;

/**
 * Background Images Items schema
 */
function BackgroundImagesItemsSchema() {
	return BP.OneOf({
		...termsEditor.schemas.common.backgroundImage,
		typeValueOpts: {
			...termsEditor.schemas.common.backgroundImage
		},
		alias: "backgroundImages",
		defaultType: "URL",
		types: {
			"URL": {
				...termsEditor.schemas.common.backgroundImageValues.url,
				value: BP.Object({
					props: BackgroundImagesUrlProps,
					editorOptions: {
						layoutType: "passthrough"
					}
				})
			}
		},
		editorOptions: {
			layoutType: "noHeader"
		}
	});
}

/**
 * Background Images schema
 *
 * @param opts Options
 */
export function BackgroundImagesSchema(
	opts: Partial<ISchemaValueArrayOpts<TGenericBlueprintSchema<unknown, unknown>>> = { alias: "backgroundImages" }
): ISchemaValue<ISchemaConstArray<TGenericBlueprintSchema<unknown, unknown>>> {
	return BP.Array({
		...termsEditor.schemas.common.backgroundImages,
		default: [],
		...opts,
		items: BackgroundImagesItemsSchema()
	});
}
