/**
 * Image component terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const image = {
	component: {
		label: "component:image#label",
		description: "component:image#description"
	}
};
