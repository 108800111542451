/**
 * Activity Item
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export interface IActivityItem {
	/** Item ID */
	id: string;

	/** Item state */
	state?: ACTIVITY_ITEM_STATE;

	/** Item data */
	data: unknown;
}

export enum ACTIVITY_ITEM_STATE {
	READY = "ready",
	ADDED = "added",
	REMOVED = "removed",
	DEFAULT = READY
}
