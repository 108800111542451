/**
 * Spacing enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum SPACING {
	NONE = "none",
	NARROW = "narrow",
	MEDIUM = "medium",
	WIDE = "wide",
	XWIDE = "x-wide"
}

export type SPACING_string = keyof typeof SPACING;

export const SPACING_default: SPACING_string = "MEDIUM";
export const SPACING_narrow: SPACING_string = "NARROW";
export const SPACING_wide: SPACING_string = "WIDE";
export const SPACING_none: SPACING_string = "NONE";
