/**
 * Composed Chart HAE component Create Scrollbar
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import { IScrollbarSettings } from "@amcharts/amcharts5/.internal/core/render/Scrollbar";
import { IRoundedRectangleSettings } from "@amcharts/amcharts5/.internal/core/render/RoundedRectangle";

import { controlBaseSettings } from "./settings";
import { THAEComponentComposedChart_Props } from "./props";
import { IColors, IStyles } from "../../types/charts";

/**
 * Creates scrollbar
 */
export function createScrollbar(
	horizontalScrollbar: THAEComponentComposedChart_Props["horizontalScrollbar"],
	root: am5.Root,
	chart: am5xy.XYChart,
	colors: IColors,
	styles: IStyles,
	roundedRectangleBaseSettings: IRoundedRectangleSettings
): {
	scrollbar: am5.Scrollbar | am5xy.XYChartScrollbar;
	scrollbarChart: am5xy.XYChart;
} {
	const { spacing } = styles;
	const { base, background, lightGray } = colors;

	let scrollbar: am5.Scrollbar | am5xy.XYChartScrollbar;
	let scrollbarChart: am5xy.XYChart;

	if (horizontalScrollbar) {
		const scrollbarSettings: IScrollbarSettings = {
			orientation: "horizontal",
			height: horizontalScrollbar.height,
			marginTop: spacing,
			marginBottom: 0,
			background: am5.Rectangle.new(root, {
				fill: lightGray,
				fillOpacity: .5
			})
		};

		if (!horizontalScrollbar.preview) {
			scrollbar = am5.Scrollbar.new(root, scrollbarSettings);

			scrollbar.thumb.setAll({
				fill: lightGray,
				fillOpacity: 1,
			});
		}
		else {
			scrollbar = am5xy.XYChartScrollbar.new(root, scrollbarSettings);
			scrollbarChart = (scrollbar as am5xy.XYChartScrollbar).chart;

			//scrollbarChart.set("background", am5.Rectangle.new(root, { fill: lightGray, fillOpacity: 1 }));

			scrollbar.thumb.setAll({
				fillOpacity: 0
			});
		}

		scrollbar.thumb.setAll({
			cornerRadiusTR: 0,
			cornerRadiusBR: 0,
			cornerRadiusTL: 0,
			cornerRadiusBL: 0
		});

		//scrollbarBackground.states.create("hover", { fill: primaryColor, fillOpacity: 1 });

		/*scrollbar.thumb.states.create("hover", {
			fill: color, fillOpacity: 1
		});*/

		const gripBackgroundSettings: IRoundedRectangleSettings = {
			...roundedRectangleBaseSettings,
			fill: background,
			fillOpacity: 1,
			stroke: base,
			strokeWidth: 1
		};

		scrollbar.startGrip.setAll({
			...controlBaseSettings,
			background: am5.RoundedRectangle.new(root, { ...gripBackgroundSettings })
		});

		scrollbar.endGrip.setAll({
			...controlBaseSettings,
			background: am5.RoundedRectangle.new(root, { ...gripBackgroundSettings })
		});

		chart.set("scrollbarX", scrollbar);
	}

	return {
		scrollbar,
		scrollbarChart
	};
}