/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	CompileContext,
	DesignContext,
	RuntimeContext,
	TBlueprintIDTNode,
	TGenericBlueprintSchema,
	TGenericModelNode,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { IItemRegistry, ItemRegistryItemProps } from "@hexio_io/hae-lib-shared";
import { DOC_TYPES } from "../blueprints";
import { IResourceErrorReport, RESOURCE_TYPES } from "../resources";


export interface IResourceParsingDetails {

	/** Registered resource type */
	isRegistered?: boolean;

	/** Valid raw data */
	isValidRaw?: boolean;

	/** Valid IDT */
	isValidIdt?: boolean;

	/** Valid Model */
	isValidModel?: boolean;

	/** Valid Spec */
	isValidSpec?: boolean;

	/** Other checks */
	[K: string]: boolean;

}

export interface IResourceAttributes {
	hidden?: boolean;
	allowWrite?: boolean;
	allowRemove?: boolean;
	allowRename?: boolean;
}

export interface IResourceParsedData {
	requireAuthenticatedUser?: boolean;
	model?: TGenericModelNode;
	spec?: TGetBlueprintSchemaSpec<TGenericBlueprintSchema>;
	[K: string]: any;
}

export interface IResourceDependency {
	id?: string;
	uri?: string;
}

export interface IResourceMetadata {
	[K: string]: any;
}

export enum RESOURCE_PERMISSIONS {
	READ = "read",
	CREATE = "create",
	UPDATE = "update",
	DELETE = "delete",
	RENAME = "rename",
	LOCK = "lock",
	METADATA_UPDATE = "metadata_update"
}

export const GENERIC_RESOURCE_PERMISSIONS = [
	RESOURCE_PERMISSIONS.CREATE,
	RESOURCE_PERMISSIONS.DELETE,
	RESOURCE_PERMISSIONS.LOCK,
	RESOURCE_PERMISSIONS.READ,
	RESOURCE_PERMISSIONS.RENAME,
	RESOURCE_PERMISSIONS.UPDATE
];

/**
 * Resource Registry Item Properties
 */
export interface IResourceProps extends ItemRegistryItemProps {

	/** uri */
	uri?: string;

	/** label */
	label?: string;

	/** location */
	location?: string;

	/** Base path */
	basePath?: string;

	/** Doc type */
	docType?: DOC_TYPES;

	/** Resource type */
	resourceType?: RESOURCE_TYPES;

	/** Blueprint ID */
	id?: string;

	/** Unparsed resource */
	content?: string;

	lastModified?: Date;

	idt?: TBlueprintIDTNode;
	isValid: boolean;
	parsedData?: IResourceParsedData;
	attributes?: IResourceAttributes;
	metadata?: IResourceMetadata;
	errors?: IResourceErrorReport;
	dependencies?: IResourceDependency[];

	/** Last modified at */
	changedTime?: Date;

	parsingDetails?: IResourceParsingDetails;
	reportParsingErrors?(dCtx: DesignContext): void;
	reportCompileErrors(cCtx: CompileContext): void;
	reportRuntimeErrors(rCtx: RuntimeContext): void;
	clone(props?: string[]): IResourceProps;

	lockedBy?: string;
	modifiedBy?: string;
	modifiedAt?: string;
	revision?: string;

}

export interface IResourceRegistry extends IItemRegistry<IResourceProps> { }