/**
 * Datetime Field HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { DateTime } from "luxon";

import { BP, defineElementaryComponent, COMPONENT_MODE, Type } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	getStringEnumValue,
	getValuesFromStringEnum,
	ICON_NAME,
	Label,
	MediaContext,
	propGroups,
	StyleSheet,
	THAEComponentDefinition,
	THAEComponentReact,
	useStyleSheetRegistry} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { FieldLabelInfo } from "./FieldLabelInfo";
import { FieldInfo } from "./FieldInfo";
import { useField, getFieldStateProps } from "./useField";
import { IFieldState } from "./state";
import { HAEComponentField_Events } from "./events";
import { FieldBaseProps } from "./props";
import { DATETIME_FIELD_TYPE, DATETIME_FIELD_TYPE_default } from "../../Enums/DATETIME_FIELD_TYPE";
import { isString, isValidObject, isValidValue } from "@hexio_io/hae-lib-shared";
import { DatetimeFieldDateInput, DatetimeFieldTimeInput } from "./DatetimeFieldInput";
import { createFieldClassListModifiers } from "./createFieldClassListModifiers";

function getDateAndTimeValuesFromISOStringOrTimestamp(value: string): { dateValue: string, timeValue: string } {
	let dateValue = "";
	let timeValue = "";

	let dateTime = DateTime.fromISO(value);

	if (!dateTime.isValid && /^\d+$/.test(value)) {
		dateTime = DateTime.fromMillis(parseInt(value, 10));
	}

	if (dateTime.isValid) {
		if (value.includes("T")) {
			[ dateValue = "", timeValue = "" ] = value.split("T");
		}
		else {
			if (value.includes("-") || `${dateTime.year}` === value) {
				dateValue = value;
			}
			else {
				timeValue = value;
			}
		}
	}

	return {
		dateValue,
		timeValue
	};
}

interface HAEComponentDatetimeField_State extends IFieldState {
	initialValue: string;
	value: string;
}

const HAEComponentDatetimeField_Props = {

	...FieldBaseProps,

	value: BP.Prop(BP.String({
		...termsEditor.schemas.datetimeField.value,
		default: "",
		fallbackValue: null,
		constraints: {
			required: true
		}
	}), 0, propGroups.common),

	type: BP.Prop(BP.Enum.String({
		...termsEditor.schemas.datetimeField.type,
		options: getValuesFromStringEnum(
			DATETIME_FIELD_TYPE,
			termsEditor.schemas.datetimeField.typeValues
		),
		default: DATETIME_FIELD_TYPE_default,
		fallbackValue: DATETIME_FIELD_TYPE_default,
		constraints: {
			required: true
		}
	}), 10, propGroups.common),

	min: BP.Prop(BP.String({
		...termsEditor.schemas.datetimeField.min
	}), 200, propGroups.validation),

	max: BP.Prop(BP.String({
		...termsEditor.schemas.datetimeField.max
	}), 210, propGroups.validation)

};

const HAEComponentDatetimeField_Events = {
	...HAEComponentField_Events
};

const HAEComponentDatetimeField_Definition = defineElementaryComponent<
	typeof HAEComponentDatetimeField_Props,
	HAEComponentDatetimeField_State,
	typeof HAEComponentDatetimeField_Events
>({
	...termsEditor.components.datetimeField.component,

	name: "datetimeField",

	category: "form",

	icon: "mdi/calendar",

	docUrl: "...",

	order: 60,

	props: HAEComponentDatetimeField_Props,

	events: HAEComponentDatetimeField_Events,

	resolve: (spec, state, updateStateAsync) => {
		let initialValue = isValidValue(spec.value) ? spec.value : state?.initialValue;

		const typeValue = getStringEnumValue(DATETIME_FIELD_TYPE, spec.type, DATETIME_FIELD_TYPE_default);

		if (typeValue === DATETIME_FIELD_TYPE.DATE) {
			initialValue = getDateAndTimeValuesFromISOStringOrTimestamp(initialValue).dateValue;
		}
		else if (typeValue === DATETIME_FIELD_TYPE.TIME || typeValue === DATETIME_FIELD_TYPE.TIME_FULL) {
			initialValue = getDateAndTimeValuesFromISOStringOrTimestamp(initialValue).timeValue;
		}

		const value = state?.initialValue === initialValue ?
			(isValidValue(state?.value) ? state.value : spec.value) :
			spec.value;

		function setValue(value: string) {
			updateStateAsync((prevState) => ({ ...prevState, value }));
		}

		function clearValue(initial = false) {
			updateStateAsync((prevState) => ({ ...prevState, value: !initial ? "" : initialValue }));
		}

		return {
			value,
			initialValue,
			...getFieldStateProps(value, initialValue, state, spec.validate),
			setValue,
			clearValue
		};
	},

	getScopeData: (spec, state) => {
		return {
			initialValue: state.initialValue,
			value: state.value,
			valid: state.valid,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				initialValue: Type.Any({ ...termsEditor.schemas.datetimeField.initialValue }),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.schemas.field.valid }),
				setValue: Type.Method({
					...termsEditor.schemas.field.setValue,
					argRequiredCount: 1,
					argSchemas: [ BP.String({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.schemas.field.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});

const HAEComponentDatetimeField_React: THAEComponentReact<typeof HAEComponentDatetimeField_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const {
		type,
		min,
		max,

		labelText,
		labelIcon,
		descriptionText,
		//hidden,
		readOnly,
		enabled,
		//validate,
		required,
		customValidation
	} = props;

	const { value, empty, touched, changed, valid } = state;

	const { componentMode } = componentInstance;

	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;

	const mediaContext = React.useContext(MediaContext);

	const componentPath = componentInstance.safePath;

	const typeValue = getStringEnumValue(DATETIME_FIELD_TYPE, type, DATETIME_FIELD_TYPE_default);

	// Classlist and stylesheet

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-field",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);
	const id = idClassName;
	
	classList.addModifiers({
		datetime: true,
		"datetime-type": typeValue,
		validate: props.validate
	});
	classList.addModifiers(createFieldClassListModifiers(classList, { enabled, empty, touched, changed, valid }), false);

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		if (isValidObject(mediaContext.iconPackageUrlMapping)) {
			const [ calendarContext, calendarName ] = ICON_NAME.CALENDAR.split('/');
			const [ clockContext, clockName ] = ICON_NAME.CLOCK.split('/');

			if (mediaContext.iconPackageUrlMapping[calendarContext]) {
				result.addString(
					`.${idClassName} .cmp-field__datetime-date-input::-webkit-calendar-picker-indicator`,
					`-webkit-mask: url("${mediaContext.iconPackageUrlMapping[calendarContext]}/${calendarName}.svg") no-repeat 50% 50%;`
				);
			}

			if (mediaContext.iconPackageUrlMapping[clockContext]) {
				result.addString(
					`.${idClassName} .cmp-field__datetime-time-input::-webkit-calendar-picker-indicator`,
					`-webkit-mask: url("${mediaContext.iconPackageUrlMapping[clockContext]}/${clockName}.svg") no-repeat 50% 50%;`
				);
			}
		}

		return result;
	}, [ idClassName ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	// Validate function

	const validate = React.useMemo(() => {
		if (!props.validate) {
			return false;
		}

		return (newValue: string) => {
			if (!isString(newValue)) {
				return false;
			}

			if ((newValue === "" && !required) || readOnly || !enabled) {
				return true;
			}

			const includesT = newValue.includes("T");

			if (typeValue === DATETIME_FIELD_TYPE.DATETIME || typeValue === DATETIME_FIELD_TYPE.DATETIME_FULL) {
				if (!includesT) {
					return false;
				}

				return DateTime.fromISO(newValue).isValid;
			}

			return DateTime.fromISO(
				includesT ? newValue.split("T")[typeValue === DATETIME_FIELD_TYPE.DATE ? 0 : 1] : newValue
			).isValid;
		};
	}, [ props.validate, readOnly, enabled, required, typeValue ]);

	const {
		setValue,
		setTouched
	} = useField<string>(
		{
			id,
			state,
			//readOnly, // not needed
			validate,
			customValidation,
			//validationDependencies: [ readOnly, enabled, required, typeValue ],
			onChange: !elementReadOnly && componentInstance.eventEnabled.change ? componentInstance.eventTriggers.change : undefined
		},
		setState
	);

	const inputProps: React.HTMLProps<HTMLInputElement> = {
		readOnly: elementReadOnly,
		disabled: !enabled,
		required
	};

	// Event handlers

	const _inputBlurHandler = React.useCallback(() => {
		setTouched();
	}, [ setTouched ]);

	inputProps.onBlur = _inputBlurHandler;

	// Content

	let inputContent = null;
	
	const dateInputProps = { ...inputProps };
	const timeInputProps = { ...inputProps };

	const { dateValue, timeValue } = getDateAndTimeValuesFromISOStringOrTimestamp(value);

	if (min) {
		const { dateValue: minDateValue, timeValue: minTimeValue } = getDateAndTimeValuesFromISOStringOrTimestamp(min);

		if (minDateValue) {
			dateInputProps.min = minDateValue;
		}

		if (minTimeValue) {
			timeInputProps.min = minTimeValue;
		}	
	}

	if (max) {
		const { dateValue: maxDateValue, timeValue: maxTimeValue } = getDateAndTimeValuesFromISOStringOrTimestamp(max);

		if (maxDateValue) {
			dateInputProps.max = maxDateValue;
		}

		if (maxTimeValue) {
			timeInputProps.max = maxTimeValue;
		}	
	}

	let setDateAndTimeValue: (date: string, time: string) => void;

	switch (typeValue) {
		case DATETIME_FIELD_TYPE.DATETIME:
		case DATETIME_FIELD_TYPE.DATETIME_FULL: {
			setDateAndTimeValue = (date = dateValue, time = timeValue) => {
				setValue(date && time ? `${date}T${time}` : (date || time || ""));
			}

			inputContent = <>
				<DatetimeFieldDateInput
					id={id}
					value={dateValue}
					setValue={setDateAndTimeValue}
					inputProps={dateInputProps}
				/>
				<DatetimeFieldTimeInput
					value={timeValue}
					setValue={setDateAndTimeValue}
					inputProps={timeInputProps}
					seconds={typeValue === DATETIME_FIELD_TYPE.DATETIME_FULL}
				/>
			</>;

			break;
		}

		case DATETIME_FIELD_TYPE.DATE: {
			setDateAndTimeValue = (date: string = dateValue) => {
				setValue(date);
			}

			inputContent = <DatetimeFieldDateInput
				id={id}
				value={dateValue}
				setValue={setDateAndTimeValue}
				inputProps={dateInputProps}
			/>;

			break;
		}

		case DATETIME_FIELD_TYPE.TIME:
		case DATETIME_FIELD_TYPE.TIME_FULL: {
			setDateAndTimeValue = (_date: string, time: string = timeValue) => {
				setValue(time);
			}

			inputContent = <DatetimeFieldTimeInput
				id={id}
				value={timeValue}
				setValue={setDateAndTimeValue}
				inputProps={timeInputProps}
				seconds={typeValue === DATETIME_FIELD_TYPE.TIME_FULL}
			/>;

			break;
		}
	}

	React.useEffect(() => {
		setDateAndTimeValue(dateValue, timeValue);
	}, [ typeValue ]);

	return (
		<div className={classList.toClassName()}>
			<Label
				text={{ ...labelText, tagName: "span" }}
				icon={{ ...labelIcon, size: "SMALL" }}
				tagName="label"
				htmlFor={id}
				classList={new ClassList("cmp-field__label")}
				componentPath={[ ...componentPath, "label" ]}
				componentMode={componentMode}
			>
				<FieldLabelInfo required={required} />
			</Label>

			<div className="cmp-field__content">
				<div className="cmp-field__datetime">
					{inputContent}
				</div>
			</div>

			<FieldInfo descriptionText={descriptionText} componentPath={[ ...componentPath, "info" ]} componentMode={componentMode} />
		</div>
	);
};

export const HAEComponentDatetimeField: THAEComponentDefinition<typeof HAEComponentDatetimeField_Definition> = {
	...HAEComponentDatetimeField_Definition,
	reactComponent: HAEComponentDatetimeField_React
};
