/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	SCHEMA_CONST_ANY_VALUE_TYPE
} from "@hexio_io/hae-lib-blueprint";
import { FLOW_NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintFlowNodeTypeReduce = {
	name: FLOW_NODE_TYPES.REDUCE,
	label: "Reduce",
	icon: "mdi/filter-variant",
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			items: BP.Prop(
				BP.Array({
					label: termsEditor.nodes.reduce.items.label,
					description: termsEditor.nodes.reduce.items.description,
					items: BP.Any({
						defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
					}),
					constraints: {
						required: true
					}
				})
			),
			initialValue: BP.Prop(
				BP.Any({
					label: termsEditor.nodes.reduce.initialValue.label,
					description: termsEditor.nodes.reduce.initialValue.description,
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
					constraints: {
						required: true
					}
				})
			),
			ignoreErrors: BP.Prop(
				BP.Boolean({
					label: termsEditor.nodes.reduce.ignoreErrors.label,
					description: termsEditor.nodes.reduce.ignoreErrors.description,
					default: false
				})
			)
		}
	}),
	editorOptions: {
		displayInPalette: true
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		onSuccess: { label: "On Success", icon: "mdi/check" },
		onError: { label: "On Catch", icon: "mdi/alert" },
		onItem: { label: "On Item", icon: "mdi/debug-step-into" }
	})
};
