/**
 * Orientation HAE enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum ORIENTATION {
	HORIZONTAL = "horizontal",
	VERTICAL = "vertical"
}

export type ORIENTATION_string = keyof typeof ORIENTATION;

export const ORIENTATION_default: ORIENTATION_string = "HORIZONTAL";

export const ORIENTATION_opts = {
	horizontal: {
		icon: "mdi/view-column"
	},
	vertical: {
		icon: "mdi/view-sequential"
	}
};

export const ORIENTATION_flow = {
	horizontal: "row",
	vertical: "column"
}