/**
 * Label Position enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum LABEL_POSITION {
	LEFT = "left",
	TOP = "top",
}

export type LABEL_POSITION_string = keyof typeof LABEL_POSITION;

export const LABEL_POSITION_default: LABEL_POSITION_string = "TOP";
