/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTList,
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts, TBlueprintSchemaParentNode, TGetBlueprintSchemaModel
} from "../Schema/IBlueprintSchema";
import { IModelNode, IModelNodeInfo, MODEL_CHANGE_TYPE } from "../Schema/IModelNode";
import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import {
	assignParentToArrItems,
	assignParentToObjAttributes,
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateAsNotSupported,
} from "../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { DesignContext } from "../Context/DesignContext";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { TGenericComponentInstance, TGenericComponentInstanceList } from "../Component/IComponentInstance";
import {
	TSchemaConstObjectProps
} from "./const/SchemaConstObject";
import { applyCodeArg } from "../Context/CompileUtil";
import { TypeDescArray } from "../Shared/ITypeDescriptor";
import { ISchemaComponent, ISchemaComponentDefault, ISchemaComponentModel, SchemaComponent, TSchemaComponentSpec } from "./SchemaComponent";
import { ModelNodeManipulationError } from "../Schema/ModelNodeManipulationError";
import { TComponentDefinitionMap } from "../Component/IComponentDefinition";
import { IComponentResolver } from "../Resolvers";
import { CMPL_ITEM_KIND } from "../Shared/ICompletionItem";

/**
 * Schema model
 */
export interface ISchemaComponentListModel<
	TInheritedProps extends TSchemaConstObjectProps = Record<string, never>
> extends IModelNode<ISchemaComponentList<TInheritedProps>> {
	items: ISchemaComponentModel[];
	__inheritedProps?: TSchemaConstObjectProps;
}

/**
 * Schema spec
 */
export interface ISchemaComponentListSpec<
	TInheritedProps extends TSchemaConstObjectProps = Record<string, never>
	> extends Array<TGenericComponentInstance<TInheritedProps>> {
	contentSpec: Array<TGenericComponentInstanceList<TInheritedProps>>;
}

/**
 * Schema defaults
 */
export type TSchemaComponentListDefault = Array<ISchemaComponentDefault>;

/**
 * Schema options
 */
export interface ISchemaComponentListOpts<
	TInheritedProps extends TSchemaConstObjectProps = Record<string, never>
> extends IBlueprintSchemaOpts {
	/** Allowed component categories */
	allowComponentCategories?: Array<string>;
	/** Explicit list of allowed component names */
	allowComponentNames?: Array<string>;
	/** Additional properties added to all child components */
	inheritedProps?: TInheritedProps;
	/** Function to return human friendly item label */
	getChildModelNodeInfo?: (modelNode: ISchemaComponentModel, index: number) => IModelNodeInfo;
}

/**
 * Schema type
 */
export interface ISchemaComponentList<
	TInheritedProps extends TSchemaConstObjectProps = Record<string, never>
> extends IBlueprintSchema<
	ISchemaComponentListOpts<TInheritedProps>,
	ISchemaComponentListModel<TInheritedProps>,
	ISchemaComponentListSpec<TInheritedProps>,
	TSchemaComponentListDefault
> {
	/** Overriden inherites props - used for parsing */
	__inheritedProps: TSchemaConstObjectProps;

	/** Internally used component schema */
	componentSchema: ISchemaComponent;

	/**
	 * Creates and adds a component to the list
	 */
	addItem: (
		modelNode: ISchemaComponentListModel, index: number, itemDefault?: ISchemaComponentDefault, notify?: boolean
	) => void;
	/**
	 * Adds existing component to the list
	 */
	addItemModel: (
		modelNode: ISchemaComponentListModel, index: number, itemNode: ISchemaComponentModel, notify?: boolean
	) => void;

	/**
	 * Remove item from the list
	 */
	removeItem: (modelNode: ISchemaComponentListModel, index: number, notify?: boolean) => ISchemaComponentModel;

	/**
	 * Removes item form the list by model reference
	 */
	removeItemByModel: (modelNode: ISchemaComponentListModel, itemNode: ISchemaComponentModel, notify?: boolean) => void;

	/**
	 * Remove all items from the list
	 */
	removeAllItems: (modelNode: ISchemaComponentListModel, notify?: boolean) => ISchemaComponentModel[];

	/**
	 * Return a map of available components
	 */
	getComponentList: (modelNode: ISchemaComponentListModel) => TComponentDefinitionMap;

	/**
	 * Overrides inherited props for schema declaration
	 *
	 * @param inheritedProps Inherited props schema
	 */
	overrideSchemaInheritedProps: (inheritedProps: TSchemaConstObjectProps) => void;

	/**
	 * Restores overriden inherited props for schema declaration
	 */
	restoreSchemaInheritedProps: () => void;

	/**
	 * Overrides inherited props
	 *
	 * @param modelNode Model node
	 * @param inheritedProps Inherited props schema
	 * @param notify If to notify about change
	 */
	overrideInheritedProps: (modelNode: ISchemaComponentListModel, inheritedProps: TSchemaConstObjectProps, notify?: boolean) => void;

	/**
	 * Restores inherited props to original ones (set by schema options)
	 *
	 * @param modelNode Model node
	 * @param notify If to notify about change
	 */
	restoreInheritedProps: (modelNode: ISchemaComponentListModel, notify?: boolean) => void;

	/**
	 * Returns inherited props (called by child components when assigned to this list)
	 *
	 * @param modelNode Model node
	 */
	getInheritedProps: (modelNode: ISchemaComponentListModel) => TSchemaConstObjectProps;

	/**
	 * Returns if given component name is allowed to be placed in the component list
	 *
	 * @param modelNode Model node
	 * @param componentName Component name to check
	 */
	isComponentAllowed: (modelNode: ISchemaComponentListModel, componentName: string) => boolean;

	/**
	 * Returns more humman friendly info about child node
	 * @param modelNode Component list model node
	 * @param index Child index
	 */
	getChildModelNodeInfo?: (modelNode: ISchemaComponentListModel, index: number) => IModelNodeInfo;
}

/**
 * Schema: Component list
 *
 * @param opts Schema options
 */
export function SchemaComponentList<
	TInheritedProps extends TSchemaConstObjectProps = Record<string, never>
>(opts: ISchemaComponentListOpts<TInheritedProps>): ISchemaComponentList<TInheritedProps> {

	const schemaComponent = SchemaComponent({
		allowComponentCategories: opts.allowComponentCategories,
		allowComponentNames: opts.allowComponentNames
	});

	const schema = createEmptySchema<ISchemaComponentList<TInheritedProps>>("componentList", opts);
	schema.__inheritedProps = opts.inheritedProps || {};
	schema.componentSchema = schemaComponent;

	const assignParentToChildrenOf = (srcModel) => {
		assignParentToObjAttributes(srcModel, srcModel.__inheritedProps)
		return assignParentToArrItems(srcModel, srcModel.items)
	}


	const createModel = (
		dCtx: DesignContext,
		inheritedProps: TSchemaConstObjectProps,
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx, parent, [], {
			items: [],
			__inheritedProps: inheritedProps
		});

		return assignParentToChildrenOf(modelNode)

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		if (defaultValue && !Array.isArray(defaultValue)) {
			throw new SchemaDeclarationError(schema.name, schema.opts, "Default value must be an array.");
		}

		// Create model
		const model = createModel(dCtx, schema.__inheritedProps, parent);

		if (Array.isArray(defaultValue)) {
			for (let i = 0; i < defaultValue.length; i++) {
				model.items.push(schemaComponent.createDefault(dCtx, model, defaultValue[i], {
					componentListModel: model as ISchemaComponentListModel<Record<string, never>>
				}));
			}
		}

		return model;

	};

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedModel = cloneModelNode(dCtx, modelNode, parent,{
			items: null,
			__inheritedProps: modelNode.__inheritedProps
		});

		const clone = assignParentToChildrenOf(clonedModel)

		const clonedItems = modelNode.items.map((cmp) => {
			const itemModel = cmp.schema.clone(dCtx, cmp, clone);

			itemModel.schema.assignToComponentList(itemModel, clone as ISchemaComponentListModel<Record<string, never>>, false);

			return itemModel;
		});

		clone.items = clonedItems;

		return clone;

	};

	schema.destroy = (modelNode) => {

		modelNode.items.forEach((item) => item.schema.destroy(item));
		destroyModelNode(modelNode);

	};

	schema.parse = (dCtx, idtNode, parent) => {

		// Check null
		if (!idtNode || (idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL)) {
			return schema.createDefault(dCtx, parent);
		}

		if (idtNode.type !== BP_IDT_TYPE.LIST) {

			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.LIST_NOT_LIST,
					message: "Expecting a list",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);

		}

		const model = createModel(dCtx, schema.__inheritedProps, parent);

		const items = idtNode.items.map((item) => {

			if (idtNode.parseInfo) {
				schemaComponent.provideCompletion(
					dCtx,
					idtNode.parseInfo.loc,
					idtNode.parseInfo.loc.range.start.col + 2,
					item
				);
			}

			return item ? schemaComponent.parse(dCtx, item, model, {
				componentListModel: model as ISchemaComponentListModel<Record<string, never>>
			}) : null;

		}).filter((item) => item !== null);

		model.items = items;

		return model;

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => [{
			kind: CMPL_ITEM_KIND.Snippet,
			label: "component",
			insertText: `- id: ${dCtx.getUniqueIdentifier("newComponent", true)}\n  component:`
		}]);

	};

	schema.serialize = (modelNode, path) => {

		return {
			type: BP_IDT_TYPE.LIST,
			path,
			items: modelNode.items.map((item, index) => schemaComponent.serialize(item, path.concat([`[${index}]`])))
		} as IBlueprintIDTList;

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		const prevContentSpec: Array<TGenericComponentInstanceList<TInheritedProps>> = prevSpec?.contentSpec || [];
		const contentSpec: Array<TGenericComponentInstanceList<TInheritedProps>> = [];
		const res: Array<TGenericComponentInstance<TInheritedProps>> = [];

		const basePath = (scope.globalData["__componentPath"] || []);

		// let hasChanged = false;

		for (let i = 0; i < modelNode.items.length; i++) {
			scope.globalData["__componentPath"] = basePath.concat([String(i)]);

			const item = modelNode.items[i].schema.render(
				rCtx, modelNode.items[i], path.concat([String(i)]), scope, prevContentSpec[i] as TSchemaComponentSpec
			) as TGenericComponentInstanceList<TInheritedProps>;

			contentSpec.push(item);

			for (let j = 0; j < item.length; j++) {
				res.push(item[j]);

				// if (prevSpec?.[res.length - 1] !== item[j]) {
				// 	hasChanged = true;
				// }
			}
		}

		// Optimization - returns the same instance to avoid react re-render
		// Disabled because of inheritedProps
		/*
		if (prevSpec && !hasChanged && prevSpec.length === res.length) {
			prevSpec.contentSpec = contentSpec;
			return prevSpec;
		}
		*/

		return Object.assign(res, {
			contentSpec: contentSpec
		});

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		const itemsCode = [];

		for (let i = 0; i < modelNode.items.length; i++) {
			const itemCmp = modelNode.items[i].schema.compileRender(cCtx, modelNode.items[i], path.concat([String(i)]));

			itemsCode.push([
				// Set path in scope
				`s.globalData["__componentPath"]=_bp.concat("${i}");`,
				// Render item
				`_cs[${i}]=${applyCodeArg(itemCmp, `_ps[${i}]`, `pt.concat(["${i}"])`)};`,
				// Add item components to result
				`for(let i=0;i<_cs[${i}].length;i++){_r.push(_cs[${i}][i]);if(!pv||(pv&&pv[_r.length-1]!==_r[_r.length-1])){_hs=true}}`
			].join(""));
		}

		const statements = [
			// Get prev spec
			`const _ps=(pv&&Array.isArray(pv)&&pv.contentSpec?pv.contentSpec:[]);`,
			// Store base path
			`const _bp=(s.globalData["__componentPath"]||[]);`,
			// Define content spec, res and hasChanged
			`const _cs=[];const _r=[];let _hs=false;`,
			// Inline items code
			itemsCode.join(""),
			// Return result
			// `if(pv&&!_hs&&pv.length===_r.length){pv.contentSpec=_cs; return pv}else{return Object.assign(_r,{contentSpec:_cs})}`
			`return Object.assign(_r,{contentSpec:_cs})`
		];

		return {
			isScoped: true,
			code: `(s,pv,pt)=>{${statements.join("")}}`
		};

	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {

		return exportSchema("SchemaComponentList", [opts]);

	};

	schema.getTypeDescriptor = (modelNode) => {

		return TypeDescArray({
			label: opts.label,
			description: opts.description,
			items: modelNode?.items.map((item) => item.schema.getTypeDescriptor(item)) || [],
			example: opts.example,
			tags: opts.tags
		});

	};

	schema.addItem = (modelNode: ISchemaComponentListModel, index: number, itemDefault?: ISchemaComponentDefault, notify?: boolean) => {

		const item = schemaComponent.createDefault(modelNode.ctx, modelNode, itemDefault, {
			componentListModel: modelNode
		});

		modelNode.items.splice(index, 0, item);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	};

	schema.addItemModel = (modelNode: ISchemaComponentListModel, index: number, itemNode: ISchemaComponentModel, notify?: boolean) => {

		itemNode.schema.assignToComponentList(itemNode, modelNode, notify);
		modelNode.items.splice(index, 0, itemNode);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	};

	schema.removeItem = (modelNode: ISchemaComponentListModel, index: number, notify?: boolean) => {

		const item = modelNode.items.splice(index, 1);

		if (!item[0]) {
			throw new ModelNodeManipulationError(
				schema.name, schema.opts, `Cannot remove item on index '${index}' because it doesn't exists.`
			);
		}

		item[0].schema.unassignFromComponentList(item[0], notify);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return item[0];

	};

	schema.removeAllItems = (modelNode: ISchemaComponentListModel, notify?: boolean) => {

		const items = modelNode.items.splice(0, modelNode.items.length);

		for (let i = 0; i < items.length; i++) {
			items[i].schema.unassignFromComponentList(items[i], notify);
		}

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return items;

	};

	schema.removeItemByModel = (modelNode: ISchemaComponentListModel, itemNode: ISchemaComponentModel, notify?: boolean) => {

		const i = modelNode.items.indexOf(itemNode);

		if (i < 0) {
			throw new ModelNodeManipulationError(
				schema.name, schema.opts, `Cannot remove item with nodeId '${itemNode.nodeId}' because it doesn't exists in this list.`
			);
		}

		modelNode.items.splice(i, 1);
		itemNode.schema.unassignFromComponentList(itemNode, notify);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	};

	schema.getComponentList = (modelNode: ISchemaComponentListModel) => {

		return modelNode.ctx.getResolver<IComponentResolver>("component").getFilteredList(opts.allowComponentCategories);

	};

	schema.overrideSchemaInheritedProps = (inheritedProps: TSchemaConstObjectProps) => {

		schema.__inheritedProps = inheritedProps;

	};

	schema.restoreSchemaInheritedProps = () => {

		schema.__inheritedProps = opts.inheritedProps || {};

	};

	schema.overrideInheritedProps = (modelNode: ISchemaComponentListModel, inheritedProps: TSchemaConstObjectProps, notify?: boolean) => {

		modelNode.__inheritedProps = inheritedProps;

		for (let i = 0; i < modelNode.items.length; i++) {
			const item = modelNode.items[i];
			item.schema.assignToComponentList(item, modelNode, notify);
		}

	};

	schema.restoreInheritedProps = (modelNode: ISchemaComponentListModel, notify?: boolean) => {

		modelNode.__inheritedProps = opts.inheritedProps || {};

		for (let i = 0; i < modelNode.items.length; i++) {
			const item = modelNode.items[i];

			if (opts.inheritedProps) {
				item.schema.assignToComponentList(item, modelNode, notify);
			} else {
				item.schema.unassignFromComponentList(item, notify);
			}
		}

	};

	schema.getInheritedProps = (modelNode: ISchemaComponentListModel) => {

		return modelNode.__inheritedProps;

	};

	schema.isComponentAllowed = (modelNode: ISchemaComponentListModel, componentName: string): boolean => {

		const allowNames = modelNode.ctx.getResolver<IComponentResolver>("component").getFilteredList(opts.allowComponentCategories);
		return !!allowNames[componentName] || opts.allowComponentNames?.includes(componentName);

	};

	schema.getChildNodes = (modelNode) => {
		return modelNode.items.map((item, index) => ({
			key: "items." + index,
			node: item
		}));
	}

	schema.getChildModelNodeInfo = (modelNode, index) => {
		const item = modelNode.items[index];

		if (!item) {
			throw new Error("Item index out of range.");
		}

		if (opts.getChildModelNodeInfo) {
			return opts.getChildModelNodeInfo(item, index);
		} else {
			return {
				icon: "mdi/arrow-right",
				label: "#" + (index + 1)
			};
		}
	};

	return schema;

}
