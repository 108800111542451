/**
 * Container schema
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaComponentListOpts,
	ISchemaConstEnum,
	ISchemaConstString,
	ISchemaValue,
	ISchemaValueEnumStringOpts,
	TSchemaConstObjectPropsSpec,
} from "@hexio_io/hae-lib-blueprint";
import { spacingPattern } from "../RegExp/spacingPattern";

import { CONTAINER_FLOW, CONTAINER_FLOW_default, CONTAINER_FLOW_opts } from "../Enums/CONTAINER_FLOW";
import {
	CONTAINER_ITEM_FLEX,
	CONTAINER_ITEM_FLEX_default,
	CONTAINER_ITEM_FLEX_inherit,
	CONTAINER_ITEM_FLEX_resolution_default
} from "../Enums/CONTAINER_ITEM_FLEX";
import { CONTAINER_ROLE, CONTAINER_ROLE_default } from "../Enums/CONTAINER_ROLE";
import { HORIZONTAL_ALIGN, HORIZONTAL_ALIGN_opts, HORIZONTAL_ALIGN_stretch } from "../Enums/HORIZONTAL_ALIGN";
import { VERTICAL_ALIGN, VERTICAL_ALIGN_opts, VERTICAL_ALIGN_top } from "../Enums/VERTICAL_ALIGN";
import { SPACING, SPACING_default, SPACING_none } from "../Enums/SPACING";

import { getValuesFromStringEnum } from "../Functions/enumHelpers";
import { termsEditor } from "../terms";
import { ItemInheritedProps } from "./ItemComponent";
import { propGroups } from "./propGroups";
import { OVERFLOW, OVERFLOW_default } from "../Enums/OVERFLOW";
import { LoadingSchema } from "../SharedSchemas/LoadingSchema";
import { BackgroundColorSchema, BorderColorSchema, ForegroundColorSchema } from "./Color";
import { BackgroundImagesSchema } from "./BackgroundImages";
import { BorderRadiusSchema } from "./BorderRadius";
import { BoxShadowSchema } from "./BoxShadow";
import { PositionSchema } from "./Position";

/**
 * Defaults
 */
export const CONTAINER_DEFAULT_HORIZONTAL_ALIGN = HORIZONTAL_ALIGN_stretch;
export const CONTAINER_DEFAULT_VERTICAL_ALIGN = VERTICAL_ALIGN_top;

/**
 * Container Item Inherited props
 */
export const ContainerItemInheritedProps = {

	containerItemFlex: BP.Prop(BP.ResponsiveValue({
		...termsEditor.schemas.container.contentItemFlex,
		value: BP.StringWithConst({
			constants: [
				{
					...termsEditor.schemas.common.inherit,
					value: CONTAINER_ITEM_FLEX_inherit
				},
				...getValuesFromStringEnum(CONTAINER_ITEM_FLEX, termsEditor.schemas.container.contentItemFlexValues)
			],
			default: CONTAINER_ITEM_FLEX_default,
			fallbackValue: CONTAINER_ITEM_FLEX_default,
			constraints: {
				...termsEditor.schemas.container.contentItemFlexConstraints,
				pattern: "^((\\S+)( \\S+){0,2})$",
				required: true
			}
		}),
		default: CONTAINER_ITEM_FLEX_resolution_default
	}), 100),

	containerItemPosition: BP.Prop(PositionSchema(), 110)

};

/**
 * Container Content schema
 *
 * @param opts Options
 */
export function ContainerContentSchema(opts: Partial<ISchemaComponentListOpts> = { alias: "containerContent" }) {
	return BP.ComponentList<TContainerContentInheritedProps>({
		...termsEditor.schemas.container.content,
		...opts,
		inheritedProps: {
			...ItemInheritedProps,
			...ContainerItemInheritedProps,
			...opts.inheritedProps
		}
	});
}

/**
 * Container Content Inherited Props types
 */
export type TContainerContentInheritedProps = typeof ItemInheritedProps & typeof ContainerItemInheritedProps;

/**
 * Container Horizontal Alignment schema
 * 
 * @param opts Options
 */
export function ContainerHorizontalAlignSchema(
	opts: Partial<ISchemaValueEnumStringOpts> = { alias: "containerHorizontalAlign" }
): ISchemaValue<ISchemaConstEnum<ISchemaConstString>> {
	return BP.Enum.String({
		...termsEditor.schemas.common.horizontalAlign,
		options: getValuesFromStringEnum(
			HORIZONTAL_ALIGN,
			termsEditor.schemas.common.horizontalAlignValues,
			HORIZONTAL_ALIGN_opts
		),
		default: CONTAINER_DEFAULT_HORIZONTAL_ALIGN,
		fallbackValue: CONTAINER_DEFAULT_HORIZONTAL_ALIGN,
		editorOptions: {
			controlType: "buttons"
		},
		...opts,
		constraints: {
			required: true,
			...opts.constraints
		},
	});
}

/**
 * Container Vertical Alignment schema
 * 
 * @param opts Options
 */
export function ContainerVerticalAlignSchema(
	opts: Partial<ISchemaValueEnumStringOpts> = { alias: "containerVerticalAlign" }
): ISchemaValue<ISchemaConstEnum<ISchemaConstString>> {
	return BP.Enum.String({
		...termsEditor.schemas.common.verticalAlign,
		options: getValuesFromStringEnum(
			VERTICAL_ALIGN,
			termsEditor.schemas.common.verticalAlignValues,
			VERTICAL_ALIGN_opts
		),
		default: CONTAINER_DEFAULT_VERTICAL_ALIGN,
		fallbackValue: CONTAINER_DEFAULT_VERTICAL_ALIGN,
		editorOptions: {
			controlType: "buttons"
		},
		...opts,
		constraints: {
			required: true,
			...opts.constraints
		}
	});
}

/**
 * Container props
 */
export const ContainerProps = {

	content: BP.Prop(ContainerContentSchema(), 0, propGroups.common),

	flow: BP.Prop(BP.Enum.String({
		...termsEditor.schemas.container.flow,
		options: getValuesFromStringEnum(
			CONTAINER_FLOW,
			termsEditor.schemas.container.flowValues,
			CONTAINER_FLOW_opts
		),
		default: CONTAINER_FLOW_default,
		fallbackValue: CONTAINER_FLOW_default,
		constraints: {
			required: true
		},
		editorOptions: {
			controlType: "buttons"
		}
	}), 100, propGroups.common),

	size: BP.Prop(BP.ResponsiveValue({
		...termsEditor.schemas.container.size,
		value: BP.Integer({
			constraints: {
				min: 1
			}
		})
	}), 110, propGroups.common),

	horizontalAlign: BP.Prop(ContainerHorizontalAlignSchema(), 120, propGroups.common),

	verticalAlign: BP.Prop(ContainerVerticalAlignSchema(), 130, propGroups.common),

	backgroundColor: BP.Prop(BackgroundColorSchema(), 10, propGroups.style),

	backgroundImages: BP.Prop(BackgroundImagesSchema(), 15, propGroups.style),

	foregroundColor: BP.Prop(ForegroundColorSchema(), 20, propGroups.style),

	borderColor: BP.Prop(BorderColorSchema(), 30, propGroups.style),

	borderWidth: BP.Prop(BP.String({
		...termsEditor.schemas.common.borderWidth,
		default: "",
	}), 40, propGroups.style),

	borderRadius: BP.Prop(BorderRadiusSchema(), 50, propGroups.style),

	boxShadow: BP.Prop(BoxShadowSchema(), 60, propGroups.style),

	padding: BP.Prop(BP.StringWithConst({
		...termsEditor.schemas.container.padding,
		example: "MEDIUM; 100px; 10px 15px 0px 5px; 50%",
		constants: getValuesFromStringEnum(SPACING, termsEditor.schemas.common.spacingValues),
		default: SPACING_none,
		constraints: {
			...termsEditor.schemas.common.spacingConstraints,
			pattern: spacingPattern
		}
	}), 100, propGroups.style),

	spacing: BP.Prop(BP.StringWithConst({
		...termsEditor.schemas.container.spacing,
		example: "MEDIUM; 100px; 10px 15px 0px 5px; 50%",
		constants: getValuesFromStringEnum(SPACING, termsEditor.schemas.common.spacingValues),
		default: SPACING_default,
		constraints: {
			...termsEditor.schemas.common.spacingConstraints,
			pattern: spacingPattern
		}
	}), 110, propGroups.style),

	overflow: BP.Prop(BP.Enum.String({
		...termsEditor.schemas.common.overflow,
		options: getValuesFromStringEnum(OVERFLOW, termsEditor.schemas.common.overflowValues),
		default: OVERFLOW_default,
		fallbackValue: OVERFLOW_default,
		constraints: {
			required: true
		}
	}), 120, propGroups.style),

	loading: BP.Prop(LoadingSchema(), 100, propGroups.advanced),

	role: BP.Prop(BP.Enum.String({
		...termsEditor.schemas.container.role,
		options: getValuesFromStringEnum(CONTAINER_ROLE, termsEditor.schemas.container.roleValues),
		default: CONTAINER_ROLE_default,
		fallbackValue: CONTAINER_ROLE_default,
		constraints: {
			required: true
		}
	}), 110, propGroups.advanced),

};

export type TContainerProps = Partial<TSchemaConstObjectPropsSpec<typeof ContainerProps>>;
