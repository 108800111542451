/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaFlowNodeTypeOutputDefinitionMap, SCHEMA_CONST_ANY_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";
import { BodyBlueprintSchema, FLOW_NODE_TYPES, HeadersBlueprintSchema } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintFlowNodeTypeRedirect = {
	name: FLOW_NODE_TYPES.REDIRECT,
	label: "Redirect",
	icon: "mdi/arrow-left-bottom",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			url: BP.Prop(BP.String({
				label: termsEditor.nodes.redirect.url.label,
				description: termsEditor.nodes.redirect.url.description,
				default: "/",
				constraints: {
					required: true
				}
			})),
			status: BP.Prop(BP.Integer({
				label: termsEditor.nodes.common.status.label,
				description: termsEditor.nodes.common.status.description,
				default: 301,
				constraints: {
					required: true,
					min: 300,
					max: 399
				}
			})),
			headers: BP.Prop(HeadersBlueprintSchema),
			query: BP.Prop(BP.Map({
				label: termsEditor.nodes.redirect.query.label,
				description: termsEditor.nodes.redirect.query.description,
				value: BP.Any({
					label: termsEditor.nodes.redirect.query.value.label,
					description: termsEditor.nodes.redirect.query.value.description,
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
					allowedTypes: {
						[SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN]: true,
						[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true,
						[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
						[SCHEMA_CONST_ANY_VALUE_TYPE.ARRAY]: true
					}
				})
			})),
			body: BP.Prop(BodyBlueprintSchema)
		}
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({})
};