/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaFlowNodeTypeOutputDefinitionMap, SCHEMA_CONST_ANY_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";
import { FLOW_NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintFlowNodeTypeError = {
	name: FLOW_NODE_TYPES.ERROR,
	label: "Throw",
	icon: "mdi/alert-circle",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			message: BP.Prop(BP.String({
				label: termsEditor.nodes.error.message.label,
				description: termsEditor.nodes.error.message.description,
				constraints: {
					required: true
				},
				fallbackValue: "Error"
			})),
			errorName: BP.Prop(BP.String({
				label: termsEditor.nodes.error.errorName.label,
				description: termsEditor.nodes.error.errorName.description,
				constraints: {
					required: false
				}
			})),
			data: BP.Prop(BP.Any({
				label: termsEditor.nodes.error.data.label,
				description: termsEditor.nodes.error.data.description,
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
				constraints: {
					required: false
				}
			}))
		}
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({})
}