/**
 * Hexio App Engine Integration extensions base library.
 *
 * @package hae-ext-integrations-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAppClient } from "@hexio_io/hae-lib-core";

export const registerExtension = ( app: IAppClient ) => {
	//
};