/**
 * Button Group schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const buttonGroup = {
	content: {
		label: "schema:buttonGroup.content#label",
	},
	contentItemUseParentStyle: {
		label: "schema:buttonGroup.content#inheritedProps.itemOverrideStyle#label",
		description: "schema:buttonGroup.content#inheritedProps.itemOverrideStyle#description"
	},
	typeData: {
		label: "schema:buttonGroup.typeData#label"
	},
	typeDataValues: {
		none: {
			label: "schema:buttonGroup.typeData#values.none#label"
		},
		toggle: {
			label: "schema:buttonGroup.typeData#values.toggle#label"
		},
		switch: {
			label: "schema:buttonGroup.typeData#values.switch#label"
		}
	},
	borderRadiusGroup: {
		label: "schema:buttonGroup.borderRadiusGroup#label"
	},
	separatorColor: {
		label: "schema:buttonGroup.separatorColor#label"
	},
	separatorSize: {
		label: "schema:buttonGroup.separatorSize#label"
	},
	activeRequired: {
		label: "schema:buttonGroup.activeRequired#label"
	},
	scopeActive: {
		label: "schema:buttonGroup.scopeActive#label"
	},
	scopeMatching: {
		label: "schema:buttonGroup.scopeMatching#label"
	},
	scopeStates: {
		label: "schema:buttonGroup.scopeStates#label"
	}
};
