/**
 * Hexio App Engine: Set of base components
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAppClient, ITranslationManager } from "@hexio_io/hae-lib-core"
import { registerComponents } from ".";
import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { TGenericHAEComponentDefinition } from "@hexio_io/hae-lib-components";

export const registerExtension = (application: IAppClient): void => {

	const registry = application.get("componentRegistry");
	const translationManager = application.get("translationManager");

	registerComponents(registry, translationManager);

	// Register server/client implementation specific components
	//registry.register(HAEComponentChart);

};

// We must export for sandbox specifically because some dependencies must be bundled only client-side
export const registerStandalone = (
	registry: ItemRegistry<TGenericHAEComponentDefinition>,
	translationManager: ITranslationManager
): void => {

	registerComponents(registry, translationManager);

	// Register server/client implementation specific components
	//registry.register(HAEComponentChart);

};