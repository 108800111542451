/**
 * Datetime Field HAE component input
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { DateTime } from "luxon";

/**
 * Datetime Field Input base props
 */
interface IDatetimeFieldInputBaseProps {
	/** Field id */
	id?: string;

	/** Value */
	value: string;

	/** Set value */
	setValue: (date?: string, time?: string) => void;

	/** Input props */
	inputProps: React.HTMLProps<HTMLInputElement>;
}

/**
 * Datetime Field Date Input props
 */
interface IDatetimeFieldDateInputProps extends IDatetimeFieldInputBaseProps {}

/**
 * Datetime Field Date Input component
 */
export const DatetimeFieldDateInput: React.FunctionComponent<IDatetimeFieldDateInputProps> = (props) => {
	const { id, value, setValue, inputProps } = props;

	const dateTime = value ? DateTime.fromISO(value) : null;

	function _inputChangeHandler(event: React.FormEvent<HTMLInputElement>) {
		const target = event.currentTarget;
		const inputDateTime = target.value ? DateTime.fromISO(target.value) : null;

		setValue((inputDateTime && inputDateTime.isValid) ? inputDateTime.toISODate() : "");
	}

	return (
		<div className="cmp-field__datetime-date">
			<input
				id={id}
				type="date"
				value={dateTime?.isValid ? dateTime.toISODate() : ""}
				className="cmp-field__datetime-date-input"
				onChange={_inputChangeHandler}
				{...inputProps}
			/>
		</div>
	);
}

/**
 * Datetime Field Time Input props
 */
interface IDatetimeFieldTimeInputProps extends IDatetimeFieldInputBaseProps {
	/** Include seconds */
	seconds?: boolean;
}

/**
 * Datetime Field Time Input component
 */
export const DatetimeFieldTimeInput: React.FunctionComponent<IDatetimeFieldTimeInputProps> = (props) => {
	const { id, value, setValue, inputProps, seconds = false } = props;

	const dateTime = value ? DateTime.fromISO(value) : null;

	function _inputChangeHandler(event: React.FormEvent<HTMLInputElement>) {
		const target = event.currentTarget;
		const inputDateTime = target.value ? DateTime.fromISO(target.value) : null;

		setValue(undefined, (inputDateTime && inputDateTime.isValid) ? inputDateTime.toISOTime() : "");
	}

	return (
		<div className="cmp-field__datetime-time">
			<input
				id={id}
				type="time"
				value={dateTime?.isValid ? dateTime.toFormat(seconds ? "HH:mm:ss" : "HH:mm") : ""}
				step={seconds ? 1 : 60}
				className="cmp-field__datetime-time-input"
				onChange={_inputChangeHandler}
				{...inputProps}
			/>
		</div>
	);
}