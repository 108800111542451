/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry, ItemRegistryItemProps } from "@hexio_io/hae-lib-shared";

/**
 * Blueprint Template props
 */
export interface IBlueprintTemplatesProps extends ItemRegistryItemProps {

	label: string;
	blueprintFilename: string;
	resourceType: string;
	getContent: (id: string, params?: { [K: string]: unknown }) => string;

}

/**
 * Blueprint Templates registry
 */
export type IBlueprintTemplatesRegistry = ItemRegistry<IBlueprintTemplatesProps>;

export class BlueprintTemplatesRegistry extends ItemRegistry<IBlueprintTemplatesProps>{ }