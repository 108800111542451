/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportValidator } from "../ExportImportSchema/ExportSchema";
import {
	IBlueprintSchemaValidationError,
	IBlueprintSchemaValidator,
	IBlueprintSchemaValidatorHandler,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { ValidatorDeclarationError } from "../Shared/ValidatorDeclarationError";

type TValidatorSecretHandler = IBlueprintSchemaValidatorHandler<string>;
type TValidatorSecret<TOpts> = IBlueprintSchemaValidator<string, TOpts>;

/**
 * Secret value validator options
 */
export type IValidatorSecretOpts = {
	/** Required value */
	required?: boolean;
};

const VALIDATOR_NAME = "ValidatorSecret";

/**
 * Secret value validator
 */
export const ValidatorSecret: TValidatorSecret<IValidatorSecretOpts> =
	(opts: IValidatorSecretOpts): TValidatorSecretHandler => {

		if (opts.required !== undefined && opts.required !== null && typeof opts.required !== "boolean") {
			throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `required` to be a boolean");
		}

		return {

			validate: (value: string): IBlueprintSchemaValidationError[] => {

				const errors = [];

				if (
					(opts.required === true && (typeof value !== "string")) ||
					(!opts.required && !(value === null || value === undefined) && (typeof value !== "string"))
				) {
					errors.push({
						type: SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED,
						message: "Should be a string"
					});
				}

				return errors;

			},

			compile: (): string => {

				const parts = [];

				if (opts.required === true) {
					// eslint-disable-next-line max-len
					parts.push(`if(typeof value !== "string"){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message: "Should be a string" }); }`);
				}

				if (!opts.required) {
					// eslint-disable-next-line max-len
					parts.push(`if(!(value === null || value === undefined) && (typeof value !== "string")){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message: "Should be a string" }); }`);
				}

				// Minification
				const code = parts.join(" ")
					.replace(/{ /g, "{")
					.replace(/ }/g, "}")
					.replace(/: "/g, ":\"")
					.replace(/ ,/g, ",")
					.replace(/ === /g, "===")
					.replace(/ && /g, "&&")
					.replace(/ !== /g, "!==")
					.replace(/"string"/g, "s")
					.replace(/value/g, "v")
					.replace(/errors.push/g, "e.push");

				return `const e=[];const s="string";${code} return e;`;

			},

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			export: (): any => {
				return exportValidator(VALIDATOR_NAME, [opts]);
			}

		}
	};
