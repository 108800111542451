/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGenericBlueprintSchema } from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../app";
import { createAuthProviderFactory } from "./createAuthProviderFactory";
import { IAuthProvider, IAuthProviderOptions } from "./IAuthProvider";
import { IAuthProviderDefinition } from "./IAuthProviderDefinition";

/**
 * Creates Auth Provider definition
 */
export function createAuthProviderDefinition<
	TOptions extends IAuthProviderOptions,
	TClass extends IAuthProvider
>(
	name: string,
	category: string,
	label: string,
	optionsSchema: TGenericBlueprintSchema,
	entityClass: new (
		authProviderName: string,
		config: TOptions,
		app: IAppServer
	) => TClass
): IAuthProviderDefinition<TOptions, TClass> {

	return {
		name,
		category,
		label,
		authProviderFactory: createAuthProviderFactory(entityClass),
		optionsSchema
	};

}