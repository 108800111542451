/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	ISchemaConstObject,
	ISchemaValue,
	TRuntimeContextRenderFunction,
	TSchemaConstObjectProps
} from "@hexio_io/hae-lib-blueprint";
import { ISchemaImportExport } from "@hexio_io/hae-lib-blueprint";

import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { IBlueprintRegistryItemProps, TBlueprintActionSpecSchema } from "../blueprints";

/**
 * Registered action instance item props
 */
export interface IActionInstanceProps extends IBlueprintRegistryItemProps {

	/** Params schema */
	paramsSchema: ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>>;

	/** Render function */
	renderFunction?: TRuntimeContextRenderFunction<TBlueprintActionSpecSchema>;

	/** If view requires authenticated user to access it */
	requireAuthenticatedUser: boolean;

	/** Action timeout */
	timeout: number;

	/** Max recursion */
	maxRecursion: number;

	/** Export data for paramSchema */
	paramsSchemaExport?: ISchemaImportExport;

	/** If the action instance is valid and fully loaded */
	isValid: boolean;

}

/**
 * Action Instance Registry
 */
export type IActionInstanceRegistry = ItemRegistry<IActionInstanceProps>;

export class ActionInstanceRegistry extends ItemRegistry<IActionInstanceProps>{ }