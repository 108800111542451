/**
 * ClassList class
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { COMPONENT_MODE, TComponentNodePath, TGenericComponentInstance } from "@hexio_io/hae-lib-blueprint";

import { isBoolean, isNilOrEmptyString, isNonEmptyArray, isNonEmptyString, isUndefined } from "@hexio_io/hae-lib-shared";

import { CSS_MEDIA_CLASS } from "./../Enums/CSS_MEDIA_CLASS";

/**
 * ClassList
 */
export type IClassList = string[];

/**
 * ClassList object
 */
export interface IClassListObject {
	[K: string]: boolean;
}

/**
 * ClassList map
 */
export interface IClassListMap {
	[K: string]: ClassList;
}

/**
 * Modifiers input object
 */
export type TModifiersObject = Record<string, string | number | boolean | null | undefined>;

/**
 * ClassList modifiers
 */
export type TClassListModifiers = Record<string, string>;

/**
 * ClassList class
 *
 * Simple class for handling classLists
 */
export class ClassList extends Array implements IClassList {
	/**
	 * ClassList constructor
	 *
	 * @param classNames multiple classNames (strings)
	 */
	constructor(...classNames: IClassList) {
		super();

		this.add(...classNames);
	}

	/**
	 * Creates classList from classListObject
	 *
	 * Resolves key-value pairs where key is a className and value a boolean
	 * indicating whether or not should className be added to new classList
	 *
	 * @param classListObject key-value pairs
	 */
	public static fromObject(classListObject: IClassListObject): ClassList {
		return new ClassList(...Object.keys(classListObject).filter((item) => classListObject[item]));
	}

	/**
	 * Creates classList from string of classNames
	 *
	 * @param className Class name string
	 * @param prefix Optional prefix
	 */
	public static fromString(className: string, prefix = ""): ClassList {
		return new ClassList(...className.split(" ").map((item: string) => prefix + item));
	}

	/**
	 * Creates Element's base ClassList and IdClassName
	 *
	 * @param name Element name
	 * @param path Path for basic usage
	 * @param opts Options for advanced usage
	 */
	public static getElementClassListAndIdClassName(
		name: string,
		path: TComponentNodePath = [],
		opts: {
			componentInstance?: TGenericComponentInstance,
			componentClassList?: ClassList
			componentMode?: COMPONENT_MODE // overrides the one from instance
		} = {}
	): {
		classList: ClassList,
		idClassName: string
	} {
		const {
			componentInstance,
			componentClassList,
			componentMode
		} = opts;

		const componentInstanceDefined = typeof componentInstance !== "undefined";

		const safePath = isNonEmptyArray(path) ?
			path :
			(
				componentInstanceDefined ?
					(
						isNonEmptyArray(componentInstance.safePath) ?
							componentInstance.safePath :
							[ `id-${componentInstance.id}-uid-${componentInstance.uid}` ]
					) :
					null
			);

		const id = safePath && safePath.join("_") || "none";

		const idClassName = `${name}--id-${id}`;

		const mode = !isUndefined(componentMode) ? componentMode : (componentInstanceDefined ? componentInstance.componentMode : "none");

		const classList = new ClassList(name, idClassName).addModifiers({ mode });

		if (componentClassList) {
			classList.add(...componentClassList);
		}

		if (componentInstanceDefined && componentInstance.eventEnabled) {
			Object.keys(componentInstance.eventEnabled).filter((item) => componentInstance.eventEnabled[item]).forEach((item) => {
				classList.add(`${name}--event-${item}`);
			});
		}

		return {
			classList,
			idClassName
		};
	}

	/**
	 * Resolves CSS Media classNames
	 *
	 * @param mediaKey Media key words
	 */
	public static resolveMediaClassNames(mediaKey: string): ClassList {
		const result = new ClassList();

		result.addString(
			mediaKey.split(" ").filter((item: string) => item in CSS_MEDIA_CLASS).map((item: string) => CSS_MEDIA_CLASS[item]).join(" ")
		);

		return result;
	}

	/**
	 * Checks whether value is classlist or not
	 * 
	 * @param classList Class List
	 */
	public static isClassList(classList: unknown): classList is ClassList {
		return classList instanceof ClassList;
	}

	/**
	 * Adds classNames
	 *
	 * @param classNames Multiple classNames (strings) to add
	 */
	public add(...classNames: IClassList): ClassList {
		const classNamesToAdd = classNames.filter((item, index) => !this.contains(item) && classNames.indexOf(item) === index);

		this.push(...classNamesToAdd);

		return this;
	}

	/**
	 * Adds string className, resolves multiple classNames in one string
	 *
	 * @param className Single className string to add
	 */
	public addString(className: string): ClassList {
		return this.add(...className.split(" "));
	}

	/**
	 * Removes classNames
	 *
	 * @param classNames Multiple classNames (strings) to remove
	 */
	public remove(...classNames: IClassList): ClassList {
		const indexes: Array<number> = [];

		classNames.forEach((item) => {
			const index = this.indexOf(item);

			if (index >= 0) {
				indexes.push(index);
			}
		});

		const indexesLength = indexes.length;

		if (indexesLength > 0) {
			if (indexesLength > 1) {
				/** Sort indexes from highest to lowest so they won't shift when removing classNames one after another */
				indexes.sort((a, b) => b - a);
			}

			indexes.forEach((item) => {
				this.splice(item, 1);
			});
		}

		return this;
	}

	/**
	 * Checks if className is contained
	 *
	 * @param className className to check
	 */
	public contains(className: string): boolean {
		return this.includes(className);
	}

	/**
	 * Returns base className
	 */
	public getBaseClassName(): string {
		return this[0];
	}

	/**
	 * Toggles className
	 *
	 * @param className className to add/remove
	 * @param force If set to true then always adds a className, if set to false then removes a className
	 */
	public toggle(className: string, force: boolean): ClassList {
		if (force === true) {
			return this.add(className);
		}

		if (force === false || this.contains(className)) {
			return this.remove(className);
		}

		return this.add(className);
	}

	/**
	 * Creates className from the ClassList
	 */
	public toClassName(): string {
		return this.join(" ");
	}

	/**
	 * Adds modifiers
	 * 
	 * @param modifiers Modifiers
	 * @param baseClassName Base className, set to false to not create modifiers
	 */
	public addModifiers(
		modifiers: TModifiersObject = {},
		baseClassName: string | boolean = this.getBaseClassName()
	): ClassList {
		Object.values(
			isNonEmptyString(baseClassName) ? this.createModifiers(modifiers, baseClassName) : modifiers as TClassListModifiers
		).forEach((item) => this.add(item));

		return this;
	}

	/**
	 * Creates modifiers object
	 * 
	 * @param modifiers Modifiers
	 * @param baseClassName Base className
	 */
	public createModifiers(
		modifiers: TModifiersObject = {},
		baseClassName = this.getBaseClassName()
	): TClassListModifiers {
		const result: TClassListModifiers = {};

		Object.entries(modifiers).forEach(([ modifierKey, modifierValue ]) => {
			// Skip modifiers with null, undefined or empty value
			if (isNilOrEmptyString(modifierValue)) {
				return;
			}

			// Boolean modifiers. e.g. "button--disabled"
			if (isBoolean(modifierValue)) {
				if (modifierValue) {
					result[modifierKey] = `${baseClassName}--${modifierKey}`;
				}

				return;
			}

			// Complicated modifiers. e.g. "button--type-submit"
			result[modifierKey] = `${baseClassName}--${modifierKey}-${modifierValue}`;
		});

		return result;
	}

}
