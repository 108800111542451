/**
 * Options Field HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, SCHEMA_CONST_ANY_VALUE_TYPE, SCHEMA_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";

import {
	ENUM_DEFAULT_VALUE,
	getValuesFromStringEnum,
	propGroups,
	termsEditor as HAELibComponentsTerms,
	TextProps,
	TextSchema,
	TextValueSchema
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { FieldBaseProps, FieldLabelTextProps } from "./props";
import { OPTIONS_FIELD_TYPE } from "../../Enums/OPTIONS_FIELD_TYPE";
import { OPTIONS_FIELD_CUSTOM_VALUE_TYPE, OPTIONS_FIELD_CUSTOM_VALUE_TYPE_default } from "../../Enums/OPTIONS_FIELD_CUSTOM_VALUE_TYPE";

export const HAEComponentOptionsField_Props = {

	...FieldBaseProps,

	value: BP.Prop(BP.String({
		...termsEditor.schemas.optionsField.value,
		default: null,
		/*fallbackValue: null,
		constraints: {
			required: true
		}*/
	}), 0, propGroups.common),

	items: BP.Prop(BP.Array({
		...termsEditor.schemas.optionsField.items,
		default: [],
		fallbackValue: [],
		items: BP.Object({
			props: {
				value: BP.Prop(BP.String({
					...termsEditor.schemas.optionsFieldItem.value,
					default: "",
					fallbackValue: "",
					constraints: {
						required: true
					}
				}), 0),

				labelText: BP.Prop(TextSchema({
					alias: "text_optionsFieldItemLabel",
					props: {
						...FieldLabelTextProps,

						value: BP.Prop(TextValueSchema({
							alias: "textValue_optionsFieldItemLabel",
							default: "Item"
						}), TextProps.value.order, TextProps.value.group),
					}
				}), FieldBaseProps.labelText.order),

				labelIcon: BP.Prop(FieldBaseProps.labelIcon.schema, FieldBaseProps.labelIcon.order),

				descriptionText: BP.Prop(FieldBaseProps.descriptionText.schema, FieldBaseProps.descriptionText.order),

				customData: BP.Prop(BP.Any({
					...HAELibComponentsTerms.schemas.common.customData,
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
				}), 200)
			}
		}),
		outlineOptions: {
			displayChildren: true,
			allowAddElement: true
		},
		getElementModelNodeInfo: (modelNode) => {
			let label: string;

			if (modelNode.type === SCHEMA_VALUE_TYPE.CONST) {
				const labelText = modelNode.constant.props.labelText.type === SCHEMA_VALUE_TYPE.CONST &&
					modelNode.constant.props.labelText.constant.props.value.type === SCHEMA_VALUE_TYPE.CONST &&
					modelNode.constant.props.labelText.constant.props.value.constant.value;

				const value = modelNode.constant.props.value.type === SCHEMA_VALUE_TYPE.CONST &&
					modelNode.constant.props.value.constant.value;

				label = (labelText ? `${labelText}${value ? ` (${value})` : ""}` : value) || null;
			}

			return {
				label,
				icon: "mdi/radiobox-marked"
			}
		}
	}), 10, propGroups.common),

	type: BP.Prop(BP.Enum.String({
		...termsEditor.schemas.optionsField.type,
		options: getValuesFromStringEnum(OPTIONS_FIELD_TYPE, termsEditor.schemas.optionsField.typeValues),
		default: ENUM_DEFAULT_VALUE
	}), 20, propGroups.common),

	placeholder: BP.Prop(BP.String({
		...termsEditor.schemas.optionsField.placeholder
	}), 30, propGroups.common),

	allowCustomValue: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.optionsField.allowCustomValue,
		enabledOpts: {
			default: false,
			fallbackValue: false
		},
		value: BP.Object({
			props: {
				type: BP.Prop(BP.Enum.String({
					...termsEditor.schemas.optionsField.allowCustomValueType,
					options: getValuesFromStringEnum(
						OPTIONS_FIELD_CUSTOM_VALUE_TYPE,
						termsEditor.schemas.optionsField.allowCustomValueTypeValues,
					),
					default: OPTIONS_FIELD_CUSTOM_VALUE_TYPE_default,
					fallbackValue: OPTIONS_FIELD_CUSTOM_VALUE_TYPE_default,
					constraints: {
						required: true
					}
				}), 0),

				placeholder: BP.Prop(BP.String({
					...termsEditor.schemas.optionsField.allowCustomValuePlaceholder,
					default: ""
				}), 10),

				searchClient: BP.Prop(BP.OptGroup({
					...termsEditor.schemas.optionsField.allowCustomValueSearchClient,
					enabledOpts: {
						default: true,
						fallbackValue: true
					},
					value: BP.Object({
						hidden: true,
						props: {},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				}), 20)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 40, propGroups.common)

};