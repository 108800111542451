/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	DESIGN_CONTEXT_READ_MODE
} from "@hexio_io/hae-lib-blueprint";

import { DOC_TYPES } from "./DocTypes";
import { createBlueprintSchema } from "./BlueprintBase";
import { FLOW_NODE_TYPES } from "./nodes/BlueprintNode";
import { termsEditor } from "../terms";
import { BlueprintFlowNodeTypeRequest } from "./nodes/BlueprintNodeRequest";
import { BlueprintFlowNodeTypeResponse } from "./nodes/BlueprintNodeResponse";
import { BlueprintFlowNodeTypeRedirect } from "./nodes/BlueprintNodeRedirect";
import { BlueprintFlowNodeTypeAction } from "./nodes/BlueprintNodeAction";
import { BlueprintFlowNodeTypeCondition } from "./nodes/BlueprintNodeCondition";
import { BlueprintFlowNodeTypeTransform } from "./nodes/BlueprintNodeTransform";

export const BlueprintEndpointFlowNodeTypes = {
	[FLOW_NODE_TYPES.REQUEST]: BlueprintFlowNodeTypeRequest,
	[FLOW_NODE_TYPES.RESPONSE]: BlueprintFlowNodeTypeResponse,
	[FLOW_NODE_TYPES.REDIRECT]: BlueprintFlowNodeTypeRedirect,
	[FLOW_NODE_TYPES.ACTION]: BlueprintFlowNodeTypeAction,
	[FLOW_NODE_TYPES.CONDITION]: BlueprintFlowNodeTypeCondition,
	[FLOW_NODE_TYPES.TRANSFORM]: BlueprintFlowNodeTypeTransform
};

const EndpointObjectSchema = BP.Object({
	props: {
		requireAuthenticatedUser: BP.Prop(BP.Const.Boolean({
			label: termsEditor.endpoint.requireAuthenticatedUser.label,
			description: termsEditor.endpoint.requireAuthenticatedUser.description,
			//label: "Require authenticated user",
			//description: "When true only logged in users will be able to request this endpoint.",
			default: true
		})),
		nodes: BP.Prop(
			BP.Conditional({
				condition: (dCtx) => {
					return dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL
				},
				value: BP.Special.FlowNodeList({
					nodeTypes: BlueprintEndpointFlowNodeTypes,
					entryNode: {
						id: "request",
						type: FLOW_NODE_TYPES.REQUEST,
						defaultPosition: {
							x: 10,
							y: 50
						},
						defaultOpts: {}
					}
				})
			})
		),
		timeout: BP.Prop(BP.Const.Integer({
			label: termsEditor.endpoint.timeout.label,
			description: termsEditor.endpoint.timeout.description,
			default: 10000,
			constraints: {
				required: true,
				min: 1000,
				max: 30000
			}
		})),
	}
});

export type TBlueprintEndpointSpecSchema = typeof EndpointObjectSchema;
export const BlueprintEndpoint = createBlueprintSchema(DOC_TYPES.ENDPOINT_V1, EndpointObjectSchema);
export type TBlueprintEndpointSchema = typeof BlueprintEndpoint;