/**
 * App Header Height enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum APP_HEADER_HEIGHT {
	SMALL = "small",
	MEDIUM = "medium",
	LARGE = "large"
}

export type APP_HEADER_HEIGHT_string = keyof typeof APP_HEADER_HEIGHT;

export const APP_HEADER_HEIGHT_default = "MEDIUM";
