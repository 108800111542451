/**
 * PROPERTY_ITEM_VALUE_MODE HAE enum
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum PROPERTY_ITEM_VALUE_MODE {
	TEXT = "text",
	PASSWORD = "password"
}

export const PROPERTY_ITEM_VALUE_MODE_default: keyof typeof PROPERTY_ITEM_VALUE_MODE = "TEXT";
