/*
 * Aria Helpers
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAriaProps } from "../ReactComponents/props";
import { TElementProps } from "../Types/TElementProps";

export const ariaProps = [ "aria-label", "aria-haspopup", "aria-expanded" ];

/**
 * Adds aria props
 * 
 * @param props Properties
 */
export function resolveAriaProps(props: IAriaProps): TElementProps {
	const result = {};

	Object.entries(props).forEach(([ key, value ]) => {
		if (ariaProps.includes(key)) {
			result[key] = value;
		}
	});

	return result;
}
