/**
 * Options Field HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, COMPONENT_MODE, createSubScope, defineElementaryComponent, Type } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	getStringEnumValue,
	Label,
	termsEditor as HAELibComponentsTerms,
	THAEComponentDefinition,
	THAEComponentReact,
	useDidUpdateEffect
} from "@hexio_io/hae-lib-components";

import { isDefined, isFunction, isNil, isNonEmptyArray, isString, isValidValue } from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";
import { OPTIONS_FIELD_TYPE } from "../../Enums/OPTIONS_FIELD_TYPE";
import { OptionsFieldRadioGroup } from "./OptionsFieldRadioGroup";
import { OptionsFieldSelect } from "./OptionsFieldSelect";
import { getFieldStateProps, useField } from "./useField";
import { FieldInfo } from "./FieldInfo";
import { FieldLabelInfo } from "./FieldLabelInfo";
import { IFieldState } from "./state";
import { HAEComponentField_Events } from "./events";
import { IOptionsFieldBaseProps, IOptionsFieldItem } from "./optionsFieldTypes";
import { HAEComponentOptionsField_Props } from "./optionsFieldProps";
import { OPTIONS_FIELD_CUSTOM_VALUE_TYPE, OPTIONS_FIELD_CUSTOM_VALUE_TYPE_default } from "../../Enums/OPTIONS_FIELD_CUSTOM_VALUE_TYPE";
import { createFieldClassListModifiers } from "./createFieldClassListModifiers";

interface HAEComponentOptionsField_State extends IFieldState {
	initialValue: string;
	value: string;
	customValue: string;
	customData: unknown;
}

const HAEComponentOptionsField_Events = {
	...HAEComponentField_Events,
	customValueChange: {
		...termsEditor.schemas.field.events.customValueChange
	}
};

function isOptionsFieldEmpty(value: string): boolean {
	return !isString(value);
}

const HAEComponentOptionsField_Definition = defineElementaryComponent<
	typeof HAEComponentOptionsField_Props,
	HAEComponentOptionsField_State,
	typeof HAEComponentOptionsField_Events
>({
	...termsEditor.components.optionsField.component,

	name: "optionsField",

	category: "form",

	icon: "mdi/form-dropdown",

	docUrl: "...",

	order: 50,

	props: HAEComponentOptionsField_Props,

	events: HAEComponentOptionsField_Events,

	resolve: (spec, state, updateStateAsync) => {
		/*const initialValue = ((isDefined(spec.value) && spec.value !== FALLBACK_VALUE) ? spec.value : state?.initialValue) ?? null;
		const value = state?.initialValue === initialValue ?
		((isDefined(state?.value) && state?.value !== FALLBACK_VALUE) ? state.value : spec.value) :
		spec.value;*/

		const initialValue = spec.value ?? state?.initialValue ?? null;
		const value =
			state?.initialValue === initialValue
				? isDefined(state?.value)
					? state.value
					: spec.value
				: spec.value;

		const customValue = state?.customValue || "";
		const customData =
			(isNonEmptyArray(spec.items) && spec.items.find((item) => item.value === value)?.customData) ??
			null;

		function setValue(value: string) {
			updateStateAsync((prevState) => ({ ...prevState, value }));
		}

		function clearValue(initial = false) {
			updateStateAsync((prevState) => ({ ...prevState, value: !initial ? null : initialValue }));
		}

		return {
			value,
			initialValue,
			...getFieldStateProps(value, initialValue, state, spec.validate, isOptionsFieldEmpty),
			customValue,
			customData,
			setValue,
			clearValue
		};
	},

	getScopeData: (spec, state) => {
		return {
			initialValue: spec.value,
			value: state.value,
			valid: state.valid,
			customValue: state.customValue,
			customData: state.customData,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				initialValue: Type.String({ ...termsEditor.schemas.optionsField.initialValue }),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.schemas.field.valid }),
				customValue: Type.Boolean({ ...termsEditor.schemas.optionsField.customValue }),
				customData: Type.Boolean({ ...HAELibComponentsTerms.schemas.common.customData }),
				setValue: Type.Method({
					...termsEditor.schemas.field.setValue,
					argRequiredCount: 1,
					argSchemas: [ BP.String({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.schemas.field.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});

const HAEComponentOptionsField_React: THAEComponentReact<typeof HAEComponentOptionsField_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const {
		placeholder,
		allowCustomValue,

		labelText,
		labelIcon,
		descriptionText,
		//hidden,
		enabled,
		readOnly,
		//validate,
		required,
		customValidation
	} = props;

	const { value, empty, touched, changed, valid, customValue } = state;

	const { componentMode } = componentInstance;

	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;
	const items: IOptionsFieldItem[] = Array.isArray(props.items)
		? props.items.filter((item) => isValidValue(item?.value))
		: [];

	const componentPath = componentInstance.safePath;

	const typeValue = getStringEnumValue(OPTIONS_FIELD_TYPE, props.type);
	const allowCustomValueTypeValue = allowCustomValue
		? getStringEnumValue(
			OPTIONS_FIELD_CUSTOM_VALUE_TYPE,
			allowCustomValue.type,
			OPTIONS_FIELD_CUSTOM_VALUE_TYPE_default
		  )
		: null;

	React.useEffect(() => {
		if (
			!allowCustomValueTypeValue ||
			allowCustomValueTypeValue === OPTIONS_FIELD_CUSTOM_VALUE_TYPE.SEARCH
		) {
			setState((prevState) => ({
				...prevState,
				customValue: ""
			}));
		}
	}, [ allowCustomValueTypeValue ]);

	// Classlist

	const { classList, idClassName: id } = ClassList.getElementClassListAndIdClassName(
		"cmp-field",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	classList.addModifiers({
		options: true,
		"options-type": typeValue,
		validate: props.validate
	});
	classList.addModifiers(
		createFieldClassListModifiers(classList, { enabled, empty, touched, changed, valid }),
		false
	);

	const isValueInItems = React.useCallback(
		(value: string) => {
			return items.some((item) => item.value === value);
		},
		[ items.map((item, index) => `${item.value}-${index}`).join("|") ]
	);

	const validate = React.useMemo(() => {
		if (!props.validate) {
			return false;
		}

		return (value: string) => {
			if ((isNil(value) && !required) || readOnly || !enabled) {
				return true;
			}

			return (
				isValueInItems(value) ||
				allowCustomValueTypeValue === OPTIONS_FIELD_CUSTOM_VALUE_TYPE.CUSTOM_VALUE ||
				allowCustomValueTypeValue === OPTIONS_FIELD_CUSTOM_VALUE_TYPE.BOTH
			);
		};
	}, [ props.validate, readOnly, enabled, required, isValueInItems, allowCustomValueTypeValue ]);

	const { setValue, setTouched } = useField<string>(
		{
			id,
			state,
			validate,
			customValidation,
			isEmpty: isOptionsFieldEmpty,
			onChange:
				!elementReadOnly && componentInstance.eventEnabled.change
					? componentInstance.eventTriggers.change
					: undefined
		},
		setState
	);

	// Select handler

	const _selectHandler = React.useCallback(
		(value: string, customValueField = false) => {
			setTouched();

			// Resolve what to update

			let updateNewValue = false;
			let updateCustomValue = false;

			if (!customValueField) {
				updateNewValue = true;
				updateCustomValue = value === null;
			} else {
				updateNewValue = allowCustomValueTypeValue !== OPTIONS_FIELD_CUSTOM_VALUE_TYPE.SEARCH;
				updateCustomValue = true;
			}

			// Resolve values

			let newValue: string;
			let newCustomValue: string;

			if (updateNewValue) {
				newValue =
					isValueInItems(value) ||
					allowCustomValueTypeValue === OPTIONS_FIELD_CUSTOM_VALUE_TYPE.CUSTOM_VALUE ||
					allowCustomValueTypeValue === OPTIONS_FIELD_CUSTOM_VALUE_TYPE.BOTH
						? value
						: null;
			}

			if (updateCustomValue) {
				newCustomValue = value || "";
			}

			// Set values to states

			if (updateNewValue) {
				if (updateCustomValue) {
					setValue(newValue, { customValue: newCustomValue });
				} else {
					setValue(newValue);
				}
			} else if (updateCustomValue) {
				setState((prevState) => ({
					...prevState,
					customValue: newCustomValue
				}));
			}
		},
		[ setState, setValue, setTouched, isValueInItems, allowCustomValueTypeValue ]
	);

	useDidUpdateEffect(() => {
		if (isFunction(componentInstance.eventTriggers.customValueChange)) {
			componentInstance.eventTriggers.customValueChange((parentScope) => createSubScope(parentScope));
		}
	}, [ customValue ]);

	const commonProps: IOptionsFieldBaseProps = {
		id,
		readOnly: elementReadOnly,
		disabled: !enabled,
		required,
		items,
		selectedItem: items.find((item) => item.value === value),
		value,
		allowCustomValue,
		allowCustomValueTypeValue,
		customValue,
		componentPath,
		componentMode,
		onSelect: _selectHandler
	};

	if (
		!!allowCustomValue?.searchClient &&
		customValue &&
		!!items.length &&
		typeValue === OPTIONS_FIELD_TYPE.DEFAULT
	) {
		if (
			allowCustomValueTypeValue === OPTIONS_FIELD_CUSTOM_VALUE_TYPE.SEARCH ||
			allowCustomValueTypeValue === OPTIONS_FIELD_CUSTOM_VALUE_TYPE.BOTH
		) {
			/*const fuse = new Fuse(items, {
				shouldSort: false,
				minMatchCharLength: 1,
				threshold: 0.2,
				keys: [ "value", "labelText.value", "descriptionText.value" ]
			});

			commonProps.items = fuse.search(customValue).map((item) => item.item);*/

			commonProps.items = commonProps.items.filter((f) => {
				const cV = customValue.toLowerCase();
				const value = f.value?.toLowerCase();
				const label = f.labelText?.value?.toLowerCase();
				const desc = f.descriptionText?.value?.toLowerCase();

				return value?.includes(cV) || label?.includes(cV) || desc?.includes(cV);
			});
		}
	}

	return (
		<div className={classList.toClassName()}>
			<Label
				text={{ ...labelText, tagName: "span" }}
				icon={{ ...labelIcon, size: "SMALL" }}
				tagName="label"
				htmlFor={id}
				classList={new ClassList("cmp-field__label")}
				componentPath={[ ...componentPath, "label" ]}
				componentMode={componentMode}
			>
				<FieldLabelInfo required={required} />
			</Label>

			<div className="cmp-field__content">
				{(() => {
					switch (typeValue) {
						case OPTIONS_FIELD_TYPE.DEFAULT:
							return (
								<OptionsFieldSelect
									{...commonProps}
									loading={enabled && !elementReadOnly && isNil(props.items)}
									placeholder={placeholder}
								/>
							);

						case OPTIONS_FIELD_TYPE.RADIO_GROUP:
							return <OptionsFieldRadioGroup {...commonProps} name={id} />;
					}
				})()}
			</div>

			<FieldInfo
				descriptionText={descriptionText}
				componentPath={[ ...componentPath, "info" ]}
				componentMode={componentMode}
			/>
		</div>
	);
};

export const HAEComponentOptionsField: THAEComponentDefinition<typeof HAEComponentOptionsField_Definition> = {
	...HAEComponentOptionsField_Definition,
	reactComponent: HAEComponentOptionsField_React
};
