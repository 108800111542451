/**
 * Number Field Input HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { isFunction } from "@hexio_io/hae-lib-shared";

export interface NumberFieldInputProps extends React.HTMLProps<HTMLInputElement> {
	onValueChange?: (value: number) => void;
}

export const NumberFieldInput: React.FC<NumberFieldInputProps> = ({ value, onValueChange, ...props }) => {
	const _inputChangeHandler = React.useCallback((event: React.SyntheticEvent<HTMLInputElement>) => {
		const { valueAsNumber: value } = event.currentTarget;

		if (isFunction(onValueChange)) {
			onValueChange(Number.isFinite(value) ? value : null);
		}
	}, [ onValueChange ]);

	return (
		<input {...props} type="number" value={Number.isFinite(value) ? `${value}` : ""} onChange={_inputChangeHandler} />
	);
};
