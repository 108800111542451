/**
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, INamedConfigResolver } from "@hexio_io/hae-lib-blueprint";
import { IIntegrationDefRegistry } from "../registries";

/**
 * Creates Integration Params Resolver
 */
export function createIntegrationParamsResolver(integrationDefRegistry: IIntegrationDefRegistry): INamedConfigResolver {

	return {

		getOptsSchemaByName: (name: string) => {

			const integrationDef = integrationDefRegistry.get(name);
			if (!integrationDef) {
				return null;
			}

			return BP.Const.Map({
				value: integrationDef.optionsSchema,
				provideKeyToScopeMetaAs: "secretsEnv"
			});

		},

		getNameList: () => {
			return integrationDefRegistry.getItemList().map((def) => ({
				name: def.name,
				label: def.label
			}));
		}

	};

}