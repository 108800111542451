/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

const LOCK = {
	/** 5 minutes */
	TTL: 5 * 60 * 1000,
	/** 10 seconds */
	TTL_INTERVAL: 10 * 1000
}

export const RESOURCE = {
	LOCK,
	MANAGER: {
		/** Check resource list if it's synced every 10 seconds */
		CHECK_RESOURCE_LIST_INTERVAL: 10000,
		/** Automatic resource list reload max once in 1 minute */
		RELOAD_RESOURCE_LIST_MIN: 60000,
		/** Detect circular dependency max recursive call */
		CIRCULAR_DEPENDENCIES_LIMIT: 500
	}
};