/**
 * Http Request schema terms
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const httpRequest = {
	method: {
		label: "schema:httpRequest.method#label",
		description: "schema:httpRequest.method#description"
	},
	methodValues: {
		head: {
			label: "schema:httpRequest.method#values.head#label"
		},
		get: {
			label: "schema:httpRequest.method#values.get#label"
		},
		post: {
			label: "schema:httpRequest.method#values.post#label"
		},
		put: {
			label: "schema:httpRequest.method#values.put#label"
		},
		patch: {
			label: "schema:httpRequest.method#values.patch#label"
		},
		delete: {
			label: "schema:httpRequest.method#values.delete#label"
		},
		options: {
			label: "schema:httpRequest.method#values.options#label"
		}
	},

	url: {
		label: "schema:httpRequest.url#label",
		description: "schema:httpRequest.url#description"
	},

	headers: {
		label: "schema:httpRequest.headers#label",
		description: "schema:httpRequest.headers#description"
	}
};
