/**
 * Label schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const label = {
	schema: {
		label: "schema:label#label"
	},
	flow: {
		label: "schema:label.flow#label",
		description: "schema:label.flow#description"
	},
	flowValues: {
		row_icon_text: {
			label: "schema:label.flow#values.row_icon_text#label"
		},
		row_text_icon: {
			label: "schema:label.flow#values.row_text_icon#label"
		},
		column_icon_text: {
			label: "schema:label.flow#values.column_icon_text#label"
		},
		column_text_icon: {
			label: "schema:label.flow#values.column_text_icon#label"
		}
	}
};
