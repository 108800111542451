/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IBlueprintSchemaOpts, TGetBlueprintSchemaSpec } from "../../Schema/IBlueprintSchema";
import { ISchemaConstArray, SchemaConstArray } from "../const/SchemaConstArray";
import { ISchemaConstBoolean, SchemaConstBoolean } from "../const/SchemaConstBoolean";
import {
	ISchemaConstObject,
	ISchemaConstObjectOptsProp,
	Prop,
	TSchemaConstObjectProps
} from "../const/SchemaConstObject";
import { ISchemaConstString, SchemaConstString } from "../const/SchemaConstString";
import { ISchemaIconName, SchemaIconName } from "../SchemaIconName";

export const SchemaBuilderPropGroups = {
	primary: {
		id: "__primary__",
		label: null
	},
	validation: {
		id: "validation",
		label: "Validation",
		order: 50,
	},
	advanced: {
		id: "advanced",
		label: "Advanced Settings",
		order: 100
	}
};

export type TSchemaBuilderBaseProps = {
	label: ISchemaConstObjectOptsProp<ISchemaConstString>;
	description: ISchemaConstObjectOptsProp<ISchemaConstString>;
	icon: ISchemaConstObjectOptsProp<ISchemaIconName>;
	placeholder: ISchemaConstObjectOptsProp<ISchemaConstString>;
	hidden: ISchemaConstObjectOptsProp<ISchemaConstBoolean>;
	example: ISchemaConstObjectOptsProp<ISchemaConstString>;
	tags: ISchemaConstObjectOptsProp<ISchemaConstArray<ISchemaConstString>>;
};

export const SchemaBuilderBaseProps: TSchemaBuilderBaseProps = {
	label: Prop(SchemaConstString({
		label: "Label",
		editorOptions: {
			hidden: true
		}
	}), 10, SchemaBuilderPropGroups.advanced),
	description: Prop(SchemaConstString({
		label: "Description",
		editorOptions: {
			hidden: true
		}
	}), 20, SchemaBuilderPropGroups.advanced),
	icon: Prop(SchemaIconName({
		label: "Icon",
		editorOptions: {
			hidden: true
		}
	}), 30, SchemaBuilderPropGroups.advanced),
	placeholder: Prop(SchemaConstString({
		label: "Placeholder",
		description: "Describes an example of a field value.",
		editorOptions: {
			hidden: true
		}
	}), 40, SchemaBuilderPropGroups.advanced),
	hidden: Prop(SchemaConstBoolean({
		label: "Hidden",
		description: "If a field should be hidden in editor user-interface.",
		editorOptions: {
			hidden: true
		}
	}), 90, SchemaBuilderPropGroups.advanced),
	example: Prop(SchemaConstString({
		label: "Example",
		editorOptions: {
			hidden: true
		}
	}), 100, SchemaBuilderPropGroups.advanced),
	tags: Prop(SchemaConstArray({
		label: "Tags",
		items: SchemaConstString({}),
		editorOptions: {
			hidden: true
		}
	}), 110, SchemaBuilderPropGroups.advanced)
};

export interface ISchemaBuilderOpts extends IBlueprintSchemaOpts {
	/** If resulting schema should support dynamic value or constant only */
	constantOnly?: boolean;
	/** Base validation rules */
	constraints?: {
		/** If the value is required */
		required?: boolean;
	}
}

export type TEquals<T, S> =
	[T] extends [S] ? (
		[S] extends [T] ? true : false
	) : false;

export type TCompareSchemaCommonOpts<
	A extends IBlueprintSchemaOpts,
	B extends ISchemaConstObject<TSchemaConstObjectProps>,
	TOmitProps extends string = never
	> = TEquals<
		Required<Omit<A, "validators" | "constraints" | "alias" | "editorOptions" | TOmitProps>>,
		Required<Omit<TGetBlueprintSchemaSpec<B>, "constraints" | TOmitProps>>
	>;

export type TCompareSchemaConstraints<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	A extends { [K: string]: any },
	B extends ISchemaConstObject<TSchemaConstObjectProps>,
	> = TEquals<
		Required<A["constraints"]>,
		Required<TGetBlueprintSchemaSpec<B>["constraints"]>
	>;

export type TCompareSchemaBuilderProps<
	A extends IBlueprintSchemaOpts,
	B extends ISchemaConstObject<TSchemaConstObjectProps>,
	TOmitProps extends string = never
	> = TEquals<
		TCompareSchemaCommonOpts<A, B, TOmitProps>,
		TCompareSchemaConstraints<A, B>
	>;
