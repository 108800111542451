/**
 * Composed Chart HAE component Create Label
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";

import { HAEComponentGaugeChart_State } from "./state";
import { IStyles } from "../../types/charts";
import { getStringEnumValue, VERTICAL_ALIGN, VERTICAL_ALIGN_bottom } from "@hexio_io/hae-lib-components";

/**
 * Creates label
 */
export function createLabel(
	label: HAEComponentGaugeChart_State["label"],
	root: am5.Root,
	chart: am5radar.RadarChart,
	value: number,
	styles: IStyles
): am5.Label {
	if (!label) {
		return;
	}

	const verticalAlignValue = getStringEnumValue(VERTICAL_ALIGN, label.verticalAlign, VERTICAL_ALIGN_bottom);
	const centerYValue = verticalAlignValue === VERTICAL_ALIGN.TOP ?
		100 :
		verticalAlignValue === VERTICAL_ALIGN.MIDDLE ?
			50 :
			0;

	const result = chart.radarContainer.children.push(am5.Label.new(root, {
		centerX: am5.percent(50),
		textAlign: "center",
		centerY: am5.percent(centerYValue),
		fontSize: styles.fontSizeLarge
	}));

	return result;
}
