/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	TBlueprintIDTNode,
	IBlueprintIDTScalar,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTList,
	BP_IDT_SCALAR_SUBTYPE
} from "./ISchemaIDT";

/**
 * Converts native JS data to IDT
 *
 * @param data Data
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function dataToIDT(data: any, documentUri: string, path: Array<string> = ["$"]): TBlueprintIDTNode {

	const idt = convertDataValueToIDT(data, path, documentUri);
	return idt;

}

/**
 * Converts native value to IDT
 *
 * @param value Value
 * @param path Path
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertDataValueToIDT(value: any, path: Array<string>, documentUri: string): TBlueprintIDTNode {

	if (value instanceof Date) {

		return {
			type: BP_IDT_TYPE.SCALAR,
			subType: BP_IDT_SCALAR_SUBTYPE.DATE,
			path: path,
			value: value.toISOString()
		} as IBlueprintIDTScalar;

	} else if (value instanceof RegExp) {

		return {
			type: BP_IDT_TYPE.SCALAR,
			subType: BP_IDT_SCALAR_SUBTYPE.REGEXP,
			path: path,
			value: value.toString()
		} as IBlueprintIDTScalar;

	} else if (value instanceof Array) {

		return {
			type: BP_IDT_TYPE.LIST,
			path: path,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			items: (value as Array<any>).map((item, index) => convertDataValueToIDT(item, path.concat([String(index)]), documentUri))
		} as IBlueprintIDTList;

	} else if (value instanceof Object) {

		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: Object.keys(value).map((key) => {

				return {
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[" + key + "]"]),
					key: convertDataValueToIDT(key, path.concat(["{" + key + "}"]), documentUri),
					value: convertDataValueToIDT(value[key], path.concat([key]), documentUri)
				} as IBlueprintIDTMapElement;

			})
		} as IBlueprintIDTMap;

	} else if (typeof value === "string") {

		return {
			type: BP_IDT_TYPE.SCALAR,
			subType: BP_IDT_SCALAR_SUBTYPE.STRING,
			path: path,
			value: value
		} as IBlueprintIDTScalar;

	} else if (typeof value === "number") {

		let subType = BP_IDT_SCALAR_SUBTYPE.FLOAT;

		if (Number.isInteger(value)) {
			subType = BP_IDT_SCALAR_SUBTYPE.INTEGER;
		}

		return {
			type: BP_IDT_TYPE.SCALAR,
			subType,
			path: path,
			value: value
		} as IBlueprintIDTScalar;

	} else if (typeof value === "boolean") {

		return {
			type: BP_IDT_TYPE.SCALAR,
			subType: BP_IDT_SCALAR_SUBTYPE.BOOLEAN,
			path: path,
			value: value
		} as IBlueprintIDTScalar;

	} else if (value === null) {

		return {
			type: BP_IDT_TYPE.SCALAR,
			subType: BP_IDT_SCALAR_SUBTYPE.NULL,
			path: path,
			value: null
		} as IBlueprintIDTScalar;

	} else {

		throw new Error(`Unsupported data value: ${typeof value}.`);

	}

}
