/**
 * Color pattern
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

// eslint-disable-next-line max-len
export const colorPattern = "^(#[a-fA-F0-9]{3}|#[a-fA-F0-9]{6}|#[a-fA-F0-9]{8}|(rgb|hsl)\\(\\s*-?[0-9]{1,3}%?\\s*,\\s*-?[0-9]{1,3}%?\\s*,\\s*-?[0-9]{1,3}%?\\s*\\)|(rgba|hsla)\\(\\s*-?[0-9]{1,3}%?\\s*,\\s*-?[0-9]{1,3}%?\\s*,\\s*-?[0-9]{1,3}%?\\s*,\\s*([0-9](\\.[0-9]+)?|\\.[0-9]+)\\s*\\)|[a-zA-Z_]+)$";
