/**
 * Sort Order enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum SORT_ORDER {
	ASC = "asc",
	DESC = "desc"
}

export type SORT_ORDER_string = keyof typeof SORT_ORDER;

export const SORT_ORDER_default = "ASC";

/**
 * Sort Order Mode enum
 */
export enum SORT_ORDER_MODE {
	NONE = "none",
	ASC_DESC = "asc-desc",
	DESC_ASC = "desc-asc",
	ASC = "asc",
	DESC = "desc"
}

export type SORT_ORDER_MODE_string = keyof typeof SORT_ORDER_MODE;

export const SORT_ORDER_MODE_default: SORT_ORDER_MODE_string = "ASC_DESC";

export const SORT_ORDER_MODE_values = {
	"asc-desc": [ SORT_ORDER.ASC, SORT_ORDER.DESC ],
	"desc-asc": [ SORT_ORDER.DESC, SORT_ORDER.ASC ],
	"asc": [ SORT_ORDER.ASC ],
	"desc": [ SORT_ORDER.DESC ]
};
