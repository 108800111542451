/**
 * MIME Type Icon Name enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ICON_NAME } from "../Enums/ICON_NAME";

export enum MIME_TYPE_ICON_NAME {
	"application/json" = ICON_NAME.FILE_CODE,
	"application/x-httpd-php" = ICON_NAME.FILE_CODE,
	"application/x-python-code" = ICON_NAME.FILE_CODE,
	"application/xml" = ICON_NAME.FILE_CODE,
	"text/css" = ICON_NAME.FILE_CODE,
	"text/html" = ICON_NAME.FILE_CODE,
	"text/javascript" = ICON_NAME.FILE_CODE,
	"text/x-python" = ICON_NAME.FILE_CODE,
	"text/xml" = ICON_NAME.FILE_CODE,

	"application/msword" = ICON_NAME.FILE_DOCUMENT,
	"application/pdf" = ICON_NAME.FILE_DOCUMENT,
	"application/rtf" = ICON_NAME.FILE_DOCUMENT,
	"application/vnd.oasis.opendocument.text" = ICON_NAME.FILE_DOCUMENT,
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document" = ICON_NAME.FILE_DOCUMENT,
	"application/x-bzpdf" = ICON_NAME.FILE_DOCUMENT,
	"application/x-gzpdf" = ICON_NAME.FILE_DOCUMENT,
	"application/x-pdf" = ICON_NAME.FILE_DOCUMENT,
	"text/markdown" = ICON_NAME.FILE_DOCUMENT,
	"text/plain" = ICON_NAME.FILE_DOCUMENT,
	"text/rtf" = ICON_NAME.FILE_DOCUMENT,

	"image" = ICON_NAME.FILE_IMAGE,

	"audio" = ICON_NAME.FILE_MUSIC,

	"application/vnd.ms-excel" = ICON_NAME.FILE_TABLE,
	"application/vnd.oasis.opendocument.spreadsheet" = ICON_NAME.FILE_TABLE,
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" = ICON_NAME.FILE_TABLE,

	"video" = ICON_NAME.FILE_VIDEO,

	"application/gzip" = ICON_NAME.FOLDER_ZIP,
	"application/vnd.rar" = ICON_NAME.FOLDER_ZIP,
	"application/x-7z-compressed" = ICON_NAME.FOLDER_ZIP,
	"application/x-tar" = ICON_NAME.FOLDER_ZIP,
	"application/zip" = ICON_NAME.FOLDER_ZIP,

	DEFAULT = ICON_NAME.FILE
}

export type MIME_TYPE_ICON_NAME_string = keyof typeof MIME_TYPE_ICON_NAME;
