/**
 * Text Field Type enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum TEXT_FIELD_TYPE {
	DEFAULT = "text",
	EMAIL = "email",
	PASSWORD = "password"
}

export type TEXT_FIELD_TYPE_string = keyof typeof TEXT_FIELD_TYPE;
