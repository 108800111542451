/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	DesignContext,
	TGetBlueprintSchemaModel
} from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../../app";
import { BlueprintDirectory, DOC_TYPES } from "../../blueprints";
import { ILogger } from "../../logger";
import { GENERIC_RESOURCE_PERMISSIONS, RESOURCE_PERMISSIONS } from "../../registries";
import { RESOURCE_TYPES } from "../IResource";
import { RESOURCE_ON_EVENT_TYPE } from "../IResourceManager";
import { IDirectoryResourceProps, IDirectoryResourceType } from "./IDirectoryResource";

type TDirectorySchemaModel = TGetBlueprintSchemaModel<typeof BlueprintDirectory>;

export class DirectoryResourceV1 implements IDirectoryResourceType {

	public get permissions(): RESOURCE_PERMISSIONS[] {
		return GENERIC_RESOURCE_PERMISSIONS;
	}

	public get name(): string {
		return DOC_TYPES.DICTIONARY_V1;
	}

	public get category(): string {
		return RESOURCE_TYPES.DIRECTORY;
	}

	public get statsName(): string {
		return "directories";
	}

	protected logger: ILogger;

	public constructor(protected app: IAppServer) {
		this.logger = this.app.get("logger").facility("resource-directory-v1");
	}

	public setup(resource: IDirectoryResourceProps): IDirectoryResourceProps {

		resource.resourceType = RESOURCE_TYPES.DIRECTORY;
		resource.parsingDetails.isRegistered = true;

		return resource;

	}

	public async scan(resource: IDirectoryResourceProps): Promise<IDirectoryResourceProps> {
		return resource;
	}

	public async parse(resource: IDirectoryResourceProps): Promise<IDirectoryResourceProps> {

		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Parse directory '${uri}'.`);

		let dCtx: DesignContext;
		let model: TDirectorySchemaModel;

		try {

			dCtx = resourceManager.createDCtx(false);

			resource = await resourceManager.parseModel<IDirectoryResourceProps>(resource, dCtx, BlueprintDirectory);
			model = resource.parsedData.model as TDirectorySchemaModel;

			if (!model) {
				this.logger.warn("Can't parse directory model.");
				this.logger.debug({ uri });
				return resource;
			}

			return resource;

		} catch (error) {

			this.logger.debug({ error: error?.message });
			resourceManager.reportError(`Can't parse directory '${uri}'.`, error);
			return resource;

		} finally {

			try {
				if (model) {
					model.schema.destroy(model);
				}
				if (dCtx) {
					dCtx.destroy();
				}
			} catch (error) {
				this.logger.debug(error);
			}

		}

	}

	public getDependenciesToReload(resource: IDirectoryResourceProps, eventType?: RESOURCE_ON_EVENT_TYPE): string[] {

		if (
			eventType &&
			[RESOURCE_ON_EVENT_TYPE.RENAME].includes(eventType) &&
			Array.isArray(resource.dependencies)
		) {
			return resource.dependencies.map((dep) => dep.uri);
		}

		return [];

	}

}