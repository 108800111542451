/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAppServer } from "../app";
import { createIntegrationFactory } from "./createIntegrationFactory";
import { IIntegrationOptions } from "./IIntegrationOptions";
import { IIntegrationDefinition, TDecoratorIntegrationDefinition } from "./IIntegrationDefinition";
import { IntegrationBase } from "./IntegrationBase";

/**
 * Creates Integration definition
 *
 * @param integrationEntityClass Integration class
 * @returns 
 */
export function createIntegrationDefinition<
	TOptions extends IIntegrationOptions,
	TClass extends IntegrationBase<TOptions>
>(
	integrationEntityClass: new (
		integrationName: string,
		config: TOptions,
		app: IAppServer
	) => TClass
): IIntegrationDefinition<TOptions, TClass> {

	const integrationDefinition = integrationEntityClass["prototype"]["integrationDefinition"] as TDecoratorIntegrationDefinition;
	const functionDefinitions = integrationEntityClass["prototype"]["functionDefinitions"];

	return {
		...integrationDefinition,
		functions: functionDefinitions,
		integrationFactory: createIntegrationFactory(
			integrationEntityClass,
			integrationDefinition,
			functionDefinitions
		)
	};

}
