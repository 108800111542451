/**
 * Routes component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { ChangeEvent, useCallback } from "react";

import {
	BP,
	COMPONENT_MODE,
	createSubScope,
	dataEqual,
	defineElementaryComponent,
	DOC_ERROR_NAME,
	DOC_ERROR_SEVERITY,
	IRouteResolver,
	ISchemaComponentListSpec,
	ISchemaConstObjectModel,
	IScope,
	RUNTIME_CONTEXT_MODE,
	SCHEMA_VALUE_TYPE,
	Type,
	TypeDescString
} from "@hexio_io/hae-lib-blueprint";
import { match, matchPath } from "react-router-dom";
import { ClassList, HAEComponentList, Icon, THAEComponentDefinition, THAEComponentReact } from "@hexio_io/hae-lib-components";

import { routes as terms } from "../../terms/editor/components/routes";
import { INavigationResolver } from "@hexio_io/hae-lib-core";
import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { DROP_ZONE_MODE } from "@hexio_io/hae-lib-components/src/Editor/useComponentListDnD";

interface HAEComponentRoutes_State {
	/** Last URL path */
	path: string;
	/** Index of a matching route */
	matchIndex: number;
	/** If option was matched via a preview */
	previewIndex: number;
	/** Match route object */
	routeMatch: match;
	/** Content of matched route */
	childContent: ISchemaComponentListSpec;
	/** Last match scope */
	matchScope: IScope;
	/** Navigate handler */
	navigateHandler: () => void;
}

const HAEComponentRoutes_Props = {
	routes: BP.Prop(
		BP.Array({
			label: terms.schema.routes.label,
			description: terms.schema.routes.description,
			items: BP.Object({
				label: terms.schema.routeItem.label,
				props: {
					routeName: BP.Prop(
						BP.String({
							label: terms.schema.routeName.label,
							description: terms.schema.routeName.description,
							constraints: {
								required: true
							},
							default: "",
							editorOptions: {
								controlType: "routeSelector"
							}
						})
					),
					exact: BP.Prop(
						BP.Boolean({
							label: terms.schema.routeExact.label,
							description: terms.schema.routeExact.description,
							default: false
						})
					),
					strict: BP.Prop(
						BP.Boolean({
							label: terms.schema.routeStrict.label,
							description: terms.schema.routeStrict.description,
							default: false
						})
					),
					enabled: BP.Prop(
						BP.Boolean({
							label: terms.schema.routeEnabled.label,
							description: terms.schema.routeEnabled.description,
							default: true,
							fallbackValue: true
						})
					),
					content: BP.Prop(
						BP.ScopedTemplate({
							label: terms.schema.routeContent.label,
							description: terms.schema.routeContent.description,
							template: BP.ComponentList({})
						})
					)
				}
			}),
			outlineOptions: {
				displayChildren: true,
				allowAddElement: true
			},
			getElementModelNodeInfo: (modelNode) => {
				const routeName =
					modelNode.type === SCHEMA_VALUE_TYPE.CONST ? modelNode.constant.props.routeName : null;

				let label: string = null;

				switch (routeName?.type) {
					case SCHEMA_VALUE_TYPE.CONST: {
						const routeItem = modelNode.ctx
							.getResolver<IRouteResolver>("route")
							.getRouteByName(routeName.constant.value);

						label = routeItem?.label || null;
						break;
					}
					case SCHEMA_VALUE_TYPE.EXPRESSION:
						label = routeName.expression.value ?? null;
						break;
				}

				return {
					label: label,
					icon: "mdi/sign-direction"
				};
			}
		})
	)
};

const HAEComponentRoutes_Events = {};

const HAEComponentRoutes_Definition = defineElementaryComponent<
	typeof HAEComponentRoutes_Props,
	HAEComponentRoutes_State,
	typeof HAEComponentRoutes_Events
>({
	name: "routes",
	category: "logic",
	label: terms.component.label,
	description: terms.component.description,
	icon: "mdi/routes",
	docUrl: "...",
	order: 20,
	nonVisual: true,
	props: HAEComponentRoutes_Props,
	events: HAEComponentRoutes_Events,

	resolve: (spec, state, updateStateAsync, cmpInstance, rCtx) => {
		const routeResolver = rCtx.getResolver<IRouteResolver>("route");
		const navResolver = rCtx.getResolver<INavigationResolver>("navigation");

		let navigateHandler;

		if (state?.navigateHandler) {
			navigateHandler = state.navigateHandler;
		} else {
			navigateHandler = () => {
				// console.log("Route update state async: onNavigate");
				updateStateAsync((_state) => _state);
			};
			onEvent(navResolver.onNavigate, navigateHandler);
		}

		/*
		 * IMPORTANT NOTE!
		 * Routes must be matched after props are reconciled.
		 * It's because of `enabled` property that may cause selection of different
		 * route on every render pass. Which causes re-creation and destroy of
		 * child components on every render pass. Which may lead to infinite render loop.
		 */
		if (!cmpInstance.customData.__reconcileBound) {
			rCtx.__callAfterReconcile(() => {
				cmpInstance.customData.__reconcileBound = false;

				const previewMode =
					rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR && cmpInstance.state.previewIndex > -1;

				const location = navResolver.getCurrentLocation();
				const routes = cmpInstance.props.routes;

				let matchIndex = null;
				let routeMatch: match = null;

				if (previewMode) {
					matchIndex = cmpInstance.state.previewIndex;
					routeMatch = {
						params: {},
						isExact: true,
						path: "",
						url: ""
					};
				} else {
					for (let i = 0; i < routes.length; i++) {
						if (routes[i].enabled === false) {
							continue;
						}

						const routeName = routes[i].routeName;
						const routeConfig = routeResolver.getRouteByName(routeName);

						if (!routeConfig) {
							rCtx.logRuntimeError({
								severity: DOC_ERROR_SEVERITY.WARNING,
								name: DOC_ERROR_NAME.INVALID_REF,
								message: `Unknown route name '${routeName}'.`,
								modelPath: cmpInstance.path,
								modelNodeId: cmpInstance.modelNodeId,
								metaData: {
									translationTerm: terms.errors.unknownRouteName,
									args: {
										routeName: routeName
									}
								}
							});

							continue;
						}

						routeMatch = matchPath(location.pathname, {
							path: routeConfig.path,
							exact: routes[i].exact,
							strict: routes[i].strict
						});

						if (routeMatch !== null) {
							matchIndex = i;
							break;
						}
					}
				}

				if (
					matchIndex !== cmpInstance.state.matchIndex ||
					!dataEqual(routeMatch, cmpInstance.state.routeMatch)
				) {
					// console.log("Route update state async: Mismatch", cmpInstance.uid);
					// console.log("Prev match index", cmpInstance.state.matchIndex, "New match index", matchIndex);
					// console.log("Prev route match", cmpInstance.state.routeMatch, "New route match", routeMatch);

					updateStateAsync((prevState) => ({
						...prevState,
						matchIndex: matchIndex,
						routeMatch: routeMatch,
						path: location.pathname
					}));
				}
			});

			cmpInstance.customData.__reconcileBound = true;
		}

		if (state) {
			let matchScope: IScope;

			const previewMode =
				rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR && cmpInstance.state.previewIndex > -1;

			const matchIndex = previewMode ? cmpInstance.state.previewIndex : state.matchIndex;
			const routeMatch: match = previewMode
				? {
					params: {},
					isExact: true,
					path: "",
					url: ""
				  }
				: state.routeMatch;

			const routeProp = matchIndex !== null ? spec.routes[matchIndex] : null;
			const childContent = routeProp
				? routeProp.content((parentScope) => {
					return (matchScope = createSubScope(
						parentScope,
						{
							routeMatch: routeMatch,
							routeParams: routeMatch.params
						},
						{},
						state?.matchScope
					));
				  }, "match_" + matchIndex)
				: null;

			return {
				matchIndex: matchIndex,
				previewIndex: state.previewIndex,
				routeMatch: routeMatch,
				path: state.path,
				childContent: childContent,
				matchScope: matchScope,
				navigateHandler: navigateHandler
			};
		} else {
			return {
				matchIndex: null,
				previewIndex: -1,
				routeMatch: null,
				path: null,
				childContent: null,
				matchScope: null,
				navigateHandler: navigateHandler
			};
		}
	},

	destroy: (_spec, state, rCtx) => {
		if (state.navigateHandler) {
			offEvent(rCtx.getResolver<INavigationResolver>("navigation").onNavigate, state.navigateHandler);
		}
	},

	getInstanceList: (_spec, state, _prevInstanceList, cmpInstance, rCtx) => {
		if (rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR) {
			return [ cmpInstance ];
		} else {
			const matchContent = state?.childContent;

			if (matchContent) {
				matchContent.forEach((cmp) => {
					cmp.inheritedProps = cmpInstance.inheritedProps;
				});
			}

			return matchContent || [];
		}
	},

	getScopeData: (_spec, state) => {
		return {
			routeMatch: state.routeMatch,
			content: state.matchScope?.localData || null
		};
	},

	getScopeType: (_spec, state) => {
		const matchedParamsDesc = {};

		if (state.routeMatch) {
			for (const k in state.routeMatch.params) {
				matchedParamsDesc[k] = TypeDescString({});
			}
		}

		return Type.Object({
			props: {
				routeMatch: Type.Object({
					label: terms.typeDesc.routeMatch.label,
					props: {
						path: Type.String({
							label: terms.typeDesc.routeMatchPath.label,
							description: terms.typeDesc.routeMatchPath.description
						}),
						url: Type.String({
							label: terms.typeDesc.routeMatchPath.label,
							description: terms.typeDesc.routeMatchPath.description
						}),
						isExact: Type.Boolean({
							label: terms.typeDesc.routeMatchIsExact.label,
							description: terms.typeDesc.routeMatchIsExact.description
						}),
						params: Type.Map({
							label: terms.typeDesc.routeMatchParams.label,
							items: matchedParamsDesc
						})
					}
				}),
				content: state.matchScope?.localType
			}
		});
	}
});

const HAEComponentRoutes_React: THAEComponentReact<typeof HAEComponentRoutes_Definition> = ({
	state,
	setState,
	componentInstance,
	reactComponentClassList,
	props,
	showOptions
}) => {
	const { safePath: componentPath, componentMode, modelNode } = componentInstance;

	const optsProp = (componentInstance.modelNode?.props as ISchemaConstObjectModel<
		typeof HAEComponentRoutes_Props
	>)?.props.routes;
	const contentModel = optsProp.constant.items[state.matchIndex]?.constant?.props.content.template;
	const optsModel = optsProp && optsProp.type === SCHEMA_VALUE_TYPE.CONST ? optsProp.constant : null;

	const { classList } = ClassList.getElementClassListAndIdClassName("cmp-routes", componentPath, {
		componentInstance,
		componentClassList: reactComponentClassList
	});

	const getRouteLabel = useCallback((routeName) => {
		return (
			modelNode?.ctx?.getResolver<IRouteResolver>("route")?.getRouteByName(routeName)?.label ||
			routeName
		);
	}, []);

	const selectPreview = useCallback(
		(ev: ChangeEvent<HTMLSelectElement>) => {
			if (setState) {
				setState((prevState) => ({
					...prevState,
					previewIndex: parseInt(ev.currentTarget.value, 10)
				}));
			}
		},
		[ setState ]
	);

	const addOption = useCallback(() => {
		if (optsModel) {
			optsModel.schema.addElement(
				optsModel,
				{
					content: []
				},
				true
			);

			setState((prevState) => ({
				...prevState,
				previewIndex: optsModel.items.length - 1
			}));
		}
	}, [ optsModel ]);

	const removeOption = useCallback(() => {
		if (optsModel && state.matchIndex !== null) {
			optsModel.schema.removeElement(optsModel, state.matchIndex, true);
		}
	}, [ optsModel, state.matchIndex ]);

	return (
		<div className={classList.toClassName()}>
			{componentMode === COMPONENT_MODE.EDIT && showOptions ? (
				<div className="hae-editor-controls">
					<span className="hae-editor-controls__label">
						Current route: {state.matchIndex !== null ? `#${state.matchIndex}` : "none"}
					</span>
					<select
						className="hae-editor-controls__select"
						value={state.previewIndex}
						onChange={selectPreview}
					>
						{/* @todo add translations */}
						<option value={-1}>No preview</option>
						{props.routes.map((option, index) => {
							return (
								<option key={index} value={index}>
									{getRouteLabel(option.routeName)}
								</option>
							);
						})}
					</select>
					{optsModel ? (
						<>
							<button
								className="hae-editor-controls__button"
								onClick={addOption}
								title="Add new option"
							>
								<Icon
									source="mdi/plus"
									size="SMALL"
									componentPath={componentPath}
									componentMode={componentMode}
								/>
								<span>Add</span>
							</button>
							{state.matchIndex !== null ? (
								<button
									className="hae-editor-controls__button hae-editor-controls__button--delete"
									onClick={removeOption}
									title="Remove current route"
								>
									<Icon
										source="mdi/delete"
										size="SMALL"
										componentPath={componentPath}
										componentMode={componentMode}
									/>
								</button>
							) : null}
						</>
					) : null}
				</div>
			) : null}
			{props.routes.length > 0 ? (
				state?.childContent ? (
					<HAEComponentList
						components={state.childContent}
						componentPath={[ ...componentPath, "component-list" ]}
						componentMode={componentMode}
						classList={new ClassList("cmp-routes__content")}
						childClassList={new ClassList("cmp-routes__item", "item")}
						childComponentClassList={new ClassList("item__component")}
						modelNode={contentModel}
						modifyModelOnDrop={() => null}
						dropZoneMode={DROP_ZONE_MODE.SINGLE}
					/>
				) : (
					<div className="cmp-routes__note">
						<div className="cmp-routes__note-text">No matching route.</div>
					</div>
				)
			) : (
				<div className="cmp-routes__note">
					<div className="cmp-routes__note-text">
						No routes configured. Use Add button above to add a new route.
					</div>
				</div>
			)}
		</div>
	);
};

export const HAEComponentRoutes: THAEComponentDefinition<typeof HAEComponentRoutes_Definition> = {
	...HAEComponentRoutes_Definition,
	reactComponent: HAEComponentRoutes_React,
	hasOptions: true
};
