/**
 * Composed Chart HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP, ISchemaConstObjectOptsGroup, SCHEMA_CONST_ANY_VALUE_TYPE, SCHEMA_VALUE_TYPE, TSchemaConstObjectPropsSpec
} from "@hexio_io/hae-lib-blueprint";

import {
	propGroups,
	ForegroundColorSchema,
	termsEditor as HAELibComponentsTerms,
	getValuesFromStringEnum,
	PercentageSchema,
	COLOR_MAIN
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import {
	COMPOSED_CHART_SERIES_TYPE_default} from "../../Enums/COMPOSED_CHART_SERIES_TYPE";
import {
	COMPOSED_CHART_AXIS_POSITION, COMPOSED_CHART_AXIS_POSITION_default, COMPOSED_CHART_AXIS_POSITION_opts
} from "../../Enums/COMPOSED_CHART_AXIS_POSITION";
import {
	COMPOSED_CHART_AXIS_TYPE_default
} from "../../Enums/COMPOSED_CHART_AXIS_TYPE";
import { COMPOSED_CHART_LINE_SERIES_STYLE, COMPOSED_CHART_LINE_SERIES_STYLE_default } from "../../Enums/COMPOSED_CHART_LINE_SERIES_STYLE";
import {
	COMPOSED_CHART_AXIS_DATETIME_UNIT, COMPOSED_CHART_AXIS_DATETIME_UNIT_default
} from "../../Enums/COMPOSED_CHART_AXIS_DATETIME_UNIT";

const HAEComponentComposedChart_seriesPropGroups: Record<string, ISchemaConstObjectOptsGroup> = {

	xAxis: {
		...termsEditor.schemas.composedChart.seriesXAxis,
		id: "xaxis",
		order: 100,
		defaultExpanded: true
	},

	yAxis: {
		...termsEditor.schemas.composedChart.seriesYAxis,
		id: "yaxis",
		order: 101,
		defaultExpanded: true
	}

};

const HAEComponentComposedChart_rangePropGroups: Record<string, ISchemaConstObjectOptsGroup> = {

	start: {
		...termsEditor.schemas.composedChart.rangeStart,
		id: "start",
		order: 0,
		defaultExpanded: true
	},

	end: {
		...termsEditor.schemas.composedChart.rangeEnd,
		id: "end",
		order: 1,
		defaultExpanded: false
	}

};

/**
 * Value axis props
 */
const ValueAxisProps = {

	min: BP.Prop(BP.Integer({
		...termsEditor.schemas.composedChart.axisMin,
		constraints: {
			required: false
		}
	}), 0),

	max: BP.Prop(BP.Integer({
		...termsEditor.schemas.composedChart.axisMax,
		constraints: {
			required: false
		}
	}), 10)

};

export type TValueAxisProps = Partial<TSchemaConstObjectPropsSpec<typeof ValueAxisProps>>;

/**
 * Datetime axis props
 */
const DatetimeAxisProps = {

	min: BP.Prop(BP.Any({
		...termsEditor.schemas.composedChart.axisMin,
		defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
		allowedTypes: {
			[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
			[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true
		},
		constraints: {
			required: false
		}
	}), 0),

	max: BP.Prop(BP.Any({
		...termsEditor.schemas.composedChart.axisMax,
		defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
		allowedTypes: {
			[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
			[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true
		},
		constraints: {
			required: false
		}
	}), 10),

	interval: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.composedChart.axisDatetimeInterval,
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: {
				unit: BP.Prop(BP.Enum.String({
					...termsEditor.schemas.composedChart.axisDatetimeIntervalUnit,
					options: getValuesFromStringEnum(
						COMPOSED_CHART_AXIS_DATETIME_UNIT,
						HAELibComponentsTerms.schemas.common.datetimeUnitValues
					),
					default: COMPOSED_CHART_AXIS_DATETIME_UNIT_default,
					fallbackValue: COMPOSED_CHART_AXIS_DATETIME_UNIT_default,
					constraints: {
						required: true
					}
				}), 0),

				count: BP.Prop(BP.Integer({
					...termsEditor.schemas.composedChart.axisDatetimeIntervalCount,
					default: 1,
					constraints: {
						required: true,
						min: 1
					}
				}), 10)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 20),

	tooltipFormat: BP.Prop(BP.String({
		...termsEditor.schemas.composedChart.axisTooltipDatetimeFormat,
		default: "yyyy-MM-dd",
		fallbackValue: "yyyy-MM-dd",
		constraints: {
			required: true
		}
	}), 30)

};

export type TDatetimeAxisProps = Partial<TSchemaConstObjectPropsSpec<typeof DatetimeAxisProps>>;

/**
 * Line series props
 */
const LineSeriesProps = {

	style: BP.Prop(BP.Enum.String({
		...termsEditor.schemas.composedChart.seriesStyle,
		options: getValuesFromStringEnum(
			COMPOSED_CHART_LINE_SERIES_STYLE,
			termsEditor.schemas.composedChart.seriesStyleValues
		),
		default: COMPOSED_CHART_LINE_SERIES_STYLE_default,
		fallbackValue: COMPOSED_CHART_LINE_SERIES_STYLE_default,
		constraints: {
			required: true
		}
	}), 0),

	fillOpacity: BP.Prop(PercentageSchema({
		...termsEditor.schemas.composedChart.seriesFillOpacity,
		alias: "fillOpacity_composedChartLineSeries"
	}), 10),

	strokeWidth: BP.Prop(BP.Integer({
		...termsEditor.schemas.composedChart.seriesStrokeWidth,
		default: 2,
		fallbackValue: 2,
		constraints: {
			required: true,
			min: 1
		}
	}), 20),

	connectEmptyValues: BP.Prop(BP.Boolean({
		...termsEditor.schemas.composedChart.seriesConnectEmptyValues,
		default: true,
		fallbackValue: true,
		constraints: {
			required: true
		}
	}), 30),

	points: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.composedChart.seriesPoints,
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: {
				size: BP.Prop(BP.Integer({
					...termsEditor.schemas.composedChart.seriesPointsSize,
					default: 2,
					fallbackValue: 2,
					constraints: {
						required: true,
						min: 2,
						max: 20
					}
				}), 0)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 40)

};

export type TLineSeriesProps = Partial<TSchemaConstObjectPropsSpec<typeof LineSeriesProps>>;

/**
 * Bar series props
 */
const BarSeriesProps = {

	columnWidth: BP.Prop(PercentageSchema({
		...termsEditor.schemas.composedChart.seriesColumnWidth,
		alias: "columnWidth_composedChartBarSeries",
		default: 80,
		fallbackValue: 80
	}), 0)

};

export type TBarSeriesProps = Partial<TSchemaConstObjectPropsSpec<typeof BarSeriesProps>>;

/**
 * Composed Chart props
 */
export const HAEComponentComposedChart_Props = {

	items: BP.Prop(BP.Array({
		...termsEditor.schemas.composedChart.items,
		default: [],
		fallbackValue: null,
		constraints: {
			required: true
		},
		items: BP.Any({
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
		})
	}), 10, propGroups.common),

	axes: BP.Prop(BP.Array({
		...termsEditor.schemas.composedChart.axes,
		default: [],
		fallbackValue: [],
		items: BP.Const.Object({
			props: {
				id: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.axisId
				}), 0),

				title: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.axisTitle,
					default: ""
				}), 10),

				position: BP.Prop(BP.Enum.String({
					...termsEditor.schemas.composedChart.axisPosition,
					options: getValuesFromStringEnum(
						COMPOSED_CHART_AXIS_POSITION,
						termsEditor.schemas.composedChart.axisPositionValues,
						COMPOSED_CHART_AXIS_POSITION_opts
					),
					default: COMPOSED_CHART_AXIS_POSITION_default,
					fallbackValue: COMPOSED_CHART_AXIS_POSITION_default,
					constraints: {
						required: true
					},
					editorOptions: {
						controlType: "buttons"
					}
				}), 20),

				typeData: BP.Prop(BP.OneOf({
					...termsEditor.schemas.composedChart.axisType,
					typeValueOpts: {
						...termsEditor.schemas.composedChart.axisType,
					},
					defaultType: COMPOSED_CHART_AXIS_TYPE_default,
					types: {
						"VALUE": {
							...termsEditor.schemas.composedChart.axisTypeValues.value,
							value: BP.Object({
								props: ValueAxisProps,
								editorOptions: {
									layoutType: "passthrough"
								}
							})
						},
						"CATEGORY": {
							...termsEditor.schemas.composedChart.axisTypeValues.category,
							value: BP.Void({})
						},
						"DATETIME": {
							...termsEditor.schemas.composedChart.axisTypeValues.datetime,
							value: BP.Object({
								props: DatetimeAxisProps,
								editorOptions: {
									layoutType: "passthrough"
								}
							})
						},
					},
					constraints: {
						required: true
					},
					editorOptions: {
						layoutType: "noHeader"
					}
				}), 30),

				grid: BP.Prop(BP.Boolean({
					...termsEditor.schemas.composedChart.axisGrid,
					default: true,
					fallbackValue: true,
					constraints: {
						required: true
					}
				}), 40)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		}),
		getElementModelNodeInfo: (modelNode) => {
			const position = modelNode.props.position.type === SCHEMA_VALUE_TYPE.CONST &&
				modelNode.props.position.constant.value?.toLocaleLowerCase();

			return {
				label: (modelNode.props.title.type === SCHEMA_VALUE_TYPE.CONST &&
					modelNode.props.title.constant.value) ||
					(modelNode.props.id.type === SCHEMA_VALUE_TYPE.CONST &&
					modelNode.props.id.constant.value) ||
					position,
				icon: position ? COMPOSED_CHART_AXIS_POSITION_opts[position].icon : null
			};
		}
	}), 20, propGroups.common),

	series: BP.Prop(BP.Array({
		...termsEditor.schemas.composedChart.series,
		default: [],
		fallbackValue: [],
		constraints: {
			required: true
		},
		items: BP.Const.Object({
			props: {
				title: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.seriesTitle,
					default: "",
					fallbackValue: "",
					constraints: {
						required: true
					}
				}), 0, propGroups.common),

				foregroundColor: BP.Prop(ForegroundColorSchema({
					alias: "foregroundColor_composedChartSeries",
					default: "PRIMARY",
					constants: getValuesFromStringEnum(COLOR_MAIN, HAELibComponentsTerms.schemas.color.values)
				}), 0, propGroups.style),

				typeData: BP.Prop(BP.OneOf({
					...termsEditor.schemas.composedChart.seriesType,
					typeValueOpts: {
						...termsEditor.schemas.composedChart.seriesType,
					},
					defaultType: COMPOSED_CHART_SERIES_TYPE_default,
					types: {
						"LINE": {
							...termsEditor.schemas.composedChart.seriesTypeValues.line,
							value: BP.Object({
								props: LineSeriesProps,
								editorOptions: {
									layoutType: "passthrough"
								}
							})
						},
						"BAR": {
							...termsEditor.schemas.composedChart.seriesTypeValues.bar,
							value: BP.Object({
								props: BarSeriesProps,
								editorOptions: {
									layoutType: "passthrough"
								}
							})
						}
					},
					constraints: {
						required: true
					},
					editorOptions: {
						controlType: "buttons",
						layoutType: "noHeader"
					}
				}), 10, propGroups.style),

				xAxisId: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.seriesAxisId,
					default: ""
				}), 0, HAEComponentComposedChart_seriesPropGroups.xAxis),

				xAxisKey: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.seriesAxisKey,
					default: ""
				}), 10, HAEComponentComposedChart_seriesPropGroups.xAxis),

				xAxisMapper: BP.Prop(BP.OptGroup({
					...termsEditor.schemas.composedChart.seriesAxisMapper,
					enabledOpts: {
						default: false,
						fallbackValue: false,
					},
					value: BP.ScopedTemplate({
						template: BP.Expression({
							default: "",
							placeholder: "item.key"
						})
					})
				}), 20, HAEComponentComposedChart_seriesPropGroups.xAxis),

				yAxisId: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.seriesAxisId,
					default: ""
				}), 0, HAEComponentComposedChart_seriesPropGroups.yAxis),

				yAxisKey: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.seriesAxisKey,
					default: ""
				}), 10, HAEComponentComposedChart_seriesPropGroups.yAxis),

				yAxisMapper: BP.Prop(BP.OptGroup({
					...termsEditor.schemas.composedChart.seriesAxisMapper,
					enabledOpts: {
						default: false,
						fallbackValue: false,
					},
					value: BP.ScopedTemplate({
						template: BP.Expression({
							default: "",
							placeholder: "item.key"
						})
					})
				}), 20, HAEComponentComposedChart_seriesPropGroups.yAxis),
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		}),
		getElementModelNodeInfo: (modelNode) => {
			const type = modelNode.props.typeData.type.type === SCHEMA_VALUE_TYPE.CONST &&
				modelNode.props.typeData.type.constant.value?.toLocaleLowerCase();

			return {
				label: modelNode.props.title.type === SCHEMA_VALUE_TYPE.CONST &&
					modelNode.props.title.constant.value ||
					type,
				icon: null
			};
		}
	}), 30, propGroups.common),

	ranges: BP.Prop(BP.Array({
		...termsEditor.schemas.composedChart.ranges,
		default: [],
		items: BP.Const.Object({
			props: {
				id: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.rangeId,
					default: "",
					fallbackValue: "",
					constraints: {
						required: true
					}
				}), 0, propGroups.common),

				axisId: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.rangeAxisId,
					default: "",
					fallbackValue: "",
					constraints: {
						required: true
					}
				}), 10, propGroups.common),

				startValue: BP.Prop(BP.Any({
					...termsEditor.schemas.composedChart.rangeValue,
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER,
					allowedTypes: {
						[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
						[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true
					},
					fallbackValue: "", // @todo ask Jirka (defaultType vs fallbackValue) + ask him about default object
					constraints: {
						required: true
					}
				}), 0, HAEComponentComposedChart_rangePropGroups.start),

				startTitle: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.rangeTitle,
					default: ""
				}), 10, HAEComponentComposedChart_rangePropGroups.start),

				/*startDraggable: BP.Prop(BP.Boolean({
					...termsEditor.schemas.composedChart.rangeDraggable,
					default: false,
					fallbackValue: false,
					constraints: {
						required: true
					}
				}), 20, HAEComponentComposedChart_rangePropGroups.start),*/

				endValue: BP.Prop(BP.Any({
					...termsEditor.schemas.composedChart.rangeValue,
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER,
					allowedTypes: {
						[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
						[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true
					}
				}), 0, HAEComponentComposedChart_rangePropGroups.end),

				endTitle: BP.Prop(BP.String({
					...termsEditor.schemas.composedChart.rangeTitle,
					default: ""
				}), 10, HAEComponentComposedChart_rangePropGroups.end),

				/*endDraggable: BP.Prop(BP.Boolean({
					...termsEditor.schemas.composedChart.rangeDraggable,
					default: false
				}), 20, HAEComponentComposedChart_rangePropGroups.end),*/

				foregroundColor: BP.Prop(ForegroundColorSchema({
					alias: "foregroundColor_composedChartRange",
					default: "PRIMARY",
					constants: getValuesFromStringEnum(COLOR_MAIN, HAELibComponentsTerms.schemas.color.values)
				}), 0, propGroups.style),

				fillOpacity: BP.Prop(PercentageSchema({
					...termsEditor.schemas.composedChart.rangeFillOpacity,
					alias: "fillOpacity_composedChartRange",
					default: 20,
					fallbackValue: 20
				}), 10, propGroups.style)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		}),
		getElementModelNodeInfo: (modelNode) => {
			return {
				label: modelNode.props.id.type === SCHEMA_VALUE_TYPE.CONST &&
					modelNode.props.id.constant.value,
				icon: null
			};
		}
	}), 40, propGroups.common),

	legend: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.composedChart.legend,
		enabledOpts: {
			default: true,
			fallbackValue: true
		},
		value: BP.Object({
			props: {},
			editorOptions: {
				layoutType: "passthrough"
			},
			hidden: true
		})
	}), 50, propGroups.common),

	horizontalScrollbar: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.composedChart.horizontalScrollbar,
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: {
				height: BP.Prop(BP.Integer({
					...termsEditor.schemas.composedChart.horizontalScrollbarHeight,
					default: 20,
					fallbackValue: 20,
					constraints: {
						required: true,
						min: 20
					}
				}), 0),

				preview: BP.Prop(BP.Boolean({
					...termsEditor.schemas.composedChart.horizontalScrollbarPreview,
					default: false,
					fallbackValue: false,
					constraints: {
						required: true
					}
				}), 10)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 60, propGroups.common),

	datetimeUtc: BP.Prop(BP.Boolean({
		...termsEditor.schemas.composedChart.datetimeUtc,
		default: false
	}), 70, propGroups.common),

	datetimeFormat: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.composedChart.datetimeFormat,
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: {
				millisecond: BP.Prop(BP.String({
					...HAELibComponentsTerms.schemas.common.datetimeUnitValues.millisecond,
					default: "mm:ss SSS",
					fallbackValue: "mm:ss SSS",
					constraints: {
						required: true
					}
				}), 0),

				second: BP.Prop(BP.String({
					...HAELibComponentsTerms.schemas.common.datetimeUnitValues.second,
					default: "HH:mm:ss",
					fallbackValue: "HH:mm:ss",
					constraints: {
						required: true
					}
				}), 10),

				minute: BP.Prop(BP.String({
					...HAELibComponentsTerms.schemas.common.datetimeUnitValues.minute,
					default: "HH:mm",
					fallbackValue: "HH:mm",
					constraints: {
						required: true
					}
				}), 20),

				hour: BP.Prop(BP.String({
					...HAELibComponentsTerms.schemas.common.datetimeUnitValues.hour,
					default: "HH:mm",
					fallbackValue: "HH:mm",
					constraints: {
						required: true
					}
				}), 30),

				day: BP.Prop(BP.String({
					...HAELibComponentsTerms.schemas.common.datetimeUnitValues.day,
					default: "MMM dd",
					fallbackValue: "MMM dd",
					constraints: {
						required: true
					}
				}), 40),

				week: BP.Prop(BP.String({
					...HAELibComponentsTerms.schemas.common.datetimeUnitValues.week,
					default: "MMM dd",
					fallbackValue: "MMM dd",
					constraints: {
						required: true
					}
				}), 50),

				month: BP.Prop(BP.String({
					...HAELibComponentsTerms.schemas.common.datetimeUnitValues.month,
					default: "MMM",
					fallbackValue: "MMM",
					constraints: {
						required: true
					}
				}), 60),

				year: BP.Prop(BP.String({
					...HAELibComponentsTerms.schemas.common.datetimeUnitValues.year,
					default: "yyyy",
					fallbackValue: "yyyy",
					constraints: {
						required: true
					}
				}), 70)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 80, propGroups.common),

	animate: BP.Prop(BP.Boolean({
		...HAELibComponentsTerms.schemas.common.animate,
		default: false
	}), 90, propGroups.common)
};

/**
 * Composed Chart props type
 */
export type THAEComponentComposedChart_Props = Partial<TSchemaConstObjectPropsSpec<typeof HAEComponentComposedChart_Props>>;
