/*
 * Slider HAE component
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";
import RcSlider from "rc-slider";

type SliderProps = React.ComponentProps<typeof RcSlider>;

export const Slider: React.FC<SliderProps> = ({ step, disabled, value, ...props }) => (
	<RcSlider
		{...props}
		step={step ?? undefined}
		disabled={disabled ?? undefined}
		value={typeof props.onChange === "undefined" ? value : value ?? props.defaultValue}
	/>
);

export { SliderProps };
