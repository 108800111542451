/**
 * Use Loading hook
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { toNumber } from "@hexio_io/hae-lib-shared";

/**
 * @param componentLoading Component loading state
 * @param delay Loading delay
 */
export function useLoading(
	componentLoading: boolean,
	delay = 0
): [ loading: boolean, elementRef: React.MutableRefObject<HTMLDivElement> ] {
	const [ loading, setLoading ] = React.useState(false);

	const elementRef = React.useRef<HTMLDivElement>();

	React.useEffect(() => {
		let timeout: NodeJS.Timeout;

		if (componentLoading) {
			if (!loading) {			
				setLoading(true);
			}
		}
		else {
			// If state is no longer "LOADING" (or "LOADED") but internal "loading" state is still true, remove it
			if (loading) {
				let duration: number;

				if (elementRef.current) {
					elementRef.current.style.opacity = "0";

					duration = Math.max(
						toNumber(getComputedStyle(elementRef.current).getPropertyValue("--element-transition-duration")) || 0,
						0
					) * 1000;
				}

				if (duration) {
					timeout = setTimeout(() => {
						setLoading(false);
					}, duration);
				}
				else {
					setLoading(false);
				}
			}
		}

		return () => {
			// Negative componentLoading means it just changed to true
			if (elementRef.current && !componentLoading) {
				elementRef.current.style.opacity = "";
			}

			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [ componentLoading ]);

	React.useEffect(() => {
		if (!loading) {
			return;
		}

		if (elementRef.current) {
			elementRef.current.style.opacity = "0";
		}

		const timeout = setTimeout(() => {
			if (elementRef.current) {
				elementRef.current.style.opacity = "";
			}
		}, delay);
		
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [ loading ]);

	return [ loading, elementRef ];
}
