/**
 * Groups schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const propGroups = {
	common: {
		label: "schema:propGroups#values.common#label"
	},
	link: {
		label: "schema:propGroups#values.link#label"
	},
	state: {
		label: "schema:propGroups#values.state#label"
	},
	type: {
		label: "schema:propGroups#values.type#label"
	},
	style: {
		label: "schema:propGroups#values.style#label"
	},
	validation: {
		label: "schema:propGroups#values.validation#label"
	},
	advanced: {
		label: "schema:propGroups#values.advanced#label"
	},
};
