/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Schema validator error types
 */
export enum SCHEMA_VALIDATION_ERROR_TYPE {
	REQUIRED = "required",
	CONST = "const",
	RANGE = "limit",
	PATTERN = "pattern",
	FORMAT = "format",
	DUPLICATE_IDENTIFIER = "duplicate_identifier",
	INVALID_REF = "invalid_ref",
	INVALID_EXPRESSION = "invalid_expression",
	NO_VALIDATE = "no_validate" // schema does not support validation
}

/**
 * Schema validator validation error
 */
export interface IBlueprintSchemaValidationError {
	type: SCHEMA_VALIDATION_ERROR_TYPE;
	message: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	metaData: { [K: string]: any }
}

/**
 * Schema validator handler
 */
export interface IBlueprintSchemaValidatorHandler<TValue> {
	validate: (value: TValue) => IBlueprintSchemaValidationError[];
	compile: () => string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	export: () => any;
}

/**
 * Schema validator type
 */
export type IBlueprintSchemaValidator<TValue, TOpts> = (opts: TOpts) => IBlueprintSchemaValidatorHandler<TValue>;