/**
 * Font Size Class enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum FONT_SIZE_CLASS {
	//INHERIT = "font--size-inherit",
	BASE = "font--size-base",
	XSMALL = "font--size-x-small",
	SMALL = "font--size-small",
	MEDIUM = "font--size-medium",
	LARGE = "font--size-large",
	XLARGE = "font--size-x-large",
	XXLARGE = "font--size-xx-large",
	XXXLARGE = "font--size-xxx-large"
}

export type FONT_SIZE_CLASS_string = keyof typeof FONT_SIZE_CLASS;

export const FONT_SIZE_CLASS_default = "BASE";
