/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstStringOpts, SchemaConstString, ISchemaConstString } from "../const/SchemaConstString";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueStringOpts extends ISchemaValueOpts<ISchemaConstString>, ISchemaConstStringOpts {}

/**
 * Schema: String value
 *
 * @param opts Schema options
 */
export function SchemaValueString(opts: ISchemaValueStringOpts): ISchemaValue<ISchemaConstString> {

	return SchemaValue(SchemaConstString({
		...opts,
		allowInterpolation: opts.allowInterpolation !== undefined ? opts.allowInterpolation : true
	}), {
		...opts,
		allowTranslate: opts.allowTranslate !== undefined ? opts.allowTranslate : true
	});

}
