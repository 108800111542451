/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ERROR_NAMES } from "../errors/ErrorNames";
import { ServiceError } from "../errors/ServiceError";
import { ILogger } from "../logger";
import { IService } from "./IService";

export class Service implements IService {

	protected initialized = false;
	protected logger: ILogger;

	public constructor(logger: ILogger) {
		this.logger = logger.facility("service");
	}

	public async init(): Promise<void> {
		this.logger.info("Initializing.");
		this.initialized = true;
	}

	public async dispose(): Promise<void> {
		this.logger.info("Disposing...");
		this.initialized = false;
	}

	public isInit(): boolean {
		return this.initialized;
	}

	protected checkInit(message?: string): void {
		if (this.initialized !== true) {
			this.logger.warn(message);
			throw new ServiceError(ERROR_NAMES.SERVICE.INIT, `Service wasn't initialized${message ? ": " + message : "."}`);
		}
	}

}