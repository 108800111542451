/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Terms for DataSources
 */
export const dataSourcesTerms = {
	variable: {
		root: {
			label: "dataSource:variable.root#label",
			description: "dataSource:variable.root#description"
		},
		initialValue: {
			label: "dataSource:variable.initialValue#label",
			description: "dataSource:variable.initialValue#description"
		},
		value: {
			label: "dataSource:variable.value#label",
			description: "dataSource:variable.value#description"
		},
		setValue: {
			label: "dataSource:variable.setValue#label",
			description: "dataSource:variable.setValue#description"
		},
		setValueArg: {
			label: "dataSource:variable.setValueArg#label",
			description: "dataSource:variable.setValueArg#description"		
		}
	},
	action: {
		root: {
			label: "dataSource:action.root#label",
			description: "dataSource:action.root#description"
		},
		action: {
			label: "dataSource:action.action#label",
			description: "dataSource:action.action#description"
		},
		enabled: {
			label: "dataSource:action.enabled#label",
			description: "dataSource:action.enabled#description"
		},
		cacheData: {
			label: "dataSource:action.cacheData#label",
			description: "dataSource:action.cacheData#description"
		},
		reload: {
			label: "dataSource:action.reload#label",
			description: "dataSource:action.reload#description"
		},
		reloadEnabled: {
			label: "dataSource:action.reloadEnabled#label",
			description: "dataSource:action.reloadEnabled#description"
		},
		reloadInterval: {
			label: "dataSource:action.reloadInterval#label",
			description: "dataSource:action.reloadInterval#description"
		},
		default: {
			label: "dataSource:action.default#label",
			description: "dataSource:action.default#description"
		},
		scopeState: {
			label: "dataSource:action.scopeState#label",
			description: "dataSource:action.scopeState#description"
		},
		scopeIsLoading: {
			label: "dataSource:action.scopeIsLoading#label",
			description: "dataSource:action.scopeIsLoading#description"
		},
		scopeHasData: {
			label: "dataSource:action.scopeHasData#label",
			description: "dataSource:action.scopeHasData#description"
		},
		scopeData: {
			label: "dataSource:action.scopeData#label",
			description: "dataSource:action.scopeData#description"
		},
		scopeLastError: {
			label: "dataSource:action.scopeLastError#label",
			description: "dataSource:action.scopeLastError#description"
		},
		scopeReload: {
			label: "dataSource:action.scopeReload#label",
			description: "dataSource:action.scopeReload#description"
		}
	}
};