/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, DESIGN_CONTEXT_READ_MODE } from "@hexio_io/hae-lib-blueprint";

import { FLOW_NODE_TYPES } from "./nodes/BlueprintNode";
import { DOC_TYPES } from "./DocTypes";
import { createBlueprintSchema } from "./BlueprintBase";
import { BlueprintFlowNodeTypeStart } from "./nodes/BlueprintNodeStart";
import { BlueprintFlowNodeTypeIntegration } from "./nodes/BlueprintNodeIntegration";
import { BlueprintFlowNodeTypeAction } from "./nodes/BlueprintNodeAction";
import { BlueprintFlowNodeTypeTransform } from "./nodes/BlueprintNodeTransform";
import { BlueprintFlowNodeTypeCondition } from "./nodes/BlueprintNodeCondition";
import { BlueprintFlowNodeTypeOutput } from "./nodes/BlueprintNodeOutput";
import { BlueprintFlowNodeTypeError } from "./nodes/BlueprintNodeError";
import { BlueprintFlowNodeTypeStartViewToHtml } from "./nodes/BlueprintNodeViewToHtml";
import { BlueprintFlowNodeTypeLogin } from "./nodes/BlueprintNodeLogin";
import { BlueprintFlowNodeTypeLogout } from "./nodes/BlueprintNodeLogout";
import { BlueprintFlowNodeTypeSetSession } from "./nodes/BlueprintNodeSetSession";
import { BlueprintFlowNodeTypeMap } from "./nodes/BlueprintNodeMap";
import {
	BlueprintFlowNodeTypeTimeout,
	BlueprintFlowNodeTypeVariable
} from "./nodes";
import { BlueprintFlowNodeTypeReduce } from "./nodes/BlueprintNodeReduce";

export const ACTION_NODE_MAX_RECURSION = 3000;

/**
 * Action Node Types Blueprint Schema
 */
export const BlueprintActionNodeTypes = {
	[FLOW_NODE_TYPES.START]: BlueprintFlowNodeTypeStart,
	[FLOW_NODE_TYPES.INTEGRATION]: BlueprintFlowNodeTypeIntegration,
	[FLOW_NODE_TYPES.ACTION]: BlueprintFlowNodeTypeAction,
	[FLOW_NODE_TYPES.TRANSFORM]: BlueprintFlowNodeTypeTransform,
	[FLOW_NODE_TYPES.CONDITION]: BlueprintFlowNodeTypeCondition,
	[FLOW_NODE_TYPES.OUTPUT]: BlueprintFlowNodeTypeOutput,
	[FLOW_NODE_TYPES.ERROR]: BlueprintFlowNodeTypeError,
	[FLOW_NODE_TYPES.VIEWTOHTML]: BlueprintFlowNodeTypeStartViewToHtml,
	[FLOW_NODE_TYPES.LOGIN]: BlueprintFlowNodeTypeLogin,
	[FLOW_NODE_TYPES.LOGOUT]: BlueprintFlowNodeTypeLogout,
	[FLOW_NODE_TYPES.SETSESSION]: BlueprintFlowNodeTypeSetSession,
	[FLOW_NODE_TYPES.MAP]: BlueprintFlowNodeTypeMap,
	[FLOW_NODE_TYPES.REDUCE]: BlueprintFlowNodeTypeReduce,
	[FLOW_NODE_TYPES.VAR]: BlueprintFlowNodeTypeVariable,
	[FLOW_NODE_TYPES.TIMEOUT]: BlueprintFlowNodeTypeTimeout
};

export type TActionNodeTypes = keyof typeof BlueprintActionNodeTypes;

/**
 * Action Node Types
 */
export type TBlueprintActionNodeTypes = typeof BlueprintActionNodeTypes;

/**
 * Action blueprint "spec" property schema
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createBlueprintActionSpec = (constantParams: boolean) =>
	BP.Const.Object({
		// @todo Translate!!!
		label: "action",
		constraints: {
			required: true
		},
		props: {
			params: BP.Prop(
				BP.Builder.Object({
					// @todo Add translation terms
					label: "Parameters",
					description: "A set of parameters the action can be called with.",
					constantOnly: constantParams
				})
			),
			timeout: BP.Prop(
				BP.Const.Integer({
					// @todo Add translation term + description
					label: "Timeout",
					default: 10000,
					constraints: {
						required: true,
						min: 1000,
						/** Currently this is max possible timeout, because HttpClient has max timeout 300000 */
						max: 300000
					}
				})
			),
			maxRecursion: BP.Prop(
				BP.Const.Integer({
					label: "Max recursion",
					description:
						"Maximum number of the recursive action node calls. After exceeding this value the action will return an error.",
					default: ACTION_NODE_MAX_RECURSION,
					constraints: {
						min: 1
					}
				})
			),
			requireAuthenticatedUser: BP.Prop(
				BP.Const.Boolean({
					// @todo Add translation term + description
					label: "Require authenticated user",
					description: "When true only logged in users will be able to invoke this action.",
					default: true
				})
			),
			scheduled: BP.Prop(
				BP.OptGroup({
					label: "Scheduled",
					description: "Action will be executed automatically on the background.",
					enabledOpts: {
						allowExpression: false
					},
					value: BP.Const.Object({
						props: {
							once: BP.Prop(
								BP.Const.Boolean({
									label: "Once",
									description: "Execute action only once.",
									default: false
								})
							),
							interval: BP.Prop(
								BP.Const.Integer({
									label: "Interval [ms]",
									description: "Interval in milliseconds.",
									default: 60000,
									constraints: {
										min: 100
									}
								})
							)
						}
					})
				})
			),
			nodes: BP.Prop(
				BP.Conditional({
					condition: (dCtx) => dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL,
					value: BP.Special.FlowNodeList({
						nodeTypes: BlueprintActionNodeTypes,
						entryNode: {
							id: "start",
							type: FLOW_NODE_TYPES.START,
							defaultPosition: {
								x: 10,
								y: 50
							},
							defaultOpts: {}
						}
					})
				})
			)
		}
	});

export type TBlueprintActionSpecSchema = ReturnType<typeof createBlueprintActionSpec>;

/**
 * Action Blueprint Schema
 */
export const BlueprintAction = createBlueprintSchema(DOC_TYPES.ACTION_V1, createBlueprintActionSpec(false));
export const BlueprintActionWithConstParams = createBlueprintSchema(
	DOC_TYPES.ACTION_V1,
	createBlueprintActionSpec(true)
);

/** Action Blueprint Schema Type */
export type TBlueprintActionSchema = typeof BlueprintAction;
