/**
 * Tree Navigation Style HAE enum
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum TREE_NAVIGATION_STYLE {
	SIMPLE = "simple",
	MENU_LEFT = "menu-left",
	MENU_RIGHT = "menu-right"
}

export type TREE_NAVIGATION_STYLE_string = keyof typeof TREE_NAVIGATION_STYLE;

export const TREE_NAVIGATION_STYLE_default: TREE_NAVIGATION_STYLE_string = "SIMPLE";
