/**
 * Hexio App Engine Shared library.
 *
 * @package hae-lib-shared
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { stringify } from "qs";

/**
 * Returns "&"" if url already has any query parameters else returns "?".
 * @param url URL
 * @returns "&"" | "?"
 */
export function getUrlJoinChar(url: string): string {
	return /\?/.test(url) ? "&" : "?";
}

/**
 * Builds url query parameters
 *
 * @param url URL
 * @param querystring Query string
 * @param queryParams Query object
 * @returns 
 */
export function buildUrlQuery(url: string, querystring?: string, queryParams?: { [K: string]: any }): string {

	let newUrl = url;

	if (typeof querystring === "string") {
		const _querystring = querystring.trim();
		if (_querystring.length > 0) {
			newUrl = `${newUrl}${getUrlJoinChar(newUrl)}${_querystring}`;
		}
	}

	if (queryParams) {
		const stringified = stringify(queryParams);
		if (stringified) {
			newUrl = `${newUrl}${getUrlJoinChar(newUrl)}${stringified}`;
		}
	}

	return newUrl;

}