/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Terms for UI
 */
export const uiTerms = {
	events: {
		messages: {
			actionHasFailed: "events:messages.actionHasFailed#message",
			actionNotAllowed: "events:messages.actionsNotAllowed#message",
			methodHasFailed: "events:messages.methodHasFailed#message",
			valueIsNotMethod: "events:messages.valueIsNotMethod#message",
		},
		types: {
			authAllowed: "events:types.authAllowed#label",
			conditionResult: "events:types.conditionResult#label",
			dialogResult: "events:types.dialogResult#label",
			dialogButtonId: "events:types.dialogButtonId#label",
			dialogCustomData: "events:types.dialogCustomData#label",
			methodError: {
				label: "events:types.methodError#label",
				nameLabel: "events:types.methodError.name#label",
				messageLabel: "events:types.methodError.message#label"
			}
		}
	}
};