/**
 * Sidebar Layout HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	BP,
	Type,
	defineElementaryComponent,
	ISchemaConstObjectOptsGroup,
	COMPONENT_MODE,
} from "@hexio_io/hae-lib-blueprint";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	Container,
	ContainerContentSchema,
	ClassList,
	ContainerProps,
	getValuesFromStringEnum,
	getStringEnumKeyByValue,
	CONTAINER_FLOW,
	StyleSheet,
	getStringEnumCssValue,
	HAEComponentMainContext,
	dimensionWithConstantPattern,
	useStyleSheetRegistry,
	Viewport,
	OVERFLOW,
	CONTAINER_DEFAULT_HORIZONTAL_ALIGN,
	CONTAINER_DEFAULT_VERTICAL_ALIGN
} from "@hexio_io/hae-lib-components";

import { isBrowser, isFunction } from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";
import {
	SIDEBAR_LAYOUT_POSITION, SIDEBAR_LAYOUT_POSITION_default, SIDEBAR_LAYOUT_POSITION_opts
} from "../../Enums/SIDEBAR_LAYOUT_POSITION";
import { SIDEBAR_LAYOUT_WIDTH, SIDEBAR_LAYOUT_WIDTH_default } from "../../Enums/SIDEBAR_LAYOUT_WIDTH";

interface HAEComponentSidebarLayout_State {}

const HAEComponentSidebarLayout_propGroups: Record<string, ISchemaConstObjectOptsGroup> = {

	content: {
		...termsEditor.schemas.sidebarLayout.content,
		id: "content",
		order: 0,
		defaultExpanded: true
	},

	/*sidebar: {
		...termsEditor.schemas.sidebarLayout.sidebar,
		id: "sidebar",
		order: 0
	}*/

};

const HAEComponentSidebarLayout_Props = {

	content: BP.Prop(ContainerContentSchema(), 0, HAEComponentSidebarLayout_propGroups.content),

	padding: BP.Prop(ContainerProps.padding.schema, 10, HAEComponentSidebarLayout_propGroups.content),

	spacing: BP.Prop(ContainerProps.spacing.schema, 20, HAEComponentSidebarLayout_propGroups.content),

	sidebar: BP.Prop(BP.Const.Object({
		...termsEditor.schemas.sidebarLayout.sidebar,
		props: {
			content: BP.Prop(ContainerContentSchema(), 0),

			fixed: BP.Prop(BP.Boolean({
				...termsEditor.schemas.sidebarLayout.fixed,
				default: true,
				constraints: {
					required: true
				}
			}), 10),

			position: BP.Prop(BP.Enum.String({
				...termsEditor.schemas.sidebarLayout.position,
				options: getValuesFromStringEnum(
					SIDEBAR_LAYOUT_POSITION,
					termsEditor.schemas.sidebarLayout.positionValues,
					SIDEBAR_LAYOUT_POSITION_opts
				),
				default: SIDEBAR_LAYOUT_POSITION_default,
				fallbackValue: SIDEBAR_LAYOUT_POSITION_default,
				editorOptions: {
					controlType: "buttons"
				},
				constraints: {
					required: true
				}
			}), 20),

			padding: BP.Prop(ContainerProps.padding.schema, 30),

			spacing: BP.Prop(ContainerProps.spacing.schema, 40),

			width: BP.Prop(BP.StringWithConst({
				...termsEditor.schemas.sidebarLayout.width,
				constants: getValuesFromStringEnum(SIDEBAR_LAYOUT_WIDTH, termsEditor.schemas.sidebarLayout.widthValues),
				example: "MEDIUM; 100px; 50%",
				default: SIDEBAR_LAYOUT_WIDTH_default,
				fallbackValue: SIDEBAR_LAYOUT_WIDTH_default,
				constraints: {
					...termsEditor.schemas.sidebarLayout.widthConstraints,
					pattern: dimensionWithConstantPattern,
					required: true
				}
			}), 200)
		},
		constraints: {
			required: true
		},
		editorOptions: {
			defaultExpanded: true
		}
	}), 200)

};

const HAEComponentSidebarLayout_Events = {};

const HAEComponentSidebarLayout_Definition = defineElementaryComponent<
	typeof HAEComponentSidebarLayout_Props,
	HAEComponentSidebarLayout_State,
	typeof HAEComponentSidebarLayout_Events
>({

	...termsEditor.components.sidebarLayout.component,

	name: "sidebarLayout",

	category: "layout",

	icon: "mdi/page-layout-sidebar-left",

	docUrl: "...",

	order: 40,

	container: true,

	props: HAEComponentSidebarLayout_Props,

	events: HAEComponentSidebarLayout_Events,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}

});

const HAEComponentSidebarLayout_React: THAEComponentReact<typeof HAEComponentSidebarLayout_Definition> = ({
	props, componentInstance, reactComponentClassList
}) => {
	const {
		content,
		padding,
		spacing,
		sidebar
	} = props;

	const { componentMode } = componentInstance;

	const componentMainContext = React.useContext(HAEComponentMainContext);
	const { viewport } = componentMainContext;

	const elementRef = React.useRef<HTMLDivElement>();

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-sidebar-layout", componentInstance.safePath, { componentClassList: reactComponentClassList, componentMode }
	);

	classList.add(...ClassList.fromObject({
		"cmp-sidebar-layout--fixed": sidebar.fixed,
		"cmp-sidebar-layout--static": !sidebar.fixed
	}));

	if (sidebar.position in SIDEBAR_LAYOUT_POSITION) {
		classList.add(idClassName, `cmp-sidebar-layout--position-${SIDEBAR_LAYOUT_POSITION[sidebar.position]}`);
	}

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		// Width

		if (sidebar.width) {
			const sidebarWidthValue = getStringEnumCssValue(SIDEBAR_LAYOUT_WIDTH, sidebar.width, "element-sidebar-width-");

			result.addString(`.${idClassName}`, `--element-sidebar-width: ${sidebarWidthValue};`);
		}

		return result;
	}, [ idClassName, sidebar.width ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	const containerFlow = getStringEnumKeyByValue(CONTAINER_FLOW, CONTAINER_FLOW.COLUMN);
	const containerHorizontalAlign = CONTAINER_DEFAULT_HORIZONTAL_ALIGN;
	const containerVerticalAlign = CONTAINER_DEFAULT_VERTICAL_ALIGN;

	const viewportRootElement = viewport.getRootElement();

	const appHeaderHeight = isBrowser() &&
		viewportRootElement &&
		isFunction(getComputedStyle) &&
		parseFloat(getComputedStyle(viewportRootElement).getPropertyValue("--app-header-height")) ||
		0;

	React.useLayoutEffect(() => {
		function fixPositionProperties() {
			if (componentMode === COMPONENT_MODE.EDIT || !sidebar.fixed || !elementRef.current) {
				return;
			}

			const elementRect = elementRef.current.getBoundingClientRect();

			if (elementRect.bottom >= 0) {
				const { height: viewportHeight, top: viewportTop } = viewport.getProperties();

				const top = Math.max(appHeaderHeight + viewportTop, elementRect.top);
				const height = Math.max(0, Math.min(viewportHeight - top + viewportTop, elementRect.bottom - top));

				elementRef.current.style.setProperty("--element-sidebar-display", "");
				elementRef.current.style.setProperty("--element-sidebar-top", `${top}px`);
				elementRef.current.style.setProperty("--element-sidebar-height", `${height}px`);
			}
			else {
				elementRef.current.style.setProperty("--element-sidebar-display", "none");
			}
		}

		fixPositionProperties();

		// Unfortunately contentRect from entries doesn't return top coordinates as getBoundingClientRect so it's not used

		const resizeObserver = new ResizeObserver(() => fixPositionProperties());

		resizeObserver.observe(elementRef.current);

		function _windowEditorViewportScrollHandler() {
			fixPositionProperties();
		}

		window.addEventListener(Viewport.SCROLL_EVENT_TYPE, _windowEditorViewportScrollHandler);

		return () => {
			resizeObserver.disconnect();

			window.removeEventListener(Viewport.SCROLL_EVENT_TYPE, _windowEditorViewportScrollHandler);
		};
	}, [ sidebar.fixed, elementRef.current, appHeaderHeight, componentMode ]);

	return (
		<div ref={elementRef} className={classList.toClassName()}>
			<Container
				content={content}
				flow={containerFlow}
				horizontalAlign={containerHorizontalAlign}
				verticalAlign={containerVerticalAlign}
				padding={padding}
				spacing={spacing}
				componentPath={[ ...componentInstance.safePath, "container" ]}
				componentMode={componentMode}
				classList={new ClassList("cmp-sidebar-layout__container")}
				contentModelNode={componentInstance.modelNode?.props.props.content as unknown as any}
			/>
			<div className="cmp-sidebar-layout__sidebar">
				<Container
					content={sidebar.content}
					flow={containerFlow}
					horizontalAlign={containerHorizontalAlign}
					verticalAlign={containerVerticalAlign}
					padding={sidebar.padding}
					spacing={sidebar.spacing}
					overflow={getStringEnumKeyByValue(OVERFLOW, OVERFLOW.VERTICAL)}
					componentPath={[ ...componentInstance.safePath, "sidebar-container" ]}
					componentMode={componentMode}
					classList={new ClassList("cmp-sidebar-layout__sidebar-container")}
					contentModelNode={(componentInstance.modelNode?.props.props.sidebar as any)?.props.content as unknown as any}
				/>
			</div>
		</div>
	);
};

export const HAEComponentSidebarLayout: THAEComponentDefinition<typeof HAEComponentSidebarLayout_Definition> = {
	...HAEComponentSidebarLayout_Definition,
	reactComponent: HAEComponentSidebarLayout_React
};
