/**
 * Number Field Slider Mode enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum NUMBER_FIELD_SLIDER_MODE {
	DEFAULT = "default",
	FROM_START = "from-start",
	FROM_END = "from-end"
}
