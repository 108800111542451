/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const TEMP_FILE = {
	/** Temporary file default TTL in milliseconds */
	EXPIRES_IN: 30000,
	/** Check temporary files TTL every milliseconds */
	CHECK_EVERY: 60000
};