/**
 * Gauge Chart HAE component Get Current Range
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { getStringEnumValue } from "@hexio_io/hae-lib-components";
import { isNumber } from "@hexio_io/hae-lib-shared";
import { GAUGE_CHART_RANGE_TYPE } from "../../Enums/GAUGE_CHART_RANGE_TYPE";
import { HAEComponentGaugeChart_State, TGaugeChartRange } from "./state";

/**
 * Returns current range
 * 
 * @param value Value
 * @param ranges Ranges
 */
export function getCurrentRange(value: number, ranges: HAEComponentGaugeChart_State["ranges"]): TGaugeChartRange {
	return ranges.find((item) => {
		const typeValue = getStringEnumValue(GAUGE_CHART_RANGE_TYPE, item.typeData.type);

		if (isNumber(value) && typeValue === GAUGE_CHART_RANGE_TYPE.DEFAULT) {
			const typeDataValue = item.typeData.value[item.typeData.type];

			return value >= typeDataValue.startValue && value < typeDataValue.endValue;
		}

		if (typeValue === GAUGE_CHART_RANGE_TYPE.NULL) {
			return value === null;
		}

		return false;
	});
}
