/**
 * Composed Chart HAE component definition
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	Type,
	defineElementaryComponent,
	IScope,
	createSubScope,
	OBJECT_TYPE_PROP_NAME,
	OBJECT_TYPE
} from "@hexio_io/hae-lib-blueprint";
import { getStringEnumValue } from "@hexio_io/hae-lib-components";

import { getTimestamp, isNumber, isString, isValidValue, isUndefined, isDeepEqual, toNumber } from "@hexio_io/hae-lib-shared";
import { COMPOSED_CHART_AXIS_POSITION, COMPOSED_CHART_AXIS_POSITION_default } from "../../Enums/COMPOSED_CHART_AXIS_POSITION";
import { COMPOSED_CHART_AXIS_TYPE, COMPOSED_CHART_AXIS_TYPE_default } from "../../Enums/COMPOSED_CHART_AXIS_TYPE";
import { COMPOSED_CHART_SERIES_TYPE, COMPOSED_CHART_SERIES_TYPE_default } from "../../Enums/COMPOSED_CHART_SERIES_TYPE";

import { termsEditor } from "../../terms";

import { HAEComponentComposedChart_Events } from "./events";
import { HAEComponentComposedChart_Props } from "./props";
import {
	HAEComponentComposedChart_State,
	IDataItem,
	TDataValue,
	IResolvedAxis,
	TResolvedAxes,
	TResolvedRanges,
	TResolvedSeries,
	TDatetimeAxesKeys
} from "./state";

/**
 * Creates key subscope
 */
function createKeySubScope(parentScope: IScope, item: unknown) {
	return createSubScope(parentScope, { item }, { item: Type.Any({}) });
}

/**
 * Sanitizes chart value
 */
function sanitizeValue(value: unknown, axisType: COMPOSED_CHART_AXIS_TYPE): TDataValue {
	const string = isString(value);

	if (!isNumber(value) && !string && value !== null) {
		return void 0;
	}

	let result: TDataValue;

	switch (axisType) {
		case COMPOSED_CHART_AXIS_TYPE.VALUE: {
			result = toNumber(value);

			if (!Number.isFinite(result)) {
				result = null;
			}

			break;
		}

		case COMPOSED_CHART_AXIS_TYPE.CATEGORY: {
			result = string ? value as string : `${value}`;

			break;
		}

		case COMPOSED_CHART_AXIS_TYPE.DATETIME: {
			result = getTimestamp(value as string);

			if (!Number.isFinite(result)) {
				result = void 0;
			}

			break;
		}
	}

	return result;
}

/**
 * Fixes datetime axes keys
 */
function fixDatetimeAxesKeys(datetimeAxesKeys: TDatetimeAxesKeys, axis: IResolvedAxis, key: string): void {
	if (
		axis.type === COMPOSED_CHART_AXIS_TYPE.DATETIME &&
		datetimeAxesKeys[axis.id] instanceof Set
	) {
		datetimeAxesKeys[axis.id].add(key);
	}
}

export const HAEComponentComposedChart_Definition = defineElementaryComponent<
	typeof HAEComponentComposedChart_Props,
	HAEComponentComposedChart_State,
	typeof HAEComponentComposedChart_Events
>({
	...termsEditor.components.composedChart.component,

	name: "composedChart",

	category: "charts",

	icon: "mdi/chart-areaspline",

	docUrl: "...",

	order: 10,

	props: HAEComponentComposedChart_Props,

	events: HAEComponentComposedChart_Events,

	resolve: (spec, state) => {
		if (!Array.isArray(spec.items)) {
			return state;
		}

		const {
			items,
			legend,
			horizontalScrollbar,
			animate,
			datetimeUtc,
			datetimeFormat
		} = spec;

		const axes = Array.isArray(spec.axes) ? spec.axes : [];
		const series = Array.isArray(spec.series) ? spec.series : [];
		const ranges = Array.isArray(spec.ranges) ? spec.ranges : [];

		const {
			resolvedAxes: stateResolvedAxes = new Map(),
			resolvedSeries: stateResolvedSeries = [],
			resolvedRanges: stateResolvedRanges = new Map(),
			legend: stateLegend,
			horizontalScrollbar: stateHorizontalScrollbar,
			animate: stateAnimate,
			datetimeUtc: stateDatetimeUtc,
			datetimeFormat: stateDatetimeFormat
		} = state || {};

		// Axes

		const resolvedAxes: TResolvedAxes = new Map();

		const mainAxesIds: Partial<Record<COMPOSED_CHART_AXIS_POSITION, string>> = {};

		const datetimeAxesKeys: TDatetimeAxesKeys = {};

		axes.forEach((item, index) => {
			const positionValue = getStringEnumValue(COMPOSED_CHART_AXIS_POSITION, item.position, COMPOSED_CHART_AXIS_POSITION_default);
			const typeValue = getStringEnumValue(COMPOSED_CHART_AXIS_TYPE, item.typeData.type, COMPOSED_CHART_AXIS_TYPE_default);

			const id = item.id || (!resolvedAxes.has(positionValue) ? positionValue : `${positionValue}_${index}`);

			if (!mainAxesIds[positionValue]) {
				mainAxesIds[positionValue] = id;
			}

			const horizontal = positionValue === COMPOSED_CHART_AXIS_POSITION.HORIZONTAL_BOTTOM ||
				positionValue === COMPOSED_CHART_AXIS_POSITION.HORIZONTAL_TOP;

			const opposite = positionValue === COMPOSED_CHART_AXIS_POSITION.HORIZONTAL_TOP ||
				positionValue === COMPOSED_CHART_AXIS_POSITION.VERTICAL_RIGHT;

			resolvedAxes.set(id, {
				id,
				position: positionValue,
				type: typeValue,
				horizontal,
				opposite,
				originalData: item
			});

			if (typeValue === COMPOSED_CHART_AXIS_TYPE.DATETIME) {
				datetimeAxesKeys[id] = new Set();
			}
		});

		// Series

		const resolvedSeries: TResolvedSeries = [];

		series.forEach((item, index) => {
			const xAxisId = resolvedAxes.has(item.xAxisId) ?
				item.xAxisId :
				(
					mainAxesIds[COMPOSED_CHART_AXIS_POSITION.HORIZONTAL_BOTTOM] ||
					mainAxesIds[COMPOSED_CHART_AXIS_POSITION.HORIZONTAL_TOP]
				);

			const yAxisId = resolvedAxes.has(item.yAxisId) ?
				item.yAxisId :
				(
					mainAxesIds[COMPOSED_CHART_AXIS_POSITION.VERTICAL_LEFT] ||
					mainAxesIds[COMPOSED_CHART_AXIS_POSITION.VERTICAL_RIGHT]
				);

			if (!xAxisId || !yAxisId) {
				return;
			}

			const typeValue = getStringEnumValue(COMPOSED_CHART_SERIES_TYPE, item.typeData.type, COMPOSED_CHART_SERIES_TYPE_default);

			resolvedSeries.push({
				type: typeValue,
				xAxis: resolvedAxes.get(xAxisId),
				xKey: `x_${index}`,
				yAxis: resolvedAxes.get(yAxisId),
				yKey: `y_${index}`,
				originalData: item
			});
		});

		// Data

		const data: IDataItem[] = [];

		items.forEach((item) => {
			if (!isValidValue(item)) {
				return;
			}

			const dataItem: IDataItem = {
				originalData: item
			};

			resolvedSeries.forEach((resolvedSeriesItem) => {
				const x = sanitizeValue(
					resolvedSeriesItem.originalData.xAxisMapper ?
						resolvedSeriesItem.originalData.xAxisMapper((parentScope) => createKeySubScope(parentScope, item)) :
						item[resolvedSeriesItem.originalData.xAxisKey],
					resolvedSeriesItem.xAxis.type
				);

				const y = sanitizeValue(
					resolvedSeriesItem.originalData.yAxisMapper ?
						resolvedSeriesItem.originalData.yAxisMapper((parentScope) => createKeySubScope(parentScope, item)) :
						item[resolvedSeriesItem.originalData.yAxisKey],
					resolvedSeriesItem.yAxis.type
				);

				if (!isUndefined(x) && !isUndefined(y)) {
					dataItem[resolvedSeriesItem.xKey] = x;
					dataItem[resolvedSeriesItem.yKey] = y;

					fixDatetimeAxesKeys(datetimeAxesKeys, resolvedSeriesItem.xAxis, resolvedSeriesItem.xKey);
					fixDatetimeAxesKeys(datetimeAxesKeys, resolvedSeriesItem.yAxis, resolvedSeriesItem.yKey);
				}
			});

			if (Object.keys(dataItem).length > 1) {
				data.push(dataItem);
			}
		});

		// Datetime axes intervals

		Object.entries(datetimeAxesKeys).forEach(([ axisId, keys ]) => {
			const axis = { ...resolvedAxes.get(axisId) };

			keys.forEach((key) => {
				const filteredDataItems = data.filter((item) => isNumber(item[key]));

				if (filteredDataItems.length < 2) {
					return;
				}

				const [ firstDataItem, secondDataItem ] = filteredDataItems;
				const datetimeInterval = Math.abs((secondDataItem[key] as number) - (firstDataItem[key] as number));

				axis.datetimeInterval = isNumber(axis.datetimeInterval) ?
					Math.min(axis.datetimeInterval, datetimeInterval) :
					datetimeInterval;
			});

			resolvedAxes.set(axisId, axis);
		});

		// Ranges

		const resolvedRanges: TResolvedRanges = new Map();

		ranges.forEach((item) => {
			if (!item.id || !item.axisId || !resolvedAxes.has(item.axisId) || (!item.startValue && item.startValue !== 0)) {
				return;
			}

			resolvedRanges.set(item.id, {
				id: item.id,
				axis: resolvedAxes.get(item.axisId),
				originalData: item
			});
		});

		// Return

		const settingsUpdated = !isDeepEqual(resolvedAxes, stateResolvedAxes) ||
			!isDeepEqual(resolvedSeries, stateResolvedSeries) ||
			!isDeepEqual(resolvedRanges, stateResolvedRanges) ||
			!isDeepEqual(legend, stateLegend) ||
			!isDeepEqual(horizontalScrollbar, stateHorizontalScrollbar) ||
			!isDeepEqual(animate, stateAnimate) ||
			!isDeepEqual(datetimeUtc, stateDatetimeUtc) ||
			!isDeepEqual(datetimeFormat, stateDatetimeFormat);

		return {
			data: {
				[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.SCOPE,
				value: data
			},
			resolvedAxes,
			resolvedSeries,
			resolvedRanges,
			legend,
			horizontalScrollbar,
			animate,
			datetimeUtc,
			datetimeFormat,
			settingsTimestamp: !settingsUpdated ? (state?.settingsTimestamp || 0) : getTimestamp()
		};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});
