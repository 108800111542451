/**
 * View component runtime terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const view = {
	unauthorized: "component:view.unauthorized",
	noContent: "component:view.noContent",
	states: {
		blank: "component:view.states.blank",
		not_found: "component:view.states.notFound",
		invalid_blueprint: "component:view.states.invalidBlueprint",
		invalid_params: "component:view.states.invalidParams",
		invalid_error: "component:view.states.invalidError",
	}
};
