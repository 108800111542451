/*
 * useForkRef HAE hook
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ForwardedRef, MutableRefObject, useMemo } from "react";
import { isFunction, isNil } from "@hexio_io/hae-lib-shared";

export type HTMLElementOrNull = HTMLElement | null;
export type RefElementOrNull<T> = T | null;
export type CallbackRef = (node: HTMLElementOrNull) => unknown;
export type AnyRef = CallbackRef | MutableRefObject<HTMLElementOrNull> | ForwardedRef<any>;

function setRef(ref: AnyRef, value: HTMLElementOrNull) {
	if (!ref) {
		return;
	}

	if (isFunction(ref)) {
		ref(value);
		return;
	}

	if (ref) {
		ref.current = value;
		return;
	}
}

export function useForkRef(refA: AnyRef, refB: AnyRef): CallbackRef {
	/**
	 * This will create a new function if the ref props change and are defined.
	 * This means react will call the old forkRef with `null` and the new forkRef
	 * with the ref. Cleanup naturally emerges from this behavior
	 */
	return useMemo(() => {
		if (isNil(refA) && isNil(refB)) {
			return null;
		}

		return (refValue: HTMLElementOrNull) => {
			setRef(refA, refValue);
			setRef(refB, refValue);
		};
	}, [ refA, refB ]);
}
