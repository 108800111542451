/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { DesignContext, RuntimeContext, TGetBlueprintSchemaModel } from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../../app";
import { BlueprintRoutes, DOC_TYPES } from "../../blueprints";
import { ILogger } from "../../logger";
import { GENERIC_RESOURCE_PERMISSIONS, RESOURCE_PERMISSIONS } from "../../registries";
import { RESOURCE_TYPES } from "../IResource";
import { RESOURCE_ON_EVENT_TYPE } from "../IResourceManager";
import { IRoutesResourceProps, IRoutesResourceType } from "./IRoutesResource";

type TRoutesSchemaModel = TGetBlueprintSchemaModel<typeof BlueprintRoutes>;

export class RoutesResourceV1 implements IRoutesResourceType {

	public get permissions(): RESOURCE_PERMISSIONS[] {
		return GENERIC_RESOURCE_PERMISSIONS;
	}

	public get name(): string {
		return DOC_TYPES.ROUTES_V1;
	}

	public get category(): string {
		return RESOURCE_TYPES.ROUTES;
	}

	public get statsName(): string {
		return "routes";
	}

	protected logger: ILogger;

	public constructor(protected app: IAppServer) {
		this.logger = this.app.get("logger").facility("resource-routes-v1");
	}

	public setup(resource: IRoutesResourceProps): IRoutesResourceProps {

		resource.resourceType = RESOURCE_TYPES.ROUTES;
		resource.parsingDetails.isRegistered = true;

		return resource;

	}

	public async scan(resource: IRoutesResourceProps): Promise<IRoutesResourceProps> {

		let dCtx: DesignContext;
		let model: TRoutesSchemaModel;
		let rCtx: RuntimeContext;

		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Parse routes '${uri}'.`);

		try {

			dCtx = resourceManager.createDCtx(false);

			resource = await resourceManager.parseModel<IRoutesResourceProps>(resource, dCtx, BlueprintRoutes);
			model = resource.parsedData.model as TRoutesSchemaModel;

			if (!model) {
				this.logger.warn("Can't parse routes model.");
				this.logger.debug({ uri });
				return resource;
			}

			resource = await resourceManager.renderSpec(resource);
			const spec = resource.parsedData.spec;

			if (!spec) {
				this.logger.warn("Can't parse routes model.");
				this.logger.debug({ uri });
				return resource;
			}

			resource.parsedData.routes = spec.spec;

			return resource;

		} catch (error) {

			this.logger.debug({ error: error?.message });
			throw error;

		} finally {

			try {
				if (rCtx) {
					rCtx.destroy();
				}
				if (model) {
					model.schema.destroy(model);
				}
				if (dCtx) {
					dCtx.destroy();
				}
			} catch (error) {
				this.logger.debug(error);
			}

		}

	}

	public async parse(resource: IRoutesResourceProps): Promise<IRoutesResourceProps> {
		return resource;
	}

	public getDependenciesToReload(resource: IRoutesResourceProps, eventType?: RESOURCE_ON_EVENT_TYPE): string[] {
		return [];
	}

}