/**
 * Hexio App Engine Shared Library
 *
 * @package hae-lib-shared
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export type TSimpleEventListener<TEventData> = (eventData?: TEventData) => void;
export type TSimpleEventEmitter<TEventData> = Array<TSimpleEventListener<TEventData>>;

export type TGetSimpleEventEmitterDataType<TEmitter> = TEmitter extends TSimpleEventEmitter<infer R> ? R : undefined;

export type TGetSimpleEventListener<TEmitter> = TSimpleEventListener<TGetSimpleEventEmitterDataType<TEmitter>>


/**
 * Creates a simple event emitter
 */
export function createEventEmitter<TEventData = never>() : TSimpleEventEmitter<TEventData> {
	return [];
}

/**
 * Adds an event listener to emitter
 *
 * @param eventEmitter Simple event emitter
 * @param listener Listener function
 */
export function onEvent<
	TEmitter extends TSimpleEventEmitter<unknown> = TSimpleEventEmitter<unknown>,
	TEventData = TGetSimpleEventEmitterDataType<TEmitter>
>(eventEmitter: TEmitter, listener: TSimpleEventListener<TEventData>): void {

	eventEmitter.push(listener);

}

/**
 * Removes an event listener from emitter
 *
 * @param eventEmitter Simple event emitter
 * @param listener Listener function
 */
export function offEvent<TEventData>(eventEmitter: TSimpleEventEmitter<TEventData>, listener: TSimpleEventListener<TEventData>): void {

	const i = eventEmitter.indexOf(listener);

	if (i >= 0) {
		eventEmitter.splice(i, 1);
	}

}

/**
 * Removes an event listener from emitter
 *
 * @param eventEmitter Simple event emitter
 * @param listener Listener function
 */
export function onceEvent<
	TEmitter extends TSimpleEventEmitter<unknown> = TSimpleEventEmitter<unknown>,
	TEventData = TGetSimpleEventEmitterDataType<TEmitter>
>(eventEmitter: TEmitter, listener: TSimpleEventListener<TEventData>): void {

	const eventHandler = (eventData: TEventData) => {

		try {
			listener(eventData);
		} finally {
			const i = eventEmitter.indexOf(eventHandler);

			if (i >= 0) {
				eventEmitter.splice(i, 1);
			}
		}

	};

	eventEmitter.push(eventHandler);

}

/**
 * Removes all listeners from an emitter
 *
 * @param eventEmitter Simple event emitter
 */
export function removeAllEventListeners(eventEmitter: TSimpleEventEmitter<unknown>): void {

	eventEmitter.splice(0, eventEmitter.length);

}

/**
 * Emits event on an event emitter
 *
 * @param eventEmitter Simple event emitter
 * @param eventData Event data
 */
export function emitEvent<
	TEmitter extends TSimpleEventEmitter<unknown> = TSimpleEventEmitter<unknown>
>(eventEmitter: TEmitter, eventData?: TGetSimpleEventEmitterDataType<TEmitter>): void {

	const _events = eventEmitter.slice();

	for (let i = 0; i < _events.length; i++) {
		_events[i](eventData);
	}

}
