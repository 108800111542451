/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IBaseError } from "./IError";

/**
 * Base Error Details
 */
export interface IBaseErrorDetails {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[K: string]: any;
}

/**
 * Base Error
 */
export class BaseError extends Error implements IBaseError {

	/** Error name */
	public error: string;

	/** Error message */
	public message: string;

	/** Human readable message */
	public userMessage?: string;

	public constructor(
		error: string,
		message: string,
		public errorDetails: IBaseErrorDetails = {},
		public sourceError?: any
	) {

		super(message);

		this.userMessage = message;
		this.message = message;
		this.name = error;

	}

}