/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { OBJECT_TYPE, OBJECT_TYPE_PROP_NAME, SCOPE_KEY_INDEX_PROP_NAME } from "../constants";
import { RuntimeContext, RUNTIME_CONTEXT_MODE } from "../Context/RuntimeContext";
import { TTypeDesc, TypeDescAny } from "../Shared/ITypeDescriptor";
import { ISchemaConstObjectModel, TSchemaConstObjectProps, TSchemaConstObjectPropsSpec } from "../schemas/const/SchemaConstObject";
import { dataEqual } from "../Shared/Equal";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { IScope } from "../Shared/Scope";
import {
	IDataSourceDefinition,
	IDataSourceInfo,
	IDataSourceStateBase,
	TDataSourceUpdateStateFunction,
	TDataSourceUpdateStateHandlerFunction
} from "./IDataSourceDefinition";
import { IDataSourceInstance } from "./IDataSourceInstance";

const DEBUG_LOG_CHANGES = false;

/**
 * Data source data provided to scope
 */
export interface IDataSourceScopeData {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[K: string]: any;
}

/**
 * DataSources's resolve function
 *
 * Function takes current opts, current state, and returns a new state
 */
export type TDataSourceResolveFunction<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase
> = (
	/** Current opts */
	opts: TSchemaConstObjectPropsSpec<TDataSourceOpts>,
	/** Current state */
	state: TDataSourceState,
	/** Function to update data source state asynchronously */
	updateStateAsync: TDataSourceUpdateStateFunction<TDataSourceState>,
	/** Data source instance */
	dsInstance: IDataSourceInstance<TDataSourceOpts, TDataSourceState>,
	/** Runtime context instance */
	rCtx: RuntimeContext,
	/** Current scope */
	scope: IScope
) => TDataSourceState;

/**
 * Function to return scope data based on spec and state
 */
export type TDataSourceGetScopeDataFunction<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase
> = (
	/** Current specification */
	opts: TSchemaConstObjectPropsSpec<TDataSourceOpts>,
	/** Current state */
	state: TDataSourceState
) => IDataSourceScopeData;

/**
 * Function to return scope data based on spec and state
 */
export type TDataSourceGetScopeTypeFunction<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase
> = (
	/** Current specification */
	opts: TSchemaConstObjectPropsSpec<TDataSourceOpts>,
	/** Current state */
	state: TDataSourceState,
	/** Opts model (available only in editor mode) */
	optsModel?: ISchemaConstObjectModel<TDataSourceOpts>
) => TTypeDesc;

/**
 * DataSource's destroy function
 *
 * Function takes current spec, current state
 */
export type TDataSourceDestroyFunction<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase
> = (
	/** Current specification */
	opts: TSchemaConstObjectPropsSpec<TDataSourceOpts>,
	/** Current state */
	state: TDataSourceState,
	/** Runtime context instance */
	rCtx: RuntimeContext
) => void;

/**
 * Elementary Data Source Definition
 */
export interface IElementaryDataSourceDefinition<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase
> extends IDataSourceInfo<TDataSourceOpts> {
	/** Resolves state */
	resolve: TDataSourceResolveFunction<TDataSourceOpts, TDataSourceState>;

	/** Returns scope data for a data source */
	getScopeData: TDataSourceGetScopeDataFunction<TDataSourceOpts, TDataSourceState>;

	/** Returns type of scope data for a data source */
	getScopeType: TDataSourceGetScopeTypeFunction<TDataSourceOpts, TDataSourceState>;

	/** Handles destroy process */
	destroy?: TDataSourceDestroyFunction<TDataSourceOpts, TDataSourceState>;
}

/**
 * Factory function to create elementary data source definition with lifecycle handling
 *
 * @param dataSourceDef Data Source definition
 * @returns
 */
export function defineElementaryDataSource<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase
>(
	dataSourceDef: IElementaryDataSourceDefinition<TDataSourceOpts, TDataSourceState>
): IDataSourceDefinition<TDataSourceOpts, TDataSourceState> {

	return {
		name: dataSourceDef.name,
		label: dataSourceDef.label,
		description: dataSourceDef.description,
		icon: dataSourceDef.icon,
		order: dataSourceDef.order,
		docUrl: dataSourceDef.docUrl,
		opts: dataSourceDef.opts,

		render: (rCtx, spec, path, scope, prevInstance) => {

			// Get prev instance
			const dfInstance = (
				prevInstance &&
				prevInstance instanceof Object &&
				prevInstance.type === dataSourceDef.name
					? prevInstance
					: {
						[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.DATASOURCE_INSTANCE,
						type: dataSourceDef.name,
						id: spec.id,
						path: path,
						opts: spec.opts,
						lastScopeDataTypeDescriptor: TypeDescAny({}),
						setState: (newState: TDataSourceState|TDataSourceUpdateStateHandlerFunction<TDataSourceState>) => {

							if (newState instanceof Function) {
								dfInstance.state = newState(dfInstance.state);
							} else {
								dfInstance.state = newState;
							}

							dfInstance.customData.stateManuallyChanged = true;
							rCtx.__invalidate(dfInstance.path, true);

						},
						customData: {
							wasModified: false,
							destroyed: false,
							stateManuallyChanged: false
						}
					}
			) as IDataSourceInstance<TDataSourceOpts, TDataSourceState>;

			// Validate ID
			if (scope.localData[SCOPE_KEY_INDEX_PROP_NAME].has(spec.id)) {
				rCtx.logRuntimeError({
					severity: DOC_ERROR_SEVERITY.WARNING,
					name: DOC_ERROR_NAME.DUPLICATE_SCOPE_ID,
					message: `Duplicate identifier '${spec.id}'.`,
					modelPath: path,
					modelNodeId: dfInstance.modelNodeId
				});

				spec.id += "_dup_" + path.join("_");
			}

			scope.localData[SCOPE_KEY_INDEX_PROP_NAME].add(spec.id);

			// Update props
			dfInstance.id = spec.id;
			dfInstance.path = path;
			dfInstance.opts = spec.opts;
			dfInstance.modelNode = spec.modelNode;

			// Assign debug info
			if (rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR) {
				dfInstance.debug = {
					scope: scope
				};
			}

			if (!spec.hasErrors && (!spec.isLoading || (spec.isLoading && spec.isLoadingWithData))) {

				const newState = dataSourceDef.resolve(spec.opts, dfInstance.state, dfInstance.setState, dfInstance, rCtx, scope);

				if (dfInstance.customData.stateManuallyChanged || !dataEqual(dfInstance.state, newState)) {
					if (DEBUG_LOG_CHANGES) { console.log("Change: State", dfInstance.path, dfInstance.state, newState); }
					dfInstance.state = newState ? newState : dfInstance.state;
					dfInstance.customData.stateManuallyChanged = false;
				}

			}


			// Update scope data
			const scopeData = dataSourceDef.getScopeData(spec.opts, dfInstance.state);
			const isScopeDataModified = !dataEqual(scope.localData[spec.id], scopeData);

			if (isScopeDataModified) {
				if (DEBUG_LOG_CHANGES) { console.log("Change: Scope data", dfInstance.path, scope.localData[spec.id], scopeData); }

				scope.localData[spec.id] = scopeData;
				scope.globalData[spec.id] = scopeData;

				if (rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR) {
					const typeDef = {
						...dataSourceDef.getScopeType(
							spec.opts,
							dfInstance.state,
							spec.modelNode.opts as unknown as ISchemaConstObjectModel<TDataSourceOpts>
						),
						label: spec.id
					} as TTypeDesc;

					scope.localType.props[spec.id] = typeDef;
					scope.globalType.props[spec.id] = typeDef;
				}

				rCtx.__invalidate(dfInstance.path, false);
			}

			// Set entity live
			rCtx.__setEntityLive(dfInstance, () => {
				dfInstance.customData.destroyed = true;

				if (dataSourceDef.destroy) {
					dataSourceDef.destroy(spec.opts, dfInstance.state, rCtx);
				}
			});

			return dfInstance;

		}
	}

}
