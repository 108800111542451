/**
 * React component props
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { COMPONENT_MODE, TComponentNodePath } from "@hexio_io/hae-lib-blueprint";
import { ClassList } from "../Classes/ClassList";

/**
 * Defaults
 */
export const FORCE_RENDER_default = false;

/**
 * Base props
 */
export interface IBaseProps {
	/** Component path */
	componentPath: TComponentNodePath;

	/** Component mode */
	componentMode: COMPONENT_MODE;

	/** Custom ClassList */
	classList?: ClassList;
}

/**
 * Render props
 */
export interface IRenderProps {
	/** Force render, defaults to "false" */
	forceRender?: boolean;
}

/**
 * Event props
 */
export interface IEventProps<TData = unknown> {
	/** On click handler */
	onClick?: ((value?: unknown, data?: TData) => Promise<void>) | ((value?: unknown, name?: TData) => void);
}

/**
 * ARIA props
 */
export interface IAriaProps extends React.AriaAttributes {}
