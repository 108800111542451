/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import Router from "@koa/router";
import { BP } from "@hexio_io/hae-lib-blueprint";
import { termsEditor } from "../terms";

/**
 * AuthProvider Options
 */
export interface IAuthProviderOptions { }

/**
 * AuthProvider Options Schema Props
 */
export const AuthProviderOptionsSchemaProps = {
	ssoProfileType: BP.Prop(BP.Const.String({
		label: termsEditor.auth.authProvider.options.ssoProfileType.label,
		description: termsEditor.auth.authProvider.options.ssoProfileType.description,
		constraints: {
			required: true
		}
	}))
}

/**
 * Auth Provider
 */
export interface IAuthProvider {

	/**
	 * Returns url
	 */
	getUrl(): string;

	/**
	 * Creates Router
	 */
	createRouter(): Router;

}
