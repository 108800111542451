/**
 * Background Attachment enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum BACKGROUND_ATTACHMENT {
	SCROLL = "scroll",
	LOCAL = "local",
	FIXED = "fixed"
}

export type BACKGROUND_ATTACHMENT_string = keyof typeof BACKGROUND_ATTACHMENT;

export const BACKGROUND_ATTACHMENT_default: BACKGROUND_ATTACHMENT_string = "SCROLL";
