/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import { TGenericBlueprintSchema } from "../Schema/IBlueprintSchema";
import { isNil, isNonEmptyObject, isNonEmptyString } from "@hexio_io/hae-lib-shared";
import {
	ISchemaThemeRef,
	ISchemaBuilderAny,
	ISchemaBuilderArray,
	ISchemaBuilderBoolean,
	ISchemaBuilderData,
	ISchemaBuilderDate,
	ISchemaBuilderFloat,
	ISchemaBuilderInteger,
	ISchemaBuilderMap,
	ISchemaBuilderObject,
	ISchemaBuilderPassword,
	ISchemaBuilderString,
	ISchemaComments,
	ISchemaComponent, ISchemaComponentList,
	ISchemaConstAny,
	ISchemaConstArray,
	ISchemaConstBoolean,
	ISchemaConstEnum,
	ISchemaConstFloat,
	ISchemaConstInteger,
	ISchemaConstMap,
	ISchemaConstObject,
	ISchemaConstPassword,
	ISchemaConstSecret,
	ISchemaConstString,
	ISchemaDataSource,
	ISchemaExpression,
	ISchemaFlowNode,
	ISchemaFlowNodeList,
	ISchemaFlowNodeTypeDefinitionMap,
	ISchemaIconName,
	ISchemaIntegrationRef,
	ISchemaOneOf,
	ISchemaOptionalGroupModel,
	ISchemaRecursive,
	ISchemaResponsiveValue,
	ISchemaRouteRef,
	ISchemaScopedTemplate,
	ISchemaStringTranslate,
	ISchemaStringWithConst,
	ISchemaValue,
	ISchemaViewRef,
	TSchemaConstEnumValueSchema,
	TSchemaConstObjectProps,
	TSchemaOneOfTypes,
	ISchemaActionRef
} from "../schemas";
import { ISchemaDataSourceList } from "../schemas/SchemaDataSourceList";

export function isSchema(value: unknown): value is TGenericBlueprintSchema {
	return isNonEmptyObject(value) && isNonEmptyString(value.name) && !isNil(value.opts);
}

export function isSchemaActionRef(value: unknown): value is ISchemaActionRef {
	return isSchema(value) && value.name === "actionRef";
}

export function isSchemaThemeRef(value: unknown): value is ISchemaThemeRef {
	return isSchema(value) && value.name === "themeRef";
}

export function isSchemaBuilderAny(value: unknown): value is ISchemaBuilderAny {
	return isSchema(value) && value.name === "builderAny";
}

export function isSchemaBuilderArray(value: unknown): value is ISchemaBuilderArray {
	return isSchema(value) && value.name === "builderArray";
}

export function isSchemaBuilderBoolean(value: unknown): value is ISchemaBuilderBoolean {
	return isSchema(value) && value.name === "builderBoolean";
}

export function isSchemaBuilderData(value: unknown): value is ISchemaBuilderData {
	return isSchema(value) && value.name === "builderData";
}

export function isSchemaBuilderDate(value: unknown): value is ISchemaBuilderDate {
	return isSchema(value) && value.name === "builderDate";
}

export function isSchemaBuilderEnum(value: unknown): value is ISchemaBuilderAny {
	return isSchema(value) && value.name === "builderEnum";
}

export function isSchemaBuilderFloat(value: unknown): value is ISchemaBuilderFloat {
	return isSchema(value) && value.name === "builderFloat";
}

export function isSchemaBuilderInteger(value: unknown): value is ISchemaBuilderInteger {
	return isSchema(value) && value.name === "builderInteger";
}

export function isSchemaBuilderMap(value: unknown): value is ISchemaBuilderMap {
	return isSchema(value) && value.name === "builderMap";
}

export function isSchemaBuilderObject(value: unknown): value is ISchemaBuilderObject {
	return isSchema(value) && value.name === "builderObject";
}

export function isSchemaBuilderPassword(value: unknown): value is ISchemaBuilderPassword {
	return isSchema(value) && value.name === "builderPassword";
}

export function isSchemaBuilderString(value: unknown): value is ISchemaBuilderString {
	return isSchema(value) && value.name === "builderString";
}

export function isSchemaComments(value: unknown): value is ISchemaComments {
	return isSchema(value) && value.name === "comments";
}

export function isSchemaComponent(value: unknown): value is ISchemaComponent {
	return isSchema(value) && value.name === "component";
}

export function isSchemaComponentList(value: unknown): value is ISchemaComponentList {
	return isSchema(value) && value.name === "componentList";
}

export function isSchemaConstAny(value: unknown): value is ISchemaConstAny {
	return isSchema(value) && value.name === "constAny";
}

export function isSchemaConstArray<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaConstArray<Schema> {
	return isSchema(value) && value.name === "constArray";
}

export function isSchemaConstBoolean(value: unknown): value is ISchemaConstBoolean {
	return isSchema(value) && value.name === "constBoolean";
}

export function isSchemaConstEnum<
	TValueSchema extends TSchemaConstEnumValueSchema = TSchemaConstEnumValueSchema
>(value: unknown): value is ISchemaConstEnum<TValueSchema> {
	return isSchema(value) && value.name === "constEnum";
}

export function isSchemaConstFloat(value: unknown): value is ISchemaConstFloat {
	return isSchema(value) && value.name === "constFloat";
}

export function isSchemaConstInteger(value: unknown): value is ISchemaConstInteger {
	return isSchema(value) && value.name === "constInteger";
}

export function isSchemaConstPassword(value: unknown): value is ISchemaConstPassword {
	return isSchema(value) && value.name === "constPassword";
}

export function isSchemaConstString(value: unknown): value is ISchemaConstString {
	return isSchema(value) && value.name === "constString";
}

export function isSchemaConstObject<SchemaProps extends TSchemaConstObjectProps = TSchemaConstObjectProps>(
	value: unknown
): value is ISchemaConstObject<SchemaProps> {
	return isSchema(value) && value.name === "constObject";
}

export function isSchemaConstMap<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaConstMap<Schema> {
	return isSchema(value) && value.name === "constMap";
}

export function isSchemaConstSecret(value: unknown): value is ISchemaConstSecret {
	return isSchema(value) && value.name === "constSecret";
}

export function isSchemaDataSource(value: unknown): value is ISchemaDataSource {
	return isSchema(value) && value.name === "dataSource";
}

export function isSchemaDataSourceList(value: unknown): value is ISchemaDataSourceList {
	return isSchema(value) && value.name === "dataSourceList";
}

export function isSchemaExpression(value: unknown): value is ISchemaExpression {
	return isSchema(value) && value.name === "expression";
}

export function isSchemaFlowNode<
	TypeDefinitionMap extends ISchemaFlowNodeTypeDefinitionMap = ISchemaFlowNodeTypeDefinitionMap
>(value: unknown): value is ISchemaFlowNode<TypeDefinitionMap> {
	return isSchema(value) && value.name === "flowNode";
}

export function isSchemaFlowNodeList<
	TypeDefinitionMap extends ISchemaFlowNodeTypeDefinitionMap = ISchemaFlowNodeTypeDefinitionMap
>(value: unknown): value is ISchemaFlowNodeList<TypeDefinitionMap> {
	return isSchema(value) && value.name === "flowNodeList";
}

export function isSchemaIconName(value: unknown): value is ISchemaIconName {
	return isSchema(value) && value.name === "iconName";
}

export function isSchemaIntegrationRef(value: unknown): value is ISchemaIntegrationRef {
	return isSchema(value) && value.name === "integrationRef";
}

export function isSchemaOneOf<Types extends TSchemaOneOfTypes = TSchemaOneOfTypes>(
	value: unknown
): value is ISchemaOneOf<Types> {
	return isSchema(value) && value.name === "oneOf";
}

export function isSchemaOptGroup<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaOptionalGroupModel<Schema> {
	return isSchema(value) && value.name === "optGroup";
}

export function isSchemaRecursive<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaRecursive<Schema> {
	return isSchema(value) && value.name === "recursive";
}

export function isSchemaResponsiveValue<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaResponsiveValue<Schema> {
	return isSchema(value) && value.name === "responsiveValue";
}

export function isSchemaRouteRef(value: unknown): value is ISchemaRouteRef {
	return isSchema(value) && value.name === "routeRef";
}

export function isSchemaScopedTemplate<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaScopedTemplate<Schema> {
	return isSchema(value) && value.name === "scopedTemplate";
}

export function isSchemaStringTranslate(value: unknown): value is ISchemaStringTranslate {
	return isSchema(value) && value.name === "stringTranslate";
}

export function isSchemaStringWithConst(value: unknown): value is ISchemaStringWithConst {
	return isSchema(value) && value.name === "stringWithConst";
}

export function isSchemaValue<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaValue<Schema> {
	return isSchema(value) && value.name === "value";
}

export function isSchemaViewRef(value: unknown): value is ISchemaViewRef {
	return isSchema(value) && value.name === "viewRef";
}
