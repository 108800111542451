/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE,
	IBlueprintIDTScalar
} from "../IDT/ISchemaIDT";
import {IBlueprintSchema, IBlueprintSchemaOpts, TBlueprintSchemaParentNode} from "../Schema/IBlueprintSchema";
import {
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
} from "../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { DesignContext } from "../Context/DesignContext";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { TypeDescAny } from "../Shared/ITypeDescriptor";
import { EXP_PARSER_MODE } from "../Expression/ExpParser";
import { compile } from "../Expression/ExpCompiler";
import { TSchemaExpressionFnResult } from "../Expression/ExpTypes";
import {
	handleExpressionRefactoring,
	ISchemaExpressionBasedModel,
	logExpressionErrorsToContext,
	parseExpression
} from "./expressionHelpers";
import { createEventEmitter, emitEvent, offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { MODEL_CHANGE_TYPE } from "../Schema/IModelNode";

/**
 * Schema model
 */
export interface ISchemaExpressionModel extends ISchemaExpressionBasedModel<ISchemaExpression> {
	parserMode: EXP_PARSER_MODE;
}

/**
 * Schema options
 */
export interface ISchemaExpressionOpts extends IBlueprintSchemaOpts {
	/** Default expression value */
	default?: string;
}

/**
 * Schema default
 */
export type TSchemaExpressionDefault = string;

/**
 * Schema create opts
 */
export interface ISchemaExpressionCreateOpts {
	/** Parser mode */
	mode?: EXP_PARSER_MODE;
}

/**
 * Schema type
 */
export interface ISchemaExpression extends IBlueprintSchema<
	ISchemaExpressionOpts,
	ISchemaExpressionModel,
	unknown,
	TSchemaExpressionDefault,
	ISchemaExpressionCreateOpts
> {
	/**
	 * Sets a new expression value
	 *
	 * @param modelNode Model Node
	 * @param value New value
	 * @param notify If to notify about change (emit events)
	 */
	setValue: (modelNode: ISchemaExpressionModel, value: string, notify?: boolean) => void;
}

/**
 * Schema: Variable reference
 *
 * @param opts Schema options
 */
export function SchemaExpression(opts: ISchemaExpressionOpts): ISchemaExpression {

	const schema = createEmptySchema<ISchemaExpression>("expression", opts);

	const createModel = (
		dCtx: DesignContext,
		parserMode: EXP_PARSER_MODE,
		source: string,
		parent: TBlueprintSchemaParentNode
	) => {

		const model = createModelNode(schema, dCtx, parent, [], {
			parserMode: parserMode,
			value: source,
			expAst: null,
			expParseErrors: [],
			expParseTrace: null,
			expCompiledCode: null,
			expCompiledFn: null,
			expLastValue: null,
			expLastValueChangeEvent: createEventEmitter(),
			__refactoringHandler: null
		});

		parseExpression(model, parserMode);

		model.__refactoringHandler = (event) => {
			if (handleExpressionRefactoring(model, event)) {
				parseExpression(model, parserMode);
				handleModelNodeChange(model, MODEL_CHANGE_TYPE.VALUE);
			}
		};

		onEvent(dCtx.identifierRenameEvent, model.__refactoringHandler);

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue, createOpts) => {

		const value = defaultValue !== null && defaultValue !== undefined ? defaultValue : opts.default !== undefined ? opts.default : null;
		return createModel(dCtx, createOpts?.mode || EXP_PARSER_MODE.DEFAULT, value, parent);

	};

	schema.parse = (dCtx, idtNode, parent, createOpts) => {

		if (!idtNode) {
			return schema.createDefault(dCtx, parent);
		}

		if (idtNode.type !== BP_IDT_TYPE.SCALAR || (
			idtNode.type == BP_IDT_TYPE.SCALAR &&
			idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.STRING &&
			idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.NULL
		)) {
			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.STR_NOT_STRING,
					message: "Expecting a string",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		// Create model
		const model = createModel(dCtx, createOpts?.mode || EXP_PARSER_MODE.DEFAULT, idtNode.value as string, parent);
		logExpressionErrorsToContext(model, idtNode);

		return model;

	};

	schema.serialize = (modelNode, path) => {

		if (modelNode.value !== null) {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.STRING,
				path: path,
				value: modelNode.value
			} as IBlueprintIDTScalar;

		} else {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.NULL,
				path: path,
				value: null
			} as IBlueprintIDTScalar;

		}

	};

	schema.clone = (dCtx, modelNode, parent) => {

		return cloneModelNode(dCtx, modelNode, parent,{
			parserMode: modelNode.parserMode,
			value: modelNode.value,
			expAst: modelNode.expAst,
			expParseErrors: modelNode.expParseErrors.slice(),
			expParseTrace: modelNode.expParseTrace ? modelNode.expParseTrace.slice() : null,
			expCompiledCode: modelNode.expCompiledCode,
			expLastValueChangeEvent: createEventEmitter(),
			expCompiledFn: modelNode.expCompiledFn
		});

	};

	schema.destroy = (modelNode) => {

		offEvent(modelNode.ctx.identifierRenameEvent, modelNode.__refactoringHandler);

		modelNode.expAst = null;
		modelNode.expParseErrors = null;
		modelNode.expParseTrace = null;
		modelNode.expCompiledCode = null;
		modelNode.expCompiledFn = null;
		modelNode.expLastValue = null;
		modelNode.__refactoringHandler = null;

		destroyModelNode(modelNode);

	};

	schema.render = (rCtx, modelNode, path, scope) => {

		modelNode.lastScopeFromRender = scope;

		let value: TSchemaExpressionFnResult;

		if (modelNode.expCompiledFn) {
			value = modelNode.expCompiledFn(rCtx, scope, path);
		} else {
			value = null;
		}

		if (value !== modelNode.expLastValue) {
			modelNode.expLastValue = value;
			emitEvent(modelNode.expLastValueChangeEvent);
		}

		return value ? value.result : null;

	};

	schema.compileRender = (cCtx, modelNode) => {

		let code: string = null;

		if (modelNode.expAst) {
			const compiledRes = compile(cCtx, modelNode.nodeId, modelNode.expAst, modelNode.value, false);
			code = compiledRes.code;
		}

		return {
			isScoped: true,
			code: `(s,pv,pt)=>${code ? `(${code})(s,pt).result` : "null"}`
		}

	};

	schema.validate = (rCtx, path, modelNodeId) => {
		return true;
	};

	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return `(v,pt)=>true`;
	};

	schema.setValue = (modelNode: ISchemaExpressionModel, value: string, notify?: boolean) => {

		modelNode.value = value;
		parseExpression(modelNode, modelNode.parserMode);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}

	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaExpression", [opts]);
	};

	schema.getTypeDescriptor = () => {
		return TypeDescAny({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = () => {
		return [];
	}

	return schema;

}
