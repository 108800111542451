/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_TYPE,
	IBlueprintIDTList,
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts, TBlueprintSchemaParentNode
} from "../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../Schema/IModelNode";
import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import {
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateAsNotSupported,
} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { applyCodeArg } from "../Context/CompileUtil";
import { TypeDescArray } from "../Shared/ITypeDescriptor";
import { ModelNodeManipulationError } from "../Schema/ModelNodeManipulationError";
import { IDataSourceResolver } from "../Resolvers";
import { CMPL_ITEM_KIND } from "../Shared/ICompletionItem";
import { TGenericDataSourceInstance } from "../DataSource/IDataSourceInstance";
import { ISchemaDataSource, ISchemaDataSourceDefault, ISchemaDataSourceModel, SchemaDataSource } from "./SchemaDataSource";
import { TGenericDataSourceDefinitionMap } from "../DataSource/IDataSourceDefinition";
import { validateIDTNode } from "../Context/ParseUtil";

/**
 * Schema model
 */
export interface ISchemaDataSourceListModel extends IModelNode<ISchemaDataSourceList> {
	items: ISchemaDataSourceModel[];
}

/**
 * Schema spec
 */
export interface ISchemaDataSourceListSpec extends Array<TGenericDataSourceInstance> {}

/**
 * Schema defaults
 */
export type TSchemaDataSourceListDefault = Array<ISchemaDataSourceDefault>;

/**
 * Schema options
 */
export interface ISchemaDataSourceListOpts extends IBlueprintSchemaOpts {}

/**
 * Schema type
 */
export interface ISchemaDataSourceList extends IBlueprintSchema<
	ISchemaDataSourceListOpts,
	ISchemaDataSourceListModel,
	ISchemaDataSourceListSpec,
	TSchemaDataSourceListDefault
> {
	/** Internally used data source schema */
	dataSourceSchema: ISchemaDataSource;

	/**
	 * Creates and adds a data source to the list
	 */
	addItem: (
		modelNode: ISchemaDataSourceListModel, index: number, itemDefault?: ISchemaDataSourceDefault, notify?: boolean
	) => ISchemaDataSourceModel;

	/**
	 * Adds existing data source to the list
	 */
	addItemModel: (
		modelNode: ISchemaDataSourceListModel, index: number, itemNode: ISchemaDataSourceModel, notify?: boolean
	) => void;

	/**
	 * Remove item from the list
	 */
	removeItem: (modelNode: ISchemaDataSourceListModel, index: number, notify?: boolean) => ISchemaDataSourceModel;

	/**
	 * Removes item form the list by model reference
	 */
	removeItemByModel: (modelNode: ISchemaDataSourceListModel, itemNode: ISchemaDataSourceModel, notify?: boolean) => void;

	/**
	 * Remove all items from the list
	 */
	removeAllItems: (modelNode: ISchemaDataSourceListModel, notify?: boolean) => ISchemaDataSourceModel[];

	/**
	 * Return a map of available data source types
	 */
	getDataSourceTypeList: (modelNode: ISchemaDataSourceListModel) => TGenericDataSourceDefinitionMap;
}

/**
 * Schema: Data Source list
 *
 * @param opts Schema options
 */
export function SchemaDataSourceList(opts: ISchemaDataSourceListOpts): ISchemaDataSourceList {

	const schemaDataSource = SchemaDataSource({});

	const schema = createEmptySchema<ISchemaDataSourceList>("dataSourceList", opts);
	schema.dataSourceSchema = schemaDataSource;

	const createModel = (
		dCtx: DesignContext,
		parent: TBlueprintSchemaParentNode
	) => {

		const model = createModelNode(schema, dCtx, parent, [], {
			items: []
		});

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		if (defaultValue && !Array.isArray(defaultValue)) {
			throw new SchemaDeclarationError(schema.name, schema.opts, "Default value must be an array.");
		}

		// Create model
		const model = createModel(dCtx, parent);

		if (Array.isArray(defaultValue)) {
			for (let i = 0; i < defaultValue.length; i++) {
				model.items.push(schemaDataSource.createDefault(dCtx, model, defaultValue[i], {
					dataSourceListModel: model as ISchemaDataSourceListModel
				}));
			}
		}

		return model;

	};

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedModel = cloneModelNode(dCtx, modelNode, parent,{
			items: null
		});

		const clonedItems = modelNode.items.map((cmp) => {
			const itemModel = cmp.schema.clone(dCtx, cmp, clonedModel);

			itemModel.schema.assignToDataSourceList(itemModel, clonedModel, false);

			return itemModel;
		});

		clonedModel.items = clonedItems;

		return clonedModel;

	};

	schema.destroy = (modelNode) => {

		modelNode.items.forEach((item) => item.schema.destroy(item));
		destroyModelNode(modelNode);

	};

	schema.parse = (dCtx, idtNode, parent) => {

		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: false,
			idtType: BP_IDT_TYPE.LIST
		});

		if (!isRootNodeValid || !rootNode) {
			return schema.createDefault(dCtx, parent);
		}

		// Create model
		const model = createModel(dCtx, parent);

		// Parse items
		const items = rootNode.items.map((item) => {

			if (idtNode.parseInfo) {
				schemaDataSource.provideCompletion(
					dCtx,
					idtNode.parseInfo.loc,
					idtNode.parseInfo.loc.range.start.col + 2,
					item
				);
			}

			if (!item) {
				return null;
			}

			const itemNode = schemaDataSource.parse(dCtx, item, model, {
				dataSourceListModel: model as ISchemaDataSourceListModel
			});

			if (!itemNode) {
				return null;
			}

			return itemNode;

		}).filter((item) => item !== null);

		model.items = items;

		return model;

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => [{
			kind: CMPL_ITEM_KIND.Snippet,
			label: "dataSource",
			insertText: `- id: ${dCtx.getUniqueIdentifier("newDataSource", true)}\n  type:`
		}]);

	};

	schema.serialize = (modelNode, path) => {

		return {
			type: BP_IDT_TYPE.LIST,
			path,
			items: modelNode.items.map((item, index) => schemaDataSource.serialize(item, path.concat([`[${index}]`])))
		} as IBlueprintIDTList;

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		modelNode.lastScopeFromRender = scope;

		const value = modelNode.items.map(
			(item, index) => schemaDataSource.render(
				rCtx, item, path.concat([String(index)]), scope, prevSpec && Array.isArray(prevSpec) ? prevSpec[index] : undefined
			)
		);

		return value;

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		let isScoped = false;
		let compiledItems = [];

		compiledItems = modelNode.items.map((item, index) => {

			const compiledElement = schemaDataSource.compileRender(cCtx, item, path.concat([index]));
			isScoped = isScoped || compiledElement.isScoped;

			return applyCodeArg(compiledElement, `pv?pv[${index}]:undefined`, `pt.concat(["${index}"])`);

		});

		const code = compiledItems.join(",");

		return {
			isScoped,
			code: isScoped ? `(s,pv,pt)=>([${code}])` : `[${code}]`
		};

	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {

		return exportSchema("SchemaDataSourceList", [opts]);

	};

	schema.getTypeDescriptor = (modelNode) => {

		return TypeDescArray({
			label: opts.label,
			description: opts.description,
			items: modelNode.items.map((item) => item.schema.getTypeDescriptor(item)),
			example: opts.example,
			tags: opts.tags
		});

	};

	schema.addItem = (modelNode: ISchemaDataSourceListModel, index: number, itemDefault?: ISchemaDataSourceDefault, notify?: boolean) => {

		const item = schemaDataSource.createDefault(modelNode.ctx, modelNode, itemDefault, {
			dataSourceListModel: modelNode
		});

		modelNode.items.splice(index, 0, item);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return item;

	};

	schema.addItemModel = (modelNode: ISchemaDataSourceListModel, index: number, itemNode: ISchemaDataSourceModel, notify?: boolean) => {

		itemNode.schema.assignToDataSourceList(itemNode, modelNode, notify);
		modelNode.items.splice(index, 0, itemNode);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	};

	schema.removeItem = (modelNode: ISchemaDataSourceListModel, index: number, notify?: boolean) => {

		const item = modelNode.items.splice(index, 1);

		if (!item[0]) {
			throw new ModelNodeManipulationError(
				schema.name, schema.opts, `Cannot remove item on index '${index}' because it doesn't exists.`
			);
		}

		item[0].schema.unassignFromDataSourceList(item[0], notify);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return item[0];

	};

	schema.removeAllItems = (modelNode: ISchemaDataSourceListModel, notify?: boolean) => {

		const items = modelNode.items.splice(0, modelNode.items.length);

		for (let i = 0; i < items.length; i++) {
			items[i].schema.unassignFromDataSourceList(items[i], notify);
		}

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return items;

	};

	schema.removeItemByModel = (modelNode: ISchemaDataSourceListModel, itemNode: ISchemaDataSourceModel, notify?: boolean) => {

		const i = modelNode.items.indexOf(itemNode);

		if (i < 0) {
			throw new ModelNodeManipulationError(
				schema.name, schema.opts, `Cannot remove item with nodeId '${itemNode.nodeId}' because it doesn't exists in this list.`
			);
		}

		modelNode.items.splice(i, 1);
		itemNode.schema.unassignFromDataSourceList(itemNode, notify);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	};

	schema.getDataSourceTypeList = (modelNode: ISchemaDataSourceListModel) => {

		return modelNode.ctx.getResolver<IDataSourceResolver>("dataSource").getList()

	};

	schema.getChildNodes = (modelNode) => {
		return modelNode.items.map((item, index) => ({
			key: "items." + index,
			node: item
		}));
	}

	return schema;

}
