/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	DesignContext,
	DOC_ERROR_NAME,
	DOC_ERROR_SEVERITY,
	IRuntimeError,
	RuntimeContext,
	TGetBlueprintSchemaModel
} from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../../app";
import { BlueprintManifest, DOC_TYPES } from "../../blueprints";
import { CONST } from "../../constants";
import { ILogger } from "../../logger";
import { RESOURCE_PERMISSIONS } from "../../registries";
import { RESOURCE_TYPES } from "../IResource";
import { RESOURCE_ON_EVENT_TYPE } from "../IResourceManager";
import { RESOURCE_ERROR_NAMES } from "../ResourceErrorNames";
import { IManifestResourceProps, IManifestResourceType } from "./IManifestResource";

type TManifestSchemaModel = TGetBlueprintSchemaModel<typeof BlueprintManifest>;

export class ManifestResourceV1 implements IManifestResourceType {

	public get permissions(): RESOURCE_PERMISSIONS[] {
		return [RESOURCE_PERMISSIONS.READ, RESOURCE_PERMISSIONS.UPDATE];
	}

	public get name(): string {
		return DOC_TYPES.MANIFEST_V1;
	}

	public get category(): string {
		return RESOURCE_TYPES.MANIFEST;
	}

	public get statsName(): string {
		return "manifests";
	}

	protected logger: ILogger;

	public constructor(protected app: IAppServer) {
		this.logger = this.app.get("logger").facility("resource-manifest-v1");
	}

	public setup(resource: IManifestResourceProps): IManifestResourceProps {

		resource.resourceType = RESOURCE_TYPES.MANIFEST;
		resource.parsingDetails.isRegistered = true;

		return resource;

	}

	public async scan(resource: IManifestResourceProps): Promise<IManifestResourceProps> {
		return resource;
	}

	public async parse(resource: IManifestResourceProps): Promise<IManifestResourceProps> {

		resource.parsingDetails.isValidDefaultProfile = false;

		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Parse manifest '${uri}'.`);

		let dCtx: DesignContext;
		let model: TManifestSchemaModel;

		try {

			dCtx = resourceManager.createDCtx(false);

			resource = await resourceManager.parseModel<IManifestResourceProps>(resource, dCtx, BlueprintManifest);
			model = resource.parsedData.model as TManifestSchemaModel;

			if (!model) {
				this.logger.warn("Can't parse manifest model.");
				this.logger.debug({ uri });
				return resource;
			}

			resource = await resourceManager.renderSpec(resource);

			const spec = resource.parsedData.spec;

			if (!spec) {
				this.logger.warn("Can't parse manifest model.");
				this.logger.debug({ uri });
				return resource;
			}

			// Check if manifest has fallback value that is required
			let fallbackOpts;
			if (spec?.spec) {
				fallbackOpts = spec?.spec?.[CONST.MANIFEST.FALLBACK_APP_ENV_ID];
			}

			if (!fallbackOpts) {

				resource.reportRuntimeErrors({
					getRuntimeErrors: () => {
						return [{
							severity: DOC_ERROR_SEVERITY.ERROR,
							name: RESOURCE_ERROR_NAMES.RESOURCE_INVALID_CONFIG,
							message: "Manifest expected to have an 'default' configuration.",
							details: [`Manifest Id: '${spec.id}'`],
							modelPath: ["$"],
							modelNodeId: model.nodeId
						} as IRuntimeError];
					}
				} as RuntimeContext);

				return resource;

			}

			resource.parsingDetails.isValidDefaultProfile = true;
			return resource;

		} catch (error) {

			this.logger.debug({ error: error?.message });
			resourceManager.reportError(`Can't parse manifest '${uri}'.`, error);
			return resource;

		} finally {

			if (model) {
				model.schema.destroy(model);
			}
			if (dCtx) {
				dCtx.destroy();
			}

		}

	}

	public async after(resource: IManifestResourceProps): Promise<IManifestResourceProps> {

		const { uri } = resource;
		this.logger.debug(`After hook manifest '${uri}'`);

		const parseErrors = [];

		/** 
		 * Remove invalid actionRef errors from manifest errors.
		 */
		for (const error of resource.errors?.parseErrors || []) {
			if (error.name !== DOC_ERROR_NAME.INVALID_REF) {
				parseErrors.push(error);
			}
		}

		resource.errors.parseErrors = parseErrors;
		return resource;

	}

	public getDependenciesToReload(resource: IManifestResourceProps, eventType?: RESOURCE_ON_EVENT_TYPE): string[] {
		return [];
	}

}