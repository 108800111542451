/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstIntegerOpts, SchemaConstInteger, ISchemaConstInteger } from "../const/SchemaConstInteger";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueIntegerOpts extends ISchemaValueOpts<ISchemaConstInteger>, ISchemaConstIntegerOpts {}

/**
 * Schema: Integer value
 *
 * @param opts Schema options
 */
export function SchemaValueInteger(opts: ISchemaValueIntegerOpts): ISchemaValue<ISchemaConstInteger> {

	return SchemaValue(SchemaConstInteger(opts), opts);

}
