/**
 * Data Table HAE component state
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstObject, TGetBlueprintSchemaSpec } from "@hexio_io/hae-lib-blueprint";
import { TTableItemIds } from "../Table/types";
import { HAEComponentDataTable_Props } from "./props";

export type TResolvedItemValue = string | number | boolean;

export type TResolvedItemData = {
	[K: string]: {
		value: TResolvedItemValue;
		formattedValue?: string;
	};
};

export enum DATA_TABLE_RESOLVED_ITEM_STATE {
	UNEDITED = "unedited",
	EDITED_BY_USER = "edited-by-user",
	EDITED_BY_COMPONENT = "edited-by-component"
}

export interface IResolvedItem {
	id: string;
	data: TResolvedItemData;
	originalData: unknown;
	state: DATA_TABLE_RESOLVED_ITEM_STATE;
}

export interface IPagination {
	limit: number;
	page: number;
	maxDisplayedPages: number;
	typeData: TGetBlueprintSchemaSpec<ISchemaConstObject<typeof HAEComponentDataTable_Props>>["pagination"]["typeData"];
}

export interface ISort {
	key?: string;
	order: string;
	client: boolean;
}

export interface HAEComponentDataTable_State {
	items: unknown[];

	resolvedItems: IResolvedItem[];

	totalItems: number;

	order: TTableItemIds;

	pageOrder: TTableItemIds;

	pagination?: IPagination;

	specPagination?: IPagination;

	sort?: ISort;

	specSort?: ISort;

	setPage?: (page: number) => void;
}
