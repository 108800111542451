/**
 * Property Item Style enum
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum PROPERTY_ITEM_STYLE {
	SIMPLE = "simple",
	CHIP = "chip"
}

export const PROPERTY_ITEM_STYLE_default: keyof typeof PROPERTY_ITEM_STYLE = "SIMPLE";
