/**
 * Icon Name enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum ICON_NAME {
	ARROW_DOWN = "mdi/arrow-down",
	ARROW_DOWN_COLLAPSE = "mdi/arrow-collapse-down",
	ARROW_LEFT = "mdi/arrow-left",
	ARROW_RIGHT = "mdi/arrow-right",
	ARROW_UP = "mdi/arrow-up",
	ARROW_UP_DOWN = "mdi/arrow-up-down",
	ARROW_UP_COLLAPSE = "mdi/arrow-collapse-up",
	CALENDAR = "mdi/calendar",
	CANCEL = "mdi/cancel",
	CHECK = "mdi/check",
	CHEVRON_LEFT = "mdi/chevron-left",
	CHEVRON_RIGHT = "mdi/chevron-right",
	CIRCLE = "mdi/circle-outline",
	CIRCLE_2 = "mdi/circle",
	CLIPBOARD_TO = "mdi/clipboard-arrow-right-outline",
	CLOCK = "mdi/clock-outline",
	CLOSE = "mdi/close",
	COPY = "mdi/content-copy",
	DELETE = "mdi/delete",
	DOTS_HORIZONTAL = "mdi/dots-horizontal",
	DOTS_VERTICAL = "mdi/dots-vertical",
	EMAIL = "mdi/at",
	ERROR = "mdi/alert-circle-outline",
	ERROR_2 = "mdi/alert-circle",
	EXCLAMATION = "mdi/exclamation",
	EYE = "mdi/eye",
	EYE_OFF = "mdi/eye-off",
	FILE = "mdi/file-outline",
	FILE_CODE = "mdi/file-code-outline",
	FILE_DOCUMENT = "mdi/file-document-outline",
	FILE_EXPORT = "mdi/file-export-outline",
	FILE_IMAGE = "mdi/file-image-outline",
	FILE_MUSIC = "mdi/file-music-outline",
	FILE_TABLE = "mdi/file-table-outline",
	FILE_VIDEO = "mdi/file-video-outline",
	FOLDER_ZIP = "mdi/folder-zip-outline",
	INFO = "mdi/information-outline",
	INFO_2 = "mdi/information",
	MENU = "mdi/menu",
	MENU_DOWN = "mdi/menu-down",
	MENU_RIGHT = "mdi/menu-right",
	MINUS = "mdi/minus",
	OK = "mdi/check-circle-outline",
	OK_2 = "mdi/check-circle",
	PLUS = "mdi/plus",
	POWER = "mdi/power",
	RELOAD = "mdi/reload",
	RESTART = "mdi/restart",
	SUCCESS = OK,
	SUCCESS_2 = OK_2,
	UPLOAD = "mdi/upload",
	WARNING = "mdi/alert-outline",
	WARNING_2 = "mdi/alert"
}

export type ICON_NAME_string = keyof typeof ICON_NAME;
