/**
 * Popup Position enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { HORIZONTAL_ALIGN_string } from "./HORIZONTAL_ALIGN";

export enum POPUP_POSITION {
	TOP = "top",
	TOP_LEFT = "top-left",
	TOP_CENTER = "top-center",
	TOP_RIGHT = "top-right",
	BOTTOM = "bottom",
	BOTTOM_LEFT = "bottom-left",
	BOTTOM_CENTER = "bottom-center",
	BOTTOM_RIGHT = "bottom-right"
}

export type POPUP_POSITION_string = keyof typeof POPUP_POSITION;

export const POPUP_POSITION_default = "BOTTOM";

export const HORIZONTAL_ALIGN_TO_POPUP_POSITION: Record<"TOP"|"BOTTOM", Record<HORIZONTAL_ALIGN_string, POPUP_POSITION>> = {
	TOP: {
		LEFT: POPUP_POSITION.TOP_LEFT,
		CENTER: POPUP_POSITION.TOP_CENTER,
		RIGHT: POPUP_POSITION.TOP_RIGHT,
		STRETCH: POPUP_POSITION.TOP
	},
	BOTTOM: {
		LEFT: POPUP_POSITION.BOTTOM_LEFT,
		CENTER: POPUP_POSITION.BOTTOM_CENTER,
		RIGHT: POPUP_POSITION.BOTTOM_RIGHT,
		STRETCH: POPUP_POSITION.BOTTOM
	}
};
