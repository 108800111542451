/**
 * Custom Chart HAE component default callback
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Default setup callback
 * PLAIN JAVASCRIPT ONLY!
 */
export const CUSTOM_CHART_DEFAULT_SETUP = `// Example setup callback (XY Chart)

// Available
// console.log(am5, am5flow, am5hierarchy, am5map, am5radar, am5venn, am5wc);
// console.log(root, cleanUpQueue, color, style);
// console.log(data);

// Chart
const chart = root.container.children.push(am5xy.XYChart.new(root, {
	panX: false,
	panY: false
}));

// Cursor
const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
cursor.lineY.set("visible", false);

// X-axis
const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
	maxDeviation: 0.3,
	categoryField: "name",
	renderer: am5xy.AxisRendererX.new(root, {})
}));

// Y-axis
const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
	maxDeviation: 0.3,
	renderer: am5xy.AxisRendererY.new(root, {})
}));

// Series
const series = chart.series.push(am5xy.LineSeries.new(root, {
	name: "Series",
	stroke: color.primary,
	xAxis: xAxis,
	yAxis: yAxis,
	valueYField: "value",
	sequencedInterpolation: true,
	categoryXField: "name"
}));

series.strokes.template.setAll({
	strokeWidth: 2
});

// Series' tooltip
const tooltip = series.set("tooltip", am5.Tooltip.new(root, {
	autoTextColor: false,
	getFillFromSprite: false,
	getStrokeFromSprite: false,
	pointerOrientation: "horizontal",
	labelText: "{valueY}"
}));

tooltip.get("background").setAll({
	fill: color.background,
	stroke: color.base,
	strokeWidth: 1
});

tooltip.label.setAll({
	fill: color.base
});

cleanUpQueue.unshift(() => series.remove("tooltip"));

// Everything returned will be passed to Update callback as "setupData"
return { chart, xAxis, series };`;

/**
 * Default update callback
 * PLAIN JAVASCRIPT ONLY!
 */
export const CUSTOM_CHART_DEFAULT_UPDATE = `// Example update callback

// Available
// console.log(am5, am5flow, am5hierarchy, am5map, am5radar, am5venn, am5wc);
// console.log(root, color, style);
// console.log(data)
// console.log(setupData);

if (setupData && data) {
	setupData.xAxis?.data.setAll(data);
	setupData.series?.data.setAll(data);
}`;

/**
 * Default data
 */
export const CUSTOM_CHART_DEFAULT_DATA = JSON.stringify([{
	name: "Lorem",
	value: 711
}, {
	name: "Ipsum",
	value: 443
}, {
	name: "Dolor",
	value: 625
}]);
