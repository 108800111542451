/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { SchemaConstArray } from "./const/SchemaConstArray";
import { SchemaConstBoolean } from "./const/SchemaConstBoolean";
import { SchemaConstDate } from "./const/SchemaConstDate";
import { SchemaConstData } from "./const/SchemaConstData";
import { SchemaConstEnum } from "./const/SchemaConstEnum";
import { SchemaConstFloat } from "./const/SchemaConstFloat";
import { SchemaConstInteger } from "./const/SchemaConstInteger";
import { SchemaConstMap } from "./const/SchemaConstMap";
import { Prop, SchemaConstObject } from "./const/SchemaConstObject";
import { SchemaConstString } from "./const/SchemaConstString";
import { SchemaConstAny } from "./const/SchemaConstAny";
import { SchemaConstSecret } from "./const/SchemaConstSecret";

import { SchemaValue } from "./value/SchemaValue";
import { SchemaValueArray } from "./value/SchemaValueArray";
import { SchemaValueBoolean } from "./value/SchemaValueBoolean";
import { SchemaValueDate } from "./value/SchemaValueDate";
import { SchemaValueData } from "./value/SchemaValueData";
import { SchemaValueEnumFloat, SchemaValueEnumInteger, SchemaValueEnumString } from "./value/SchemaValueEnum";
import { SchemaValueFloat } from "./value/SchemaValueFloat";
import { SchemaValueInteger } from "./value/SchemaValueInteger";
import { SchemaValueMap } from "./value/SchemaValueMap";
import { SchemaValueObject } from "./value/SchemaValueObject";
import { SchemaValueString } from "./value/SchemaValueString";
import { SchemaValueAny } from "./value/SchemaValueAny";

import { SchemaBuilderAny } from "./builder/SchemaBuilderAny";
import { SchemaBuilderArray } from "./builder/SchemaBuilderArray";
import { SchemaBuilderBoolean } from "./builder/SchemaBuilderBoolean";
import { SchemaBuilderData } from "./builder/SchemaBuilderData";
import { SchemaBuilderDate } from "./builder/SchemaBuilderDate";
import { SchemaBuilderEnum } from "./builder/SchemaBuilderEnum";
import { SchemaBuilderFloat } from "./builder/SchemaBuilderFloat";
import { SchemaBuilderInteger } from "./builder/SchemaBuilderInteger";
import { SchemaBuilderMap } from "./builder/SchemaBuilderMap";
import { SchemaBuilderObject } from "./builder/SchemaBuilderObject";
import { SchemaBuilderString } from "./builder/SchemaBuilderString";

import { SchemaComments } from "./SchemaComments";
import { SchemaComponent } from "./SchemaComponent";
import { SchemaComponentList } from "./SchemaComponentList";
import { SchemaDataSource } from "./SchemaDataSource";
import { SchemaDataSourceList } from "./SchemaDataSourceList";
import { SchemaExpression } from "./SchemaExpression";
import { SchemaOptionalGroup } from "./SchemaOptionalGroup";
import { SchemaOverride } from "./SchemaOverride";
import { SchemaStringTranslate } from "./SchemaStringTranslate";
import { SchemaOneOf } from "./SchemaOneOf";
import { SchemaResponsiveValue } from "./SchemaResponsiveValue";
import { SchemaNamedConfig } from "./SchemaNamedConfig";
import { SchemaIconName } from "./SchemaIconName";
import { SchemaActionRef } from "./SchemaActionRef";
import { SchemaThemeRef } from "./SchemaThemeRef";
import { SchemaViewRef } from "./SchemaViewRef";
import { SchemaIntegrationRef } from "./SchemaIntegrationRef";
import { SchemaRouteRef } from "./SchemaRouteRef";
import { SchemaConditional } from "./SchemaConditional";
import { SchemaScopedTemplate } from "./SchemaScopedTemplate";
import { SchemaStringWithConst } from "./SchemaStringWithConst";
import { SchemaFlowNode } from "./SchemaFlowNode";
import { SchemaFlowNodeList } from "./SchemaFlowNodeList";
import { SchemaRuntimeWrapper } from "./SchemaRuntimeWrapper";
import { SchemaRecursive } from "./SchemaRecursive";
import { SchemaVoid } from "./SchemaVoid";
import { SchemaValueSecret } from "./value/SchemaValueSecret";
import { SchemaConstPassword } from "./const/SchemaConstPassword";
import { SchemaValuePassword } from "./value/SchemaValuePassword";

export const BP = {
	// Constant values
	Const: {
		Array: SchemaConstArray,
		Boolean: SchemaConstBoolean,
		Data: SchemaConstData,
		Date: SchemaConstDate,
		Enum: SchemaConstEnum,
		Float: SchemaConstFloat,
		Integer: SchemaConstInteger,
		Map: SchemaConstMap,
		Object: SchemaConstObject,
		Password: SchemaConstPassword,
		String: SchemaConstString,
		Any: SchemaConstAny,
		Secret: SchemaConstSecret
	},

	// Internals
	Internal: {
		Value: SchemaValue,
		StringTranslate: SchemaStringTranslate,
		Override: SchemaOverride
	},

	// Builder
	Builder: {
		Any: SchemaBuilderAny,
		Array: SchemaBuilderArray,
		Boolean: SchemaBuilderBoolean,
		Data: SchemaBuilderData,
		Date: SchemaBuilderDate,
		Enum: SchemaBuilderEnum,
		Float: SchemaBuilderFloat,
		Integer: SchemaBuilderInteger,
		Map: SchemaBuilderMap,
		Object: SchemaBuilderObject,
		String: SchemaBuilderString
	},

	// Special high order schemas
	Special: {
		Comments: SchemaComments,
		IntegrationRef: SchemaIntegrationRef,
		FlowNode: SchemaFlowNode,
		FlowNodeList: SchemaFlowNodeList,
		RWP: SchemaRuntimeWrapper
	},

	// Basic values
	Array: SchemaValueArray,
	Boolean: SchemaValueBoolean,
	Date: SchemaValueDate,
	Data: SchemaValueData,
	Enum: {
		Integer: SchemaValueEnumInteger,
		Float: SchemaValueEnumFloat,
		String: SchemaValueEnumString
	},
	Float: SchemaValueFloat,
	Integer: SchemaValueInteger,
	Map: SchemaValueMap,
	Object: SchemaValueObject,
	Password: SchemaValuePassword,
	Prop: Prop,
	Secret: SchemaValueSecret,
	String: SchemaValueString,
	Any: SchemaValueAny,

	// High-order schemas
	Component: SchemaComponent,
	ComponentList: SchemaComponentList,
	DataSource: SchemaDataSource,
	DataSourceList: SchemaDataSourceList,
	Expression: SchemaExpression,
	OptGroup: SchemaOptionalGroup,
	OneOf: SchemaOneOf,
	ResponsiveValue: SchemaResponsiveValue,
	NamedConfig: SchemaNamedConfig,
	IconName: SchemaIconName,
	ActionRef: SchemaActionRef,
	ViewRef: SchemaViewRef,
	RouteRef: SchemaRouteRef,
	ThemeRef: SchemaThemeRef,
	Conditional: SchemaConditional,
	ScopedTemplate: SchemaScopedTemplate,
	StringWithConst: SchemaStringWithConst,
	Recursive: SchemaRecursive,
	Void: SchemaVoid
};

export * from "./const/SchemaConstAny";
export * from "./const/SchemaConstArray";
export * from "./const/SchemaConstBoolean";
export * from "./const/SchemaConstDate";
export * from "./const/SchemaConstEnum";
export * from "./const/SchemaConstFloat";
export * from "./const/SchemaConstInteger";
export * from "./const/SchemaConstMap";
export * from "./const/SchemaConstObject";
export * from "./const/SchemaConstPassword";
export * from "./const/SchemaConstSecret";
export * from "./const/SchemaConstString";

export * from "./value/SchemaValue";
export * from "./value/SchemaValueArray";
export * from "./value/SchemaValueBoolean";
export * from "./value/SchemaValueDate";
export * from "./value/SchemaValueEnum";
export * from "./value/SchemaValueFloat";
export * from "./value/SchemaValueInteger";
export * from "./value/SchemaValueMap";
export * from "./value/SchemaValueObject";
export * from "./value/SchemaValuePassword";
export * from "./value/SchemaValueString";
export * from "./value/SchemaValueAny";

export * from "./builder/SchemaBuilderAny";
export * from "./builder/SchemaBuilderArray";
export * from "./builder/SchemaBuilderBoolean";
export * from "./builder/SchemaBuilderData";
export * from "./builder/SchemaBuilderDate";
export * from "./builder/SchemaBuilderEnum";
export * from "./builder/SchemaBuilderFloat";
export * from "./builder/SchemaBuilderInteger";
export * from "./builder/SchemaBuilderMap";
export * from "./builder/SchemaBuilderObject";
export * from "./builder/SchemaBuilderPassword";

export * from "./builder/SchemaBuilderString";

export * from "./SchemaActionRef";
export * from "./SchemaThemeRef";
export * from "./SchemaComments";
export * from "./SchemaComponent";
export * from "./SchemaComponentList";
export * from "./SchemaConditional";
export * from "./SchemaDataSource";
export * from "./SchemaDynamic";
export * from "./SchemaExpression";
export * from "./SchemaFlowNode";
export * from "./SchemaFlowNodeList";
export * from "./SchemaIconName";
export * from "./SchemaIntegrationRef";
export * from "./SchemaNamedConfig";
export * from "./SchemaOneOf";
export * from "./SchemaOptionalGroup";
export * from "./SchemaOverride";
export * from "./SchemaRecursive";
export * from "./SchemaResponsiveValue";
export * from "./SchemaRouteRef";
export * from "./SchemaRuntimeWrapper";
export * from "./SchemaScopedTemplate";
export * from "./SchemaStringTranslate";
export * from "./SchemaStringWithConst";
export * from "./SchemaViewRef";
export * from "./SchemaVoid";

export * from "./expressionHelpers";
