/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, declareFunction, SCHEMA_CONST_ANY_VALUE_TYPE, Type } from "@hexio_io/hae-lib-blueprint";

export const isNull = declareFunction({
	name: "ISNULL",
	category: "types",
	label: "Is Null",
	description: "Returns true if a value is null.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		return args[0]() === null;
	}
});

export const isString = declareFunction({
	name: "ISSTRING",
	category: "types",
	label: "Is String",
	description: "Returns true if a value is a string.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		return typeof args[0]() === "string";
	}
});

export const isNumber = declareFunction({
	name: "ISNUMBER",
	category: "types",
	label: "Is Number",
	description: "Returns true if a value is a number.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		return typeof args[0]() === "number";
	}
});

export const isNaNFunc = declareFunction({
	name: "ISNAN",
	category: "types",
	label: "Is Not a Number",
	description: "Returns true if value is not a number.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER,
			constraints: {
				required: true
			},
			fallbackValue: 0
		})
	],
	argRestSchema: null,
	returnType: Type.Boolean({}),
	render: (_rCtx, args) => {
		return isNaN(args[0]());
	}
});

export const isFiniteFunc = declareFunction({
	name: "ISFINITE",
	category: "types",
	label: "Is Finite",
	description: "Returns true if value is a finite number.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Float({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: 0
		})
	],
	argRestSchema: null,
	returnType: Type.Boolean({}),
	render: (_rCtx, args) => {
		return isFinite(args[0]());
	}
});

export const isBool = declareFunction({
	name: "ISBOOL",
	category: "types",
	label: "Is Boolean",
	description: "Returns true if a value is a boolean.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		return typeof args[0]() === "boolean";
	}
});

export const isObject = declareFunction({
	name: "ISOBJECT",
	category: "types",
	label: "Is Object",
	description: "Returns true if a value is an object.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		return args[0]() instanceof Object;
	}
});

export const isArray = declareFunction({
	name: "ISARRAY",
	category: "types",
	label: "Is Array",
	description: "Returns true if a value is an array.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		return Array.isArray(args[0]());
	}
});
