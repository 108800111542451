/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	IActionRefItem,
	IActionRefResolver,
	TSchemaConstObjectProps
} from "@hexio_io/hae-lib-blueprint";
import { createEventEmitter, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IResourceRegistry } from "../registries";
import { IActionResourceProps } from "../resources";

/**
 * Action Ref Resolver
 */
export class ActionRefResolver implements IActionRefResolver {

	/**
	 * Invalidate event - emitted when action list has changed (and param schemas must be re-resolved)
	 */
	public onInvalidate: TSimpleEventEmitter<void>;

	/**
	 * Constructor
	 */
	public constructor(protected resourceRegistry: IResourceRegistry) {
		this.onInvalidate = createEventEmitter();
	}

	/**
	 * Returns a params schema by action ID
	 */
	public getParamsSchemaById(actionId: string): TSchemaConstObjectProps {

		const action = this.resourceRegistry.getItemList()
			.filter((resource) => resource.id === actionId)[0] as IActionResourceProps;

		if (action) {
			return action.parsedData.paramsSchemaImport.constSchema.opts.props;
		} else {
			return null;
		}

	}

	/**
	 * Returns a list of available actions
	 */
	public getActionList(): IActionRefItem[] {
		return;
	}

}