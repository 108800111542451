/**
 * Menu Button schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const menuButton = {
	items: {
		label: "schema:menuButton.items#label",
		description: "schema:menuButton.items#description"
	},
	itemKey: {
		label: "schema:menuButton.itemKey#label",
		description: "schema:menuButton.itemKey#description"
	},
	itemHideOnClick: {
		label: "schema:menuButton.itemHideOnClick#label",
		description: "schema:menuButton.itemHideOnClick#description"
	},
	itemSeparated: {
		label: "schema:menuButton.itemSeparated#label",
		description: "schema:menuButton.itemSeparated#description"
	},
	itemVisible: {
		label: "schema:menuButton.items#props.visible#label"
	},
	menuHorizontalAlign: {
		label: "schema:menuButton.menuHorizontalAlign#label",
		description: "schema:menuButton.menuHorizontalAlign#description"
	},
	events: {
		itemClick: {
			label: "schema:menuButton.events.itemClick#label"
		}
	}
};
