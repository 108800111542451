/**
 * Property Item schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const propertyItem = {
	items: {
		label: "schema:propertyItem.items#label",
		description: "schema:propertyItem.items#description"
	},
	copy: {
		label: "schema:propertyItem.copy#label",
		description: "schema:propertyItem.copy#description"
	},
	mode: {
		label: "schema:propertyItem.mode#label",
		description: "schema:propertyItem.mode#description"
	},
	modeValues: {
		text: {
			label: "schema:propertyItem.typeData#values.text#label"
		},
		password: {
			label: "schema:propertyItem.typeData#values.password#label"
		}
	},
	prefixText: {
		label: "schema:propertyItem.prefixText#label",
		description: "schema:propertyItem.prefixText#description"
	},
	prefixWidth: {
		label: "schema:propertyItem.prefixWidth#label",
		description: "schema:propertyItem.prefixWidth#description"
	},
	suffixText: {
		label: "schema:propertyItem.suffixText#label",
		description: "schema:propertyItem.suffixText#description"
	},
	suffixWidth: {
		label: "schema:propertyItem.suffixWidth#label",
		description: "schema:propertyItem.suffixWidth#description"
	},
	style: {
		label: "schema:propertyItem.style#label",
		description: "schema:propertyItem.style#description"
	},
	styleValues: {
		simple: {
			label: "schema:propertyItem.style#values.simple#label"
		},
		chip: {
			label: "schema:propertyItem.style#values.chip#label"
		}
	},
	text: {
		label: "schema:propertyItem.text#label",
		description: "schema:propertyItem.text#description"
	}
};
