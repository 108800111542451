/**
 * Icon HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	BP,
	Type,
	defineElementaryComponent,
} from "@hexio_io/hae-lib-blueprint";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	Icon,
	IconProps,
	IconSourceSchema,
	ICON_SOURCE_FALLBACK_VALUE,
	propGroups,
	IIconProps,
	ICON_SIZE_CLASS_string
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";

interface HAEComponentIcon_State {}

const HAEComponentIcon_Props = {

	source: BP.Prop(IconSourceSchema({
		alias: "iconSource_icon",
		fallbackValue: ICON_SOURCE_FALLBACK_VALUE,
		constraints: {
			required: true
		}
	}), IconProps.source.order, propGroups.common),

	size: BP.Prop(IconProps.size.schema, IconProps.size.order, propGroups.common),

	foregroundColor: BP.Prop(IconProps.foregroundColor.schema, IconProps.foregroundColor.order, propGroups.common)

};

const HAEComponentIcon_Events = {};

const HAEComponentIcon_Definition = defineElementaryComponent<
	typeof HAEComponentIcon_Props,
	HAEComponentIcon_State,
	typeof HAEComponentIcon_Events
>({

	...termsEditor.components.icon.component,

	name: "icon",

	category: "content",

	icon: "mdi/star",

	docUrl: "...",

	order: 50,

	props: HAEComponentIcon_Props,

	events: HAEComponentIcon_Events,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: () => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}

});

const HAEComponentIcon_React: THAEComponentReact<typeof HAEComponentIcon_Definition> = ({
	props, componentInstance, reactComponentClassList
}) => {
	const iconProps = { ...props };

	iconProps.size = iconProps.size as ICON_SIZE_CLASS_string;

	return (
		<Icon
			{...iconProps as IIconProps}
			componentPath={componentInstance.safePath}
			classList={reactComponentClassList}
			forceRender={true}
		/>
	);
};

export const HAEComponentIcon: THAEComponentDefinition<typeof HAEComponentIcon_Definition> = {
	...HAEComponentIcon_Definition,
	reactComponent: HAEComponentIcon_React
};
