/**
 * Horizontal Alignment enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum HORIZONTAL_ALIGN {
	LEFT = "left",
	CENTER = "center",
	RIGHT = "right",
	STRETCH = "stretch"
}

export type HORIZONTAL_ALIGN_string = keyof typeof HORIZONTAL_ALIGN;

export enum HORIZONTAL_ALIGN_BASIC {
	LEFT = "left",
	CENTER = "center",
	RIGHT = "right"
}

export type HORIZONTAL_ALIGN_BASIC_string = keyof typeof HORIZONTAL_ALIGN_BASIC;

export const HORIZONTAL_ALIGN_default: HORIZONTAL_ALIGN_string & HORIZONTAL_ALIGN_BASIC_string = "LEFT";

export const HORIZONTAL_ALIGN_stretch: HORIZONTAL_ALIGN_string = "STRETCH";

export const HORIZONTAL_ALIGN_itemDefault: HORIZONTAL_ALIGN_string = "CENTER";

export const HORIZONTAL_ALIGN_opts = {
	left: {
		icon: "mdi/format-horizontal-align-left"
	},
	center: {
		icon: "mdi/format-horizontal-align-center"
	},
	right: {
		icon: "mdi/format-horizontal-align-right"
	},
	stretch: {
		icon: "mdi/arrow-expand-horizontal"
	}
};
