/**
 * Number Field Slider HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { Slider } from "@hexio_io/hae-lib-components";
import { isFunction } from "@hexio_io/hae-lib-shared";

export interface NumberFieldSliderProps {
	className?: string;
	min?: number;
	max?: number;
	step?: number;
	disabled?: boolean;
	defaultValue?: number;
	value?: number;
	reverse?: boolean;
	onChange?: (value: number) => void;
}

export function NumberFieldSlider({ value, onChange, ...props }: NumberFieldSliderProps): React.ReactElement {
	const _sliderChangeHandler = React.useCallback(
		(newValue: number) => {
			if (isFunction(onChange)) {
				onChange(newValue);
			}
		},
		[ value, onChange ]
	);

	return (
		<Slider {...props} value={value} onChange={_sliderChangeHandler} dots={Number.isFinite(props.step)} />
	);
}
