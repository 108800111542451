/**
 * Border Radius schema
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstString,
	ISchemaValue,
	ISchemaValueStringOpts
} from "@hexio_io/hae-lib-blueprint";

import { termsEditor } from "../terms";

/**
 * Border Radius schema
 *
 * @param opts Options
 */
export function BorderRadiusSchema(opts: Partial<ISchemaValueStringOpts> = { alias: "borderRadius" }): ISchemaValue<ISchemaConstString> {
	return BP.String({
		...termsEditor.schemas.common.borderRadius,
		default: "",
		...opts
	});
}
