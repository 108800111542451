/**
 * Data Table HAE component Cell Title
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	SORT_ORDER,
	SORT_ORDER_MODE,
	SORT_ORDER_MODE_values,
	Text,
	ClassList,
	ICON_NAME,
	Button,
	IBaseProps,
	TTextProps,
	TIconProps,
	isUserInteractionEnabled
} from "@hexio_io/hae-lib-components";

import { isBoolean, isFunction, isValidObject } from "@hexio_io/hae-lib-shared";

/**
 * Data Table Content props
 */
export interface ITableCellTitleProps extends IBaseProps {
	/** Column key */
	columnKey: string;

	/** Text value */
	textValue: TTextProps["value"];

	/** Sort configuration */
	sort?: {
		key: string;
		orderValue: SORT_ORDER;
		orderModeValue: SORT_ORDER_MODE;
	};

	/** Select all configuration */
	selectAll?: {
		values?: boolean[];
		onChange: (checked: boolean) => void;
	};

	/** On Button click */
	onButtonClick?: (newSortOrderValue: SORT_ORDER_MODE) => void;
}

/**
 * Data Table Content component
 */
export const TableCellTitle: React.FunctionComponent<ITableCellTitleProps> = (props) => {
	const {
		columnKey,
		textValue,
		sort,
		selectAll,
		componentPath,
		componentMode,
		onButtonClick
	} = props;

	const userInteractionEnabled = isUserInteractionEnabled(componentMode);

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"table__cell-title", componentPath, { componentClassList: props.classList }
	);

	// Sort

	let iconSource: TIconProps["source"];	
	let newSortOrderValue: SORT_ORDER;

	if (isValidObject(sort)) {
		const [
			primarySortOrderValue,
			secondarySortOrderValue
		] = SORT_ORDER_MODE_values[sort.orderModeValue];

		if (columnKey === sort.key) {
			newSortOrderValue = sort.orderValue !== primarySortOrderValue ?
				primarySortOrderValue :
				(secondarySortOrderValue || primarySortOrderValue);

			iconSource = sort.orderValue === SORT_ORDER.ASC ?
				ICON_NAME.ARROW_UP :
				ICON_NAME.ARROW_DOWN;
		}
		else {
			newSortOrderValue = primarySortOrderValue;

			iconSource = ICON_NAME.ARROW_UP_DOWN;
		}
	}

	// Select all

	const selectAllValue = selectAll?.values?.every((item) => !!item) ?? null;

	const [ selectAllChecked, setSelectAllChecked ] = React.useState(selectAllValue);

	React.useLayoutEffect(() => {
		if (!isBoolean(selectAllValue)) {
			return;
		}

		setSelectAllChecked(selectAllValue);
	}, [ columnKey, selectAllValue ]);

	// Event handlers

	const _buttonClickHandler = React.useMemo(() => {
		if (!isFunction(onButtonClick)) {
			return;
		}

		return () => {
			onButtonClick(newSortOrderValue);
		};
	}, [ onButtonClick ]);

	const _selectAllChangeHandler = React.useMemo(() => {
		if (!isFunction(selectAll?.onChange)) {
			return;
		}

		return (event: React.ChangeEvent<HTMLInputElement>) => {
			const { currentTarget: target } = event;

			if (!target || !userInteractionEnabled) {
				return;
			}

			selectAll.onChange(target.checked);
		};
	}, [ selectAll?.onChange, userInteractionEnabled ]);

	return (
		<div className={classList.toClassName()}>
			<div className="table__cell-title-content">
				{
					_selectAllChangeHandler ?
						<input
							type="checkbox"
							checked={selectAllChecked}
							className="input input--type-checkbox table__cell-input"
							onChange={_selectAllChangeHandler}
						/> :
						null
				}

				{
					_buttonClickHandler ?
						<Button
							labelText={{ value: textValue }}
							labelIcon={{ source: iconSource, size: "SMALL" }}
							style="CLEAR"
							componentPath={[ ...componentPath, "button" ]}
							componentMode={componentMode}
							classList={new ClassList("table__cell-title-button")}
							onClick={_buttonClickHandler}
						/> :
						<Text
							value={textValue}
							componentPath={[ ...componentPath, "text" ]}
							componentMode={componentMode}
						/>
				}
			</div>
		</div>
	);
}
