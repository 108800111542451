/**
 * Data Table HAE component Resolve Formatted Value
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type, createSubScope, ISchemaExpression, ISchemaScopedTemplateSpec } from "@hexio_io/hae-lib-blueprint";

import { isFunction, isString, isValidValue } from "@hexio_io/hae-lib-shared";
import { TResolvedItemValue } from "./state";

/**
 * Resolves Formatted Value
 * 
 * @param value Value
 * @param formatter Formatter
 */
export function resolveFormattedValue(value: TResolvedItemValue, formatter?: ISchemaScopedTemplateSpec<ISchemaExpression>): string {
	if (isFunction(formatter)) {
		const formattedValue = formatter((parentScope) => {
			return createSubScope(parentScope, { value }, { value: Type.Any({}) });
		});

		if (isString(formattedValue)) {
			return formattedValue;
		}

		if (isValidValue(formattedValue)) {
			return `${formattedValue}`;
		}
	}

	return null;
}
