/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IExecutionContext } from "../WebServer";

export function MethodDecorator() {

	return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {

		const originalMethod = descriptor.value;

		const handler = async function (params, context: IExecutionContext) {
			return originalMethod.call(this, params, context);
		};

		descriptor.value = handler;

	};

}