/**
 * Button Role enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum BUTTON_ROLE {
	NONE = "none",
	SUBMIT = "submit"
}

export type BUTTON_ROLE_string = keyof typeof BUTTON_ROLE;

export const BUTTON_ROLE_default = "NONE";

export const BUTTON_ROLE_types = {
	none: "button",
	submit: "submit"
};
