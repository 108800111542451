/**
 * useField HAE component hook
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { THTMLFieldElement, useDidUpdateEffect, } from "@hexio_io/hae-lib-components";

import { isBoolean, isBrowser, isDeepEqual, isFunction, isNilOrEmptyString, isValidObject } from "@hexio_io/hae-lib-shared";

import { createSubScope, IScope, TComponentUpdateStateFunction } from "@hexio_io/hae-lib-blueprint";
import { IFieldState, initialFieldState } from "./state";

type TValidityValue = boolean | null;
type TStateData = Record<string, unknown>;

interface UseFieldOptions<TValue> {
	id?: string;
	state: IFieldState;
	readOnly?: boolean;
	validate?: boolean | ((value: TValue, validOrFieldElement: TValidityValue | THTMLFieldElement) => boolean);
	customValidation?: {
		condition: boolean;
		message: string;
	},
	validationDependencies?: React.DependencyList;
	fixDefaultValue?: boolean;
	isEmpty?: ((value: TValue) => boolean) | boolean;
	onChange?: (scope: IScope | ((parentScope: IScope) => IScope)) => Promise<void>;
}

export function useField<TValue = unknown>({
	id,
	state,
	readOnly,
	validate = false,
	customValidation,
	validationDependencies: dependencies = [],
	fixDefaultValue = false,
	isEmpty = isFieldEmpty,
	onChange
}: UseFieldOptions<TValue>, setState: TComponentUpdateStateFunction<IFieldState>
): {
	setValue: (value: TValue, stateData?: TStateData) => void;
	setTouched: (touched?: boolean) => void;
	fixValidity: (valueOrFieldElement?: TValidityValue | THTMLFieldElement) => void;
} {
	const initialValueRef = React.useRef(state.initialValue);

	const browser = isBrowser();

	// Returns element, safer than using "ref"

	const getFieldElement = React.useCallback(() => {
		return (browser && id) ? (document.getElementById(id) as THTMLFieldElement) : null;
	}, [ id ]);

	// Resolves element's native validity

	const resolveElementValidity = React.useCallback((fieldElement: THTMLFieldElement = getFieldElement()) => {
		if (browser && validate && fieldElement && isValidObject(fieldElement.validity)) {
			if (isFunction(fieldElement.setCustomValidity)) {
				if (customValidation && !customValidation.condition && customValidation.message) {
					fieldElement.setCustomValidity(customValidation.message);
				}
				else if (fieldElement.validity.customError) {
					fieldElement.setCustomValidity("");
				}
			}

			// If readOnly (property) has opposite value to what is set for element
			// (due to specific render conditions which shoudln't be taken into account for validation)
			// Then quickly invert it and retrieve real validation state

			if (isBoolean(readOnly) && readOnly !== fieldElement.readOnly) {
				fieldElement.readOnly = readOnly;

				const { valid } = fieldElement.validity;

				fieldElement.readOnly = !readOnly;

				return valid;
			}

			return fieldElement.validity.valid;
		}

		return null;
	}, [ readOnly, validate, customValidation?.condition, customValidation?.message, getFieldElement ]);

	// Set valid

	const setValid = React.useCallback((valid: boolean) => {
		if (!browser || valid === state.valid) {
			return;
		}

		setState((prevState) => ({
			...prevState,
			valid
		}));
	}, [ state.valid, setState ]);

	// Fix validity
	// @todo eventually don't expose fixValidity, but setValid and remove "isBoolean(validOrFieldElement)" part (check first!)

	const fixValidity = React.useCallback((validOrFieldElement: TValidityValue | THTMLFieldElement = getFieldElement()) => {
		if (!browser) {
			return;
		}

		if (!validate) {
			setValid(true);

			return;
		}

		if (isFunction(validate)) {
			setValid(validate(state.value as TValue, validOrFieldElement));
		}
		else if (validOrFieldElement instanceof HTMLElement) {
			setValid(resolveElementValidity(validOrFieldElement));
		}
		else if (isBoolean(validOrFieldElement)) {
			setValid(validOrFieldElement);
		}
	}, [ state.value, validate, setValid, resolveElementValidity, getFieldElement ]);

	// Set value in local field's state

	const setValue = React.useCallback((value: TValue, stateData: TStateData = {}) => {
		if (!browser) {
			return;
		}

		setState((prevState) => {
			const newState = {
				...prevState,
				...stateData,
				value
			};

			newState.empty = isFunction(isEmpty) ? isEmpty(newState.value) : isEmpty;
			newState.changed = !isDeepEqual(newState.value, newState.initialValue);

			return newState;
		});
	}, [ isEmpty, setState ]);

	// Set field value private method

	const setFieldElementValue = React.useCallback((value: string) => {
		if (!browser) {
			return;
		}

		const fieldElement = getFieldElement();

		if (fieldElement && fieldElement !== document.activeElement && fieldElement.value !== value) {
			fieldElement.value = (value !== "") ? value : null;
		}
	}, [ getFieldElement ]);

	// Set touched

	const setTouched = React.useCallback((touched = true) => {
		if (!browser || touched === state.touched) {
			return;
		}

		setState((prevState) => ({
			...prevState,
			touched
		}));
	}, [ state.touched, setState ]);

	// Reset touched when initial value changes

	React.useEffect(() => {
		if (!browser || isDeepEqual(initialValueRef.current, state.initialValue)) {
			return;
		}

		initialValueRef.current = state.initialValue;

		setTouched(initialFieldState.touched);
	}, [ state.initialValue, setTouched ]);

	// Default value fix

	React.useLayoutEffect(() => {
		if (!fixDefaultValue) {
			return;
		}

		setFieldElementValue(`${state.initialValue ?? ""}`);
	}, [ state.initialValue, fixDefaultValue, setFieldElementValue ]);

	const fixDefaultValueStringRef = React.useRef<string>(`${state.value ?? ""}`);
	const fixDefaultValueTimeoutRef = React.useRef<NodeJS.Timeout>();

	React.useLayoutEffect(() => {
		if (!browser || !fixDefaultValue) {
			return;
		}

		fixDefaultValueStringRef.current = `${state.value ?? ""}`;

		if (fixDefaultValueTimeoutRef.current) {
			return;
		}

		fixDefaultValueTimeoutRef.current = setTimeout(() => {
			setFieldElementValue(fixDefaultValueStringRef.current);

			fixDefaultValueTimeoutRef.current = null;
		}, 200);
	}, [ state.value, fixDefaultValue, setFieldElementValue ]);

	// Refresh validation after fixValidity function is updated
	// (this helps to prevent race condition with setState)

	React.useEffect(() => {
		fixValidity();
	}, [ fixValidity, ...dependencies ]);

	// On change event

	useDidUpdateEffect(() => {
		if (isFunction(onChange)) {
			onChange((parentScope) => createSubScope(parentScope));
		}
	}, [ state.value ]);

	// React.useEffect(() => console.log("Value changed", stateValue), [ stateValue ]);
	// React.useEffect(() => console.log("Validate changed", validate), [ validate ]);
	// React.useEffect(() => console.log("Set state changed"), [ setState ]);

	return {
		setValue,
		setTouched,
		fixValidity
	};
}

export function isFieldEmpty(value: unknown): boolean {
	return isNilOrEmptyString(value);
}

export function getFieldStateProps(
	value: unknown,
	initialValue: unknown,
	state: IFieldState,
	validate: boolean,
	isEmpty: ((value: unknown) => boolean) | boolean = isFieldEmpty
): {
	empty: boolean; touched: boolean; changed: boolean; valid: boolean;
} {
	return {
		empty: isFunction(isEmpty) ? isEmpty(value) : isEmpty,
		touched: isBoolean(state?.touched) ? state.touched : initialFieldState.touched,
		changed: !isDeepEqual(value, initialValue),
		valid: (validate && isBoolean(state?.valid)) ? state.valid : initialFieldState.valid
	};
}
