/**
 * Boolean helpers
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { isNonEmptyString } from "@hexio_io/hae-lib-shared";
import {
	BOOLEAN,
	BOOLEAN_default,
	BOOLEAN_emptyStringDefault,
	BOOLEAN_miscStringDefault
} from "../Enums/BOOLEAN";

import { getStringEnumValue } from "./enumHelpers";

/**
 * Resolves boolean from string value
 * 
 * @param value String value
 * @param specificStringsRules Specific strings' rules
 * @param emptyStringRule Empty string rule
 * @param miscStringRule Misc string rule
 */
export function resolveBooleanFromString(
	value: string,
	specificStringsRules: { key: string, value: string }[],
	emptyStringRule: string,
	miscStringRule: string
): boolean {
	let booleanValue: string;

	const rule = specificStringsRules.find((item) => isNonEmptyString(item.key) && item.key === value);

	if (rule) {
		booleanValue = getStringEnumValue(BOOLEAN, rule.value, BOOLEAN_default);
	}
	else {
		booleanValue = value === "" ?
			getStringEnumValue(BOOLEAN, emptyStringRule, BOOLEAN_emptyStringDefault) :
			getStringEnumValue(BOOLEAN, miscStringRule, BOOLEAN_miscStringDefault);
	}

	switch (booleanValue) {
		case BOOLEAN.TRUE:
			return true;

		case BOOLEAN.FALSE:
			return false;

		default:
			return null;
	}
}
