/**
 * Common schema terms
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const common = {
	animate: {
		label: "schema:common.animate#label"
	},

	backgroundImages: {
		label: "schema:common.backgroundImages#label",
		description: "schema:common.backgroundImages#description"
	},
	backgroundImage: {
		label: "schema:common.backgroundImage#label"
	},
	backgroundImageValues: {
		url: {
			label: "schema:common.backgroundImage#values.url#label"
		}
	},
	backgroundImageSource: {
		label: "schema:common.backgroundImage#props.source#label",
		description: "schema:common.backgroundImage#props.source#description"
	},
	backgroundImageSize: {
		label: "schema:common.backgroundImage#props.size#label"
	},
	backgroundImageSizeValues: {
		contain: {
			label: "schema:common.backgroundImage#props.size#values.contain#label"
		},
		cover: {
			label: "schema:common.backgroundImage#props.size#values.cover#label"
		}
	},
	backgroundImagePosition: {
		label: "schema:common.backgroundImage#props.position#label",
		description: "schema:common.backgroundImage#props.position#description"
	},
	backgroundImageRepeat: {
		label: "schema:common.backgroundImage#props.repeat#label",
		description: "schema:common.backgroundImage#props.repeat#description"
	},
	backgroundImageAttachment: {
		label: "schema:common.backgroundImage#props.attachment#label",
		description: "schema:common.backgroundImage#props.attachment#description"
	},
	backgroundImageAttachmentValues: {
		scroll: {
			label: "schema:common.backgroundImage#props.attachment#values.scroll#label"
		},
		local: {
			label: "schema:common.backgroundImage#props.attachment#values.local#label"
		},
		fixed: {
			label: "schema:common.backgroundImage#props.attachment#values.fixed#label"
		}
	},

	booleanValues: {
		true: {
			label: "schema:common.boolean#values.true#label"
		},
		false: {
			label: "schema:common.boolean#values.false#label"
		}
	},
	booleanFromSpecificString: {
		label: "schema:common.booleanFromSpecificString#label"
	},
	booleanFromSpecificStringKey: {
		label: "schema:common.booleanFromSpecificString#props.key#label"
	},
	booleanFromSpecificStringValue: {
		label: "schema:common.booleanFromSpecificString#props.value#label"
	},
	booleanFromEmptyString: {
		label: "schema:common.booleanFromEmptyString#label"
	},
	booleanFromMiscString: {
		label: "schema:common.booleanFromMiscString#label"
	},

	borderRadius: {
		label: "schema:common.borderRadius#label",
		description: "schema:common.borderRadius#description"
	},

	borderWidth: {
		label: "schema:common.borderWidth#label",
		description: "schema:common.borderWidth#description"
	},

	boxShadow: {
		label: "schema:common.boxShadow#label",
		description: "schema:common.boxShadow#description"
	},
	boxShadowValues: {
		none: {
			label: "schema:common.boxShadow#values.none#label"
		},
		theme: {
			label: "schema:common.boxShadow#values.theme#label"
		},
		xsmall: {
			label: "schema:common.boxShadow#values.xsmall#label"
		},
		small: {
			label: "schema:common.boxShadow#values.small#label"
		},
		medium: {
			label: "schema:common.boxShadow#values.medium#label"
		},
		large: {
			label: "schema:common.boxShadow#values.large#label"
		},
		xlarge: {
			label: "schema:common.boxShadow#values.xlarge#label"
		},
	},

	customData: {
		label: "schema:common.customData#label",
		description: "schema:common.customData#description"
	},

	datetimeUnitValues: {
		millisecond: {
			label: "schema:common.datetimeUnit#values.millisecond#label"
		},
		second: {
			label: "schema:common.datetimeUnit#values.second#label"
		},
		minute: {
			label: "schema:common.datetimeUnit#values.minute#label"
		},
		hour: {
			label: "schema:common.datetimeUnit#values.hour#label"
		},
		day: {
			label: "schema:common.datetimeUnit#values.day#label"
		},
		week: {
			label: "schema:common.datetimeUnit#values.week#label"
		},
		month: {
			label: "schema:common.datetimeUnit#values.month#label"
		},
		year: {
			label: "schema:common.datetimeUnit#values.year#label"
		}
	},

	dimensionConstraints: {
		patternErrorMessage: "schema:common.dimension#constraints.patternError#message"
	},

	height: {
		label: "schema:common.height#label"
	},

	horizontalAlign: {
		label: "schema:common.horizontalAlign#label",
		description: "schema:common.horizontalAlign#description"
	},
	horizontalAlignValues: {
		left: {
			label: "schema:common.horizontalAlign#values.left#label"
		},
		center: {
			label: "schema:common.horizontalAlign#values.center#label"
		},
		right: {
			label: "schema:common.horizontalAlign#values.right#label"
		},
		stretch: {
			label: "schema:common.horizontalAlign#values.stretch#label"
		}
	},

	inherit: {
		label: "schema:common.inheritFromBase#label"
	},

	labelPosition: {
		label: "schema:common.labelPosition#label",
		description: "schema:common.labelPosition#description"
	},
	labelPositionValues: {
		top: {
			label: "schema:common.labelPosition#values.top#label"
		},
		left: {
			label: "schema:common.labelPosition#values.left#label"
		}
	},
	labelWidth: {
		label: "schema:common.labelWidth#label",
		description: "schema:common.labelWidth#description"
	},
	labelWidthConstraints: {
		patternErrorMessage: "schema:common.labelWidth#constraints.patternError#message"
	},

	loading: {
		label: "schema:common.loading#label"
	},
	loadingRenderContent: {
		label: "schema:common.loading#props.renderContent#label"
	},

	margin: {
		label: "schema:common.margin#label",
		description: "schema:common.margin#description"
	},

	name: {
		label: "schema:common.name#label"
	},

	orientation: {
		label: "schema:common.orientation#label"
	},
	orientationValues: {
		horizontal: {
			label: "schema:common.orientation#values.horizontal#label"
		},
		vertical: {
			label: "schema:common.orientation#values.vertical#label"
		}
	},

	overflow: {
		label: "schema:common.overflow#label",
		description: "schema:common.overflow#description"
	},
	overflowValues: {
		none: {
			label: "schema:common.overflow#values.none#label"
		},
		horizontal: {
			label: "schema:common.overflow#values.horizontal#label"
		},
		vertical: {
			label: "schema:common.overflow#values.vertical#label"
		},
		both: {
			label: "schema:common.overflow#values.both#label"
		},
		visible: {
			label: "schema:common.overflow#values.visible#label"
		}
	},

	padding: {
		label: "schema:common.padding#label",
		description: "schema:common.padding#description"
	},

	position: {
		label: "schema:common.position#label",
		description: "schema:common.position#description"
	},
	positionValues: {
		relative: {
			label: "schema:common.position#values.relative#label"
		},
		absolute: {
			label: "schema:common.position#values.absolute#label"
		},
		fixed: {
			label: "schema:common.position#values.fixed#label"
		}
	},
	positionInset: {
		top: {
			label: "schema:common.position#props.inset#props.top#label"
		},
		right: {
			label: "schema:common.position#props.inset#props.right#label"
		},
		bottom: {
			label: "schema:common.position#props.inset#props.bottom#label"
		},
		left: {
			label: "schema:common.position#props.inset#props.left#label"
		}
	},

	sortOrderModeValues: {
		none: {
			label: "schema:common.sortOrderMode#values.none#label"
		},
		asc_desc: {
			label: "schema:common.sortOrderMode#values.asc_desc#label"
		},
		desc_asc: {
			label: "schema:common.sortOrderMode#values.desc_asc#label"
		},
		asc: {
			label: "schema:common.sortOrderMode#values.asc#label"
		},
		desc: {
			label: "schema:common.sortOrderMode#values.desc#label"
		}
	},

	spacingValues: {
		none: {
			label: "schema:common.spacing#values.none#label"
		},
		narrow: {
			label: "schema:common.spacing#values.narrow#label"
		},
		medium: {
			label: "schema:common.spacing#values.medium#label"
		},
		wide: {
			label: "schema:common.spacing#values.wide#label"
		},
		xwide: {
			label: "schema:common.spacing#values.xwide#label"
		}
	},
	spacingConstraints: {
		patternErrorMessage: "schema:common.spacing#constraints.patternError#message"
	},

	tooltip: {
		label: "schema:common.tooltip#label"
	},

	verticalAlign: {
		label: "schema:common.verticalAlign#label",
		description: "schema:common.verticalAlign#description"
	},
	verticalAlignValues: {
		top: {
			label: "schema:common.verticalAlign#values.top#label"
		},
		middle: {
			label: "schema:common.verticalAlign#values.middle#label"
		},
		bottom: {
			label: "schema:common.verticalAlign#values.bottom#label"
		},
		stretch: {
			label: "schema:common.verticalAlign#values.stretch#label"
		}
	},

	width: {
		label: "schema:common.width#label"
	},

	zIndex: {
		label: "schema:common.zIndex#label"
	},
	zIndexValues: {
		auto: {
			label: "schema:common.zIndex#values.auto#label"
		},
		under: {
			label: "schema:common.zIndex#values.under#label"
		},
		base: {
			label: "schema:common.zIndex#values.base#label"
		},
		above: {
			label: "schema:common.zIndex#values.above#label"
		},
		top: {
			label: "schema:common.zIndex#values.top#label"
		}
	}
};
