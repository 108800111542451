/**
 * Icon Size Class enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum ICON_SIZE_CLASS {
	SMALL = "icon--size-small",
	MEDIUM = "icon--size-medium",
	LARGE = "icon--size-large",
	XLARGE = "icon--size-x-large",
	XXLARGE = "icon--size-xx-large"
}

export type ICON_SIZE_CLASS_string = keyof typeof ICON_SIZE_CLASS;

export const ICON_SIZE_CLASS_default: ICON_SIZE_CLASS_string = "MEDIUM";
