/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum EDITOR_APP_PERMISSIONS {
	AppEditorImpersonate = "APP/IMPERSONATE",
	AppEditorRead = "APP/EDITOR_READ",
	AppEditorComment = "APP/EDITOR_COMMENT",
	AppEditorEdit = "APP/EDITOR_EDIT",
	AppIntegrationRead = "APP/INTEGRATION_READ",
	AppIntegrationManage = "APP/INTEGRATION_MANAGE",
	AppUsersRead = "APP/USERS_READ",
	AppUsersManage = "APP/USERS_MANAGE",
	AppDeployment = "APP/DEPLOYMENT",
	AppDeploymentProtected = "APP/DEPLOYMENT_PROTECTED"
}
