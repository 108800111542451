/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaFlowNodeTypeOutputDefinitionMap } from "@hexio_io/hae-lib-blueprint";
import { FLOW_NODE_TYPES } from "./BlueprintNode";

export const BlueprintFlowNodeTypeIntegration = {
	name: FLOW_NODE_TYPES.INTEGRATION,
	label: "Integration",
	opts: BP.Special.IntegrationRef({
		constraints: { required: true }
	}),
	editorOptions: {
		displayInPalette: false,
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		onSuccess: { label: "On Success", icon: "mdi/check" },
		onError: { label: "On Error", icon: "mdi/alert" }
	})
};