/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { DesignContext } from "../../Context/DesignContext";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { ISchemaImportExport } from "../../ExportImportSchema/ExportTypes";
import {
	IBlueprintSchema,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel
} from "../../Schema/IBlueprintSchema";
import {
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	validateAsNotSupported,
	assignParentToModelProps
} from "../../Schema/SchemaHelpers";
import { ISchemaConstPasswordOpts } from "../const/SchemaConstPassword";
import { ISchemaConstObject, ISchemaConstObjectOptsProp, Prop, SchemaConstObject } from "../const/SchemaConstObject";
import { ISchemaBuilderOpts, SchemaBuilderBaseProps, TCompareSchemaBuilderProps, TSchemaBuilderBaseProps } from "./SchemaBuilderShared";
import { IModelNode } from "../../Schema/IModelNode";
import { TypeDescAny } from "../../Shared/ITypeDescriptor";
import { applyCodeArg } from "../../Context/CompileUtil";
import { ISchemaConstBoolean, SchemaConstBoolean } from "../const/SchemaConstBoolean";
import { ISchemaConstString, SchemaConstString } from "../const/SchemaConstString";
import { ISchemaConstInteger, SchemaConstInteger } from "../const/SchemaConstInteger";

type TSchemaBuilderPasswordPropsSchema = ISchemaConstObject<
	TSchemaBuilderBaseProps & {
		default: ISchemaConstObjectOptsProp<ISchemaConstString>;
		fallbackValue: ISchemaConstObjectOptsProp<ISchemaConstString>;
		constraints: ISchemaConstObjectOptsProp<ISchemaConstObject<{
			required: ISchemaConstObjectOptsProp<ISchemaConstBoolean>;
			const: ISchemaConstObjectOptsProp<ISchemaConstString>;
			min: ISchemaConstObjectOptsProp<ISchemaConstInteger>;
			max: ISchemaConstObjectOptsProp<ISchemaConstInteger>;
			pattern: ISchemaConstObjectOptsProp<ISchemaConstString>;
			patternErrorMessage: ISchemaConstObjectOptsProp<ISchemaConstString>;
		}>>
	}
>;

/**
 * Development helper to check types - when resolves to "true" then the props schema is correct and up to date.
 * Otherwise you have an error somewhere. It compares if SchemaBuilder...PropsSchema output (spec) equals to the interface
 * that describes options of a target schema the builder represents.
 */
type _TypeCheck = TCompareSchemaBuilderProps<ISchemaConstPasswordOpts, TSchemaBuilderPasswordPropsSchema>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __HERE_SHOULD_BE_NO_TYPE_ERRORS__: _TypeCheck = true;

/**
 * Opts Schema
 */
export interface ISchemaBuilderPasswordOptsSchema extends ISchemaBuilderOpts { }

/**
 * Schema Model
 */
export interface ISchemaBuilderPasswordModel extends IModelNode<ISchemaBuilderPassword> {
	props: TGetBlueprintSchemaModel<TSchemaBuilderPasswordPropsSchema>
}

export type TSchemaBuilderPasswordSpec = ISchemaImportExport;
export type TSchemaBuilderPasswordDefault = TGetBlueprintSchemaDefault<TSchemaBuilderPasswordPropsSchema>;

/**
 * Schema Builder Password Schema
 */
export interface ISchemaBuilderPassword extends IBlueprintSchema<
	ISchemaBuilderOpts,
	ISchemaBuilderPasswordModel,
	ISchemaImportExport,
	TSchemaBuilderPasswordDefault
> { }

/**
 * Schema Builder: Password
 *
 * @param opts Schema options
 */
export function SchemaBuilderPassword(opts: ISchemaBuilderOpts): ISchemaBuilderPassword {

	type TPropsModel = TGetBlueprintSchemaModel<TSchemaBuilderPasswordPropsSchema>;

	const propsSchema: TSchemaBuilderPasswordPropsSchema = SchemaConstObject({
		constraints: opts.constraints,
		props: {
			...SchemaBuilderBaseProps,
			default: Prop(SchemaConstString({
				label: "Default value",
				description: "Default field value."
			}), 60),
			fallbackValue: Prop(SchemaConstString({
				label: "Fallback value",
				description: "Value which will be used when a field value is not valid."
			}), 70),
			constraints: Prop(SchemaConstObject({
				label: "Constraints",
				description: "Validation rules.",
				props: {
					required: Prop(SchemaConstBoolean({
						label: "Required",
						description: "If a value is required."
					}), 10),
					const: Prop(SchemaConstString({
						label: "Must be exact",
						description: "If a value must be exact true or false to be valid."
					}), 20),
					min: Prop(SchemaConstInteger({
						label: "Minimum length",
						description: "Minimum required length of the value."
					}), 30),
					max: Prop(SchemaConstInteger({
						label: "Maximum length",
						description: "Maximum allowed length of the value."
					}), 40),
					pattern: Prop(SchemaConstString({
						label: "Validation pattern",
						description: "Regular expression used to validate the value."
					}), 50),
					patternErrorMessage: Prop(SchemaConstString({
						label: "Pattern error message",
						description: "Displayed instead of regular expression when value doesn't match."
					}), 60)
				}
			}), 80)
		}
	});

	const schema = createEmptySchema<ISchemaBuilderPassword>("builderPassword", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, ["props"])
	}

	const createModel = (
		dCtx: DesignContext,
		propsModel: TPropsModel,
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx, parent, [], {
			props: propsModel
		});

		const model = assignParentToChildrenOf(modelNode)

		model.initRequiredValid = propsModel.initRequiredValid;

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		const propsModel = propsSchema.createDefault(dCtx, null, defaultValue);
		return createModel(dCtx, propsModel, parent);

	};

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedPropsModel = propsSchema.clone(dCtx, modelNode.props, null);

		const clone = cloneModelNode(dCtx, modelNode, parent,{
			props: clonedPropsModel
		})

		return assignParentToChildrenOf(clone)

	};

	schema.destroy = (modelNode) => {

		propsSchema.destroy(modelNode.props);
		modelNode.props = undefined;
		destroyModelNode(modelNode);

	};

	schema.parse = (dCtx, idtNode, parent) => {

		const propsModel = propsSchema.parse(dCtx, idtNode, parent);
		return createModel(dCtx, propsModel, parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		if (propsSchema.provideCompletion) {
			propsSchema.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}

	};

	schema.serialize = (modelNode, path) => {

		return propsSchema.serialize(modelNode.props, path);

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		const prevPropsSpec = prevSpec?._type === "SchemaValue" ? prevSpec._values[0]._values[0] : prevSpec?._values?.[0];
		const propsSpec = propsSchema.render(rCtx, modelNode.props, path, scope, prevPropsSpec);

		const exportData = {
			_type: "Schema",
			_name: "SchemaConstPassword",
			_values: [propsSpec]
		};

		if (opts.constantOnly === true) {
			return exportData;
		} else {
			return {
				_type: "Schema",
				_name: "SchemaValue",
				_values: [exportData, propsSpec]
			};
		}

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		const propsCmp = propsSchema.compileRender(cCtx, modelNode.props, path);

		return {
			isScoped: true,
			code: `(s,pv,pt)=>{${[
				// Previous props
				`const _pp=pv&&pv._type==="SchemaValue"?pv._values[0]._values[0]:pv&&pv._values?pv._values[0]:undefined;`,
				// opts
				`const _op=${applyCodeArg(propsCmp, `_pp`, `pt`)};`,
				// Export data
				`const _ed={_type:"Schema",_name:"SchemaConstPassword",_values:[_op]};`,
				// Return
				opts.constantOnly
					? `return _ed`
					: `return {_type:"Schema",_name:"SchemaValue",_values:[_ed,_op]}`
			].join("")}}`
		}

	};

	/** Schema value cannot be validated because spec is ExportImport type which should not be constructed in any manuall way */
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	schema.compileValidate = (cCtx, path, modelNodeId) => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	schema.export = () => {
		return exportSchema("SchemaBuilderPassword", [opts]);
	};

	/** Is any because export data are internals and should not be exposed. */
	schema.getTypeDescriptor = () => {
		return TypeDescAny({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = (modelNode) => {
		return [{
			key: "props",
			node: modelNode.props
		}]
	}

	return schema;

}
