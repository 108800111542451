/**
 * Composed Chart HAE component settings
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const noStrokeSettings = {
	strokeOpacity: 0,
	strokeWidth: 0
};

export const noPaddingSettings = {
	paddingTop: 0,
	paddingRight: 0,
	paddingBottom: 0,
	paddingLeft: 0
};

export const rangeBaseSettings = {
	strokeOpacity: 1,
	strokeWidth: 1,
	location: 0
};

export const rangeLabelBaseSettings = {
	paddingTop: 5,
	paddingRight: 5,
	paddingBottom: 5,
	paddingLeft: 5
};

export const controlBaseSettings = {
	width: 30,
	height: 30
};
