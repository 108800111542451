/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaFlowNodeTypeOutputDefinitionMap } from "@hexio_io/hae-lib-blueprint";
import { FLOW_NODE_TYPES } from "./BlueprintNode";

export const BlueprintFlowNodeTypeAction = {
	name: FLOW_NODE_TYPES.ACTION,
	label: "Action",
	icon: "mdi/motion-play",
	opts: BP.ActionRef({
		constraints: { required: true },
		label: "Action",
		description: "asdsad"
	}),
	editorOptions: {
		displayInPalette: true
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		onSuccess: { label: "On Success", icon: "mdi/check" },
		onError: { label: "On Error", icon: "mdi/alert" },
		onFail: { label: "On Fail", icon: "mdi/close" }
	})
};
