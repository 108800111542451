/**
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useCallback, useRef, useState, CSSProperties, useMemo } from "react";
import { COMPONENT_MODE, TGenericComponentInstance } from "@hexio_io/hae-lib-blueprint";
import { HAEComponentChildContext, IHAEComponentChildContextValue, useComponentMainContext } from "./HAEComponentContext";
import { useHAEComponent } from "./useHAEComponent";
import { ClassList } from "../Classes/ClassList";
import { HAEComponentEditorHandle } from "./HAEComponentEditorHandle";
import { HAEComponentResizer } from "./HAEComponentResizer";
import { IAllowedResizeDimensions, TResizeBeginHandler, TResizeEndHandler, TResizeUpdateHandler } from "../Editor/IResize";
import { useComponentEdit } from "../Editor/useComponentEdit";
import { termsRuntime } from "../terms";
import { LoadingInfo } from "../ReactComponents/LoadingInfo";
import { ICON_NAME } from "../Enums/ICON_NAME";
import { useLoading } from "../Hooks/useLoading";
import { useTranslate } from "../i18n/useTranslate";
import { Icon } from "../ReactComponents/Icon";

/**
 * HAE Component properties
 */
export interface IHAEComponentProps {
	/** Component instance */
	componentInstance: TGenericComponentInstance;

	/** React tag to wrap component into (defaults to <div>) */
	overrideTagName?: "div"|"span";

	/** ClassList to add to component wrapper */
	classList?: ClassList;

	/** Custom additional inline stle */
	inlineStyle?: CSSProperties;

	/** Additional properties */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	additionalProps?: any;

	/** If element can be resized in edit mode */
	allowResize?: IAllowedResizeDimensions;

	/** Callback to handle component resize begin */
	onResizeBegin?: TResizeBeginHandler<TGenericComponentInstance, unknown>;

	/** Callback to handle component resize update on mouse move (should update element style) */
	onResizeUpdate?: TResizeUpdateHandler<TGenericComponentInstance, unknown>;

	/** Callback to handle component resize end (should write changes to model) */
	onResizeEnd?: TResizeEndHandler<TGenericComponentInstance, unknown>;

	/** If to allow dragging of the element */
	allowDrag?: boolean;
}

/**
 * Loading delay temporary const
 */
const LOADING_DELAY = 1000;

/**
 * Renders a list of HAE Components
 *
 * @param reactProps React component props
 */
export const HAEComponent: React.FunctionComponent<IHAEComponentProps> = (reactProps) => {

	const ctx = useComponentMainContext();

	const wrapperRef = useRef<HTMLElement>();

	const { props, state, setState } = useHAEComponent(reactProps);
	const { cssVisibility, showLoading } = reactProps.componentInstance.display;

	// Child context is a memoized instance to avoid unneccessary re-renders
	// Child react components use only object references
	const childContext: IHAEComponentChildContextValue = useMemo(() => ({
		dndState: {
			active: null
		}
	}), []);

	const {
		isSelected,
		isHovered,
		isBeingDragged,
		componentDnD,
		handleClick,
		handleMouseOver,
		handleMouseOut,
		handleDragStart
	} = useComponentEdit(reactProps.componentInstance, wrapperRef, childContext);

	const t = useTranslate();

	const [ showOptions, setShowOptions ] = useState(false);

	const { id, componentMode } = reactProps.componentInstance;
	const editComponentMode = componentMode === COMPONENT_MODE.EDIT;

	const componentLoading = showLoading && reactProps.componentInstance.isLoading &&
		!reactProps.componentInstance.isLoadingWithData;

	const componentLoadingWithData = showLoading && reactProps.componentInstance.isLoading &&
		reactProps.componentInstance.isLoadingWithData &&
		!reactProps.componentInstance.hasErrors;

	const [ loading, loadingRef ] = useLoading(componentLoading, LOADING_DELAY);
	const [ loadingWithData, loadingWithDataRef ] = useLoading(componentLoadingWithData, LOADING_DELAY);

	// Get component definition
	const cmpDef = ctx.componentRegistry.get(reactProps.componentInstance.componentName);

	const notFound = !cmpDef;

	const wasRendered = reactProps.componentInstance.wasRendered;

	const componentContents = useMemo(() => {
		// console.log("Cmp re-render", id, reactProps.componentInstance.uid, reactProps.componentInstance);

		if (!wasRendered) {
			return (
				<Icon
					source={ICON_NAME.CLOSE}
					size="LARGE"
					tooltip={t("runtime", termsRuntime.states.componentNotRendered, { name: cmpDef.name })}
					componentPath={[ ...reactProps.componentInstance.safePath, "not-rendered" ]}
					componentMode={componentMode}
					classList={new ClassList("hae-component__state-info")}
				/>
			);
		}

		if (
			(showLoading && reactProps.componentInstance.isLoading && !reactProps.componentInstance.isLoadingWithData) ||
			reactProps.componentInstance.hasErrors
		) {
			return null;
		}

		return (
			<HAEComponentChildContext.Provider value={childContext}>
				<cmpDef.reactComponent
					props={props}
					state={state}
					setState={setState}
					componentInstance={reactProps.componentInstance}
					reactComponentClassList={new ClassList("hae-component__react-component")}
					additionalProps={reactProps.additionalProps}
					showOptions={showOptions}
				/>
			</HAEComponentChildContext.Provider>
		);
	}, [
		props,
		state,
		setState,
		reactProps.componentInstance,
		wasRendered,
		showLoading,
		reactProps.additionalProps,
		showOptions
	]);

	// Mouse events

	const handleOptionsToggle = useCallback(() => {
		setShowOptions((s) => !s);
	}, []);

	const tagName = reactProps.overrideTagName || cmpDef.reactTagName || "div";

	// Create classList

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"hae-component", undefined, { componentClassList: reactProps.classList, componentMode }
	);

	if (cmpDef.reactClassName) {
		classList.addString(cmpDef.reactClassName);
	}

	if (reactProps.componentInstance.display.cssClassName) {
		classList.addString(reactProps.componentInstance.display.cssClassName);
	}

	classList.addModifiers({
		name: cmpDef.name,
		"visible": cssVisibility === "visible",
		"hidden": cssVisibility === "hidden",
		// Editor
		"hovered": isHovered,
		"selected": isSelected,
		"non-visual": cmpDef.nonVisual,
		"templated": reactProps.componentInstance.isTemplated,
		"dragging": !!isBeingDragged,
		"not-found": notFound,
		"rendered": wasRendered,
		"not-rendered": !wasRendered,
		// Errors & warnings
		"with-errors": reactProps.componentInstance.hasErrors,
		"with-warnings": reactProps.componentInstance.hasWarnings,
	});

	if (notFound) {
		return (
			<div className={classList.toClassName()}>
				<Icon
					source={ICON_NAME.ERROR}
					size="LARGE"
					tooltip={t("runtime", termsRuntime.errors.componentNotFound, { name: reactProps.componentInstance.componentName })}
					componentPath={[ ...reactProps.componentInstance.safePath, "not-found" ]}
					componentMode={componentMode}
					classList={new ClassList("hae-component__state-info")}
				/>
			</div>
		);
	}

	// Set content component name to provide better debuging
	cmpDef.reactComponent.displayName = "HAEComponentContent_" + cmpDef.name;

	// Render content
	const content = <>
		{
			editComponentMode
				? <>
					<div className="hae-component__editor-boundary hae-component__editor-boundary--background" />
					<div className="hae-component__editor-boundary hae-component__editor-boundary--top" />
					<div className="hae-component__editor-boundary hae-component__editor-boundary--right" />
					<div className="hae-component__editor-boundary hae-component__editor-boundary--bottom" />
					<div className="hae-component__editor-boundary hae-component__editor-boundary--left" />
				</>
				: null
		}

		{
			// Loading, without data
			loading
				? <div ref={loadingRef} className="hae-component__loading hae-component__loading--hidden hae-component__loading--full">
					<LoadingInfo classList={new ClassList("hae-component__loading-info")} />
				</div>
				: null
		}

		{
			// Loading with data
			loadingWithData
				? <div
					ref={loadingWithDataRef}
					className="hae-component__loading hae-component__loading--hidden hae-component__loading--with-data"
				>
					<LoadingInfo classList={new ClassList("hae-component__loading-info")} />
				</div>
				: null
		}

		{
			!componentLoading
				// Has data, check error
				? reactProps.componentInstance.hasErrors
					? <span className="hae-component__error-container">
						<Icon
							source={ICON_NAME.ERROR}
							size="LARGE"
							tooltip={t("runtime", termsRuntime.errors.componentHasInvalidConfiguration)}
							componentPath={[ ...reactProps.componentInstance.safePath, "invalid-configuration" ]}
							componentMode={componentMode}
							classList={new ClassList("hae-component__state-info")}
						/>
					</span>
					: componentContents
				: null
		}

		{
			editComponentMode && (
				isHovered ||
				isSelected ||
				(cmpDef.container && componentDnD?.wasEntered())
			)
				? <HAEComponentEditorHandle
					label={id}
					optionsActive={showOptions}
					onOptionsToggle={cmpDef.hasOptions ? handleOptionsToggle : undefined}
				/>
				: null
		}

		{
			editComponentMode &&
			reactProps.componentInstance.hasWarnings &&
			(
				!reactProps.componentInstance.isLoading ||
				(reactProps.componentInstance.isLoading && reactProps.componentInstance.isLoadingWithData)
			)
				? <span className="hae-component__editor-flag-warning" title={t("runtime", termsRuntime.errors.componentHasErrors)}>
					{/*<Icon
						source={ICON_NAME.WARNING_2}
						size={"MEDIUM"}
						componentPath={[ ...reactProps.componentInstance.safePath, "warning" ]}
					/>*/}
					{ /*eslint-disable max-len */ }
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24pt" height="24pt">
						<path d="M 12.889 20.452 L 21.786 20.452 L 11.999 3.549 L 2.213 20.452 Z" className="editor-flag-shape-outline" />
						<path d="M 12.889 14.224 L 11.109 14.224 L 11.109 9.776 L 12.889 9.776 M 12.889 17.783 L 11.109 17.783 L 11.109 16.004 L 12.889 16.004 M 2.213 20.452 L 21.786 20.452 L 11.999 3.549 L 2.213 20.452 Z" className="editor-flag-shape-main" />
					</svg>
					{ /*eslint-enable max-len */ }
				</span>
				: null
		}

		{
			editComponentMode && isSelected && reactProps.allowResize
				? <HAEComponentResizer
					targetElement={wrapperRef.current}
					subject={reactProps.componentInstance}
					updateRef={reactProps.componentInstance.rev}
					onResizeBegin={reactProps.onResizeBegin}
					onResizeUpdate={reactProps.onResizeUpdate}
					onResizeEnd={reactProps.onResizeEnd}
					allowedDims={reactProps.allowResize}
				/>
				: null
		}
	</>;

	// console.log("Render component", id);

	const elId = "cmp-" + id;

	return React.createElement(tagName, {
		id: elId,
		className: classList.toClassName(),
		style: reactProps.inlineStyle,
		ref: wrapperRef,
		onClick: handleClick,
		onMouseOver: handleMouseOver,
		//onMouseOut: handleMouseOut,
		onMouseLeave: handleMouseOut, // Fixes handle blinking
		onDragStart: handleDragStart,
		draggable: editComponentMode && reactProps.allowDrag ? true : false
	}, content);
};
