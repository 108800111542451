
/**
 * Schema prop groups
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstObjectOptsGroup } from "@hexio_io/hae-lib-blueprint";
import { termsEditor } from "../terms";

export const propGroups: Record<string, ISchemaConstObjectOptsGroup> = {

	__primary__: {
		label: null,
		id: "__primary__"
	},

	common: {
		...termsEditor.schemas.propGroups.common,
		id: "common",
		order: 0,
		defaultExpanded: true
	},

	state: {
		...termsEditor.schemas.propGroups.state,
		id: "state",
		order: 20,
		defaultExpanded: true
	},

	type: {
		...termsEditor.schemas.propGroups.type,
		id: "type",
		order: 30,
		defaultExpanded: true
	},

	style: {
		...termsEditor.schemas.propGroups.style,
		id: "style",
		order: 40,
		defaultExpanded: true
	},

	validation: {
		...termsEditor.schemas.propGroups.validation,
		id: "validation",
		order: 50,
		defaultExpanded: true
	},

	advanced: {
		...termsEditor.schemas.propGroups.advanced,
		id: "advanced",
		order: 60
	}

};