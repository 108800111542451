/**
 * Hexio App Engine Shared library.
 *
 * @package hae-lib-shared
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { v4 as uuid_v4 } from "uuid";

export function uuid(): string {
	return uuid_v4();
}