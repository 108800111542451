/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGenericBlueprintSchema } from "@hexio_io/hae-lib-blueprint";
import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { ISessionStorage, ISessionStorageOptions } from "./ISessionStorage";
import { ISessionStorageFactory } from "./ISessionStorageFactory";

/**
 * Session Storage Info
 */
export interface ISessionStorageInfo {
	/** Auth Provider Name */
	name: string;
	/** Auth Provider category */
	category: string;
	/** Auth label */
	label: string;
}

/**
 * Session Storage Definition
 */
export interface ISessionStorageDefinition<TOptions, TClass> extends ISessionStorageInfo {
	/** Session Storage factory */
	factory: ISessionStorageFactory<TClass, TOptions>;
	/** Session Storage parameters schema */
	optionsSchema: TGenericBlueprintSchema;
}

/**
 * Generic Session Storage Definition
 */
export type TGenericSessionStorageDefinition<
	TOptions = ISessionStorageOptions,
	TClass = ISessionStorage
	> = ISessionStorageDefinition<TOptions, TClass>;

export class SessionStorageDefinitionRegistry extends ItemRegistry<TGenericSessionStorageDefinition>{ }