/**
 * Position enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum POSITION {
	RELATIVE = "relative",
	ABSOLUTE = "absolute",
	FIXED = "fixed"
}

export type POSITION_string = keyof typeof POSITION;

export const POSITION_default: POSITION_string = "RELATIVE";

export const POSITION_inherit = "INHERIT";

export const POSITION_resolution_default = {
	screenBase: {
		type: POSITION_default,
		value: {}
	},
	screenLarge: {
		type: POSITION_inherit,
		value: {}
	},
	screenTablet: {
		type: POSITION_inherit,
		value: {}
	},
	screenPhone: {
		type: POSITION_inherit,
		value: {}
	},
	print: {
		type: POSITION_inherit,
		value: {}
	}
};
