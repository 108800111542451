/**
 * Link schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstObject,
	ISchemaValue,
	ISchemaValueObjectOpts,
	TSchemaConstObjectProps,
	TSchemaConstObjectPropsSpec,
} from "@hexio_io/hae-lib-blueprint";
import { LINK_TARGET } from "../Enums/LINK_TARGET";

import { LINK_TARGET_default } from "../Enums/LINK_TARGET";
import { getValuesFromStringEnum } from "../Functions/enumHelpers";
import { termsEditor } from "../terms";
import { LinkLocationSchema } from "./LinkLocation";

/**
 * Link props
 */
export const LinkProps = {

	locationData: BP.Prop(LinkLocationSchema({
		defaultType: "NONE",
		constraints: {
			required: true
		}
	}), 0),

	target: BP.Prop(BP.Enum.String({
		...termsEditor.schemas.link.target,
		options: getValuesFromStringEnum(LINK_TARGET, termsEditor.schemas.link.targetValues),
		default: LINK_TARGET_default
	}), 10),

	enabled: BP.Prop(BP.Boolean({
		...termsEditor.schemas.link.enabled,
		default: true,
		fallbackValue: true,
		constraints: {
			required: true
		}
	}), 20),

	exact: BP.Prop(BP.Boolean({
		...termsEditor.schemas.link.exact,
		default: false,
		fallbackValue: false,
		constraints: {
			required: true
		}
	}), 30)

}

/**
 * Link props type
 */
export type TLinkProps = Partial<TSchemaConstObjectPropsSpec<typeof LinkProps>>;

/**
 * Link schema
 *
 * @param opts Options
 */
export function LinkSchema(
	opts: Partial<ISchemaValueObjectOpts<TSchemaConstObjectProps>> = { alias: "link" }
): ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>> {
	return BP.Object({
		...termsEditor.schemas.link.schema,
		...opts,
		props: {
			...LinkProps,
			...opts.props
		},
		editorOptions: {
			layoutType: "passthrough",
			...opts.editorOptions
		}
	});
}
