/**
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	ISchemaComponentListModel,
	ISchemaComponentModel,
	TGenericComponentInstance,
	Selection,
	ISelectionItem_Base
} from "@hexio_io/hae-lib-blueprint";
import { createEventEmitter, emitEvent, removeAllEventListeners, TSimpleEventEmitter, isNil } from "@hexio_io/hae-lib-shared";
import { IElementRelativeCoords } from "./IEditCommon";

/**
 * Selection item types
 */
export enum EDIT_SELECTION_ITEM_TYPE {
	COMPONENT = "component",
	COMPONENT_LIST = "componentList",
}

export interface ISelectionItem_Component extends ISelectionItem_Base {
	/** Item type */
	type: EDIT_SELECTION_ITEM_TYPE.COMPONENT;

	/** Component instance */
	cmpInstance: TGenericComponentInstance;

	/** Element dimensions */
	getCoords: TGetCoordsFn;
}

export interface ISelectionItem_ComponentList extends ISelectionItem_Base {
	/** Item type */
	type: EDIT_SELECTION_ITEM_TYPE.COMPONENT_LIST;
}

/**
 * Current component selection
 */
export type TEditSelectionItem =
	ISelectionItem_Component |
	ISelectionItem_ComponentList |
	ISelectionItem_Base;

/**
 * Function to return element dimensions
 */
export type TGetCoordsFn = () => IElementRelativeCoords;

/**
 * Hovered component info
 */
export interface IHoverItem {
	nodeId: number;
}

/**
 * Type guard for component selection
 * @param value
 */
export function isSelectionItemComponent(value: TEditSelectionItem): value is ISelectionItem_Component {
	return value?.type === EDIT_SELECTION_ITEM_TYPE.COMPONENT;
}

/**
 * Type guard for component list selection
 * @param value
 */
export function isSelectionItemComponentList(value: TEditSelectionItem): value is ISelectionItem_ComponentList {
	return value?.type === EDIT_SELECTION_ITEM_TYPE.COMPONENT_LIST;
}

/**
 * Class to manage editor selection
 */
export class EditSelection<
	TSelectionItem extends TEditSelectionItem = TEditSelectionItem
> extends Selection<TSelectionItem> {

	/** Hover change event */
	public onHoverChange: TSimpleEventEmitter<void> = createEventEmitter();

	/** When there's a try to select already selected item */
	public onSelectSelected = createEventEmitter<void>();

	/** Currently hovered item */
	private hoveredItem: IHoverItem = null;

	/**
	 * Returns if component instance were created by any of selected component models
	 *
	 * @param cmpInstance Component instance
	 */
	public isComponentSelected(cmpInstance: TGenericComponentInstance): boolean {

		if (!cmpInstance.modelNode || cmpInstance.isTemplated) {
			return false;
		}

		const index = this.findItemIndexByNodeId(cmpInstance.modelNode.nodeId);

		return isSelectionItemComponent(this.items[index]);

	}

	/**
	 * Returns if component instance were created by any of selected component models.
	 * This version also updates cmpInstance reference if selected.
	 *
	 * @param cmpInstance Component instance
	 */
	public isComponentSelectedWithRefUpdate(cmpInstance: TGenericComponentInstance): boolean {

		if (!cmpInstance.modelNode || cmpInstance.isTemplated) {
			return false;
		}

		const index = this.findItemIndexByNodeId(cmpInstance.modelNode.nodeId);

		const item = this.items[index];

		if (isSelectionItemComponent(item)) {
			item.cmpInstance = cmpInstance;
			return true;
		} else {
			return false;
		}

	}

	public isComponentHovered(modelNode: ISchemaComponentModel): boolean {
		return !isNil(this.hoveredItem?.nodeId) && this.hoveredItem?.nodeId === modelNode?.nodeId;
	}

	/**
	 * Sets component as hovered.
	 * Return if component is set as hovered or not.
	 *
	 * @param modelNode Component model node
	 */
	public setHoveredComponent(modelNode: ISchemaComponentModel): boolean {

		if (!modelNode) {
			return false;
		}

		const isHovered = this.isComponentHovered(modelNode);

		if (isHovered) {
			return true;
		}

		this.hoveredItem = {
			nodeId: modelNode.nodeId
		};

		emitEvent(this.onHoverChange);

		return true;

	}


	/**
	 * Unsets component as hovered.
	 * Return if component is set as hovered or not.
	 *
	 * @param modelNode Component model node
	 */
	public unsetHoveredComponent(modelNode: ISchemaComponentModel): boolean {

		if (!modelNode) {
			return false;
		}

		const isHovered = this.isComponentHovered(modelNode);

		if (!isHovered) {
			return false;
		}

		this.hoveredItem = null;

		emitEvent(this.onHoverChange);

		return false;

	}


	/**
	 * Selects component model that creates the component instance
	 * Return is component was (de)selected or not
	 *
	 * @param cmpInstance Component instance
	 * @param dimCb Dimension callback
	 */
	public selectComponent(cmpInstance: TGenericComponentInstance, getCoords: TGetCoordsFn): boolean {

		if (cmpInstance.modelNode) {

			this.clearSelectionIfMixedType(EDIT_SELECTION_ITEM_TYPE.COMPONENT);

			const isSelected = this.isComponentSelected(cmpInstance);

			// In multiselect, de-selected the component, otherwise it's already selected so do nothing
			if (isSelected && this.multiSelect) {
				this.deselectComponent(cmpInstance);
				return true;
			} else if (isSelected) {
				emitEvent(this.onSelectSelected)
				return false;
			}

			const selItem = {
				type: EDIT_SELECTION_ITEM_TYPE.COMPONENT,
				nodeId: cmpInstance.modelNode.nodeId,
				cmpInstance: cmpInstance,
				getCoords: getCoords
			} as TSelectionItem;

			if (this.multiSelect) {
				this.items.push(selItem);
			} else {
				this.unselectAll();
				this.items = [ selItem ];
			}

			emitEvent(this.onSelectSingle, selItem);
			emitEvent(this.onChange);

			return true;

		} else {
			return false;
		}

	}

	/**
	 * Selects component model
	 *
	 * @param cmpModel Component model
	 */
	public selectComponentByModel(cmpModel: ISchemaComponentModel): boolean {

		if (this.isNodeSelected(cmpModel.nodeId)) {
			return;
		}

		this.clearSelectionIfMixedType(EDIT_SELECTION_ITEM_TYPE.COMPONENT);

		const selItem: TSelectionItem = {
			type: EDIT_SELECTION_ITEM_TYPE.COMPONENT,
			nodeId: cmpModel.nodeId,
			cmpInstance: null,
			getCoords: null
		} as TSelectionItem;

		this.unselectAll();
		this.items = [ selItem ];

		emitEvent(this.onSelectSingle, selItem);
		emitEvent(this.onChange);

	}

	/**
	 * Updates meta-data of already selected component
	 *
	 * @param cmpInstance Component instance
	 */
	public updateComponentMetaData(cmpInstance: TGenericComponentInstance, getCoords: TGetCoordsFn): void {

		if (cmpInstance.modelNode) {

			const index = this.findItemIndexByNodeId(cmpInstance.modelNode.nodeId);

			if (index >= 0 && this.items[index].type === EDIT_SELECTION_ITEM_TYPE.COMPONENT) {
				const item = this.items[index] as ISelectionItem_Component;
				item.cmpInstance = cmpInstance;
				item.getCoords = getCoords;
			}

		}

	}

	/**
	 * Deselected the component (if was selected) and returns if component selection changes
	 *
	 * @param cmpInstance Component instance
	 */
	public deselectComponent(cmpInstance: TGenericComponentInstance): boolean {

		if (!cmpInstance.modelNode || cmpInstance.isTemplated) {
			return false;
		}

		return this.deselectByNodeId(cmpInstance.modelNode.nodeId);

	}

	/**
	 * Returns if component list is selected
	 *
	 * @param cmpListModel Component list model
	 */
	public isComponentListSelected(cmpListModel: ISchemaComponentListModel): boolean {

		if (!cmpListModel) {
			return false;
		}

		const index = this.findItemIndexByNodeId(cmpListModel.nodeId);

		// @TODO: discuss if this isn't a mistake
		return index >= 0 && this.items[index].type === EDIT_SELECTION_ITEM_TYPE.COMPONENT;

	}

	/**
	 * Selects component list model
	 * Return is component list was (de)selected or not
	 *
	 * @param cmpListModel Component list model
	 */
	public selectComponentList(cmpListModel: ISchemaComponentListModel): boolean {

		if (!cmpListModel) {
			return false;
		}

		const isSelected = this.isComponentListSelected(cmpListModel);

		// Component list does not support multi select (makes no sense, we cannot manipulate with multiple component lists)
		if (isSelected) {
			this.deselectComponentList(cmpListModel);
			return true;
		}

		const selItem = {
			type: EDIT_SELECTION_ITEM_TYPE.COMPONENT_LIST,
			nodeId: cmpListModel.nodeId
		} as TSelectionItem;

		this.unselectAll();
		this.items = [ selItem ];

		emitEvent(this.onSelectSingle, selItem);
		emitEvent(this.onChange);

		return true;

	}

	/**
	 * Deselected the component list (if was selected) and returns if selection changes
	 *
	 * @param cmpListModel Component list model
	 */
	public deselectComponentList(cmpListModel: ISchemaComponentListModel): boolean {

		if (!cmpListModel) {
			return false;
		}

		return this.deselectByNodeId(cmpListModel.nodeId);

	}

	/**
	 * Destroys the selection
	 */
	public destroy(): void {

		super.destroy();
		removeAllEventListeners(this.onHoverChange);

	}

}
