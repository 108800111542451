/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/*
 * Note: Parser uses module variables (singleton pattern) because creating
 * parser instance each time may impact performance.
 */

import * as Moo from "moo";
import { DOC_ERROR_SEVERITY, IDocumentError, IDocumentErrorRelatedInformation } from "../Shared/IDocumentError";
import { EXP_PARSE_ERROR_NAME, TOKEN_KIND, lexerStatesDefault, lexerStatesStringTemplate } from "./ExpParserTypes";
import {
	IExpAstPosition,
	IExpAstRange,
	IExpAst_ArgumentList,
	IExpAst_BooleanLiteral,
	IExpAst_Comment,
	IExpAst_FunctionCall,
	IExpAst_Identifier,
	IExpAst_IndexAccessor,
	IExpAst_List,
	IExpAst_NullLiteral,
	IExpAst_NumberLiteral,
	IExpAst_Object,
	IExpAst_ObjectProperty,
	IExpAst_Operator,
	IExpAst_PrefixOperator,
	IExpAst_StringLiteral,
	IExpAst_StringTemplate,
	IExpAst_ValueReference,
	NODE_KIND,
	OPERATOR_TYPE,
	PREFIX_OPERATOR_TYPE,
	TExpAst_Expression
} from "./ExpAst";

export interface IExpParseResult {
	ast: TExpAst_Expression;
	errors: IDocumentError[];
	source: string;
	trace?: string[];
}

export enum EXP_PARSER_MODE {
	DEFAULT = "default",
	STRING_TEMPLATE = "stringTemplate"
}

interface IExpError {
	severity: DOC_ERROR_SEVERITY;
	name: string;
	message: string;
	relatedInformation?: Array<IDocumentErrorRelatedInformation>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	metaData?: { [K: string]: any; }
}

interface IParseBoundary {
	tokenIndex: number;
	errors: IDocumentError[];
}


const enableTrace = true;

let lexer: Moo.Lexer = Moo.states(lexerStatesDefault);

let parserMode = EXP_PARSER_MODE.DEFAULT;
let tokenBuffer: Moo.Token[];
let currentTokenIndex: number;
let currentToken: Moo.Token;
let currentErrors: IDocumentError[];

let parseBoundaries: IParseBoundary[];
let parseTrace: string[];

/**
 * Resets parser state
 */
function resetState() {
	tokenBuffer = [];
	currentTokenIndex = -1;
	currentToken = null;
	currentErrors = [];
	parseBoundaries = [];
	parseTrace = [];
}

function trace(value: string) {
	if (enableTrace) {
		parseTrace.push(value);
	}
}

/**
 * Begins try block
 * Stores current parser state which can be then restored.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function beginTry(): void {
	trace("beginTry");

	parseBoundaries.push({
		tokenIndex: currentTokenIndex,
		errors: currentErrors
	});
}

/**
 * Finishes try block. Can either accept current state or restore the one before beginTry
 *
 * @param acceptState If to accept current state (or return to previous one)
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function finishTry(acceptState: boolean): void {
	trace("finishTry");

	const prevBoundary = parseBoundaries.pop();

	if (!prevBoundary) {
		throw new Error(`Trying to finish un-started try block.`);
	}

	if (acceptState) {
		currentErrors = prevBoundary.errors.concat(currentErrors);
	} else {
		currentTokenIndex = prevBoundary.tokenIndex;
		currentToken = tokenBuffer[currentTokenIndex] || null;
		currentErrors = prevBoundary.errors;
	}
}

/**
 * Tries to read a next token
 * @returns Returns if a next token was available and thus added to a buffer
 */
function readToken() {
	const nextToken = lexer.next();

	if (nextToken) {
		tokenBuffer.push(nextToken);
		return true;
	}

	return false;
}

/**
 * Sets current token
 *
 * @param tokenIndex Token index
 * @returns If token index is valid
 */
function setCurrentToken(tokenIndex: number) {
	if (tokenIndex >= tokenBuffer.length) {
		currentTokenIndex = tokenBuffer.length;
		currentToken = null;
		return false;
	} else {
		currentTokenIndex = tokenIndex;
		currentToken = tokenBuffer[currentTokenIndex];
		return true;
	}
}

/**
 * Return current token
 */
function getToken(): Moo.Token {
	return currentToken;
}

/**
 * Returns previous token
 */
function getPrevToken(): Moo.Token {
	return tokenBuffer[currentTokenIndex - 1] || null;
}

/**
 * Returns a token at given index or null if not exists
 * @param tokenIndex Token index
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getTokenAtIndex(tokenIndex: number) {
	return tokenBuffer[tokenIndex] || null;
}

/**
 * Returns current token kind
 */
function getTokenKind(): TOKEN_KIND {
	return currentToken !== null ? currentToken.type as TOKEN_KIND : null;
}

/**
 * Moves cursor to a next token
 */
function nextToken(): void {
	if (tokenBuffer.length <= currentTokenIndex + 1) {
		readToken();
	}

	setCurrentToken(currentTokenIndex + 1);
}

/**
 * Creates position object from a token
 *
 * @param token Token
 */
export function tokenToPosition(token: Moo.Token) : IExpAstPosition {

	return token ? {
		line: token.line - 1,
		col: token.col - 1,
		offset: token.offset
	} : {
		line: 0,
		col: 0,
		offset: token.offset
	};

}

/**
 * Creates range object from a token
 *
 * @param token Token
 * @param token Optional end token
 */
function tokenToRange(token: Moo.Token, endToken?: Moo.Token) : IExpAstRange {

	const startTokenPos = tokenToPosition(token);
	const endTokenPos = endToken ? tokenToPosition(endToken) : null;

	const endPos = endToken ? {
		line: endTokenPos.line,
		col: endTokenPos.col + endToken.text.length,
		offset: endToken.offset + endToken.text.length
	} : {
		line: startTokenPos.line,
		col: startTokenPos.col + token.text.length,
		offset: token.offset + token.text.length
	};

	return {
		start: startTokenPos,
		end: endPos
	}

}

/**
 * Returns current token position
 */
function getCurrentPosition(): IExpAstPosition {
	const currToken = getToken();
	const prevToken = getPrevToken();

	if (currToken) {
		return tokenToPosition(currToken);
	} else if (prevToken) {
		const pos = tokenToPosition(prevToken);
		pos.col += prevToken.text.length;
		return pos;
	} else {
		return tokenToPosition(null);
	}
}

/**
 * Reports an expression parse error at current token
 *
 * @param error Expression error
 */
function reportErrorAtCurrentToken(error: IExpError): void {
	const reportToken = getToken() || getPrevToken();

	currentErrors.push({
		...error,
		range: tokenToRange(reportToken)
	});
}

/**
 * Converts token kind to a string representation
 * @param kind Token kind
 */
function tokenKindToString(kind: TOKEN_KIND) {
	return String(kind);
}

/**
 * Parses expected token. If token does not match an error is reported.
 *
 * @param kind Expected token kind
 * @param error Custom error
 * @param shouldAdvance If should advance to a next token when match
 * @returns If token has matched
 */
function parseExpected(kind: TOKEN_KIND, error: IExpError = null, shouldAdvance = true): boolean {
	const tokenKind = getTokenKind();

	if (tokenKind === kind) {
		trace("expect:" + kind);
		if (shouldAdvance) {
			nextToken();
		}
		return true;
	}

	trace("!!expect:" + kind + ":" + tokenKind);

	if (error) {
		reportErrorAtCurrentToken(error);
	} else if (tokenKind) {
		reportErrorAtCurrentToken({
			severity: DOC_ERROR_SEVERITY.ERROR,
			message: `Unexpected token '${getToken().text}'. Was expecting ${tokenKindToString(kind)}.`,
			name: EXP_PARSE_ERROR_NAME.UNEXPECTED_TOKEN,
			metaData: {
				// @todo from terms
				translationTerm: "expression.errors.unexpectedToken",
				args: {
					currentTokenText: getToken().text,
					expectedTokenKind: tokenKindToString(kind)
				},
			}
		});
	} else {
		reportErrorAtCurrentToken({
			severity: DOC_ERROR_SEVERITY.ERROR,
			message: `Unexpected end of input. Was expecting ${tokenKindToString(kind)}.`,
			name: EXP_PARSE_ERROR_NAME.UNEXPECTED_EOF,
			metaData: {
				// @todo add from terms
				translationTerm: "expression.errors.unexpectedEof",
				args: {
					expectedTokenKind: tokenKindToString(kind)
				}
			}
		});
	}

	return false;
}

/**
 * Tries to parse token.
 *
 * @param kind Expected token kind
 * @param shouldAdvance If should advance to a next token when match
 * @returns If token has matched
 */
function parseOptional(kind: TOKEN_KIND, shouldAdvance = true): boolean {
	const tokenKind = getTokenKind();

	if (tokenKind === kind) {
		trace("opt*:" + kind + ":" + tokenKind);
		if (shouldAdvance) {
			nextToken();
		}
		return true;
	}

	trace("opt!:" + kind + ":" + tokenKind);

	return false;
}

function parseExpectedOneOfKind<TKinds extends Array<{ kind: TOKEN_KIND, parse: () => unknown, shouldAdvance: boolean }>>(
	kinds: TKinds, error: IExpError = null
): ReturnType<TKinds[number]["parse"]> {
	const tokenKind = getTokenKind();

	for (let i = 0; i < kinds.length; i++) {
		if (tokenKind === kinds[i].kind) {
			trace("oneOf*:" + kinds[i].kind + ":" + tokenKind);

			if (kinds[i].shouldAdvance) {
				nextToken();
			}

			return kinds[i].parse() as ReturnType<TKinds[number]["parse"]>;
		}
	}

	const kindList = kinds.map((k) => tokenKindToString(k.kind));

	trace("!!oneOf:" + kindList.join("|") + ":" + tokenKind);

	if (error) {
		reportErrorAtCurrentToken(error);
	} else if (tokenKind) {
		reportErrorAtCurrentToken({
			severity: DOC_ERROR_SEVERITY.ERROR,
			message: `Unexpected token '${getToken().text}'. Was expecting one of ${kindList.join(", ")}.`,
			name: EXP_PARSE_ERROR_NAME.UNEXPECTED_TOKEN,
			metaData: {
				// @todo from terms
				translationTerm: "expression.errors.unexpectedToken",
				args: {
					currentTokenText: getToken().text,
					expectedTokenKind: kindList
				},
			}
		});
	} else {
		reportErrorAtCurrentToken({
			severity: DOC_ERROR_SEVERITY.ERROR,
			message: `Unexpected end of input. Was expecting one of '${kindList.join(", ")}'.`,
			name: EXP_PARSE_ERROR_NAME.UNEXPECTED_EOF,
			metaData: {
				// @todo add from terms
				translationTerm: "expression.errors.unexpectedEof",
				args: {
					expectedTokenKind: kindList
				}
			}
		});
	}

	return null;
}

function parseOptionalOneOfKind<TKinds extends Array<{ kind: TOKEN_KIND, parse: () => unknown, shouldAdvance: boolean }>>(
	kinds: TKinds
): ReturnType<TKinds[number]["parse"]> {
	const tokenKind = getTokenKind();

	for (let i = 0; i < kinds.length; i++) {
		if (tokenKind === kinds[i].kind) {
			trace("oneOf*:" + kinds[i].kind + ":" + tokenKind);

			if (kinds[i].shouldAdvance) {
				nextToken();
			}

			return kinds[i].parse() as ReturnType<TKinds[number]["parse"]>;
		} else {
			trace("oneOf!:" + kinds[i].kind + ":" + tokenKind);
		}
	}

	return null;
}

/**
 * Parses expression
 * @param source Source code
 */
export function parse(source: string, mode = EXP_PARSER_MODE.DEFAULT): IExpParseResult {

	if (mode !== parserMode) {
		parserMode = mode;

		if (mode === EXP_PARSER_MODE.STRING_TEMPLATE) {
			lexer = Moo.states(lexerStatesStringTemplate);
		} else {
			lexer = Moo.states(lexerStatesDefault);
		}
	}

	resetState();
	lexer.reset(source);

	nextToken();

	let ast: TExpAst_Expression;

	if (mode === EXP_PARSER_MODE.STRING_TEMPLATE) {
		ast = parseRootStringTemplate();
	} else {
		ast = parseExpression();
	}

	// Eat final ws and comments
	parseCommentAndWs();

	// Check if there are some unexpected tokens left
	if (getToken() !== null) {
		reportErrorAtCurrentToken({
			severity: DOC_ERROR_SEVERITY.ERROR,
			message: `Unexpected token. Was expecting end of input or an operand.`,
			name: EXP_PARSE_ERROR_NAME.UNEXPECTED_TOKEN,
			metaData: {
				// @todo add from terms
				translationTerm: "expression.errors.expectingEofOrOperand",
				args: {
					expectedTokenKind: getTokenKind()
				}
			}
		});
	}

	return {
		ast: ast,
		errors: currentErrors,
		source: source,
		trace: parseTrace
	};

}

//
// --- Grammar Parsers ---
//

function eatWhiteSpaces() {
	while(getTokenKind() === TOKEN_KIND.WHITESPACE || getTokenKind() === TOKEN_KIND.NEWLINE) {
		nextToken();
	}
}

function skipUntilToken(kind: TOKEN_KIND): void {

	while (getToken()) {
		if (getTokenKind() === kind) {
			nextToken();
			break;
		}
		nextToken();
	}
}

function parseAsStringUntilToken(kind: TOKEN_KIND) {
	let value = "";

	while (getToken()) {
		if (getTokenKind() === kind) {
			nextToken();
			break;
		}

		value += String(getToken().value);
		nextToken();
	}

	return value;
}

function parseAsStringUntilNewLine(): string {
	return parseAsStringUntilToken(TOKEN_KIND.NEWLINE);
}

function parseCommentAndWs(): IExpAst_Comment {
	eatWhiteSpaces();
	const start = getCurrentPosition();

	if (parseOptional(TOKEN_KIND.COMMENT_SINGLINE)) {
		const value = parseAsStringUntilNewLine();
		const end = getCurrentPosition(); // we want position before ws
		eatWhiteSpaces();

		return {
			kind: NODE_KIND.COMMENT,
			value: value,
			range: { start, end }
		}
	}

	if (parseOptional(TOKEN_KIND.COMMENT_OPEN)) {
		const value = parseAsStringUntilToken(TOKEN_KIND.COMMENT_CLOSE);
		const end = getCurrentPosition();  // we want position before ws
		eatWhiteSpaces();

		return {
			kind: NODE_KIND.COMMENT,
			value: value,
			range: { start, end }
		}
	}

	return null;
}

function parseNullLiteral(): IExpAst_NullLiteral {
	const start = getCurrentPosition();

	if (parseExpected(TOKEN_KIND.NULL)) {
		const end = getCurrentPosition();

		return {
			kind: NODE_KIND.NULL_LITERAL,
			range: { start, end }
		};
	}

	return null;
}

function parseBooleanLiteral(): IExpAst_BooleanLiteral {
	const start = getCurrentPosition();

	return parseExpectedOneOfKind([
		{
			kind: TOKEN_KIND.TRUE,
			shouldAdvance: true,
			parse: () => {
				return {
					kind: NODE_KIND.BOOLEAN_LITERAL,
					value: true,
					range: { start, end: getCurrentPosition() }
				} as IExpAst_BooleanLiteral
			}
		},
		{
			kind: TOKEN_KIND.FALSE,
			shouldAdvance: true,
			parse: () => {
				return {
					kind: NODE_KIND.BOOLEAN_LITERAL,
					value: false,
					range: { start, end: getCurrentPosition() }
				} as IExpAst_BooleanLiteral
			}
		}
	]);
}

function parseStringLiteral(): IExpAst_StringLiteral {
	const start = getCurrentPosition();

	if (parseExpected(TOKEN_KIND.STRING_LITERAL)) {
		const end = getCurrentPosition();

		return {
			kind: NODE_KIND.STRING_LITERAL,
			value: getPrevToken().value,
			range: { start, end }
		};
	}

	return null;
}

function parseNumberLiteral(): IExpAst_NumberLiteral {
	const start = getCurrentPosition();

	if (parseExpected(TOKEN_KIND.NUMBER_LITERAL)) {
		const end = getCurrentPosition();

		return {
			kind: NODE_KIND.NUMBER_LITERAL,
			value: parseFloat(getPrevToken().value),
			range: { start, end }
		};
	}

	return null;
}

function parseStringTemplate(atRoot = false): IExpAst_StringTemplate {
	const start = getCurrentPosition();

	if (atRoot || parseExpected(TOKEN_KIND.STRING_TEMPLATE_BEGIN)) {

		const elements: Array<IExpAst_StringLiteral|TExpAst_Expression> = [];
		let elementStart = getCurrentPosition();
		let elementEnd = null;
		let lastValue = "";
		let wasEnded = false;

		while (getToken()) {
			const tokenKind = getTokenKind();

			// Skip escape
			if (tokenKind === TOKEN_KIND.ESCAPE) {
				nextToken();
				continue;
			}

			// Parse interpolation expression
			if (tokenKind === TOKEN_KIND.INTERPOLATION_BEGIN) {
				elements.push({
					kind: NODE_KIND.STRING_LITERAL,
					value: lastValue,
					range: { start: elementStart, end: getCurrentPosition() }
				});

				nextToken();
				elements.push(parseExpression());
				parseCommentAndWs();

				lastValue = "";

				if (parseExpected(TOKEN_KIND.BRACE_CLOSE)) {
					elementStart = getCurrentPosition();
					continue;
				} else {
					elementStart = getCurrentPosition();
					continue;
				}
			}

			// Parse template end
			if (tokenKind === TOKEN_KIND.STRING_TEMPLATE_END) {
				elementEnd = getCurrentPosition();
				nextToken();
				wasEnded = true;
				break;
			}

			lastValue += String(getToken().value);
			nextToken();
		}

		if (!atRoot && !wasEnded) {
			parseExpected(TOKEN_KIND.STRING_TEMPLATE_END);
		}

		// Add final element
		elements.push({
			kind: NODE_KIND.STRING_LITERAL,
			value: lastValue,
			range: { start: elementStart, end: elementEnd }
		});

		const end = getCurrentPosition();

		return {
			kind: NODE_KIND.STRING_TEMPLATE,
			elements: elements,
			range: { start, end }
		};
	}

	return null;
}

function parseIdentifier(startKind: TOKEN_KIND.IDENTIFIER|TOKEN_KIND.IDENTIFIER_LITERAL): IExpAst_Identifier {
	const start = getCurrentPosition();

	if (parseExpected(startKind)) {
		return {
			kind: NODE_KIND.IDENTIFIER,
			value: getPrevToken().value,
			range: { start, end: getCurrentPosition() }
		};
	} else {
		return null;
	}
}

function parseReference(
	startKind: TOKEN_KIND.IDENTIFIER|TOKEN_KIND.IDENTIFIER_LITERAL
): TExpAst_Expression {
	const start = getCurrentPosition();

	if (parseExpected(startKind)) {

		const identifierNode: IExpAst_Identifier = {
			kind: NODE_KIND.IDENTIFIER,
			value: getPrevToken().value,
			range: { start, end: getCurrentPosition() }
		};

		const refNode: IExpAst_ValueReference =  {
			kind: NODE_KIND.VALUE_REF,
			identifier: identifierNode,
			range: { start, end: getCurrentPosition() }
		};

		const indexAccessorOrFn = parseIndexAccessorOrFunction(refNode);

		if (indexAccessorOrFn) {
			return indexAccessorOrFn;
		} else {
			return refNode;
		}
	}

	return null;
}

function parseDotIndexAccessor(
	baseReference: TExpAst_Expression,
	startKind: TOKEN_KIND.IDENTIFIER|TOKEN_KIND.IDENTIFIER_LITERAL
) {
	const start = getCurrentPosition();

	if (parseExpected(startKind)) {
		const end = getCurrentPosition();

		const resNode: IExpAst_IndexAccessor = {
			kind: NODE_KIND.INDEX_ACCESSOR,
			baseReference: baseReference,
			index: {
				kind: NODE_KIND.IDENTIFIER,
				value: getPrevToken().value,
				range: { start, end }
			},
			range: { start: baseReference.range.start, end }
		};

		// Try another index accessor
		const nestedIndexAccessor = parseIndexAccessorOrFunction(resNode);

		if (nestedIndexAccessor) {
			return nestedIndexAccessor;
		} else {
			return resNode;
		}
	}

	return null;
}

function parseArgumentList(): IExpAst_ArgumentList {
	const start = getCurrentPosition();
	const args: TExpAst_Expression[] = [];
	let end = getCurrentPosition();

	// If not closed (without args)
	if (!parseOptional(TOKEN_KIND.PARAN_CLOSE)) {
		while (getToken()) {
			// Expect expression
			args.push(parseExpression());
			parseCommentAndWs(); // Eat remaining comments and white spaces

			// If next arg
			if (parseOptional(TOKEN_KIND.COMMA)) {
				continue;
			} else {
				end = getCurrentPosition();

				if (!parseExpected(TOKEN_KIND.PARAN_CLOSE)) {
					skipUntilToken(TOKEN_KIND.PARAN_CLOSE);
				}

				break;
			}
		}
	}

	return {
		kind: NODE_KIND.ARGUMENT_LIST,
		arguments: args,
		range: { start, end }
	};
}

function parseIndexAccessorOrFunction(
	baseReference: TExpAst_Expression
): TExpAst_Expression {

	// Identifier-based index accessor
	if (parseOptional(TOKEN_KIND.DOT)) {
		return parseExpectedOneOfKind([
			{
				kind: TOKEN_KIND.IDENTIFIER,
				parse: () => parseDotIndexAccessor(baseReference, TOKEN_KIND.IDENTIFIER),
				shouldAdvance: false
			},
			{
				kind: TOKEN_KIND.IDENTIFIER_LITERAL,
				parse: () => parseDotIndexAccessor(baseReference, TOKEN_KIND.IDENTIFIER_LITERAL),
				shouldAdvance: false
			},
		]);
	}

	// Expression-based index accesor
	if (parseOptional(TOKEN_KIND.BRACKET_OPEN)) {
		const indexExp = parseExpression();
		parseCommentAndWs();

		if (!parseExpected(TOKEN_KIND.BRACKET_CLOSE)) {
			skipUntilToken(TOKEN_KIND.BRACKET_CLOSE);
		}

		const resNode: IExpAst_IndexAccessor = {
			kind: NODE_KIND.INDEX_ACCESSOR,
			baseReference: baseReference,
			index: indexExp,
			range: { start: baseReference.range.start, end: getCurrentPosition() }
		}

		// Try another index accessor
		const nestedIndexAccessor = parseIndexAccessorOrFunction(resNode);

		if (nestedIndexAccessor) {
			return nestedIndexAccessor;
		} else {
			return resNode;
		}
	}

	// Is function
	if (parseOptional(TOKEN_KIND.PARAN_OPEN)) {
		const argList = parseArgumentList();

		const resNode: IExpAst_FunctionCall = {
			kind: NODE_KIND.FUNCTION_CALL,
			reference: baseReference,
			argList: argList,
			range: { start: baseReference.range.start, end: getCurrentPosition() }
		}

		// Try another index accessor
		const nestedIndexAccessor = parseIndexAccessorOrFunction(resNode);

		if (nestedIndexAccessor) {
			return nestedIndexAccessor;
		} else {
			return resNode;
		}
	}

	return null;
}

function parseSubExpression(): TExpAst_Expression {
	if (parseExpected(TOKEN_KIND.PARAN_OPEN)) {
		const expNode = parseExpression();
		parseCommentAndWs();

		if (!parseExpected(TOKEN_KIND.PARAN_CLOSE)) {
			skipUntilToken(TOKEN_KIND.PARAN_CLOSE);
		}

		// Try another index accessor
		const nestedIndexAccessor = parseIndexAccessorOrFunction(expNode);

		if (nestedIndexAccessor) {
			return nestedIndexAccessor;
		} else {
			return expNode;
		}
	}

	return null;
}

function parseList(): IExpAst_List {
	const start = getCurrentPosition();
	const elements: TExpAst_Expression[] = [];

	parseExpected(TOKEN_KIND.BRACKET_OPEN);
	parseCommentAndWs(); // eat all whitespaces and comments

	// If not closed (without args)
	if (!parseOptional(TOKEN_KIND.BRACKET_CLOSE)) {
		while (getToken()) {
			// Expect expression
			elements.push(parseExpression());
			parseCommentAndWs(); // Eat remaining comments and white spaces

			// If next arg
			if (parseOptional(TOKEN_KIND.COMMA)) {
				continue;
			} else {
				if (!parseExpected(TOKEN_KIND.BRACKET_CLOSE)) {
					skipUntilToken(TOKEN_KIND.BRACKET_CLOSE);
				}
				break;
			}
		}
	}

	return {
		kind: NODE_KIND.LIST,
		elements: elements,
		range: { start, end: getCurrentPosition() }
	};
}

function parseObjectProperty(comment?: IExpAst_Comment): IExpAst_ObjectProperty {
	const start = getCurrentPosition();
	let spread = false;
	let key: TExpAst_Expression|IExpAst_Identifier;
	let value: TExpAst_Expression = null;

	if (parseOptional(TOKEN_KIND.SPREAD)) {
		key = null;
		spread = true;

		value = parseExpression();
	} else {

		// Property name as expression
		if (parseOptional(TOKEN_KIND.BRACKET_OPEN)) {
			key = parseExpression();

			if (!parseExpected(TOKEN_KIND.BRACKET_CLOSE)) {
				skipUntilToken(TOKEN_KIND.BRACKET_CLOSE);
			}
		// Literal
		} else {
			key = parseExpectedOneOfKind([
				{ kind: TOKEN_KIND.IDENTIFIER, parse: () => parseIdentifier(TOKEN_KIND.IDENTIFIER), shouldAdvance: false },
				{ kind: TOKEN_KIND.IDENTIFIER_LITERAL, parse: () => parseIdentifier(TOKEN_KIND.IDENTIFIER_LITERAL), shouldAdvance: false },
				{ kind: TOKEN_KIND.STRING_LITERAL, parse: parseStringLiteral, shouldAdvance: false },
				{ kind: TOKEN_KIND.NUMBER_LITERAL, parse: parseNumberLiteral, shouldAdvance: false }
			]);
		}

		// Separator
		eatWhiteSpaces();

		if (parseExpected(TOKEN_KIND.COLON)) {
			value = parseExpression();
		}
	}

	return {
		kind: NODE_KIND.OBJECT_PROPERTY,
		key: key,
		value: value,
		spread: spread,
		comment: comment,
		range: { start: start, end: getCurrentPosition() }
	};
}

function parseObject(): IExpAst_Object {
	const start = getCurrentPosition();
	const properties: IExpAst_ObjectProperty[] = [];
	let lastComment = null;

	parseExpected(TOKEN_KIND.BRACE_OPEN);
	lastComment = parseCommentAndWs(); // eat all whitespaces and comments

	// If not closed (without args)
	if (!parseOptional(TOKEN_KIND.BRACE_CLOSE)) {
		while (getToken()) {
			// Expect expression
			properties.push(parseObjectProperty(lastComment));
			parseCommentAndWs()

			// If next arg
			if (parseOptional(TOKEN_KIND.COMMA)) {
				lastComment = parseCommentAndWs(); // Eat remaining comments and white spaces
				continue;
			} else {
				if (!parseExpected(TOKEN_KIND.BRACE_CLOSE)) {
					skipUntilToken(TOKEN_KIND.BRACE_CLOSE);
				}
				break;
			}
		}
	}

	// @todo Report error on duplicate property names
	const propIndex: { [K: string]: IExpAst_ObjectProperty } = {};

	for (let i = 0; i < properties.length; i++) {
		const prop = properties[i];

		if (!prop.key || (
			prop.key.kind !== NODE_KIND.IDENTIFIER &&
			prop.key.kind !== NODE_KIND.STRING_LITERAL &&
			prop.key.kind !== NODE_KIND.NUMBER_LITERAL
		)) {
			continue;
		}

		const _key = String(prop.key.value);

		if (propIndex[_key] !== undefined) {
			reportErrorAtCurrentToken({
				severity: DOC_ERROR_SEVERITY.ERROR,
				message: `Duplicate object property '${_key}'.`,
				name: EXP_PARSE_ERROR_NAME.DUPLICATE_KEY,
				metaData: {
					// @todo from terms
					translationTerm: "expression.errors.duplicateKey",
					args: {
						key: _key
					}
				},
				relatedInformation: [{
					message: `Property was declared here.`,
					metaData: {
						// @todo from terms
						translationTerm: "expression.errors.propertyWasDeclaredHere",
					},
					location: {
						uri: null,
						range: propIndex[_key].range
					}
				}]
			});
		} else {
			propIndex[_key] = prop;
		}
	}

	return {
		kind: NODE_KIND.OBJECT,
		properties: properties,
		range: { start, end: getCurrentPosition() }
	};
}

function parseOperand(prevComment?: IExpAst_Comment): TExpAst_Expression {
	const comment = parseCommentAndWs();

	const res = parseExpectedOneOfKind([
		{ kind: TOKEN_KIND.IDENTIFIER, parse: () => parseReference(TOKEN_KIND.IDENTIFIER), shouldAdvance: false },
		{ kind: TOKEN_KIND.IDENTIFIER_LITERAL, parse: () => parseReference(TOKEN_KIND.IDENTIFIER_LITERAL), shouldAdvance: false },
		{ kind: TOKEN_KIND.PARAN_OPEN, parse: parseSubExpression, shouldAdvance: false },
		{ kind: TOKEN_KIND.NULL, parse: parseNullLiteral, shouldAdvance: false },
		{ kind: TOKEN_KIND.TRUE, parse: parseBooleanLiteral, shouldAdvance: false },
		{ kind: TOKEN_KIND.FALSE, parse: parseBooleanLiteral, shouldAdvance: false },
		{ kind: TOKEN_KIND.STRING_TEMPLATE_BEGIN, parse: parseStringTemplate, shouldAdvance: false },
		{ kind: TOKEN_KIND.STRING_LITERAL, parse: parseStringLiteral, shouldAdvance: false },
		{ kind: TOKEN_KIND.NUMBER_LITERAL, parse: parseNumberLiteral, shouldAdvance: false },
		{ kind: TOKEN_KIND.BRACKET_OPEN, parse: parseList, shouldAdvance: false },
		{ kind: TOKEN_KIND.BRACE_OPEN, parse: parseObject, shouldAdvance: false }
	]);

	if (res) {
		res.comment = comment || prevComment;
	}

	return res;
}

function parsePrefixOperator(type: PREFIX_OPERATOR_TYPE, comment: IExpAst_Comment): IExpAst_PrefixOperator {
	const opStart = tokenToPosition(getPrevToken());
	const opEnd = getCurrentPosition();

	const right = parsePrefixOperatorOrOperand();

	return {
		kind: NODE_KIND.PREFIX_OPERATOR,
		type: type,
		right: right,
		comment: comment,
		opRange: { start: opStart, end: opEnd },
		range: { start: opStart, end: getCurrentPosition() }
	};

}

function parsePrefixOperatorOrOperand(): TExpAst_Expression {	
	const comment = parseCommentAndWs();

	const prefixOperator = parseOptionalOneOfKind([
		{ kind: TOKEN_KIND.NOT, parse: () => parsePrefixOperator(PREFIX_OPERATOR_TYPE.NOT, comment), shouldAdvance: true },
		{ kind: TOKEN_KIND.PLUS, parse: () => parsePrefixOperator(PREFIX_OPERATOR_TYPE.ART_PLUS, comment), shouldAdvance: true },
		{ kind: TOKEN_KIND.MINUS, parse: () => parsePrefixOperator(PREFIX_OPERATOR_TYPE.ART_MINUS, comment), shouldAdvance: true },
	]);

	if (prefixOperator) {
		return prefixOperator;
	} else {
		return parseOperand(comment);
	}
}

function parseOperator(type: OPERATOR_TYPE, left: TExpAst_Expression, comment: IExpAst_Comment): TExpAst_Expression {
	const opStart = tokenToPosition(getPrevToken());
	const opEnd = getCurrentPosition();

	// Check for non-bounded operator (plus / minus, and, or)
	if (
		type === OPERATOR_TYPE.ART_PLUS ||
		type === OPERATOR_TYPE.ART_MINUS ||
		type === OPERATOR_TYPE.LOGIC_AND ||
		type === OPERATOR_TYPE.LOGIC_OR
	) {
		const right = parseExpression();

		return {
			kind: NODE_KIND.OPERATOR,
			type: type,
			left: left,
			right: right,
			comment: comment,
			opRange: { start: opStart, end: opEnd },
			range: { start: left.range.start, end: getCurrentPosition() }
		};
	} else {
		const right = parsePrefixOperatorOrOperand();
		const opNode = {
			kind: NODE_KIND.OPERATOR,
			type: type,
			left: left,
			right: right,
			comment: comment,
			opRange: { start: opStart, end: opEnd },
			range: { start: left.range.start, end: getCurrentPosition() }
		} as IExpAst_Operator;

		return tryParseOperator(opNode);
	}
}

function tryParseOperator(left: TExpAst_Expression): TExpAst_Expression {
	const comment = parseCommentAndWs();
	
	const operator = parseOptionalOneOfKind([
		{ kind: TOKEN_KIND.PLUS, parse: () => parseOperator(OPERATOR_TYPE.ART_PLUS, left, comment), shouldAdvance: true },
		{ kind: TOKEN_KIND.MINUS, parse: () => parseOperator(OPERATOR_TYPE.ART_MINUS, left, comment), shouldAdvance: true },
		{ kind: TOKEN_KIND.MULTIPLY, parse: () => parseOperator(OPERATOR_TYPE.ART_MULTIPLY, left, comment), shouldAdvance: true },
		{ kind: TOKEN_KIND.DIVDE, parse: () => parseOperator(OPERATOR_TYPE.ART_DIVIDE, left, comment), shouldAdvance: true },
		{ kind: TOKEN_KIND.MODULO, parse: () => parseOperator(OPERATOR_TYPE.ART_MODULO, left, comment), shouldAdvance: true },

		{
			kind: TOKEN_KIND.LOWER_THAN_EQL,
			parse: () => parseOperator(OPERATOR_TYPE.CMP_LOWER_THAN_EQL, left, comment),
			shouldAdvance: true
		},
		{
			kind: TOKEN_KIND.LOWER_THAN,
			parse: () => parseOperator(OPERATOR_TYPE.CMP_LOWER_THAN, left, comment),
			shouldAdvance: true
		},
		{
			kind: TOKEN_KIND.GREATER_THAN_EQL,
			parse: () => parseOperator(OPERATOR_TYPE.CMP_GREATER_THAN_EQL, left, comment),
			shouldAdvance: true
		},
		{
			kind: TOKEN_KIND.GREATER_THAN,
			parse: () => parseOperator(OPERATOR_TYPE.CMP_GREATER_THAN, left, comment),
			shouldAdvance: true
		},
		{
			kind: TOKEN_KIND.EQUAL,
			parse: () => parseOperator(OPERATOR_TYPE.CMP_EQUAL, left, comment),
			shouldAdvance: true
		},
		{
			kind: TOKEN_KIND.NOT_EQUAL,
			parse: () => parseOperator(OPERATOR_TYPE.CMP_NOT_EQUAL, left, comment),
			shouldAdvance: true
		},

		{ kind: TOKEN_KIND.OR, parse: () => parseOperator(OPERATOR_TYPE.LOGIC_OR, left, comment), shouldAdvance: true },
		{ kind: TOKEN_KIND.AND, parse: () => parseOperator(OPERATOR_TYPE.LOGIC_AND, left, comment), shouldAdvance: true },

		{ kind: TOKEN_KIND.AMP, parse: () => parseOperator(OPERATOR_TYPE.STR_CONCAT, left, comment), shouldAdvance: true }
	]);

	if (operator) {
		return operator;
	} else {
		return left;
	}
}

function parseExpression(): TExpAst_Expression {

	const left = parsePrefixOperatorOrOperand();

	if (left) {
		return tryParseOperator(left);
	} else {
		return null;
	}

}

function parseRootStringTemplate(): TExpAst_Expression {
	return parseStringTemplate(true);
}