/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TBlueprintIDTNodePath } from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaCreateOpts,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {assignParentToModelProps, cloneModelNode, createEmptySchema, createModelNode, destroyModelNode} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import { ISchemaValueStringOpts } from "./value/SchemaValueString";
import { TypeDescRecursive } from "../Shared/ITypeDescriptor";

/**
 * Function to get value schema which provides reference to recursive schema itself (local root)
 */
export type TSchemaRecursiveValueFunction<
	TValueSchema extends TGenericBlueprintSchema
> = (self: ISchemaRecursive<TValueSchema>) => TValueSchema;

/**
 * Schema model
 */
export interface ISchemaRecursiveModel<
	TValueSchema extends TGenericBlueprintSchema
> extends IModelNode<ISchemaRecursive<TValueSchema>> {
	value: TGetBlueprintSchemaModel<TValueSchema>;
}

/**
 * Schema options
 */
export interface ISchemaRecursiveOpts<
	TValueSchema extends TGenericBlueprintSchema
> extends ISchemaValueStringOpts {
	/** Value as a function which recieves self reference */
	value: (self: ISchemaRecursive<TValueSchema>) => TValueSchema;
}

/**
 * Schema type
 */
export interface ISchemaRecursive<
	TValueSchema extends TGenericBlueprintSchema,
> extends IBlueprintSchema<
	ISchemaRecursiveOpts<TValueSchema>,
	ISchemaRecursiveModel<TValueSchema>,
	TGetBlueprintSchemaSpec<TValueSchema>,
	TGetBlueprintSchemaDefault<TValueSchema>,
	TGetBlueprintSchemaCreateOpts<TValueSchema>
> { }

/**
 * Schema: Runtime Wrapper
 * Allows to specify custom render code
 *
 * @param opts Schema options
 */
export function SchemaRecursive<
	TValueSchema extends TGenericBlueprintSchema
>(
	opts: ISchemaRecursiveOpts<TValueSchema>
): ISchemaRecursive<TValueSchema> {

	type TValueModel = TGetBlueprintSchemaModel<TValueSchema>;

	let valueSchemaCache: TValueSchema;
	let compiledValidateCache: string;

	const schema = createEmptySchema<ISchemaRecursive<TValueSchema>>("recursive", opts);

	const getValueSchema = () => {

		if (valueSchemaCache) {
			return valueSchemaCache;
		} else {
			return valueSchemaCache = opts.value(schema);
		}

	};

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, ["value"])
	}

	const createModel = (
		dCtx: DesignContext,
		valueModel: TValueModel,
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx, parent,[], {
			value: valueModel
		});

		const model = assignParentToChildrenOf(modelNode)

		model.initRequiredValid = valueModel.initRequiredValid;

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue, createOpts) => {

		const valueModel = getValueSchema().createDefault(dCtx, null, defaultValue, createOpts as never) as TValueModel;
		return createModel(dCtx, valueModel, parent);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedValue = getValueSchema().clone(dCtx, modelNode.value, null) as TValueModel;

		const clone = cloneModelNode(dCtx, modelNode, parent,{
			value: clonedValue
		});

		return assignParentToChildrenOf(clone)
	}

	schema.destroy = (modelNode) => {

		getValueSchema().destroy(modelNode.value);
		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent, createOpts) => {

		const valueModel = getValueSchema().parse(dCtx, idtNode, null, createOpts as never) as TValueModel;
		return createModel(dCtx, valueModel, parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		const valueSchema = getValueSchema();

		if (valueSchema.provideCompletion) {
			valueSchema.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}

	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {

		return getValueSchema().serialize(modelNode.value, path);

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		return getValueSchema().render(rCtx, modelNode.value, path, scope, prevSpec);

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		return getValueSchema().compileRender(cCtx, modelNode.value, path);

	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {

		return getValueSchema().validate(rCtx, path, modelNodeId, value, validateChildren);

	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren, createOpts): string => {

		if (compiledValidateCache) {
			return compiledValidateCache;
		} else {
			compiledValidateCache = cCtx.addGlobalValue(`(v,pt)=>false`);
			const lastGlobal = cCtx.getLastGlobalValue();
			const validationCode = getValueSchema().compileValidate(cCtx, path, modelNodeId, validateChildren, createOpts as never);

			cCtx.replaceGlobalValue(lastGlobal.index, `(v,pt)=>(${validationCode})(v,pt)`);

			return compiledValidateCache;
		}

	};

	// Recursive schema value is defined by a function thus cannot be exported
	schema.export = (): ISchemaImportExport => {

		throw new Error("Recursive schema cannot be exported.");

	};

	schema.getTypeDescriptor = (modelNode) => {
		if(modelNode){
			return getValueSchema().getTypeDescriptor(modelNode.value);
		}else{
			return TypeDescRecursive(getValueSchema().opts);
		}
	};

	schema.getChildNodes = (modelNode) => {
		return [{
			key: "value",
			node: modelNode.value
		}];
	}

	return schema;

}
