/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, declareFunction, SCHEMA_CONST_ANY_VALUE_TYPE, Type } from "@hexio_io/hae-lib-blueprint";

export const objKeys = declareFunction({
	name: "OBJ_KEYS",
	category: "object",
	label: "Get Object Keys",
	description: "Returns an array of object keys",
	argRequiredCount: 1,
	argSchemas: [
		BP.Map({
			label: "Value",
			constraints: {
				required: true
			},
			value: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			})
		}),
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {

		const v = args[0]();

		if (!(v instanceof Object)) {
			throw new Error("Value must be an object.");
		}

		return Object.keys(v);

	}
});

export const objSetKey = declareFunction({
	name: "OBJ_SET_KEY",
	category: "object",
	label: "Set object key",
	description: "Sets an object key to a given value and returns a modified object. Does not modify original object in place.",
	argRequiredCount: 3,
	argSchemas: [
		BP.Map({
			label: "Object",
			constraints: {
				required: true
			},
			value: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			})
		}),
		BP.String({
			label: "Key",
			constraints: {
				required: true
			}
		}),
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {

		const o = args[0]();
		const k = args[1]();
		const v = args[2]();

		if (!(o instanceof Object)) {
			throw new Error("First argument must be an object.");
		}

		return {
			...o,
			[k]: v
		};

	}
});

export const objDeleteKey = declareFunction({
	name: "OBJ_DELETE_KEY",
	category: "object",
	label: "Delete object key",
	description: "Deletes an object key and returns a modified object. Does not modify original object in place.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Map({
			label: "Object",
			constraints: {
				required: true
			},
			value: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			})
		}),
		BP.String({
			label: "Key",
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {

		const o = args[0]();
		const k = args[1]() as string;

		if (!(o instanceof Object)) {
			throw new Error("First argument must be an object.");
		}

		const r = { ...o };
		delete r[k];

		return r;

	}
});


export const propsToArray = declareFunction({
	name: "PROPS_TO_ARRAY",
	category: "object",
	label: "Convert object props to array",
	description: "Convert object props to array of objects with key value props",
	argRequiredCount: 1,
	argSchemas: [
		BP.Map({
			label: "Value",
			constraints: {
				required: true
			},
			value: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			})
		}),
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {

		const obj = args[0]();

		if (!(obj instanceof Object)) {
			throw new Error("Value must be an object.");
		}

		const keys: string[] = Object.keys(obj)

		const arr = []

		keys.map(item => {
			arr.push({
				"key": item,
				"value": obj[item]
			})
		})

		return arr;

	}
});

export const objFromArray = declareFunction({
	name: "OBJ_FROM_ARRAY",
	category: "object",
	label: "Creates an object from an array",
	description: "Converts key/value pairs in a provided array into an object.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Array({
			label: "Value",
			constraints: {
				required: true
			},
			items: BP.Object({
				label: "Property",
				props: {
					key: BP.Prop(BP.String({
						label: "Key"
					})),
					value: BP.Prop(BP.Any({
						label: "Value",
						defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
					}))
				}
			})
		}),
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {

		const props = (args[0]() || []) as { key: string, value: unknown }[];
		const r = {};

		for (let i = 0; i < props.length; i++) {
			r[props[i].key] = props[i].value;
		}

		return r;

	}
});
