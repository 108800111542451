/**
 * Z-index enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum Z_INDEX {
	AUTO = "auto",
	UNDER = "under",
	BASE = "base",
	ABOVE = "above",
	TOP = "top"
}

export type Z_INDEX_string = keyof typeof Z_INDEX;

export const Z_INDEX_default: Z_INDEX_string = "AUTO";
