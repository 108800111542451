/**
 * Hexio App Engine Shared library.
 *
 * @package hae-lib-shared
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import lodashMerge from "lodash.merge";

export function mergeDeep(target: unknown, source: unknown): unknown {
	return lodashMerge(target, source);
}