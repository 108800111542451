/**
 * CSS units
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const cssLengthUnits = "%|cap|ch|em|ex|ic|lh|rem|rlh|px|cm|mm|in|pc|pt|vw|vh|vi|vb|vmin|vmax";

export const cssBaseLengthUnits = "%|px";
