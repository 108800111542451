/**
 * Composed Chart HAE component Create Legend
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { THAEComponentComposedChart_Props } from "./props";

/**
 * Creates legend
 */
export function createLegend(
	legend: THAEComponentComposedChart_Props["legend"],
	root: am5.Root,
	chart: am5xy.XYChart,
	spacing: number
): void {
	if (!legend) {
		return null;
	}

	const chartLegend = chart.children.push(am5.Legend.new(root, {
		x: am5.p50,
		centerX: am5.p50,
		marginTop: spacing,
		cursorOverStyle: "pointer"
	}))

	chartLegend.data.setAll(chart.series.values);
}
