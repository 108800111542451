/**
 * Tree Navigation Item Expand enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum TREE_NAVIGATION_ITEM_EXPAND {
	DEFAULT = "default",
	EXPANDED = "expanded",
	COLLAPSED = "collapsed"
}

export type TREE_NAVIGATION_ITEM_EXPAND_string = keyof typeof TREE_NAVIGATION_ITEM_EXPAND;
