/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import { ErrorTrackerIssueReport, IBreadcrumb, IErrorTrackerConfig } from "./ErrorTrackerTypes";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration, Offline as OfflineIntegration } from "@sentry/integrations";
import { processReactBreadcrumb } from "./breadcrumbProcessors/processReactBreadcrumb";
import { getEnv, getReleaseVersion } from "@hexio_io/hae-lib-shared";
import { AppContext, CaptureContext, Context } from "@sentry/types";
import SentryRRWeb from "@sentry/rrweb";

export class ErrorTracker {
	private static instance: ErrorTracker;
	private readonly dsn: string;
	private readonly environment = getEnv();
	private readonly release = getReleaseVersion();
	private readonly disabled: boolean = false;

	constructor(config: IErrorTrackerConfig) {
		if (ErrorTracker.instance) {
			console.error(
				`ErrorTracker was already initialised. Use ErrorTracker.getInstance() instead of new ErrorTracker()`
			);
			return;
		}

		this.dsn = config.dsn;
		this.disabled = !!config.disabled;

		ErrorTracker.instance = this;

		this.initReactTracker();
	}

	public static getInstance(): ErrorTracker {
		if (!ErrorTracker.instance) {
			console.error(
				`ErrorTracker was not initialised. Use new ErrorTracker() before ErrorTracker.getInstance()`
			);
			return;
		}

		return ErrorTracker.instance;
	}

	private initReactTracker(): void {
		Sentry.init({
			dsn: this.dsn,
			enabled: !this.disabled,
			environment: this.environment,
			release: this.release,
			normalizeDepth: 6,
			integrations: [
				new Integrations.BrowserTracing(),
				new CaptureConsoleIntegration({
					levels: ["error"]
				}),
				new OfflineIntegration(),
				new SentryRRWeb()
			],
			tracesSampleRate: 0.5,
			beforeBreadcrumb: processReactBreadcrumb,
			ignoreErrors: [
				"ResizeObserver loop limit exceeded",
				"Text content does not match server-rendered HTML.",
				"Hydration failed because the initial UI does not match what was rendered on the server.",
				/while hydrating/,
				/Failed to authenticate/,
				/authInvalidToken/,
				/Unauthorized/,
				/authNotLoggedIn/,
				/ServiceUnavailable/,
				/Failed to load session/,
				/Failed to authenticate session/,
				/REQUEST_FAILED/
			]
		});
	}

	public setUser(user: Sentry.User): void {
		Sentry.setUser(user);
	}

	public clearUser(): void {
		Sentry.setUser(null);
	}

	public setApp(app: AppContext): void {
		Sentry.setContext("app", app);
	}

	public setState(state: Context): void {
		Sentry.setContext("state", state);
	}

	public addBreadcrumb({
		type = "debug",
		level = "info",
		event_id,
		category,
		message,
		data,
		timestamp
	}: IBreadcrumb): void {
		Sentry.addBreadcrumb({
			type,
			level: level,
			event_id,
			category,
			message,
			data,
			timestamp: timestamp || (new Date()).getTime()
		});
	}

	public sendUserReport({ title, description, level }: ErrorTrackerIssueReport): void {
		Sentry.withScope(scope => {
			scope.setTag("user-report", true);
			scope.setLevel(level || "fatal");
			Sentry.captureMessage(`[User Report]: ${title}\n${description}`);
		});
	}

	public captureException(exception: any, captureContext?: CaptureContext): void {
		Sentry.captureException(exception, captureContext);
	}
}
