/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaFlowNodeTypeOutputDefinitionMap } from "@hexio_io/hae-lib-blueprint";
import { FLOW_NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintFlowNodeTypeTransform = {
	name: FLOW_NODE_TYPES.TRANSFORM,
	label: "Transform",
	icon: "mdi/language-javascript",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			code: BP.Prop(BP.String({
				label: termsEditor.nodes.transform.code.label,
				description: termsEditor.nodes.transform.code.description,
				allowInterpolation: false,
				allowTranslate: false,
				editorOptions: {
					controlType: "codeJavaScript"
				}
			}))
		}
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		onSuccess: { label: "On Success", icon: "mdi/check" },
		onError: { label: "On Error", icon: "mdi/alert" },
		onFail: { label: "On Fail", icon: "mdi/close" }
	})
};