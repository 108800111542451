/**
 * Activity Container
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

import { ACTIVITY_ITEM_STATE, IActivityItem } from "./IActivityItem";
import { isBrowser, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";

/**
 * Activity Container Manager interface
 */
export interface IActivityContainerManager<TActivityItem = unknown, TData = unknown> {
	/** On Update event */
	onUpdate: TSimpleEventEmitter<TActivityItem>;

	/** Add Item Data method */
	addItemData: (data: TData) => string;

	/** Return items method */
	getItems: () => TActivityItem[];

	/** Add item method */
	addItem: (data: TActivityItem) => void;

	/** Remove item method */
	removeItem: (id: string) => void;

	/** Remove all items method */
	removeAllItems: () => void;
}

/**
 * Removes item from local state
 */
function _removeItemFromState(
	id: string,
	setState: React.Dispatch<React.SetStateAction<IActivityItem[]>>
) {
	setState((prevItems) => {
		const result = prevItems.filter((prevItem) => prevItem.id !== id);

		return result;
	});
}

/**
 * Sets item as ready (state is set to default)
 */
function _setItemAsReady(
	id: string,
	setState: React.Dispatch<React.SetStateAction<IActivityItem[]>>
) {
	setState((prevItems) => {
		const result = [ ...prevItems ];
		const index = prevItems.findIndex((prevItem) => prevItem.id === id);

		if (index >= 0) {
			result[index].state = ACTIVITY_ITEM_STATE.DEFAULT;
		}

		return result;
	});
}

/**
 * Use Activity Container hook
 *
 * @param manager Container manager
*/
export function useActivityContainer<T extends IActivityItem>(manager: IActivityContainerManager<T>): [
	T[],
	(itemId: string) => void,
	(itemId: string, buttonId: string, removeFromState: boolean) => void
] {
	const [ items, setItems ] = React.useState<T[]>([]);

	const browser = isBrowser();

	React.useEffect(() => {
		if (!browser) {
			return;
		}

		const managerItems = manager.getItems();

		const newItems: T[] = [];
		const ids = [];

		let update = false;

		// First go through all items in state and see if any was removed in manager
		items.forEach((item) => {
			const existingItem = managerItems.find((managerItem) => managerItem.id === item.id);

			if (!existingItem) {
				update = update || item.state !== ACTIVITY_ITEM_STATE.REMOVED;

				newItems.push({
					...item,
					state: ACTIVITY_ITEM_STATE.REMOVED
				});
			}
			else {
				newItems.push(existingItem);
			}

			ids.push(item.id);
		});

		// Then add the rest of items from manager as the new ones
		managerItems.filter((item) => !ids.includes(item.id)).forEach((item) => {
			update = update || true;

			newItems.push({
				...item,
				state: ACTIVITY_ITEM_STATE.ADDED
			});
		});

		update = update || newItems.length !== items.length;

		if (update) {
			setItems(newItems);
		}
	});

	// On SSR, manager is not available
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const _removeItemFromManager = manager ? manager.removeItem.bind(manager) : () => {};

	/**
	 * Sets item as ready
	 *
	 * @param itemId Item id
	 */
	function setItemAsReady(itemId: string) {
		if (!browser) {
			return;
		}

		_setItemAsReady(itemId, setItems);
	}

	/**
	 * Removes the item
	 *
	 * @param itemId Item id
	 * @param buttonId Button id
	 * @param removeFromState Remove from state; first remove item from manager (triggers animation), then from state
	 */
	function removeItem(itemId: string, buttonId: string = null, removeFromState = false) {
		if (!browser) {
			return;
		}

		if (!removeFromState) {
			_removeItemFromManager(itemId, buttonId);
		}
		else {
			_removeItemFromState(itemId, setItems);
		}
	}

	return [ items, setItemAsReady, removeItem ];
}
