/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstAnyOpts, SchemaConstAny, ISchemaConstAny } from "../const/SchemaConstAny";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueAnyOpts extends ISchemaValueOpts<ISchemaConstAny>, ISchemaConstAnyOpts {}

/**
 * Schema: Boolean value
 *
 * @param opts Schema options
 */
export function SchemaValueAny(opts: ISchemaValueAnyOpts): ISchemaValue<ISchemaConstAny> {

	return SchemaValue(SchemaConstAny(opts), opts);

}