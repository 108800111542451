/**
 * Data Table HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	SORT_ORDER,
	Text,
	ClassList,
	StyleSheet,
	useLoading,
	LoadingInfo,
	ICON_NAME,
	getStringEnumKeyByValue,
	Pagination,
	SORT_ORDER_MODE,
	SORT_ORDER_MODE_values,
	useTranslate,
	Button,
	getStringEnumCssValue,
	SPACING,
	useStyleSheetRegistry
} from "@hexio_io/hae-lib-components";

import { COMPONENT_MODE } from "@hexio_io/hae-lib-blueprint";

import { HAEComponentDataTable_Definition } from "./definition";
import { resolveTableColumnsClassListsAndStyleSheet, resolveTableBorderStyleSheet } from "../Table/helpers";
import { DataTableCsvExport } from "./DataTableCsvExport";
import { termsRuntime } from "../../terms";
import { DataTableContent } from "./DataTableContent";
import { TableCellTitle } from "../Table/TableCellTitle";
import { DATA_TABLE_RESOLVED_ITEM_STATE, IResolvedItem } from "./state";
import { cloneDeep } from "@hexio_io/hae-lib-shared";

const HAEComponentDataTable_React: THAEComponentReact<typeof HAEComponentDataTable_Definition> = ({
	props, state, setState, componentInstance, reactComponentClassList
}) => {
	const { columns, borderData, spacing, paginationStyle, itemCount, csvExport, emptyText } = props;
	const { resolvedItems, totalItems, pageOrder, pagination, sort } = state;

	const { safePath: componentPath, componentMode } = componentInstance;

	const elementRef = React.useRef<HTMLDivElement>();
	const styleSheetRegistry = useStyleSheetRegistry();

	const t = useTranslate();

	const [ loading, loadingRef ] = useLoading(componentInstance.isLoading);

	const paginationEnabled = !!pagination;

	const empty = !resolvedItems.length && !loading;
	const headerDefined = columns.some((item) => !!item.titleTextValue);

	const options = itemCount || !!(csvExport && !empty);

	const sortEnabled = !!sort;
	const sortOrderValue = (sortEnabled && sort?.order) in SORT_ORDER ? SORT_ORDER[sort.order] : null;

	// Classlists and stylesheets

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-data-table",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	classList.addModifiers({
		filled: !empty,
		empty,
		loading,
		options,
		pagination: paginationEnabled
	});

	const { classList: tableClassList, idClassName: tableIdClassName } = ClassList.getElementClassListAndIdClassName(
		"table",
		componentPath,
		{ componentInstance }
	);

	tableClassList.add("cmp-data-table__table");

	tableClassList.addModifiers({
		border: borderData.type.toLowerCase()
	});

	const styleSheet = React.useMemo(() => {
		const result = resolveTableBorderStyleSheet(tableIdClassName, borderData);

		// Spacing

		if (spacing) {
			const spacingValue = getStringEnumCssValue(SPACING, spacing, "spacing-");

			result.addString(`.${idClassName}`, `--element-spacing: ${spacingValue};`);
		}

		return result;
	}, [ idClassName, tableIdClassName, borderData, spacing ]);

	const { columnsClassLists, columnsStyleSheet } = React.useMemo(() => {
		return resolveTableColumnsClassListsAndStyleSheet(columns, componentPath, componentMode);
	}, [ columns, componentPath, componentMode ]);

	const allStyleSheets = React.useMemo(() => {
		return new StyleSheet(...styleSheet, ...columnsStyleSheet);
	}, [ styleSheet.toString(), columnsStyleSheet.toString() ]);

	styleSheetRegistry.add(idClassName, allStyleSheets);

	// Event handlers

	const _paginationButtonClickHandler = React.useMemo(() => {
		if (!paginationEnabled) {
			return;
		}

		return (value: number) => {
			setState((prevState) => ({
				...prevState,
				pagination: {
					...prevState.pagination,
					page: value
				}
			}));
		};
	}, [ setState, paginationEnabled, componentMode ]);

	// Header

	const tableHeaderContent = headerDefined ?
		<thead className="table__header">
			<tr className="table__row">
				{
					columns.map((columnItem, columnIndex) => {
						if (!columnItem.render && componentMode === COMPONENT_MODE.NORMAL) {
							return null;
						}

						const columnKey = `header-column-${columnIndex}`;

						const { key, sortOrderMode } = columnItem;
						const sortOrderModeValue = SORT_ORDER_MODE[sortOrderMode];

						const columnSortEnabled = sortEnabled && sortOrderModeValue !== SORT_ORDER_MODE.NONE;

						let _sortButtonClickHandler: (newSortOrderValue: SORT_ORDER_MODE) => void;

						if (columnSortEnabled) {
							_sortButtonClickHandler = (newSortOrderValue) => {
								const newState = {
									...state,
									sort: {
										...state.sort,
										key,
										order: getStringEnumKeyByValue(SORT_ORDER, newSortOrderValue)
									}
								};

								if (paginationEnabled) {
									newState.pagination.page = 1;
								}

								setState(newState);
							}
						}

						let selectAllEnabled: boolean;
						let values: boolean[];
						let _selectAllChangeHandler: (checked: boolean) => void;

						if (columnItem.typeData.type === "BOOLEAN" && columnItem.editable) {
							selectAllEnabled = columnItem.typeData.value[columnItem.typeData.type].selectAll;

							if (selectAllEnabled) {
								values = resolvedItems.filter((item) => {
									return pageOrder.includes(item.id)
								}).map((item) => item.data[key].value as boolean);

								_selectAllChangeHandler = (checked) => {
									setState((prevState) => ({
										...prevState,
										resolvedItems: prevState.resolvedItems.map((item) => {
											let result: IResolvedItem;

											if (pageOrder.includes(item.id)) {
												result = cloneDeep(item);

												result.data[key].value = checked;
												result.state = DATA_TABLE_RESOLVED_ITEM_STATE.EDITED_BY_COMPONENT;
											}
											else {
												result = item;
											}

											return result;
										})
									}));
								}
							}
						}

						return (
							<th key={columnKey} className={columnsClassLists[columnIndex].toClassName()}>
								<TableCellTitle
									columnKey={key}
									textValue={columnItem.titleTextValue}
									sort={
										columnSortEnabled ?
											{
												key: sort.key,
												orderValue: sortOrderValue,
												orderModeValue: SORT_ORDER_MODE[sortOrderMode]
											} :
											undefined
									}
									selectAll={
										selectAllEnabled ?
											{
												values,
												onChange: _selectAllChangeHandler
											} :
											undefined
									}
									componentPath={[ ...componentPath, columnKey, "cell-title" ]}
									componentMode={componentMode}
									onButtonClick={_sortButtonClickHandler}
								/>
							</th>
						);
					})
				}
			</tr>
		</thead> :
		null;

	return (
		<div ref={elementRef} className={classList.toClassName()}>
			<div className="cmp-data-table__container">
				<div className="cmp-data-table__inner">
					{
						!empty || tableHeaderContent ?
							<table className={tableClassList.toClassName()}>
								{tableHeaderContent}

								<DataTableContent
									resolvedItems={resolvedItems}
									order={pageOrder}
									columns={columns}
									columnsClassLists={columnsClassLists}
									componentInstance={componentInstance}
									setResolvedItemValue={(editableCell, value) => {
										setState((prevState) => ({
											...prevState,
											resolvedItems: prevState.resolvedItems.map((item) => {
												let result: IResolvedItem;

												if (editableCell.id === item.id) {
													result = cloneDeep(item);

													result.data[editableCell.key].value = value;
													result.state = DATA_TABLE_RESOLVED_ITEM_STATE.EDITED_BY_USER;
												}
												else {
													result = item;
												}

												return result;
											}),
											sort: prevState?.sort ? {
												...prevState.sort,
												key: ""
											} : null,
										}));
									}}
								/>
							</table> :
							null
					}

					{
						loading ?
							<div ref={loadingRef} className="state-info">
								<LoadingInfo />
							</div> :
							null
					}

					{
						empty && emptyText && emptyText.value ?
							<Text
								{...emptyText}
								componentPath={[ ...componentPath, "empty-text" ]}
								componentMode={componentMode}
								classList={new ClassList("cmp-data-table__empty-text")}
							/> :
							null
					}
				</div>
			</div>

			{
				options ?
					<div className="cmp-data-table__options">
						{
							itemCount ?
								<div className="cmp-data-table__options-total">
									{`${t("runtime", termsRuntime.components.dataTable.items.label)}: ${totalItems}`}
								</div> :
								null
						}

						{
							(csvExport && !empty) ?
								<div className="cmp-data-table__options-export">
									<DataTableCsvExport
										items={resolvedItems}
										fileName={`export_${componentInstance.id}`}
										header={csvExport.header ? columns.map((item) => item.titleTextValue) : undefined}
										componentPath={[ ...componentPath, "csv-export" ]}
										componentMode={componentMode}
									/>
								</div> :
								null
						}
					</div> :
					null
			}

			{
				paginationEnabled ?
					<Pagination
						items={totalItems}
						limit={pagination.limit}
						page={pagination.page}
						maxDisplayedPages={pagination.maxDisplayedPages}
						{...paginationStyle}
						componentPath={[ ...componentPath, "pagination" ]}
						componentMode={componentMode}
						classList={new ClassList("cmp-data-table__pagination")}
						onButtonClick={_paginationButtonClickHandler}
					/> :
					null
			}
		</div>
	);
};

export const HAEComponentDataTable: THAEComponentDefinition<typeof HAEComponentDataTable_Definition> = {
	...HAEComponentDataTable_Definition,
	reactComponent: HAEComponentDataTable_React
};
