/**
 * Overlay Manager
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createEventEmitter, emitEvent, offEvent, onEvent, TSimpleEventEmitter, getTimestamp } from "@hexio_io/hae-lib-shared";
import { IActivityContainerManager } from "./useActivityContainer";
import { TOverlay, IOverlayActivityItem, OVERLAY_TYPE, IOverlayCloseResult, IViewOverlayBase } from "./Overlay";
import { TChildViewFactoryFn } from "../RootComponentListSchema";

/**
 * Overlay Manager interface
 */
export interface IOverlayManager<TActivityItem, TData> extends IActivityContainerManager<TActivityItem, TData> {
	/** On Action event */
	onAction: TSimpleEventEmitter<IOverlayCloseResult>;

	/** Remove item method */
	removeItem: (id: string, buttonId?: string) => void;

	/** Item is resolved method */
	itemIsResolved: (id: string) => Promise<IOverlayCloseResult>;
}

/**
 * Overlay Manager class
 */

export class OverlayManager implements IOverlayManager<IOverlayActivityItem, TOverlay> {

	private items: IOverlayActivityItem[] = [];

	//private childViewFactory: TChildViewFactoryFn;

	public onUpdate: TSimpleEventEmitter<IOverlayActivityItem>;

	public onAction: TSimpleEventEmitter<IOverlayCloseResult>;

	/**
	 * Overlay Manager class constructor
	 */
	public constructor(
		private childViewFactory: TChildViewFactoryFn
	) {
		this.onUpdate = createEventEmitter();

		this.onAction = createEventEmitter();
	}

	/**
	 * Returns items
	 */
	public getItems(): IOverlayActivityItem[] {
		return this.items;
	}

	/**
	 * Adds item
	 * 
	 * @param activityItem Activity item
	 */
	public addItem(activityItem: IOverlayActivityItem): string {
		if (!this.items.some((item) => item.id === activityItem.id)) {
			this.items.push(activityItem);

			emitEvent(this.onUpdate, activityItem);
		}

		return activityItem.id;
	}

	/**
	 * Adds item data
	 * 
	 * @param data Item data
	 */
	public addItemData(data: TOverlay): string {
		const id = data.id || `${getTimestamp()}_${data.type}_${data.size}`;

		const newData = { ...data };

		if (data.type === OVERLAY_TYPE.DIALOG_VIEW || data.type === OVERLAY_TYPE.SIDEBAR_VIEW) {
			const viewOverlayNewData = newData as IViewOverlayBase;

			viewOverlayNewData.viewRuntimeContext = this.childViewFactory(viewOverlayNewData.view.viewId, viewOverlayNewData.view.params);
			viewOverlayNewData.viewRuntimeContext.render();
		}

		return this.addItem({
			id,
			data: newData
		});
	}

	/**
	 * Removes item
	 * 
	 * @param id Item id
	 * @param buttonId Button id
	 * @param customData Custom data
	 */
	public removeItem(id: string, buttonId: string = null, customData = null): void {
		const index = this.items.findIndex((item) => item.id === id);

		if (index >= 0) {
			const [ item ] = this.items.splice(index, 1);
			const { type } = item.data;

			if (type === OVERLAY_TYPE.DIALOG_VIEW || type === OVERLAY_TYPE.SIDEBAR_VIEW) {
				const viewOverlayNewData = item.data as IViewOverlayBase;
	
				if (viewOverlayNewData.viewRuntimeContext) {
					viewOverlayNewData.viewRuntimeContext.destroy();
				}
			}

			emitEvent(this.onUpdate);
			emitEvent(this.onAction, { overlayId: id, buttonId, customData });
		}
	}

	/**
	 * Removes all items
	 */
	public removeAllItems(): void {
		this.items = [];

		emitEvent(this.onUpdate);
	}

	/**
	 * Item is resolved
	 * 
	 * @param id Item id
	 */
	public itemIsResolved(id: string): Promise<IOverlayCloseResult> {
		return new Promise((resolve) => {
			const _actionHandler = (result: IOverlayCloseResult) => {
				if (result.overlayId === id) {
					resolve(result);

					offEvent(this.onAction, _actionHandler);
				}
			}

			onEvent(this.onAction, _actionHandler);
		});
	}

}
