/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	createEmptyScope,
	createSubScope,
	functionMapToScopeData,
	IFunctionResolver,
	ISchemaFlowNodeSpec,
	IScope,
	RuntimeContext,
	RUNTIME_CONTEXT_MODE,
	TGetBlueprintSchemaSpec,
	TTypeDesc,
	Type,
	TypeDescAny
} from "@hexio_io/hae-lib-blueprint";
import { IViewParamsSpec, offEvent, onEvent, truncateData } from "@hexio_io/hae-lib-shared";

import { ILogger } from "../logger";
import { IAppServer } from "../app";
import { ActionError } from "./ActionError";
import { IActionParams } from "./IActionParams";
import { IActionDebugData_Node } from "./IActionDebugData";
import { IExecutionOptions } from "../managers/IExecutionOptions";
import { ITransformNodeExecutor } from "./ITransformNodeExecutor";
import { ExecutionContext, IExecutionContext } from "../WebServer";
import { IActionResourceProps, IResourceOnEventData, RESOURCE_TYPES } from "../resources";
import { ACTION_ERROR_REASON, ACTION_RESULT_TYPE, TActionResult } from "./IActionResult";
import { FLOW_NODE_TYPES } from "../blueprints/nodes/BlueprintNode";
import { createActionErrorResult, createActionNodeResult } from "./helpers";
import { FLOW_NODE_OUTPUT_NAMES, IActionManager } from "./IActionManager";
import {
	ACTION_NODE_MAX_RECURSION,
	BlueprintActionNodeTypes,
	DOC_TYPES,
	TActionNodeTypes,
	TBlueprintActionNodeTypes
} from "../blueprints";
import { TFlowNodeHandler } from "./FlowNodeHandlers";
import { Session } from "../sessions";
import { BlueprintFlowNodeTypeMap, FOR_EACH_NODE_ON_ERROR_TYPES } from "../blueprints/nodes/BlueprintNodeMap";
import { BlueprintFlowNodeTypeReduce } from "../blueprints/nodes/BlueprintNodeReduce";

interface IProcessedNode {
	result: TActionResult;
	typeDescriptor?: TTypeDesc;
	action?: ACTION_NODE_ACTIONS;
}

import { IAppEnvs } from "../envvars";

export type TActionViewRenderer = (
	app: IAppServer,
	viewId: string,
	params: IViewParamsSpec,
	context: IExecutionContext
) => Promise<string>;

/**
 * Action Manager Options
 */
export interface IActionManagerOptions {
	/** Debug mode */
	debug?: boolean;
	/** Renderer */
	renderer?: TActionViewRenderer;
}

export interface IActionNodeResult {
	status?: ACTION_RESULT_TYPE;
	nodeId?: string;
	outputName?: string;
	data?: {
		errorName?: string;
		message?: string;
		code?: string;
		data?: any;
		processedItems?: any[];
		errorList?: any[];
	};
	type?: TTypeDesc;
	debug?: IActionDebugData_Node;
}

export enum ACTION_NODE_ACTIONS {
	RETURN = "return"
	//CONTINUE = "continue",
	//BREAK = "break"
}

/** Debug info */
export type TDebugInfo = {
	nodes: { [K: string]: IActionDebugData_Node };
	parameters?: any;
	timeout?: number;
	executionTimeInMs?: number;
};

/**
 * Action Manager
 */
export class ActionManager implements IActionManager {
	private onResourceEventHandler: (data: IResourceOnEventData) => Promise<void>;

	private scheduledActions: {
		[K: string]: {
			isInterval: boolean;
			timeout: NodeJS.Timeout;
		};
	} = {};

	/** Logger instance */
	protected logger: ILogger;

	public constructor(
		protected app: IAppServer,
		protected executor: ITransformNodeExecutor,
		protected actionNodeHandlers: { [keys in TActionNodeTypes]?: TFlowNodeHandler },
		protected config?: IActionManagerOptions
	) {
		this.logger = app.get("logger").facility("action-manager");
	}

	/**
	 * Initialize
	 */
	public async init(): Promise<void> {
		this.logger.info("Initializing...");
		await this.executor.init();

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const that = this;

		this.onResourceEventHandler = async (data: IResourceOnEventData) => {
			return that.onResourceEvent(data);
		};

		onEvent(this.app.get("resourceManager").onResource, this.onResourceEventHandler);
	}

	/**
	 * Disposes provider
	 */
	public async dispose(): Promise<void> {
		this.logger.info("Disposing...");

		if (this.executor) {
			await this.executor.dispose();
		}

		if (this.onResourceEventHandler) {
			offEvent(this.app.get("resourceManager").onResource, this.onResourceEventHandler);
			this.onResourceEventHandler = undefined;
		}

		for (const actionId of Object.keys(this.scheduledActions)) {
			this.logger.debug(`Clear interval/timeout of scheduled action '${actionId}'`);
			this.clearTimeout(actionId);
		}

		this.scheduledActions = {};
	}

	public isInit(): boolean {
		return true;
	}

	protected clearTimeout(actionId: string): void {
		const action = this.scheduledActions[actionId];

		if (action) {
			if (action.isInterval) {
				clearInterval(action.timeout);
			} else {
				clearTimeout(action.timeout);
			}
			delete this.scheduledActions[actionId];
		}
	}

	protected async onResourceEvent(data: IResourceOnEventData): Promise<void> {
		/** Resource changed, if not action skip */
		if (data.resource?.resourceType !== RESOURCE_TYPES.ACTION) {
			return;
		}

		/** Resource changed, clear existing scheduled action to re-initialize it the next steps. */
		if (data.resource?.id) {
			this.clearTimeout(data.resource.id);
		}

		const actions = this.app
			.get("resourceManager")
			.getResourceListByType(DOC_TYPES.ACTION_V1) as IActionResourceProps[];
		const scheduledActionsIds: string[] = [];

		for (const action of actions) {
			/** Skip if it's not scheduled action */
			if (!action.parsedData.scheduled) {
				continue;
			}
			scheduledActionsIds.push(action.id);

			/** Skip if action already scheduled */
			if (this.scheduledActions[action.id]) {
				continue;
			}

			/** Dummy context */
			const context = new ExecutionContext(new Session({}, { userLoggedIn: true }), this.logger);

			const interval = action.parsedData.scheduledInterval || 1000;
			let timeout;
			if (action.parsedData.scheduledOnce) {
				this.logger.debug(`Schedule action '${action.id}' once in ${interval} milliseconds.`);

				timeout = setTimeout(async () => {
					this.logger.debug(`Invoke scheduled action '${action.id}' once.`);
					try {
						await this.invoke(
							action.id,
							{},
							context,
							{},
							this.app.appId,
							this.app.appEnvId,
							this.app.config.appName
						);
					} catch (error) {
						// ignore
					}

					/** Cleaning up after invocation. */
					delete this.scheduledActions[action.id];
				}, interval);
			} else {
				this.logger.debug(`Schedule action '${action.id}' every ${interval} milliseconds.`);

				timeout = setInterval(async () => {
					this.logger.debug(`Invoke scheduled action '${action.id}'.`);
					try {
						await this.invoke(
							action.id,
							{},
							context,
							{},
							this.app.appId,
							this.app.appEnvId,
							this.app.config.appName
						);
					} catch (error) {
						// ignore
					}
				}, interval);
			}

			this.scheduledActions[action.id] = {
				isInterval: action.parsedData.scheduledOnce,
				timeout
			};
		}

		/** Clear all actions that is missing in the ResourceManager resource list. */
		for (const actionId of Object.keys(this.scheduledActions)) {
			if (!scheduledActionsIds.includes(actionId)) {
				this.clearTimeout(actionId);
			}
		}
	}

	/**
	 * Invokes an action
	 *
	 * @param actionName Action name
	 * @param params Params
	 * @param context Execution context object
	 * @param config Execution options
	 * @returns
	 */
	public async invoke(
		actionName: string,
		params: IActionParams,
		context: IExecutionContext,
		config: IExecutionOptions,
		appId: string,
		appEnvId: string,
		appName: string
	): Promise<TActionResult> {

		this.logger.debug(`Invoking action '${actionName}'...`, config);

		const debug: TDebugInfo = { nodes: {}, parameters: params };

		let result: TActionResult;
		let typeDescriptor: TTypeDesc;

		const startTimeInMs = Date.now();

		try {
			const resource = this.app
				.get("resourceManager")
				.getResourceById(actionName) as IActionResourceProps;

			// Check if action exists
			if (!resource) {
				context.warn(`Action '${actionName}' was not found.`);

				return createActionErrorResult(
					{
						reason: ACTION_ERROR_REASON.ACTION_NOT_FOUND,
						errorName: "ActionNotFound",
						message: `Action '${actionName}' was not found.`
					},
					config,
					debug
				);
			}

			if (!resource.isValid) {
				context.warn(`Can't invoke action '${actionName}'. Action is invalid.`);
				this.logger.debug(resource.errors);

				return createActionErrorResult(
					{
						reason: ACTION_ERROR_REASON.INVALID_BLUEPRINT,
						errorName: "InvalidAction",
						message: `Action '${actionName}' is invalid.`
					},
					config,
					debug
				);
			}

			if (
				resource.parsedData.requireAuthenticatedUser === true &&
				!context.session?.isAuthenticated()
			) {
				context.warn(`Action '${actionName}' requires authenticated user.`);

				return createActionErrorResult(
					{
						reason: ACTION_ERROR_REASON.UNAUTHENTICATED,
						errorName: "Unauthenticated",
						message: "You must be logged in to invoke this action."
					},
					config,
					debug
				);
			}

			// Process params
			const { paramsSchemaImport: paramsSchema, renderFn, timeout } = resource.parsedData;

			const maxRecursion = resource.parsedData.maxRecursion || ACTION_NODE_MAX_RECURSION;

			if (context.meta?.actionNodeCalls > maxRecursion) {
				throw new ActionError(
					ACTION_ERROR_REASON.MAX_RECURSION,
					`Action is exceeded max (${maxRecursion}) allowed recursive call of the action node.`
				);
			}

			const functionScopeData = functionMapToScopeData(
				this.app.get("resolversRegistry").get<IFunctionResolver>("function").getFunctionMap(),
				config.withTypeDescriptor
			);

			let constants = {};
			if (this.app.has("constantsManager")) {
				constants = this.app.get("constantsManager").getAllConstants();
			}

			const _app: IAppEnvs = {
				envs: {},
				theme: this.app.get("resourceManager")?.getManifest()?.defaultTheme || { themeId: null, styleName: null }
			};
			if (this.app.has("envVarManager")) {
				_app.envs = this.app.get("envVarManager").getPublicVars();
			}

			const scope = createEmptyScope(
				{
					globals: {
						...functionScopeData.data
					},
					params,
					session: context.session.export(),
					currentUser: context.user || null,
					appId,
					appEnvId: context.session?.appEnvId || appEnvId,
					appName,
					constants,
					_app
				},
				{
					globals: Type.Object({
						props: {
							...functionScopeData.type
						}
					})
				}
			);

			// Validate params
			const rCtx = new RuntimeContext(
				{
					resolvers: this.app.get("resolversRegistry").getAll(),
					mode: RUNTIME_CONTEXT_MODE.NORMAL
				},
				renderFn,
				scope
			);

			const isValid = paramsSchema?.validate(rCtx, ["$"], 0, params, true);
			if (!isValid) {
				return createActionErrorResult(
					{
						reason: ACTION_ERROR_REASON.INVALID_PARAMS,
						errorName: "InvalidParams",
						message: "Invalid action parameters.",
						details: rCtx.getRuntimeErrors()
					},
					config,
					debug
				);
			}

			const spec = await rCtx.renderAsync();

			type TNodeSpec = ISchemaFlowNodeSpec<TBlueprintActionNodeTypes>;
			const nodes = spec?.nodes || [];

			const nodeIndex: { [K: string]: TNodeSpec } = {};
			nodes.forEach((node) => {
				nodeIndex[node.id] = node;
			});

			const localScope = createSubScope(scope);
			const renderer = this.config?.renderer;

			// Process node(s)
			const processNode = async (
				nodeId: string,
				localScope: IScope,
				invocationOrder: number
			): Promise<IProcessedNode> => {
				const nodeStartTimeInMs = Date.now();

				const node = nodeIndex[nodeId];

				if (!node) {
					context.warn(`Node '${nodeId}' not found`);

					return {
						result: createActionErrorResult(
							{
								reason: ACTION_ERROR_REASON.NODE_NOT_FOUND,
								errorName: "NodeNotFound",
								message: `Can't invoke action node '${nodeId}' because it does not exists.`
							},
							config,
							debug
						)
					};
				}

				if (node.type === FLOW_NODE_TYPES.ACTION) {
					context.meta.actionNodeCalls++;
				}

				if (!Object.keys(BlueprintActionNodeTypes).includes(node.type)) {
					return {
						result: createActionErrorResult(
							{
								reason: ACTION_ERROR_REASON.INVALID_BLUEPRINT,
								errorName: "UnsupportedNodeType",
								message: `Unsupported action node type: '${node.type}'.`,
								nodeId
							},
							config,
							debug
						)
					};
				}

				const opts = node.opts(localScope);
				const nodeHandler = this.actionNodeHandlers[node.type];
				let nodeResult: IActionNodeResult;

				if (nodeHandler) {
					const handlerResult = await nodeHandler(opts, {
						app: this.app,
						resourceId: actionName,
						nodeId,
						localScope,
						config,
						context,
						timeout,
						appId,
						appEnvId,
						appName,
						renderer,
						executor: this.executor,
						memoryLimit: 512
					});

					nodeResult = createActionNodeResult(handlerResult, {
						nodeId,
						localScope,
						opts,
						nodeType: node.type,
						invocationOrder
					});
				} else {
					throw new ActionError(
						ACTION_ERROR_REASON.INVALID_BLUEPRINT,
						`Unsupported action node type: '${node.type}'.`
					);
				}

				if (node.type === FLOW_NODE_TYPES.REDUCE) {
					const reduceOpts = opts as TGetBlueprintSchemaSpec<
						typeof BlueprintFlowNodeTypeReduce["opts"]
					>;

					if (nodeResult.outputName === FLOW_NODE_OUTPUT_NAMES.ON_ITEM) {
						const items = nodeResult.data as unknown as any[];

						let accumulator: any = reduceOpts.initialValue;

						for (let index = 0; index < items.length; index++) {
							const item = items[index];

							/** Set item as data for sub nodes invocation. */
							nodeResult.data = item;
							nodeResult.outputName = FLOW_NODE_OUTPUT_NAMES.ON_ITEM;

							const reduceScope = createSubScope(localScope);
							setScopeVariable(reduceScope, node.varName, undefined, undefined);

							const varName = node.varName || "";

							setScopeVariable(reduceScope, `${varName}_item`, item, nodeResult.type);
							setScopeVariable(reduceScope, `${varName}_index`, index, Type.Integer({}));
							setScopeVariable(reduceScope, `${varName}_acc`, accumulator, Type.Any({}));

							const nodeCopy = { ...node };
							nodeCopy.varName = undefined;

							const processedNodeResult = await processNodeResult(
								nodeId,
								nodeCopy,
								nodeResult,
								0,
								reduceScope
							);

							if (processedNodeResult.action === ACTION_NODE_ACTIONS.RETURN) {
								this.logger.debug(`Return from Reduce node '${nodeId}'.`);
								return processedNodeResult;
							} else if (processedNodeResult.result.status === ACTION_RESULT_TYPE.ERROR) {
								this.logger.debug(`Error at Reduce node '${nodeId}'.`);

								if (reduceOpts.ignoreErrors !== true) {
									this.logger.debug(`Fail on error at Reduce node '${nodeId}'.`);

									nodeResult.outputName = FLOW_NODE_OUTPUT_NAMES.ON_ERROR;
									nodeResult.debug.output.name = FLOW_NODE_OUTPUT_NAMES.ON_ERROR;

									nodeResult.data = {
										errorName: processedNodeResult.result.error?.errorName,
										message: processedNodeResult.result.error?.message,
										data: processedNodeResult.result.error?.data
									};
									nodeResult.debug.output.data = nodeResult.data;

									nodeResult.debug.executionTimeInMs = Date.now() - nodeStartTimeInMs;
									debug.nodes[nodeId] = nodeResult.debug;

									return await processNodeResult(
										nodeId,
										node,
										nodeResult,
										invocationOrder,
										localScope,
										timeout
									);
								} else {
									this.logger.debug(`Ignore error at Reduce node '${nodeId}'.`);
									/** Ignore error and continue. */
								}
							} else {
								this.logger.debug(`Got result Reduce node '${nodeId}'.`);
								accumulator = processedNodeResult.result.data;
							}
						}

						nodeResult.outputName = FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS;
						nodeResult.debug.output.name = FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS;

						nodeResult.data = accumulator as any;
						nodeResult.debug.output.data = nodeResult.data;

						nodeResult.type = Type.Any({});
					}
				}

				if (node.type === FLOW_NODE_TYPES.MAP) {
					const mapOpts = opts as TGetBlueprintSchemaSpec<typeof BlueprintFlowNodeTypeMap["opts"]>;

					if (nodeResult.outputName === FLOW_NODE_OUTPUT_NAMES.ON_ITEM) {
						const items = nodeResult.data as unknown as any[];

						const mapResults: IProcessedNode[] = [];

						for (let index = 0; index < items.length; index++) {
							const item = items[index];

							/** Set item as data for sub nodes invocation. */
							nodeResult.data = item;
							nodeResult.outputName = FLOW_NODE_OUTPUT_NAMES.ON_ITEM;

							const mapScope = createSubScope(localScope);
							setScopeVariable(mapScope, node.varName, undefined, undefined);

							const varName = node.varName || "";

							setScopeVariable(mapScope, `${varName}_item`, item, nodeResult.type);
							setScopeVariable(mapScope, `${varName}_index`, index, Type.Integer({}));

							const nodeCopy = { ...node };
							nodeCopy.varName = undefined;

							const processedNodeResult = await processNodeResult(
								nodeId,
								nodeCopy,
								nodeResult,
								0,
								mapScope
							);

							if (processedNodeResult.action === ACTION_NODE_ACTIONS.RETURN) {
								this.logger.debug(`Return from Map node '${nodeId}'.`);
								return processedNodeResult;
							} else if (processedNodeResult.result.status === ACTION_RESULT_TYPE.ERROR) {
								this.logger.debug(`Error at Map node '${nodeId}'.`);

								if (mapOpts.onError === FOR_EACH_NODE_ON_ERROR_TYPES.FAIL_ON_FIRST) {
									this.logger.debug(`Fail on first error at Map node '${nodeId}'.`);

									nodeResult.outputName = FLOW_NODE_OUTPUT_NAMES.ON_ERROR;
									nodeResult.debug.output.name = FLOW_NODE_OUTPUT_NAMES.ON_ERROR;

									const processedItems = [
										...mapResults.map((item) => item.result["data"]),
										...new Array(items.length - mapResults.length).fill(null)
									];

									const errorList = new Array(items.length).fill(null);
									errorList[index] = processedNodeResult.result.error;

									nodeResult.data = {
										errorName: processedNodeResult.result.error?.errorName,
										message: processedNodeResult.result.error?.message,
										data: processedNodeResult.result.error?.data,
										processedItems,
										errorList
									};
									nodeResult.debug.output.data = nodeResult.data;

									nodeResult.debug.executionTimeInMs = Date.now() - nodeStartTimeInMs;
									debug.nodes[nodeId] = nodeResult.debug;

									return await processNodeResult(
										nodeId,
										node,
										nodeResult,
										invocationOrder,
										localScope,
										timeout
									);
								} else {
									this.logger.debug(`Continue on error at Map node '${nodeId}'.`);
									mapResults.push(processedNodeResult);
								}
							} else {
								this.logger.debug(`Got result Map node '${nodeId}'.`);
								mapResults.push(processedNodeResult);
							}
						}

						if (mapOpts.onError === FOR_EACH_NODE_ON_ERROR_TYPES.IGNORE) {
							this.logger.debug(`Ignore Map errors at node '${nodeId}'.`);

							nodeResult.outputName = FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS;
							nodeResult.debug.output.name = FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS;

							nodeResult.data = mapResults.map((res) =>
								res.result.status === ACTION_RESULT_TYPE.SUCCESS ? res.result.data : null
							) as any;
							nodeResult.debug.output.data = nodeResult.data;

							nodeResult.type = Type.Array({ items: [Type.Any({})] });
						} else if (mapOpts.onError === FOR_EACH_NODE_ON_ERROR_TYPES.FAIL_AFTER_ALL) {
							const errors = mapResults.filter(
								(res) => res.result.status === ACTION_RESULT_TYPE.ERROR
							) as any;

							if (errors.length > 0) {
								this.logger.debug(
									`Fail after all Map errors at node '${nodeId}'. Errors: ${errors.length}.`
								);

								nodeResult.outputName = FLOW_NODE_OUTPUT_NAMES.ON_ERROR;
								nodeResult.debug.output.name = FLOW_NODE_OUTPUT_NAMES.ON_ERROR;

								const processedItems = mapResults.map((res) =>
									res.result.status === ACTION_RESULT_TYPE.SUCCESS ? res.result.data : null
								) as any;

								const errorList = mapResults.map((res) =>
									res.result.status === ACTION_RESULT_TYPE.ERROR ? res.result.error : null
								) as any;

								nodeResult.data = {
									errorName: "Map Error",
									message: "One or more items failed to process.",
									data: null,
									processedItems,
									errorList
								};
								nodeResult.debug.output.data = nodeResult.data;
							} else {
								this.logger.debug(`Success after all Map loop errors at node '${nodeId}'.`);

								nodeResult.outputName = FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS;
								nodeResult.debug.output.name = FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS;

								nodeResult.data = mapResults.map((res) => res.result["data"]) as any;
								nodeResult.debug.output.data = nodeResult.data;
							}

							nodeResult.type = Type.Array({ items: [Type.Any({})] });
						} else {
							this.logger.debug(`No errors at node '${nodeId}'.`);

							nodeResult.outputName = FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS;
							nodeResult.debug.output.name = FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS;

							nodeResult.data = mapResults.map((res) => res.result["data"] || null) as any;
							nodeResult.debug.output.data = nodeResult.data;
							nodeResult.type = Type.Array({ items: [Type.Any({})] });
						}
					}
				}

				nodeResult.debug.executionTimeInMs = Date.now() - nodeStartTimeInMs;
				debug.nodes[nodeId] = nodeResult.debug;

				return await processNodeResult(
					nodeId,
					node,
					nodeResult,
					invocationOrder,
					localScope,
					timeout
				);
			};

			const processNodeResult = async (
				nodeId: string,
				node: TNodeSpec,
				nodeResult: IActionNodeResult,
				invocationOrder: number,
				scope: IScope,
				timeout?: number
			): Promise<IProcessedNode> => {
				if (
					nodeResult.outputName &&
					node.outputs[nodeResult.outputName] &&
					node.outputs[nodeResult.outputName].length > 0
				) {
					const targetNodes = node.outputs[nodeResult.outputName];

					this.logger.debug(`Node '${nodeId}' target nodes:`, targetNodes);

					const nextPromises = [];
					const nextScope = createSubScope(scope);

					if (node.varName) {
						setScopeVariable(nextScope, node.varName, nodeResult.data, nodeResult.type);
					}

					setScopeVariable(nextScope, "prevNodeResult", nodeResult.data, {
						...(nodeResult.type ? nodeResult.type : TypeDescAny({})),
						label: "Previous node result"
					} as TTypeDesc);

					/** Make sure that `_app` wasn't rewritten by any user-defined variable. It's read-only. */
					nextScope.localData["_app"] = nextScope.globalData["_app"];

					for (let i = 0; i < targetNodes.length; i++) {
						nextPromises.push(processNode(targetNodes[i], nextScope, ++invocationOrder));
					}

					/** Using `Promise.race()` here to return only first resolved child node. */
					if (timeout) {
						debug.timeout = timeout;
						return await timeoutPromise(
							timeout,
							() => Promise.race(nextPromises),
							"Action invocation timeout expired."
						);
					} else {
						return await Promise.race(nextPromises);
					}
				} else {
					if (node.type === FLOW_NODE_TYPES.OUTPUT) {
						return {
							result: {
								status: ACTION_RESULT_TYPE.SUCCESS,
								data: nodeResult.data
							},
							typeDescriptor: nodeResult.type,
							action: ACTION_NODE_ACTIONS.RETURN
						};
					} else if (node.type === FLOW_NODE_TYPES.ERROR) {
						return {
							result: createActionErrorResult(
								{
									...nodeResult.data,
									data: nodeResult.data?.data,
									reason: ACTION_ERROR_REASON.USER_ERROR,
									message: nodeResult.data.message,
									errorName: nodeResult.data.errorName,
									customData: nodeResult.data,
									nodeId,
									nodeType: node.type,
									nodeVarName: node.varName || null
								},
								config,
								debug
							),
							typeDescriptor: nodeResult.type
						};
					} else if (nodeResult.outputName === FLOW_NODE_OUTPUT_NAMES.ON_ERROR) {
						// Default error handler
						return {
							result: createActionErrorResult(
								{
									...nodeResult.data,
									data: nodeResult.data?.data,
									reason: ACTION_ERROR_REASON.UNHANDLED_ERROR,
									message: nodeResult.data?.message || "Unhandled action error.",
									errorName: nodeResult.data?.errorName || null,
									nodeId,
									nodeType: node.type,
									nodeVarName: node.varName || null
								},
								config,
								debug
							),
							typeDescriptor: nodeResult.type
						};
					} else {
						// Unhandled action end - return last data

						return {
							result: {
								status: ACTION_RESULT_TYPE.SUCCESS,
								data: nodeResult.data
							},
							typeDescriptor: nodeResult.type
						};
					}
				}
			};

			const processedNode = await processNode(FLOW_NODE_TYPES.START, localScope, 0);

			result = processedNode.result;
			typeDescriptor = processedNode.typeDescriptor;
		} catch (error) {
			if (
				error instanceof ActionError &&
				(<any>Object).values(ACTION_ERROR_REASON).includes(error.name)
			) {
				if (error.name === ACTION_ERROR_REASON.MAX_RECURSION) {
					throw error;
				}

				result = createActionErrorResult(
					{
						reason: ACTION_ERROR_REASON.UNHANDLED_ERROR,
						errorName: (error.name as any) || "UnhandledError",
						message: error.message
					},
					config,
					debug
				);
			} else {
				context.warn(`Unhandled error: ${String(error)}`);

				// Return internal error, because this one should have been handled or should not happened
				result = createActionErrorResult(
					{
						reason: ACTION_ERROR_REASON.INTERNAL_ERROR,
						errorName: error?.message || "UnknownError",
						message: error?.message || "Unknown error.",
						details: error.errorDetails
					},
					config,
					debug
				);
			}
		}

		if (config.withTypeDescriptor && result && typeDescriptor) {
			result["typeDescriptor"] = typeDescriptor;
		}

		if (config.debug && result && debug) {
			debug.executionTimeInMs = Date.now() - startTimeInMs;
			result.debug = truncateData(debug, {
				maxStringLength: 1024 * 1024 // 1MB
			});
		}

		return result;
	}
}

const setScopeVariable = (scope: IScope, varName: string, value: any, type: TTypeDesc): IScope => {
	scope.localData[varName] = scope.globalData[varName] = value;
	scope.localType[varName] = scope.globalType[varName] = type;
	return scope;
};

/**
 * Promise with timeout
 *
 * @param timeout Timeout in milliseconds
 * @param promise Function that returns promise
 * @param message Error message
 * @returns
 */
export const timeoutPromise = <T>(timeout: number, promise: () => Promise<T>, message: string) => {
	let timeoutHandle: NodeJS.Timeout;

	const timeoutPromise = new Promise<never>((resolve, reject) => {
		timeoutHandle = setTimeout(() => {
			reject(new ActionError(ACTION_ERROR_REASON.TIMEOUT, message));
		}, timeout);
	});

	return Promise.race([promise(), timeoutPromise]).then((result) => {
		clearTimeout(timeoutHandle);
		return result;
	});
};
