/**
 * Button schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const button = {
	/*labelText: {
		label: "schema:button.labelText#label",
		description: "schema:button.labelText#description"
	},*/
	typeData: {
		label: "schema:button.typeData#label"
	},
	typeDataValues: {
		default: {
			label: "schema:button.typeData#values.default#label"
		},
		link: {
			label: "schema:button.typeData#values.link#label"
		},
	},
	styleData: {
		label: "schema:button.styleData#label"
	},
	styleDataValues: {
		default: {
			label: "schema:button.styleData#values.default#label"
		},
		clear: {
			label: "schema:button.styleData#values.clear#label"
		},
		tab: {
			label: "schema:button.styleData#values.tab#label"
		},
		custom: {
			label: "schema:button.styleData#values.custom#label"
		}
	},
	styleDataHoverState: {
		label: "schema:button.styleData#props.hoverState#label"
	},
	styleDataActiveState: {
		label: "schema:button.styleData#props.activeState#label"
	},
	role: {
		label: "schema:button.role#label",
		description: "schema:button.role#description"
	},
	roleValues: {
		none: {
			label: "schema:button.role#values.none#label"
		},
		submit: {
			label: "schema:button.role#values.submit#label"
		}
	},
	enabled: {
		label: "schema:button.enabled#label",
		description: "schema:button.enabled#description"
	},
	active: {
		label: "schema:button.active#label",
		description: "schema:button.active#description"
	},
	setActive: {
		label: "schema:button.setActive#label",
		description: "schema:button.setActive#description"
	},
	link: {
		label: "schema:button.link#label",
		description: "schema:button.link#description"
	},
	working: {
		label: "schema:button.working#label",
		description: "schema:button.working#description"
	}
};
