/**
 * Composed Chart HAE component Create Axis
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import { AxisRenderer } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer";

import { HAEComponentGaugeChart_State } from "./state";
import { getStringEnumCssValue, SPACING } from "@hexio_io/hae-lib-components";
import { isNonEmptyString, toNumber } from "@hexio_io/hae-lib-shared";
import { getChartColor } from "../../Functions/chartHelpers";

/**
 * Creates axis
 */
export function createAxis(
	size: HAEComponentGaugeChart_State["size"],
	min: HAEComponentGaugeChart_State["min"],
	max: HAEComponentGaugeChart_State["max"],
	backgroundColor: HAEComponentGaugeChart_State["backgroundColor"],
	ticks: HAEComponentGaugeChart_State["ticks"],
	root: am5.Root,
	chart: am5radar.RadarChart,
	viewportRootElement: HTMLElement
): {
	axis: am5xy.ValueAxis<AxisRenderer>;
	axisBackgroundRange: am5.DataItem<am5xy.IValueAxisDataItem>;
} {
	let innerRadius: number | am5.Percent;

	if (isNonEmptyString(size)) {
		if (size.endsWith("%")) {
			innerRadius = am5.percent(0 - toNumber(size));
		}
		else {
			const sizeValue = getStringEnumCssValue(SPACING, size, "spacing-", undefined, undefined, viewportRootElement);

			if (sizeValue.endsWith("px")) {
				innerRadius = 0 - toNumber(sizeValue);
			}
		}
	}

	const axisRenderer = am5radar.AxisRendererCircular.new(root, {
		innerRadius,
		strokeWidth: 0
	});

	axisRenderer.grid.template.setAll({
		visible: false
	});

	// Ticks

	axisRenderer.labels.template.setAll({
		visible: !!ticks?.values
	});

	axisRenderer.ticks.template.setAll(ticks?.lines ? {
		stroke: root.interfaceColors.get("text"),
		strokeOpacity: 1,
		visible: true
	} : { visible: false });

	// Axis

	const axis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
		maxDeviation: 0,
		min,
		max,
		strictMinMax: true,
		renderer: axisRenderer
	}));

	// Background

	let axisBackgroundRange: am5.DataItem<am5xy.IValueAxisDataItem>;

	if (backgroundColor) {
		axisBackgroundRange = axis.createAxisRange(axis.makeDataItem({}));

		axisBackgroundRange.setAll({
			value: min,
			endValue: max
		});

		axisBackgroundRange.get("axisFill").setAll({
			fill: getChartColor(backgroundColor, viewportRootElement),
			fillOpacity: 1,
			visible: true
		});

		axisBackgroundRange.get("tick").set("forceHidden", true);
	}

	return { axis, axisBackgroundRange };
}
