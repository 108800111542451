/**
 * Custom Chart schema terms
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const customChart = {
	setup: {
		label: "schema:customChart.setup#label",
		description: "schema:customChart.setup#description"
	},
	update: {
		label: "schema:customChart.callback#label",
		description: "schema:customChart.callback#description"
	},
	data: {
		label: "schema:customChart.data#label"
	}
};
