/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { TGenericIntegrationDefinition } from "../integrations";

export interface IIntegrationDefRegistry extends ItemRegistry<TGenericIntegrationDefinition> { }
export class IntegrationDefRegistry extends ItemRegistry<TGenericIntegrationDefinition> { }
