/**
 * Link target enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum LINK_TARGET {
	SELF = "_self",
	BLANK = "_blank",
	TOP = "_top"
}

export type LINK_TARGET_string = keyof typeof LINK_TARGET;

export const LINK_TARGET_default = "SELF";
