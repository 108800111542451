/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstObjectOpts, SchemaConstObject, ISchemaConstObject, TSchemaConstObjectProps } from "../const/SchemaConstObject";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueObjectOpts<
	TProps extends TSchemaConstObjectProps
> extends ISchemaValueOpts<ISchemaConstObject<TProps>>, ISchemaConstObjectOpts<TProps> {}

/**
 * Schema: Object value
 *
 * @param opts Schema options
 */
export function SchemaValueObject<
	TProps extends TSchemaConstObjectProps
>(opts: ISchemaValueObjectOpts<TProps>): ISchemaValue<ISchemaConstObject<TProps>> {

	return SchemaValue(SchemaConstObject(opts), opts);

}
