/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

declare const __BUILD_MODE__: string;
declare const __REPO_COMMIT_HASH__: string;

export enum ENV {
	LOCAL = "local",
	DEVELOPMENT = "development",
	PRODUCTION = "production",
	UNKNOWN = "unknown"
}

export function isProdBuild(): boolean {
	return __BUILD_MODE__ === "production";
}

export function isLocalEnv(): boolean {
	return window?.location?.hostname === "localhost";
}

export function isDevelopmentEnv(): boolean {
	return window?.location?.hostname?.endsWith(".dev");
}

export function isProductionEnv(): boolean {
	return !isDevelopmentEnv() && !isLocalEnv() && isProdBuild();
}

export function getEnv(): ENV {
	if (isLocalEnv()) {
		return ENV.LOCAL;
	}

	if (isDevelopmentEnv()) {
		return ENV.DEVELOPMENT;
	}

	if (isProductionEnv()) {
		return ENV.PRODUCTION;
	}

	return ENV.UNKNOWN;
}

export function getReleaseVersion(): string {
	return __REPO_COMMIT_HASH__;
}
