/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TBlueprintIDTNodePath } from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaCreateOpts,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel} from "../Schema/IBlueprintSchema";
import { IModelNode, IModelNodeCompileResult } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	validateAsNotSupported
} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import { ISchemaValueStringOpts } from "./value/SchemaValueString";
import { RuntimeContext } from "../Context/RuntimeContext";
import { TModelPath } from "../Shared/TModelPath";
import { IScope } from "../Shared/Scope";
import { CompileContext } from "../Context/CompileContext";
import { TTypeDesc, TypeDescAny } from "../Shared/ITypeDescriptor";

/**
 * Schema model
 */
export interface ISchemaRuntimeWrapperModel<
	TValueSchema extends TGenericBlueprintSchema,
	TSpec
> extends IModelNode<ISchemaRuntimeWrapper<TValueSchema, TSpec>> {
	value: TGetBlueprintSchemaModel<TValueSchema>;
}

/**
 * Schema options
 */
export interface ISchemaRuntimeWrapperOpts<
	TValueSchema extends TGenericBlueprintSchema,
	TSpec
> extends ISchemaValueStringOpts {
	/** Value schema */
	value: TValueSchema;

	/**
	 * Custom render function
	 *
	 * @param rCtx Runtime Context instance
	 * @param modelNode Value model node
	 * @param path Model path
	 * @param scope Scope
	 * @param prevSpec Previously rendered spec
	 */
	render: (
		rCtx: RuntimeContext,
		modelNode: TGetBlueprintSchemaModel<TValueSchema>,
		path: TModelPath,
		scope: IScope,
		prevSpec: TSpec
	) => TSpec;

	/**
	 * Custom compile render function
	 *
	 * @param cCtx Compile Context instance
	 * @param modelNode Value model node
	 * @param path Model path
	 */
	compileRender: (
		cCtx: CompileContext,
		modelNode: TGetBlueprintSchemaModel<TValueSchema>,
		path: TModelPath
	) => IModelNodeCompileResult;

	/**
	 * Return type descriptor
	 */
	getTypeDescriptor?: (modelNode?: TGetBlueprintSchemaModel<TValueSchema>) => TTypeDesc;

}

/**
 * Schema type
 */
export interface ISchemaRuntimeWrapper<
	TValueSchema extends TGenericBlueprintSchema,
	TSpec
> extends IBlueprintSchema<
	ISchemaRuntimeWrapperOpts<TValueSchema, TSpec>,
	ISchemaRuntimeWrapperModel<TValueSchema, TSpec>,
	TSpec,
	TGetBlueprintSchemaDefault<TValueSchema>,
	TGetBlueprintSchemaCreateOpts<TValueSchema>
> {}

/**
 * Schema: Runtime Wrapper
 * Allows to specify custom render code
 *
 * @param opts Schema options
 */
export function SchemaRuntimeWrapper<
	TValueSchema extends TGenericBlueprintSchema,
	TSpec
>(
	opts: ISchemaRuntimeWrapperOpts<TValueSchema, TSpec>
): ISchemaRuntimeWrapper<TValueSchema, TSpec> {

	type TValueModel = TGetBlueprintSchemaModel<TValueSchema>;

	const schema = createEmptySchema<ISchemaRuntimeWrapper<TValueSchema, TSpec>>("runtimeWrapper", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, ["value"])
	}

	const createModel = (
		dCtx: DesignContext,
		valueModel: TValueModel,
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx, parent, [], {
			value: valueModel
		});

		const model = assignParentToChildrenOf(modelNode)

		model.initRequiredValid = valueModel.initRequiredValid;

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue, createOpts) => {

		const valueModel = opts.value.createDefault(dCtx, null, defaultValue, createOpts as never) as TValueModel;
		return createModel(dCtx, valueModel, parent);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedValue = modelNode.value.schema.clone(dCtx, modelNode.value, null) as TValueModel;

		const clone = cloneModelNode(dCtx, modelNode, parent,{
			value: clonedValue
		});

		return assignParentToChildrenOf(clone)

	}

	schema.destroy = (modelNode) => {

		modelNode.value.schema.destroy(modelNode.value);
		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent, createOpts) => {

		const valueModel = opts.value.parse(dCtx, idtNode, null, createOpts as never) as TValueModel;
		return createModel(dCtx, valueModel, parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		if (opts.value.provideCompletion) {
			opts.value.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}

	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {

		return modelNode.value.schema.serialize(modelNode.value, path);

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		return opts.render(rCtx, modelNode.value, path, scope, prevSpec);

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		return opts.compileRender(cCtx, modelNode.value, path);

	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	// Conditional schema is internal and cannot be exported
	schema.export = (): ISchemaImportExport => {

		throw new Error("Runtime Wrapper schema cannot be exported.");

	};

	schema.getChildNodes = (modelNode) => {
		return [{
			key: "value",
			node: modelNode.value
		}];
	}

	schema.getTypeDescriptor = (modelNode) => {

		if (opts.getTypeDescriptor) {
			return opts.getTypeDescriptor(modelNode.value);
		} else {
			return TypeDescAny({
				label: opts.label,
				description: opts.description,
				example: opts.example,
				tags: opts.tags
			});
		}

	};

	return schema;

}
