/**
 * Pagination schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const pagination = {
	limit: {
		label: "schema:pagination.limit#label",
		description: "schema:pagination.limit#description"
	},
	items: {
		label: "schema:pagination.items#label",
		description: "schema:pagination.items#description"
	},
	page: {
		label: "schema:pagination.page#label",
		description: "schema:pagination.page#description"
	},
	maxDisplayedPages: {
		label: "schema:pagination.maxDisplayedPages#label",
		description: "schema:pagination.maxDisplayedPages#description"
	},
	offset: {
		label: "schema:pagination.offset#label",
		description: "schema:pagination.offset#description"
	},
	typeData: {
		label: "schema:pagination.typeData#label"
	},
	typeDataValues: {
		client: {
			label: "schema:pagination.typeData#values.client#label"
		},
		server: {
			label: "schema:pagination.typeData#values.server#label"
		},
	},
	item: {
		label: "schema:pagination.item#label"
	},
	itemSize: {
		label: "schema:pagination.item#props.size#label"
	},
	itemCurrentState: {
		label: "schema:pagination.item#props.currentState#label"
	},
	setPage: {
		label: "schema:pagination.setPage#label"
	}
};
