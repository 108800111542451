/**
 * Field HAE component selection
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Selection interface
 */
export interface IFieldSelection {
	caret: number;
	start: number;
	end: number;
	direction: "forward" | "backward" | "none";
}

/**
 * Initial selection
 */
export const initialSelection: IFieldSelection = {
	caret: null,
	start: null,
	end: null,
	direction: null
};

/**
 * Returns field selection
 * 
 * @param field HTML field element
 */
export function getSelectionByField(field: HTMLInputElement | HTMLTextAreaElement): IFieldSelection {
	if (!field) {
		return initialSelection;
	}

	const { selectionStart: start, selectionEnd: end, selectionDirection: direction } = field;

	return {
		caret: direction !== "backward" ? end : start,
		start,
		end,
		direction
	};
}
