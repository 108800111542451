/**
 * Font size
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Font size enum
 */
export enum FONT_SIZE {
	BASE = "base",
	XSMALL = "x-small",
	SMALL = "small",
	MEDIUM = "medium",
	LARGE = "large",
	XLARGE = "x-large",
	XXLARGE = "xx-large",
	XXXLARGE = "xxx-large"
}

export type FONT_SIZE_string = keyof typeof FONT_SIZE;

export type FONT_SIZE_css_variable = `--font-size-${FONT_SIZE}`;
