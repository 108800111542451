/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE, IBlueprintIDTScalar, TBlueprintIDTNodePath } from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts, TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaCreateOpts,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {assignParentToModelProps, cloneModelNode, createEmptySchema, createModelNode, destroyModelNode} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import { TypeDescNull } from "../Shared/ITypeDescriptor";

/**
 * Schema model
 */
export interface ISchemaConditionalModel<
	TValueSchema extends TGenericBlueprintSchema
> extends IModelNode<ISchemaConditional<TValueSchema>> {
	value: TGetBlueprintSchemaModel<TValueSchema>;
}

/**
 * Schema options
 */
export interface ISchemaConditionalOpts<
	TValueSchema extends TGenericBlueprintSchema
> extends IBlueprintSchemaOpts {
	value: TValueSchema;
	condition: (dCtx: DesignContext) => boolean;
}

/**
 * Schema type
 */
export interface ISchemaConditional<
	TValueSchema extends TGenericBlueprintSchema
> extends IBlueprintSchema<
	ISchemaConditionalOpts<TValueSchema>,
	ISchemaConditionalModel<TValueSchema>,
	TGetBlueprintSchemaSpec<TValueSchema>,
	TGetBlueprintSchemaDefault<TValueSchema>,
	TGetBlueprintSchemaCreateOpts<TValueSchema>
	> { }

/**
 * Schema: Conditional
 * Allows to specify whenever a value will be parsed (or created) based on a condition
 *
 * @param opts Schema options
 */
export function SchemaConditional<
	TValueSchema extends TGenericBlueprintSchema
>(
	opts: ISchemaConditionalOpts<TValueSchema>
): ISchemaConditional<TValueSchema> {

	type TValueModel = TGetBlueprintSchemaModel<TValueSchema>;

	const schema = createEmptySchema<ISchemaConditional<TValueSchema>>("conditional", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, "value")
	}


	const createModel = (
		dCtx: DesignContext,
		valueModel: TValueModel,
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx,parent, [], {
			value: valueModel
		});

		const model = assignParentToChildrenOf(modelNode)

		if (valueModel) {
			model.initRequiredValid = valueModel.initRequiredValid;
		} else {
			model.initRequiredValid = true;
		}

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue, createOpts) => {

		let valueModel;

		if (opts.condition(dCtx)) {
			valueModel = opts.value.createDefault(dCtx, null, defaultValue, createOpts as never) as TValueModel;
		} else {
			valueModel = null;
		}

		return createModel(dCtx, valueModel, parent);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedValue = modelNode.value ? modelNode.value.schema.clone(dCtx, modelNode.value, null) as TValueModel : null;

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			value: clonedValue
		});

		return assignParentToChildrenOf(clone)
	}

	schema.destroy = (modelNode) => {

		if (modelNode.value) {
			modelNode.value.schema.destroy(modelNode.value);
		}

		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent, createOpts) => {

		let valueModel;

		if (opts.condition(dCtx)) {
			valueModel = opts.value.parse(dCtx, idtNode, null, createOpts as never) as TValueModel;
		} else {
			valueModel = null;
		}

		return createModel(dCtx, valueModel, parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		if (opts.value.provideCompletion) {
			opts.value.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}

	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {

		if (modelNode.value) {
			return modelNode.value.schema.serialize(modelNode.value, path);
		} else {
			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.NULL,
				path: path,
				value: null
			} as IBlueprintIDTScalar;
		}

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		if (modelNode.value) {
			return modelNode.value.schema.render(rCtx, modelNode.value, path, scope, prevSpec);
		} else {
			return null;
		}

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		if (modelNode.value) {
			return modelNode.value.schema.compileRender(cCtx, modelNode.value, path);
		} else {
			return {
				isScoped: false,
				code: "null"
			};
		}

	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {

		return opts.value.validate(rCtx, path, modelNodeId, value, validateChildren);

	}

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {

		return opts.value.compileValidate(cCtx, path, modelNodeId, validateChildren);

	};

	// Conditional schema is internal and cannot be exported
	schema.export = (): ISchemaImportExport => {

		throw new Error("Conditional schema cannot be exported.");

	};

	schema.getTypeDescriptor = (modelNode) => {

		if (modelNode.value) {
			return modelNode.value.schema.getTypeDescriptor(modelNode.value);
		} else {
			return TypeDescNull({});
		}

	};

	schema.getChildNodes = (modelNode) => {
		return [{
			key: "value",
			node: modelNode.value
		}];
	}

	return schema;

}
