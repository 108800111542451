/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE, IBlueprintIDTScalar } from "../../IDT/ISchemaIDT";
import {
	IBlueprintSchemaScalar,
	IBlueprintSchemaOpts,
	TGenericBlueprintSchemaScalarWithValue,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../../Schema/IModelNode";
import { SchemaDeclarationError } from "../../Schema/SchemaDeclarationError";
import {
	applyRuntimeValidators, assignParentToModelProps,
	cloneModelNode,
	compileRuntimeValidators,
	compileScalarNodeRender,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	renderScalarNode,
	validateDefaultValue,
	validateParsedValueAndReport,
	validateValueAndUpdateModel
} from "../../Schema/SchemaHelpers";
import { DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { DesignContext } from "../../Context/DesignContext";
import { ISchemaConstFloatModel } from "./SchemaConstFloat";
import { ISchemaConstIntegerModel } from "./SchemaConstInteger";
import { ISchemaConstStringModel } from "./SchemaConstString";
import { IBlueprintSchemaValidationError, IBlueprintSchemaValidatorHandler } from "../../Validator/IBlueprintSchemaValidator";
import { ValidatorEnum, IValidatorEnumOpts } from "../../validators/ValidatorEnum";
import { applyCodeArg } from "../../Context/CompileUtil";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { ModelNodeManipulationError } from "../../Schema/ModelNodeManipulationError";
import { CMPL_ITEM_KIND, ICompletionItem } from "../../Shared/ICompletionItem";

/**
 * Schema value type
 */
export type TSchemaConstEnumValueSchema
	= TGenericBlueprintSchemaScalarWithValue<string | number>;
//= IBlueprintSchemaScalar<unknown, IModelNodeConst<TSchemaConstEnumValueSchema, string | number>, string | number>;


/**
 * Enum entry
 */
export interface ISchemaConstEnumOptionEntry<
	TValueSchema extends TSchemaConstEnumValueSchema
> {
	value?: TGetBlueprintSchemaSpec<TValueSchema>;
	label?: string;
	description?: string;
	icon?: string;
	separator?: boolean
}

/**
 * Enum separator
 */
export interface ISchemaConstEnumOptionSeparator {
	separator: true;
}

/**
 * Schema model
 */
export interface ISchemaConstEnumModel<
	TValueSchema extends TSchemaConstEnumValueSchema
> extends IModelNode<ISchemaConstEnum<TValueSchema>> {
	value: TGetBlueprintSchemaSpec<TValueSchema>;
	__value: TGetBlueprintSchemaModel<TValueSchema>;
}

/**
 * Schema options
 */
export interface ISchemaConstEnumOpts<
	TValueSchema extends TSchemaConstEnumValueSchema
	> extends IBlueprintSchemaOpts {
	/** Schema representing an enum value */
	value: TValueSchema;
	/** Available options to select from */
	options: ISchemaConstEnumOptionEntry<TValueSchema>[];
	// options: Array<ISchemaConstEnumOptionEntry<TValueSchema> | ISchemaConstEnumOptionSeparator>;
	/** Base validation constraints */
	constraints?: Omit<IValidatorEnumOpts, "enum">;
	/** Custom validators */
	validators?: IBlueprintSchemaValidatorHandler<TGetBlueprintSchemaSpec<TValueSchema>>[];
	/** Fallback value to return when validation fails */
	fallbackValue?: TGetBlueprintSchemaSpec<TValueSchema>;
}

/**
 * Schema type
 */
export interface ISchemaConstEnum<TValueSchema extends TSchemaConstEnumValueSchema> extends IBlueprintSchemaScalar<
	ISchemaConstEnumOpts<TValueSchema>,
	ISchemaConstEnumModel<TValueSchema>,
	TGetBlueprintSchemaSpec<TValueSchema>
> { }

/**
 * Schema: Enum scalar constant
 *
 * @param opts Schema options
 */
export function SchemaConstEnum<TValueSchema extends TSchemaConstEnumValueSchema>(
	opts: ISchemaConstEnumOpts<TValueSchema>
): ISchemaConstEnum<TValueSchema> {

	type TSpecType = TGetBlueprintSchemaSpec<TValueSchema>;
	type TValueModel = TGetBlueprintSchemaModel<TValueSchema>;

	const valueMap = opts.options
		.filter((o) => o["separator"] === undefined)
		.map((o: ISchemaConstEnumOptionEntry<TValueSchema>) => o.value);

	const validators: IBlueprintSchemaValidatorHandler<TSpecType>[] = [
		ValidatorEnum({
			...opts.constraints || {},
			enum: valueMap
		})
	].concat(opts.validators || []);

	const errSeverity = opts.fallbackValue !== undefined ? DOC_ERROR_SEVERITY.WARNING : DOC_ERROR_SEVERITY.ERROR;

	function valueInEnum(value: TSpecType): boolean {

		// console.log('valueMap:', value, " in ", valueMap);

		return valueMap.includes(value);
	}

	const schema = createEmptySchema<ISchemaConstEnum<TValueSchema>>("constEnum", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, "__value")
	}

	const createModel = (dCtx: DesignContext, valueModel: TValueModel, parent, validationErrors: IBlueprintSchemaValidationError[]) => {

		const modelNode = createModelNode(schema, dCtx, parent, validationErrors, {
			__value: valueModel,
			value: null
		});

		const model = assignParentToChildrenOf(modelNode)

		model.value = model.__value.value as TSpecType;

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		const value = defaultValue !== undefined ? defaultValue : null;
		const valueModel = opts.value.createDefault(dCtx, parent, value) as TValueModel;

		const errors = validateDefaultValue(schema, validators, valueModel.value as TSpecType);
		return createModel(dCtx, valueModel, parent, errors);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		const __value = opts.value.clone(dCtx, modelNode.__value, null) as TValueModel

		const clone = cloneModelNode(dCtx, modelNode, parent,{
			__value: __value,
			value: __value.value as TSpecType
		});

		return assignParentToChildrenOf(clone)
	};

	schema.destroy = (modelNode): void => {

		modelNode.__value.schema.destroy(modelNode.__value);
		modelNode.__value = undefined;

		modelNode.value = undefined;

		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent) => {

		// Check null
		if (!idtNode || (idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL)) {
			return schema.createDefault(dCtx, parent);
		}

		const parsed = opts.value.parse(dCtx, idtNode, null) as ISchemaConstIntegerModel | ISchemaConstFloatModel | ISchemaConstStringModel;

		const errors = validateParsedValueAndReport(dCtx, idtNode, validators, parsed.value as TSpecType);

		return createModel(dCtx, parsed as TValueModel, parent, errors);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			const items: ICompletionItem[] = [];

			for (let i = 0; i < opts.options.length; i++) {
				items.push({
					kind: CMPL_ITEM_KIND.EnumMember,
					label: String(opts.options[i].value),
					insertText: String(opts.options[i].value)
				});
			}

			return items;
		});

	};

	schema.serialize = (modelNode, path) => {

		const serializedValue = opts.value.serialize(modelNode.__value, path) as IBlueprintIDTScalar;

		return {
			type: BP_IDT_TYPE.SCALAR,
			subType: serializedValue.subType,
			path,
			value: serializedValue.value,
		} as IBlueprintIDTScalar;

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec?) => {

		modelNode.lastScopeFromRender = scope;

		const value = opts.value.render(rCtx, modelNode.__value, path, scope, prevSpec) as TSpecType;

		return renderScalarNode(
			modelNode, scope,
			value,
			opts.fallbackValue || valueMap[0] || null
		);

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		const valueCmp = opts.value.compileRender(cCtx, modelNode.__value as TValueModel, path);

		return compileScalarNodeRender(
			cCtx, modelNode, path, errSeverity,
			applyCodeArg(valueCmp),
			opts.fallbackValue || valueMap[0] || null
		);

	};

	schema.setValue = (modelNode, value, notify): void => {

		validateValueAndUpdateModel(modelNode, validators, value);

		if (!valueInEnum(value)) {
			if (!(value === null && !schema.opts?.constraints?.required)) {
				throw new ModelNodeManipulationError(schema.name, schema.opts, `Expecting value to be one of [ ${valueMap.join(", ")} ]`);
			}
		}

		opts.value.setValue(modelNode.__value, value, false);
		modelNode.value = modelNode.__value.value as TSpecType;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}

	};

	schema.validate = (rCtx, path, modelNodeId, value) => {
		return applyRuntimeValidators(rCtx, path, modelNodeId, validators, errSeverity, value);
	};

	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileRuntimeValidators(cCtx, path, modelNodeId, validators, errSeverity);
	};

	schema.getValue = (modelNode) => {
		return modelNode.value;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaConstEnum", [opts]);
	};

	// Check allowed value types
	if (!['constInteger', 'constFloat', 'constString'].includes(opts.value.name)) {
		throw new SchemaDeclarationError(schema.name, schema.opts, `Unsupported schema type. Expected a(an) integer, float or string.`);
	}

	// Check default value
	const defModel = opts.value.createDefault(new DesignContext({
		resolvers: {}
	}), null);

	// console.log('defModel.value:', defModel.value, defModel);

	if (defModel.value !== null && !valueInEnum(defModel.value as TSpecType)) {
		throw new SchemaDeclarationError(
			schema.name,
			schema.opts,
			`Expecting schema's default value to be one of [ ${valueMap.join(", ")} ]`
		);
	}

	schema.getTypeDescriptor = (modelNode) => {
		return defModel.schema.getTypeDescriptor(modelNode?.__value);
	}

	schema.getChildNodes = (modelNode) => {
		return [{
			key: "__value",
			node: modelNode.__value
		}];
	}

	return schema;

}
