/**
 * Field component runtime terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const fileField = {
	dropOrClick: {
		label: "component:fileField.dropOrClick#label"
	},
	dropOrTap: {
		label: "component:fileField.dropOrTap#label"
	},
	maxFilesExceeded: {
		message: "component:fileField.maxFilesExceeded#message"
	},
	maxFileSizeExceeded: {
		message: "component:fileField.maxFileSizeExceeded#message"
	},
	uploadFile: {
		label: "component:fileField.uploadFile#label"
	},
	cancelUpload: {
		label: "component:fileField.cancelUpload#label"
	},
	removeFile: {
		label: "component:fileField.removeFile#label"
	},
	deleteFile: {
		label: "component:fileField.deleteFile#label"
	},
	retryUpload: {
		label: "component:fileField.retryUpload#label"
	}
};
