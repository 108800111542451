/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const SECRETS = {
	/** Attempt to renew token every 30 seconds. */
	VAULT_RENEW_INTERVAL: 30000
}