/**
 * File Field HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	BP,
	COMPONENT_MODE,
	createSubScope,
	defineElementaryComponent,
	Type
} from "@hexio_io/hae-lib-blueprint";

import {
	Button,
	ClassList,
	MIME_TYPE_ICON_NAME,
	getStringEnumValue,
	HAEComponentMainContext,
	IButtonProps,
	Icon,
	ICON_NAME,
	Label,
	T,
	THAEComponentDefinition,
	THAEComponentReact,
	useTranslate,
	isUserInteractionEnabled,
	isEventEnabled
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { FieldLabelInfo } from "./FieldLabelInfo";
import { FieldInfo } from "./FieldInfo";
import { FILE_FIELD_TYPE, FILE_FIELD_TYPE_default } from "../../Enums/FILE_FIELD_TYPE";
import { HttpRequest, HTTP_METHOD } from "@hexio_io/hae-lib-core";
import { FILE_FIELD_FILE_STATUS } from "../../Enums/FILE_FIELD_FILE_STATUS";
import { getTimestamp, isBoolean, isBrowser, TStringSet } from "@hexio_io/hae-lib-shared";
import { termsRuntime } from "../../terms";
import {
	HAEComponentFileField_State, IFileFieldFile, TFileFieldFileMap, TFileFieldMessageMap, TFileFieldRequestMap
} from "./fileFieldState";
import { HAEComponentFileField_Props } from "./fileFieldProps";
import { addMessage, getRequestSettings, updateFile } from "./fileFieldHelpers";
import { HAEComponentField_Events } from "./events";
import { initialFieldState } from "./state";
import { createFieldClassListModifiers } from "./createFieldClassListModifiers";

const HAEComponentFileField_Events = {
	...HAEComponentField_Events,

	add: {
		...termsEditor.schemas.fileField.events.add
	},

	remove: {
		...termsEditor.schemas.fileField.events.remove
	},

	delete: {
		...termsEditor.schemas.fileField.events.delete
	},

	upload: {
		...termsEditor.schemas.fileField.events.upload
	},

	uploadError: {
		...termsEditor.schemas.fileField.events.uploadError
	}
};

const HAEComponentFileField_Definition = defineElementaryComponent<
	typeof HAEComponentFileField_Props,
	HAEComponentFileField_State,
	typeof HAEComponentFileField_Events
>({
	...termsEditor.components.fileField.component,

	name: "fileField",

	category: "form",

	icon: "mdi/file-upload-outline",

	docUrl: "...",

	order: 60,

	props: HAEComponentFileField_Props,

	events: HAEComponentFileField_Events,

	resolve: (spec, state, updateStateAsync, componentInstance, rCtx) => {
		const { urlData, multiple, maxFileSize, requestTimeout } = spec;
		const multipleMax = multiple?.max;

		const { componentMode } = componentInstance;

		const messages: TFileFieldMessageMap = state?.messages || new Map();
		const requests: TFileFieldRequestMap = state?.requests || new Map();

		// Files

		let files: TFileFieldFileMap = state?.files || new Map();
		let filesSize = files.size;

		if (filesSize) {
			// Max file size

			if (Number.isFinite(maxFileSize)) {
				files = new Map([ ...files ].filter(([ key, value ]) => value.size <= maxFileSize));

				if (filesSize > files.size) {
					filesSize = files.size;

					addMessage(messages, termsRuntime.components.fileField.maxFileSizeExceeded.message);
				}
			}

			// Max number of files

			if (Number.isFinite(multipleMax)) {
				files = new Map([ ...files ].slice(0, multipleMax));
	
				if (filesSize > files.size) {
					filesSize = files.size;
	
					addMessage(messages, termsRuntime.components.fileField.maxFilesExceeded.message);
				}
			}
		}

		const waitingFilesIds: TStringSet = new Set([ ...files.values() ].filter((item) => {
			return item.status === FILE_FIELD_FILE_STATUS.WAITING;
		}).map((item) => item.id));

		// Events

		if (isUserInteractionEnabled(componentMode) && state) {
			const addedFilesIds: TStringSet = state?.addedFilesIds || new Set();
			const removedFiles: TFileFieldFileMap = state?.removedFiles || new Map();

			// Changed

			if (componentInstance.eventEnabled.change && (addedFilesIds.size || removedFiles.size)) {
				rCtx.__callAfterReconcile(() => {
					componentInstance.eventTriggers.change((parentScope) => createSubScope(parentScope));
				});
			}

			// Added

			if (componentInstance.eventEnabled.add && addedFilesIds.size) {
				rCtx.__callAfterReconcile(() => {
					const addedFiles = [ ...addedFilesIds.values() ].filter((item) => files.has(item)).map((item) => files.get(item));

					componentInstance.eventTriggers.add((parentScope) => {
						const subScope = createSubScope(
							parentScope,
							{ files: addedFiles },
							{ files: Type.Array({ items: addedFiles.map(() => Type.Any({})) }) }
						);

						return subScope;
					});
				});
			}

			// Removed

			if (componentInstance.eventEnabled.remove && removedFiles.size) {
				rCtx.__callAfterReconcile(() => {
					const removedFilesArray = [ ...removedFiles.values() ];

					componentInstance.eventTriggers.remove((parentScope) => createSubScope(
						parentScope,
						{ files: removedFilesArray },
						{ files: Type.Array({ items: removedFilesArray.map(() => Type.Any({})) }) }
					));
				});
			}
		}

		// Validation

		const valid = spec.validate ?
			((!spec.required || !!filesSize) && (!spec.customValidation || spec.customValidation.condition)) :
			true;

		// Upload All

		function uploadAll() {
			waitingFilesIds.forEach(upload);
		}

		// Upload File

		async function upload(fileId: string) {
			const sourceFile = files.has(fileId) ? files.get(fileId) : null;

			if (!sourceFile) {
				return;
			}

			const file = { ...sourceFile };
			const { id } = file;

			if (file.status === FILE_FIELD_FILE_STATUS.UPLOADING || requests.has(id)) {
				return;
			}

			const requestSettings = await getRequestSettings(urlData, file.data, rCtx);

			if (!requestSettings || requestSettings.error || !HTTP_METHOD[requestSettings.method]) {
				updateStateAsync((prevState) => {
					file.status = FILE_FIELD_FILE_STATUS.ERROR;
					file.error = requestSettings.error;
	
					return updateFile(prevState, file);
				});

				return;
			}

			const method = HTTP_METHOD[requestSettings.method];
			const { url, headers, formDataKey } = requestSettings;

			file.uploadUrl = url;
			file.objectName = requestSettings.objectName;

			let data: FormData | File;

			if (formDataKey) {
				data = new FormData();
				data.append(formDataKey, file.data);
			}
			else {
				data = file.data;
			}

			const request = new HttpRequest(
				method,
				url,
				undefined,
				data,
				headers,
				requestTimeout
			);

			request.onUploadProgress((value) => {
				if (!isBrowser()) {
					return;
				}

				const progressElement = document.querySelector(`.${file.pathId}_progress`) as HTMLDivElement;

				if (progressElement) {
					progressElement.style.width = `${value * 100}%`;
				}
			});

			requests.set(id, request);

			updateStateAsync((prevState) => {
				file.status = FILE_FIELD_FILE_STATUS.UPLOADING;

				return updateFile(prevState, file);
			});

			try {
				const response = await request.send();

				updateStateAsync((prevState) => {
					file.status = FILE_FIELD_FILE_STATUS.UPLOADED;
					file.response = response;

					if (isEventEnabled(componentInstance.eventEnabled.upload, componentMode)) {
						componentInstance.eventTriggers.upload((parentScope) => {
							return createSubScope(parentScope, { file }, { file: Type.Any({}) });
						});
					}
	
					return updateFile(prevState, file);
				});
			}
			catch (error) {
				updateStateAsync((prevState) => {
					file.status = FILE_FIELD_FILE_STATUS.ERROR;
					file.error = error;

					if (isEventEnabled(componentInstance.eventEnabled.uploadError, componentMode)) {
						componentInstance.eventTriggers.uploadError((parentScope) => {
							return createSubScope(parentScope, { file }, { file: Type.Any({}) });
						});
					}
	
					return updateFile(prevState, file);
				});
			}

			requests.delete(id);
		}

		// Remove All

		function removeAll() {
			updateStateAsync((prevState) => {
				const requests = new Map(prevState.requests);

				requests.forEach((item) => item.abort());
				requests.clear();

				return {
					...prevState,
					files: new Map(),
					removedFiles: prevState.files,
					requests
				}
			});
		}

		// Remove File

		function remove(fileId: string) {
			updateStateAsync((prevState) => {
				const files = new Map(prevState.files);
				const removedFiles: TFileFieldFileMap = new Map();
				const requests = new Map(prevState.requests);

				if (files.has(fileId)) {
					removedFiles.set(fileId, files.get(fileId));

					files.delete(fileId);
				}

				if (requests.has(fileId)) {
					requests.get(fileId).abort();
					requests.delete(fileId);
				}

				return {
					...prevState,
					files,
					removedFiles,
					requests
				};
			});
		}

		// Cancel upload

		function cancel(fileId: string) {
			updateStateAsync((prevState) => {
				const files = new Map(prevState.files);
				const requests = new Map(prevState.requests);
				
				if (files.has(fileId)) {
					const file = files.get(fileId);

					if (file.status === FILE_FIELD_FILE_STATUS.UPLOADING) {
						files.set(fileId, {
							...file,
							status: FILE_FIELD_FILE_STATUS.WAITING
						});
					}

					if (requests.has(fileId)) {
						requests.get(fileId).abort();
						requests.delete(fileId);
					}
				}

				return {
					...prevState,
					files,
					requests
				};
			});
		}

		return {
			files,
			addedFilesIds: new Set(),
			removedFiles: new Map(),
			waitingFilesIds,
			empty: !files.size,
			touched: isBoolean(state?.touched) ? state.touched : initialFieldState.touched,
			changed: isBoolean(state?.changed) ? state.changed : initialFieldState.changed,
			valid,
			uploadAll,
			upload,
			removeAll,
			remove,
			cancel,
			messages,
			requests
		};
	},

	getScopeData: (spec, state) => {
		return {
			files: [ ...state.files.values() ].map((item) => {
				const result: IFileFieldFile = {
					id: item.id,
					name: item.name,
					type: item.type,
					size: item.size,
					status: item.status,
					uploadUrl: item.uploadUrl || "",
					objectName: item.objectName || null,
					response: item.response || null,
					error: item.error || null
				};

				return result;
			}),
			valid: state.valid,
			uploadAll: state.uploadAll,
			upload: state.upload,
			removeAll: state.removeAll,
			remove: state.remove,
			cancel: state.cancel,
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				files: Type.Array({
					...termsEditor.schemas.fileField.files,
					items: [ ...state.files.values() ].map(() => Type.Any({}))
				}),

				valid: Type.Boolean({ ...termsEditor.schemas.field.valid }),

				uploadAll: Type.Method({
					...termsEditor.schemas.fileField.uploadAll,
					argRequiredCount: 0,
					argSchemas: [],
					argRestSchema: null,
					returnType: Type.Void({})
				}),

				upload: Type.Method({
					...termsEditor.schemas.fileField.upload,
					argRequiredCount: 1,
					argSchemas: [ BP.String({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),

				removeAll: Type.Method({
					...termsEditor.schemas.fileField.removeAll,
					argRequiredCount: 0,
					argSchemas: [],
					argRestSchema: null,
					returnType: Type.Void({})
				}),

				remove: Type.Method({
					...termsEditor.schemas.fileField.remove,
					argRequiredCount: 1,
					argSchemas: [ BP.String({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),

				cancel: Type.Method({
					...termsEditor.schemas.fileField.cancel,
					argRequiredCount: 1,
					argSchemas: [ BP.String({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});

const HAEComponentFileField_React: THAEComponentReact<typeof HAEComponentFileField_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const {
		uploadImmediately,
		typeData,
		accept,
		multiple,
		customPlaceholder,
		customPlaceholderTouch,

		labelText,
		labelIcon,
		descriptionText,
		//hidden,
		enabled,
		validate,
		required
	} = props;

	const {
		files, waitingFilesIds, empty, touched, changed, valid, uploadAll, upload, removeAll, remove, cancel, messages, requests
	} = state;

	const { componentMode } = componentInstance;

	const readOnly = componentMode !== COMPONENT_MODE.NORMAL;

	const componentMainContext = React.useContext(HAEComponentMainContext);

	const t = useTranslate();

	const componentPath = componentInstance.safePath;

	const typeValue = getStringEnumValue(FILE_FIELD_TYPE, typeData.type, FILE_FIELD_TYPE_default);

	const fileNames = typeValue === FILE_FIELD_TYPE.SIMPLE ? typeData.value[typeData.type].fileNames : false;
	const fileNamesActive = fileNames && !empty;

	// Text

	const dropText = React.useMemo(() => {
		if (isBrowser() && ("ontouchstart" in window || navigator.maxTouchPoints > 0)) {
			return customPlaceholderTouch || termsRuntime.components.fileField.dropOrTap.label;
		}

		return customPlaceholder || termsRuntime.components.fileField.dropOrClick.label;
	}, [ customPlaceholder, customPlaceholderTouch ]);

	// Classlist

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-field",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);
	const id = idClassName;

	classList.add(
		"cmp-field--file",
		`cmp-field--file-type-${typeValue}`
	);

	classList.addModifiers({
		filenames: fileNames,
		validate
	});
	classList.addModifiers(createFieldClassListModifiers(classList, { enabled, empty, touched, changed, valid }), false);

	// Immediate upload

	React.useEffect(() => {
		if (!uploadImmediately || !waitingFilesIds.size) {
			return;
		}

		uploadAll();
	}, [ [ ...waitingFilesIds.values() ].join(",") ]);

	// Cancel all requests on unmount

	React.useEffect(() => {
		return () => {
			requests.forEach((item) => item.abort());
		};
	}, []);

	const messagesKeysString = [ ...messages.keys() ].join(",");

	// Toast messages

	React.useEffect(() => {
		if (!messages.size) {
			return;
		}

		messages.forEach((item) => {
			componentMainContext.toastMessageManager.addItemData({
				id: item.message,
				type: item.type,
				message: t("runtime", item.message)
			});
		});
	}, [ messagesKeysString ]);

	// Event handlers

	const _inputClickHandler = React.useMemo(() => {
		if (readOnly) {
			return;
		}

		return () => {
			if (messages.size) {
				messages.forEach((item) => {
					componentMainContext.toastMessageManager.removeItem(item.id);
				});

				setState((prevState) => ({
					...prevState,
					messages: new Map()
				}));
			}
		};
	}, [ messagesKeysString, readOnly, setState ]);

	const _inputChangeHandler = React.useMemo(() => {
		if (readOnly) {
			return;
		}

		return (event: React.FormEvent<HTMLInputElement>) => {
			// Touched is not set on click as it causes field to be marked as invalid before user even picks some file

			setState((prevState) => ({ ...prevState, touched: true }));

			const inputFiles = Array.from(event.currentTarget.files);

			if (!inputFiles.length) {
				return;
			}

			const newFiles = new Map(files);
			const addedFilesIds: TStringSet = new Set();

			const timestamp = getTimestamp();

			inputFiles.forEach((item, index) => {
				const id = `${timestamp}_${index}`;

				newFiles.set(id, {
					id,
					pathId: `${idClassName}_${id}`,
					name: item.name,
					type: item.type,
					size: item.size,
					data: item,
					status: FILE_FIELD_FILE_STATUS.WAITING,
					timestamp
				});

				addedFilesIds.add(id);
			});

			// Set changed and state

			setState((prevState) => ({
				...prevState,
				files: newFiles,
				addedFilesIds,
				changed: true
			}));
		};
	}, [ [ ...files.keys() ].join(","), readOnly, setState ]);

	const _clearButtonClickHandler = React.useMemo(() => {
		if (!fileNamesActive || readOnly) {
			return;
		}

		return () => {
			removeAll();
		};
	}, [ fileNamesActive, readOnly, removeAll ]);

	return (
		<div className={classList.toClassName()}>
			<Label
				text={{ ...labelText, tagName: "span" }}
				icon={{ ...labelIcon, size: "SMALL" }}
				tagName="label"
				htmlFor={id}
				classList={new ClassList("cmp-field__label")}
				componentPath={[ ...componentPath, "label" ]}
				componentMode={componentMode}
			>
				<FieldLabelInfo required={required} />
			</Label>

			<div className="cmp-field__content">
				<div className="cmp-field__file">
					<div className="cmp-field__file-select">
						<div className="cmp-field__file-text">
							<div className="cmp-field__file-text-inner">
								<T domain="runtime" term={dropText} />
							</div>

							{
								fileNamesActive ?
									<div className="cmp-field__file-simple">
										<div className="cmp-field__file-simple-files">
											{
												[ ...files.values() ].map((item) => item.name).join(", ")
											}
										</div>

										<Button
											style="CLEAR"
											labelIcon={{ source: ICON_NAME.CLOSE }}
											enabled={enabled}
											componentPath={[ ...componentPath, "clear-button" ]}
											componentMode={componentMode}
											classList={new ClassList("cmp-field__file-simple-clear-button")}
											onClick={_clearButtonClickHandler}
										/>
									</div> :
									null
							}
						</div>

						{
							!readOnly ?
								<input
									id={id}
									type="file"
									value=""
									accept={accept}
									multiple={!!multiple}
									disabled={!enabled}
									className="cmp-field__file-input"
									onClick={_inputClickHandler}
									onChange={_inputChangeHandler}
								/> :
								null
						}
					</div>

					{
						typeValue === FILE_FIELD_TYPE.LIST && !empty ?
							<ul className="cmp-field__file-list">
								{
									[ ...files.values() ].map((item) => {
										const itemClassList = new ClassList("cmp-field__file-list-item");

										itemClassList.add(`cmp-field__file-list-item--status-${item.status.toLowerCase()}`)

										const iconSource = MIME_TYPE_ICON_NAME[item.type] ||
											MIME_TYPE_ICON_NAME[item.type.split("/")[0]] ||
											MIME_TYPE_ICON_NAME.DEFAULT;

										const optionsContent = [];
										const buttons: (Omit<IButtonProps, "componentMode"> & { key: string; })[] = [];

										switch (item.status) {
											case FILE_FIELD_FILE_STATUS.WAITING: {
												if (!uploadImmediately) {
													buttons.push({
														key: "upload",
														labelIcon: { source: ICON_NAME.UPLOAD },
														tooltip: t("runtime", termsRuntime.components.fileField.uploadFile.label),
														componentPath: [ ...componentPath, item.id, "upload-button" ],
														onClick: () => !readOnly && upload(item.id)
													});
												}

												buttons.push({
													key: "remove",
													labelIcon: { source: ICON_NAME.DELETE },
													tooltip: t("runtime", termsRuntime.components.fileField.removeFile.label),
													componentPath: [ ...componentPath, item.id, "remove-button" ],
													onClick: () => !readOnly && remove(item.id)
												});

												break;
											}

											case FILE_FIELD_FILE_STATUS.UPLOADING: {
												buttons.push({
													key: "cancel",
													labelIcon: { source: ICON_NAME.CLOSE },
													tooltip: t("runtime", termsRuntime.components.fileField.cancelUpload.label),
													componentPath: [ ...componentPath, item.id, "cancel-button" ],
													onClick: () => !readOnly && cancel(item.id)
												});

												break;
											}

											case FILE_FIELD_FILE_STATUS.UPLOADED: {
												optionsContent.push(
													<Icon
														key="icon"
														source={ICON_NAME.CHECK}
														size="MEDIUM"
														foregroundColor="SUCCESS"
														componentPath={[ ...componentPath, item.id, "success-icon" ]}
														componentMode={componentMode}
														classList={new ClassList("cmp-field__file-list-success-icon")}
													/>
												);

												if (componentInstance.eventEnabled.delete) {
													buttons.push({
														key: "delete",
														labelIcon: { source: ICON_NAME.DELETE },
														tooltip: t("runtime", termsRuntime.components.fileField.deleteFile.label),
														componentPath: [ ...componentPath, item.id, "delete-button" ],
														onClick: async () => {
															if (!readOnly) {
																const files = [ item ];

																await componentInstance.eventTriggers.delete((parentScope) => {
																	return createSubScope(
																		parentScope,
																		{ files },
																		{ files: Type.Array({ items: files.map(() => Type.Any({})) }) }
																	);
																});

																remove(item.id);
															}
														}
													});
												}

												break;
											}

											case FILE_FIELD_FILE_STATUS.ERROR: {
												buttons.push({
													key: "retry",
													foregroundColor: "ERROR",
													labelIcon: { source: ICON_NAME.RELOAD },
													tooltip: t("runtime", termsRuntime.components.fileField.retryUpload.label),
													componentPath: [ ...componentPath, item.id, "upload-button" ],
													onClick: () => !readOnly && upload(item.id)
												}, {
													key: "remove-2",
													labelIcon: { source: ICON_NAME.DELETE },
													tooltip: t("runtime", termsRuntime.components.fileField.removeFile.label),
													componentPath: [ ...componentPath, item.id, "remove-button" ],
													onClick: () => !readOnly && remove(item.id)
												});

												break;
											}
										}

										optionsContent.push(buttons.map((item) => {
											return (
												<Button
													style="CLEAR"
													componentMode={componentMode}
													enabled={enabled}
													classList={new ClassList("cmp-field__file-list-button")}
													{...item}
													labelIcon={{ ...item.labelIcon, size: "SMALL" }}
												/>
											);
										}));

										return (
											<li
												key={item.id}
												className={itemClassList.toClassName()}
											>
												<Icon
													source={iconSource}
													size="MEDIUM"
													componentPath={[ ...componentPath, item.id, "icon" ]}
													componentMode={componentMode}
													classList={new ClassList("cmp-field__file-list-icon")}
												/>

												<div className="cmp-field__file-list-info">
													<div className="cmp-field__file-list-name">{item.name}</div>
													<div className="cmp-field__file-list-status">
														<div className={`cmp-field__file-list-progress ${item.pathId}_progress`} />
													</div>
												</div>

												<div className="cmp-field__file-list-options">
													{optionsContent}
												</div>
											</li>
										);
									})
								}
							</ul> :
							null
					}
				</div>
			</div>

			<FieldInfo descriptionText={descriptionText} componentPath={[ ...componentPath, "info" ]} componentMode={componentMode} />
		</div>
	);
};

export const HAEComponentFileField: THAEComponentDefinition<typeof HAEComponentFileField_Definition> = {
	...HAEComponentFileField_Definition,
	reactComponent: HAEComponentFileField_React
};
