/**
 * Text component terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const repeat = {
	component: {
		label: "component:repeat#label",
		description: "component:repeat#description"
	},
	schema: {
		items: {
			label: "component:repeat.schema.items#label",
			description: "component:repeat.schema.items#description"
		},
		contentTemplate: {
			label: "component:repeat.schema.contentTemplate#label",
			description: "component:repeat.schema.contentTemplate#description"
		},
		key: {
			label: "component:repeat.schema.key#label",
			description: "component:repeat.schema.key#description"
		}
	},
	typeDesc: {
		elements: {
			label: "component:repeat.typeDesc.elements#label",
			description: "component:repeat.typeDesc.elements#description",
		}
	}
};
