/**
 * Link schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const link = {
	schema: {
		label: "schema:link#label"
	},
	target: {
		label: "schema:link.target#label"
	},
	targetValues: {
		self: {
			label: "schema:link.target#values.self#label"
		},
		blank: {
			label: "schema:link.target#values.blank#label"
		},
		top: {
			label: "schema:link.target#values.top#label"
		}
	},
	enabled: {
		label: "schema:link.enabled#label"
	},
	exact: {
		label: "schema:link.exact#label"
	}
};
