/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

function truncateString(value: string, maxLength: number) {
	if (value.length > maxLength) {
		return value.substring(0, maxLength)
			+ `(...truncated ${value.length - maxLength} characters)`;
	} else {
		return value;
	}
}

export interface TTruncateDataOpts {
	maxStringLength: number;
}

/**
 * Truncates data IN place.
 * Traverses the data structure and makes sure that data values are truncated to the configured sizes.
 * Function updates values in-place
 *
 * @param data Data
 * @param opts Options
 * @returns 
 */
export function truncateData<T>(data: T, opts: TTruncateDataOpts): T {
	if (data instanceof Array) {
		for (let i = 0; i < data.length; i++) {
			if (typeof data[i] === "string") {
				data[i] = truncateString(data[i], opts.maxStringLength);
			} else {
				truncateData(data[i], opts);
			}
		}

		return data;
	} else if (data instanceof Object) {
		for (const k in data) {
			if (typeof data[k] === "string") {
				data[k] = truncateString(data[k] as unknown as string, opts.maxStringLength) as unknown as typeof data[typeof k];
			} else {
				truncateData(data[k], opts);
			}
		}

		return data;
	} else if (typeof data === "string") {
		return truncateString(data as string, opts.maxStringLength) as unknown as T;
	} else {
		return data;
	}
}