import { INamedConfigResolver } from "@hexio_io/hae-lib-blueprint";
import { HexioAuthenticationOptionsSchema } from "../blueprints";

export const AuthenticationOptionsResolver: INamedConfigResolver = {

	getOptsSchemaByName: () => {
		return HexioAuthenticationOptionsSchema
	},

	getNameList: () => []

};
