/**
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	dataToIDT,
	DesignContext,
	ISchemaComponentList,
	ISchemaComponentListModel,
	ISchemaComponentModel,
	ISchemaConstObject,
	serializeIDTToData,
	TGenericComponentInstance,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec,
	TSchemaConstObjectProps
} from "@hexio_io/hae-lib-blueprint";
import {
	createEventEmitter, emitEvent, getTimestamp, isDefined, isFunction, isNonEmptyArray, TSimpleEventEmitter
} from "@hexio_io/hae-lib-shared";
import { IElementDimensions, IElementOffset } from "./IEditCommon";

const DND_METADATA_TYPE_PREFIX = "application/vnd.hae.dnd.components#metadata:";
const DND_LEAVE_CHECK_DELAY = 200;
const DND_LEAVE_TTL = 500;

export type TDropTargetRef = TGetBlueprintSchemaModel<ISchemaComponentList<TSchemaConstObjectProps>>;

export enum DND_MODE {
	MOVE = "move",
	COPY = "copy",
	DEFAULT = COPY
}

export enum DND_DROP_TYPE {
	INSERT = "insert",
	REPLACE = "replace"
}

/**
 * Object containing an exported component to be serialized into a Data Transfer Object (DTO)
 */
export interface IDnDSerializedComponentItem {
	/** Component node ID */
	nodeId: number;

	/** Serialized component */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	blueprint: any;
}

/**
 * Object containing an exported components state to be serialized into a Data Transfer Object (DTO)
 */
export interface IDnDSerializedComponents {
	/** Editor instance ID */
	contextId: string;

	/** Components */
	items: IDnDSerializedComponentItem[];
}

/**
 * Serialized component meta-data
 * NOTE: All props must be lowercase because browser converts "type" name to lowercase 🤦‍♂️
 */
export interface IDnDSerializedComponentMetaDataItem {
	/** Component node ID */
	nodeid: number;

	/** Element dimensions */
	dimensions: IElementDimensions;

	/** Element offset relative to drag cursor */
	offset: IElementOffset;

	/** Serialized component's inherited props value (it's spec not blueprint!) */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	inheritedpropsspec: any;
}

/**
 * Serialized component drag'n'drop meta-data
 * NOTE: All props must be lowercase because browser converts "type" name to lowercase 🤦‍♂️
 */
export interface IDnDSerializedComponentMetaData {
	/** Editor instance ID */
	contextid: string;

	/** Meta-data of dragged items */
	items: IDnDSerializedComponentMetaDataItem[];
}

export interface ISourceItem {
	/** Selected component model node ID */
	nodeId: number;

	/** Component instance */
	cmpInstance: TGenericComponentInstance;

	/** Element dimensions */
	dimensions: IElementDimensions;

	/** Element offset relative to drag cursor */
	offset: IElementOffset;
}

/**
 * Object containing new component data (to be serialized)
 */
export interface INewItem {
	/** Serialized component */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	blueprint: any;

	/** Element dimensions */
	dimensions: IElementDimensions;

	/** Element offset relative to drag cursor */
	offset: IElementOffset;
}

/**
 * Object containing meta-data about position, inherited props and dimensions of the dropped item
 */
export interface IDropElement {
	/** Original inherites props */
	inheritedProps: TGetBlueprintSchemaSpec<ISchemaConstObject<TSchemaConstObjectProps>>,

	/** Element dimensions */
	dimensions: IElementDimensions;

	/** Element offset relative to drag cursor */
	offset: IElementOffset;
}

/**
 * Function to modify a component model on drop
 * 
 * @param itemModel Item component model
 * @param element Drop element
 */
export type TModifyModelOnDropFunction = (
	itemModel: ISchemaComponentModel,
	element: IDropElement
) => void;

/**
 * Function to be called when a new drop target is entered to determine if a new target is a valid drop zone
 *
 * @param metaData Parsed DnD Meta-data
 * @param dropTargetRef Drop target to be checked
 */
export type TCanDropFunction = (
	metaData: IDnDSerializedComponentMetaData,
	dropTargetRef: TDropTargetRef
) => boolean;

/**
 * Drag'n'drop workflow manager
 */
export class EditDnD {

	/** Mode - copy is default, when moving from local context, it's gonna be set to MOVE in start method */
	private mode: DND_MODE = DND_MODE.DEFAULT;

	/** If the dnd drop area was entered */
	private entered = false;

	/** List of drag source items (set only when drag was initialized in the same context) */
	private sourceItems: ISourceItem[] = [];

	/** Serialized dnd meta-data */
	private metaData: IDnDSerializedComponentMetaData = null;

	/** Drop target reference - to detect what is the latest drop target */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private dropTargetRef: TDropTargetRef = null;

	/** Ref to timeout that checks for DnD leaved the drop zone */
	private leaveCheckTimeout: NodeJS.Timeout;

	/** Timestamp of last drag over - do detect leave via timeout */
	private lastDragOver = 0;

	/** If drop action was just taken */
	private dropStarted = false;

	/** If should update elements */
	private updateElements = false;

	/** Emitted when dnd state change */
	public onStateChange: TSimpleEventEmitter<void> = createEventEmitter();

	/**
	 * Starts the DnD process
	 * 
	 * Returns if DnD has been sucessfully started.
	 * 
	 * @param mode Drag mode
	 * @param dCtx Design Context
	 * @param dto Data Transfer Object
	 * @param sourceItems Drag source items
	 * @param external If the drag is external (eg. from component palette)
	 */
	public start(
		mode: DND_MODE,
		dCtx: DesignContext,
		dto: DataTransfer,
		sourceItems: ISourceItem[],
		external = false
	): boolean {
		if (this.hasDropStarted()) {
			return false;
		}

		dndSetDataTransfer(dCtx, dto, sourceItems);

		if (!external) {
			this.mode = mode;
			this.sourceItems = sourceItems.slice();

			// Mark all selected components as being dragged to hide them from component list
			this.sourceItems.forEach((item) => {
				item.cmpInstance.customData.isBeingDragged = true;
			});
		}

		return true;
	}

	/**
	 * Fixes last drag over
	 */
	public fixLastDragOver(): void {
		this.lastDragOver = getTimestamp();
	}

	/**
	 * Handles where use drags over the drop zone 
	 * Returns if drop target is valid - eg. if we have a legal payload
	 *
	 * @param dto DataTransfer Object
	 * @param dropTargetRef Drop target reference
	 * @param canDropCallback Function to be called when a new drop target is entered to determine if a new target is a valid drop zone
	 */
	public over(
		dto: DataTransfer,
		dropTargetRef: TDropTargetRef,
		canDropCallback?: TCanDropFunction,
	): boolean {
		this.fixLastDragOver();

		if (this.hasDropStarted()) {
			return false;
		}

		// Skip if we already entered DnD process
		if (this.entered) {
			if (!this.isDropTarget(dropTargetRef)) {
				if (canDropCallback) {
					const metaData = parseDndMetaData(dto);

					if (!metaData) {
						return false;
					}

					if (!canDropCallback(metaData, dropTargetRef)) {
						return false;
					}
				}

				this.dropTargetRef = dropTargetRef;
				
				this.emitStateChangeEvent();
			}

			return true;
		}

		// Try to parse payload
		const metaData = parseDndMetaData(dto);

		if (!metaData) {
			this.dropTargetRef = null;

			return false;
		}

		if (!canDropCallback(metaData, dropTargetRef)) {
			return false;
		}

		// Update state
		this.entered = true;
		this.metaData = metaData;
		this.dropTargetRef = dropTargetRef;

		// Check leave
		this.fixLeaveCheckTimeout();

		this.emitStateChangeEvent();

		return true;
	}

	/**
	 * Handles the drop
	 * Drop must be performed on the current target ref
	 * 
	 * @param dto Data Transfer Object
	 * @param dropTargetRef Drop Target Reference (drop list)
	 * @param dCtx Design Context
	 * @param dropIndex Drop Index (where the components should be inserted)
	 * @param elements Drop elements (eg. properties of palceholders)
	 * @param modifyModelOnDrop Function to modify component model(s) with respect to element properties
	 * @returns A list of modified component models
	 */
	public drop(
		dto: DataTransfer,
		dropTargetRef: TDropTargetRef,
		dCtx: DesignContext,
		dropIndex: number,
		elements: IDropElement[],
		type: DND_DROP_TYPE,
		modifyModelOnDrop?: TModifyModelOnDropFunction
	): ISchemaComponentModel[] {
		if (this.entered && this.isDropTarget(dropTargetRef)) {
			this.clearLeaveCheckTimeout();
			this.clearState(false);

			this.dropStarted = true;

			const components = parseDndComponents(dto);

			let resultModels: ISchemaComponentModel[] = [];

			this.sourceItems.forEach((item) => {
				item.cmpInstance.forceCompareInvalidate = true;
			});

			if (this.dropTargetRef && components) {
				resultModels = [];

				// Remove and prepare items
				for (let i = 0; i < components.items.length; i++) {
					const cmpItem = components.items[i];
					let itemModel: ISchemaComponentModel;
			
					// Mode "move" and same context - try to find an existing node and remove it from current container
					if (this.mode === DND_MODE.MOVE && components.contextId === dCtx.getContextId()) {
						itemModel = dCtx.getNodeById(cmpItem.nodeId) as ISchemaComponentModel;
			
						// Remove from the old list
						if (itemModel) {
							itemModel.schema.removeSelfFromComponentList(itemModel, true);
						}
					}

					// If item not exists, create it
					if (!itemModel) {
						const idt = dataToIDT(cmpItem.blueprint, "dnd://import", ["$"]);
			
						dCtx.clearParseDocumentErrors("dnd://import");

						itemModel = this.dropTargetRef.schema.componentSchema.parse(dCtx, idt, null, {
							componentListModel: this.dropTargetRef as ISchemaComponentListModel<Record<string, never>>
						});

						if (!itemModel) {
							console.warn(
								"Failed to create model from drop", cmpItem.blueprint, dCtx.getParseDocumentErrors("dnd://import")
							);
							continue;
						}

						// Update id to be unique
						const newId = dCtx.getUniqueIdentifier(itemModel.id.value, true);
						itemModel.id.schema.setValue(itemModel.id, newId, true);
					}

					resultModels.push(itemModel);
				}

				// Remove all?
				if (type === DND_DROP_TYPE.REPLACE) {
					this.dropTargetRef.schema.removeAllItems(
						this.dropTargetRef as ISchemaComponentListModel<Record<string, never>>,
						true
					);
				}

				// Add items (must be done after all previous are remove due to correct ordering)
				for (let i = resultModels.length - 1; i >= 0; i--) {
					this.dropTargetRef.schema.addItemModel(
						this.dropTargetRef as ISchemaComponentListModel<Record<string, never>>,
						dropIndex,
						resultModels[i],
						true
					);
			
					// Modify model
					if (isFunction(modifyModelOnDrop)) {
						modifyModelOnDrop(resultModels[i], elements[i]);
					}
				}
			}
			else {
				console.warn("Cannot perform drop - no target or cannot parse components data.");
			}

			return resultModels;
		}
		else {
			return null;
		}
	}

	/**
	 * Ends the DnD process
	 * Should be called on "dropend" event bound to the dragged item (eg. starting item)
	 */
	public end(): void {
		// Ignore when moving, will be finished via `finishDrop` method
		if (this.mode === DND_MODE.MOVE && this.dropStarted) {
			return;
		}

		this.clear();

		this.updateElements = true;

		this.emitStateChangeEvent();

		this.updateElements = false;
	}

	/**
	 * Finishes the drop process - called by the drop zone
	 * Must be called by the drop zone (drop target) to finish the DnD.
	 * This method should be called after target list is refreshed (eg. React re-rendered after model render)
	 * 
	 * @param dropTargetRef Drop target ref
	 */
	public finishDrop(dropTargetRef?: TDropTargetRef): void {
		if (!this.dropStarted || (isDefined(dropTargetRef) && !this.isDropTarget(dropTargetRef))) {
			return;
		}

		this.clear();

		this.dropStarted = false;

		this.emitStateChangeEvent();
	}

	/**
	 * Returns if provided drop target ref is the current one
	 *
	 * @param dropTargetRef Drop target ref to check
	 */
	public isDropTarget(dropTargetRef: TDropTargetRef): boolean {
		return this.dropTargetRef === dropTargetRef;
	}

	/**
	 * Returns if the DnD zone was entered
	 */
	public wasEntered(): boolean {
		return this.entered;
	}

	/**
	 * Returns serialied meta-data
	 */
	public getMetaData(): IDnDSerializedComponentMetaData {
		return this.metaData;
	}

	/**
	 * If should update elements
	 *
	 * Is set to true on dragend before change event is emitted to signal component list to re-render
	 * because if it has any dragged (eg. hidden) components, they must be re-rendered.
	 */
	public shouldUpdateElements(): boolean {
		return this.updateElements;
	}

	/**
	 * Checks if drop has started
	 */
	private hasDropStarted(): boolean {
		if (this.dropStarted) {
			console.warn("Drop hasn't been finished!");

			return true;
		}
	}

	/**
	 * Clears leave check timeout
	 */
	private clearLeaveCheckTimeout() {
		if (this.leaveCheckTimeout) {
			clearTimeout(this.leaveCheckTimeout);
		}
	}

	/**
	 * Fixes leave check timeout (drop target timed-out)
	 */
	private fixLeaveCheckTimeout() {
		this.clearLeaveCheckTimeout();

		this.leaveCheckTimeout = setTimeout(() => {
			if (this.entered && this.lastDragOver < (getTimestamp() - DND_LEAVE_TTL)) {
				this.leave();
			}
			else {
				this.fixLeaveCheckTimeout();
			}
		}, DND_LEAVE_CHECK_DELAY);
	}

	/**
	 * Leave the drop zone(s)
	 */
	private leave() {
		this.clearState();

		this.dropStarted = false;

		this.emitStateChangeEvent();
	}

	/**
	 * Resets mode
	 */
	private resetMode() {
		this.mode = DND_MODE.DEFAULT;
	}

	/**
	 * Clears state
	 */
	private clearState(clearDropTargetRef = true) {
		this.entered = false;
		this.metaData = null;

		if (clearDropTargetRef) {
			this.dropTargetRef = null;
		}
	}
	
	/**
	 * Clears source items
	 */
	private clearSourceItems() {
		// Mark all selected components as not being dragged anymore
		if (isNonEmptyArray(this.sourceItems)) {
			this.sourceItems.forEach((item) => {
				item.cmpInstance.customData.isBeingDragged = false;
			});
		}

		this.sourceItems = [];
	}

	/**
	 * Clears all
	 */
	private clear() {
		this.clearState();
		this.clearSourceItems();
		this.clearLeaveCheckTimeout();
		this.resetMode();
	}

	/** 
	 * Emits state change event
	 */
	private emitStateChangeEvent() {
		emitEvent(this.onStateChange);
	}

}

/**
 * Serializes selected component items into DataTransfer object
 *
 * @param dCtx Design Context
 * @param dto DataTransfer
 * @param sourceItems Drag source items
 */
export function dndSetDataTransfer(
	dCtx: DesignContext,
	dto: DataTransfer,
	sourceItems: ISourceItem[]
): void {

	const components: IDnDSerializedComponents = {
		contextId: dCtx.getContextId(),
		items: []
	};

	const metaData: IDnDSerializedComponentMetaData = {
		contextid: dCtx.getContextId(),
		items: []
	};

	sourceItems.forEach((item) => {

		const model = dCtx.getNodeById(item.nodeId);

		if (!model) {
			return;
		}

		components.items.push({
			nodeId: item.nodeId,
			blueprint: serializeIDTToData(model.schema.serialize(model, ["$"]))
		});

		metaData.items.push({
			nodeid: item.nodeId,
			inheritedpropsspec: item.cmpInstance.inheritedProps,
			dimensions: item.dimensions,
			offset: item.offset
		});

	});

	const componentsData = JSON.stringify(components);

	dto.setData("application/vnd.hae.dnd.components+json", componentsData);
	dto.setData("text", componentsData);
	dto.setData("text/plain", componentsData);
	dto.setData("application/json", componentsData);
	
	// Hack: We need to store data in type, because data itself are available only in drop event
	// but we need them in dragover as well.
	// See https://stackoverflow.com/questions/11927309/html5-dnd-datatransfer-setdata-or-getdata-not-working-in-every-browser-except-fi
	dto.setData(DND_METADATA_TYPE_PREFIX + JSON.stringify(metaData), "");

	const blankImage = new Image();
	blankImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
	dto.setDragImage(blankImage, 0, 0);

}

/**
 * Serializes selected component items into DataTransfer object
 *
 * @param dto DataTransfer
 * @param newItems Drag items
 */
export function dndSetDataTransferFromNew(
	dto: DataTransfer,
	newItems: INewItem[]
): void {

	const components: IDnDSerializedComponents = {
		contextId: null,
		items: []
	};

	const metaData: IDnDSerializedComponentMetaData = {
		contextid: null,
		items: []
	};

	newItems.forEach((item) => {

		components.items.push({
			nodeId: null,
			blueprint: item.blueprint
		});

		metaData.items.push({
			nodeid: null,
			inheritedpropsspec: {},
			dimensions: item.dimensions,
			offset: item.offset
		});

	});

	const componentsData = JSON.stringify(components);

	dto.setData("application/vnd.hae.dnd.components+json", componentsData);
	dto.setData("text", componentsData);
	dto.setData("text/plain", componentsData);
	dto.setData("application/json", componentsData);
	
	// Hack: We need to store data in type, because data itself are available only in drop event
	// but we need them in dragover as well.
	// See https://stackoverflow.com/questions/11927309/html5-dnd-datatransfer-setdata-or-getdata-not-working-in-every-browser-except-fi
	dto.setData(DND_METADATA_TYPE_PREFIX + JSON.stringify(metaData), "");

	const blankImage = new Image();
	blankImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
	dto.setDragImage(blankImage, 0, 0);

}

/**
 * Parses drag'n'drop meta-data from Data Transfer object
 *
 * @param dto Data Transfer Object
 */
export function parseDndMetaData(dto: DataTransfer): IDnDSerializedComponentMetaData {

	for (let i = 0; i < dto.types.length; i++) {
		const type = dto.types[i];

		if (type.startsWith(DND_METADATA_TYPE_PREFIX)) {
			try {
				return JSON.parse(type.substr(DND_METADATA_TYPE_PREFIX.length));
			} catch (err) {
				console.warn("Failed to parse DND meta-data:", err);
				return null;
			}		
		}
	}

	return null;

}

/**
 * Parses drag'n'drop meta-data from Data Transfer object
 *
 * @param dto Data Transfer Object
 */
export function parseDndComponents(dto: DataTransfer): IDnDSerializedComponents {

	const data = dto.getData("application/vnd.hae.dnd.components+json");

	if (data !== "") {
		try {
			return JSON.parse(data);
		} catch (err) {
			console.warn("Failed to parse DND components:", err);
			return null;
		}
	} else {
		return null;
	}

}
