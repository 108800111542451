/**
 * Sidebar Layout Side enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum SIDEBAR_LAYOUT_POSITION {
	LEFT = "left",
	RIGHT = "right"
}

export type SIDEBAR_LAYOUT_POSITION_string = keyof typeof SIDEBAR_LAYOUT_POSITION;

export const SIDEBAR_LAYOUT_POSITION_default = "LEFT";

export const SIDEBAR_LAYOUT_POSITION_opts = {
	left: {
		icon: "mdi/page-layout-sidebar-left"
	},
	right: {
		icon: "mdi/page-layout-sidebar-right"
	}
};