/**
 * Overlay
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TIconProps, TTextProps } from "../..";
import { ISchemaViewRef, RuntimeContext, TGetBlueprintSchemaSpec } from "@hexio_io/hae-lib-blueprint";
import { IActivityItem } from "./IActivityItem";
import { TRootComponentListSchema } from "../RootComponentListSchema";

/**
 * Overlay types
 */
export enum OVERLAY_TYPE {
	DIALOG_INFO = "dialog-info",
	DIALOG_CONFIRMATION = "dialog-confirmation",
	DIALOG_VIEW = "dialog-view",
	SIDEBAR_VIEW = "sidebar-view"
}

/**
 * Overlay size
 */
export enum OVERLAY_SIZE {
	SMALL = "small",
	MEDIUM = "medium",
	LARGE = "large"
}

export const OVERLAY_SIZE_default: keyof typeof OVERLAY_SIZE = "MEDIUM";

/**
 * Overlay closable
 */
export enum OVERLAY_CLOSABLE {
	YES = "yes",
	NO = "no",
	BUTTON = "button",
	CLICK_OUTSIDE = "click-outside"
}

export const OVERLAY_CLOSABLE_default: keyof typeof OVERLAY_CLOSABLE = "YES";

/**
 * Overlay Header spec
 */
export interface IOverlayHeader {
	text: TTextProps;
	icon?: TIconProps;
}

/**
 * Overlay Button spec
 */
export interface IOverlayButton {
	labelText: TTextProps;
	labelIcon: TIconProps;
	backgroundColor?: string;
	foregroundColor?: string;
	width: string;
}

/**
 * Info Dialog Overlay Button spec
 */
export interface IInfoDialogOverlayButton extends IOverlayButton {
	id: string;
	enabled?: boolean;
}

/**
 * Object return when an overlay is closed
 */
export interface IOverlayCloseResult {
	/** Overlay id */
	overlayId: string;

	/**
	 * If overlay was closed by the button the buttonId is provided.
	 * If overlay was closed in any other way the buttonId is null.
	 */
	buttonId: string;

	/**
	 * Custom data
	 */
	customData: unknown;
}

/**
 * Overlay definition object
 */
export interface IOverlayBase {
	/** Unique ID - can be referenced in events */
	id?: string;

	/** Size */
	size: string;

	/** Header config */
	header: IOverlayHeader;
}

/**
 * Info Dialog
 */
export interface IInfoDialogOverlay extends IOverlayBase {
	type: OVERLAY_TYPE.DIALOG_INFO;

	/** Dialog text */
	text: TTextProps;
	
	/** Closable */
	closable?: OVERLAY_CLOSABLE;

	/** A list of dialog buttons */
	buttons: IInfoDialogOverlayButton[];
}

/**
 * Confirmation Dialog
 */
export interface IConfirmationDialogOverlay extends IOverlayBase {
	type: OVERLAY_TYPE.DIALOG_CONFIRMATION;

	/** Dialog text */
	text: TTextProps;

	/** If set user will be asked to enter a value which must match with this property */
	confirmValue?: string;

	/** Confirm button options */
	confirmButton: IOverlayButton;

	/** Cancel button options */
	cancelButton: IOverlayButton;
}

export const OVERLAY_CONFIRM_BUTTON_ID = "confirm";
export const OVERLAY_CANCEL_BUTTON_ID = "cancel";

/**
 * View base
 */
export interface IViewOverlayBase extends IOverlayBase {
	/** View to display */
	view: TGetBlueprintSchemaSpec<ISchemaViewRef>;

	/** View Runtime context */
	viewRuntimeContext?: RuntimeContext<TRootComponentListSchema>;
}

/**
 * View Dialog
 */
export interface IViewDialogOverlay extends IViewOverlayBase {
	type: OVERLAY_TYPE.DIALOG_VIEW;

	/** Closable */
	closable?: OVERLAY_CLOSABLE;
}

/**
 * View Sidebar
 */
export interface IViewSidebarOverlay extends IViewOverlayBase {
	type: OVERLAY_TYPE.SIDEBAR_VIEW;

	/** Closable */
	closable?: OVERLAY_CLOSABLE;
}

/**
 * Overlay Spec Type
 */
export type TOverlay = IInfoDialogOverlay | IConfirmationDialogOverlay | IViewDialogOverlay | IViewSidebarOverlay;

/**
 * Overlay Activity Item
 */
export interface IOverlayActivityItem extends IActivityItem {
	/** Item data */
	data: TOverlay;
}
