/**
 * Composed Chart Axis Position enum
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum COMPOSED_CHART_AXIS_POSITION {
	HORIZONTAL_BOTTOM = "bottom",
	HORIZONTAL_TOP = "top",
	VERTICAL_LEFT = "left",
	VERTICAL_RIGHT = "right"
}

export const COMPOSED_CHART_AXIS_POSITION_default: keyof typeof COMPOSED_CHART_AXIS_POSITION = "HORIZONTAL_BOTTOM";

export const COMPOSED_CHART_AXIS_POSITION_opts = {
	horizontal_bottom: {
		icon: "mdi/border-bottom-variant"
	},
	horizontal_top: {
		icon: "mdi/border-top-variant"
	},
	vertical_left: {
		icon: "mdi/border-left-variant"
	},
	vertical_right: {
		icon: "mdi/border-right-variant"
	}
};
