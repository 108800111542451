/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportValidator } from "../ExportImportSchema/ExportSchema";
import {
	IBlueprintSchemaValidationError,
	IBlueprintSchemaValidator,
	IBlueprintSchemaValidatorHandler,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { ValidatorDeclarationError } from "../Shared/ValidatorDeclarationError";

type TValidatorObjectHandler = IBlueprintSchemaValidatorHandler<{ [K: string]: unknown }>;
type TValidatorObject<TOpts> = IBlueprintSchemaValidator<{ [K: string]: unknown }, TOpts>;

/**
 * Object validator options
 */
export type IValidatorObjectOpts = {
	/** Required value */
	required?: boolean;
};

const VALIDATOR_NAME = "ValidatorObject";

/**
 * Object validator
 */
export const ValidatorObject: TValidatorObject<IValidatorObjectOpts> =
	(opts: IValidatorObjectOpts): TValidatorObjectHandler => {

		if (opts.required !== undefined && opts.required !== null && typeof opts.required !== "boolean") {
			throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `required` to be a boolean");
		}

		return {

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			validate: (value: { [K: string]: unknown }): IBlueprintSchemaValidationError[] => {

				const errors = [];

				if (
					(opts.required === true && !(value instanceof Object)) ||
					(!opts.required && !(value === null || value === undefined) && !(value instanceof Object))
				) {
					errors.push({
						type: SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED,
						message: "Should be an Object"
					});
				}

				return errors;

			},

			compile: (): string => {

				const parts = [];

				if (opts.required === true) {
					// eslint-disable-next-line max-len
					parts.push(`if(value===null||typeof value!=="object"){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message: "Should be an Object" }); }`);
				}

				if (!opts.required) {
					// eslint-disable-next-line max-len
					parts.push(`if(!(value === null || value === undefined) && typeof value!=="object"){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message: "Should be an Object" }); }`);
				}

				// Minification
				const code = parts.join(" ")
					.replace(/{ /g, "{")
					.replace(/ }/g, "}")
					.replace(/: "/g, ":\"")
					.replace(/ ,/g, ",")
					.replace(/ < /g, "<")
					.replace(/ > /g, ">")
					.replace(/ === /g, "===")
					.replace(/ && /g, "&&")
					.replace(/value/g, "v")
					.replace(/errors.push/g, "e.push");

				return `const e=[];${code} return e;`;

			},

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			export: (): any => {
				return exportValidator(VALIDATOR_NAME, [opts]);
			}

		}
	};
