/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { MANIFEST_FAVICON_TYPES } from "../blueprints";
import { CONST } from "../constants";
import { IApplicationManifest } from "./IManifest";

export function createDefaultManifest(): IApplicationManifest {

	return {
		application: {
			name: "Adapptio Application"
		},
		session: {
			expiration: {
				cookie: CONST.SESSIONS.EXPIRATION.COOKIE,
				lastLogin: CONST.SESSIONS.EXPIRATION.LAST_LOGIN,
				storage: CONST.SESSIONS.EXPIRATION.STORAGE
			},
			storageType: {
				name: CONST.SESSIONS.DEFAULT_STORAGE_ADAPTER_NAME,
				opts: {}
			}
		},
		defaultTheme: {
			themeId: null,
			styleName: null
		},
		authentication: {
			providers: [],
			redirect: {
				logoutUrl: "/",
				loginFailedUrl: "/",
				loginSucceedUrl: "/"
			},
			authType: {
				type: "adapptio",
				value: {
					adapptio: {}
				}
			}
		},
		routes: [],
		constants: {},
		telemetry: {},
		externalServices: {},
		favicon: {
			type: MANIFEST_FAVICON_TYPES.CUSTOM,
			value: {}
		},
		template: {}
	};

}
