/**
 * Resolve Stylesheet for Table HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { COMPONENT_MODE, ISchemaConstObject, TComponentNodePath, TGetBlueprintSchemaSpec } from "@hexio_io/hae-lib-blueprint";
import {
	ClassList,
	getStringEnumValue,
	HORIZONTAL_ALIGN,
	HORIZONTAL_ALIGN_default,
	StyleSheet
} from "@hexio_io/hae-lib-components";

import { TableBaseProps, TableColumnBaseProps } from "./baseProps";

/**
 * Resolves Table Border Stylesheet
 * 
 * @param idClassName Id class name
 * @param borderData Border data
 */
export function resolveTableBorderStyleSheet(
	idClassName: string,
	borderData: TGetBlueprintSchemaSpec<ISchemaConstObject<typeof TableBaseProps>>["borderData"]
): StyleSheet {
	const result = new StyleSheet();

	switch (borderData.type) {
		case "NONE":
			break;
	
		case "ROWS": {
			const rowsBorderData = borderData.value[borderData.type];

			if (rowsBorderData.borderColor) {
				result.addColorProperties({
					selector: `.${idClassName}.table--border-rows`,
					name: "element-border-color",
					value: rowsBorderData.borderColor
				});
			}

			break;
		}

		case "CELLS": {
			const cellsBorderData = borderData.value[borderData.type];

			if (cellsBorderData.borderColor) {
				result.addColorProperties({
					selector: `.${idClassName}.table--border-cells`,
					name: "element-border-color",
					value: cellsBorderData.borderColor
				});
			}

			break;
		}
	}

	return result;
}

/**
 * Resolves Table Column classlists and stylesheet
 *
 * @param columns Columns
 * @param componentPath Component path
 * @param componentMode Component mode
 * @param editor Editor
 */
export function resolveTableColumnsClassListsAndStyleSheet(
	columns: TGetBlueprintSchemaSpec<ISchemaConstObject<typeof TableColumnBaseProps>>[],
	componentPath: TComponentNodePath,
	componentMode: COMPONENT_MODE,
	editor = false
): {
	columnsClassLists: ClassList[],
	columnsStyleSheet: StyleSheet
} {
	const resultClassLists: ClassList[] = [];
	const resultStyleSheet = new StyleSheet();

	columns.forEach((columnItem, columnIndex) => {
		const {
			classList: columnClassList, idClassName: columnIdClassName
		} = ClassList.getElementClassListAndIdClassName(
			"table__cell",
			[ ...componentPath, `column-${columnIndex}` ],
			{ componentMode }
		);

		if (columnItem.render || editor) {
			const horizontalAlignValue = getStringEnumValue(HORIZONTAL_ALIGN, columnItem.horizontalAlign, HORIZONTAL_ALIGN_default);

			columnClassList.addModifiers({
				"horizontal-align": horizontalAlignValue,
				visible: columnItem.visible,
				hidden: !columnItem.visible,
				...(editor ? { "not-render": !columnItem.render } : {})
			});

			if (columnItem.width) {
				resultStyleSheet.addString(`.${columnIdClassName}`, `width: ${columnItem.width} !important;`);
			}

			if (columnItem.minWidth) {
				resultStyleSheet.addString(`.${columnIdClassName}`, `min-width: ${columnItem.minWidth} !important;`);
			}
		}

		resultClassLists.push(columnClassList);
	});
	
	return {
		columnsClassLists: resultClassLists,
		columnsStyleSheet: resultStyleSheet
	};
}
