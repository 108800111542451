/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createEventEmitter, emitEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { GlobalsContainer } from "../globals/GlobalsContainer";
import { IBaseGlobals } from "../globals/IBaseGlobals";
import { IGlobalsResolver, IResolvedGlobals } from "./IGlobalsResolver";

/**
 * Globals Resolver
 */
export class GlobalsResolver<TGlobals extends IBaseGlobals> implements IGlobalsResolver<TGlobals> {

	/** Globals container */
	private globalsContainer: GlobalsContainer<TGlobals>;

	/**
	 * Invalidate event - emitted when action list has changed (and param schemas must be re-resolved)
	 */
	public onChange = createEventEmitter<void>();

	/**
	 * Resolver constructor
	 *
	 * @param globalsContainer Globals container instance
	 */
	public constructor(globalsContainer: GlobalsContainer<TGlobals>) {

		this.globalsContainer = globalsContainer;

		onEvent(this.globalsContainer.onChange, () => emitEvent(this.onChange));
	
	}

	public getGlobals(): IResolvedGlobals<TGlobals> {

		return {
			data: this.globalsContainer.getData(),
			type: this.globalsContainer.getType()
		};

	}

}
