/**
 * Image HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	Type,
	defineElementaryComponent,
	createSubScope,
} from "@hexio_io/hae-lib-blueprint";

import { THAEComponentDefinition, THAEComponentReact, Image, ImageProps, isEventEnabled } from "@hexio_io/hae-lib-components";
import { BaseEvents } from "../../base/BaseEvents";
import { termsEditor } from "../../terms";

interface HAEComponentImage_State {}

const HAEComponentImage_Props = {
	...ImageProps
};

const HAEComponentImage_Events = {
	...BaseEvents
};

const HAEComponentImage_Definition = defineElementaryComponent<
	typeof HAEComponentImage_Props,
	HAEComponentImage_State,
	typeof HAEComponentImage_Events
>({

	...termsEditor.components.image.component,

	name: "image",

	category: "content",

	icon: "mdi/image",

	events: HAEComponentImage_Events,

	docUrl: "...",

	order: 60,

	props: HAEComponentImage_Props,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}

});

const HAEComponentImage_React: THAEComponentReact<typeof HAEComponentImage_Definition> = ({
	props, componentInstance, reactComponentClassList
}) => {
	const _clickHandler = React.useMemo(() => {
		if (isEventEnabled(componentInstance.eventEnabled.click, componentInstance.componentMode)) {
			return () => componentInstance.eventTriggers.click((parentScope) => createSubScope(parentScope));
		}

		return;		
	}, [ componentInstance.componentMode, componentInstance.eventEnabled.click, componentInstance.eventTriggers.click ]);

	return (
		<Image
			{...props}
			componentPath={componentInstance.safePath}
			componentMode={componentInstance.componentMode}
			classList={reactComponentClassList}
			forceRender={true}
			onClick={_clickHandler}
		/>
	);
};

export const HAEComponentImage: THAEComponentDefinition<typeof HAEComponentImage_Definition> = {
	...HAEComponentImage_Definition,
	reactComponent: HAEComponentImage_React
};
 