/**
 * Tree Navigation schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const treeNavigation = {
	items: {
		label: "schema:treeNavigation.items#label",
		description: "schema:treeNavigation.items#description"
	},
	itemLabelText: {
		label: "schema:treeNavigation.items#props.labelText#label"
	},
	itemExpand: {
		label: "schema:treeNavigation.items#props.expand#label"
	},
	itemExpandValues: {
		default: {
			label: "schema:treeNavigation.items#props.expand#values.default#label"
		},
		expanded: {
			label: "schema:treeNavigation.items#props.expand#values.expanded#label"
		},
		collapsed: {
			label: "schema:treeNavigation.items#props.expand#values.collapsed#label"
		}
	},
	itemVisible: {
		label: "schema:treeNavigation.items#props.visible#label"
	},
	itemItems: {
		label: "schema:treeNavigation.items#props.items#label"
	},
	expandable: {
		label: "schema:treeNavigation.expandable#label",
		description: "schema:treeNavigation.expandable#description"
	},
	expandableShowLevels: {
		label: "schema:treeNavigation.expandable#props.showLevels#label",
		description: "schema:treeNavigation.expandable#props.showLevels#description"
	},
	style: {
		label: "schema:treeNavigation.style#label"
	},
	styleValues: {
		simple: {
			label: "schema:treeNavigation.style#values.simple#label"
		},
		menu_left: {
			label: "schema:treeNavigation.style#values.menuLeft#label"
		},
		menu_right: {
			label: "schema:treeNavigation.style#values.menuRight#label"
		}
	}
}
