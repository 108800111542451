/**
 * Link schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const linkLocation = {
	schema: {
		label: "schema:linkType#label"
	}
};
