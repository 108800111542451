/**
 * Is Browser check
 *
 * @package hae-lib-shared
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

export function isBrowser(): boolean {
	return typeof window !== "undefined" && typeof window.document !== "undefined";
}
