/**
 * Button Type enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum BUTTON_TYPE {
	DEFAULT = "default",
	LINK = "link"
}

export type BUTTON_TYPE_string = keyof typeof BUTTON_TYPE;
