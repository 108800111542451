/**
 * Container Edit Mode functions
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	ISchemaComponentModel,
	ISchemaConstObjectModel,
	MEDIA_RESOLUTIONS_default,
	MEDIA_RESOLUTIONS_string,
	SCHEMA_VALUE_TYPE,
	TGenericComponentInstance
} from "@hexio_io/hae-lib-blueprint";
import { IAllowedResizeDimensions, TResizeAfterRenderHandler, TResizeUpdateHandler } from "../Editor/IResize";
import { IHAEComponentListElementPlaceholder, THAEComponentListElement } from "../HAEComponent/HAEComponentList";
import { ItemInheritedProps } from "../SharedSchemas/ItemComponent";
import { IResizeOffset } from "../Editor/IEditCommon";
import { ContainerItemInheritedProps } from "../SharedSchemas/Container";
import { getStringEnumCssValue } from "./enumHelpers";
import { SPACING } from "../Enums/SPACING";
import { CONTAINER_FLOW } from "..";
import { CONTAINER_ITEM_FLEX_default, CONTAINER_ITEM_FLEX_inherit, CONTAINER_ITEM_FLEX_none } from "../Enums/CONTAINER_ITEM_FLEX";
import { isString, toNumber } from "@hexio_io/hae-lib-shared";

export type TContainerItemInheritedProps = typeof ItemInheritedProps & typeof ContainerItemInheritedProps;

/**
 * Resize initial state
 */
export interface IResizeInitialState {
	width: number;
	height: number;
	cellWidth: number;
	cellHeight: number;
	cellMinHeight: number;
}

/**
 * Cell dimensions
 */
interface IContainerCellDimensions {
	width: number;
	height: number;
	minHeight: number;
}

const defaultContainerCellDimensions: IContainerCellDimensions = {
	width: 10,
	height: 10,
	minHeight: 10
};

/**
 * Returns dimension value
 *
 * @param name Name
 * @param resizeOffset Resize offset
 * @param initialState Initial state
 * @param minValueName Optional min value name
 */
function getDimensionValue(
	name: "width" | "height",
	resizeOffset: IResizeOffset,
	initialState: IResizeInitialState
): number {
	const newValue = initialState[name] + resizeOffset[name];

	const isWidth = name === "width";

	const cellDimension = isWidth ? initialState.cellWidth : initialState.cellHeight;
	const minValue = isWidth ? cellDimension : initialState.cellMinHeight;

	return Math.max(newValue - (newValue % cellDimension), minValue);
}

/**
 * Returns cell dimensions
 */
export function getCellDimensions(): IContainerCellDimensions {
	const { width, height, minHeight } = defaultContainerCellDimensions;

	const documentElementStyle = getComputedStyle(document.documentElement);

	return {
		width,
		height: toNumber(documentElementStyle.getPropertyValue("--layout-grid-row-height")) || height,
		minHeight: toNumber(documentElementStyle.getPropertyValue("--layout-row-height")) || minHeight
	};
}

/**
 * Resolves child inline style
 */
export function resolveChildInlineStyle(
	element: THAEComponentListElement<TContainerItemInheritedProps>,
	index: number,
	media: MEDIA_RESOLUTIONS_string
): React.CSSProperties | undefined {
	if (element.type !== "placeholder") {
		return;
	}

	const result: React.CSSProperties = {};

	const inheritedProps = (element.inheritedProps as Record<string, unknown>);

	// Item spacing

	if (typeof inheritedProps.itemspacing === "string") {
		const itemSpacingValue = getStringEnumCssValue(
			SPACING,
			inheritedProps.itemspacing.toUpperCase(),
			"spacing-",
			undefined,
			inheritedProps.itemspacing
		);

		if (itemSpacingValue) {
			result["--element-spacing"] = itemSpacingValue;
		}
	}

	return result;
}

/**
 * Resolves child component inline style
 *
 * @note Unused, remove later
 */
export function resolveChildComponentInlineStyle(
	element: THAEComponentListElement<TContainerItemInheritedProps>,
	index: number,
	media: MEDIA_RESOLUTIONS_string
): React.CSSProperties {
	if (element.type !== "placeholder") {
		return;
	}

	const result: React.CSSProperties = {};

	return result;
}

/**
 * Resolves allow resize
 */
export function resolveAllowResize(
	componentInstance: TGenericComponentInstance<TContainerItemInheritedProps>,
	index: number,
	media: MEDIA_RESOLUTIONS_string,
	flow: CONTAINER_FLOW
): IAllowedResizeDimensions | null {
	if (!componentInstance.modelNode) {
		return null;
	}

	const inheritedProps = (componentInstance.modelNode.inheritedProps as ISchemaConstObjectModel<TContainerItemInheritedProps>).props;

	const itemWidthProp = inheritedProps.itemWidth.value.constant.props[media];
	const itemHeightProp = inheritedProps.itemHeight.value.constant.props[media];

	const containerItemFlexProp = inheritedProps.containerItemFlex.value.constant.props[media];
	const baseContainerItemFlexProp = media !== MEDIA_RESOLUTIONS_default ?
		inheritedProps.containerItemFlex.value.constant.props[MEDIA_RESOLUTIONS_default] :
		null;

	const containerItemFlexPropValue = containerItemFlexProp.value.type === SCHEMA_VALUE_TYPE.CONST ?
		containerItemFlexProp.value.constant.value :
		null;

	const noFlex = isString(containerItemFlexPropValue) &&
		(
			containerItemFlexPropValue === CONTAINER_ITEM_FLEX_default ||
			containerItemFlexPropValue === CONTAINER_ITEM_FLEX_none ||
			(
				baseContainerItemFlexProp ?
					(
						baseContainerItemFlexProp.value.type === SCHEMA_VALUE_TYPE.CONST &&
						(
							baseContainerItemFlexProp.value.constant.value === CONTAINER_ITEM_FLEX_default ||
							baseContainerItemFlexProp.value.constant.value === CONTAINER_ITEM_FLEX_none ||
							baseContainerItemFlexProp.value.constant.value === ""
						) &&
						(
							containerItemFlexPropValue === CONTAINER_ITEM_FLEX_inherit ||
							containerItemFlexPropValue === ""
						)
					) :
					(
						containerItemFlexPropValue === ""
					)
			)
		);

	const enableWidth = inheritedProps.itemWidth.value.type === SCHEMA_VALUE_TYPE.CONST &&
		itemWidthProp.type == SCHEMA_VALUE_TYPE.CONST &&
		(
			itemWidthProp.constant.value?.endsWith("px") ||
			itemWidthProp.constant.value === "" ||
			itemWidthProp.constant.value === null
		) &&
		(
			flow !== CONTAINER_FLOW.ROW ||
			noFlex
		);

	const enableHeight = inheritedProps.itemHeight.value.type === SCHEMA_VALUE_TYPE.CONST &&
		itemHeightProp.type == SCHEMA_VALUE_TYPE.CONST &&
		(
			itemHeightProp.constant.value?.endsWith("px") ||
			itemHeightProp.constant.value === "" ||
			itemHeightProp.constant.value === null
		) &&
		(
			flow !== CONTAINER_FLOW.COLUMN ||
			noFlex
		);

	return {
		left: enableWidth,
		top: enableHeight,
		width: enableWidth,
		height: enableHeight
	} as IAllowedResizeDimensions;
}

/**
 * Handles resize begin
 */
export function handleResizeBegin(
	subject: TGenericComponentInstance<TContainerItemInheritedProps>,
	resizeOffset: IResizeOffset,
	media: MEDIA_RESOLUTIONS_string
): IResizeInitialState | null {
	if (!subject.modelNode) {
		return null;
	}

	const { itemWidth, itemHeight } = subject.inheritedProps;

	const itemWidthString = itemWidth[media];
	const itemHeightString = itemHeight[media];

	const baseItemWidthString = media !== MEDIA_RESOLUTIONS_default ? itemWidth[MEDIA_RESOLUTIONS_default] : null;
	const baseItemHeightString = media !== MEDIA_RESOLUTIONS_default ? itemHeight[MEDIA_RESOLUTIONS_default] : null;

	let width = (itemWidthString?.endsWith("px") && parseFloat(itemWidthString)) ||
		(baseItemWidthString?.endsWith("px") && parseFloat(baseItemWidthString));

	let height = (itemHeightString?.endsWith("px") && parseFloat(itemHeightString)) ||
		(baseItemHeightString?.endsWith("px") && parseFloat(baseItemHeightString));

	width = Number.isFinite(width) ? width : resizeOffset.initialWidth;
	height = Number.isFinite(height) ? height: resizeOffset.initialHeight;

	const { width: cellWidth, height: cellHeight, minHeight: cellMinHeight } = getCellDimensions();

	return {
		width,
		height,
		cellWidth,
		cellHeight,
		cellMinHeight
	};
}

/**
 * Handles resize update
 */
export const handleResizeUpdate: TResizeUpdateHandler<TGenericComponentInstance<TContainerItemInheritedProps>, unknown> = (
	_subject: TGenericComponentInstance<TContainerItemInheritedProps>,
	element: HTMLElement,
	resizeOffset: IResizeOffset,
	initialState: IResizeInitialState
) => {
	const parentElement = element.parentElement;

	if (resizeOffset.width !== 0) {
		if (parentElement.classList.contains("item--auto-width")) {
			parentElement.classList.remove("item--auto-width");
		}

		element.style.width = `${getDimensionValue("width", resizeOffset, initialState)}px`;
	}

	if (resizeOffset.height !== 0) {
		element.style.height = `${getDimensionValue("height", resizeOffset, initialState)}px`;
	}
}

/**
 * Handles resize end
 */
export function handleResizeEnd(
	subject: TGenericComponentInstance<TContainerItemInheritedProps>,
	resizeOffset: IResizeOffset,
	initialState: IResizeInitialState,
	media: MEDIA_RESOLUTIONS_string
): void | TResizeAfterRenderHandler {
	if (!subject.modelNode) {
		return null;
	}

	const inheritedProps = (subject.modelNode.inheritedProps as ISchemaConstObjectModel<TContainerItemInheritedProps>).props;

	if (resizeOffset.width !== 0) {
		const itemWidthPropConst = inheritedProps.itemWidth.value.constant.props[media].constant;

		itemWidthPropConst.schema.setValue(itemWidthPropConst, `${getDimensionValue("width", resizeOffset, initialState)}px`, true);
	}

	if (resizeOffset.height !== 0) {
		const itemHeightPropConst = inheritedProps.itemHeight.value.constant.props[media].constant;

		itemHeightPropConst.schema.setValue(itemHeightPropConst, `${getDimensionValue("height", resizeOffset, initialState)}px`, true);
	}

	return (element: HTMLElement) => {
		element.style.height = "";
		element.style.width = "";
	};
}

/**
 * Modifies model on drop
 */
export function modifyModelOnDrop(
	modelNode: ISchemaComponentModel,
	element: IHAEComponentListElementPlaceholder<TContainerItemInheritedProps>,
	media: MEDIA_RESOLUTIONS_string
): void {
	// Only set it up when component comes from Grid
	// functionality disabled on request

	/*const elementInheritedProps = element.inheritedProps as Record<string, unknown>;

	if (typeof elementInheritedProps.griditemposition !== "undefined") {
		const modelInheritedProps = (modelNode.inheritedProps as ISchemaConstObjectModel<TContainerItemInheritedProps>).props;

		const itemWidthProp = modelInheritedProps.itemWidth.value.constant.props[media];
		const itemHeightProp = modelInheritedProps.itemHeight.value.constant.props[media];

		if (
			itemWidthProp.type === SCHEMA_VALUE_TYPE.CONST &&
			element.dimensions.width !== null
		) {
			const itemWidthValue = `${Math.round(element.dimensions.width)}px`;

			itemWidthProp.constant.schema.setValue(itemWidthProp.constant, itemWidthValue, true);
		}

		if (
			itemHeightProp.type === SCHEMA_VALUE_TYPE.CONST &&
			element.dimensions.height !== null
		) {
			const itemHeightValue = `${Math.round(element.dimensions.height)}px`;

			itemHeightProp.constant.schema.setValue(itemHeightProp.constant, itemHeightValue, true);
		}
	}*/

	return;
}
