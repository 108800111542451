/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IScope, TGetBlueprintSchemaSpec, TTypeDesc, Type } from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../app";
import {
	BlueprintFlowNodeTypeTimeout,
	BlueprintFlowNodeTypeVariable,
	FLOW_NODE_TYPES,
	TActionNodeTypes,
	TBlueprintActionNodeTypes,
	TEndpointFlowNodeTypes
} from "../blueprints";
import { BlueprintFlowNodeTypeError } from "../blueprints/nodes/BlueprintNodeError";
import {
	BlueprintFlowNodeTypeMap
} from "../blueprints/nodes/BlueprintNodeMap";
import { BlueprintFlowNodeTypeReduce } from "../blueprints/nodes/BlueprintNodeReduce";
import { BlueprintFlowNodeTypeSetSession } from "../blueprints/nodes/BlueprintNodeSetSession";
import { IntegrationError } from "../errors";
import { IExecutionOptions } from "../managers/IExecutionOptions";
import { IExecutionContext } from "../WebServer";

import { TActionViewRenderer } from "./ActionManager";
import { IActionDebugData_Node, TActionNodeOpts, TEndpointNodeOpts } from "./IActionDebugData";
import { FLOW_NODE_OUTPUT_NAMES } from "./IActionManager";
import { ACTION_ERROR_REASON, ACTION_RESULT_TYPE, IActionResultError, TActionResult } from "./IActionResult";
import { ITransformNodeExecutor } from "./ITransformNodeExecutor";

export type TFlowNodeHandler = (
	opts: TActionNodeOpts,
	additionalOpts?: IFlowNodeHandlerAdditionalOpts
) => Promise<FlowNodeHandlerResult>;

export interface FlowNodeHandlerResult {
	outputName: FLOW_NODE_OUTPUT_NAMES;
	data?: any;
	type?: TTypeDesc;
	additionalInfo?: IActionDebugData_Node["additionalInfo"];
}

export interface IFlowNodeHandlerAdditionalOpts {
	app: IAppServer;
	context: IExecutionContext;
	config: IExecutionOptions;
	appId: string;
	appEnvId: string;
	appName: string;
	executor?: ITransformNodeExecutor;
	resourceId: string;
	nodeId: string;
	localScope: IScope;
	timeout: number;
	memoryLimit: number;
	renderer?: TActionViewRenderer;
	request?: any;
}

export async function startNodeHandler(opts: TActionNodeOpts): Promise<FlowNodeHandlerResult> {
	return {
		outputName: FLOW_NODE_OUTPUT_NAMES.ON_START,
		type: Type.Null({})
	};
}

export async function errorNodeHandler(
	opts: TActionNodeOpts,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { context } = additionalOpts;
	context.debug("Error node.");

	const { message, errorName, data } = opts as TGetBlueprintSchemaSpec<
		typeof BlueprintFlowNodeTypeError
	>["opts"];

	return {
		outputName: null,
		data: {
			message,
			errorName,
			data
		},
		type: Type.Object({
			props: {
				message: Type.String({}),
				errorName: Type.String({}),
				data: Type.Any({})
			}
		})
	};
}

export async function outputNodeHandler(opts: TActionNodeOpts): Promise<FlowNodeHandlerResult> {
	const data = opts["value"];

	return {
		outputName: null,
		data,
		type: Type.Any({})
	};
}

export async function integrationNodeHandler(
	opts: TActionNodeOpts,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { functionName, integrationId, params } = opts as TGetBlueprintSchemaSpec<
		TBlueprintActionNodeTypes["integration"]["opts"]
	>;

	const { app, config, context } = additionalOpts;

	try {
		const result = await app
			.get("integrationManager")
			.execute(integrationId, functionName, params, context, config);

		return {
			outputName: FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS,
			data: result.data,
			type: result.typeDescriptor,
			additionalInfo: result.debug
		};
	} catch (err) {
		const error = err as IntegrationError;

		return {
			outputName: FLOW_NODE_OUTPUT_NAMES.ON_ERROR,
			data: {
				...error?.errorDetails,
				message: error?.message || "Unknown integration error.",
				errorName: error?.error || error?.name || "IntegrationError",
				userMessage: error?.userMessage || null,
				integrationId,
				functionName
			},
			type: Type.Object({
				props: {
					message: Type.String({}),
					errorName: Type.String({}),
					userMessage: Type.Null({})
				}
			}),
			additionalInfo: error
		};
	}
}

export async function actionNodeHandler(
	opts: TActionNodeOpts,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { actionId, params } = opts as TGetBlueprintSchemaSpec<TBlueprintActionNodeTypes["action"]["opts"]>;
	const { app, appEnvId, appId, appName, config, context } = additionalOpts;

	try {
		const result = (await app
			.get("actionManager")
			.invoke(actionId, params, context, config, appId, appEnvId, appName)) as TActionResult;

		if (result.status === ACTION_RESULT_TYPE.SUCCESS) {
			return {
				outputName: FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS,
				data: result.data,
				type: Type.Any({}),
				additionalInfo: result.debug
			};
		} else if (result.status === ACTION_RESULT_TYPE.ERROR) {
			return {
				outputName: FLOW_NODE_OUTPUT_NAMES.ON_ERROR,
				data: (result as IActionResultError)?.error,
				type: Type.Any({}),
				additionalInfo: result.debug
			};
		}
	} catch (error) {
		if (error.name === ACTION_ERROR_REASON.MAX_RECURSION) {
			throw error;
		}

		return {
			outputName: FLOW_NODE_OUTPUT_NAMES.ON_ERROR,
			data: {
				message: error?.message || "Unknown internal action error.",
				errorName: error?.error || error?.name || "InternalActionError",
				userMessage: error?.userMessage || null
			},
			type: Type.Object({
				props: {
					message: Type.String({}),
					errorName: Type.String({}),
					userMessage: Type.Null({})
				}
			}),
			additionalInfo: error
		};
	}
}

export async function transformNodeHandler(
	opts: TActionNodeOpts,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { code } = opts as TGetBlueprintSchemaSpec<TBlueprintActionNodeTypes["transform"]["opts"]>;
	const { resourceId, executor, localScope, memoryLimit, nodeId, timeout } = additionalOpts;
	try {
		const result = await executor.execute(resourceId, nodeId, code, localScope.globalData, {
			memoryLimit,
			timeout
		});

		return {
			outputName: FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS,
			data: result,
			type: result && result.typeDescriptor ? result.typeDescriptor : null,
			additionalInfo: result && result.debug
		};
	} catch (error) {
		return {
			outputName: FLOW_NODE_OUTPUT_NAMES.ON_ERROR,
			data: {
				message: error?.message || "Unknown transform error.",
				errorName: error?.error || error?.name || "TransformError",
				userMessage: error?.userMessage || null
			},
			type: Type.Object({
				props: {
					message: Type.String({}),
					errorName: Type.String({}),
					userMessage: Type.Null({})
				}
			}),
			additionalInfo: error
		};
	}
}

export async function conditionNodeHandler(opts: TActionNodeOpts): Promise<FlowNodeHandlerResult> {
	const { condition } = opts as TGetBlueprintSchemaSpec<TBlueprintActionNodeTypes["condition"]["opts"]>;

	let outputName = FLOW_NODE_OUTPUT_NAMES.ON_FALSE;
	if (condition === true) {
		outputName = FLOW_NODE_OUTPUT_NAMES.ON_TRUE;
	}

	return {
		outputName,
		data: condition,
		type: Type.Boolean({})
	};
}

export async function viewToHtmlNodeHandler(
	opts: TActionNodeOpts,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { viewId, params } = opts as TGetBlueprintSchemaSpec<
		TBlueprintActionNodeTypes["viewToHtml"]["opts"]
	>;
	const { app, context, renderer } = additionalOpts;

	let html;

	if (renderer) {
		try {
			html = await renderer(app, viewId, params, context);
		} catch (error) {
			return {
				outputName: FLOW_NODE_OUTPUT_NAMES.ON_ERROR,
				data: {
					message: error?.message || "Unknown View rendering error.",
					errorName: error?.error || error?.name || "RenderingError",
					userMessage: error?.userMessage || null
				},
				type: Type.Object({
					props: {
						message: Type.String({}),
						errorName: Type.String({}),
						userMessage: Type.Null({})
					}
				}),
				additionalInfo: error
			};
		}
	} else {
		html = `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="utf-8"><title>View to HTML</title></head>
<body><h1>Rendering View to HTML is disabled. Contact support.</h1></body>
</html>`;
	}

	return {
		outputName: FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS,
		data: html,
		type: Type.String({})
	};
}

export async function requestNodeHandler(
	opts: TEndpointNodeOpts,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	return {
		outputName: FLOW_NODE_OUTPUT_NAMES.ON_REQUEST,
		type: Type.Object({
			props: {
				path: Type.String({}),
				query: Type.Map({
					items: { "[K: string]": Type.Any({}) }
				}),
				headers: Type.Map({
					items: { "[K: string]": Type.String({}) }
				}),
				params: Type.Map({
					items: { "[K: string]": Type.String({}) }
				}),
				body: Type.Any({})
			}
		}),
		data: additionalOpts?.request || {}
	};
}

export async function responseNodeHandler(opts: TEndpointNodeOpts): Promise<FlowNodeHandlerResult> {
	/** Filter default values from blueprint schema. */
	if (opts?.["headers"] && Object.keys(opts?.["headers"]).length === 0) {
		opts["headers"] = undefined;
	}

	/** Filter default values from blueprint schema. */
	if (opts?.["body"] === null) {
		opts["body"] = undefined;
	}

	return {
		outputName: null,
		data: opts,
		type: Type.Object({
			props: {
				status: Type.Integer({}),
				headers: Type.Map({
					items: { "[K: string]": Type.String({}) }
				}),
				body: Type.Any({})
			}
		})
	};
}

export async function redirectNodeHandler(opts: TEndpointNodeOpts): Promise<FlowNodeHandlerResult> {
	return {
		outputName: null,
		data: opts,
		type: Type.Object({
			props: {
				url: Type.String({}),
				status: Type.Integer({}),
				headers: Type.Map({
					items: { "[K: string]": Type.String({}) }
				}),
				body: Type.Any({})
			}
		})
	};
}

export async function loginNodeHandler(
	opts: TEndpointNodeOpts,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { context } = additionalOpts;

	context.debug("Login node.");

	try {
		await context.session.login({ updateLastLoginAt: true });

		return {
			outputName: FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS,
			data: null,
			type: Type.Void({})
		};
	} catch (error) {
		return unhandledHandlerError(error);
	}
}

export async function logoutNodeHandler(
	opts: TEndpointNodeOpts,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { context } = additionalOpts;

	context.debug("Logout node.");

	try {
		await context.session.logout();

		return {
			outputName: FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS,
			data: null,
			type: Type.Void({})
		};
	} catch (error) {
		return unhandledHandlerError(error);
	}
}

export async function setSessionNodeHandler(
	opts: TGetBlueprintSchemaSpec<typeof BlueprintFlowNodeTypeSetSession["opts"]>,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { context } = additionalOpts;

	const { userIdentityKey, meta } = opts;
	context.debug("Set session node:", { userIdentityKey, meta });

	if (userIdentityKey) {
		context.session.userIdentityKey = userIdentityKey;
	}
	if (meta) {
		context.session.meta = meta;
	}

	try {
		await context.session.save();

		return {
			outputName: FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS,
			data: context.session.export(),
			type: Type.Void({})
		};
	} catch (error) {
		context.warn("Error save session.");
		context.debug({ error, message: error?.message });
		return unhandledHandlerError(error);
	}
}

export async function mapNodeHandler(
	opts: TGetBlueprintSchemaSpec<typeof BlueprintFlowNodeTypeMap["opts"]>,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { context } = additionalOpts;

	const { items } = opts;

	try {
		if (Array.isArray(items)) {
			return {
				outputName: FLOW_NODE_OUTPUT_NAMES.ON_ITEM,
				data: items,
				type: Type.Array({ items: [Type.Any({})] })
			};
		} else {
			return {
				outputName: FLOW_NODE_OUTPUT_NAMES.ON_ERROR,
				data: {
					message: "Items expected to be an array.",
					errorName: "MapError",
					userMessage: null
				},
				type: Type.Object({
					props: {
						message: Type.String({}),
						errorName: Type.String({}),
						userMessage: Type.Null({})
					}
				}),
				additionalInfo: {}
			};
		}
	} catch (error) {
		context.warn("Error.");
		context.debug({ error, message: error?.message });
		return unhandledHandlerError(error);
	}
}

export async function reduceNodeHandler(
	opts: TGetBlueprintSchemaSpec<typeof BlueprintFlowNodeTypeReduce["opts"]>,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { context } = additionalOpts;

	const { items } = opts;

	try {
		if (Array.isArray(items)) {
			return {
				outputName: FLOW_NODE_OUTPUT_NAMES.ON_ITEM,
				data: items,
				type: Type.Array({ items: [Type.Any({})] })
			};
		} else {
			return {
				outputName: FLOW_NODE_OUTPUT_NAMES.ON_ERROR,
				data: {
					message: "Items expected to be an array.",
					errorName: "MapError",
					userMessage: null
				},
				type: Type.Object({
					props: {
						message: Type.String({}),
						errorName: Type.String({}),
						userMessage: Type.Null({})
					}
				}),
				additionalInfo: {}
			};
		}
	} catch (error) {
		context.warn("Error.");
		context.debug({ error, message: error?.message });
		return unhandledHandlerError(error);
	}
}

export async function variableNodeHandler(
	opts: TGetBlueprintSchemaSpec<typeof BlueprintFlowNodeTypeVariable["opts"]>,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { context } = additionalOpts;

	try {
		const data = opts["value"];

		return {
			outputName: FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS,
			data,
			type: Type.Any({})
		};
	} catch (error) {
		context.warn("Error.");
		context.debug({ error, message: error?.message });
		return unhandledHandlerError(error);
	}
}

export async function timeoutNodeHandler(
	opts: TGetBlueprintSchemaSpec<typeof BlueprintFlowNodeTypeTimeout["opts"]>,
	additionalOpts: IFlowNodeHandlerAdditionalOpts
): Promise<FlowNodeHandlerResult> {
	const { context } = additionalOpts;

	try {
		const timeoutInMs = opts["timeoutInMs"];

		await new Promise((resolve) =>
			setTimeout(resolve, Number.isInteger(timeoutInMs) && timeoutInMs > 0 ? timeoutInMs : 100)
		);

		return {
			outputName: FLOW_NODE_OUTPUT_NAMES.ON_SUCCESS,
			data: null,
			type: Type.Any({})
		};
	} catch (error) {
		context.warn("Error.");
		context.debug({ error, message: error?.message });
		return unhandledHandlerError(error);
	}
}

function unhandledHandlerError(error: any): FlowNodeHandlerResult {
	return {
		outputName: FLOW_NODE_OUTPUT_NAMES.ON_ERROR,
		data: {
			message: error?.message || "Unknown Session error.",
			errorName: error?.error || error?.name || "SessionError",
			userMessage: error?.userMessage || null
		},
		type: Type.Object({
			props: {
				message: Type.String({}),
				errorName: Type.String({}),
				userMessage: Type.String({})
			}
		}),
		additionalInfo: error
	};
}

export const ACTION_FLOW_NODES: { [keys in TActionNodeTypes]?: TFlowNodeHandler } = {
	[FLOW_NODE_TYPES.ACTION]: actionNodeHandler,
	[FLOW_NODE_TYPES.CONDITION]: conditionNodeHandler,
	[FLOW_NODE_TYPES.ERROR]: errorNodeHandler,
	[FLOW_NODE_TYPES.INTEGRATION]: integrationNodeHandler,
	[FLOW_NODE_TYPES.OUTPUT]: outputNodeHandler,
	[FLOW_NODE_TYPES.START]: startNodeHandler,
	[FLOW_NODE_TYPES.TRANSFORM]: transformNodeHandler,
	[FLOW_NODE_TYPES.VIEWTOHTML]: viewToHtmlNodeHandler,
	[FLOW_NODE_TYPES.LOGIN]: loginNodeHandler,
	[FLOW_NODE_TYPES.LOGOUT]: logoutNodeHandler,
	[FLOW_NODE_TYPES.SETSESSION]: setSessionNodeHandler,
	[FLOW_NODE_TYPES.MAP]: mapNodeHandler,
	[FLOW_NODE_TYPES.REDUCE]: reduceNodeHandler,
	[FLOW_NODE_TYPES.VAR]: variableNodeHandler,
	[FLOW_NODE_TYPES.TIMEOUT]: timeoutNodeHandler
};

export const ENDPOINT_FLOW_NODES: { [keys in TEndpointFlowNodeTypes]?: TFlowNodeHandler } = {
	[FLOW_NODE_TYPES.REQUEST]: requestNodeHandler,
	[FLOW_NODE_TYPES.RESPONSE]: responseNodeHandler,
	[FLOW_NODE_TYPES.REDIRECT]: redirectNodeHandler,
	[FLOW_NODE_TYPES.ACTION]: actionNodeHandler,
	[FLOW_NODE_TYPES.CONDITION]: conditionNodeHandler,
	[FLOW_NODE_TYPES.TRANSFORM]: transformNodeHandler
};
