/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, declareFunction, SCHEMA_CONST_ANY_VALUE_TYPE, Type } from "@hexio_io/hae-lib-blueprint";

export const containsFunc = declareFunction({
	name: "CONTAINS",
	category: "string",
	label: "Contains",
	description: "Returns true if a string contains a given substring.",
	argRequiredCount: 2,
	argSchemas: [
		BP.String({
			label: "String",
			constraints: {
				required: true
			}
		}),
		BP.String({
			label: "Substring",
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Boolean({}),
	render: (_rCtx, args) => {

		return args[0]().includes(args[1]());

	}
});

export const startsWithFunc = declareFunction({
	name: "STARTS_WITH",
	category: "string",
	label: "Starts With",
	description: "Returns true if string starts with a given substring.",
	argRequiredCount: 2,
	argSchemas: [
		BP.String({
			label: "String",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.String({
			label: "Substring",
			constraints: {
				required: true
			},
			fallbackValue: null
		})
	],
	argRestSchema: null,
	returnType: Type.Boolean({}),
	render: (_rCtx, args) => {

		return args[0]().startsWith(args[1]());

	}
});

export const concatFunc = declareFunction({
	name: "CONCAT",
	category: "string",
	label: "Concatenate",
	description: "Concatenates multiple values into a single string.",
	argRequiredCount: 1,
	argSchemas: [],
	argRestSchema: BP.Any({ label: "Value", defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING }),
	returnType: Type.String({}),
	render: (_runtimeCtx, _args, restArgs) => {
		return restArgs.map((x) => String(x())).join("");
	}
});

export const base64DecodeFunc = declareFunction({
	name: "BASE64_DECODE",
	category: "string",
	label: "Decode Base64",
	description: "Decodes string from BASE-64.",
	argRequiredCount: 1,
	argSchemas: [BP.String({ label: "Text" })],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		return atob(String(args[0]()));

	}
});

export const base64EncodeFunc = declareFunction({
	name: "BASE64_ENCODE",
	category: "string",
	label: "Encode Base64",
	description: "Encodes string to BASE-64.",
	argRequiredCount: 1,
	argSchemas: [BP.String({ label: "Text" })],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		return btoa(String(args[0]()));

	}
});

export const lowerCaseFunc = declareFunction({
	name: "LOWERCASE",
	category: "string",
	label: "Lowercase",
	description: "Converts string value to lowercase.",
	argRequiredCount: 1,
	argSchemas: [BP.String({ label: "Value" })],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		return String(args[0]()).toLowerCase();

	}
});

export const upperCaseFunc = declareFunction({
	name: "UPPERCASE",
	category: "string",
	label: "Uppercase",
	description: "Converts string value to uppercase.",
	argRequiredCount: 1,
	argSchemas: [BP.String({ label: "Value" })],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		return String(args[0]()).toUpperCase();

	},
});

export const isEmptyFunc = declareFunction({
	name: "ISEMPTY",
	category: "string",
	label: "Is Empty",
	description: "Returns true if a value is empty string.",
	argRequiredCount: 1,
	argSchemas: [BP.String({ label: "Value" })],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		return String(args[0]()) === "";

	},
});

export const emptyNullFunc = declareFunction({
	name: "EMPTYNULL",
	category: "string",
	label: "Empty String to Null",
	description: "Returns null if value is empty. Otherwise returns value as is.",
	argRequiredCount: 1,
	argSchemas: [BP.String({ label: "Value" })],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		const value = args[0]();
		return String(value) === "" ? null : value;

	},
});

export const splitFunc = declareFunction({
	name: "SPLIT",
	category: "string",
	label: "Splits string",
	description: "Splits string to an array using separator.",
	argRequiredCount: 2,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.String({
			label: "Delimiter",
			constraints: {
				required: true
			},
			fallbackValue: ","
		}),
		BP.Integer({
			label: "Maximum elements",
			description: "Maximum number of elements to be separated",
			constraints: {
				required: false
			},
			fallbackValue: 0
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		const value = String(args[0]());
		const delim = String(args[1]());
		const maxElements = args[2]();

		return value.split(delim, typeof maxElements === "number" && maxElements > 0 ? maxElements : undefined);

	},
});

export const substringFunc = declareFunction({
	name: "SUBSTRING",
	category: "string",
	label: "Substring",
	description: "Get a substring from a string.",
	argRequiredCount: 2,
	argSchemas: [
		BP.String({
			label: "Input string",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.Integer({
			label: "Start",
			constraints: {
				required: true
			},
			fallbackValue: 0
		}),
		BP.Integer({
			label: "End",
			constraints: {
				required: false
			},
			fallbackValue: null
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		const value = String(args[0]());
		const start = args[1]() as number;
		const end = args[2]() as number | null;

		return value.substring(start, end);

	},
});

export const replaceFunc = declareFunction({
	name: "REPLACE",
	category: "string",
	label: "Replace string",
	description: "Replaces string in provided string.",
	argRequiredCount: 3,
	argSchemas: [
		BP.String({
			label: "Input string",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.String({
			label: "Search value",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.String({
			label: "Replace value",
			constraints: {
				required: true
			},
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		const value = String(args[0]());
		const searchValue = String(args[1]());
		const replaceValue = String(args[2]());

		return value.replace(new RegExp(searchValue, 'g'), replaceValue);

	},
});

export const joinFunc = declareFunction({
	name: "JOIN",
	category: "string",
	label: "Joins array to string",
	description: "Joins an array elements to a string using glue.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Array({
			label: "Value",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		}),
		BP.String({
			label: "Separator",
			constraints: {
				required: false
			},
			default: ",",
			fallbackValue: ","
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		const arr = args[0]();
		const delim = String(args[1]());

		if (arr instanceof Array) {
			return arr.join(delim);
		} else {
			return "";
		}

	},
});

export const strlenFunc = declareFunction({
	name: "STRLEN",
	category: "string",
	label: "String length",
	description: "Returns a length of the string.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: false
			},
			default: "",
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({}),
	render: (_rCtx, args) => {

		const str = args[0]();

		if (typeof str === "string") {
			return str.length;
		} else {
			return -1;
		}

	},
});

export const strInsertFunc = declareFunction({
	name: "STR_INSERT",
	category: "string",
	label: "String insert",
	description: "Inserts a string at given position into another string",
	argRequiredCount: 3,
	argSchemas: [
		BP.String({
			label: "Original string",
			constraints: {
				required: false
			},
			default: "",
			fallbackValue: ""
		}),
		BP.String({
			label: "String to insert",
			constraints: {
				required: false
			},
			default: "",
			fallbackValue: ""
		}),
		BP.Integer({
			label: "Position to insert",
			constraints: {
				required: false
			},
			default: 0,
			fallbackValue: 0
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		const origStr = String(args[0]()) as string;
		const insertStr = String(args[1]()) as string;
		const position = args[2]() as number;

		return origStr.substring(0, position) + insertStr + origStr.substring(position);

	},
});

export const toStringFunc = declareFunction({
	name: "TO_STRING",
	category: "types",
	label: "To String",
	description: "Converts given value to string",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {
		return String(args[0]());
	}
});
