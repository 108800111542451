/**
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

const urlProtocolRegExp = /^([a-zA-Z]+:)?\/\//;

/**
 * Checks whether or not is link external
 * 
 * @param to Link (relative / absolute path or URL)
 */
export function isLinkExternal(to: string): boolean {
	return urlProtocolRegExp.test(to);
}