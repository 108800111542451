/**
 * Text Alignment Class enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum TEXT_ALIGN_CLASS {
	LEFT = "text--align-left",
	CENTER = "text--align-center",
	RIGHT = "text--align-right",
	BLOCK = "text--align-block",
	DEFAULT = "text--align-default"
}

export type TEXT_ALIGN_CLASS_string = keyof typeof TEXT_ALIGN_CLASS;
