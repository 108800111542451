/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP } from "@hexio_io/hae-lib-blueprint";
import { createBlueprintSchema } from "./BlueprintBase";
import { DOC_TYPES } from "./DocTypes";

/**
 * Translations blueprint "spec" property schema
 */
export const BlueprintTranslationsSpec = BP.Const.Map({
	label: "Language",
	value: BP.Const.Map({
		label: "Term",
		value: BP.Const.String({
			label: "Translation",
			constraints: {
				required: true
			}
		})
	})
});

export type TBlueprintTranslationsSpecSchema = typeof BlueprintTranslationsSpec;

/**
 * Translations Blueprint Schema
 */
export const BlueprintTranslations = createBlueprintSchema(DOC_TYPES.TRANSLATIONS_V1, BlueprintTranslationsSpec);

export type TBlueprintTranslationsSchema = typeof BlueprintTranslations;