/**
 * Gauge Chart HAE component definition
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	Type,
	defineElementaryComponent,
	IScope,
	createSubScope,
	OBJECT_TYPE_PROP_NAME,
	OBJECT_TYPE
} from "@hexio_io/hae-lib-blueprint";

import { getStringEnumValue } from "@hexio_io/hae-lib-components";

import { getTimestamp, isNumber, isString, isValidValue, isUndefined, isDeepEqual, toNumber } from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";

import { HAEComponentGaugeChart_Events } from "./events";
import { HAEComponentGaugeChart_Props } from "./props";
import { HAEComponentGaugeChart_State } from "./state";

export const HAEComponentGaugeChart_Definition = defineElementaryComponent<
	typeof HAEComponentGaugeChart_Props,
	HAEComponentGaugeChart_State,
	typeof HAEComponentGaugeChart_Events
>({
	...termsEditor.components.gaugeChart.component,

	name: "caugeChart",

	category: "charts",

	icon: "mdi/gauge",

	docUrl: "...",

	order: 20,

	props: HAEComponentGaugeChart_Props,

	events: HAEComponentGaugeChart_Events,

	resolve: (spec, state) => {
		const {
			min,
			max,
			startAngle,
			endAngle,
			ranges = [],
			position,
			styleData,
			backgroundColor,
			foregroundColor,
			size,
			hand,
			ticks,
			label,
			//grid
		} = spec;

		const {
			min: stateMin,
			max: stateMax,
			startAngle: stateStartAngle,
			endAngle: stateEndAngle,
			ranges: stateRanges,
			position: statePosition,
			styleData: stateStyleData,
			backgroundColor: stateBackgroundColor,
			foregroundColor: stateForegroundColor,
			size: stateSize,
			hand: stateHand,
			ticks: stateTicks,
			label: stateLabel
			//grid: stateGrid
		} = state || {};

		const settingsUpdated = min !== stateMin ||
			max !== stateMax ||
			startAngle !== stateStartAngle ||
			endAngle !== stateEndAngle ||
			!isDeepEqual(ranges, stateRanges) ||
			!isDeepEqual(position, statePosition) ||
			!isDeepEqual(styleData, stateStyleData) ||
			backgroundColor !== stateBackgroundColor ||
			foregroundColor !== stateForegroundColor ||
			size !== stateSize ||
			!isDeepEqual(hand, stateHand) ||
			!isDeepEqual(ticks, stateTicks) ||
			!isDeepEqual(label, stateLabel);/* ||
			grid !== stateGrid;*/

		return {
			min,
			max,
			startAngle,
			endAngle,
			ranges,
			position,
			styleData,
			backgroundColor,
			foregroundColor,
			size,
			hand,
			ticks,
			label,
			//grid,
			settingsTimestamp: !settingsUpdated ? (state?.settingsTimestamp || 0) : getTimestamp()
		};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});
