/**
 * Options Field Custom Value Typue enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum OPTIONS_FIELD_CUSTOM_VALUE_TYPE {
	CUSTOM_VALUE = "custom-value",
	SEARCH = "search",
	BOTH = "both"
}

export type OPTIONS_FIELD_CUSTOM_VALUE_TYPE_string = keyof typeof OPTIONS_FIELD_CUSTOM_VALUE_TYPE;

export const OPTIONS_FIELD_CUSTOM_VALUE_TYPE_default: OPTIONS_FIELD_CUSTOM_VALUE_TYPE_string = "CUSTOM_VALUE";
