/**
 * CSS Transitions
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { StyleSheet } from "../Classes/StyleSheet";

/** Transition delay */
export const TRANSITION_DELAY = 100;

/** Transition duration safe delay */
export const TRANSITION_SAFE_TIMEOUT = 100;

/**
 * Item states
 */
export enum ITEM_STATE {
	READY = "ready",
	ADDED = "added",
	REMOVED = "removed",
	DEFAULT = READY
}

/**
 * Sanitize a style
 */
function sanitizeCssText(style: string): string {
	return style.replace(/\s/g, "").replace(/;$/, "");
}

/**
 * Returns transition duration set as css variable
 *
 * @param propertyName Name of the custom property (css variable)
 * @param element Target element
 */
function getTransitionDuration(
	propertyName = "--transition-duration",
	element: HTMLElement = document.documentElement
): string {
	return getComputedStyle(element).getPropertyValue(propertyName);
}

/**
 * Returns sanitized transition duration
 *
 * @param duration Duration in milliseconds (css)
 */
function getSanitizedDuration(duration: number): number {
	return (duration || 0) + TRANSITION_DELAY + TRANSITION_SAFE_TIMEOUT;
}

/**
 * Makes a transition
 * 
 * TODO REWRITE SO IT USES OBJECT CONFIGURATION FOR PROPERTIES
 *
 * @param element Target element
 * @param initialStyle Initial css style string
 * @param targetStyle Target css style string
 * @param property Property name (or names, separated by comma)
 * @param durationPropertyName Transition duration custom property name
 * @param callback Optional callback function
 */
export function makeCssTransition(
	element: HTMLElement,
	initialStyle: React.CSSProperties,
	targetStyle: React.CSSProperties,
	property = "all",
	durationPropertyName?: string,
	callback?: () => void
): void {
	const transitionDuration = getTransitionDuration(durationPropertyName, element) || getTransitionDuration();

	element.style.cssText = sanitizeCssText(StyleSheet.objectToCssText(initialStyle));

	setTimeout(() => {
		element.style.cssText = sanitizeCssText(
			StyleSheet.objectToCssText({ ...targetStyle, transitionDuration, transitionProperty: property })
		);
	}, TRANSITION_DELAY);

	if (typeof callback === "function") {
		setTimeout(callback, getSanitizedDuration(parseFloat(transitionDuration) * 1000));
	}
}
