/**
 * Pagination HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, COMPONENT_MODE, defineElementaryComponent, Type } from "@hexio_io/hae-lib-blueprint";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	termsEditor as HAELibComponentsTerms,
	PaginationProps,
	Pagination,
	getTotalPages
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { isNumber } from "@hexio_io/hae-lib-shared";

/**
 * Pagination state
 */
interface HAEComponentPagination_State {
	page: number;
	specPage: number;
	setPage: (page: number) => void;
}

/**
 * Pagination props
 */
const HAEComponentPagination_Props = {
	...PaginationProps
};

const HAEComponentPagination_Events = {};

const HAEComponentPagination_Definition = defineElementaryComponent<
	typeof HAEComponentPagination_Props,
	HAEComponentPagination_State,
	typeof HAEComponentPagination_Events
>({
	...termsEditor.components.pagination.component,

	name: "pagination",

	category: "navigation",

	icon: "mdi/numeric-1-box-multiple-outline",

	docUrl: "...",

	order: 20,

	props: HAEComponentPagination_Props,

	events: HAEComponentPagination_Events,

	resolve: (spec, state, updateStateAsync) => {
		const { items, limit, page: specPage } = spec;

		let page = (state?.specPage === specPage || !isNumber(specPage)) && state?.page || specPage;

		if (
			!Number.isFinite(page) ||
			(Number.isFinite(items) && Number.isFinite(limit) && page > getTotalPages(items, limit))
		) {
			page = 1;
		}

		function setPage(page: number) {
			updateStateAsync((prevState) => ({ ...prevState, page }));
		}

		return {
			page,
			specPage,
			setPage
		};
	},

	getScopeData: (spec, state) => {
		return {
			limit: spec.limit,
			page: state.page,
			offset: (state.page - 1) * spec.limit,
			setPage: state.setPage
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				limit: props.props.limit.schema.getTypeDescriptor(props.props.limit),
				page: props.props.page.schema.getTypeDescriptor(props.props.page),
				offset: Type.Boolean({ ...HAELibComponentsTerms.schemas.pagination.offset }),
				setPage: Type.Method({
					...HAELibComponentsTerms.schemas.pagination.setPage,
					argRequiredCount: 1,
					argSchemas: [ BP.Integer({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
			}
		});
	}
});

const HAEComponentPagination_React: THAEComponentReact<typeof HAEComponentPagination_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const { componentMode } = componentInstance;

	const _buttonClickHandler = React.useCallback((value: number) => {
		setState((prevState) => ({
			...prevState,
			page: value
		}));
	}, [ state.specPage, componentMode ]);

	return (
		<Pagination
			items={props.items}
			limit={props.limit}
			page={state.page}
			maxDisplayedPages={props.maxDisplayedPages}
			backgroundColor={props.backgroundColor}
			foregroundColor={props.foregroundColor}
			borderRadius={props.borderRadius}
			boxShadow={props.boxShadow}
			item={props.item}
			componentPath={componentInstance.safePath}
			componentMode={componentInstance.componentMode}
			classList={reactComponentClassList}
			onButtonClick={_buttonClickHandler}
		/>
	);
};

export const HAEComponentPagination: THAEComponentDefinition<typeof HAEComponentPagination_Definition> = {
	...HAEComponentPagination_Definition,
	reactComponent: HAEComponentPagination_React
};
