/**
 * Use Chart hook
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { getCSSPropertyValue } from "@hexio_io/hae-lib-components";
import { toNumber } from "@hexio_io/hae-lib-shared";
import { IColors, IStyles } from "../types/charts";
import { getChartColor } from "../Functions/chartHelpers";

export const colorProperties = {
	black: "BLACK",
	white: "WHITE",
	base: "TEXT",
	background: "BACKGROUND",
	primary: "PRIMARY",
	lightGray: "LIGHT_GRAY"
};

/**
 * Use Chart hook
 */
export function useChart(
	resolved: boolean,
	elementRef: React.MutableRefObject<HTMLDivElement>,
	viewportRootElement: HTMLElement,
	animate = false,
	datetimeUtc = false
): {
	rootRef: React.MutableRefObject<am5.Root>;
	colorRef: React.MutableRefObject<IColors>;
	colorValuesString: string;
	styleRef: React.MutableRefObject<IStyles>;
	styleValuesString: string;
} {
	const rootRef = React.useRef<am5.Root>();

	// Colors and styles

	const colorRef = React.useRef<IColors>({ black: null, white: null, base: null, background: null, primary: null, lightGray: null });
	const styleRef = React.useRef<IStyles>({
		borderRadius: null, fontFamily: null, fontSizeBase: null, fontSizeLarge: null, spacing: null
	});

	React.useLayoutEffect(() => {
		if (!resolved) {
			return;
		}

		const colors = {};

		Object.entries(colorProperties).forEach(([ key, value ]) => {
			colors[key] = getChartColor(value, viewportRootElement);
		});

		colorRef.current = colors;
	
		styleRef.current = {
			borderRadius: toNumber(getCSSPropertyValue("--border-radius", viewportRootElement)) || 0,
			fontFamily: (getCSSPropertyValue("font-family", viewportRootElement) || "").split(",")[0] || "sans-serif",
			fontSizeBase: getCSSPropertyValue("--font-size-base", viewportRootElement),
			fontSizeLarge: getCSSPropertyValue("--font-size-large", viewportRootElement),
			spacing: toNumber(getCSSPropertyValue("--spacing-medium", viewportRootElement)) || 10
		};
	}, [ resolved, viewportRootElement ]);

	const colorValuesString = Object.values(colorRef.current).join(" ");
	const styleValuesString = Object.values(styleRef.current).join(" ");

	// Base configuration

	React.useLayoutEffect(() => {
		if (!resolved) {
			return;
		}

		const { base } = colorRef.current;
		const { fontFamily, fontSizeBase } = styleRef.current;

		const root = am5.Root.new(elementRef.current);

		root.interfaceColors.set("text", base);

		const defaultTheme = am5.Theme.new(root);

		defaultTheme.rule("Label").setAll({
			fontFamily,
			fontSize: fontSizeBase
		});

		const themes = [ defaultTheme ];

		if (animate) {
			console.log(111);

			themes.push(am5themes_Animated.new(root));
		}

		root.setThemes(themes);

		root.utc = datetimeUtc;

		rootRef.current = root;

		return () => {
			if (root) {
				root.dispose();
			}
		};
	}, [ resolved, colorValuesString, styleValuesString, animate, datetimeUtc ]);

	return {
		rootRef,
		colorRef,
		colorValuesString,
		styleRef,
		styleValuesString
	};
}



