/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	SCHEMA_CONST_ANY_VALUE_TYPE
} from "@hexio_io/hae-lib-blueprint";
import { FLOW_NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export enum FOR_EACH_NODE_ON_ERROR_TYPES {
	FAIL_ON_FIRST = "fail_on_first",
	FAIL_AFTER_ALL = "fail_after_all",
	IGNORE = "ignore"
}

export const BlueprintFlowNodeTypeMap = {
	name: FLOW_NODE_TYPES.MAP,
	label: "Map",
	icon: "mdi/code-brackets",
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			items: BP.Prop(
				BP.Array({
					label: termsEditor.nodes.map.items.label,
					description: termsEditor.nodes.map.items.description,
					items: BP.Any({
						defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
					}),
					constraints: {
						required: true
					}
				})
			),
			onError: BP.Prop(
				BP.Enum.String({
					label: termsEditor.nodes.map.onError.label,
					description: termsEditor.nodes.map.onError.description,
					default: FOR_EACH_NODE_ON_ERROR_TYPES.FAIL_ON_FIRST,
					options: [
						{
							label: termsEditor.nodes.map.onError.failOnFirst.label,
							description: termsEditor.nodes.map.onError.failOnFirst.description,
							value: FOR_EACH_NODE_ON_ERROR_TYPES.FAIL_ON_FIRST
						},
						{
							label: termsEditor.nodes.map.onError.failAfterAll.label,
							description: termsEditor.nodes.map.onError.failAfterAll.description,
							value: FOR_EACH_NODE_ON_ERROR_TYPES.FAIL_AFTER_ALL
						},
						{
							label: termsEditor.nodes.map.onError.ignore.label,
							description: termsEditor.nodes.map.onError.ignore.description,
							value: FOR_EACH_NODE_ON_ERROR_TYPES.IGNORE
						}
					]
				})
			)
		}
	}),
	editorOptions: {
		displayInPalette: true
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		onSuccess: { label: "On Success", icon: "mdi/check" },
		onError: { label: "On Catch", icon: "mdi/alert" },
		onItem: { label: "On Item", icon: "mdi/debug-step-into" }
	})
};
