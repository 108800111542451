/**
 * Field HAE component state
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export interface IFieldState {
	value: unknown;
	initialValue: unknown;

	empty: boolean;
	touched: boolean;
	changed: boolean;
	valid: boolean;

	/** Methods */
	setValue?: (value: unknown) => void;
	clearValue?: () => void;
}

export const initialFieldState = {
	empty: true,
	touched: false,
	changed: false,
	valid: true
};
