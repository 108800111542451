/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/* eslint-disable no-prototype-builtins */

import { TGenericBlueprintSchema } from "../Schema/IBlueprintSchema";
import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import { IBlueprintSchemaValidatorHandler } from "../Validator/IBlueprintSchemaValidator";
import { getSchemas, getValidators } from "./export";
import { ExportTypes, ISchemaImportExport } from "./ExportTypes";

/**
 * Imports value
 *
 * @param value 
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function importValue(value: any): any {

	if (typeof value === "object") {

		if (value === null) {

			return null;

		} else if (Array.isArray(value)) {

			return value.map((a) => importValue(a));

		} else if (value.hasOwnProperty("_type")) {

			if (value._type === ExportTypes.NAN) {
				return NaN;
			}

			if (value._type === ExportTypes.DATE) {

				if (isNaN(Date.parse(value._value))) {
					throw new Error(`Can't import Date: invalid date string`);
				}

				return new Date(value._value);

			}

			if (!value.hasOwnProperty("_name")) {
				throw new Error(`Can't import schema: missing _name`);
			}

			if (value._type === ExportTypes.SCHEMA) {

				return importSchema(value);

			} else if (value._type === ExportTypes.VALIDATOR) {

				return importValidator(value);

			}

		} else {

			const importedObject = {};

			for (const propName of Object.keys(value)) {
				importedObject[propName] = importValue(value[propName]);
			}

			return importedObject;

		}

	} else {

		return value;

	}

}

/**
 * Imports schema
 *
 * @param data
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function importSchema<TSchema extends TGenericBlueprintSchema>(data: ISchemaImportExport): TSchema {

	if (!data.hasOwnProperty("_type")) {
		throw new Error(`Can't import schema: missing _type`);
	}

	if (!data.hasOwnProperty("_name")) {
		throw new Error(`Can't import schema: missing _name`);
	}

	if (!data.hasOwnProperty("_values")) {
		throw new Error(`Can't import schema: missing _values`);
	}

	if (data._type !== ExportTypes.SCHEMA) {
		throw new Error(`Can't import schema: unsupported import type '${data._type}'`);
	}

	if (!Array.isArray(data._values)) {
		throw new Error(`Can't import schema:  '_values' expected to be an array`);
	}

	const args = importValue(data._values);
	const schemas = getSchemas();

	if (!schemas[data._name]) {
		throw new Error(`Can't import unknown schema '${data._name}'.`);
	}

	return schemas[data._name](...args) as TSchema;

}

/**
 * Imports validator
 *
 * @param data
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function importValidator(data: ISchemaImportExport): IBlueprintSchemaValidatorHandler<any> {

	if (!data.hasOwnProperty("_type")) {
		throw new SchemaDeclarationError("", {}, `Can't import validator: missing '_type' property`);
	}

	if (!data.hasOwnProperty("_name")) {
		throw new SchemaDeclarationError("", {}, `Can't import validator: missing '_name' property`);
	}

	const validatorName = data._name;

	if (!data.hasOwnProperty("_values")) {
		throw new SchemaDeclarationError(validatorName, {}, `Can't import validator: missing _values`);
	}

	if (data._type !== ExportTypes.VALIDATOR) {
		throw new SchemaDeclarationError(validatorName, {}, `Can't import validator: unsupported import type '${data._type}'`);
	}

	if (!Array.isArray(data._values)) {
		throw new SchemaDeclarationError(validatorName, {}, `Can't import validator: '_values' expected to be an array`);
	}

	const args = importValue(data._values);
	const validators = getValidators();

	if (!validators[data._name]) {
		throw new Error(`Can't import unknown validator '${data._name}'.`);
	}

	return validators[data._name](...args);

}