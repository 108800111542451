/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { DOC_ERROR_SEVERITY, IIconNameItem, ISchemaImportExport } from "@hexio_io/hae-lib-blueprint";
import { IIconPackageURLMapping } from "@hexio_io/hae-lib-components";
import { IAppEnvIdPublicKey } from "../../secrets/ISecretsManager";

import { ILockResourceInfo, IResourceDescription } from "../../resources";
import { IApiSessionResponse } from "../common";
import { components, AppStatusEnum } from "./BeApi";
import { IResourceTemplateWithContent } from "../..";
import { IAppEnvs } from "../../envvars";

/** Editor API related typing */
export { AppStatusEnum as DeploymentStatus };
export type TEditorApiAppMetadataResponse = components["schemas"]["Application"];
export type TEditorApiDeploymentStatusResponse = components["schemas"]["AppEnvironment"];
export type IDeploymentStatus = TEditorApiDeploymentStatusResponse;
export type TEditorApiDeploymentsResponse = components["schemas"]["DeploymentCollection"];
export type TEditorApiDeploymentResponse = components["schemas"]["Deployment"];
export type TEditorApiEnvironmentResponse = components["schemas"]["AppEnvironmentSnippet"];
export type IEnvironment = TEditorApiDeploymentStatusResponse;
export type TEditorApiDeploymentEnvironmentsResponse = TEditorApiEnvironmentResponse[];
export type TEditorApiAccountResponse = components["schemas"]["AccountAppMetadata"];
export type TEditorApiTokenResponse = components["schemas"]["JwtToken"]["token"];
export type TEditorApiImpersonateTokenResponse = components["schemas"]["JwtToken"];
export type TEditorApiImpersonateAccountResponse = components["schemas"]["AccountWithRole"];
export type TEditorApiImpersonateAccountsResponse = TEditorApiImpersonateAccountResponse[];
export type TEditorApiUserResponse = components["schemas"]["UserSnippet"];
export type TEditorApiResponseGetResourcesResponse = IResourceDescription[];
export type TEditorApiResponseCreateBlueprintResponse = IResourceDescription;

/** Resource Provider related typing */
export interface IBlueprintMetadata extends IResourceDescription {
	lockedBy?: string;
	lockedAt?: string;
	lockMetadata?: ILockResourceInfo["metadata"];
	modifiedBy?: string;
	modifiedAt?: string;
	revision?: string;
}

export interface IBlueprintContentMetadata extends IBlueprintMetadata {
	content: string;
}

export interface IBlueprintHistoryRecord {
	revision: string;
	modifiedBy: string;
	modifiedAt: string;
	commitMessage: string;
	tags?: string[];
}

export type TEditorApiGetTemplatesResponse = IResourceTemplateWithContent[];

export interface IEditorApiIntegrationFunctionResponse {
	functionName: string;
	label: string;
	description: string;
	icon: string;
	docUrl: string;
	order: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	paramsSchema: ISchemaImportExport;
}

export interface IEditorApiIntegrationResponse {
	integrationType: string;
	label: string;
	category: string;
	description: string;
	icon: string;
	docUrl: string;
	order: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	optionsSchema: ISchemaImportExport;
	functions: IEditorApiIntegrationFunctionResponse[];
	// TODO: this is for backward compatibility. Remove this is the next major release.
	hidden: boolean;
	deprecated?: boolean;
	new?: boolean;
}

export type TEditorApiGetIntegrationsResponse = IEditorApiIntegrationResponse[];

export interface IEditorApiBlueprintRevision {
	revision: string;
	modifiedBy: string;
	modifiedAt: string;
	tags?: string[];
	commitMessage: string;
}

export type TEditorApiBlueprintHistory = IEditorApiBlueprintRevision[];

export interface IEditorApiSessionResponse extends IApiSessionResponse {
	identityKey: any;
	editorLoggedIn: boolean;
}

export interface IEditorApiSessionsStatus {
	userSessionsCount: number;
	userSessionsIdleTime: number;
	guestSessionsCount: number;
	guestSessionsIdleTime: number;
}

export type TEditorApiModelPath = Array<string | number>;

export type TEditorApiParsePath = string[];

export interface IEditorApiDocumentPosition {
	line: number;
	col: number;
}

export interface IEditorApiDocumentRange {
	start: IEditorApiDocumentPosition;
	end: IEditorApiDocumentPosition;
}

export interface IEditorApiCompileError {
	severity: DOC_ERROR_SEVERITY;
	name: string;
	message: string;
	modelPath: TEditorApiModelPath;
}

export interface IEditorApiDocumentError {
	range: IEditorApiDocumentRange;
	severity: DOC_ERROR_SEVERITY;
	name: string;
	message: string;
	parsePath?: TEditorApiParsePath;
}

export interface IEditorApiRuntimeError {
	severity?: DOC_ERROR_SEVERITY;
	name?: string;
	message?: string;
	details?: string[];
	modelPath?: TEditorApiModelPath;
}

export interface IEditorApiErrorListResponse {
	[key: string]: {
		compileErrors: IEditorApiCompileError[];
		parseErrors: IEditorApiDocumentError[];
		runtimeErrors: IEditorApiRuntimeError[];
		uri: string;
		id: string;
	};
}
export interface IEditorApiIconPackResponse {
	iconPacks: Array<{
		name: string;
		label: string;
		shortLabel: string;
		description: string;
		icons: IIconNameItem[];
	}>;
	urlMapping: IIconPackageURLMapping;
}

export type TSecretsManagerGetKeysResponse = Array<string>;

/**
 * Common API Response
 */
export interface IApiResponse<T> {
	data: T;
}

/**
 * Config Response
 */
export interface IEditorApiConfigResponse {
	/** Public constants */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	publicConstants: { [K: string]: any };
	/** Private constants */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	privateConstants: { [K: string]: any };
	/** List of appEnvIds and publicKeys */
	publicKeys: IAppEnvIdPublicKey[];
	/** Internal vars */
	_app: IAppEnvs;
}

export interface IEditorApiManifestDefinition {
	sessionStorages: {
		name: string;
		category: string;
		optionsSchema: ISchemaImportExport;
	}[];
	authProviders: {
		name: string;
		category: string;
		optionsSchema: ISchemaImportExport;
	}[];
}
