/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { OBJECT_TYPE, OBJECT_TYPE_PROP_NAME } from "../constants";
import { RuntimeContext } from "../Context/RuntimeContext";
import { TGenericBlueprintSchema } from "../Schema/IBlueprintSchema";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { IDocumentRange } from "../Shared/IDocumentRange";
import { ITypeDescObject, TypeDescFunction } from "../Shared/ITypeDescriptor";
import { createSubScope, IScope, TScopeData, TScopeType } from "../Shared/Scope";
import { TModelPath } from "../Shared/TModelPath";
import { IFunctionDefinition, TFunctionDefinitionMap } from "./ISchemaFunctionDefinition";

/**
 * Function to create function definition (just for typing)
 *
 * @param definition Function definition
 */
export function declareFunction<
	TArgsSchema extends TGenericBlueprintSchema[],
	TRestArgsSchema extends TGenericBlueprintSchema,
	TSpec
>(
	definition: IFunctionDefinition<TArgsSchema, TRestArgsSchema, TSpec>
): IFunctionDefinition<TArgsSchema, TRestArgsSchema, TSpec> {
	return definition;
}

/**
 * Returns a scope data object that contains function executors and corresponding scope types
 *
 * @param fnMap Function map
 * @param provideTypes If to provide function types
 */
export function functionMapToScopeData(fnMap: TFunctionDefinitionMap, provideTypes = false): {
	data: TScopeData;
	type: TScopeType;
} {

	const data = {};
	const type = {};

	for (const k in fnMap) {

		const fnDef = fnMap[k];

		const executor = (
			rCtx: RuntimeContext,
			path: TModelPath,
			modelNodeId: number,
			scope: IScope,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			args: Array<(scope: IScope) => any>,
			range: IDocumentRange
		) => {

			// Validate argument count
			if (args.length < fnDef.argRequiredCount) {
				rCtx.logRuntimeError({
					severity: DOC_ERROR_SEVERITY.WARNING,
					name: DOC_ERROR_NAME.FUNC_INVALID_ARGUMENTS,
					message: `Function '${fnDef.name}' requires at least ${fnDef.argRequiredCount} argument(s).`,
					modelPath: path,
					modelNodeId: modelNodeId,
					metaData: {
						translationTerm: "function:errors.minArgs",
						args: {
							minArgCount: fnDef.argRequiredCount
						},
						range: range
					}
				});

				return null;
			}

			if (args.length > fnDef.argSchemas.length && !fnDef.argRestSchema) {
				rCtx.logRuntimeError({
					severity: DOC_ERROR_SEVERITY.WARNING,
					name: DOC_ERROR_NAME.FUNC_INVALID_ARGUMENTS,
					message: `Function accepts only ${fnDef.argSchemas.length} argument(s).`,
					modelPath: path,
					modelNodeId: modelNodeId,
					metaData: {
						translationTerm: "function:errors.maxArgs",
						args: {
							maxArgCount: fnDef.argSchemas.length
						},
					}
				});

				return null;
			}

			const argGetters = [];
			const restArgGetters = [];

			for (let i = 0; i < fnDef.argSchemas.length; i++) {
				argGetters.push((scopeData?: TScopeData, scopeType?: ITypeDescObject["props"]) => {
					const val = args[i] ? args[i](createSubScope(scope, scopeData, scopeType)) : null;

					if (!fnDef.argSchemas[i].validate(rCtx, path, modelNodeId, val, true)) {
						return null;
					}

					return val;
				});
			}

			for (let i = fnDef.argSchemas.length; i < args.length; i++) {
				restArgGetters.push((scopeData?: TScopeData, scopeType?: ITypeDescObject["props"]) => {
					const val = args[i] ? args[i](createSubScope(scope, scopeData, scopeType)) : null;

					if (!fnDef.argRestSchema.validate(rCtx, path, modelNodeId, val, true)) {
						return null;
					}

					return val;
				});
			}

			return fnDef.render(rCtx, argGetters, restArgGetters, scope);

		};

		Object.defineProperty(executor, "name", { value: `functionExecutor[${fnDef.name}]` });
		Object.defineProperty(executor, "toString", { value: () => `function[${fnDef.name}]` });
		executor[OBJECT_TYPE_PROP_NAME] = OBJECT_TYPE.FUNCTION;

		data[k] = executor;

		if (provideTypes) {
			type[k] = TypeDescFunction({
				label: fnDef.label,
				description: fnDef.description,
				category: fnDef.category,
				argRequiredCount: fnDef.argRequiredCount,
				argSchemas: fnDef.argSchemas,
				argRestSchema: fnDef.argRestSchema,
				returnType: fnDef.returnType
			});
		}

	}

	return {
		data, type
	};

}