/**
 * Use Drag and Drop State Change Handler
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { IEditContextValue } from "../Editor/EditContext";

/**
 * @param editContext Edit context
 * @param handler Function handler
 * @param dependencies Optional hook dependencies
 */
export function useDnDStateChangeHandler(
	editContext: IEditContextValue,
	handler: (active: boolean) => void,
	dependencies: React.DependencyList = []
): void {
	React.useEffect(() => {
		if (!editContext) {
			return;
		}

		const { componentDnD } = editContext;

		let active = false;

		function _stateChangeHandler() {
			const entered = componentDnD.wasEntered();

			if (active === entered) {
				return;
			}

			active = entered;

			handler(active);
		}

		onEvent(componentDnD.onStateChange, _stateChangeHandler);

		return () => {
			handler(false);

			offEvent(componentDnD.onStateChange, _stateChangeHandler);
		}
	}, [ editContext, ...dependencies ]);
}