/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ILogger } from "../logger";
import { ISessionStorage, ISessionStorageOptions } from "./ISessionStorage";
import { ISessionStorageFactory } from "./ISessionStorageFactory";

/**
 * Creates Session Storage instance
 * 
 * @param entity Session Storage class
 * @returns 
 */
export function createSessionStorageFactory<
	TOptions extends ISessionStorageOptions,
	TClass extends ISessionStorage
>(
	entity: new (
		config: TOptions,
		logger: ILogger
	) => TClass
): ISessionStorageFactory<TClass, TOptions> {

	return {
		factory: (
			config: TOptions,
			logger: ILogger
		) => new entity(config, logger)
	}

}