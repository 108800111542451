/**
 * Button Style enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum BUTTON_STYLE {
	DEFAULT = "default",
	CLEAR = "clear",
	TAB = "tab",
	CUSTOM = "custom"
}

export type BUTTON_STYLE_string = keyof typeof BUTTON_STYLE;
