/**
 * Z-index schema
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstEnum,
	ISchemaConstString,
	ISchemaValue,
	ISchemaValueEnumStringOpts,
} from "@hexio_io/hae-lib-blueprint";
import { Z_INDEX, Z_INDEX_default } from "../Enums/Z_INDEX";
import { getValuesFromStringEnum } from "../Functions/enumHelpers";

import { termsEditor } from "../terms";

/**
 * Z-index schema
 * 
 * @param opts Options
 */
export function ZIndexSchema(
	opts: Partial<ISchemaValueEnumStringOpts> = { alias: "zIndex" }
): ISchemaValue<ISchemaConstEnum<ISchemaConstString>> {
	return BP.Enum.String({
		...termsEditor.schemas.common.zIndex,
		options: getValuesFromStringEnum(
			Z_INDEX,
			termsEditor.schemas.common.zIndexValues
		),
		default: Z_INDEX_default,
		fallbackValue: Z_INDEX_default,
		constraints: {
			required: true
		},
		...opts
	});
}