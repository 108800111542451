/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	CompileContext,
	DesignContext,
	RuntimeContext,
	TBlueprintIDTNode
} from "@hexio_io/hae-lib-blueprint";

import { cloneDeep } from "@hexio_io/hae-lib-shared";
import { DOC_TYPES } from "../blueprints";
import { ERROR_REPORT_TYPE } from "../errors";
import {
	IResourceAttributes,
	IResourceDependency,
	IResourceMetadata,
	IResourceParsedData,
	IResourceParsingDetails,
	IResourceProps
} from "../registries";
import { RESOURCE_TYPES } from "./IResource";
import { IResourceErrorReport } from "./IResourceManager";

export class Resource implements IResourceProps {

	public name: string;
	public category?: string;
	public uri?: string;
	public location?: string;
	public label?: string;
	public basePath?: string;
	public docType?: DOC_TYPES;
	public resourceType?: RESOURCE_TYPES;
	public id?: string;
	public content?: string;
	public lastModified?: Date;
	public idt?: TBlueprintIDTNode;
	public parsedData?: IResourceParsedData;
	public attributes?: IResourceAttributes;
	public metadata?: IResourceMetadata;
	public errors?: IResourceErrorReport;
	public dependencies?: IResourceDependency[];
	public changedTime?: Date;
	public parsingDetails?: IResourceParsingDetails;
	public lockedBy?: string;
	public modifiedBy?: string;
	public modifiedAt?: string;
	public revision?: string;

	public get isValid(): boolean {
		let isValid = true;
		if (this.parsingDetails) {
			for (const value of Object.values(this.parsingDetails)) {
				if (value !== true) {
					isValid = false;
					break;
				}
			}
		}
		return isValid;
	}

	public set isValid(value: boolean) {
		// read-only
	}

	public constructor(config:
		{
			name: string;
			category?: string;
			uri?: string;
			label?: string;
			location?: string;
			basePath?: string;
			docType?: DOC_TYPES;
			resourceType?: RESOURCE_TYPES;
			id?: string;
			content?: string;
			lastModified?: Date;
			idt?: TBlueprintIDTNode;
			parsedData?: IResourceParsedData;
			attributes?: IResourceAttributes;
			metadata?: IResourceMetadata;
			errors?: IResourceErrorReport;
			dependencies?: IResourceDependency[];
			changedTime?: Date;
			parsingDetails?: IResourceParsingDetails;
			lockedBy?: string;
			modifiedBy?: string;
			modifiedAt?: string;
			revision?: string;
		}
	) {
		this.name = config.name;
		this.category = config.category;
		this.uri = config.uri;
		this.location = config.location;
		this.label = config.label;
		this.basePath = config.basePath;
		this.docType = config.docType;
		this.resourceType = config.resourceType;
		this.id = config.id;
		this.content = config.content;
		this.lastModified = config.lastModified;
		this.idt = config.idt;
		this.parsedData = config.parsedData || {};
		this.attributes = config.attributes || {};
		this.metadata = config.metadata || {};
		this.errors = config.errors;
		this.dependencies = config.dependencies || [];
		this.changedTime = new Date();
		this.parsingDetails = config.parsingDetails;
		this.lockedBy = config.lockedBy || null;
		this.modifiedBy = config.modifiedBy || null;
		this.modifiedAt = config.modifiedAt || null;
		this.revision = config.revision || null;
	}

	protected upsertErrors(): IResourceErrorReport {

		return this.errors = this.errors ? this.errors : this.errors = {
			type: ERROR_REPORT_TYPE.BLUEPRINT,
			uri: this.uri,
			id: this?.id || null,
			parseErrors: [],
			runtimeErrors: [],
			compileErrors: []
		};

	}

	public reportParsingErrors(dCtx: DesignContext): void {

		this.upsertErrors();
		const parseErrors = dCtx.getParseErrors();

		const uriList = Object.keys(parseErrors);

		if (uriList.length > 1) {
			console.log(parseErrors);
			throw new Error("design context contains error for other resources");
		}

		this.errors.parseErrors = this.errors.parseErrors.concat(parseErrors[this.uri] || []);

	}

	public reportCompileErrors(cCtx: CompileContext): void {

		this.upsertErrors();
		const compileErrors = cCtx.getCompileErrors();
		this.errors.compileErrors = this.errors.compileErrors.concat(compileErrors || []);

	}

	public reportRuntimeErrors(rCtx: RuntimeContext): void {

		this.upsertErrors();
		const runtimeErrors = rCtx.getRuntimeErrors();
		this.errors.runtimeErrors = this.errors.runtimeErrors.concat(runtimeErrors || []);

	}

	public clone(props?: string[]): IResourceProps {

		const clone = { ...cloneDeep(this), isValid: this.isValid };

		if (Array.isArray(props)) {
			for (const name of Object.keys(clone)) {
				if (!props.includes(name)) {
					delete clone[name];
				}
			}
		}

		return clone;

	}

}
