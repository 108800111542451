/**
 * Container Item Flex enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TMapMediaResolutions } from "@hexio_io/hae-lib-blueprint";

export enum CONTAINER_ITEM_FLEX {
	AUTO = "auto",
	NONE = "none",
	STRETCH = "stretch",
	SHRINK = "shrink",
	STRETCH_SHRINK = "stretch-shrink"
}

export type CONTAINER_ITEM_FLEX_string = keyof typeof CONTAINER_ITEM_FLEX;
export type CONTAINER_ITEM_FLEX_resolution = TMapMediaResolutions<CONTAINER_ITEM_FLEX_string|"INHERIT">;

export const CONTAINER_ITEM_FLEX_default: CONTAINER_ITEM_FLEX_string = "AUTO";

export const CONTAINER_ITEM_FLEX_none: CONTAINER_ITEM_FLEX_string = "NONE";

export const CONTAINER_ITEM_FLEX_inherit = "INHERIT";

export const CONTAINER_ITEM_FLEX_resolution_default: CONTAINER_ITEM_FLEX_resolution = {
	screenBase: CONTAINER_ITEM_FLEX_default,
	screenLarge: CONTAINER_ITEM_FLEX_inherit,
	screenTablet: CONTAINER_ITEM_FLEX_inherit,
	screenPhone: CONTAINER_ITEM_FLEX_inherit,
	print: CONTAINER_ITEM_FLEX_inherit
};
