/**
 * Boolean From String enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { termsEditor } from "../terms";

export enum BOOLEAN {
	TRUE = "true",
	FALSE = "false"
}

export type BOOLEAN_string = keyof typeof BOOLEAN;

export const BOOLEAN_default: BOOLEAN_string = "TRUE";
export const BOOLEAN_emptyStringDefault: BOOLEAN_string = "FALSE";
export const BOOLEAN_miscStringDefault: BOOLEAN_string = "TRUE";

export const BOOLEAN_opts = {
	true: {
		...termsEditor.schemas.common.booleanValues.true
	},
	false: {
		...termsEditor.schemas.common.booleanValues.false
	}
};
