/**
 * Color schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const color = {
	background: {
		label: "schema:color.background#label",
		description: "schema:color.background#description"
	},
	foreground: {
		label: "schema:color.foreground#label",
		description: "schema:color.foreground#description"
	},
	border: {
		label: "schema:color.border#label",
		description: "schema:color.border#description"
	},
	values: {
		inherit: {
			label: "schema:color#values.inherit#label"
		},
		transparent: {
			label: "schema:color#values.transparent#label"
		},
		black: {
			label: "schema:color#values.black#label"
		},
		white: {
			label: "schema:color#values.white#label"
		},
		gray: {
			label: "schema:color#values.gray#label"
		},
		light_gray: {
			label: "schema:color#values.light_gray#label"
		},
		red: {
			label: "schema:color#values.red#label"
		},
		green: {
			label: "schema:color#values.green#label"
		},
		blue: {
			label: "schema:color#values.blue#label"
		},
		yellow: {
			label: "schema:color#values.yellow#label"
		},
		orange: {
			label: "schema:color#values.orange#label"
		},
		primary: {
			label: "schema:color#values.primary#label"
		},
		secondary: {
			label: "schema:color#values.secondary#label"
		},
		success: {
			label: "schema:color#values.success#label"
		},
		warning: {
			label: "schema:color#values.warning#label"
		},
		error: {
			label: "schema:color#values.error#label"
		},
		danger: {
			label: "schema:color#values.danger#label"
		},
		background: {
			label: "schema:color#values.background#label"
		},
		text: {
			label: "schema:color#values.text#label"
		},
		icon: {
			label: "schema:color#values.icon#label"
		},
		field_background: {
			label: "schema:color#values.field_background#label"
		}
	},
	constraints: {
		patternErrorMessage: "schema:color#constraints.patternError#message"
	},
};
