/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IExpAstRange, TExpAst_Expression, NODE_KIND, TExpAstNode } from "./ExpAst";

export enum EXP_MOD_TYPE {
	REPLACE = "replace"
}

export interface IExpModification {
	type: EXP_MOD_TYPE;
	range: IExpAstRange;
	value: string;
}

/**
 * Traverses all ast node and applies cb to them
 *
 * @param ast Ast node
 * @param cb Callback
 */
export function traverseExpAst(node: TExpAstNode, cb: (node: TExpAstNode) => void): void {

	if (!node) {
		return;
	}

	switch (node.kind) {
		case NODE_KIND.STRING_TEMPLATE: {
			for (let i = 0; i < node.elements.length; i++) {
				traverseExpAst(node.elements[i], cb);
			}
			break;
		}
		case NODE_KIND.VALUE_REF: {
			traverseExpAst(node.identifier, cb);
			break;
		}
		case NODE_KIND.INDEX_ACCESSOR: {
			traverseExpAst(node.baseReference, cb);
			traverseExpAst(node.index, cb);
			break;
		}
		case NODE_KIND.ARGUMENT_LIST: {
			for (let i = 0; i < node.arguments.length; i++) {
				traverseExpAst(node.arguments[i], cb);
			}
			break;
		}
		case NODE_KIND.FUNCTION_CALL: {
			traverseExpAst(node.reference, cb);
			traverseExpAst(node.argList, cb);
			break;
		}
		case NODE_KIND.PREFIX_OPERATOR: {
			traverseExpAst(node.right, cb);
			break;
		}
		case NODE_KIND.OPERATOR: {
			traverseExpAst(node.left, cb);
			traverseExpAst(node.right, cb);
			break;
		}
		case NODE_KIND.LIST: {
			for (let i = 0; i < node.elements.length; i++) {
				traverseExpAst(node.elements[i], cb);
			}
			break;
		}
		case NODE_KIND.OBJECT_PROPERTY: {
			traverseExpAst(node.key, cb);
			traverseExpAst(node.value, cb);
			break;
		}
		case NODE_KIND.OBJECT: {
			for (let i = 0; i < node.properties.length; i++) {
				traverseExpAst(node.properties[i], cb);
			}
			break;
		}
	}

	cb(node);

}

/**
 * Applies modifications to the source and return modified one
 *
 * @param source Source code
 * @param modifications Array of modification operations 
 */
export function applyExpModifications(source: string, modifications: IExpModification[]): string {

	const offsetChanges: Array<{
		offset: number;
		length: number;
	}> = [];

	let result = source;

	for (let i = 0; i < modifications.length; i++) {

		const mod = modifications[i];
		let _startOffset = mod.range.start.offset;
		let _endOffset = mod.range.end.offset;

		for (let j = 0; j < offsetChanges.length; j++) {
			if (offsetChanges[j].offset <= _startOffset) {
				_startOffset += offsetChanges[j].length;
			}
			if (offsetChanges[j].offset <= _endOffset) {
				_endOffset += offsetChanges[j].length;
			}
		}

		const before = result.substr(0, _startOffset);
		const after = result.substr(_endOffset);

		result = before + mod.value + after;

		if (_endOffset - _startOffset !== mod.value.length) {
			offsetChanges.push({
				offset: _startOffset,
				length: mod.value.length - (_endOffset - _startOffset)
			});
		}

	}

	return result;

}

/**
 * Renames all occurances of identifier and returns new source
 *
 * @param source Source
 * @param ast AST
 */
export function renameExpRootIdentifiers(source: string, ast: TExpAst_Expression, oldIdentifier: string, newIdentifier: string): string {

	const modifications: IExpModification[] = [];

	traverseExpAst(ast, (node) => {
		if (node.kind === NODE_KIND.VALUE_REF && node.identifier.value === oldIdentifier) {
			modifications.push({
				type: EXP_MOD_TYPE.REPLACE,
				range: node.identifier.range,
				value: newIdentifier
			});
		}
	});

	return applyExpModifications(source, modifications);

}
