/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const nodesTerms = {
	common: {
		options: {
			label: "nodes:common.options#label",
			description: "nodes:common.options#description"
		},
		headers: {
			label: "nodes:common.headers#label",
			description: "nodes:common.headers#description"
		},
		header: {
			label: "nodes:common.header#label",
			description: "nodes:common.header#description"
		},
		body: {
			label: "nodes:common.body#label",
			description: "nodes:common.body#description"
		},
		status: {
			label: "nodes:common.status#label",
			description: "nodes:common.status#description"
		}
	},
	transform: {
		code: {
			label: "nodes:transform.code#label",
			description: "nodes:transform.code#description"
		}
	},
	setSession: {
		userIdentityKey: {
			label: "nodes:setSession.userIdentityKey#label",
			description: "nodes:setSession.userIdentityKey#description"
		},
		meta: {
			label: "nodes:setSession.meta#label",
			description: "nodes:setSession.meta#description",
			value: {
				label: "nodes:setSession.meta.value#label",
				description: "nodes:setSession.meta.value#description"
			}
		}
	},
	map: {
		label: "nodes:map#label",
		description: "nodes:map#description",
		items: {
			label: "nodes:map.items#label",
			description: "nodes:map.items#description"
		},
		onError: {
			label: "nodes:map.onError#label",
			description: "nodes:map.onError#description",
			failOnFirst: {
				label: "nodes:map.onError.failOnFirst#label",
				description: "nodes:map.onError.failOnFirst#description"
			},
			failAfterAll: {
				label: "nodes:map.onError.failAfterAll#label",
				description: "nodes:map.onError.failAfterAll#description"
			},
			ignore: {
				label: "nodes:map.onError.ignore#label",
				description: "nodes:map.onError.ignore#description"
			}
		}
	},
	filter: {
		label: "nodes:filter#label",
		description: "nodes:filter#description",
		items: {
			label: "nodes:filter.items#label",
			description: "nodes:filter.items#description"
		},
		condition: {
			label: "nodes:filter.condition#label",
			description: "nodes:filter.condition#description"
		}
	},
	reduce: {
		label: "nodes:reduce#label",
		description: "nodes:reduce#description",
		items: {
			label: "nodes:reduce.items#label",
			description: "nodes:reduce.items#description"
		},
		initialValue: {
			label: "nodes:reduce.initialValue#label",
			description: "nodes:reduce.initialValue#description"
		},
		ignoreErrors: {
			label: "nodes:reduce.ignoreErrors#label",
			description: "nodes:reduce.ignoreErrors#description"
		}
	},
	condition: {
		condition: {
			label: "nodes:condition.condition#label",
			description: "nodes:condition.condition#description"
		}
	},
	error: {
		message: {
			label: "nodes:error.message#label",
			description: "nodes:error.message#description"
		},
		errorName: {
			label: "nodes:error.errorName#label",
			description: "nodes:error.errorName#description"
		},
		data: {
			label: "nodes:error.data#label",
			description: "nodes:error.data#description"
		}
	},
	output: {
		value: {
			label: "nodes:output.value#label",
			description: "nodes:output.value#description"
		}
	},
	continue: {
		value: {
			label: "nodes:continue.value#label",
			description: "nodes:continue.value#description"
		},
		skipValue: {
			label: "nodes:continue.skipValue#label",
			description: "nodes:continue.skipValue#description"
		}
	},
	break: {
		value: {
			label: "nodes:break.value#label",
			description: "nodes:break.value#description"
		}
	},
	variable: {
		value: {
			label: "nodes:variable.value#label",
			description: "nodes:variable.value#description"
		}
	},
	timeout: {
		value: {
			label: "nodes:timeout.value#label",
			description: "nodes:timeout.value#description"
		}
	},
	redirect: {
		url: {
			label: "nodes:redirect.url#label",
			description: "nodes:redirect.url#description"
		},
		query: {
			label: "nodes:redirect.query#label",
			description: "nodes:redirect.query#description",
			value: {
				label: "nodes:redirect.query.value#label",
				description: "nodes:redirect.query.value#description"
			}
		}
	},
	request: {
		path: {
			label: "nodes:request.path#label",
			description: "nodes:request.path#description"
		},
		method: {
			label: "nodes:request.method#label",
			description: "nodes:request.method#description"
		},
		tags: {
			label: "nodes:request.tags#label",
			description: "nodes:request.tags#description",
			item: {
				label: "nodes:request.tags.item#label",
				description: "nodes:request.tags.item#description"
			}
		},
		parameter: {
			label: "nodes:request.parameter#label",
			description: "nodes:request.parameter#description",
			name: {
				label: "nodes:request.parameter.name#label",
				description: "nodes:request.parameter.name#description"
			},
			in: {
				label: "nodes:request.parameter.in#label",
				description: "nodes:request.parameter.in#description"
			},
			allowEmptyValue: {
				label: "nodes:request.parameter.allowEmptyValue#label",
				description: "nodes:request.parameter.allowEmptyValue#description"
			}
		},
		parameters: {
			label: "nodes:request.parameters#label",
			description: "nodes:request.parameters#description"
		},
		requestBody: {
			label: "nodes:request.requestBody#label",
			description: "nodes:request.requestBody#description",
			content: {
				label: "nodes:request.requestBody.content#label",
				description: "nodes:request.requestBody.content#description"
			}
		},
		mediaType: {
			label: "nodes:request.mediaType#label",
			description: "nodes:request.mediaType#description"
		},
		description: {
			label: "nodes:request.description#label",
			description: "nodes:request.description#description"
		},
		required: {
			label: "nodes:request.required#label",
			description: "nodes:request.required#description"
		},
		deprecated: {
			label: "nodes:request.deprecated#label",
			description: "nodes:request.deprecated#description"
		},
		jsonSchema: {
			label: "nodes:request.jsonSchema#label",
			description: "nodes:request.jsonSchema#description"
		},
		example: {
			label: "nodes:request.example#label",
			description: "nodes:request.example#description",
			value: {
				label: "nodes:request.example.value#label",
				description: "nodes:request.example.value#description"
			}
		},
		examples: {
			label: "nodes:request.examples#label",
			description: "nodes:request.examples#description"
		},
		summary: {
			label: "nodes:request.summary#label",
			description: "nodes:request.summary#description"
		}
	}
};
