/**
 * Sidebar Layout Width enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum SIDEBAR_LAYOUT_WIDTH {
	SMALL = "small",
	MEDIUM = "medium",
	LARGE = "large"
}

export type SIDEBAR_LAYOUT_WIDTH_string = keyof typeof SIDEBAR_LAYOUT_WIDTH;

export const SIDEBAR_LAYOUT_WIDTH_default = "MEDIUM";
