/**
 * Label Flow enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum LABEL_FLOW {
	ROW_ICON_TEXT = "row-icon-text",
	ROW_TEXT_ICON = "row-text-icon",
	COLUMN_ICON_TEXT = "column-icon-text",
	COLUMN_TEXT_ICON = "column-text-icon"
}

export type LABEL_FLOW_string = keyof typeof LABEL_FLOW;

export const LABEL_FLOW_default: LABEL_FLOW_string = "ROW_ICON_TEXT";
