/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry, ItemRegistryItemProps } from "@hexio_io/hae-lib-shared";
import { IComponentsRegistry } from "./IComponentsRegistry";

interface ComponentRegistryItemProps extends ItemRegistryItemProps {
	component: unknown;
}

export class ComponentsRegistry implements IComponentsRegistry {

	protected registry: ItemRegistry<ComponentRegistryItemProps> = new ItemRegistry<ComponentRegistryItemProps>();

	public register(name: string, component: unknown): void {
		this.registry.register({ name, component });
	}

	public remove(name: string): void {
		this.registry.unregister(name);
	}

	public get<T>(name: string): T {
		return this.registry.get(name) as unknown as T;
	}

	public getAll(): { [K: string]: unknown } {

		const resolvers = {};
		for (const [name, props] of Object.entries(this.registry.getItems())) {
			resolvers[name] = props.component;
		}
		return resolvers;

	}

	public flush(): void {
		this.registry.flush();
	}

}