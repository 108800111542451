/**
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createContext } from "react";
import { TLinkLocationSpec } from "../SharedSchemas/LinkLocation";
import { IResolvedLink, IResolvedLocation, IResolveLinkOpts } from "./IResolvedLink";

export interface IRoutingContextValue {
	/**
	 * Resolves link based on link schema spec and options
	 * 
	 * @param linkSpec Location specification
	 * @param opts Options
	 */
	resolveLink: (linkSpec: TLinkLocationSpec, opts: IResolveLinkOpts) => IResolvedLink;

	/**
	 * Navigates to a new path (does not reload the page)
	 */
	navigate: (path: string) => void;

	/**
	 * Current location object
	 */
	location: IResolvedLocation;
}

/**
 * Routing context
 */
export const RoutingContext = createContext<IRoutingContextValue>(null);
