/**
 * Custom Chart component terms
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const customComponent = {
	component: {
		label: "component:customComponent#label",
		description: "component:customComponent#description"
	},
	props: {
		label: "schema:customComponent.props#label",
		description: "schema:customComponent.props#description"
	},
	htmlTemplate: {
		label: "schema:customComponent.htmlTemplate#label",
		description: "schema:customComponent.htmlTemplate#description"
	},
	className: {
		label: "schema:customComponent.className#label",
		description: "schema:customComponent.className#description"
	},
	styleSheet: {
		label: "schema:customComponent.styleSheet#label",
		description: "schema:customComponent.styleSheet#description"
	},
	onMountCode: {
		label: "schema:customComponent.onMountCode#label",
		description: "schema:customComponent.onMountCode#description"
	},
	onPropsChangedCode: {
		label: "schema:customComponent.onPropsChangedCode#label",
		description: "schema:customComponent.onPropsChangedCode#description"
	},
	onUpdateMethodCode: {
		label: "schema:customComponent.onUpdateMethodCode#label",
		description: "schema:customComponent.onUpdateMethodCode#description"
	},
	onDisposeCode: {
		label: "schema:customComponent.onDisposeCode#label",
		description: "schema:customComponent.onDisposeCode#description"
	},
	externalScripts: {
		label: "schema:customComponent.externalScripts#label",
		description: "schema:customComponent.externalScripts#description"
	},
	externalModules: {
		label: "schema:customComponent.externalModules#label",
		description: "schema:customComponent.externalModules#description"
	},
	customEvent: {
		label: "schema:customComponent.customEvent#label",
		description: "schema:customComponent.customEvent#description"
	}
};
