/**
 * CSS helpers
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { isBrowser } from "@hexio_io/hae-lib-shared";

/**
 * Returns CSS property value (client only)
 *
 * @param name Variable name
 * @param element Element
 */
export function getCSSPropertyValue(
	name: string,
	element?: HTMLElement
): string {
	if (isBrowser()) {
		const targetElement = element || document.documentElement;
		const value = getComputedStyle(targetElement).getPropertyValue(name);

		if (value) {
			return value.trim();
		}
	}

	return "";
}