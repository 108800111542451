/**
 * Grid helper functions
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { toNumber } from "@hexio_io/hae-lib-shared";

/**
 * Returns css grid-area value
 */
export function getGridArea(x: number, y: number, width: number, height: number): string {
	return [ y + 1, x + 1, y + 1 + height, x + 1 + width ].join(" / ");
}

/**
 * Cell dimensions
 */
export interface IGridCellDimensions {
	width: number;
	height: number;
	baseHeight: number;
}

/**
 * Default cell dimensions
 */
const defaultGridCellDimensions: IGridCellDimensions = {
	width: 20,
	height: 20,
	baseHeight: 20
};

/**
 * Returns grid cell's width & height (in pixels)
 * 
 * @param element Main grid element
 */
export function getGridCellDimensions(element: HTMLDivElement): IGridCellDimensions {
	if (!element) {
		return defaultGridCellDimensions;
	}

	const content = element.querySelector(":scope > .cmp-grid__inner > .cmp-grid__content");

	if (!content) {
		return defaultGridCellDimensions;
	}

	const style = getComputedStyle(content, "::before");

	const width = toNumber(style.getPropertyValue("width"));
	const height = toNumber(style.getPropertyValue("height"));
	const baseHeight = toNumber(getComputedStyle(content).getPropertyValue("--layout-grid-row-height"));

	return {
		width: Number.isFinite(width) ? width : defaultGridCellDimensions.width,
		height: Number.isFinite(height) ? height : defaultGridCellDimensions.height,
		baseHeight: Number.isFinite(baseHeight) ? baseHeight : defaultGridCellDimensions.baseHeight
	};
}
