/**
 * Composed Chart Series Type enum
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum COMPOSED_CHART_SERIES_TYPE {
	LINE = "line",
	BAR = "bar"
}

export type COMPOSED_CHART_SERIES_TYPE_string = keyof typeof COMPOSED_CHART_SERIES_TYPE;

export const COMPOSED_CHART_SERIES_TYPE_default: COMPOSED_CHART_SERIES_TYPE_string = "LINE";

export const COMPOSED_CHART_SERIES_TYPE_opts = {
	line: {
		icon: "mdi/chart-line"
	},
	bar: {
		icon: "mdi/chart-bar"
	}
};
