/**
 * Label HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	Type,
	defineElementaryComponent,
	createSubScope,
	BP,
	SCHEMA_VALUE_TYPE,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaSpec,
	TGetBlueprintSchemaModel,
} from "@hexio_io/hae-lib-blueprint";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	Label,
	getValuesFromStringEnum,
	termsEditor as HAELibComponentsTerms,
	VERTICAL_ALIGN,
	VERTICAL_ALIGN_default,
	ClassList,
	TextSchema,
	TextValueSchema,
	IconSchema,
	getStringEnumValue,
	IconSourceSchema,
	HORIZONTAL_ALIGN,
	HORIZONTAL_ALIGN_default,
	BackgroundColorSchema,
	StyleSheet,
	propGroups,
	IconProps,
	TextProps,
	LABEL_FLOW,
	LABEL_FLOW_default,
	LABEL_FLOW_string,
	HORIZONTAL_ALIGN_opts,
	VERTICAL_ALIGN_opts,
	useStyleSheetRegistry,
	isEventEnabled,
	BorderRadiusSchema,
	TTextPropsModelNode,
	TTextProps
} from "@hexio_io/hae-lib-components";
import { BaseEvents } from "../../base/BaseEvents";
import { termsEditor } from "../../terms";

interface HAEComponentLabel_State {}

const HAEComponentLabel_Props = {

	text: BP.Prop(TextSchema({
		alias: "text_label",
		props: {
			value: BP.Prop(TextValueSchema({
				alias: "textValue_label",
				default: "Label"
			}), TextProps.value.order, TextProps.value.group),

			align: BP.Prop(BP.Void({})),

			overflow: BP.Prop(BP.Void({}))
		}
	}), 0, propGroups.common),

	icon: BP.Prop(IconSchema({
		alias: "icon_label",
		props: {
			source: BP.Prop(IconSourceSchema({
				alias: "iconSource_label",
				default: "mdi/check"
			}), IconProps.source.order, IconProps.source.group)
		}
	}), 10, propGroups.common),

	flow: BP.Prop(BP.Enum.String({
		...HAELibComponentsTerms.schemas.label.flow,
		options: getValuesFromStringEnum(LABEL_FLOW, HAELibComponentsTerms.schemas.label.flowValues),
		default: LABEL_FLOW_default
	}), 20, propGroups.common),

	horizontalAlign: BP.Prop(BP.Enum.String({
		...HAELibComponentsTerms.schemas.common.horizontalAlign,
		options: getValuesFromStringEnum(
			HORIZONTAL_ALIGN,
			HAELibComponentsTerms.schemas.common.horizontalAlignValues,
			HORIZONTAL_ALIGN_opts
		),
		default: HORIZONTAL_ALIGN_default,
		editorOptions: {
			controlType: "buttons"
		},
	}), 30, propGroups.common),

	verticalAlign: BP.Prop(BP.Enum.String({
		...HAELibComponentsTerms.schemas.common.verticalAlign,
		options: getValuesFromStringEnum(
			VERTICAL_ALIGN,
			HAELibComponentsTerms.schemas.common.verticalAlignValues,
			VERTICAL_ALIGN_opts
		),
		default: VERTICAL_ALIGN_default,
		editorOptions: {
			controlType: "buttons"
		},
	}), 40, propGroups.common),

	backgroundColor: BP.Prop(BackgroundColorSchema(), 0, propGroups.style),

	borderRadius: BP.Prop(BorderRadiusSchema(), 10, propGroups.style)

};

const HAEComponentLabel_Events = {
	...BaseEvents
};

const HAEComponentLabel_Definition = defineElementaryComponent<
	typeof HAEComponentLabel_Props,
	HAEComponentLabel_State,
	typeof HAEComponentLabel_Events
>({

	...termsEditor.components.label.component,

	name: "label",

	category: "content",

	icon: "mdi/text-short",

	docUrl: "...",

	order: 20,

	props: HAEComponentLabel_Props,

	events: HAEComponentLabel_Events,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}

});

const HAEComponentLabel_React: THAEComponentReact<typeof HAEComponentLabel_Definition> = ({
	props, componentInstance, reactComponentClassList
}) => {
	const {
		backgroundColor,
		borderRadius
	} = props;

	const elementProps: React.HTMLProps<HTMLDivElement> = {};

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-label", componentInstance.safePath, { componentClassList: reactComponentClassList }
	);

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		// Background color

		if (backgroundColor) {
			result.addColorProperties({ selector: `.${idClassName}`, name: "element-background-color", value: backgroundColor });
		}

		// Border radius

		if (borderRadius) {
			result.addString(`.${idClassName}`, `--element-border-radius: ${borderRadius} !important;`);
		}

		return result;
	}, [ idClassName, backgroundColor, borderRadius ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	const horizontalAlignValue = getStringEnumValue(HORIZONTAL_ALIGN, props.horizontalAlign, HORIZONTAL_ALIGN_default);
	const verticalAlignValue = getStringEnumValue(VERTICAL_ALIGN, props.verticalAlign, VERTICAL_ALIGN_default);

	classList.addModifiers({
		"horizontal-align": horizontalAlignValue,
		"vertical-align": verticalAlignValue
	});

	// Editable

	const textProp = componentInstance.modelNode?.props?.props?.text as any;
	const textPropsModelNode = textProp?.type === SCHEMA_VALUE_TYPE.CONST ? textProp.constant : null;

	// Event handler

	const _clickHandler = React.useMemo(() => {
		if (isEventEnabled(componentInstance.eventEnabled.click, componentInstance.componentMode)) {
			return () => componentInstance.eventTriggers.click((parentScope) => createSubScope(parentScope));
		}

		return;
	}, [ componentInstance.componentMode, componentInstance.eventEnabled.click, componentInstance.eventTriggers.click ]);

	if (_clickHandler) {
		classList.add("cmp-label--event-click");

		elementProps.tabIndex = 0;
		elementProps.onClick = _clickHandler;
	}

	return (
		<div
			{...elementProps}
			className={classList.toClassName()}
		>
			<Label
				text={textPropsModelNode ? { ...props.text, propsModelNode: textPropsModelNode } : props.text}
				icon={props.icon}
				flow={props.flow as LABEL_FLOW_string}
				componentPath={[ ...componentInstance.safePath, "label" ]}
				componentMode={componentInstance.componentMode}
			/>
		</div>
	);
};

export const HAEComponentLabel: THAEComponentDefinition<typeof HAEComponentLabel_Definition> = {
	...HAEComponentLabel_Definition,
	reactComponent: HAEComponentLabel_React
};
