/*
 * listHelpers HAE
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Checks if any value in array includes searched value, case insensitive.
 *
 * Example:
 * someIncludesCaseInsensitive('bo', ['Alice', 'Bob', 'Clara']) // true
 * someIncludesCaseInsensitive('is', ['Alice', 'Bob', 'Clara']) // false
 *
 * @param searchValue
 * @param values
 */
export function someIncludesCaseInsensitive(searchValue: string, values: string[]): boolean {
	return values.some((val) => val && val.toLowerCase().includes(searchValue.toLowerCase()));
}
