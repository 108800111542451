/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { TGenericFunctionDefinition } from "@hexio_io/hae-lib-blueprint";
import { IAppClient, ITranslationManager } from "@hexio_io/hae-lib-core";
import { registerFunctions } from ".";

export const registerExtension = (app: IAppClient): void => {
	
	registerFunctions(
		app.get("functionRegistry"),
		app.get("translationManager")
	);

};

export const registerStandalone = (
	registry: ItemRegistry<TGenericFunctionDefinition>,
	translationManager: ITranslationManager
): void => {

	registerFunctions(registry, translationManager);

};