/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, SCHEMA_CONST_ANY_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";
import { BlueprintBaseSharedProps } from ".";
import { CONST } from "../constants";
import { termsEditor } from "../terms";
import { DOC_TYPES } from "./DocTypes";

/**
 * Manifest Constants Types
 */
export enum MANIFEST_CONSTANTS_TYPES {
	constant = "constant",
	envvar = "envvar"
}

/** Authentication types. */
export enum MANIFEST_AUTH_TYPES {
	ADAPPTIO = "adapptio",
	CUSTOM = "custom"
}

export enum MANIFEST_FAVICON_TYPES {
	CUSTOM = "custom"
}

const blueprintsPathSchema = BP.Const.String({
	tags: ["Deprecated", "Hidden"],
	label: termsEditor.manifest.spec.blueprintsPath.label,
	description: termsEditor.manifest.spec.blueprintsPath.description
});

const applicationSchema = BP.Object({
	tags: ["Deprecated", "Hidden"],
	label: termsEditor.manifest.spec.application.label,
	description: termsEditor.manifest.spec.application.description,
	props: {
		name: BP.Prop(
			BP.Const.String({
				label: termsEditor.manifest.spec.application.name.label,
				description: termsEditor.manifest.spec.application.name.description,
				constraints: {
					required: true
				}
			})
		)
	}
});

const ssrSchema = BP.Object({
	label: termsEditor.manifest.spec.ssr.label,
	description: termsEditor.manifest.spec.ssr.description,
	tags: ["System"],
	props: {
		enabled: BP.Prop(
			BP.Boolean({
				label: termsEditor.manifest.spec.ssr.enabled.label,
				description: termsEditor.manifest.spec.ssr.enabled.description,
				default: true
			})
		)
	}
});

const sessionSchema = BP.Object({
	label: termsEditor.manifest.spec.session.label,
	description: termsEditor.manifest.spec.session.description,
	tags: ["System"],
	props: {
		expiration: BP.Prop(
			BP.Const.Object({
				label: termsEditor.manifest.spec.session.expiration.label,
				description: termsEditor.manifest.spec.session.expiration.description,
				props: {
					cookie: BP.Prop(
						BP.Const.Integer({
							label: termsEditor.manifest.spec.session.expiration.cookie.label,
							description:
								termsEditor.manifest.spec.session.expiration.cookie.description,
							default: CONST.SESSIONS.EXPIRATION.COOKIE,
							constraints: {
								min: 1000
							}
						})
					),
					storage: BP.Prop(
						BP.Const.Integer({
							label: termsEditor.manifest.spec.session.expiration.storage.label,
							description:
								termsEditor.manifest.spec.session.expiration.storage.description,
							default: CONST.SESSIONS.EXPIRATION.STORAGE,
							constraints: {
								min: 1000
							}
						})
					),
					lastLogin: BP.Prop(
						BP.Const.Integer({
							label: termsEditor.manifest.spec.session.expiration.lastLogin.label,
							description:
								termsEditor.manifest.spec.session.expiration.lastLogin
									.description,
							default: CONST.SESSIONS.EXPIRATION.LAST_LOGIN,
							constraints: {
								min: 1000
							}
						})
					)
				}
			})
		),
		storageType: BP.Prop(
			BP.NamedConfig({
				hidden: true,
				tags: ["Deprecated"],
				label: termsEditor.manifest.spec.session.storageType.label,
				description: termsEditor.manifest.spec.session.storageType.description,
				resolverName: "sessionStorageOptions"
			})
		)
	}
});

const authenticationSchema = BP.Const.Object({
	label: termsEditor.manifest.spec.authentication.label,
	description: termsEditor.manifest.spec.authentication.description,
	tags: ["System"],
	props: {
		authType: BP.Prop(
			BP.OneOf({
				label: termsEditor.manifest.spec.authentication.authType.label,
				description: termsEditor.manifest.spec.authentication.authType.description,
				defaultType: MANIFEST_AUTH_TYPES.ADAPPTIO,
				types: {
					[MANIFEST_AUTH_TYPES.ADAPPTIO]: {
						label: termsEditor.manifest.spec.authentication.authType.adapptio.label,
						description:
							termsEditor.manifest.spec.authentication.authType.adapptio
								.description,
						value: BP.Const.Object({ props: {} })
					},
					[MANIFEST_AUTH_TYPES.CUSTOM]: {
						label: termsEditor.manifest.spec.authentication.authType.custom.label,
						description:
							termsEditor.manifest.spec.authentication.authType.custom.description,
						value: BP.Const.Object({
							props: {
								devAutoLogin: BP.Prop(
									BP.Object({
										label: termsEditor.manifest.spec.authentication.authType
											.custom.devAutoLogin.label,
										description:
											termsEditor.manifest.spec.authentication.authType
												.custom.devAutoLogin.description,
										props: {
											action: BP.Prop(
												BP.ActionRef({
													label: termsEditor.manifest.spec
														.authentication.authType.custom
														.devAutoLogin.action.label,
													description:
														termsEditor.manifest.spec.authentication
															.authType.custom.devAutoLogin.action
															.description
												})
											)
										}
									})
								),
								getAccount: BP.Prop(
									BP.Object({
										label: termsEditor.manifest.spec.authentication.authType
											.custom.getAccount.label,
										description:
											termsEditor.manifest.spec.authentication.authType
												.custom.getAccount.description,
										props: {
											action: BP.Prop(
												BP.ActionRef({
													label: termsEditor.manifest.spec
														.authentication.authType.custom.getAccount
														.action.label,
													description:
														termsEditor.manifest.spec.authentication
															.authType.custom.getAccount.action
															.description,
													constraints: {
														required: true
													}
												})
											),
											cache: BP.Prop(
												BP.OptGroup({
													label: termsEditor.manifest.spec
														.authentication.authType.custom.getAccount
														.cache.label,
													description:
														termsEditor.manifest.spec.authentication
															.authType.custom.getAccount.cache
															.description,
													value: BP.Object({
														props: {
															expiresInMilliseconds: BP.Prop(
																BP.Integer({
																	label: termsEditor.manifest
																		.spec.authentication
																		.authType.custom
																		.getAccount.cache
																		.expiresInMilliseconds
																		.label,
																	description:
																		termsEditor.manifest.spec
																			.authentication
																			.authType.custom
																			.getAccount.cache
																			.expiresInMilliseconds
																			.description,
																	default:
																		CONST.ACCOUNT.CACHE
																			.EXPIRES_IN_MILLISECONDS
																})
															),
															maxReadCount: BP.Prop(
																BP.Integer({
																	label: termsEditor.manifest
																		.spec.authentication
																		.authType.custom
																		.getAccount.cache
																		.maxReadCount.label,
																	description:
																		termsEditor.manifest.spec
																			.authentication
																			.authType.custom
																			.getAccount.cache
																			.maxReadCount
																			.description,
																	default:
																		CONST.ACCOUNT.CACHE
																			.MAX_READ_COUNT
																})
															)
														}
													})
												})
											)
										}
									})
								)
							}
						})
					}
				}
			})
		),

		providers: BP.Prop(
			BP.Const.Array({
				hidden: true,
				label: termsEditor.manifest.spec.authentication.providers.label,
				description: termsEditor.manifest.spec.authentication.providers.description,
				tags: ["Deprecated"],
				items: BP.Object({
					props: {
						name: BP.Prop(BP.String({})),
						opts: BP.Prop(
							BP.Any({
								defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.MAP
							})
						)
					}
				}),
				default: [
					{
						name: "adapptioOAuth",
						opts: {}
					}
				]
			})
		),

		redirect: BP.Prop(
			BP.Const.Object({
				hidden: true,
				label: termsEditor.manifest.spec.authentication.opts.redirect.label,
				description: termsEditor.manifest.spec.authentication.opts.redirect.description,
				props: {
					logoutUrl: BP.Prop(
						BP.Const.String({
							label: termsEditor.manifest.spec.authentication.opts.redirect
								.logoutUrl.label,
							description:
								termsEditor.manifest.spec.authentication.opts.redirect.logoutUrl
									.description,
							default: "/"
						})
					),
					loginFailedUrl: BP.Prop(
						BP.Const.String({
							label: termsEditor.manifest.spec.authentication.opts.redirect
								.loginFailedUrl.label,
							description:
								termsEditor.manifest.spec.authentication.opts.redirect
									.loginFailedUrl.description,
							default: "/"
						})
					),
					loginSucceedUrl: BP.Prop(
						BP.Const.String({
							label: termsEditor.manifest.spec.authentication.opts.redirect
								.loginSucceedUrl.label,
							description:
								termsEditor.manifest.spec.authentication.opts.redirect
									.loginSucceedUrl.description,
							default: "/"
						})
					)
				}
			})
		)
	}
});

const constantsSchema = BP.Const.Map({
	label: termsEditor.manifest.spec.constants.label,
	description: termsEditor.manifest.spec.constants.description,
	tags: ["Constants"],
	value: BP.OneOf({
		defaultType: MANIFEST_CONSTANTS_TYPES.constant,
		types: {
			[MANIFEST_CONSTANTS_TYPES.constant]: {
				label: termsEditor.manifest.spec.constants.types.constant.label,
				description: termsEditor.manifest.spec.constants.types.constant.description,
				value: BP.Const.Object({
					props: {
						value: BP.Prop(
							BP.Const.Any({
								label: termsEditor.manifest.spec.constants.types.constant.value
									.label,
								description:
									termsEditor.manifest.spec.constants.types.constant.value
										.description,
								defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
								allowedTypes: {
									[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
									[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true,
									[SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN]: true
								}
							})
						),
						public: BP.Prop(
							BP.Const.Boolean({
								label: termsEditor.manifest.spec.constants.types.constant.public
									.label,
								description:
									termsEditor.manifest.spec.constants.types.constant.public
										.description,
								constraints: {
									required: true
								},
								default: false
							})
						)
					}
				})
			},
			[MANIFEST_CONSTANTS_TYPES.envvar]: {
				label: termsEditor.manifest.spec.constants.types.envvar.label,
				description: termsEditor.manifest.spec.constants.types.envvar.description,
				value: BP.Const.Object({
					props: {
						name: BP.Prop(
							BP.Const.String({
								label: termsEditor.manifest.spec.constants.types.envvar.value.name
									.label,
								description:
									termsEditor.manifest.spec.constants.types.envvar.value.name
										.description,
								constraints: {
									required: true
								}
							})
						),
						type: BP.Prop(
							BP.Const.Enum({
								label: termsEditor.manifest.spec.constants.types.envvar.value.type
									.label,
								description:
									termsEditor.manifest.spec.constants.types.envvar.value.type
										.description,
								constraints: {
									required: true
								},
								value: BP.Const.String({
									default: "string",
									label: termsEditor.manifest.spec.constants.types.envvar.value
										.type.value.label,
									description:
										termsEditor.manifest.spec.constants.types.envvar.value
											.type.value.description
								}),
								options: [
									{ value: "string" },
									{ value: "number" },
									{ value: "boolean" }
								]
							})
						),
						default: BP.Prop(
							BP.Const.Any({
								label: termsEditor.manifest.spec.constants.types.envvar.default
									.label,
								description:
									termsEditor.manifest.spec.constants.types.envvar.default
										.description,
								defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
								allowedTypes: {
									[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
									[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true,
									[SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN]: true
								}
							})
						),
						public: BP.Prop(
							BP.Const.Boolean({
								label: termsEditor.manifest.spec.constants.types.envvar.public
									.label,
								description:
									termsEditor.manifest.spec.constants.types.envvar.public
										.description,
								constraints: {
									required: true
								},
								default: false
							})
						)
					}
				})
			}
		}
	})
});

const telemetrySchema = BP.Object({
	label: termsEditor.manifest.spec.telemetry.label,
	description: termsEditor.manifest.spec.telemetry.description,
	tags: ["Services"],
	props: {
		googleAnalytics: BP.Prop(
			BP.OptGroup({
				label: termsEditor.manifest.spec.telemetry.googleAnalytics.label,
				description: termsEditor.manifest.spec.telemetry.googleAnalytics.description,
				value: BP.Object({
					props: {
						trackingId: BP.Prop(
							BP.String({
								label: termsEditor.manifest.spec.telemetry.googleAnalytics
									.trackingId.label,
								description:
									termsEditor.manifest.spec.telemetry.googleAnalytics.trackingId
										.description,
								fallbackValue: null
							})
						)
					}
				})
			})
		),
		hotJar: BP.Prop(
			BP.OptGroup({
				label: termsEditor.manifest.spec.telemetry.hotJar.label,
				description: termsEditor.manifest.spec.telemetry.hotJar.description,
				value: BP.Object({
					props: {
						siteId: BP.Prop(
							BP.String({
								label: termsEditor.manifest.spec.telemetry.hotJar.siteId.label,
								description:
									termsEditor.manifest.spec.telemetry.hotJar.siteId.description,
								fallbackValue: null
							})
						)
					}
				})
			})
		)
	}
});

const externalServicesSchema = BP.Object({
	label: termsEditor.manifest.spec.externalServices.label,
	description: termsEditor.manifest.spec.externalServices.description,
	tags: ["Services"],
	props: {
		intercom: BP.Prop(
			BP.OptGroup({
				label: termsEditor.manifest.spec.externalServices.intercom.label,
				description: termsEditor.manifest.spec.externalServices.intercom.description,
				value: BP.Object({
					props: {
						appId: BP.Prop(
							BP.String({
								label: termsEditor.manifest.spec.externalServices.intercom.appId
									.label,
								description:
									termsEditor.manifest.spec.externalServices.intercom.appId
										.description
							})
						)
					}
				})
			})
		)
	}
});

const faviconSchema = BP.OneOf({
	label: termsEditor.manifest.spec.favicon.label,
	description: termsEditor.manifest.spec.favicon.description,
	defaultType: MANIFEST_FAVICON_TYPES.CUSTOM,
	tags: ["Visual"],
	types: {
		[MANIFEST_FAVICON_TYPES.CUSTOM]: {
			label: termsEditor.manifest.spec.favicon.type.custom.label,
			description: termsEditor.manifest.spec.favicon.type.custom.description,
			value: BP.Object({
				label: termsEditor.manifest.spec.favicon.label,
				description: termsEditor.manifest.spec.favicon.description,
				props: {
					icon: BP.Prop(
						BP.String({
							label: termsEditor.manifest.spec.favicon.icon.label,
							description: termsEditor.manifest.spec.favicon.icon.description
						})
					),
					pngIcons: BP.Prop(
						BP.Array({
							label: termsEditor.manifest.spec.favicon.pngIcons.label,
							description: termsEditor.manifest.spec.favicon.pngIcons.description,
							items: BP.Object({
								props: {
									path: BP.Prop(BP.String({
										label: termsEditor.manifest.spec.favicon.pngIcons.path.label,
										description: termsEditor.manifest.spec.favicon.pngIcons.path.description
									})),
									size: BP.Prop(BP.String({
										label: termsEditor.manifest.spec.favicon.pngIcons.size.label,
										description: termsEditor.manifest.spec.favicon.pngIcons.size.description
									}))
								}
							})
						})
					),
					appleTouchIcons: BP.Prop(
						BP.Array({
							label: termsEditor.manifest.spec.favicon.appleTouchIcons.label,
							description: termsEditor.manifest.spec.favicon.appleTouchIcons.description,
							items: BP.Object({
								props: {
									path: BP.Prop(BP.String({
										label: termsEditor.manifest.spec.favicon.appleTouchIcons.path.label,
										description: termsEditor.manifest.spec.favicon.appleTouchIcons.path.description
									})),
									size: BP.Prop(BP.String({
										label: termsEditor.manifest.spec.favicon.appleTouchIcons.size.label,
										description: termsEditor.manifest.spec.favicon.appleTouchIcons.size.description
									}))
								}
							})
						})
					)
				}
			})
		}
	}
});

const themeRefSchema = BP.ThemeRef({
	label: termsEditor.manifest.spec.theme.label,
	description: termsEditor.manifest.spec.theme.description
});

const MANIFEST_GROUPS = {
	SYSTEM: {
		id: "system",
		order: 0,
		label: termsEditor.manifest.categories.system.label,
		description: termsEditor.manifest.categories.system.description
	},
	VISUAL: {
		id: "visual",
		order: 1,
		label: termsEditor.manifest.categories.visual.label,
		description: termsEditor.manifest.categories.visual.description
	},
	CONSTANTS: {
		id: "constants",
		order: 2,
		label: termsEditor.manifest.categories.constants.label,
		description: termsEditor.manifest.categories.constants.description
	},
	SERVICES: {
		id: "services",
		order: 3,
		label: termsEditor.manifest.categories.services.label,
		description: termsEditor.manifest.categories.services.description
	}
}

const templateSchema = BP.Object({
	label: termsEditor.manifest.template.label,
	description: termsEditor.manifest.template.description,
	props: {
		header: BP.Prop(BP.String({
			label: termsEditor.manifest.template.header.label,
			description: termsEditor.manifest.template.header.description,
			allowInterpolation: false,
			allowTranslate: false,
			editorOptions: {
				controlType: "codeHTML"
			}
		})),
		footer: BP.Prop(BP.String({
			label: termsEditor.manifest.template.footer.label,
			description: termsEditor.manifest.template.footer.description,
			allowInterpolation: false,
			allowTranslate: false,
			editorOptions: {
				controlType: "codeHTML"
			}
		}))
	}
});

/**
 * Manifest blueprint "spec" property schema
 */
export const BlueprintManifestSpec = BP.Const.Object({
	props: {
		blueprintsPath: BP.Prop(blueprintsPathSchema),
		application: BP.Prop(applicationSchema),
		defaultTheme: BP.Prop(themeRefSchema, 0, MANIFEST_GROUPS.VISUAL),
		ssr: BP.Prop(ssrSchema, 0, MANIFEST_GROUPS.SYSTEM),
		session: BP.Prop(sessionSchema, 0, MANIFEST_GROUPS.SYSTEM),
		authentication: BP.Prop(authenticationSchema, 0, MANIFEST_GROUPS.SYSTEM),
		constants: BP.Prop(constantsSchema, 0, MANIFEST_GROUPS.CONSTANTS),
		telemetry: BP.Prop(telemetrySchema, 0, MANIFEST_GROUPS.SERVICES),
		externalServices: BP.Prop(externalServicesSchema, 1, MANIFEST_GROUPS.SERVICES),
		favicon: BP.Prop(faviconSchema, 1, MANIFEST_GROUPS.VISUAL),
		template: BP.Prop(templateSchema, 2, MANIFEST_GROUPS.SYSTEM),
	}
});

export type TBlueprintManifestSpecSchema = typeof BlueprintManifestSpec;

/**
 * Manifest Blueprint Schema
 */
export const BlueprintManifest = BP.Const.Object({
	props: {
		doctype: BP.Prop(
			BP.Const.String({
				constraints: {
					const: DOC_TYPES.MANIFEST_V1,
					required: true
				}
			})
		),
		...BlueprintBaseSharedProps,
		spec: BP.Prop(
			BP.Const.Map({
				value: BlueprintManifestSpec,
				provideKeyToScopeMetaAs: "secretsEnv"
			})
		)
	}
});

export type TBlueprintManifestSchema = typeof BlueprintManifest;
