/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import { MouseOrTouchEvent } from "../Types/THelperTypes";

export function getMouseOrTouchEventData(event: MouseOrTouchEvent): MouseEvent | Touch {
	return (event as TouchEvent).changedTouches ? (event as TouchEvent).changedTouches[0] : (event as MouseEvent);
}
