/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IExecutionOptions } from "../managers/IExecutionOptions";
import { IService } from "../services";
import { IExecutionContext } from "../WebServer";
import { IActionParams } from "./IActionParams";
import { TActionResult } from "./IActionResult";

export enum FLOW_NODE_OUTPUT_NAMES {
	ON_START = "onStart",
	ON_SUCCESS = "onSuccess",
	ON_ERROR = "onError",
	ON_TRUE = "onTrue",
	ON_FALSE = "onFalse",
	ON_REQUEST = "onRequest",
	ON_ITEM = "onItem",
}

export interface IActionManager extends IService {

	invoke(
		actionName: string,
		params: IActionParams,
		context: IExecutionContext,
		config: IExecutionOptions,
		appId: string,
		appEnvId: string,
		appName: string
	): Promise<TActionResult>;

}