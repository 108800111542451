/**
 * Datetime Field Type enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum DATETIME_FIELD_TYPE {
	DATETIME = "datetime",
	DATETIME_FULL = "datetime-full",
	DATE = "date",
	TIME = "time",
	TIME_FULL = "time-full",
	//MONTH = "month"
}

export type DATETIME_FIELD_TYPE_string = keyof typeof DATETIME_FIELD_TYPE;

export const DATETIME_FIELD_TYPE_default: DATETIME_FIELD_TYPE_string = "DATETIME";
