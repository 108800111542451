/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TTypeDesc } from "@hexio_io/hae-lib-blueprint";
import { createEventEmitter, emitEvent, removeAllEventListeners, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IActionDebugData } from "./IActionDebugData";
import { ACTION_DELEGATE_STATE, IActionDelegate } from "./IActionDelegate";
import { IActionDelegateSerializedState } from "./IActionDelegateSerializedState";
import { IActionParams } from "./IActionParams";
import { ACTION_ERROR_REASON, ACTION_RESULT_TYPE, IActionResultError, IActionResultErrorObject, TActionResult } from "./IActionResult";

/**
 * Action Delegate function to invoke action
 */
export type TActionDelegateInvokeFn = () => Promise<TActionResult>;

/**
 * Client-side Action Delegate
 */
export class ActionDelegate implements IActionDelegate {

	/* Internal state properties */
	private actionId: string;
	private params: IActionParams;
	private state: ACTION_DELEGATE_STATE = ACTION_DELEGATE_STATE.NOT_LOADED;
	private loaded = false;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private data?: any = null;
	private typeDescriptor?: TTypeDesc = null;
	private debugData?: IActionDebugData = null;
	private lastError?: IActionResultErrorObject = null;
	private lastInvoke?: number = null;
	private isReadyAfterInjection = true;

	/** Event emitted when new data are received */
	public onUpdate: TSimpleEventEmitter<void>;

	/** Invoke function */
	private invokeFn: TActionDelegateInvokeFn;

	/** Loading promise - is passed when loading in progress */
	private loadingPromise: Promise<void> = null;

	/** List of registered reload intervals */
	private reloadIntervals: Array<number> = [];

	/** Reload interval with minimum value */
	private minReloadInterval: number = null;

	/** If delegate was disposed */
	private wasDisposed = false;

	/**
	 * Delegate constructor
	 *
	 * @param actionId Action ID
	 * @param params Action params
	 */
	public constructor(
		actionId: string,
		params: IActionParams,
		invokeFn: TActionDelegateInvokeFn,
		initialState: IActionDelegateSerializedState
	) {

		this.actionId = actionId;
		this.params = params;
		this.invokeFn = invokeFn;

		this.onUpdate = createEventEmitter();

		if (initialState) {
			this.state = initialState.state;
			this.loaded = initialState.wasLoaded;
			this.data = initialState.data;
			this.typeDescriptor = initialState.typeDescriptor;
			this.debugData = initialState.debugData;
			this.lastError = initialState.lastError;
			this.lastInvoke = initialState.lastInvoke || null;
			this.isReadyAfterInjection = false;
		}

	}

	/**
	 * Returns action ID
	 */
	public getActionId(): string {
		return this.actionId;
	}

	/**
	 * Returns action params
	 */
	public getParams(): IActionParams {
		return this.params;
	}

	/**
	 * Returns delegate action state
	 */
	public getState(): ACTION_DELEGATE_STATE {
		return this.state;
	}

	/**
	 * Returns if data was already loaded at least once
	 */
	public wasLoaded(): boolean {
		return this.loaded;
	}

	/**
	 * Returns loaded data
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public getData(): any {
		return this.data;
	}

	/**
	 * Returns action result type descriptor (if was provided in response)
	 */
	public getTypeDescriptor(): TTypeDesc {
		return this.typeDescriptor;
	}

	/**
	 * Returns action result debug data (if were provided in response)
	 */
	public getDebugData(): IActionDebugData {
		return this.debugData;
	}

	/**
	 * Returns last error (present even when not in error state anymore)
	 */
	public getLastError(): IActionResultErrorObject {
		return this.lastError;
	}

	/**
	 * Returns when the action was invoked last time
	 */
	public getLastInvocationTimestamp(): number {
		return this.lastInvoke;
	}

	/**
	 * Invokes an action (always resolves, never rejects, potential error is stored in a delegate itself)
	 * 
	 * @param reload If true the data will be reloaded even when cached, defaults to false
	 */
	public async invoke(reload = false): Promise<void> {

		// Check if disposed
		if (this.wasDisposed) {
			throw new Error("Action delegate was disposed.");
		}
		
		// If already loading, return existing promise
		if (this.loadingPromise) {
			return this.loadingPromise;
		}

		// Return when was injected to prevent conflicts with SSR version when something has failed
		if (!this.isReadyAfterInjection) {
			return;
		}

		// If no reload required and data was already loaded
		if (!reload && this.loaded) {
			return;
		}

		// Invoke action
		this.lastInvoke = Date.now();
		this.state = ACTION_DELEGATE_STATE.LOADING;

		emitEvent(this.onUpdate);

		return this.loadingPromise = new Promise((resolve) => {

			this.invokeFn().then((result) => {

				if (result.status === ACTION_RESULT_TYPE.SUCCESS) {
					this.state = ACTION_DELEGATE_STATE.LOADED;
					this.loaded = true;
					this.data = result.data;
					this.typeDescriptor = result.typeDescriptor || null;
					this.debugData = result.debug || null;
				} else {
					this.state = ACTION_DELEGATE_STATE.ERROR;
					this.data = null;
					this.typeDescriptor = null;
					this.debugData = result.debug || null;
					this.lastError = (result as IActionResultError).error;
				}

				emitEvent(this.onUpdate);
				resolve();

				// Clear loading promise = unlock loading
				this.loadingPromise = null;

			}).catch((err) => {

				this.state = ACTION_DELEGATE_STATE.ERROR;
				this.data = null;
				this.typeDescriptor = null;
				this.debugData = null;
				this.lastError = {
					reason: ACTION_ERROR_REASON.REQUEST_ERROR,
					message: String(err),
					errorName: "0",
					customData: null,
					details: err.detail ? [err.detail] : [],
					date: err.date ? err.date : new Date(),
					reqId: err.reqId ? err.reqId : null,
					traceId: null
				};

				emitEvent(this.onUpdate);
				resolve();

				// Clear loading promise = unlock loading
				this.loadingPromise = null;

			});

		});

	}

	/**
	 * Adds interval for automatic refresh
	 * 
	 * @param reloadIntervalMs Reload interval in milliseconds
	 */
	public addReloadInterval(reloadIntervalMs: number): void {

		// Check if disposed
		if (this.wasDisposed) {
			throw new Error("Action delegate was disposed.");
		}

		this.reloadIntervals.push(reloadIntervalMs);

		if (this.minReloadInterval === null || reloadIntervalMs < this.minReloadInterval) {
			this.minReloadInterval = reloadIntervalMs;
		}

	}

	/**
	 * Removes interval for automatic refresh
	 * 
	 * @param reloadIntervalMs Reload interval in milliseconds
	 */
	public removeReloadInterval(reloadIntervalMs: number): void {

		// Check if disposed
		if (this.wasDisposed) {
			throw new Error("Action delegate was disposed.");
		}

		const i = this.reloadIntervals.indexOf(reloadIntervalMs);

		if (i >= 0) {
			this.reloadIntervals.splice(i, 1);
			this.minReloadInterval = null;

			for (let i = 0; i < this.reloadIntervals.length; i++) {
				if (this.minReloadInterval === null || this.reloadIntervals[i] < this.minReloadInterval) {
					this.minReloadInterval = this.reloadIntervals[i];
				}
			}
		}

	}

	/**
	 * Checks if a deleate should be reloaded and reload it if neccessary
	 */
	public checkReload(): void {

		if (this.wasDisposed) {
			return;
		}
		
		const now = Date.now();
		const shouldReload = this.lastInvoke && this.minReloadInterval && this.lastInvoke < now - this.minReloadInterval;

		if (shouldReload && this.state !== ACTION_DELEGATE_STATE.LOADING) {
			this.invoke(true).catch((err) => {
				console.error("[ActionDelegate] Failed to reload action delegate:", err, this);
			});
		}

	}

	/**
	 * Returns if a delegate is being used (eg. has pending loading promise, bound listeners, etc...)
	 */
	public isUsed(): boolean {

		if (this.wasDisposed) {
			return false;
		}

		if (this.state === ACTION_DELEGATE_STATE.LOADING) {
			return true;
		}

		return this.onUpdate.length > 0 ? true : false;

	}

	/**
	 * Sets delegate as ready after injection
	 * (means the action can be loaded normally)
	 */
	public setReadyAfterInjection(): void {
		this.isReadyAfterInjection = true;

		if (this.state === ACTION_DELEGATE_STATE.LOADING) {
			this.invoke(true);
		}
	}

	/**
	 * Removes all listeners and sets delegate as disposed
	 */
	public dispose(): void {

		this.wasDisposed = true;

		this.state = ACTION_DELEGATE_STATE.NOT_LOADED;
		this.loaded = false;
		this.data = undefined;
		this.typeDescriptor = undefined;
		this.debugData = undefined;
		this.lastError = undefined;
		this.lastInvoke = undefined;
		
		removeAllEventListeners(this.onUpdate);

	}

}