/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE, IBlueprintIDTMap, IBlueprintIDTMapElement, TBlueprintIDTNodePath } from "../../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../../Schema/IBlueprintSchema";
import { IModelNode, IModelNodeInfo } from "../../Schema/IModelNode";
import {
	applyRuntimeValidators,
	assignParentToObjAttributes,
	cloneModelNode,
	compileRuntimeValidators,
	createEmptySchema,
	createModelNode,
	destroyModelNode
} from "../../Schema/SchemaHelpers";
import { applyCodeArg, escapeString, inlineValue } from "../../Context/CompileUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { DesignContext } from "../../Context/DesignContext";
import { IValidatorObjectOpts, ValidatorObject } from "../../validators/ValidatorObject";
import { IBlueprintSchemaValidationError, IBlueprintSchemaValidatorHandler } from "../../Validator/IBlueprintSchemaValidator";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { TypeDescObject } from "../../Shared/ITypeDescriptor";
import { CMPL_ITEM_KIND, ICompletionItem } from "../../Shared/ICompletionItem";
import {
	extractAndValidateIDTMapProperties,
	IIDTValidateRule,
	provideIDTMapRootCompletions,
	validateIDTNode
} from "../../Context/ParseUtil";

/**
 * Object property group options
 */
export interface ISchemaConstObjectOptsGroup {
	id: string;
	label: string;
	description?: string;
	order?: number;
	defaultExpanded?: boolean;
}

interface ISchemaConstObjectOptsPropGroupInternal {
	group: ISchemaConstObjectOptsGroup;
	props: string[];
}

/**
 * Object schema property
 */
export interface ISchemaConstObjectOptsProp<
	TPropSchema extends TGenericBlueprintSchema = TGenericBlueprintSchema
> {
	/** Property schema */
	schema: TPropSchema;

	/** Order in editor UI */
	order?: number;

	/** Editor UI group */
	group?: ISchemaConstObjectOptsGroup;
}

/**
 * Dict of object properties
 */
export type TSchemaConstObjectProps = { [K: string]: ISchemaConstObjectOptsProp };

/**
 * Helper type which resolves to a map of SchemaConstObject properties and their corresponding schemas
 */
export type TSchemaConstObjectPropsSchema<T extends TSchemaConstObjectProps> = {
	[K in keyof T]: T[K]["schema"];
};

/**
 * Helper type which resolves to a map of SchemaConstObject properties and their corresponding TModelNode
 */
export type TSchemaConstObjectPropsModel<T extends TSchemaConstObjectProps> = {
	[K in keyof T]: TGetBlueprintSchemaModel<T[K]["schema"]>;
};

/**
 * Helper type which resolves to a map of SchemaConstObject properties and their corresponding TSpec
 */
export type TSchemaConstObjectPropsSpec<T extends TSchemaConstObjectProps> = {
	[K in keyof T]: TGetBlueprintSchemaSpec<T[K]["schema"]>;
};

/**
 * Helper type which resolves to a map of SchemaConstObject properties and their corresponding TDefault
 */
export type TSchemaConstObjectPropsDefault<T extends TSchemaConstObjectProps> = {
	[K in keyof T]: TGetBlueprintSchemaDefault<T[K]["schema"]>;
};

/**
 * Schema model
 */
export interface ISchemaConstObjectModel<TProps extends TSchemaConstObjectProps>
	extends IModelNode<ISchemaConstObject<TProps>> {
	props: TSchemaConstObjectPropsModel<TProps>;
}

/**
 * Schema options
 */
export interface ISchemaConstObjectOpts<TProps extends TSchemaConstObjectProps> extends IBlueprintSchemaOpts {
	/** Object properties */
	props: TProps;
	/** Default value for a new node */
	default?: TSchemaConstObjectPropsDefault<TProps>;
	/** Base validation constraints */
	constraints?: IValidatorObjectOpts;
	/** Custom validators */
	validators?: IBlueprintSchemaValidatorHandler<TSchemaConstObjectPropsSpec<TProps>>[];
	/** Fallback value to return when validation fails */
	fallbackValue?: TSchemaConstObjectPropsSpec<TProps>;
	/** If and how to display child nodes in editor outline */
	outlineOptions?: {
		displayChildren?: boolean;
		displayOnlyProps?: string[];
	};
	/** Function to return human friendly item label */
	getElementModelNodeInfo?: <TKey extends keyof TProps>(
		modelNode: TSchemaConstObjectPropsModel<TProps>[TKey],
		propName: TKey
	) => IModelNodeInfo;
}

/**
 * Schema type
 */
export interface ISchemaConstObject<TProps extends TSchemaConstObjectProps>
	extends IBlueprintSchema<
		ISchemaConstObjectOpts<TProps>,
		ISchemaConstObjectModel<TProps>,
		TSchemaConstObjectPropsSpec<TProps>,
		TSchemaConstObjectPropsDefault<TProps>
	> {
	getElementModelNodeInfo: (
		modelNode: ISchemaConstObjectModel<TProps>,
		propName: keyof TProps
	) => IModelNodeInfo;

	getPropGroups: () => ISchemaConstObjectOptsGroup[];

	getPropGroupNodes: (
		modelNode: ISchemaConstObjectModel<TProps>,
		groupId: string
	) => TGetBlueprintSchemaModel<TProps[keyof TProps]>[] | null;
}

export type TGetSchemaConstObjectProps<TConstObjectSchema> = TConstObjectSchema extends ISchemaConstObject<
	infer TProps
>
	? TProps
	: never;

/**
 * Creates object property definition
 *
 * @param schema Property schema
 * @param order Order in editor UI
 * @param groupName Group name in editor UI
 */
export function Prop<TPropSchema extends TGenericBlueprintSchema>(
	schema: TPropSchema,
	order?: number,
	group?: ISchemaConstObjectOptsGroup
): ISchemaConstObjectOptsProp<TPropSchema> {
	return {
		schema: schema,
		order: order,
		group: group || null
	};
}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaConstObject<TProps extends TSchemaConstObjectProps>(
	opts: ISchemaConstObjectOpts<TProps>
): ISchemaConstObject<TProps> {
	type TModel = TGetBlueprintSchemaModel<TProps[Extract<keyof TProps, string>]["schema"]>;
	type TSpec = TSchemaConstObjectPropsSpec<TProps>;

	const defaultValidator = ValidatorObject(opts.constraints || {});
	const validators: IBlueprintSchemaValidatorHandler<TSpec>[] = opts.validators || [];

	const errSeverity =
		opts.fallbackValue !== undefined ? DOC_ERROR_SEVERITY.WARNING : DOC_ERROR_SEVERITY.ERROR;

	const props = opts.props;
	const schema = createEmptySchema<ISchemaConstObject<TProps>>("constObject", opts);

	const keyPropRules = {} as { [K in keyof TProps]: IIDTValidateRule };

	// Assign property schemas default order and add to prop rules
	let _tmpOrder = 0;
	const propCompletions: ICompletionItem[] = [];

	const _createdPropGroups: Map<string, ISchemaConstObjectOptsPropGroupInternal> = new Map();

	for (const k in props) {
		keyPropRules[k] = {
			required: false,
			provideCompletion: props[k].schema.provideCompletion
		};

		if (props[k].order === undefined) {
			props[k].order = _tmpOrder;
		}

		const group = props[k].group;

		if (group) {
			if (!_createdPropGroups.has(group.id)) {
				if (group.order === undefined) {
					group.order = _tmpOrder;
				}

				_createdPropGroups.set(group.id, { group: group, props: [] });
			}

			const propGroup = _createdPropGroups.get(group.id);
			propGroup.props.push(k);
		}

		propCompletions.push({
			kind: CMPL_ITEM_KIND.Property,
			label: k,
			insertText: `${k}:`
		});

		_tmpOrder++;
	}

	const _propGroups = Array.from(_createdPropGroups.values()).sort((a, b) => a.group.order - b.group.order);
	_propGroups.forEach((group) => group.props.sort((a, b) => props[a].order - props[b].order));

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToObjAttributes(srcModel, srcModel.props);
	};

	const createModel = (
		dCtx: DesignContext,
		modelProps: TSchemaConstObjectPropsModel<TProps>,
		parent: TBlueprintSchemaParentNode,
		validationErrors: IBlueprintSchemaValidationError[]
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, validationErrors, {
			props: modelProps
		});

		return assignParentToChildrenOf(modelNode);
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		const validationErrors: IBlueprintSchemaValidationError[] = defaultValidator.validate(defaultValue);
		const defaultProps: TSchemaConstObjectPropsModel<TProps> = {} as TSchemaConstObjectPropsModel<TProps>;

		for (const k in props) {
			defaultProps[k] = props[k].schema.createDefault(
				dCtx,
				null,
				defaultValue instanceof Object ? defaultValue[k] : undefined
			) as TModel;
		}

		return createModel(dCtx, defaultProps, parent, validationErrors);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedProps: TSchemaConstObjectPropsModel<TProps> = {} as TSchemaConstObjectPropsModel<TProps>;

		for (const k in modelNode.props) {
			clonedProps[k] = props[k].schema.clone(dCtx, modelNode.props[k], null) as TModel;
		}

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			props: clonedProps
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		for (const k in modelNode.props) {
			props[k].schema.destroy(modelNode.props[k]);
		}

		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return schema.createDefault(dCtx, parent);
		}

		// Extract keys
		const { keys, keysValid } = extractAndValidateIDTMapProperties(dCtx, rootNode, keyPropRules);

		// Construct prop values
		const propValues: TSchemaConstObjectPropsModel<TProps> = {} as TSchemaConstObjectPropsModel<TProps>;

		for (const k in props) {
			if (keys[k as string] && keysValid[k as string]) {
				propValues[k] = props[k].schema.parse(dCtx, keys[k as string].value, null) as TModel;
			} else {
				propValues[k] = props[k].schema.createDefault(dCtx, null) as TModel;

				if (!propValues[k].initRequiredValid) {
					const _parseInfo = idtNode.parentKeyParseInfo
						? idtNode.parentKeyParseInfo
						: idtNode.parseInfo;

					if (_parseInfo) {
						dCtx.logParseError(_parseInfo.loc.uri, {
							range: _parseInfo.loc.range,
							severity: DOC_ERROR_SEVERITY.ERROR,
							name: DOC_ERROR_NAME.INVALID_VALUE,
							message: `Property '${k}' is required and must be specified.`,
							parsePath: idtNode.path
						});
					}
				}
			}
		}

		return createModel(dCtx, propValues, parent, []);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);
	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {
		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: Object.keys(props)
				.map((propName) => {
					const valueIdt = props[propName].schema.serialize(
						modelNode.props[propName],
						path.concat([ propName ])
					);

					if (!valueIdt) {
						return null;
					}

					return {
						type: BP_IDT_TYPE.MAP_ELEMENT,
						path: path.concat([ "[" + propName + "]" ]),
						key: {
							type: BP_IDT_TYPE.SCALAR,
							subType: BP_IDT_SCALAR_SUBTYPE.STRING,
							path: path.concat([ "{" + propName + "}" ]),
							value: propName
						},
						value: valueIdt
					} as IBlueprintIDTMapElement;
				})
				.filter((idtNode) => (idtNode ? true : false))
		} as IBlueprintIDTMap;
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		//const value = prevSpec && typeof prevSpec === "object" ? prevSpec : {};
		const value = {};

		for (const k in modelNode.props) {
			const propValue = props[k].schema.render(
				rCtx,
				modelNode.props[k],
				path.concat([ k ]),
				scope,
				typeof prevSpec === "object" && prevSpec !== null ? prevSpec[k as string] : undefined
			);

			if (propValue !== undefined) {
				value[k as string] = propValue;
			}
		}

		const isValid = applyRuntimeValidators<TSpec>(
			rCtx,
			path,
			modelNode.nodeId,
			validators,
			errSeverity,
			value as TSpec
		);

		if (isValid) {
			return value as TSpec;
		} else {
			return (opts.fallbackValue || {}) as TSpec;
		}
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// Pre-validate - no need to do this here because existing object is always an object

		let isScoped = false;
		const propsCode = [];

		for (const k in props) {
			const propCmp = props[k].schema.compileRender(cCtx, modelNode.props[k], path.concat([ k ]));

			if (propCmp) {
				isScoped = isScoped || propCmp.isScoped;
				const _k = escapeString(k);

				propsCode.push(
					`"${_k}":(${applyCodeArg(propCmp, `pv?pv["${_k}"]:undefined`, `pt.concat(["${_k}"])`)})`
				);
			}
		}

		const valueCode = `{${propsCode.join(",")}}`;

		const additionalValidators = compileRuntimeValidators(
			cCtx,
			path,
			modelNode.nodeId,
			validators,
			errSeverity
		);

		if (additionalValidators !== null) {
			return {
				isScoped: true,
				// eslint-disable-next-line max-len
				code: `(s,pv,pt)=>{const _v=${valueCode};const _iv=${additionalValidators}(_v,pt);return _iv?_v:${inlineValue(
					opts.fallbackValue || {}
				)}}`
			};
		} else {
			return {
				isScoped,
				code: isScoped ? `(s,pv,pt)=>(${valueCode})` : `${valueCode}`
			};
		}
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		// Validate base
		const selfValid = applyRuntimeValidators<TSpec>(
			rCtx,
			path,
			modelNodeId,
			[ defaultValidator ].concat(validators),
			errSeverity,
			value
		);
		let childValid = true;

		// Call child validators
		if (validateChildren && value instanceof Object) {
			for (const k in props) {
				childValid =
					childValid &&
					props[k].schema.validate(
						rCtx,
						path.concat([ k ]),
						modelNodeId,
						value[k],
						validateChildren
					);
			}
		}

		return selfValid && childValid;
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		const expressions = [];

		const selfValidator = compileRuntimeValidators(
			cCtx,
			path,
			modelNodeId,
			[ defaultValidator ].concat(validators),
			errSeverity
		);

		if (selfValidator !== null) {
			expressions.push(`_vd=(${selfValidator})(v,pt)&&_vd;`);
		}

		if (validateChildren) {
			const propExpressions = [];

			for (const k in props) {
				const propValidator = props[k].schema.compileValidate(
					cCtx,
					path.concat([ k ]),
					modelNodeId,
					validateChildren
				);
				const _k = escapeString(k);

				if (propValidator !== null) {
					propExpressions.push(`_vd=(${propValidator})(v["${_k}"],pt.concat(["${_k}"]))&&_vd;`);
				}
			}

			if (propExpressions.length > 0) {
				// eslint-disable-next-line max-len
				expressions.push(`if(v instanceof Object){${propExpressions.join("")}}`);
			}
		}

		if (expressions.length === 0) {
			return null;
		} else {
			return cCtx.addGlobalValue(`(v,pt)=>{let _vd=true;${expressions.join("")}return _vd}`);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaConstObject", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		const propsTypeDesc = {};
		for (const [ propName, propModel ] of Object.entries(props)) {
			propsTypeDesc[propName] = props[propName].schema.getTypeDescriptor();
		}

		return TypeDescObject({
			props: propsTypeDesc,
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.getChildNodes = (modelNode) => {
		const children = [];

		for (const k in modelNode.props) {
			children.push({
				key: "props." + k,
				node: modelNode.props[k]
			});
		}

		return children;
	};

	schema.getElementModelNodeInfo = (modelNode, propName) => {
		const item = modelNode.props[propName];

		if (!item) {
			throw new Error("Property name does not exists.");
		}

		if (opts.getElementModelNodeInfo) {
			return opts.getElementModelNodeInfo(item, propName);
		} else {
			return {
				icon: "mdi/arrow-right",
				label: propName as string
			};
		}
	};

	schema.getPropGroups = () => {
		return _propGroups.map((propGroup) => propGroup.group);
	};

	schema.getPropGroupNodes = (modelNode, groupId) => {
		const group = _createdPropGroups.get(groupId);

		if (!group) {
			return null;
		}

		const result = [];

		for (let i = 0; i < group.props.length; i++) {
			const propName = group.props[i];
			const propNode = modelNode.props[propName];

			if (!propNode) {
				continue;
			}

			result.push(propNode);
		}

		return result;
	};

	return schema;
}
