/**
 * Text schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstBoolean,
	ISchemaConstEnum,
	ISchemaConstObject,
	ISchemaConstObjectOptsProp,
	ISchemaConstString,
	ISchemaOptionalGroup,
	ISchemaStringWithConst,
	ISchemaStringWithConstOpts,
	ISchemaValue,
	ISchemaValueBooleanOpts,
	ISchemaValueEnumStringOpts,
	ISchemaValueObjectOpts,
	ISchemaValueStringOpts,
	TGetBlueprintSchemaModel,
	TSchemaConstObjectProps,
	TSchemaConstObjectPropsSpec,
} from "@hexio_io/hae-lib-blueprint";

import { FONT_SIZE_CLASS, FONT_SIZE_CLASS_default } from "../Enums/FONT_SIZE_CLASS";
import { TEXT_ALIGN_CLASS } from "../Enums/TEXT_ALIGN_CLASS";
import { TEXT_OVERFLOW, TEXT_OVERFLOW_default, TEXT_OVERFLOW_opts } from "../Enums/TEXT_OVERFLOW";
import { ENUM_DEFAULT_VALUE, getValuesFromStringEnum } from "../Functions/enumHelpers";
import { fontSizePattern } from "../RegExp/fontSizePattern";
import { termsEditor } from "../terms";
import { ForegroundColorSchema } from "./Color";
import { propGroups } from "./propGroups";

/**
 * Text props
 */
export const TextProps = {

	value: BP.Prop(TextValueSchema(), 0, propGroups.__primary__),

	align: BP.Prop(TextAlignSchema(), 10),

	style: BP.Prop(TextStyleSchema(), 20),

	fontSize: BP.Prop(TextFontSizeSchema(), 30),

	foregroundColor: BP.Prop(ForegroundColorSchema({
		alias: "foregroundColor_text",
		default: "TEXT"
	}), 40),

	overflow: BP.Prop(TextOverflowSchema(), 50),

	markdown: BP.Prop(TextMarkdownSchema(), 60),

	html: BP.Prop(TextHtmlSchema(), 70)

};

/**
 * Text props type
 */
export type TTextProps = Partial<TSchemaConstObjectPropsSpec<typeof TextProps>>;

/**
 * Text props model node type
 */
export type TTextPropsModelNode = TGetBlueprintSchemaModel<ISchemaConstObject<typeof TextProps>>;

/**
 * Text schema
 *
 * @param opts Options
 */
export function TextSchema(
	opts: Partial<ISchemaValueObjectOpts<TSchemaConstObjectProps>> = { alias: "text" }
): ISchemaValue<ISchemaConstObject<Partial<typeof TextProps & typeof opts.props>>> {
	return BP.Object({
		...termsEditor.schemas.text.schema,
		...opts,
		props: {
			...TextProps,
			...opts.props
		}
	}) as ISchemaValue<ISchemaConstObject<typeof TextProps & typeof opts.props>>;
}

/**
 * Text Value schema
 *
 * @param opts Options
 */
export function TextValueSchema(
	opts: Partial<ISchemaValueStringOpts> = { alias: "textValue" }
): ISchemaValue<ISchemaConstString> {
	return BP.String({
		...termsEditor.schemas.text.value,
		default: "",
		fallbackValue: "",
		...opts,
		constraints: {
			required: true,
			...opts.constraints
		},
		editorOptions: {
			layoutType: "noHeader",
			...opts.editorOptions
		}
	});
}

/**
 * Text Align schema
 * 
 * @param opts Options
 */
export function TextAlignSchema(
	opts: Partial<ISchemaValueEnumStringOpts> = { alias: "textAlign" }
): ISchemaValue<ISchemaConstEnum<ISchemaConstString>> {
	return BP.Enum.String({
		...termsEditor.schemas.text.align,
		options: getValuesFromStringEnum(
			TEXT_ALIGN_CLASS,
			termsEditor.schemas.text.alignValues,
			{
				left: {
					icon: "mdi/format-align-left"
				},
				center: {
					icon: "mdi/format-align-center"
				},
				right: {
					icon: "mdi/format-align-right"
				},
				block: {
					icon: "mdi/format-align-justify"
				},
				default: {
					icon: "mdi/set-none"
				}
			}
		),
		default: ENUM_DEFAULT_VALUE,
		...opts,
		editorOptions: {
			controlType: "buttons",
			...opts.editorOptions
		}
	});
}

export function TextStyleSchema(
	opts: Partial<ISchemaValueObjectOpts<TSchemaConstObjectProps>> = { alias: "textStyle" }
): ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>> {
	return BP.Object({
		...termsEditor.schemas.text.style,
		...opts,
		props: {
			bold: BP.Prop(BP.Boolean({
				...termsEditor.schemas.text.bold,
				icon: "mdi/format-bold",
				default: false
			})),

			italic: BP.Prop(BP.Boolean({
				...termsEditor.schemas.text.italic,
				icon: "mdi/format-italic",
				default: false
			})),

			underline: BP.Prop(BP.Boolean({
				...termsEditor.schemas.text.underline,
				icon: "mdi/format-underline",
				default: false
			})),

			monospace: BP.Prop(BP.Boolean({
				...termsEditor.schemas.text.monospace,
				icon: "mdi/code-tags",
				default: false
			})),

			...opts.props
		},
		editorOptions: {
			controlType: "buttons",
			...opts.editorOptions
		}
	});
}

/**
 * Text Font Size schema
 * 
 * @param opts Options
 */
export function TextFontSizeSchema(
	opts: Partial<ISchemaStringWithConstOpts> = { alias: "textFontSize" }
): ISchemaStringWithConst {
	return BP.StringWithConst({
		...termsEditor.schemas.text.fontSize,
		constants: getValuesFromStringEnum(FONT_SIZE_CLASS, termsEditor.schemas.text.fontSizeValues),
		default: FONT_SIZE_CLASS_default,
		editorOptions: {
			controlType: "fontSizeSelector"
		},
		...opts,
		constraints: {
			...termsEditor.schemas.text.fontSizeConstraints,
			pattern: fontSizePattern,
			...opts.constraints
		}
	});
}

/**
 * Text Overflow schema
 * 
 * @param opts Options
 */
export function TextOverflowSchema(
	opts: Partial<ISchemaValueEnumStringOpts> = { alias: "textOverflow" }
): ISchemaValue<ISchemaConstEnum<ISchemaConstString>> {
	return BP.Enum.String({
		...termsEditor.schemas.text.overflow,
		options: getValuesFromStringEnum(
			TEXT_OVERFLOW,
			termsEditor.schemas.text.overflowValues,
			TEXT_OVERFLOW_opts
		),
		default: TEXT_OVERFLOW_default,
		fallbackValue: TEXT_OVERFLOW_default,
		...opts,
		editorOptions: {
			controlType: "buttons",
			...opts.editorOptions
		},
		constraints: {
			required: true,
			...opts.constraints
		}
	});
}

/**
 * Text Markdown schema
 */
export function TextMarkdownSchema(): ISchemaOptionalGroup<ISchemaValue<ISchemaConstObject<{
	blocks: ISchemaConstObjectOptsProp<ISchemaValue<ISchemaConstBoolean>>;
}>>> {
	return BP.OptGroup({
		...termsEditor.schemas.text.markdown,
		alias: "textMarkdown",
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: {
				blocks: BP.Prop(BP.Boolean({
					...termsEditor.schemas.text.markdownBlocks,
					default: true,
					fallbackValue: true
				}))
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	});
}

/**
 * Text Html schema
 * 
 * @param opts Options
 */
export function TextHtmlSchema(
	opts: Partial<ISchemaValueBooleanOpts> = { alias: "textHtml" }
): ISchemaValue<ISchemaConstBoolean> {
	return BP.Boolean({
		...termsEditor.schemas.text.html,
		default: false,
		hidden: true,
		fallbackValue: false,
		...opts,
		editorOptions: {
			hidden: true,
			...opts.editorOptions
		}
	});
}
