/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createEventEmitter, emitEvent, removeAllEventListeners, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";

export interface ISelectionItem_Base {
	/** Item type */
	type: string;

	/** Selected model node ID */
	nodeId: number;
}

/**
 * Class to manage editor selection
 */
export class Selection<TSelectionItem extends ISelectionItem_Base = ISelectionItem_Base> {

	/** Selection change event */
	public onChange: TSimpleEventEmitter<void> = createEventEmitter();

	/** Single selection event */
	public onSelectSingle: TSimpleEventEmitter<TSelectionItem> = createEventEmitter();

	/** Single selection event */
	public onUnselectSingle: TSimpleEventEmitter<TSelectionItem> = createEventEmitter();

	/** Single selection event */
	public onUnselectAll: TSimpleEventEmitter<void> = createEventEmitter();

	/** Selected items array */
	protected items: TSelectionItem[] = [];

	/** If multiselect is enabled */
	protected multiSelect = false;

	protected findItemIndexByNodeId(nodeId: number): number {

		for (let i = 0; i < this.items.length; i++) {
			if (this.items[i].nodeId === nodeId) {
				return i;
			}
		}

		return -1;

	}

	protected clearSelectionIfMixedType(type: string): void {

		let clear = false;

		for (let i = 0; i < this.items.length; i++) {
			if (this.items[i].type !== type) {
				clear = true;
				break;
			}
		}

		if (clear) {
			this.unselectAll();
		}

	}

	protected unselectAll(): void {

		for (let i = 0; i < this.items.length; i++) {
			emitEvent(this.onUnselectSingle, this.items[i]);
		}

		this.items = [];

	}

	/**
	 * Enables multi select
	 */
	public enableMultiSelect(): void {
		this.multiSelect = true;
	}

	/**
	 * Disables multi select
	 */
	public disableMultiSelect(): void {
		this.multiSelect = false;
	}

	/**
	 * Returns if multi select is enabled
	 */
	public isMultiSelectEnabled(): boolean {
		return this.multiSelect;
	}

	/**
	 * Returns selected items
	 */
	public getItems(): TSelectionItem[] {
		return this.items;
	}

	/**
	 * Sets the selected items (replace)
	 *
	 * @param items Selection items
	 */
	public setItems(items: TSelectionItem[]): void {

		this.items = items;

		for (let i = 0; i < this.items.length; i++) {
			emitEvent(this.onSelectSingle, this.items[i]);
		}

		emitEvent(this.onChange);

	}

	/**
	 * Returns if node is selected
	 *
	 * @param nodeId Node ID
	 */
	public isNodeSelected(nodeId: number): boolean {
		return this.findItemIndexByNodeId(nodeId) >= 0;
	}

	/**
	 * Unselected item by node ID
	 *
	 * @param nodeId Node ID
	 */
	public deselectByNodeId(nodeId: number): boolean {

		const itemIndex = this.findItemIndexByNodeId(nodeId);

		if (itemIndex >= 0) {

			const item = this.items[itemIndex];

			this.items.splice(itemIndex);
			emitEvent(this.onChange);
			emitEvent(this.onUnselectSingle, item);

			if (this.items.length === 0) {
				emitEvent(this.onUnselectAll);
			}

			return true;

		} else {

			return false;

		}

	}

	/**
	 * Clears selection
	 */
	public clear(emitOnUnselectAll = true): void {

		this.unselectAll();

		emitEvent(this.onChange);

		if (emitOnUnselectAll) {
			emitEvent(this.onUnselectAll);
		}

	}

	/**
	 * Destroys the selection
	 */
	public destroy(): void {

		removeAllEventListeners(this.onChange);
		removeAllEventListeners(this.onSelectSingle);
		removeAllEventListeners(this.onUnselectSingle);
		removeAllEventListeners(this.onUnselectAll);

	}

}