/**
 * Text Field V2 HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, COMPONENT_MODE, defineElementaryComponent } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	ENUM_DEFAULT_VALUE,
	getStringEnumValue,
	Label,
	propGroups,
	StyleSheet,
	THAEComponentDefinition,
	THAEComponentReact,
	useStyleSheetRegistry
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { TEXT_FIELD_TYPE } from "../../Enums/TEXT_FIELD_TYPE";
import { FieldLabelInfo } from "./FieldLabelInfo";
import { FieldInfo } from "./FieldInfo";
import { useField } from "./useField";
import { ITextFieldAutocompleteRefProps, TextFieldAutocomplete } from "./TextFieldAutocomplete";
import { TextFieldPasswordPreview } from "./TextFieldPasswordPreview";
import { definition, HAEComponentTextField_State } from "./textFieldDefinition";
import { TextFieldBaseProps } from "./textFieldBaseProps";
import { HAEComponentField_Events } from "./events";
import { isString } from "@hexio_io/hae-lib-shared";
import { createFieldClassListModifiers } from "./createFieldClassListModifiers";
import { getSelectionByField, initialSelection } from "./selection";

const HAEComponentTextField_Props = {

	...TextFieldBaseProps,

	typeData: BP.Prop(BP.OneOf({
		...termsEditor.schemas.textField.type,
		typeValueOpts: {
			...termsEditor.schemas.textField.type,
		},
		defaultType: ENUM_DEFAULT_VALUE,
		types: {
			"DEFAULT": {
				...termsEditor.schemas.textField.typeValues.default,
				value: BP.Void({})
			},
			"EMAIL": {
				...termsEditor.schemas.textField.typeValues.email,
				value: BP.Void({})
			},
			"PASSWORD": {
				...termsEditor.schemas.textField.typeValues.password,
				value: BP.Object({
					props: {
						passwordPreview: BP.Prop(BP.Boolean({
							...termsEditor.schemas.textField.passwordPreview,
							default: false,
							fallbackValue: false,
							constraints: {
								required: true
							}
						}), 0)
					},
					editorOptions: {
						layoutType: "passthrough"
					}
				})
			}
		},
		constraints: {
			required: true
		},
		editorOptions: {
			layoutType: "noHeader"
		}
	}), 10, propGroups.common)

};

const HAEComponentTextField_Events = {
	...HAEComponentField_Events
};

const HAEComponentTextField_Definition = defineElementaryComponent<
	typeof HAEComponentTextField_Props,
	HAEComponentTextField_State,
	typeof HAEComponentTextField_Events
>({

	...definition,

	name: "textField_v2",

	props: HAEComponentTextField_Props,

	events: HAEComponentTextField_Events,

});

const HAEComponentTextField_React: THAEComponentReact<typeof HAEComponentTextField_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const {
		placeholder,
		prefix,
		suffix,
		pattern,
		autocomplete,
		htmlAutocomplete,
		minLength,
		maxLength,

		labelText,
		labelIcon,
		descriptionText,
		//hidden,
		readOnly,
		enabled,
		validate,
		required,
		customValidation
	} = props;

	const { value, empty, touched, changed, valid } = state;

	const { safePath: componentPath, componentMode } = componentInstance;

	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;

	const contentRef = React.useRef<HTMLDivElement>();
	const inputRef = React.useRef<HTMLInputElement>();
	const autocompleteRef = React.useRef<ITextFieldAutocompleteRefProps>();

	// Type

	const specTypeValue = getStringEnumValue(TEXT_FIELD_TYPE, props.typeData.type);

	const [ typeValue, setTypeValue ] = React.useState(specTypeValue);

	const typeDataValue = props.typeData.value[props.typeData.type];

	React.useEffect(() => {
		setTypeValue(specTypeValue);
	}, [ specTypeValue, typeDataValue?.passwordPreview ]);

	// Classlist and stylesheet

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-field",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);
	const id = idClassName;

	classList.add(
		"cmp-field--text",
		`cmp-field--text-type-${specTypeValue}`
	);
	classList.addModifiers({
		"with-prefix": !!prefix?.value,
		"with-suffix": !!suffix?.value,
		validate
	});
	classList.addModifiers(createFieldClassListModifiers(classList, { enabled, empty, touched, changed, valid }), false);

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		if (prefix?.width) {
			result.addString(`.${idClassName}`, `--element-prefix-width: ${prefix.width};`);
		}

		if (suffix?.width) {
			result.addString(`.${idClassName}`, `--element-suffix-width: ${suffix.width};`);
		}

		return result;
	}, [ prefix, suffix ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	const {
		setValue,
		//setTouched
	} = useField<string>(
		{
			id,
			state,
			readOnly,
			validate,
			customValidation,
			validationDependencies: [ enabled, required, pattern, minLength, maxLength, typeValue ],
			fixDefaultValue: true,
			onChange: !elementReadOnly && componentInstance.eventEnabled.change ? componentInstance.eventTriggers.change : undefined
		},
		setState
	);

	const inputProps: React.HTMLProps<HTMLInputElement> = {
		id,
		name: id,
		className: "cmp-field__input",
		type: typeValue,
		defaultValue: isString(value) ? value : "",
		placeholder,
		autoComplete: (autocomplete?.items && !htmlAutocomplete) ? "off" : htmlAutocomplete,
		readOnly: elementReadOnly,
		disabled: !enabled,
		required,
		pattern,
		minLength,
		maxLength
	};

	// Event handlers

	const _inputFocusHandler = React.useCallback(() => {
		autocompleteRef.current?.inputOnFocus();
	}, []);

	const _inputBlurHandler = React.useCallback(() => {
		setState((prevState) => ({ ...prevState, touched: true, selection: initialSelection }));

		autocompleteRef.current?.inputOnBlur();
	}, [ initialSelection, setState ]);

	const _inputMouseDownHandler = React.useCallback(() => {
		window.addEventListener("mouseup", () => {
			setState((prevState) => ({ ...prevState, selection: getSelectionByField(inputRef.current) }));
		}, { once: true });
	}, [ getSelectionByField, setState ]);

	const _inputKeyDownHandler = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
		autocompleteRef.current?.inputOnKeyDown(event);
	}, []);

	const _inputKeyUpHandler = React.useCallback(() => {
		setState((prevState) => ({ ...prevState, selection: getSelectionByField(inputRef.current) }));
	}, [ getSelectionByField, setState ]);

	const _inputChangeHandler = React.useCallback(() => {
		if (!inputRef.current) {
			return;
		}

		setValue(inputRef.current.value, { selection: getSelectionByField(inputRef.current) });
	}, [ getSelectionByField, setValue ]);

	return (
		<div className={classList.toClassName()}>
			<Label
				text={{ ...labelText, tagName: "span" }}
				icon={{ ...labelIcon, size: "SMALL" }}
				tagName="label"
				htmlFor={id}
				classList={new ClassList("cmp-field__label")}
				componentPath={[ ...componentPath, "label" ]}
				componentMode={componentMode}
			>
				<FieldLabelInfo required={required} />
			</Label>

			<div ref={contentRef} className="cmp-field__content">
				<input
					ref={inputRef}
					{...inputProps}
					onFocus={_inputFocusHandler}
					onBlur={_inputBlurHandler}
					onMouseDown={_inputMouseDownHandler}
					onKeyDown={_inputKeyDownHandler}
					onKeyUp={_inputKeyUpHandler}
					onChange={_inputChangeHandler}
				/>

				{
					prefix?.value ?
						<label htmlFor={id} className="cmp-field__prefix">{prefix.value}</label> :
						null
				}

				{
					suffix?.value ?
						<label htmlFor={id} className="cmp-field__suffix">{suffix.value}</label> :
						null
				}

				{
					specTypeValue === TEXT_FIELD_TYPE.PASSWORD && typeDataValue?.passwordPreview ?
						<TextFieldPasswordPreview
							typeValue={typeValue}
							setTypeValue={setTypeValue}
							componentPath={[ ...componentPath, "password-preview" ]}
							componentMode={componentMode}
						/> :
						null
				}
			</div>

			<FieldInfo descriptionText={descriptionText} componentPath={[ ...componentPath, "info" ]} componentMode={componentMode} />

			{
				autocomplete ?
					<TextFieldAutocomplete
						ref={autocompleteRef}
						items={autocomplete.items}
						value={value}
						setValue={setValue}
						minValueLength={autocomplete.minValueLength}
						contentRef={contentRef}
						inputId={id}
						inputRef={inputRef}
						componentPath={[ ...componentPath, "autocomplete" ]}
						componentMode={componentMode}
					/> :
					null
			}
		</div>
	);
};

export const HAEComponentTextField_v2: THAEComponentDefinition<typeof HAEComponentTextField_Definition> = {
	...HAEComponentTextField_Definition,
	reactComponent: HAEComponentTextField_React
};
