/**
 * View component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useEffect, useState } from "react";

import {
	BP,
	Type,
	defineElementaryComponent,
	DOC_ERROR_SEVERITY,
	DOC_ERROR_NAME,
	RuntimeContext,
	createEmptyScope,
	ISchemaConstObject,
	TSchemaConstObjectProps,
	TSchemaConstObjectPropsSpec,
	TGenericComponentInstance,
	RUNTIME_CONTEXT_MODE,
	TComponentNodePath,
	createEventTrigger,
	COMPONENT_MODE
} from "@hexio_io/hae-lib-blueprint";

import {
	ClassList, Container, THAEComponentDefinition, THAEComponentReact, LoadingInfo,
	ICON_NAME, useLoading, getStringEnumKeyByValue, CONTAINER_FLOW,
	Label, useTranslate, ContainerProps, OVERFLOW_string, CONTAINER_DEFAULT_HORIZONTAL_ALIGN, CONTAINER_DEFAULT_VERTICAL_ALIGN
} from "@hexio_io/hae-lib-components";

import {
	IViewInstanceResolver,
	IViewInstance,
	TBlueprintViewSpecSchema,
	IViewGlobals,
	IGlobalsResolver,
	createViewScope
} from "@hexio_io/hae-lib-core";
import { dataEqual } from "@hexio_io/hae-lib-blueprint/src/Shared/Equal";
import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { termsRuntime } from "../../terms";

export enum VIEW_COMPONENT_STATE {
	BLANK = "BLANK",
	LOADING = "LOADING",
	LOADED = "LOADED",
	NOT_FOUND = "NOT_FOUND",
	INVALID_BLUEPRINT = "INVALID_BLUEPRINT",
	INVALID_PARAMS = "INVALID_PARAMS",
	RESOLVE_ERROR = "RESOLVED_ERROR",
	READY = "READY"
}

interface HAEComponentView_State {
	viewId: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	viewParams: any;
	viewInstance: IViewInstance,
	viewParamsSchema: ISchemaConstObject<TSchemaConstObjectProps>;
	viewRuntimeContext: RuntimeContext<TBlueprintViewSpecSchema>;
	state: VIEW_COMPONENT_STATE;
	loadingRev: number;
	errorMessage: string;
	forceReload: boolean;
	onRenderHandler: () => void;
	onGlobalsChangeHandler: () => void;
	onResolverInvalidateHandler: (ev: { viewId?: string }) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	outlets: any;
	viewEventHandler: () => Promise<void>;
}

const HAEComponentView_Props = {

	view: BP.Prop(BP.ViewRef({
		label: "View",
		description: "View to display.",
		constraints: {
			required: false
		}
	})),

	isPage: BP.Prop(BP.Boolean({
		hidden: true
	})),

	overflow: ContainerProps.overflow

};

const HAEComponentView_Events = {};

function requestRender(cmpInstance: TGenericComponentInstance): void {
	if (cmpInstance.customData.viewRenderTimeout) {
		return;
	}

	cmpInstance.customData.viewRenderTimeout = setTimeout(() => {
		const state = cmpInstance.state as HAEComponentView_State;

		if (state.viewRuntimeContext && !state.viewRuntimeContext.isDestroyed()) {
			state.viewRuntimeContext.render();
		}
	}, 0);
}

function updateViewParams(
	rCtx: RuntimeContext,
	cmpInstance: TGenericComponentInstance,
	spec: TSchemaConstObjectPropsSpec<typeof HAEComponentView_Props>,
	state: HAEComponentView_State
): HAEComponentView_State {

	// Validate params
	const isValid = state.viewParamsSchema.validate(
		rCtx, cmpInstance.path.concat(["view", "params"]), cmpInstance.modelNodeId, spec.view.params, true
	);

	if (!isValid) {

		return {
			...state,
			viewParams: spec.view.params,
			state: VIEW_COMPONENT_STATE.INVALID_PARAMS
		};

	}

	const viewSpec = state.viewRuntimeContext.getLastSpec();
	const wasInitialized = state.state === VIEW_COMPONENT_STATE.READY;

	let viewEventHandler: () => Promise<void>;
	let viewEventHandlerTriggered = false; // Use scoped variable to prevent multiple triggers

	// Update params only if they really changed
	if (!dataEqual(state.viewRuntimeContext.getScope().globalData.params, spec.view.params)) {
		// Reset view spec?
		if (viewSpec?.reInitOnParamsChanged) {
			state.viewRuntimeContext.clearLastSpec();
		}

		const scope = createViewScope(rCtx, spec.view.params);
		state.viewRuntimeContext.setScope(scope, true);

		viewEventHandler = async () => {
			if (viewEventHandlerTriggered || state.viewRuntimeContext.isDestroyed()) {
				return;
			}

			viewEventHandlerTriggered = true;

			const evViewSpec = state.viewRuntimeContext.getLastSpec();
			const eventSpec = state.viewRuntimeContext.getLastSpec()?.events;
			const getEventSpec = (eventName: string) => state.viewRuntimeContext.getLastSpec()?.events[eventName];

			if (
				eventSpec?.onInit && (
					!wasInitialized ||
					viewSpec.reInitOnParamsChanged
				)
			) {
				const evTrigger = createEventTrigger(
					state.viewRuntimeContext, "componentEvent", getEventSpec, "onInit",
					"$", ["$"], -1, null,
					undefined
				);
		
				await evTrigger(state.viewRuntimeContext.getLastScope());
			}
		
			if (
				eventSpec?.onParamsChanged && (
					evViewSpec.reInitOnParamsChanged !== true &&
					state.state !== VIEW_COMPONENT_STATE.LOADED
				)
			) {
				const evTrigger = createEventTrigger(
					state.viewRuntimeContext, "componentEvent", getEventSpec, "onParamsChanged",
					"$", ["$"], -1, null,
					undefined
				);
		
				await evTrigger(state.viewRuntimeContext.getLastScope());
			}		
		};
	}

	// Return new state
	return {
		...state,
		viewParams: spec.view.params,
		state: VIEW_COMPONENT_STATE.READY,
		outlets: state.viewRuntimeContext.getLastSpec()?.outlets,
		viewEventHandler: viewEventHandler
	};

}

const HAEComponentView_Definition = defineElementaryComponent<
	typeof HAEComponentView_Props,
	HAEComponentView_State,
	typeof HAEComponentView_Events
>({
	name: "view",
	category: "logic",
	label: "View",
	description: "Displays a view.",
	icon: "mdi/view-dashboard",
	docUrl: "...",
	order: 50,
	props: HAEComponentView_Props,
	events: HAEComponentView_Events,

	resolve: (spec, state, updateStateAsync, cmpInstance, rCtx) => {

		const prevViewId = state?.viewId;
		let loadingRev = state?.loadingRev || 0;

		let globalsChangeHandler;
		let resolverInvalidateHandler;

		// Define globals change handler
		if (state?.onGlobalsChangeHandler) {
			globalsChangeHandler = state.onGlobalsChangeHandler;
		} else {
			const globalsResolver = rCtx.getResolver<IGlobalsResolver<IViewGlobals>>("globals");

			globalsChangeHandler = () => {
				if (cmpInstance.state.viewRuntimeContext) {
					const scope = createViewScope(rCtx, cmpInstance.state.viewParams);
					cmpInstance.state.viewRuntimeContext.setScope(scope, false);

					requestRender(cmpInstance);
				}
			};

			onEvent(globalsResolver.onChange, globalsChangeHandler);
		}

		// Define resolver invalidate handler
		if (state?.onResolverInvalidateHandler) {
			resolverInvalidateHandler = state.onResolverInvalidateHandler;
		} else {
			const viewInstanceResolver = rCtx.getResolver<IViewInstanceResolver>("viewInstance");

			resolverInvalidateHandler = (ev: { viewId?: string }) => {
				if (!ev.viewId || ev.viewId === cmpInstance.state.viewId) {
					updateStateAsync((prevState) => ({
						...prevState,
						forceReload: true
					}));
				}
			};

			onEvent(viewInstanceResolver.onInvalidate, resolverInvalidateHandler);
		}

		// Update runtime context path
		if (state?.viewRuntimeContext && state.viewRuntimeContext.getContextPath() !== cmpInstance.path) {
			state.viewRuntimeContext.setContextPath(cmpInstance.path);
			requestRender(cmpInstance);
		}

		// Resolve view instance if not up to date
		if (state?.forceReload || prevViewId !== spec.view.viewId) {

			// console.log(`View ID changed ${prevViewId} -> ${spec.view.viewId}, resolving view instance...`);

			loadingRev++;

			// Unbind render handler if present
			if (state && state.viewRuntimeContext) {
				offEvent(state.viewRuntimeContext.renderEvent, state.onRenderHandler);
				rCtx.disconnectChildContext(state.viewRuntimeContext);
			}

			// Has a view ID
			if (spec.view.viewId) {

				const getInstancePromise = rCtx.getResolver<IViewInstanceResolver>("viewInstance").getInstance(spec.view.viewId);
				rCtx.__addAsyncOperation(new Promise((resolve) => getInstancePromise.then(resolve, resolve)));

				let _wasStateUpdated = false;

				getInstancePromise.then((viewInstance) => {

					// Terminate when loading rev has changed or component is destroyed
					if (cmpInstance.state.loadingRev !== loadingRev || cmpInstance.customData.destroyed) {
						return;
					}

					if (!viewInstance) {

						updateStateAsync((prevState) => ({
							viewId: spec.view.viewId,
							viewParams: null,
							viewInstance: null,
							viewParamsSchema: null,
							viewRuntimeContext: null,
							state: VIEW_COMPONENT_STATE.NOT_FOUND,
							errorMessage: null,
							loadingRev: prevState.loadingRev,
							forceReload: false,
							onRenderHandler: null,
							onGlobalsChangeHandler: globalsChangeHandler,
							onResolverInvalidateHandler: resolverInvalidateHandler,
							outlets: null,
							viewEventHandler: null
						}));

						_wasStateUpdated = true;

						return;

					}

					if (!viewInstance.isValid) {

						updateStateAsync((prevState) => ({
							viewId: spec.view.viewId,
							viewParams: null,
							viewInstance: null,
							viewParamsSchema: null,
							viewRuntimeContext: null,
							state: VIEW_COMPONENT_STATE.INVALID_BLUEPRINT,
							errorMessage: null,
							loadingRev: prevState.loadingRev,
							forceReload: false,
							onRenderHandler: null,
							onGlobalsChangeHandler: globalsChangeHandler,
							onResolverInvalidateHandler: resolverInvalidateHandler,
							outlets: null,
							viewEventHandler: null
						}));

						_wasStateUpdated = true;

						return;

					}

					// Create runtime context
					const scope = createEmptyScope();

					const viewRuntimeContext = new RuntimeContext({
						mode: RUNTIME_CONTEXT_MODE.NORMAL,
						resolvers: rCtx.getAllResolvers(),
						preserveErrorsBetweenRenders: rCtx.getPreserveErrorsBetweenRenders(),
						path: cmpInstance.path
					}, viewInstance.renderFn, scope);

					// Each time the view is changed we must use another uid or it will prevent re-render
					// of a new content (components are memoized based on their uid). If it will not be different
					// each time all child components will have the same uid (0) regardless the view instance.
					viewRuntimeContext.setLastUid(loadingRev);

					const viewParamsSchema = BP.Const.Object({
						props: viewInstance.params
					});

					const onRenderHandler = () => {

						// console.log("View rendered", cmpInstance.path.join("."), spec.view.viewId);

						if (cmpInstance.customData.viewRenderTimeout) {
							clearTimeout(cmpInstance.customData.viewRenderTimeout);
							cmpInstance.customData.viewRenderTimeout = null;
						}

						// Update outlets
						const newOutlets = viewRuntimeContext.getLastSpec()?.outlets;

						if (!dataEqual(cmpInstance.state.outlets, newOutlets)) {
							updateStateAsync((prevState) => ({
								...prevState,
								outlets: newOutlets
							}));
						}

					};

					onEvent(viewRuntimeContext.renderEvent, onRenderHandler);
					rCtx.connectChildContext(viewRuntimeContext);

					updateStateAsync((prevState) => {
						return updateViewParams(rCtx, cmpInstance, spec, {
							viewId: spec.view.viewId,
							viewParams: null,
							viewInstance: viewInstance,
							viewParamsSchema: viewParamsSchema,
							viewRuntimeContext: viewRuntimeContext,
							state: VIEW_COMPONENT_STATE.LOADED,
							errorMessage: null,
							loadingRev: prevState.loadingRev,
							forceReload: false,
							onRenderHandler: onRenderHandler,
							onGlobalsChangeHandler: globalsChangeHandler,
							onResolverInvalidateHandler: resolverInvalidateHandler,
							outlets: null,
							viewEventHandler: null
						})
					})

					_wasStateUpdated = true;

				}, (err) => {

					rCtx.logRuntimeError({
						severity: DOC_ERROR_SEVERITY.ERROR,
						name: DOC_ERROR_NAME.VIEW_LOAD_ERROR,
						modelPath: cmpInstance.path,
						modelNodeId: cmpInstance.modelNodeId,
						message: `Failed to resolve View '${spec.view.viewId}': ${String(err)}`,
						metaData: {
							// @todo add translation to terms and table
							translationTerm: "component:errors.failedToResolveView",
							args: {
								viewId: spec.view.viewId
							}
						}
					});

					// Terminate when loading rev has changed or component is destroyed
					if (cmpInstance.state.loadingRev !== loadingRev || cmpInstance.customData.destroyed) {
						return;
					}

					updateStateAsync((prevState) => ({
						viewId: spec.view.viewId,
						viewParams: null,
						viewInstance: null,
						viewParamsSchema: null,
						viewRuntimeContext: null,
						state: VIEW_COMPONENT_STATE.RESOLVE_ERROR,
						errorMessage: err,
						loadingRev: prevState.loadingRev,
						forceReload: false,
						onRenderHandler: null,
						onGlobalsChangeHandler: globalsChangeHandler,
						onResolverInvalidateHandler: resolverInvalidateHandler,
						outlets: null,
						viewEventHandler: null
					}));

					_wasStateUpdated = true;

				});

				// If promise was resolved immediately, return null so the "asyncly" updated state remains.
				if (_wasStateUpdated) {
					return null;
				}

				return {
					viewId: spec.view.viewId,
					viewParams: null,
					viewInstance: null,
					viewParamsSchema: null,
					viewRuntimeContext: null,
					state: VIEW_COMPONENT_STATE.LOADING,
					errorMessage: null,
					loadingRev: loadingRev,
					forceReload: false,
					onRenderHandler: null,
					onGlobalsChangeHandler: globalsChangeHandler,
					onResolverInvalidateHandler: resolverInvalidateHandler,
					outlets: null,
					viewEventHandler: null
				};

			} else {

				return {
					viewId: spec.view.viewId,
					viewParams: null,
					viewInstance: null,
					viewParamsSchema: null,
					viewRuntimeContext: null,
					state: VIEW_COMPONENT_STATE.BLANK,
					errorMessage: null,
					loadingRev: loadingRev,
					forceReload: false,
					onRenderHandler: null,
					onGlobalsChangeHandler: globalsChangeHandler,
					onResolverInvalidateHandler: resolverInvalidateHandler,
					outlets: null,
					viewEventHandler: null
				};

			}

		}

		// Update params
		if (
			(
				state?.state == VIEW_COMPONENT_STATE.LOADED ||
				state?.state == VIEW_COMPONENT_STATE.INVALID_PARAMS ||
				state?.state == VIEW_COMPONENT_STATE.READY
			) && !dataEqual(state.viewParams, spec.view.params)
		) {
			return updateViewParams(rCtx, cmpInstance, spec, state);
		}

		return state;
	},

	destroy: (_spec, state, rCtx) => {

		if (state && state.viewRuntimeContext) {

			if (state.onRenderHandler) {
				offEvent(state.viewRuntimeContext.renderEvent, state.onRenderHandler);
				rCtx.disconnectChildContext(state.viewRuntimeContext);
			}

			state.viewRuntimeContext.destroy();
		}

		if(state && state.onGlobalsChangeHandler) {
			const globalsResolver = rCtx.getResolver<IGlobalsResolver<IViewGlobals>>("globals");
			offEvent(globalsResolver.onChange, state.onGlobalsChangeHandler);
		}

		if(state && state.onResolverInvalidateHandler) {
			const viewInstanceResolver = rCtx.getResolver<IViewInstanceResolver>("viewInstance");
			offEvent(viewInstanceResolver.onInvalidate, state.onResolverInvalidateHandler);
		}

	},

	getScopeData: (_spec, state) => {
		return {
			outlets: state.outlets
		};
	},

	getScopeType: () => {
		return Type.Object({
			props: {
				outlets: Type.Any({
					label: "Outlets",
					description: "Data passed back from the view."
				})
			}
		});
	}
});

interface IViewContentWrapperProps {
	viewId: string;
	rCtx: RuntimeContext<TBlueprintViewSpecSchema>;
	overflow: OVERFLOW_string;
	componentPath?: TComponentNodePath;
	componentMode: COMPONENT_MODE;
	classList?: ClassList;
}

const ViewContentWrapper: React.FunctionComponent<IViewContentWrapperProps> = (props) => {

	const [ spec, setSpec ] = useState(() => props.rCtx.getLastSpec());

	const t = useTranslate();

	//console.log("Render view component", props.rCtx);

	useEffect(() => {

		const onRender = () => {
			//console.log("View content render");
			setSpec(props.rCtx.getLastSpec());
		}

		onEvent(props.rCtx.renderEvent, onRender);

		return () => {
			offEvent(props.rCtx.renderEvent, onRender);
		};

	}, [ props.rCtx ]);

	const content = spec?.content;

	if (!spec.isAuthorized || !content) {
		const stateTerm = !spec.isAuthorized ?
			termsRuntime.components.view.unauthorized :
			termsRuntime.components.view.noContent;

		return (
			<div className="state-info cmp-view__state-info">
				<Label
					text={{ value: t("runtime", stateTerm) }}
					icon={{ source: ICON_NAME.ERROR, size: "LARGE" }}
					componentPath={[ ...props.componentPath, "state" ]}
					componentMode={props.componentMode}
				/>
			</div>
		);
	}

	return (
		<Container
			content={content}
			flow={getStringEnumKeyByValue(CONTAINER_FLOW, CONTAINER_FLOW.COLUMN)}
			horizontalAlign={CONTAINER_DEFAULT_HORIZONTAL_ALIGN}
			verticalAlign={CONTAINER_DEFAULT_VERTICAL_ALIGN}
			overflow={props.overflow}
			componentPath={[ ...props.componentPath, "container" ]}
			componentMode={props.componentMode}
			classList={props.classList}
		/>
	);

};

const STATE_INFO_DELAY = 1000;

const HAEComponentView_React: THAEComponentReact<typeof HAEComponentView_Definition> = ({
	props, state, componentInstance, reactComponentClassList
}) => {
	const { overflow } = props;

	/** debugging, remove later
	const [ viewState, setViewState ] = React.useState(VIEW_COMPONENT_STATE.LOADING);
	React.useEffect(() => {
		setTimeout(() => {
			setViewState(VIEW_COMPONENT_STATE.READY);
		}, 5000);
	}, []);/**/

	const t = useTranslate();

	const { componentMode } = componentInstance;
	const editComponentMode = componentMode === COMPONENT_MODE.EDIT;

	useEffect(() => {
		if (state.viewEventHandler && !editComponentMode) {
			state.viewEventHandler().catch((err) => {
				console.warn("Failed to handle view events:", err);
			});
		}
	}, [ state.viewEventHandler, editComponentMode ])

	const viewState = state?.state || VIEW_COMPONENT_STATE.LOADING;
	const viewStateLoading = viewState === VIEW_COMPONENT_STATE.LOADING || viewState === VIEW_COMPONENT_STATE.LOADED;
	const viewStateReady = viewState === VIEW_COMPONENT_STATE.READY;

	const [ loading, stateInfoRef ] = useLoading(viewStateLoading, STATE_INFO_DELAY);

	if ([
		VIEW_COMPONENT_STATE.BLANK,
		VIEW_COMPONENT_STATE.NOT_FOUND,
		VIEW_COMPONENT_STATE.INVALID_BLUEPRINT,
		VIEW_COMPONENT_STATE.INVALID_PARAMS,
		VIEW_COMPONENT_STATE.RESOLVE_ERROR
	].includes(viewState)) {
		return (
			<div className="state-info cmp-view__state-info">
				<Label
					text={{ value: t("runtime", termsRuntime.components.view.states[viewState.toLowerCase()]) }}
					icon={{ source: ICON_NAME.ERROR, size: "LARGE" }}
					componentPath={[ ...componentInstance.safePath, "state" ]}
					componentMode={componentMode}
				/>
			</div>
		);
	}

	return (
		<>
			{
				loading ?
					<div ref={stateInfoRef} className="state-info cmp-view__state-info">
						<LoadingInfo />
					</div> :
					null
			}
			{
				viewStateReady ?
					<ViewContentWrapper
						viewId={state.viewId}
						rCtx={state.viewRuntimeContext}
						overflow={overflow as OVERFLOW_string}
						componentPath={componentInstance.safePath}
						componentMode={componentMode}
						classList={reactComponentClassList}
					/> :
					null
			}
		</>
	);
};

export const HAEComponentView: THAEComponentDefinition<typeof HAEComponentView_Definition> = {
	...HAEComponentView_Definition,
	reactComponent: HAEComponentView_React
};
