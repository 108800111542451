/**
 * Hexio App Engine Core
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	DOC_ERROR_SEVERITY,
	RUNTIME_CONTEXT_MODE,
	RuntimeContext,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import {
	IIconPackageURLMapping,
	OverlayManager,
	TChildViewFactoryFn,
	TGenericHAEComponentDefinition,
	ToastMessageManager,
	TRootComponentListSchema
} from "@hexio_io/hae-lib-components";
import { createEventEmitter, emitEvent, ItemRegistry, IViewParamsSpec, onEvent } from "@hexio_io/hae-lib-shared";
import { IAppState } from "../../shared/IAppState";
import { ActionRepository } from "./ActionRepository";
import { RoutingManager } from "./RoutingManager";
import { SessionProvider } from "./SessionProvider";
import { ViewRepository } from "./ViewRepository";
import {
	ComponentResolver,
	CoreComponentEventNodeTypes,
	createCoreComponentEventHandlers,
	createRootViewRuntime,
	DataSourceResolver,
	FunctionResolver,
	GlobalsContainer,
	GlobalsResolver,
	IActionParams,
	IAppEnvs,
	IComponentDefRegistry,
	IConstants,
	IDataSourceDefRegistry,
	IFunctionDefRegistry,
	IRenderContext,
	IRenderContextResolvers,
	ITranslationManager,
	IViewGlobals,
	IViewInstance,
	IThemeProvider
} from "@hexio_io/hae-lib-core";

/**
 * Client render context options
 */
export interface IClientRenderContextOpts {
	componentRegistry: IComponentDefRegistry;
	dataSourceRegistry: IDataSourceDefRegistry;
	functionRegistry: IFunctionDefRegistry;
	viewRepository: ViewRepository;
	actionRepository: ActionRepository;
	routingManager: RoutingManager;
	sessionProvider: SessionProvider;
	translationManager: ITranslationManager;
	iconPackageUrlMapping: IIconPackageURLMapping;
	appId: string;
	appEnvId: string;
	appName: string;
	constants: IConstants;
	_app: IAppEnvs;
	viewName?: string;
	viewParams?: IViewParamsSpec;
}

/**
 * Client render context
 */
export class ClientRenderContext implements IRenderContext {
	/** Runtime mode - normal */
	public mode = RUNTIME_CONTEXT_MODE.NORMAL;

	/** Media resolution - not set = auto */
	public mediaResolution = null;

	/** Component registry */
	public componentRegistry: ItemRegistry<TGenericHAEComponentDefinition>;

	/** Routing manager */
	public routingManager: RoutingManager;

	/** Overlay manager */
	public overlayManager: OverlayManager;

	/** Toast Message manager */
	public toastMessageManager: ToastMessageManager;

	/** Icon package URL mapping */
	public iconPackageUrlMapping: IIconPackageURLMapping = {};

	/** Translate function */
	public translateFn: IRenderContext["translateFn"];

	/** Emitted when runtime context has rendered */
	public onRender = createEventEmitter<void>();

	/** Emitted when mode has changed (never emitted in this context) */
	public onModeChange = createEventEmitter<void>();

	/** Emitted when media resoluton has changed (never emitted in this context) */
	public onMediaResolutionChange = createEventEmitter<void>();

	/** Runtime context */
	private rCtx: RuntimeContext<TRootComponentListSchema>;

	/** Resolvers */
	private resolvers: IRenderContextResolvers;

	/** View repository */
	private viewRepository: ViewRepository;

	/** Action repository */
	private actionRepository: ActionRepository;

	/** Session provider */
	private sessionProvider: SessionProvider;

	/** Globals container */
	private globalsContainer: GlobalsContainer<IViewGlobals>;

	public themeProvider: IThemeProvider;

	/**
	 * Creates child root view and runtime context
	 * WARNING: You must destroy child runtime context yourself using `runtimeContext.destroy()`!
	 * NOTE: Intentionally defined as property so it can be safely passed to other code parts.
	 *
	 * @param viewName View name
	 * @param viewParams View params
	 */
	private childViewFactory: TChildViewFactoryFn;

	/**
	 * Context constructor
	 *
	 * @param config Context options
	 */
	public constructor(protected config: IClientRenderContextOpts) {
		this.componentRegistry = config.componentRegistry;
		this.viewRepository = config.viewRepository;
		this.actionRepository = config.actionRepository;
		this.iconPackageUrlMapping = config.iconPackageUrlMapping;
		this.routingManager = config.routingManager;

		this.sessionProvider = config.sessionProvider;

		this.setupGlobalsContainer();

		console.log("CLIENT CFG:", config);

		this.globalsContainer.set("appId", config.appId);
		this.globalsContainer.set("appEnvId", config.appEnvId);
		this.globalsContainer.set("appName", config.appName);
		this.globalsContainer.set("constants", config.constants);
		this.globalsContainer.set("_app", config._app);

		const eventHandlers = this.createEventHandlers();

		this.translateFn = (domain, term, vars, fallbackValue) => {
			// @todo !!! Change language to a user's one!
			return config.translationManager.translate(domain, "en_US", term, vars, fallbackValue);
		};

		// Add resolvers
		this.resolvers = {
			// Component resolver
			component: new ComponentResolver(config.componentRegistry),

			// Component event resolver
			componentEvent: {
				getEventNodeTypes: () => CoreComponentEventNodeTypes,
				getEventHandler: (name: string) => eventHandlers[name] || null
			},

			// Data source resolver
			dataSource: new DataSourceResolver(config.dataSourceRegistry),

			// Function resolver
			function: new FunctionResolver(config.functionRegistry),

			// Globals resolver
			globals: new GlobalsResolver(this.globalsContainer),

			// View instance resolver
			viewInstance: {
				onInvalidate: createEventEmitter<{ viewId?: string }>(),
				getInstance: async (viewId: string): Promise<IViewInstance> => {
					return this.viewRepository.getView(viewId);
				}
			},

			// Action delegate
			actionDelegate: {
				getDelegate: (actionId: string, params: IActionParams) => {
					return this.actionRepository.getDelegate(actionId, params);
				}
			},

			// Route & nav resolver
			route: config.routingManager.routeResolver,
			navigation: config.routingManager.navigationResolver,

			translate: {
				translate: (term, vars, fallbackValue, domain) => {
					return this.translateFn(domain || "app", term, vars, fallbackValue);
				}
			}
		};

		// Create runtime context
		this.rCtx = createRootViewRuntime(this.resolvers, this.config.viewName, this.config.viewParams);

		onEvent(this.rCtx.errorEvent, () => this.logRuntimeErrors());
		onEvent(this.rCtx.renderEvent, () => emitEvent(this.onRender));

		this.logRuntimeErrors();

		// Create child view factory
		this.childViewFactory = (viewName: string, viewParams: IViewParamsSpec) => {
			return createRootViewRuntime(this.resolvers, viewName, viewParams, this.rCtx);
		};

		this.overlayManager = new OverlayManager(this.childViewFactory);

		this.toastMessageManager = new ToastMessageManager();
	}

	private logRuntimeErrors() {
		this.rCtx.getRuntimeErrors(true).forEach((err) => {
			let color: string;
			let bg: string;
			let levelLabel: string;

			switch (err.severity) {
				case DOC_ERROR_SEVERITY.ERROR: {
					color = "#D50000";
					bg = "#D50000";
					levelLabel = "ERROR";
					break;
				}
				case DOC_ERROR_SEVERITY.WARNING: {
					color = "#FF6F00";
					bg = "#FF6F00";
					levelLabel = "WARN";
					break;
				}
				case DOC_ERROR_SEVERITY.INFO: {
					color = "#000000";
					bg = "#eeeeee";
					levelLabel = "INFO";
					break;
				}
				case DOC_ERROR_SEVERITY.HINT: {
					color = "#333333";
					bg = "transparent";
					levelLabel = "HINT";
					break;
				}
			}

			if (window.APP_DEBUG) {
				console.groupCollapsed(
					"[Runtime] %c %s %c %s: %c%s %c(%s)",
					`color: #ffffff; background: ${bg}`,
					levelLabel,
					`color: ${color}`,
					err.modelPath.join(" > "),
					`color: #000000`,
					err.message,
					`color: #999999`,
					err.name
				);

				console.log("Details:", err.details);
				console.log("Model Node ID:", err.modelNodeId);
				console.log("Meta Data:", err.metaData);
				console.groupEnd();
			}
		});
	}

	/**
	 * Creates global container and setup event listeners
	 */
	private setupGlobalsContainer() {
		this.globalsContainer = new GlobalsContainer<IViewGlobals>({
			location: this.routingManager.getCurrentLocation(),
			session: this.sessionProvider.getSession(),
			currentUser: this.sessionProvider.getIdentity()
		});

		// Update globals on changes and set defaults
		onEvent(this.routingManager.onNavigate, () => {
			this.globalsContainer.set("location", this.routingManager.getCurrentLocation());
		});

		onEvent(this.sessionProvider.onChange, () => {
			this.globalsContainer.set("session", this.sessionProvider.getSession());
			this.globalsContainer.set("currentUser", this.sessionProvider.getIdentity());
		});
	}

	private createEventHandlers() {
		return createCoreComponentEventHandlers({
			logEvent: (eventType, opts, metaData, result) => {
				if (window.APP_DEBUG) {
					// eslint-disable-next-line max-len
					console.groupCollapsed(
						"[EventSystem] Handled event '%s' #%d node ID '%s' (%s)",
						metaData.eventName,
						metaData.executionOrder,
						metaData.nodeId,
						eventType
					);
					console.log("Options:", opts);
					console.log("Result:", result);
					console.log("Sender:", {
						id: metaData.senderId,
						path: metaData.senderPath,
						modelNodeId: metaData.senderModelNodeId,
						instance: metaData.senderInstance
					});
					console.log("Input Scope:", metaData.inputScope);
					console.groupEnd();
				}
			},
			getActionDelegate: (actionId, params) => {
				return this.actionRepository.getDelegate(actionId, params);
			},
			navigate: (linkSpec, opts, openInNew) => {
				const target = this.routingManager.resolveLink(linkSpec, opts);

				if (openInNew) {
					window.open(target.url);
				} else {
					this.routingManager.navigate(target.url);
				}
			},
			navigateBack: () => {
				this.routingManager.navigate(-1);
			},
			openOverlay: async (data) => {
				const { overlayId, buttonId, customData } = await this.overlayManager.itemIsResolved(
					this.overlayManager.addItemData(data)
				);

				return {
					overlayId,
					buttonId,
					customData
				};
			},
			closeOverlay: (overlayId, buttonId, customData) => {
				this.overlayManager.removeItem(overlayId, buttonId, customData);
			},
			showToastMessage: (data) => {
				return this.toastMessageManager.addItemData(data);
			},
			hideToastMessage: (toastMessageId) => {
				this.toastMessageManager.removeItem(toastMessageId);
			},
			reloadSession: async () => {
				await this.sessionProvider.reload();
			}
		});
	}

	/**
	 * Returns component list spec
	 */
	public getComponentListSpec(): TGetBlueprintSchemaSpec<TRootComponentListSchema> {
		return this.rCtx.getLastSpec();
	}

	/**
	 * Returns runtime context
	 */
	public getRuntimeContext(): RuntimeContext {
		return this.rCtx;
	}

	/**
	 * Injects application state
	 *
	 * @param appState Application State
	 */
	public injectAppState(appState: IAppState): void {
		this.viewRepository.injectState(appState.viewInstances);
		this.actionRepository.injectState(appState.actionDelegates);
		this.sessionProvider.injectState(appState.session);
	}
}
