/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { OBJECT_TYPE, OBJECT_TYPE_PROP_NAME } from "../constants";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function cmpDataEqual(a: any, b: any) {

	// Direct match
	if (a === b) {

		if (
			a && typeof a === "object" &&
			a[OBJECT_TYPE_PROP_NAME] === OBJECT_TYPE.COMPONENT &&
			(a["inheritedPropsHasChanged"] === true || a["forceCompareInvalidate"] === true)
		) {
			a["forceCompareInvalidate"] = false;
			return false;
		}

		return true;
	}

	// Is function
	if (a && b && typeof a === "function" && typeof b === "function") {
		return true;
	}

	// Is object
	if (a && b && typeof a === "object" && typeof b === "object") {

		// If component, then compare only names, uid and inheritedProps
		if (
			a[OBJECT_TYPE_PROP_NAME] === OBJECT_TYPE.COMPONENT &&
			b[OBJECT_TYPE_PROP_NAME] === OBJECT_TYPE.COMPONENT
		) {
			if (
				a["componentName"] === b["componentName"] &&
				a["uid"] === b["uid"] &&
				a["inheritedPropsHasChanged"] !== true &&
				b["inheritedPropsHasChanged"] !== true
			) {
				return true;
			} else {
				return false;
			}
		}

		if (
			a[OBJECT_TYPE_PROP_NAME] === OBJECT_TYPE.SCOPE &&
			b[OBJECT_TYPE_PROP_NAME] === OBJECT_TYPE.SCOPE
		) {
			return true;
		}

		if (a.constructor !== b.constructor) return false;

		// Check array
		if (Array.isArray(a)) {
			const length = a.length;

			if (length != b.length){
				return false;
			}

			for (let i = length; i-- !== 0;) {
				if (!cmpDataEqual(a[i], b[i])) return false;
			}

			return true;
		}

		// Check map
		if ((a instanceof Map) && (b instanceof Map)) {
			if (a.size !== b.size){
				return false;
			}

			for (const i of a.entries()){
				if (!b.has(i[0])){
					return false;
				}
			}

			for (const i of a.entries()) {
				if (!cmpDataEqual(i[1], b.get(i[0]))){
					return false;
				}
			}

			return true;
		}

		// Check set
		if ((a instanceof Set) && (b instanceof Set)) {
			if (a.size !== b.size){
				return false;
			}

			for (const i of a.entries()) {
				if (!b.has(i[0])) return false;
			}

			return true;
		}

		// Check array buffer
		if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
			const length = a.byteLength;

			if (length != b.byteLength) {
				return false;
			}

			for (let i = length; i-- !== 0;) {
				if (a[i] !== b[i]) return false;
			}

			return true;
		
		}

		if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
		if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
		if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();

		const keys = Object.keys(a);
		const length = keys.length;
		if (length !== Object.keys(b).length) return false;

		for (let i = length; i-- !== 0;) {
			if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;
		}
  
		for (let i = length; i-- !== 0;) {
			const key = keys[i];

			if (!cmpDataEqual(a[key], b[key])) {
				return false;
			}
		}

		return true;

	}

	// true if both NaN, false otherwise
	return a!==a && b!==b;

}