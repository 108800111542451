/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGenericBlueprintSchema } from "../Schema/IBlueprintSchema";
import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";
import { TGenericModelNode } from "../Schema/IModelNode";
import { ICompileError } from "./ICompileError";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { TExpAst_Expression } from "../Expression/ExpAst";
import { compile as compileExpression } from "../Expression/ExpCompiler";
import { TModelPath } from "../Shared/TModelPath";

/**
 * Compile Context resolvers
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type TGenericCompileContextResolvers = {};

/**
 * Compile context options
 */
export interface ICompileContextOpts<TResolvers extends TGenericCompileContextResolvers> {
	resolvers: TResolvers;
}

export interface ICompileResult {
	code: string;
	errors: ICompileError[];
	errorCountError: number;
	errorCountWarning: number;
	errorCountInfo: number;
	errorCountHint: number;
}

/**
 * Compile context
 */
export class CompileContext<
	TResolvers extends TGenericCompileContextResolvers = TGenericCompileContextResolvers
	> {

	/** Resolvers */
	private resolvers: TResolvers;

	private compileErrors: Array<ICompileError> = [];

	private errorCountError = 0;
	private errorCountWarning = 0;
	private errorCountInfo = 0;
	private errorCountHint = 0;

	private globalValues: Array<string> = [];

	/**
	 * Design context
	 *
	 * @param opts Options
	 */
	public constructor(opts: ICompileContextOpts<TResolvers>) {

		this.resolvers = opts.resolvers;

	}

	/**
	 * Returns resolver by name
	 * 
	 * Throw an error if resolver is not available.
	 *
	 * @param name Resolver name
	 * @returns Resolver
	 */
	public getResolver<TResolver>(name: string): TResolver {

		const resolver = this.resolvers[name];

		if (resolver) {
			return resolver as unknown as TResolver;
		} else {
			throw new Error(`Resolver '${name}' is not available.`);
		}

	}

	/**
	 * INTERNAL: Sets a new resolvers - used only for testing
	 *
	 * @param resolvers Resolvers
	 */
	public __setResolvers(resolvers: TResolvers): void {

		this.resolvers = resolvers;

	}

	/**
	 * Adds global value and returns its identifier
	 *
	 * @param valueExpression Value expression JS code
	 */
	public addGlobalValue(valueExpression: string): string {

		const i = this.globalValues.indexOf(valueExpression);

		if (i >= 0) {
			return `g[${i}]`;
		} else {
			this.globalValues.push(valueExpression);
			return `g[${this.globalValues.length - 1}]`
		}

	}

	/**
	 * Returns last global value, its index and variable reference
	 */
	public getLastGlobalValue(): { index: number, value: string, ref: string } {

		const index = this.globalValues.length - 1;

		return {
			index: index,
			value: this.globalValues[index],
			ref: `g[${index}]`
		};

	}

	/**
	 * Allows to replace existing global value
	 * Can be used to firstly allocate global value and specify expression later (eg. for recursive compilation)
	 * 
	 * @param index Index
	 * @param valueExpression Value expression
	 */
	public replaceGlobalValue(index: number, valueExpression: string): void {

		if (this.globalValues[index] === undefined) {
			throw new Error(`Global value at index '${index}' does not exists.`);
		}

		this.globalValues[index] = valueExpression;

	}

	/**
	 * Returns all global values
	 */
	public getGlobalValues(): Array<string> {

		return this.globalValues.slice();

	}

	/**
	 * Logs a compile error
	 *
	 * @param error Error
	 */
	public logCompileError(error: ICompileError): void {

		this.compileErrors.push(error);

		switch (error.severity) {
			case DOC_ERROR_SEVERITY.ERROR: this.errorCountError++; break;
			case DOC_ERROR_SEVERITY.WARNING: this.errorCountWarning++; break;
			case DOC_ERROR_SEVERITY.INFO: this.errorCountInfo++; break;
			case DOC_ERROR_SEVERITY.HINT: this.errorCountHint++; break;
		}

	}

	/**
	 * Logs a compile validation error
	 * @param modelPath 
	 * @param severity 
	 * @param errors 
	 */
	public logValidationErrors(
		modelPath: TModelPath,
		modelNodeId: number,
		severity: DOC_ERROR_SEVERITY,
		errors: IBlueprintSchemaValidationError[]
	): void {

		this.logCompileError({
			severity: severity,
			name: DOC_ERROR_NAME.INVALID_VALUE,
			message: "Value pre-validation failed",
			details: (errors || []).map((e) => e.message),
			modelPath: modelPath,
			modelNodeId: modelNodeId
		});

	}

	/**
	 * Get errors for all documents
	 */
	public getCompileErrors(): ICompileError[] {

		return this.compileErrors;

	}

	/**
	 * Removes all compile errors
	 */
	public clearAllCompileErrors(): void {

		this.compileErrors = [];

		this.errorCountError = 0;
		this.errorCountHint = 0;
		this.errorCountInfo = 0;
		this.errorCountWarning = 0;

	}

	/**
	 * Returns true if context has errors
	 */
	public hasErrors(): boolean {
		return this.compileErrors.length > 0;
	}

	/**
	 * Returns if context has errors of type "error"
	 */
	public hasFatalErrors(): boolean {
		return this.errorCountError > 0;
	}

	/**
	 * Returns count of logged errors by type
	 */
	public getErrorTypeCounts(): { error: number; warning: number; info: number; hint: number; } {

		return {
			error: this.errorCountError,
			warning: this.errorCountWarning,
			info: this.errorCountInfo,
			hint: this.errorCountHint
		};

	}

	/**
	 * Compiles model to a runtime code
	 *
	 * @param modelNode Model node
	 */
	public compileModel(
		modelNode: TGenericModelNode, flushErrors = true, resetGlobals = true
	): ICompileResult {

		if (flushErrors) {
			this.compileErrors = [];
		}

		if (resetGlobals) {
			this.globalValues = [];
		}

		const modelCode = modelNode.schema.compileRender(this, modelNode, ["$"]);
		const globals = this.globalValues.join(",");

		return {
			code: `const g=[${globals}]; return ${modelCode.isScoped ? `(${modelCode.code})(s,pv,pt)` : modelCode.code}`,
			errors: this.compileErrors.slice(),
			errorCountError: this.errorCountError,
			errorCountWarning: this.errorCountWarning,
			errorCountInfo: this.errorCountInfo,
			errorCountHint: this.errorCountHint
		};

	}

	public compileValidate(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
		schema: TGenericBlueprintSchema, validateChildren = false, flushErrors = true, createOpts?: any
	): ICompileResult {

		if (flushErrors) {
			this.compileErrors = [];
		}

		const validateCode = schema.compileValidate(this, ["$"], -1, validateChildren, createOpts as never);
		const globals = this.globalValues.join(",");

		return {
			code: `const g=[${globals}]; return (${validateCode})(v,pt)`,
			errors: this.compileErrors.slice(),
			errorCountError: this.errorCountError,
			errorCountWarning: this.errorCountWarning,
			errorCountInfo: this.errorCountInfo,
			errorCountHint: this.errorCountHint
		};

	}

	public compileExpression(
		ast: TExpAst_Expression, source: string, modelNodeId: number, flushErrors = true, emitMetaData = false
	): ICompileResult {

		if (flushErrors) {
			this.compileErrors = [];
		}

		const cmp = compileExpression(this, modelNodeId, ast, source, emitMetaData);
		const globals = this.globalValues.join(",");

		return {
			code: `const g=[${globals}]; return (${cmp.code})(s,pt)`,
			errors: this.compileErrors.slice(),
			errorCountError: this.errorCountError,
			errorCountWarning: this.errorCountWarning,
			errorCountInfo: this.errorCountInfo,
			errorCountHint: this.errorCountHint
		};

	}

	/**
	 * Resets context's state
	 */
	public reset(): void {

		this.compileErrors = [];
		this.globalValues = [];		

		this.errorCountError = 0;
		this.errorCountWarning = 0;
		this.errorCountInfo = 0;
		this.errorCountHint = 0;

	}

}