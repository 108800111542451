/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ICON_NAME } from "../../Enums/ICON_NAME";
import { IActivityItem } from "./IActivityItem";

/**
 * Toast message type
 */
export enum TOAST_MESSAGE_TYPE {
	INFO = "info",
	SUCCESS = "success",
	WARNING = "warning",
	ERROR = "error"
}

/**
 * Toast Message object
 */
export interface IToastMessage {
	/** Unique ID - can be referenced in events */
	id?: string;

	/** Message type */
	type: TOAST_MESSAGE_TYPE;

	/** Message contents */
	message: string;

	/** Details */
	details?: string;

	/** Custom icon */
	iconName?: ICON_NAME;

	/** Duration in seconds after which the toast should disappear */
	duration?: number;
}

/**
 * Toast Message Activity Item
 */
export interface IToastMessageActivityItem extends IActivityItem {
	/** Item data */
	data: IToastMessage;
}
