/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IBaseErrorDetails } from ".";
import { BaseError } from "./BaseError";
import { IBaseError } from "./IError";

export interface IIntegrationErrorDetails extends IBaseErrorDetails {

	/** Integration node type */
	integrationId?: string;
	integrationType?: string;
	functionName?: string;

	/** Generic error code */
	errorCode?: string;

	/** Other options */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[K: string]: any;

}

/**
 * Integration Error
 */
export class IntegrationError extends BaseError implements IBaseError {

	public constructor(
		name: string,
		message: string,
		errorDetails?: IIntegrationErrorDetails,
		sourceError?: any
	) {
		super(name, message, errorDetails, sourceError);
	}

}
