/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstFloatOpts, SchemaConstFloat, ISchemaConstFloat } from "../const/SchemaConstFloat";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueFloatOpts extends ISchemaValueOpts<ISchemaConstFloat>, ISchemaConstFloatOpts {}

/**
 * Schema: Float value
 *
 * @param opts Schema options
 */
export function SchemaValueFloat(opts: ISchemaValueFloatOpts): ISchemaValue<ISchemaConstFloat> {

	return SchemaValue(SchemaConstFloat(opts), opts);

}