/**
 * useToggle HAE hook
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { useCallback, useState } from "react";

export function useToggle(defaultValue = false): [active: boolean, toggle: () => void, activate: () => void, deactivate: () => void] {
	const [ active, setActive ] = useState(defaultValue);

	const toggle = useCallback(() => {
		setActive((prevState) => !prevState);
	}, [ setActive ]);

	const activate = useCallback(() => {
		setActive(true);
	}, [ setActive ]);

	const deactivate = useCallback(() => {
		setActive(false);
	}, [ setActive ]);

	return [ active, toggle, activate, deactivate ];
}
