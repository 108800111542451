/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TEditorApiAppMetadataResponse } from "../api";
import { MOCKED_APP_ID, MOCKED_APP_NAME, MOCKED_EMAIL, MOCKED_IMAGE, MOCKED_PERMISSIONS } from "./MockedConst";

export const MOCKED_APP_META_DATA = {
	gitOrganization: 'demo.org',
	gitRepository: 'demo.repo',
	editorUrl: 'editor-demo.adapptio.com',
	workerApiUrl: 'api-demo.adapptio.com',
	sandboxUrl: null,
	imgId: 'demo.image',
	image: MOCKED_IMAGE,
	slug: MOCKED_APP_NAME,
	orgSlug: 'adapptio-demo',
	emailWhiteList: null,
	deployEnabled: false,
	basedOnTemplate: 'Blank',
	appEnvsCount: 2,
	appId: MOCKED_APP_ID,
	name: MOCKED_APP_NAME,
	description: null,
	enabled: true,
	starred: false,
	icon: null,
	appImage: null,
	isTemplate: true,
	isPublicTemplate: false,
	forksCount: 0,
	likesCount: 0,
	myLike: false,
	recentActivity: new Date().toISOString(),
	orgId: 'demo.org.id',
	orgName: 'demo.org',
	imageUpdateStrategy: 'Latest',
	permissions: MOCKED_PERMISSIONS,
	created: new Date().toISOString(),
	createdBy: MOCKED_EMAIL,
	modified: new Date().toISOString(),
	modifiedBy: MOCKED_EMAIL
} as TEditorApiAppMetadataResponse;