/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IScope, TGetBlueprintSchemaSpec } from "@hexio_io/hae-lib-blueprint";
import { TBlueprintActionNodeTypes, TBlueprintEndpointNodeTypes } from "../blueprints";
import { IExecutionOptions } from "../managers/IExecutionOptions";
import { IActionNodeResult } from "./ActionManager";
import { FlowNodeHandlerResult } from "./FlowNodeHandlers";
import { ACTION_RESULT_TYPE, IActionResultErrorObject, TActionResult } from "./IActionResult";

export const createActionNodeResult = (
	handlerResult: FlowNodeHandlerResult,
	params: {
		nodeId: string;
		localScope: IScope;
		opts: TGetBlueprintSchemaSpec<TBlueprintActionNodeTypes[keyof TBlueprintActionNodeTypes]["opts"]>;
		nodeType: keyof TBlueprintActionNodeTypes | keyof TBlueprintEndpointNodeTypes;
		invocationOrder: number;
	}
): IActionNodeResult => {

	return {
		nodeId: params.nodeId,
		outputName: handlerResult.outputName,
		data: handlerResult.data,
		type: handlerResult.type,
		debug: {
			additionalInfo: handlerResult.additionalInfo || {},
			inputScope: {
				globalData: params.localScope.globalData,
				localData: params.localScope.localData
			} as any,
			nodeOpts: params.opts,
			nodeType: params.nodeType,
			invocationOrder: params.invocationOrder,
			output: {
				name: handlerResult.outputName,
				data: handlerResult.data,
				type: handlerResult.type
			}
		}
	}
}

export const createActionErrorResult = (
	params: Omit<IActionResultErrorObject, "reqId" | "traceId" | "date">,
	config: IExecutionOptions,
	debug: any
): TActionResult => {

	const {
		reason,
		errorName,
		message,
		nodeType,
		nodeId,
		nodeVarName,
		details,
		integrationId,
		integrationType,
		functionName,
		httpStatus,
		data,
		httpStatusText,
		httpClientErrorCode
	} = params;

	const moreDetails = {};
	for (const name of Object.keys(params)) {
		const value = params[name];
		if (!value && typeof value !== "boolean") {
			moreDetails[name] = value;
		} else if (["boolean", "number", "string"].includes(typeof value)) {
			moreDetails[name] = value;
		} else if (Array.isArray(value)) {
			moreDetails[name] = value.filter((val) => ["boolean", "number", "string"].includes(typeof val));
		}
	}

	return {
		status: ACTION_RESULT_TYPE.ERROR,
		error: {
			...moreDetails,
			reason,
			errorName,
			message,
			nodeType: nodeType || null,
			nodeId: nodeId || null,
			nodeVarName: nodeVarName || null,
			details: details || [],
			reqId: config.reqId || null,
			traceId: config.traceId || null,
			date: new Date(),
			integrationId: integrationId || null,
			integrationType: integrationType || null,
			functionName: functionName || null,
			httpStatus: httpStatus || null,
			data: data || null,
			httpStatusText: httpStatusText || null,
			httpClientErrorCode: httpClientErrorCode || null
		},
		debug: config.debug === true ? debug : undefined
	};

}