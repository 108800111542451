/**
 * Text Field Autocomplete component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { Key } from "ts-key-enum";

import Fuse from "fuse.js";

import {
	ARROW_KEYS_NAVIGATION_TYPE,
	Button,
	ClassList,
	IBaseProps,
	IPopupRefProps,
	Popup,
	useArrowKeysNavigation
} from "@hexio_io/hae-lib-components";
import { isNonEmptyArray } from "@hexio_io/hae-lib-shared";

/**
 * Text Field Autocomplete Items type
 */
export type TTextFieldAutocompleteItems = string[];

/**
 * Text Field Autocomplete ref props
 */
export interface ITextFieldAutocompleteRefProps {
	/**
	 * Shows Autocomplete popup
	 */
	show: () => void;

	/**
	 * Hides Autocomplete popup
	 */
	hide: () => void;

	/**
	 * Popup
	 */
	//popup: IPopupRefProps;

	/**
	 * Input Focus handler
	 */
	inputOnFocus: () => void;

	/**
	 * Input Blur handler
	 */
	inputOnBlur: () => void;

	/**
	 * Input Key Down handler
	 */
	inputOnKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

/**
 * Text Field Autocomplete props
 */
export interface ITextFieldAutocompleteProps extends IBaseProps {
	/** Items */
	items: TTextFieldAutocompleteItems;

	/** Value */
	value: string;

	/** Set value method */
	setValue: (value: string) => void;

	/** Min value length */
	minValueLength: number;

	/** Content ref */
	contentRef: React.MutableRefObject<HTMLDivElement>;

	/** Input id */
	inputId: string;

	/** Input ref */
	inputRef: React.MutableRefObject<HTMLInputElement>;
}

/**
 * Text Field Autocomplete component
 */
export const TextFieldAutocomplete = React.forwardRef<ITextFieldAutocompleteRefProps, ITextFieldAutocompleteProps>((props, ref) => {
	const { items: allItems, value, setValue, minValueLength, contentRef, inputId, inputRef, componentPath, componentMode } = props;

	const [ items, setItems ] = React.useState<TTextFieldAutocompleteItems>([]);
	const [ selectedValue, setSelectedValue ] = React.useState("");

	const popupRef = React.useRef<IPopupRefProps>();

	const show = React.useCallback(() => {
		if (popupRef.current && !popupRef.current.isVisible()) {
			popupRef.current.show(contentRef.current);
		}
	}, []);

	const hide = React.useCallback(() => {
		if (popupRef.current && popupRef.current.isVisible()) {
			popupRef.current.hide();
		}
	}, []);

	const inputOnFocus = React.useCallback(() => {
		show();
	}, []);

	const inputOnBlur = React.useCallback(() => {
		if (popupRef.current) {
			setTimeout(() => {
				if (!popupRef.current.element.contains(document.activeElement)) {
					hide();
				}
			});
		}
	}, []);

	const inputOnKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
		if (popupRef.current) {
			const up = event.key === Key.ArrowUp;
			const down = event.key === Key.ArrowDown;
	
			if (up || down) {
				event.preventDefault();

				show();
			}
		}
	}, []);

	React.useImperativeHandle(ref, () => ({ show, hide, inputOnFocus, inputOnBlur, inputOnKeyDown }));

	React.useEffect(() => {
		let fuseTimeout: NodeJS.Timeout;

		if (value == null || value.length < minValueLength) {
			setItems([]);
		}
		else if (isNonEmptyArray<string>(allItems) && value !== selectedValue) {
			fuseTimeout = setTimeout(() => {
				const fuse = new Fuse(allItems, {
					minMatchCharLength: minValueLength,
					threshold: 0.2
				});

				const newItems = fuse.search(value).map((item) => item.item);

				setItems(newItems.length !== 1 || !newItems.includes(value) ? newItems : []);
				setSelectedValue("");
			}, 200);
		}

		return () => {
			if (fuseTimeout) {
				clearTimeout(fuseTimeout);
			}
		}
	}, [ allItems, value ]);

	React.useEffect(() => {
		if (items.length && inputRef.current === document.activeElement) {
			show();
		}
	}, [ show, items.length ]);

	const arrowKeysNavigation = useArrowKeysNavigation(
		ARROW_KEYS_NAVIGATION_TYPE.VERTICAL,
		"",
		`#${inputId}, .cmp-field__autocomplete-item-button-input-${inputId}`
	);

	React.useEffect(() => {
		let _windowKeyDownHandler: (event: KeyboardEvent) => void;

		if (items.length) {
			_windowKeyDownHandler = (event: KeyboardEvent) => {
				if (popupRef.current?.isVisible()) {
					if (event.key === Key.Escape) {
						event.preventDefault();

						inputRef.current.focus();
						popupRef.current.hide();
					}
					else {
						arrowKeysNavigation(event);
					}
				}
			}

			window.addEventListener("keydown", _windowKeyDownHandler);
		}

		if (popupRef.current?.isVisible()) {
			popupRef.current.fixPosition();
		}

		return () => {
			if (_windowKeyDownHandler) {
				window.removeEventListener("keydown", _windowKeyDownHandler);
			}
		}
	}, [ popupRef.current?.isVisible(), items.length ]);

	// Event handlers

	const _popupKeyDownHandler = React.useCallback((event: React.KeyboardEvent<HTMLElement>) => {
		if (event.key === Key.Tab) {
			event.preventDefault();

			inputRef.current.focus();
		}
	}, []);

	const _itemButtonClickHandler = React.useCallback((value: string) => {
		setValue(value);
		setSelectedValue(value);

		setTimeout(() => {
			inputRef.current.focus();
			popupRef.current.hide();
		});
	}, [ setValue ]);

	return items.length ?
		<Popup
			ref={popupRef}
			componentPath={componentPath}
			componentMode={componentMode}
			classList={new ClassList("cmp-field__autocomplete")}
			anchorVisible={true}
			onKeyDownCapture={_popupKeyDownHandler}
		>
			<ul className="cmp-field__autocomplete-items">
				{
					items.map((item) => {
						const itemClassList = ClassList.fromObject({
							"cmp-field__autocomplete-item": true,
							"cmp-field__autocomplete-item--selected": false // item.separated
						});

						return (
							<li key={item} className={itemClassList.toClassName()}>
								<Button
									style="CLEAR"
									value={item}
									labelText={{ value: item }}
									tabIndex={-1}
									componentPath={[ ...componentPath, item, "button" ]}
									componentMode={componentMode}
									classList={new ClassList(
										"cmp-field__autocomplete-item-button",
										`cmp-field__autocomplete-item-button-input-${inputId}`
									)}
									onClick={_itemButtonClickHandler}
								/>
							</li>
						);
					})
				}
			</ul>
		</Popup> :
		null;
});
