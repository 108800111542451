/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IScope } from "../Shared/Scope";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaEditorMetaData, TBlueprintSchemaParentNode, TGenericBlueprintSchema } from "./IBlueprintSchema";
import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";
import { TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";

/**
 * Result of a node compilation
 */
export interface IModelNodeCompileResult {
	code: string;
	isScoped: boolean;
}

export enum MODEL_CHANGE_TYPE {
	VALUE = "value",
	STRUCTURE = "struct",
	METADATA = "meta",
	RUNTIME_INFO = "runtimeInfo",
	PARENT = "parent"
}

export interface IModelChangeEvent {
	modelNode: TGenericModelNode;
	changeType: MODEL_CHANGE_TYPE;
}

/**
 * Generic model node
 */
export interface IModelNode<TSchema> {
	/** Reference to a schema that creates this node */
	schema: TSchema;

	/** Dev context */
	ctx: DesignContext;

	/** Unique Node ID (is preserved when cloning, can be used by editor) */
	nodeId: number;

	/** Last observed scope from the last render call */
	lastScopeFromRender?: IScope;

	/** Change event - emitted when model node has changed, eg. it's value, state, parent, etc. */
	changeEvent: TSimpleEventEmitter<IModelChangeEvent>;

	/** If the value is valid */
	isValid: boolean;

	/** Last validation errors */
	validationErrors: IBlueprintSchemaValidationError[];

	/** If the value, when initialized, had a required constraint valid */
	initRequiredValid: boolean;

	/** If the model was destroyed */
	destroyed: boolean;

	/** Editor meta-data */
	editorMetaData: ISchemaEditorMetaData;

	/** Parent model node */
	parent: TBlueprintSchemaParentNode;
}

/**
 * Generic scalar model node
 */
export interface IModelNodeScalar<TSchema, TValue> extends IModelNode<TSchema> {
	/** Constant value */
	value: TValue;
}

/**
 * Type shortcut for Model node with unknown type parameters
 */
export type TGenericModelNode = IModelNode<TGenericBlueprintSchema>;

/**
 * Object containing meta-data about model node
 */
export interface IModelNodeInfo {
	icon: string;
	label: string;
}
