/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IDataSourceDefRegistry } from "../registries";
import { DataSourceVariable } from "./DataSourceVariable";
import { DataSourceAction } from "./DataSourceAction";

export * from "./DataSourceVariable";
export * from "./DataSourceAction";

/**
 * Registers core data sources
 *
 * @param registry Data source definition registry
 */
export function registerCoreDataSources(registry: IDataSourceDefRegistry): void {

	registry.register(DataSourceVariable);
	registry.register(DataSourceAction);

}
