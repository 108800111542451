/**
 * OptionsFieldSelect HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useEffect } from "react";

import { Key } from "ts-key-enum";

import {
	Button, ClassList, Icon, ICON_NAME, IPopupRefProps, Label, ARROW_KEYS_NAVIGATION_TYPE, Popup, Text, useArrowKeysNavigation, LoadingInfo
} from "@hexio_io/hae-lib-components";
import { IOptionsFieldBaseProps } from "./optionsFieldTypes";
import { OPTIONS_FIELD_CUSTOM_VALUE_TYPE } from "../../Enums/OPTIONS_FIELD_CUSTOM_VALUE_TYPE";
import { isString } from "@hexio_io/hae-lib-shared";

export interface IOptionsFieldSelectProps extends IOptionsFieldBaseProps {
	loading: boolean;
	placeholder?: string;
}

export const OptionsFieldSelect: React.FC<IOptionsFieldSelectProps> = ({
	id,
	items,
	selectedItem,
	value,
	allowCustomValue,
	allowCustomValueTypeValue,
	customValue,
	componentPath,
	componentMode,
	required,
	readOnly,
	disabled,
	loading,
	placeholder,
	onSelect
}) => {
	const [ active, setActive ] = React.useState(false);

	const elementRef = React.useRef<HTMLDivElement>();
	const popupRef = React.useRef<IPopupRefProps>();
	const searchRef = React.useRef<HTMLInputElement>();

	const clearButton = !required && !readOnly && !disabled && isString(value);

	const buttonClassList = new ClassList("cmp-field__options-button");

	buttonClassList.addModifiers({
		active,
		"with-clear-button": clearButton
	});

	// Event handlers

	const _buttonClickHandler = React.useCallback(() => {
		if (!popupRef.current) {
			return;
		}

		// setActive(popupRef.current.toggle(elementRef.current));
		popupRef.current.show(elementRef.current)
		setActive(true);
	}, []);

	useEffect(() => {
		if(!searchRef?.current) {
			return
		}

		if(active) {
			searchRef.current.focus()
		}
	}, [active])

	const _popupKeyDownHandler = useArrowKeysNavigation(ARROW_KEYS_NAVIGATION_TYPE.VERTICAL, ".cmp-field__options-popup");

	const _listClickHandler = React.useCallback((event: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
		const { target } = event;

		if (target instanceof Element && !target.closest("button")) {
			event.stopPropagation();
			setActive(false)
		}
	}, [setActive]);

	const _customValueKeyDownHandler = React.useMemo(() => {
		if (!allowCustomValueTypeValue || allowCustomValueTypeValue === OPTIONS_FIELD_CUSTOM_VALUE_TYPE.SEARCH) {
			return;
		}

		return (event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === Key.Enter) {
				const { currentTarget } = event;

				onSelect(currentTarget?.value);

				setTimeout(() => {
					popupRef.current?.hide();
				});
			}
		};
	}, [ onSelect, allowCustomValueTypeValue, setActive ]);

	const _customValueChangeHandler = React.useMemo(() => {
		if (!allowCustomValueTypeValue) {
			return;
		}

		return (event: React.SyntheticEvent<HTMLInputElement>) => {
			onSelect(event.currentTarget.value, true);
		};
	}, [ onSelect, allowCustomValueTypeValue, setActive ]);

	const _itemButtonClickHandler = React.useCallback((value: string) => {
		onSelect(value);

		setTimeout(() => {
			popupRef.current?.hide();
			setActive(false)
		});
	}, [ onSelect, setActive ]);


	const _inputClickHadler = React.useCallback((value: boolean) => {
		setActive(value)
	}, [setActive])

	return (
		<div ref={elementRef} className="cmp-field__options">
			<button
				id={id}
				type="button"
				disabled={disabled || readOnly}
				className={buttonClassList.toClassName()}
				aria-haspopup="listbox"
				aria-expanded={popupRef.current?.isVisible()}
				onClick={_buttonClickHandler}
			>
				<Label
					text={
						selectedItem?.labelText?.value ?
							selectedItem.labelText :
							{
								...selectedItem?.labelText,
								value: selectedItem?.value ||
									(
										allowCustomValueTypeValue &&
										allowCustomValueTypeValue !== OPTIONS_FIELD_CUSTOM_VALUE_TYPE.SEARCH &&
										customValue
									) ||
									value ||
									placeholder
							}
					}
					icon={
						selectedItem?.labelIcon?.source ?
							{ ...selectedItem.labelIcon, size: "SMALL" } :
							undefined
					}
					componentPath={[ ...componentPath, "button-label" ]}
					componentMode={componentMode}
					forceRender={true}
				>
					{
						allowCustomValue && active ?
							<div className={ClassList.fromObject({"cmp-field__options-button--seacrh": true}).toClassName()}>
								<input
									className="cmp-field__options-custom-value"
									placeholder={allowCustomValue.placeholder}
									value={customValue}
									ref={searchRef}
									onKeyDown={_customValueKeyDownHandler}
									onChange={_customValueChangeHandler}
								/>
							</div> :
							null
					}
					<Icon
						source={ICON_NAME.MENU_DOWN}
						size="MEDIUM"
						componentPath={[ ...componentPath, "button-icon" ]}
						componentMode={componentMode}
						classList={new ClassList("cmp-field__options-button-icon")}
					/>
				</Label>
			</button>

			{
				loading ?
					<LoadingInfo classList={new ClassList("cmp-field__options-loading-info")} /> :
					clearButton ?
						<Button
							style="CLEAR"
							value={null} // JSON.stringify("")
							labelIcon={{ source: ICON_NAME.CLOSE, size: "SMALL" }}
							componentPath={[ ...componentPath, "clear-button" ]}
							componentMode={componentMode}
							classList={new ClassList("cmp-field__options-clear-button")}
							onClick={_itemButtonClickHandler}
						/> :
						null
			}

			<Popup
				ref={popupRef}
				role="listbox"
				componentPath={[ ...componentPath, "popup" ]}
				componentMode={componentMode}
				classList={new ClassList("cmp-field__options-popup")}
				setActive={_inputClickHadler}
				// focus=".cmp-field__options-item--selected button, .cmp-field__options-item--selected input"
				onKeyDownCapture={_popupKeyDownHandler}
			>
				<ul className="cmp-field__options-list" onClickCapture={_listClickHandler}>
					{
						items.map((item, index) => (
							<li
								key={`${item.value}-${index}`}
								className={ClassList.fromObject({
									"cmp-field__options-item": true,
									"cmp-field__options-item--selected": item.value === selectedItem?.value
								}).toClassName()}
							>
								<Button
									style="CLEAR"
									labelText={
										item.labelText?.value ?
											item.labelText :
											{ ...item.labelText, value: item.value }
									}
									labelIcon={
										item.labelIcon?.source ?
											{ ...item.labelIcon, size: "SMALL" } :
											undefined
									}
									value={item.value}
									aria-selected={item.value === selectedItem?.value}
									componentPath={[ ...componentPath, `${index}`, "button" ]}
									componentMode={componentMode}
									classList={new ClassList("cmp-field__options-item-button")}
									onClick={_itemButtonClickHandler}
								>
									{
										item.descriptionText?.value ?
											<Text
												{...item.descriptionText}
												classList={new ClassList("cmp-field__description")}
												componentPath={[ ...componentPath, `${index}`, "button", "description" ]}
												componentMode={componentMode}
											/> :
											null
									}
								</Button>
							</li>
						))
					}
				</ul>
			</Popup>
		</div>
	);
};
