/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import React from "react";
import * as Sentry from "@sentry/react";
import { ErrorBoundaryProps } from "@sentry/react";

export interface IErrorBoundaryProps {
	fallback?: React.ReactNode;
	beforeCapture?: ErrorBoundaryProps["beforeCapture"]
}

export const ErrorBoundary: React.FC<IErrorBoundaryProps> = ({ children, fallback, beforeCapture }) => (
	<Sentry.ErrorBoundary fallback={fallback ? <>{fallback}</> : undefined} beforeCapture={beforeCapture}>{children}</Sentry.ErrorBoundary>
);
