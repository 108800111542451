/**
 * Pagination schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	TSchemaConstObjectPropsSpec,
} from "@hexio_io/hae-lib-blueprint";
import { dimensionPattern } from "../RegExp/dimensionPattern";

import { termsEditor } from "../terms";
import { BorderRadiusSchema } from "./BorderRadius";
import { BoxShadowSchema } from "./BoxShadow";
import { BackgroundColorSchema, ForegroundColorSchema } from "./Color";
import { propGroups } from "./propGroups";

/**
 * Pagination Style props
 */
export const PaginationStyleProps = {
	
	backgroundColor: BP.Prop(BackgroundColorSchema({
		alias: "backgroundColor_pagination",
		default: "TRANSPARENT"
	}), 10, propGroups.style),

	foregroundColor: BP.Prop(ForegroundColorSchema({
		alias: "foregroundColor_pagination",
		default: "TEXT"
	}), 20, propGroups.style),

	borderRadius: BP.Prop(BorderRadiusSchema(), 30, propGroups.style),

	boxShadow: BP.Prop(BoxShadowSchema(), 40, propGroups.style),

	item: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.pagination.item,
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: {
				borderRadius: BP.Prop(BorderRadiusSchema(), 10),

				size: BP.Prop(BP.ResponsiveValue({
					...termsEditor.schemas.pagination.itemSize,
					value: BP.String({
						default: "",
						constraints: {
							...termsEditor.schemas.common.dimensionConstraints,
							pattern: dimensionPattern
						}
					})
				}), 20),

				currentState: BP.Prop(BP.OptGroup({
					...termsEditor.schemas.pagination.itemCurrentState,
					enabledOpts: {
						default: false,
						fallbackValue: false,
					},
					value: BP.Object({
						props: {
							backgroundColor: BP.Prop(BackgroundColorSchema({
								alias: "backgroundColor_paginationItemCurrentState",
								default: "SECONDARY"
							}), 10),
			
							foregroundColor: BP.Prop(ForegroundColorSchema({
								alias: "foregroundColor_paginationItemCurrentState",
								default: "WHITE"
							}), 20)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				}), 30)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 50, propGroups.style)

}

/**
 * Pagination props
 */
export const PaginationProps = {

	...PaginationStyleProps,

	items: BP.Prop(BP.Integer({
		...termsEditor.schemas.pagination.items,
		default: 0,
		fallbackValue: null,
		constraints: {
			min: 0,
			required: true
		}
	}), 0, propGroups.common),

	limit: BP.Prop(BP.Integer({
		...termsEditor.schemas.pagination.limit,
		default: 10,
		fallbackValue: 10,
		constraints: {
			min: 1,
			required: true
		}
	}), 10, propGroups.common),

	page: BP.Prop(BP.Integer({
		...termsEditor.schemas.pagination.page,
		default: 1,
		fallbackValue: 1,
		constraints: {
			min: 1,
			required: true
		}
	}), 20, propGroups.common),

	maxDisplayedPages: BP.Prop(BP.Integer({
		...termsEditor.schemas.pagination.maxDisplayedPages,
		default: 7,
		fallbackValue: 7,
		constraints: {
			min: 3,
			required: true
		}
	}), 30, propGroups.common)

};

/**
 * Pagination props type
 */
export type TPaginationProps = Partial<TSchemaConstObjectPropsSpec<typeof PaginationProps>>;
