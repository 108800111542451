/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import { isNil, isNonEmptyObject } from "@hexio_io/hae-lib-shared";
import {
	ISchemaThemeRefModel,
	ISchemaBuilderAnyModel,
	ISchemaBuilderArrayModel,
	ISchemaBuilderBooleanModel,
	ISchemaBuilderDataModel,
	ISchemaBuilderDateModel,
	ISchemaBuilderEnumModel,
	ISchemaBuilderFloatModel,
	ISchemaBuilderIntegerModel,
	ISchemaBuilderMapModel,
	ISchemaBuilderObjectModel,
	ISchemaBuilderPasswordModel,
	ISchemaBuilderStringModel,
	ISchemaCommentsModel,
	ISchemaComponentListModel,
	ISchemaComponentModel,
	ISchemaConstAnyModel,
	ISchemaConstArrayModel,
	ISchemaConstBooleanModel,
	ISchemaConstEnumModel,
	ISchemaConstFloatModel,
	ISchemaConstIntegerModel,
	ISchemaConstMapModel,
	ISchemaConstMapModelEntry,
	ISchemaConstObjectModel,
	ISchemaConstPasswordModel,
	ISchemaConstSecretModel,
	ISchemaConstStringModel,
	ISchemaDataSourceModel,
	ISchemaExpressionModel,
	ISchemaFlowNodeListModel,
	ISchemaFlowNodeModel,
	ISchemaFlowNodeTypeDefinitionMap,
	ISchemaIconNameModel,
	ISchemaIntegrationRefModel,
	ISchemaOneOfModel,
	ISchemaOptionalGroupModel,
	ISchemaRecursiveModel,
	ISchemaResponsiveValueModel,
	ISchemaRouteRefModel,
	ISchemaScopedTemplateModel,
	ISchemaStringTranslateModel,
	ISchemaStringWithConstModel,
	ISchemaValueModel,
	ISchemaViewRefModel,
	TSchemaConstEnumValueSchema,
	TSchemaConstObjectProps,
	TSchemaOneOfTypes,
	ISchemaActionRefModel
} from "../schemas";
import { IModelNode } from "../Schema/IModelNode";
import { DesignContext } from "../Context/DesignContext";
import { TGenericBlueprintSchema } from "../Schema/IBlueprintSchema";
import {
	isSchemaActionRef,
	isSchemaBuilderAny,
	isSchemaBuilderArray,
	isSchemaBuilderBoolean,
	isSchemaBuilderData,
	isSchemaBuilderDate,
	isSchemaBuilderEnum,
	isSchemaBuilderFloat,
	isSchemaBuilderInteger,
	isSchemaBuilderMap,
	isSchemaBuilderObject,
	isSchemaBuilderPassword,
	isSchemaBuilderString,
	isSchemaComments,
	isSchemaComponent,
	isSchemaComponentList,
	isSchemaConstAny,
	isSchemaConstArray,
	isSchemaConstBoolean,
	isSchemaConstEnum,
	isSchemaConstFloat,
	isSchemaConstInteger,
	isSchemaConstMap,
	isSchemaConstObject,
	isSchemaConstPassword,
	isSchemaConstSecret,
	isSchemaConstString,
	isSchemaDataSource, isSchemaDataSourceList,
	isSchemaExpression,
	isSchemaFlowNode,
	isSchemaFlowNodeList,
	isSchemaIconName,
	isSchemaIntegrationRef,
	isSchemaOneOf,
	isSchemaOptGroup,
	isSchemaRecursive,
	isSchemaResponsiveValue,
	isSchemaRouteRef,
	isSchemaScopedTemplate,
	isSchemaStringTranslate,
	isSchemaStringWithConst,
	isSchemaThemeRef,
	isSchemaValue,
	isSchemaViewRef
} from "./schema";
import { ISchemaDataSourceListModel } from "../schemas/SchemaDataSourceList";

export function isModel<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is IModelNode<Schema> {
	return (
		isNonEmptyObject(value) &&
		!isNil(value.nodeId) &&
		isNonEmptyObject(value.schema) &&
		value.ctx instanceof DesignContext
	);
}

export function isSchemaActionRefModel(value: unknown): value is ISchemaActionRefModel {
	return isModel(value) && isSchemaActionRef(value.schema);
}

export function isSchemaThemeRefModel(value: unknown): value is ISchemaThemeRefModel {
	return isModel(value) && isSchemaThemeRef(value.schema);
}

export function isSchemaBuilderAnyModel(value: unknown): value is ISchemaBuilderAnyModel {
	return isModel(value) && isSchemaBuilderAny(value.schema);
}

export function isSchemaBuilderArrayModel(value: unknown): value is ISchemaBuilderArrayModel {
	return isModel(value) && isSchemaBuilderArray(value.schema);
}

export function isSchemaBuilderBooleanModel(value: unknown): value is ISchemaBuilderBooleanModel {
	return isModel(value) && isSchemaBuilderBoolean(value.schema);
}

export function isSchemaBuilderDataModel(value: unknown): value is ISchemaBuilderDataModel {
	return isModel(value) && isSchemaBuilderData(value.schema);
}

export function isSchemaBuilderDateModel(value: unknown): value is ISchemaBuilderDateModel {
	return isModel(value) && isSchemaBuilderDate(value.schema);
}

export function isSchemaBuilderEnumModel(value: unknown): value is ISchemaBuilderEnumModel {
	return isModel(value) && isSchemaBuilderEnum(value.schema);
}

export function isSchemaBuilderFloatModel(value: unknown): value is ISchemaBuilderFloatModel {
	return isModel(value) && isSchemaBuilderFloat(value.schema);
}

export function isSchemaBuilderIntegerModel(value: unknown): value is ISchemaBuilderIntegerModel {
	return isModel(value) && isSchemaBuilderInteger(value.schema);
}

export function isSchemaBuilderMapModel(value: unknown): value is ISchemaBuilderMapModel {
	return isModel(value) && isSchemaBuilderMap(value.schema);
}

export function isSchemaBuilderObjectModel(value: unknown): value is ISchemaBuilderObjectModel {
	return isModel(value) && isSchemaBuilderObject(value.schema);
}

export function isSchemaBuilderPasswordModel(value: unknown): value is ISchemaBuilderPasswordModel {
	return isModel(value) && isSchemaBuilderPassword(value.schema);
}

export function isSchemaBuilderStringModel(value: unknown): value is ISchemaBuilderStringModel {
	return isModel(value) && isSchemaBuilderString(value.schema);
}

export function isSchemaCommentsModel(value: unknown): value is ISchemaCommentsModel {
	return isModel(value) && isSchemaComments(value.schema);
}

export function isSchemaComponentModel(value: unknown): value is ISchemaComponentModel {
	return isModel(value) && isSchemaComponent(value.schema);
}

export function isSchemaComponentListModel(value: unknown): value is ISchemaComponentListModel {
	return isModel(value) && isSchemaComponentList(value.schema);
}

export function isSchemaConstAnyModel(value: unknown): value is ISchemaConstAnyModel {
	return isModel(value) && isSchemaConstAny(value.schema);
}

export function isSchemaConstArrayModel<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaConstArrayModel<Schema> {
	return isModel(value) && isSchemaConstArray(value.schema);
}

export function isSchemaConstBooleanModel(value: unknown): value is ISchemaConstBooleanModel {
	return isModel(value) && isSchemaConstBoolean(value.schema);
}

export function isSchemaConstEnumModel<
	TValueSchema extends TSchemaConstEnumValueSchema = TSchemaConstEnumValueSchema
>(value: unknown): value is ISchemaConstEnumModel<TValueSchema> {
	return isModel(value) && isSchemaConstEnum<TValueSchema>(value.schema);
}

export function isSchemaConstFloatModel(value: unknown): value is ISchemaConstFloatModel {
	return isModel(value) && isSchemaConstFloat(value.schema);
}

export function isSchemaConstIntegerModel(value: unknown): value is ISchemaConstIntegerModel {
	return isModel(value) && isSchemaConstInteger(value.schema);
}

export function isSchemaConstMapModel<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaConstMapModel<Schema> {
	return isModel(value) && isSchemaConstMap(value.schema);
}

export function isSchemaConstMapModelEntry<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaConstMapModelEntry<Schema> {
	return isNonEmptyObject(value) && isSchemaConstStringModel(value.key) && isModel(value.value);
}

export function isSchemaConstObjectModel<
	SchemaProps extends TSchemaConstObjectProps = TSchemaConstObjectProps
>(value: unknown): value is ISchemaConstObjectModel<SchemaProps> {
	return isModel(value) && isSchemaConstObject(value.schema);
}

export function isSchemaConstPasswordModel(value: unknown): value is ISchemaConstPasswordModel {
	return isModel(value) && isSchemaConstPassword(value.schema);
}

export function isSchemaConstSecretModel(value: unknown): value is ISchemaConstSecretModel {
	return isModel(value) && isSchemaConstSecret(value.schema);
}

export function isSchemaConstStringModel(value: unknown): value is ISchemaConstStringModel {
	return isModel(value) && isSchemaConstString(value.schema);
}

export function isSchemaDataSourceModel(value: unknown): value is ISchemaDataSourceModel {
	return isModel(value) && isSchemaDataSource(value.schema);
}

export function isSchemaDataSourceListModel(value: unknown): value is ISchemaDataSourceListModel {
	return isModel(value) && isSchemaDataSourceList(value.schema);
}

export function isSchemaExpressionModel(value: unknown): value is ISchemaExpressionModel {
	return isModel(value) && isSchemaExpression(value.schema);
}

export function isSchemaFlowNodeModel<
	TypeDefinitionMap extends ISchemaFlowNodeTypeDefinitionMap = ISchemaFlowNodeTypeDefinitionMap
>(value: unknown): value is ISchemaFlowNodeModel<TypeDefinitionMap> {
	return isModel(value) && isSchemaFlowNode(value.schema);
}

export function isSchemaFlowNodeListModel<
	TypeDefinitionMap extends ISchemaFlowNodeTypeDefinitionMap = ISchemaFlowNodeTypeDefinitionMap
>(value: unknown): value is ISchemaFlowNodeListModel<TypeDefinitionMap> {
	return isModel(value) && isSchemaFlowNodeList(value.schema);
}

export function isSchemaIconNameModel(value: unknown): value is ISchemaIconNameModel {
	return isModel(value) && isSchemaIconName(value.schema);
}

export function isSchemaIntegrationRefModel(value: unknown): value is ISchemaIntegrationRefModel {
	return isModel(value) && isSchemaIntegrationRef(value.schema);
}

export function isSchemaOneOfModel<TTypes extends TSchemaOneOfTypes = TSchemaOneOfTypes>(
	value: unknown
): value is ISchemaOneOfModel<TTypes> {
	return isModel(value) && isSchemaOneOf(value.schema);
}

export function isSchemaOptGroupModel<TSchema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaOptionalGroupModel<TSchema> {
	return isModel(value) && isSchemaOptGroup<TSchema>(value.schema);
}

export function isSchemaRecursiveModel<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaRecursiveModel<Schema> {
	return isModel(value) && isSchemaRecursive(value.schema);
}

export function isSchemaResponsiveValueModel<
	Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema
>(value: unknown): value is ISchemaResponsiveValueModel<Schema> {
	return isModel(value) && isSchemaResponsiveValue(value.schema);
}

export function isSchemaRouteRefModel(value: unknown): value is ISchemaRouteRefModel {
	return isModel(value) && isSchemaRouteRef(value.schema);
}

export function isSchemaScopedTemplateModel<Schema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaScopedTemplateModel<Schema> {
	return isModel(value) && isSchemaScopedTemplate(value.schema);
}

export function isSchemaStringTranslateModel(value: unknown): value is ISchemaStringTranslateModel {
	return isModel(value) && isSchemaStringTranslate(value.schema);
}

export function isSchemaStringWithConstModel(value: unknown): value is ISchemaStringWithConstModel {
	return isModel(value) && isSchemaStringWithConst(value.schema);
}

export function isSchemaValueModel<TSchema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	value: unknown
): value is ISchemaValueModel<TSchema> {
	return isModel(value) && isSchemaValue(value.schema);
}

export function isSchemaViewRefModel(value: unknown): value is ISchemaViewRefModel {
	return isModel(value) && isSchemaViewRef(value.schema);
}
