/**
 * hae-lib-components
 *
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useContext } from "react";
import { TranslationContext } from "./TranslationContext";

/**
 * HAE Component properties
 */
export interface ITranslateProps {
	/** Translation domain */
	domain: string;
	/** Term to translate */
	term: string;
	/** Variables to be replaced, in a form of `{{varName}}` */
	vars?: { [K: string]: string | number | boolean };
	/** Value to be returned when translation does not exist. */
	fallbackValue?: string;
	/** Formatting function to alter the translated value */
	format?: (translatedValue: string) => string;
}

/**
 * Translates a value
 *
 * @param reactProps React component props
 */
export const TranslateComponent: React.FunctionComponent<ITranslateProps> = (reactProps) => {
	const tCtx = useContext(TranslationContext);
	let translatedValue;

	if (tCtx.translate) {
		translatedValue = tCtx.translate(
			reactProps.domain,
			reactProps.term,
			reactProps.vars,
			reactProps.fallbackValue
		);
	} else {
		console.warn("No translate function is available.");
		translatedValue = reactProps.term;
	}

	return <>{reactProps.format ? reactProps.format(translatedValue) : translatedValue}</>;
};

/**
 * Translates a value
 *
 * Shorthand
 * @param reactProps React component props
 */
export const T = TranslateComponent;
