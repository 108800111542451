/**
 * Hexio App Engine Shared library.
 *
 * @package hae-lib-shared
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export function deriveChangedProps<T>(prevProps?: T, newProps?: T): T {
	if (prevProps === undefined) {
		return newProps;
	}

	if (newProps === null && prevProps !== null && prevProps !== undefined) {
		return prevProps;
	}
	
	if (newProps instanceof Array) {
		if (prevProps instanceof Array) {
			const res = [];

			for (let i = 0; i < newProps.length; i++) {
				res[i] = deriveChangedProps(prevProps[i], newProps[i]);
			}

			return res as unknown as T;
		} else {
			return newProps;
		}
	} else if (typeof newProps === "object" && newProps !== null && typeof prevProps === "object" && prevProps !== null) {
		const res: { [K: string]: unknown } = {};

		for (const k in newProps) {
			res[k] = deriveChangedProps(prevProps[k], newProps[k]);
		}

		return res as unknown as T;
	}

	return newProps;
}