/**
 * Text component terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const routes = {
	component: {
		label: "component:routes#label",
		description: "component:routes#description"
	},
	schema: {
		routes: {
			label: "component:routes.schema.routes#label",
			description: "component:routes.schema.routes#description"
		},
		routeItem: {
			label: "component:routes.schema.routeItem#label"
		},
		routeName: {
			label: "component:routes.schema.routeName#label",
			description: "component:routes.schema.routeName#description"
		},
		routeExact: {
			label: "component:routes.schema.routeExact#label",
			description: "component:routes.schema.routeExact#description"
		},
		routeStrict: {
			label: "component:routes.schema.routeStrict#label",
			description: "component:routes.schema.routeStrict#description"
		},
		routeEnabled: {
			label: "component:routes.schema.routeEnabled#label",
			description: "component:routes.schema.routeEnabled#description"
		},
		routeContent: {
			label: "component:routes.schema.routeContent#label",
			description: "component:routes.schema.routeContent#description"
		}
	},
	typeDesc: {
		routeMatch: {
			label: "component:routes.typeDesc.routeMatch#label",
		},
		routeMatchPath: {
			label: "component:routes.typeDesc.routeMatchPath#label",
			description: "component:routes.typeDesc.routeMatchPath#description",
		},
		routeMatchUrl: {
			label: "component:routes.typeDesc.routeMatchUrl#label",
			description: "component:routes.typeDesc.routeMatchUrl#description",
		},
		routeMatchIsExact: {
			label: "component:routes.typeDesc.routeMatchIsExact#label",
			description: "component:routes.typeDesc.routeMatchIsExact#description",
		},
		routeMatchParams: {
			label: "component:routes.typeDesc.routeMatchParams#label"
		}
	},
	errors: {
		unknownRouteName: "component:routes.errors.unknownRouteName"
	}
};
