/**
 * Image React component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

import { ClassList, TImageProps } from "../";
import { StyleSheet } from "../Classes/StyleSheet";

import { IMAGE_FIT, IMAGE_FIT_default } from "../Enums/IMAGE_FIT";
import { getStringEnumValue } from "../Functions/enumHelpers";
import { TElementProps } from "../Types/TElementProps";
import { TElementType } from "../Types/TElementType";
import { Icon } from "./Icon";
import { Link } from "./Link";
import { FORCE_RENDER_default, IBaseProps, IEventProps, IRenderProps } from "./props";
import { useStyleSheetRegistry } from "../Hooks/useStyleSheetRegistry";

/**
 * Image props
 */
export interface IImageProps extends IBaseProps, IRenderProps, IEventProps, TImageProps {}

/**
 * Image component
 */
export const Image: React.FunctionComponent<IImageProps> = (props) => {
	const {
		source,
		alt,
		fit,
		borderRadius,
		link,
		componentPath,
		componentMode,
		forceRender = FORCE_RENDER_default,
		onClick
	} = props;

	let elementType: TElementType = "div";
	let elementProps: TElementProps = {};

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"image", componentPath, { componentClassList: props.classList }
	);

	classList.add(`image--fit-${getStringEnumValue(IMAGE_FIT, fit, IMAGE_FIT_default)}`);

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		// Border radius

		if (source && borderRadius) {
			result.addString(`.${idClassName} > .image__image`, `border-radius: ${borderRadius};`);
		}

		return result;
	}, [ idClassName, source, borderRadius ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	if (!source && !forceRender) {
		return null;
	}

	// Link

	if (link && link.locationData?.type !== "NONE") {
		elementType = Link;
		elementProps = { ...elementProps, ...link };
	}

	// Event handlers

	if (typeof onClick === "function") {
		classList.add("image--event-click");

		if (elementType !== Link) {
			elementProps.tabIndex = 0;
		}

		elementProps.onClick = () => {
			onClick();
		}
	}

	// Resolve Class list

	if (elementType === Link) {
		elementProps.classList = classList;
	}
	else {
		elementProps.className = classList.toClassName();
	}

	return React.createElement(
		elementType,
		elementProps,
		<>
			{
				source ?
					<img className="image__image" src={source} alt={alt} /> :
					<Icon forceRender={true} componentPath={[ ...componentPath, "no-source-icon" ]} componentMode={componentMode} />
			}
		</>
	);
}
