/**
 * Container Flow enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum CONTAINER_FLOW {
	COLUMN = "column",
	ROW = "row"
}

export type CONTAINER_FLOW_string = keyof typeof CONTAINER_FLOW;

export const CONTAINER_FLOW_default: CONTAINER_FLOW_string = "COLUMN";

export const CONTAINER_FLOW_opts = {
	column: {
		icon: "mdi/view-sequential"
	},
	row: {
		icon: "mdi/view-column"
	}
};
