/**
 * Button HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	Type,
	defineElementaryComponent,
	createSubScope,
	BP
} from "@hexio_io/hae-lib-blueprint";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	Button,
	WORKING_default,
	isEventEnabled,
	IButtonClickData,
	termsEditor as HAELibComponentsTerms,
	IResolvedLink
} from "@hexio_io/hae-lib-components";
import { BaseEvents } from "../../base/BaseEvents";
import { termsEditor } from "../../terms";
import { resolveButtonType } from "./resolveButtonType";
import { resolveButtonStyle } from "./resolveButtonStyle";
import { HAEComponentButton_Props } from "./props";
import { isBoolean, isFunction } from "@hexio_io/hae-lib-shared";
import { INavigationResolver } from "@hexio_io/hae-lib-core";

export interface IButtonStateBase {
	active: boolean;
	setActive: (active: boolean) => void;
	link: IResolvedLink;
	working: boolean;
}

interface HAEComponentButton_State extends IButtonStateBase {
	specActive: boolean;
}

const HAEComponentButton_Events = {
	...BaseEvents
};

const HAEComponentButton_Definition = defineElementaryComponent<
	typeof HAEComponentButton_Props,
	HAEComponentButton_State,
	typeof HAEComponentButton_Events
>({

	...termsEditor.components.button.component,

	name: "button",

	category: "content",

	icon: "mdi/gesture-tap-button",

	events: HAEComponentButton_Events,

	docUrl: "...",

	order: 30,

	props: HAEComponentButton_Props,

	resolve: (spec, state, updateStateAsync, componentInstance, rCtx) => {
		// Active

		const specActive = spec.active;
		const active = state?.specActive === specActive ? (state?.active ?? specActive) : specActive;

		function setActive(active: boolean) {
			updateStateAsync((prevState) => ({ ...prevState, active }));
		}

		// Link

		let resolvedLink: IResolvedLink = null;

		if (spec.typeData.type === "LINK") {
			const navigationResolver = rCtx.getResolver<INavigationResolver>("navigation");

			if (navigationResolver) {
				const link = spec.typeData.value[spec.typeData.type];

				resolvedLink = navigationResolver.resolveLink(link.locationData, {
					exact: isBoolean(link.exact) ? link.exact : false
				});
			}
		}

		return {
			active,
			specActive,
			setActive,
			link: resolvedLink,
			working: state?.working ?? WORKING_default
		};
	},

	getScopeData: (spec, state) => {
		return {
			active: state.active,
			setActive: state.setActive,
			link: state.link,
			working: state.working
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				active: props.props.active.schema.getTypeDescriptor(props.props.active),
				setActive: Type.Method({
					...HAELibComponentsTerms.schemas.button.setActive,
					argRequiredCount: 1,
					argSchemas: [ BP.Boolean({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				link: Type.Any({
					...HAELibComponentsTerms.schemas.button.link
				}),
				working: props.props.working.schema.getTypeDescriptor(props.props.working)
			}
		});
	}

});

const HAEComponentButton_React: THAEComponentReact<typeof HAEComponentButton_Definition> = ({
	props, state, setState, componentInstance, reactComponentClassList, additionalProps
}) => {
	const buttonProps = {
		...props,
		...resolveButtonType(props.typeData),
		resolvedLink: state?.link,
		...(additionalProps?.resolvedButtonStyle || resolveButtonStyle(props.styleData))
	};

	delete buttonProps.typeData;
	delete buttonProps.styleData;
	delete buttonProps.active;

	const _clickHandler = React.useMemo(() => {
		const eventEnabled = isEventEnabled(componentInstance.eventEnabled.click, componentInstance.componentMode);
		const onClickDefined = isFunction(additionalProps?.onClick);

		if (eventEnabled || onClickDefined) {
			return (value: unknown, data: IButtonClickData) => {
				if (onClickDefined) {
					additionalProps.onClick(value, data);
				}

				if (eventEnabled) {
					return componentInstance.eventTriggers.click((parentScope) => createSubScope(parentScope));
				}

				return;
			};
		}

		return;
	}, [
		componentInstance.componentMode,
		componentInstance.eventEnabled.click,
		componentInstance.eventTriggers.click,
		additionalProps?.onClick
	]);

	return (
		<Button
			{...buttonProps}
			name={componentInstance.id}
			componentPath={componentInstance.safePath}
			componentMode={componentInstance.componentMode}
			classList={reactComponentClassList.add("cmp-button", "button--layout")}
			active={state.active}
			setActive={state.setActive}
			onClick={_clickHandler}
			onWorkingStateChange={(working) => {
				setState((prevState) => ({ ...prevState, working }));
			}}
		/>
	);
};

export const HAEComponentButton: THAEComponentDefinition<typeof HAEComponentButton_Definition> = {
	...HAEComponentButton_Definition,
	reactComponent: HAEComponentButton_React
};
