/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGenericBlueprintSchema } from "../../Schema/IBlueprintSchema";
import { ISchemaConstMapOpts, SchemaConstMap, ISchemaConstMap } from "../const/SchemaConstMap";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueMapOpts<
	TValueSchema extends TGenericBlueprintSchema
> extends ISchemaValueOpts<ISchemaConstMap<TValueSchema>>, ISchemaConstMapOpts<TValueSchema> {}

/**
 * Schema: Map value
 *
 * @param opts Schema options
 */
export function SchemaValueMap<
	TValueSchema extends TGenericBlueprintSchema
>(opts: ISchemaValueMapOpts<TValueSchema>): ISchemaValue<ISchemaConstMap<TValueSchema>> {

	return SchemaValue(SchemaConstMap(opts), opts);

}
