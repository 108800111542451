/**
 * Percentage schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstInteger,
	ISchemaValue,
	ISchemaValueIntegerOpts
} from "@hexio_io/hae-lib-blueprint";

/**
 * Percentage schema
 *
 * @param opts Options
 */
export function PercentageSchema(opts: Partial<ISchemaValueIntegerOpts> = { alias: "percentage" }): ISchemaValue<ISchemaConstInteger> {
	return BP.Integer({
		default: 0,
		fallbackValue: 0,
		...opts,
		constraints: {
			required: true,
			min: 0,
			max: 100,
			...opts.constraints
		}
	});
}