/**
 * hae-lib-components
 *
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { COMPONENT_MODE, TGenericComponentInstance } from "@hexio_io/hae-lib-blueprint";
import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { useCallback, useEffect, useState, DragEvent, MouseEvent } from "react";
import { IHAEComponentChildContextValue } from "../HAEComponent/HAEComponentContext";
import { useEditContext } from "./EditContext";
import { DND_MODE } from "./EditDnD";
import { ISelectionItem_Component, EDIT_SELECTION_ITEM_TYPE } from "./EditSelection";
import { IElementRelativeCoords } from "./IEditCommon";

/**
 * Returns html element coordinates
 *
 * @param element HTML Element
 */
function getCoords(element: HTMLElement): IElementRelativeCoords {

	const elRect = element ? element.getBoundingClientRect() : null;
	const parentRect = element?.parentElement ? element.parentElement.getBoundingClientRect() : null;

	const result: IElementRelativeCoords = {
		x: elRect ? elRect.x : null,
		y: elRect ? elRect.y : null,
		width: elRect ? elRect.width : null,
		height: elRect ? elRect.height : null
	};

	result.parentx = parentRect ? parentRect.x : result.x;
	result.parenty = parentRect ? parentRect.y : result.y;
	result.parentwidth = parentRect ? parentRect.width : result.width;
	result.parentheight = parentRect ? parentRect.height : result.height;

	return result;

}

/**
 * Hook that provides editing info and functions for HAE components
 *
 * @param cmpInstance Component instance
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useComponentEdit = (
	cmpInstance: TGenericComponentInstance,
	listElementRef: React.MutableRefObject<HTMLElement>,
	childContextRef: IHAEComponentChildContextValue
) => {

	const editCtx = useEditContext();

	const editComponentMode = cmpInstance.componentMode === COMPONENT_MODE.EDIT;

	const componentDnD = editCtx ? editCtx.componentDnD : null;

	const [ isSelected, setIsSelected ] = useState(() => {
		if (editCtx) {
			return editCtx.selection.isComponentSelectedWithRefUpdate(cmpInstance);
		}
	});

	const [ isHovered, setIsHovered ] = useState(() => {
		if (editCtx) {
			return editCtx.selection.isComponentHovered(cmpInstance?.modelNode);
		}

		return false;
	});

	const handleMouseOver = useCallback<React.MouseEventHandler<HTMLElement>>((ev) => {
		if (editComponentMode && !componentDnD?.wasEntered()) {
			ev.stopPropagation();
			editCtx.selection.setHoveredComponent(cmpInstance?.modelNode);
		}
	}, []);

	const handleMouseOut = useCallback<React.MouseEventHandler<HTMLElement>>((ev) => {
		if (editComponentMode) {
			ev.stopPropagation();
			editCtx.selection.unsetHoveredComponent(cmpInstance?.modelNode);
		}
	}, []);

	const handleClick = useCallback((ev: MouseEvent<HTMLElement>) => {

		if (editComponentMode) {

			ev.stopPropagation();

			editCtx.selection.selectComponent(cmpInstance, () => getCoords(listElementRef.current));

		}

	}, [ editComponentMode, isSelected ]);

	// On drag start callback
	const handleDragStart = useCallback((ev: DragEvent<HTMLElement>) => {

		if (!editComponentMode) {
			return;
		}

		editCtx.selection.selectComponent(cmpInstance, () => getCoords(listElementRef.current));

		const started = editCtx.componentDnD.start(
			DND_MODE.MOVE,
			editCtx.dCtx,
			ev.nativeEvent.dataTransfer,
			editCtx.selection.getItems()
				.filter((item) => item.type === EDIT_SELECTION_ITEM_TYPE.COMPONENT)
				.map((item: ISelectionItem_Component) => {

					const coords = item.getCoords();

					return {
						cmpInstance: item.cmpInstance,
						nodeId: item.nodeId,
						dimensions: {
							width: coords.width,
							height: coords.height,
							parentwidth: coords.parentwidth,
							parentheight: coords.parentheight
						},
						offset: {
							x: ev.clientX - coords.x,
							y: ev.clientY - coords.y,
							parentx: ev.clientX - coords.parentx,
							parenty: ev.clientY - coords.parenty
						}
					}

				})
		);

		if (started) {
			childContextRef.dndState.active = true;
			ev.stopPropagation();
			ev.nativeEvent.dataTransfer.effectAllowed = "move";
		}

		// Bind custom drag-end listener because of React does not fire "dragend" event when component is removed.
		const _listElementRef = listElementRef.current;

		const handleDragEnd = () => {
			childContextRef.dndState.active = false;
			_listElementRef.removeEventListener("dragend", handleDragEnd);
			editCtx.componentDnD.end();
		};

		_listElementRef.addEventListener("dragend", handleDragEnd);

	}, [ cmpInstance, listElementRef, editComponentMode ]);

	useEffect(() => {

		if (!editCtx) {
			return;
		}

		const handleSelectionChange = () => {
			setIsSelected(editCtx.selection.isComponentSelectedWithRefUpdate(cmpInstance));
		};

		const handleHoverChange = () => {
			setIsHovered(editCtx.selection.isComponentHovered(cmpInstance?.modelNode));
		};

		onEvent(editCtx.selection.onChange, handleSelectionChange);
		onEvent(editCtx.selection.onHoverChange, handleHoverChange);

		return () => {
			offEvent(editCtx.selection.onChange, handleSelectionChange);
			offEvent(editCtx.selection.onHoverChange, handleHoverChange);
		};

	}, [ cmpInstance ]);

	useEffect(() => {

		if (editCtx && isSelected) {
			editCtx.selection.updateComponentMetaData(cmpInstance, () => getCoords(listElementRef.current));
		}

		if (cmpInstance && cmpInstance.customData.shouldRestoreFromDrag) {
			cmpInstance.customData.isBeingDragged = false;
		}

	}, [ cmpInstance, isSelected, listElementRef.current, cmpInstance?.rev ]);

	return {
		isSelected,
		isHovered,
		isBeingDragged: cmpInstance?.customData?.isBeingDragged || false,
		componentDnD,
		handleClick,
		handleMouseOver,
		handleMouseOut,
		handleDragStart
	};

}
