/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { DesignContext } from "../Context/DesignContext";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE, IBlueprintIDTScalar } from "../IDT/ISchemaIDT";
import {IBlueprintSchema, IBlueprintSchemaOpts, TBlueprintSchemaParentNode} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import { TypeDescVoid } from "../Shared/ITypeDescriptor";
import {cloneModelNode, createEmptySchema, createModelNode, destroyModelNode} from "../Schema/SchemaHelpers";

/**
 * Schema options
 */
export interface ISchemaVoidOpts extends IBlueprintSchemaOpts {}

/**
 * Schema model
 */
export interface ISchemaVoidModel extends IModelNode<ISchemaVoid> {}

/**
 * Schema type
 */
export interface ISchemaVoid extends IBlueprintSchema<
	ISchemaVoidOpts,
	ISchemaVoidModel,
	null,
	null
> {}

/**
 * Schema: Void
 *
 * Schema does not contain any values, renders to null and is always valid.
 *
 * @param opts Schema options
 */
export function SchemaVoid(opts: ISchemaVoidOpts): ISchemaVoid {

	const schema = createEmptySchema<ISchemaVoid>("void", opts);

	const createModel = (dCtx: DesignContext, parent: TBlueprintSchemaParentNode) => {

		return createModelNode(schema, dCtx, parent, [], {});

	};

	schema.createDefault = (dCtx, parent) => {

		return createModel(dCtx, parent);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		return cloneModelNode(dCtx, modelNode, parent, {});

	};

	schema.destroy = (modelNode) => {

		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idt, parent) => {

		return createModel(dCtx, parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			return null;
		});

	};

	schema.serialize = (modelNode, path) => {

		return {
			type: BP_IDT_TYPE.SCALAR,
			subType: BP_IDT_SCALAR_SUBTYPE.NULL,
			path: path,
			value: null
		} as IBlueprintIDTScalar;

	};

	schema.render = () => {

		return null;

	};

	schema.compileRender = () => {

		return {
			isScoped: false,
			code: `null`
		};

	};

	schema.validate = () => {

		return true;

	};

	schema.compileValidate = (): string => {

		return "(v)=>true";

	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaVoid", [opts]);
	};

	schema.getTypeDescriptor = () => {
		return TypeDescVoid({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = () => {
		return [];
	}

	return schema;

}
