/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportSchema } from "../ExportImportSchema/ExportSchema";
import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar,
	TBlueprintIDTNodePath
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaChildNode,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec,
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	applyRuntimeValidators, assignParentToModelProps,
	compileRuntimeValidators,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	validateDefaultValue
} from "../Schema/SchemaHelpers";
import { applyCodeArg } from "../Context/CompileUtil";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import { extractAndValidateIDTMapProperties, provideIDTMapRootCompletions, validateIDTNode } from "../Context/ParseUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { TypeDescObject } from "../Shared/ITypeDescriptor";
import { ValidatorObject } from "../validators/ValidatorObject";
import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";
import { ISchemaValue, SCHEMA_VALUE_TYPE } from "./value/SchemaValue";
import { ISchemaConstString } from "./const/SchemaConstString";
import { ISchemaConstObject, TSchemaConstObjectProps } from "./const/SchemaConstObject";
import { IActionRefItem, IActionRefResolver } from "../Resolvers/IActionRefResolver";
import { ISchemaConstMap } from "./const/SchemaConstMap";
import { ISchemaConstAny, SCHEMA_CONST_ANY_VALUE_TYPE } from "./const/SchemaConstAny";
import { SchemaValueString } from "./value/SchemaValueString";
import { SchemaValueMap } from "./value/SchemaValueMap";
import { SchemaValueAny } from "./value/SchemaValueAny";
import { SchemaValueObject } from "./value/SchemaValueObject";
import { ISchemaDynamic, SchemaDynamic } from "./SchemaDynamic";
import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";
import { IDocumentLocation } from "../Shared/IDocumentLocation";

type TSchemaActionIDSchema = ISchemaValue<ISchemaConstString>;
type TSchemaActionResolvedParamsSchema = ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>>;
type TSchemaActionGenericParamsSchema = ISchemaValue<ISchemaConstMap<ISchemaValue<ISchemaConstAny>>>;
type TSchemaActionUnionParamsSchema = TSchemaActionResolvedParamsSchema | TSchemaActionGenericParamsSchema;

type TSchemaActionParamsSchema
	= ISchemaDynamic<IParamsResolveParams, TSchemaActionUnionParamsSchema>;

interface IParamsResolveParams {
	actionId: string;
}

/**
 * Schema model
 */
export interface ISchemaActionRefModel extends IModelNode<ISchemaActionRef> {
	/** Action ID */
	actionId: TGetBlueprintSchemaModel<TSchemaActionIDSchema>;
	/** Action params - can be null! */
	params: TGetBlueprintSchemaModel<TSchemaActionParamsSchema>;
	/** Change event handler bound to model */
	__changeHandler: () => void;
	/** Resolver invalidate event bound to model */
	__invalidateHandler: () => void;
	/** If the action ID constant change event has been bound */
	__actionIdConstantChangeEventBound: boolean;
	/** Last available action ID - for ref counting */
	__lastActionId: string;
}

/**
 * Schema options
 */
export interface ISchemaActionRefOpts extends IBlueprintSchemaOpts {
	/** Validation constraints */
	constraints?: {
		/** If required */
		required?: boolean;
	}
}

/**
 * Default value
 */
export interface ISchemaActionRefDefault {
	/** Action ID */
	actionId: TGetBlueprintSchemaDefault<TSchemaActionIDSchema>;
	/** Action params - can be null! */
	params: TGetBlueprintSchemaDefault<TSchemaActionParamsSchema>;
}

/**
 * Schema spec
 */
export interface ISchemaActionRefSpec {
	/** Action ID */
	actionId: TGetBlueprintSchemaSpec<TSchemaActionIDSchema>;

	/** Action params - can be null! */
	params: TGetBlueprintSchemaSpec<TSchemaActionParamsSchema>;
}

/**
 * Schema type
 */
export interface ISchemaActionRef extends IBlueprintSchema<
	ISchemaActionRefOpts,
	ISchemaActionRefModel,
	ISchemaActionRefSpec,
	ISchemaActionRefDefault
> {
	/**
	 * Returns a list of available configuration names
	 */
	getActionList: (modelNode: ISchemaActionRefModel) => IActionRefItem[];
}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaActionRef(
	opts: ISchemaActionRefOpts,
): ISchemaActionRef {

	type TIDModel = TGetBlueprintSchemaModel<TSchemaActionIDSchema>;
	type TParamsModel = TGetBlueprintSchemaModel<TSchemaActionParamsSchema>;

	const rootValidator = ValidatorObject({ required: opts.constraints?.required });

	const resolvedParamsCache = new WeakMap();

	const getResolvedParamsSchema = (resolver: IActionRefResolver, params: IParamsResolveParams): TSchemaActionResolvedParamsSchema => {

		if (params.actionId) {

			const props = resolver.getParamsSchemaById(params.actionId);

			if (!props) {
				throw new Error(`Unknown action ID '${params.actionId}'.`);
			}

			if (resolvedParamsCache.has(props)) {
				return resolvedParamsCache.get(props);
			}

			const retSchema = SchemaValueObject({
				label: "Parameters",
				constraints: {
					required: true
				},
				props: props
			});

			resolvedParamsCache.set(props, retSchema);
			return retSchema;

		} else {

			return null;

		}

	};

	const resolveParamsFromDesignCtx = (dCtx: DesignContext, params: IParamsResolveParams) => {
		return getResolvedParamsSchema(dCtx.getResolver<IActionRefResolver>("actionRef"), params);
	}

	const idSchema: TSchemaActionIDSchema = SchemaValueString({
		label: "Action",
		constraints: {
			required: opts?.constraints?.required,
			min: 1
		},
		allowTranslate: false,
		icon: "mdi/motion-play",
		editorOptions: {
			controlType: "actionSelector",
			layoutType: "noHeader"
		}
	});

	const paramsSchema = SchemaDynamic<IParamsResolveParams, TSchemaActionUnionParamsSchema>({
		resolveSchemaDesign: resolveParamsFromDesignCtx,
		defaultSchema: SchemaValueMap({
			label: "Parameters",
			constraints: {
				required: true
			},
			value: SchemaValueAny({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			})
		})
	});

	const keyPropRules = {
		actionId: {
			required: true,
			provideCompletion: (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {

				const actionList = dCtx.getResolver<IActionRefResolver>("actionRef").getActionList();

				dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
					const items: ICompletionItem[] = actionList.map((item) => ({
						kind: CMPL_ITEM_KIND.Reference,
						label: item.label,
						insertText: item.actionId
					}));

					return items;
				});

			}
		},
		params: {
			required: true
		}
	};

	const updateRef = (dCtx: DesignContext, prevId: string, newId: string) => {
		if (prevId !== null) {
			dCtx.__removeRef("action", prevId);
		}

		if (newId !== null) {
			dCtx.__addRef("action", newId);
		}
	};

	const schema = createEmptySchema<ISchemaActionRef>("actionRef", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, ["actionId", "params"])
	}

	const createModel = (
		dCtx: DesignContext,
		idModel: TIDModel,
		paramsModel: TParamsModel,
		validationErrors: IBlueprintSchemaValidationError[],
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx, parent, validationErrors, {
			actionId: idModel,
			params: paramsModel,
			__changeHandler: null,
			__invalidateHandler: null,
			__actionIdConstantChangeEventBound: false,
			__lastActionId: null
		});

		const model = assignParentToChildrenOf(modelNode)

		const handleChange = (wasInvalidated?: boolean) => {

			if (model.actionId.type === SCHEMA_VALUE_TYPE.CONST) {

				if (!model.__actionIdConstantChangeEventBound) {
					onEvent(model.actionId.constant.changeEvent, model.__changeHandler);
					model.__actionIdConstantChangeEventBound = true;
				}

				model.params.schema.resolve(model.params, {
					actionId: model.actionId.constant.value
				}, !wasInvalidated, wasInvalidated);

				updateRef(dCtx, model.__lastActionId, model.actionId.constant.value);
				model.__lastActionId = model.actionId.constant.value;

			} else {
				model.params.schema.reset(model.params, !wasInvalidated);

				updateRef(dCtx, model.__lastActionId, null);
				model.__lastActionId = null;
			}

		};

		model.__changeHandler = () => handleChange(false);
		model.__invalidateHandler = () => handleChange(true);

		onEvent(model.actionId.changeEvent, model.__changeHandler);
		onEvent(dCtx.getResolver<IActionRefResolver>("actionRef").onInvalidate, model.__invalidateHandler);

		if (model.actionId.type === SCHEMA_VALUE_TYPE.CONST) {
			onEvent(model.actionId.constant.changeEvent, model.__changeHandler);
			model.__actionIdConstantChangeEventBound = true;

			updateRef(dCtx, null, model.actionId.constant.value);
			model.__lastActionId = model.actionId.constant.value;
		}

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const errors = validateDefaultValue(schema, [rootValidator as any], defaultValue);

		const idModel = idSchema.createDefault(dCtx, null, defaultValue?.actionId);
		let paramsModel: TParamsModel;

		if (idModel.type === SCHEMA_VALUE_TYPE.CONST) {
			paramsModel = paramsSchema.createDefault(dCtx, null, defaultValue?.params, {
				resolveParams: { actionId: idModel.constant.value }
			}) as TParamsModel;
		} else {
			paramsModel = paramsSchema.createDefault(dCtx, null, defaultValue?.params) as TParamsModel;
		}

		return createModel(dCtx, idModel, paramsModel, errors, parent);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedId = modelNode.actionId.schema.clone(dCtx, modelNode.actionId, null);
		const clonedParams = modelNode.params.schema.clone(dCtx, modelNode.params, null);

		const clone = createModel(dCtx, clonedId, clonedParams, modelNode.validationErrors, parent);

		return assignParentToChildrenOf(clone)
	}

	schema.destroy = (modelNode) => {

		modelNode.actionId.schema.destroy(modelNode.actionId);
		modelNode.params.schema.destroy(modelNode.params);

		offEvent(modelNode.ctx.getResolver<IActionRefResolver>("actionRef").onInvalidate, modelNode.__invalidateHandler);

		updateRef(modelNode.ctx, modelNode.__lastActionId, null);
		modelNode.__lastActionId = null;

		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent) => {

		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return schema.createDefault(dCtx, parent);
		}

		// Extract keys
		const { keys, isValid: isRootKeysValid, keysValid: rootKeysValidity }
			= extractAndValidateIDTMapProperties(dCtx, rootNode, keyPropRules);

		const idModel = rootKeysValidity.actionId ? idSchema.parse(dCtx, keys["actionId"].value, null) : null;

		if (keys.params && keys.params.key && idtNode.parseInfo && keys.params?.key?.parseInfo) {
			paramsSchema.provideCompletion(
				dCtx,
				{
					uri: idtNode.parseInfo.loc.uri,
					range: {
						start: {
							line: keys.params.key.parseInfo.loc.range.end.line,
							col: keys.params.key.parseInfo.loc.range.end.col + 2
						},
						end: keys.params.parseInfo.loc.range.end
					}
				},
				idtNode.parseInfo.loc.range.start.col + 1,
				keys.params.value,
				idModel && idModel.type === SCHEMA_VALUE_TYPE.CONST
					? {
						resolveParams: { actionId: idModel ? idModel.constant.value : null }
					}
					: undefined
			);
		}

		if (!isRootKeysValid) {
			return schema.createDefault(dCtx, parent);
		}

		let paramsModel: TParamsModel;

		if (idModel.type === SCHEMA_VALUE_TYPE.CONST) {
			paramsModel = paramsSchema.parse(dCtx, keys["params"].value, null, {
				resolveParams: { actionId: idModel.constant.value }
			}) as TParamsModel;
		} else {
			paramsModel = paramsSchema.parse(dCtx, keys["params"].value, null) as TParamsModel;
		}

		if (paramsModel.hasError && keys["actionId"].value.parseInfo) {
			dCtx.logParseError(keys["actionId"].value.parseInfo.loc.uri, {
				severity: DOC_ERROR_SEVERITY.WARNING,
				name: DOC_ERROR_NAME.INVALID_REF,
				message: `Cannot resolve action: ${paramsModel.error}`,
				parsePath: keys["actionId"].value.path,
				range: keys["actionId"].value.parseInfo.loc.range,
				metaData: {
					errorInstance: paramsModel.errorInstance,
					stack: paramsModel.errorInstance instanceof Error ? paramsModel.errorInstance.stack : null
				}
			});
		}

		return createModel(dCtx, idModel, paramsModel, [], parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);

	}

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {

		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// name
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[actionId]"]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["{actionId}"]),
						value: "actionId"
					} as IBlueprintIDTScalar,
					value: modelNode.actionId.schema.serialize(modelNode.actionId, path.concat(["actionId"]))
				} as IBlueprintIDTMapElement,
				// opts
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[params]"]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["{params}"]),
						value: "params"
					} as IBlueprintIDTScalar,
					value: modelNode.params.schema.serialize(modelNode.params, path.concat(["params"]))
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		modelNode.lastScopeFromRender = scope;

		const actionId = modelNode.actionId.schema.render(rCtx, modelNode.actionId, path.concat(["actionId"]), scope, prevSpec?.actionId);
		const params = modelNode.params.schema.render(rCtx, modelNode.params, path.concat(["params"]), scope, prevSpec?.params);

		return {
			actionId: actionId,
			params: params
		};

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		// Pre-validate params resolve state
		if (modelNode.params.hasError) {
			cCtx.logCompileError({
				severity: DOC_ERROR_SEVERITY.WARNING,
				name: DOC_ERROR_NAME.INVALID_REF,
				message: `Cannot resolve action: ${modelNode.params.error}`,
				metaData: {
					// @todo from translation table
					translationTerm: "schema:actionRef#errors.cannotResolve",
					actionId: modelNode.params.__lastParams.actionId
				},
				modelNodeId: modelNode.nodeId,
				modelPath: path
			});
		}

		// Log notice when dynamic actionId
		if (modelNode.actionId.type !== SCHEMA_VALUE_TYPE.CONST) {
			cCtx.logCompileError({
				severity: DOC_ERROR_SEVERITY.HINT,
				name: DOC_ERROR_NAME.DYNAMIC_VALUE_NOTICE,
				// eslint-disable-next-line max-len
				message: `Action ID is set dynamically. This prevents validation of action parameters at design time and may result in runtime errors when configured incorrectly.`,
				metaData: {
					// @todo from translation table
					translationTerm: "schema:actionRef#errors.actionIdSetDynamically"
				},
				modelNodeId: modelNode.nodeId,
				modelPath: path
			});
		}

		const idCmp = modelNode.actionId.schema.compileRender(cCtx, modelNode.actionId, path.concat("actionId"));
		const paramsCmp = modelNode.params.schema.compileRender(cCtx, modelNode.params, path.concat("params"));

		const idCode = applyCodeArg(idCmp, `typeof pv==="object"&&pv!==null?pv.actionId:undefined`, `pt.concat(["actionId"])`);
		const paramsCode = applyCodeArg(paramsCmp, `typeof pv==="object"&&pv!==null?pv.params:undefined`, `pt.concat(["params"])`);

		return {
			isScoped: true,
			code: `(s,pv,pt)=>({actionId:${idCode},params:${paramsCode}})`
		}

	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {

		let isValid = true;

		// Validate self as object
		isValid = applyRuntimeValidators<{ [K: string]: unknown }>(
			rCtx, path, modelNodeId, [rootValidator], DOC_ERROR_SEVERITY.ERROR, value as unknown as { [K: string]: unknown }
		) && isValid;

		if (validateChildren && value instanceof Object) {

			isValid = idSchema.validate(rCtx, path.concat(["actionId"]), modelNodeId, value.actionId, true) && isValid;
			isValid = paramsSchema.validate(rCtx, path.concat(["params"]), modelNodeId, value.params, true, {
				resolveParams: {
					actionId: value?.actionId
				}
			}) && isValid;

		}

		return isValid;

	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {

		const expressions = [];

		const rootValidatorCmp = compileRuntimeValidators(cCtx, path, modelNodeId, [rootValidator], DOC_ERROR_SEVERITY.ERROR);

		if (rootValidatorCmp !== null) {
			expressions.push(`_vd=(${rootValidatorCmp})(v,pt)&&_vd;`);
		}

		if (validateChildren) {

			expressions.push(`if(typeof v==="object"&&v!==null){`);

			const idValid = idSchema.compileValidate(cCtx, path.concat(["actionId"]), modelNodeId, true);
			const paramsValid = paramsSchema.compileValidate(cCtx, path.concat(["params"]), modelNodeId, true);

			if (idValid) {
				expressions.push(`_vd=(${idValid})(v.actionId,pt)&&_vd;`);
			}

			if (paramsValid) {
				expressions.push(`_vd=(${paramsValid})(v.params,pt)&&_vd;`);
			}

			expressions.push(`}`);

		}

		if (expressions.length === 0) {
			return null;
		} else {
			return cCtx.addGlobalValue(`(v,pt)=>{let _vd=true;${expressions.join("")}return _vd}`);
		}

	};

	schema.export = (): ISchemaImportExport => {

		return exportSchema("SchemaActionRef", [opts]);

	};

	schema.getTypeDescriptor = (modelNode) => {

		return TypeDescObject({
			label: opts.label,
			description: opts.description,
			props: {
				actionId: modelNode?.actionId.schema.getTypeDescriptor(modelNode.actionId) || idSchema.getTypeDescriptor(),
				params: modelNode?.params.schema.getTypeDescriptor(modelNode.params) || paramsSchema.getTypeDescriptor()
			},
			example: opts.example,
			tags: opts.tags
		})

	};

	schema.getActionList = (modelNode: ISchemaActionRefModel) => {

		return modelNode.ctx.getResolver<IActionRefResolver>("actionRef").getActionList();

	}

	schema.getChildNodes = (modelNode) => {
		const children: IBlueprintSchemaChildNode[] = [{
			key: "actionId",
			node: modelNode.actionId
		}];

		if (modelNode.params) {
			children.push({
				key: "params",
				node: modelNode.params
			});
		}

		return children;
	}

	return schema;

}
