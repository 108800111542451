/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import { TGenericModelNode } from "../Schema/IModelNode";

export function isModelValueRequired(model: TGenericModelNode): boolean {
	return !!(model?.schema?.opts as Record<string, { required?: boolean }>)?.constraints?.required;
}
