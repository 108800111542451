/*
 * Range HAE component
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";
import { Range as RcRange } from "rc-slider";

type RangeProps = React.ComponentProps<typeof RcRange>;

export const Range: React.FC<RangeProps> = ({ step, disabled, value, ...props }) => (
	<RcRange
		{...props}
		step={step ?? undefined}
		disabled={disabled ?? undefined}
		value={typeof props.onChange === "undefined" ? value : [...(value ?? props.defaultValue)]}
	/>
);

export { RangeProps };
