/**
 * Text Overflow enum
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum TEXT_OVERFLOW {
	NONE = "none",
	WORD = "word",
	LINE = "line",
	ELLIPSIS = "ellipsis"
}

export type TEXT_OVERFLOW_string = keyof typeof TEXT_OVERFLOW;

export const TEXT_OVERFLOW_default: TEXT_OVERFLOW_string = "NONE";

export const TEXT_OVERFLOW_opts = {
	none: {
		icon: "mdi/keyboard-return"
	},
	word: {
		icon: "mdi/text-short"
	},
	line: {
		icon: "mdi/minus"
	},
	ellipsis: {
		icon: "mdi/dots-horizontal"
	}
};
