/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportValidator } from "../ExportImportSchema/ExportSchema";
import {
	IBlueprintSchemaValidationError,
	IBlueprintSchemaValidator,
	IBlueprintSchemaValidatorHandler,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { ValidatorDeclarationError } from "../Shared/ValidatorDeclarationError";

type TValidatorDateHandler = IBlueprintSchemaValidatorHandler<Date>;
type TValidatorDate<TOpts> = IBlueprintSchemaValidator<Date, TOpts>;

/**
 * Date validator options
 */
export interface IValidatorDateOpts {
	/** Required value */
	required?: boolean;
	/** Constant value */
	const?: Date;
}

const VALIDATOR_NAME = "ValidatorDate";

/**
 * Date validator
 */
export const ValidatorDate: TValidatorDate<IValidatorDateOpts> =
	(opts: IValidatorDateOpts): TValidatorDateHandler => {

		if (opts.required !== undefined && opts.required !== null && typeof opts.required !== "boolean") {
			throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `required` to be a boolean");
		}

		if (opts.const !== undefined && opts.const !== null && !(opts.const instanceof Date)) {
			throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `const` to be a Date");
		}

		return {

			validate: (value: Date): IBlueprintSchemaValidationError[] => {

				const errors = [];

				if (
					(opts.required === true && !(value instanceof Date)) ||
					(!opts.required && !(value instanceof Date) && value !== null && value !== undefined)
				) {
					errors.push({
						type: SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED,
						message: "Should be a Date"
					});
				}

				if (opts.const && value instanceof Date && value.getTime() !== opts.const.getTime()) {
					errors.push({
						type: SCHEMA_VALIDATION_ERROR_TYPE.CONST,
						message: `Should be a equal to const '${opts.const}'`
					});
				}

				return errors;

			},

			compile: (): string => {

				const parts = [];

				if (opts.required === true) {
					// eslint-disable-next-line max-len
					parts.push(`if(!(value instanceof Date)){errors.push({type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message:"Should be a Date"});}`);
				}

				if (!opts.required) {
					// eslint-disable-next-line max-len
					parts.push(`if(!(value instanceof Date) && value !== null && value !== undefined){errors.push({type:"${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message:"Should be a Date"});}`);
				}

				if (opts.const) {
					// eslint-disable-next-line max-len
					parts.push(`if(value instanceof Date && value.getTime() !== ${opts.const.getTime()}){errors.push({type:"${SCHEMA_VALIDATION_ERROR_TYPE.CONST}",message:"Should be a equal to const '${opts.const.toISOString()}'"});}`);
				}

				// Minification
				const code = parts.join(" ")
					.replace(/{ /g, "{")
					.replace(/ }/g, "}")
					.replace(/: "/g, ":\"")
					.replace(/ ,/g, ",")
					.replace(/ && /g, "&&")
					.replace(/ !== /g, "!==")
					.replace(/value/g, "v")
					.replace(/errors.push/g, "e.push");

				return `const e=[];${code} return e;`;

			},

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			export: (): any => {
				return exportValidator(VALIDATOR_NAME, [opts]);
			}
		}
	};
