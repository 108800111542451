/**
 * Custom HTML component terms
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const customHtml = {
	component: {
		label: "component:customHtml#label",
		description: "component:customHtml#description"
	}
};
