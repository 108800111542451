/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstBooleanOpts, SchemaConstBoolean, ISchemaConstBoolean } from "../const/SchemaConstBoolean";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueBooleanOpts extends ISchemaValueOpts<ISchemaConstBoolean>, ISchemaConstBooleanOpts {}

/**
 * Schema: Boolean value
 *
 * @param opts Schema options
 */
export function SchemaValueBoolean(opts: ISchemaValueBooleanOpts): ISchemaValue<ISchemaConstBoolean> {

	return SchemaValue(SchemaConstBoolean(opts), opts);

}