/**
 * Composed Chart HAE component Create Angular Ranges
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as am5xy from "@amcharts/amcharts5/xy";
import { AxisRenderer } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer";

import { getChartColor } from "../../Functions/chartHelpers";
import { HAEComponentGaugeChart_State } from "./state";
import { getStringEnumValue } from "@hexio_io/hae-lib-components";
import { GAUGE_CHART_RANGE_TYPE } from "../../Enums/GAUGE_CHART_RANGE_TYPE";

/**
 * Creates angular ranges
 */
export function createAngularRanges(
	ranges: HAEComponentGaugeChart_State["ranges"],
	axis: am5xy.ValueAxis<AxisRenderer>,
	viewportRootElement: HTMLElement
): void {
	ranges.filter((item) => {
		const typeValue = getStringEnumValue(GAUGE_CHART_RANGE_TYPE, item.typeData.type);

		return typeValue === GAUGE_CHART_RANGE_TYPE.DEFAULT;
	}).forEach((item) => {
		const axisRange = axis.createAxisRange(axis.makeDataItem({}));

		const typeDataValue = item.typeData.value[item.typeData.type];

		axisRange.setAll({
			value: typeDataValue.startValue ?? -Infinity,
			endValue: typeDataValue.endValue ?? Infinity
		});

		axisRange.get("tick").set("forceHidden", true);

		const axisFill = axisRange.get("axisFill");

		if (item.backgroundColor) {
			const backgroundColor = getChartColor(item.backgroundColor, viewportRootElement);

			axisFill.setAll({
				visible: true,
				fill: backgroundColor,
				fillOpacity: 1
			});
		}
		else {
			axisFill.set("visible", false);
		}
	});
}
