/**
 * Iframe schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const iframe = {
	source: {
		label: "schema:iframe.source#label"
	},
	width: {
		label: "schema:iframe.width#label"
	},
	height: {
		label: "schema:iframe.height#label"
	},
	htmlAllow: {
		label: "schema:iframe.htmlAllow#label",
		description: "schema:iframe.htmlAllow#description"
	},
	htmlReferrerPolicy: {
		label: "schema:iframe.htmlReferrerPolicy#label",
		description: "schema:iframe.htmlReferrerPolicy#description"
	},
	htmlSandbox: {
		label: "schema:iframe.htmlSandbox#label",
		description: "schema:iframe.htmlSandbox#description"
	},
	loading: {
		label: "schema:iframe.loading#label"
	},
	loadingValues: {
		eager: {
			label: "schema:iframe.loading#values.eager#label"
		},
		lazy: {
			label: "schema:iframe.loading#values.lazy#label"
		}
	}
};
