/**
 * Font Size pattern
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { cssLengthUnits } from "./cssUnits";

// eslint-disable-next-line max-len
export const fontSizePattern = `^((([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)\\s*(${cssLengthUnits})|[A-Z_]+|inherit|xx-small|x-small|small|medium|large|x-large|xx-large|xxx-large|larger|smaller)$`;
