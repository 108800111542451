/**
 * Hexio App Engine Shared Library
 *
 * @package hae-lib-shared
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Serializes a native JS data structure into a normalized string-based signature.
 *
 * Can be used to compare or hash data structure.
 * Object properites are always alphabetically sorted so it's property order independent.
 *
 * @param value Value
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function valueToNormalSignature(value: any): string {

	if (value instanceof Date) {

		// Serialize Date object as well as string (prefix "S") to avoid differences between client/server parameter handling.
		return "S(" + value.toISOString() + ")";

	} else if (value instanceof Array) {

		return "[" + value.map((item) => valueToNormalSignature(item)).join(",") + "]";

	} else if (typeof value === "function") {

		return "<" + String(value.name) + ">";

	} else if (value instanceof Object) {

		return "{" + Object.keys(value).slice().sort(
			(a, b) => a > b ? 1 : a < b ? -1 : 0
		).map((key) => key + ":" + valueToNormalSignature(value[key])).join(",") + "}";

	} else if (value === null) {

		return "NL";

	} else if (typeof value === "string") {

		return "S(" + value + ")";

	} else if (typeof value === "number") {

		return "N(" + String(value) + ")";

	} else if (typeof value === "boolean") {

		return (value === true ? "T" : "F" );

	} else {

		return "X(" + String(value) + ")";

	}

}
