/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE, IBlueprintIDTScalar } from "../../IDT/ISchemaIDT";
import {IBlueprintSchemaOpts, IBlueprintSchemaScalar, TBlueprintSchemaParentNode} from "../../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../../Schema/IModelNode";
import {
	applyRuntimeValidators,
	cloneModelNode,
	compileRuntimeValidators,
	compileScalarNodeRender,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	renderScalarNode,
	validateDefaultValue,
	validateParsedValueAndReport,
	validateValueAndUpdateModel
} from "../../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { DesignContext } from "../../Context/DesignContext";
import { IBlueprintSchemaValidationError, IBlueprintSchemaValidatorHandler } from "../../Validator/IBlueprintSchemaValidator";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { TypeDescString } from "../../Shared/ITypeDescriptor";
import { inlineValue } from "../../Context/CompileUtil";
import { IValidatorStringOpts, ValidatorString } from "../../validators";
import { fromBase64, toBase64 } from "@hexio_io/hae-lib-shared";

/**
 * Schema value type
 */
type TSpecType = string;

/**
 * Schema options
 */
export interface ISchemaConstPasswordOpts extends IBlueprintSchemaOpts {
	/** Default value for a new node */
	default?: TSpecType;
	/** Base validation constraints */
	constraints?: IValidatorStringOpts;
	/** Custom validators */
	validators?: IBlueprintSchemaValidatorHandler<TSpecType>[];
	/** Fallback value to return when validation fails */
	fallbackValue?: TSpecType;
}

/**
 * Schema type
 */
export type ISchemaConstPassword = IBlueprintSchemaScalar<ISchemaConstPasswordOpts, ISchemaConstPasswordModel, TSpecType>

/**
 * Schema model
 */
export interface ISchemaConstPasswordModel extends IModelNode<ISchemaConstPassword> {
	value: TSpecType;
}

/**
 * Schema: Password scalar constant
 *
 * @param opts Schema options
 */
export function SchemaConstPassword(opts: ISchemaConstPasswordOpts): ISchemaConstPassword {

	const validators: IBlueprintSchemaValidatorHandler<TSpecType>[] = [
		ValidatorString(opts.constraints || {})
	].concat(opts.validators || []);

	const errSeverity = opts.fallbackValue !== undefined ? DOC_ERROR_SEVERITY.WARNING : DOC_ERROR_SEVERITY.ERROR;

	const schema = createEmptySchema<ISchemaConstPassword>("constPassword", opts);

	const createModel = (dCtx: DesignContext, value: TSpecType, parent: TBlueprintSchemaParentNode,
		validationErrors: IBlueprintSchemaValidationError[]) => {

		return createModelNode(schema, dCtx, parent,validationErrors, {
			value: value
		});

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		// Must be checked explicitly, because when using || it will always resolves to the most positive value
		const value = defaultValue !== undefined && defaultValue !== null
			? defaultValue
			: opts.default !== undefined
				? opts.default
				: null;

		const errors = validateDefaultValue(schema, validators, value);

		return createModel(dCtx, value, parent, errors);

	};

	schema.clone = (dCtx, modelNode, parent) => {
		return cloneModelNode(dCtx, modelNode, parent, {
			value: modelNode.value
		});
	};

	schema.destroy = (modelNode) => {

		modelNode.value = undefined;
		destroyModelNode(modelNode);

	};

	schema.parse = (dCtx, idtNode, parent) => {

		// Check null
		if (!idtNode || (idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL)) {
			return schema.createDefault(dCtx, parent);
		}

		if (idtNode.type !== BP_IDT_TYPE.SCALAR || (
			idtNode.type == BP_IDT_TYPE.SCALAR &&
			idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.STRING &&
			idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.NULL
		)) {
			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.STR_NOT_STRING,
					message: "Expecting a string",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		let value;
		try {

			value = fromBase64<TSpecType>(String(idtNode.value));

		} catch (error) {

			dCtx.logParseError(idtNode.parseInfo.loc.uri, {
				range: idtNode.parseInfo.loc.range,
				severity: DOC_ERROR_SEVERITY.ERROR,
				name: DOC_ERROR_NAME.INVALID_VALUE,
				message: "Expecting a valid base64 string",
				parsePath: idtNode.path
			});

			return schema.createDefault(dCtx, parent);

		}

		const errors = validateParsedValueAndReport(dCtx, idtNode, validators, value);

		return createModel(dCtx, value, parent, errors);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			return null;
		});

	};

	schema.serialize = (modelNode, path) => {

		if (modelNode.value !== null) {

			const value = toBase64(modelNode.value);

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.STRING,
				path: path,
				value: value
			} as IBlueprintIDTScalar;

		} else {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.NULL,
				path: path,
				value: null
			} as IBlueprintIDTScalar;

		}

	};

	schema.render = (rCtx, modelNode, path, scope) => {

		return renderScalarNode(
			modelNode, scope,
			modelNode.value,
			opts.fallbackValue !== undefined && opts.fallbackValue !== null
				? opts.fallbackValue
				: opts.default !== undefined ? opts.default : null
		);

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		return compileScalarNodeRender(
			cCtx, modelNode, path, errSeverity,
			inlineValue(modelNode.value),
			opts.fallbackValue !== undefined && opts.fallbackValue !== null
				? opts.fallbackValue
				: opts.default !== undefined ? opts.default : null
		);

	};

	schema.validate = (rCtx, path, modelNodeId, value) => {
		return applyRuntimeValidators(rCtx, path, modelNodeId, validators, errSeverity, value);
	};

	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileRuntimeValidators(cCtx, path, modelNodeId, validators, errSeverity);
	};

	schema.castSpec = (_rCtx, _path, _modelNodeId, value) => {

		if (value === null || value === undefined) {

			return value;

		} else if (typeof value === "string") {

			return value;

		} else {

			return String(value);

		}

	};

	schema.compileCastSpec = () => {

		/* eslint-disable indent, max-len */
		return `(v,pt)=>{${[
			`if(v===null||v===undefined){`,
			`return v`,
			`}else if(typeof v==="string"){`,
			`return v`,
			`}else{`,
			`return String(v)`,
			`}`
		].join("")}}`
		/* eslint-enable indent, max-len */

	};

	schema.getValue = (modelNode) => {
		return modelNode.value;
	};

	schema.setValue = (modelNode, value, notify) => {

		validateValueAndUpdateModel(modelNode, validators, value);
		modelNode.value = value;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}

	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaConstPassword", [opts]);
	};

	schema.getTypeDescriptor = () => {
		return TypeDescString({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = () => {
		return [];
	}

	/* @todo Disabled because of SchemaBuilder
	 * If user configures default value that is not validate due to constraints
	 * the error will be thrown and blueprints becomes invalid.
	 * This could be resolved when schema builder will be able to dynamically validate
	 * the value field against configured constraints.
	 */
	// if (opts.default !== null && opts.default !== undefined) {
	// 	validateDefaultValue(schema, validators, opts.default);
	// }

	return schema;

}

