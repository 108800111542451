/**
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createContext, useContext } from "react";
import { DesignContext, MEDIA_RESOLUTIONS_string } from "@hexio_io/hae-lib-blueprint";

import { EditDnD } from "./EditDnD";
import { EditSelection } from "./EditSelection";

export interface IEditContextValue {
	/** Selection class */
	selection: EditSelection;

	/** Drag'n'drop state */
	componentDnD: EditDnD;

	/** Design context */
	dCtx: DesignContext;

	/** Event sampling rate */
	editorEventSamplingRateMs?: number;

	/** Current media */
	media?: MEDIA_RESOLUTIONS_string;
}

/**
 * Edit context
 *
 * Provides interface between components and editor
 */
export const EditContext = createContext<IEditContextValue>(null);

export function useEditContext(): IEditContextValue {
	return useContext(EditContext);
}
