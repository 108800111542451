/**
 * Table HAE component content
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	COMPONENT_MODE,
	ISchemaConstObjectModel,
	TComponentNodePath,
	TGetComponentInstanceFromDefinition
} from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	StyleSheet,
	HAEComponentList,
	IHAEComponentListProps,
	useStyleSheetRegistry,
	isEventEnabled
} from "@hexio_io/hae-lib-components";

import { HAEComponentTable_Props } from "./props";
import { DROP_ZONE_MODE } from "@hexio_io/hae-lib-components/src/Editor/useComponentListDnD";
import { resolveAllowResize } from "./editModeFunctions";
import { TTableItemIds, TTableRows } from "./types";
import { HAEComponentTable_Definition } from "./definition";
import { isNil } from "@hexio_io/hae-lib-shared";

/**
 * Table Content props
 */
export interface ITableContentProps {
	/** Rows */
	rows: TTableRows;

	/** Order */
	order: TTableItemIds;

	/** Columns class lists */
	columnsClassLists: ClassList[];

	/** Component instance */
	componentInstance: TGetComponentInstanceFromDefinition<typeof HAEComponentTable_Definition>;

	/** Component path */
	componentPath: TComponentNodePath;
}

/**
 * Table Content component
 */
export const TableContent: React.FunctionComponent<ITableContentProps> = (props) => {
	const { rows, order, columnsClassLists, componentInstance, componentPath } = props;
	const styleSheetRegistry = useStyleSheetRegistry();

	const editComponentMode = componentInstance.componentMode === COMPONENT_MODE.EDIT;

	return (
		<tbody className="table__content">
			{
				order.map((item, index) => {
					const row = rows[item];

					const rowKey = `id-${row.id}`;
					const editRow = editComponentMode && index === 0;

					const rowComponentPath = [ ...componentPath, rowKey ];
					const rowComponentMode = (editComponentMode && !editRow) ? COMPONENT_MODE.READONLY : componentInstance.componentMode;

					let componentListEditBaseProps: Omit<IHAEComponentListProps, "components"|"componentPath"|"componentMode"> = {};

					if (editRow) {
						componentListEditBaseProps = {
							...componentListEditBaseProps,
							allowResize: resolveAllowResize,
							dropZoneMode: DROP_ZONE_MODE.VERTICAL
						};
					}

					const {
						classList: rowClassList, idClassName: rowIdClassName
					} = ClassList.getElementClassListAndIdClassName("table__row", rowComponentPath);

					const rowStyleSheet = new StyleSheet();

					if (row.style?.backgroundColor) {
						rowStyleSheet.addColorProperties({
							selector: `.${rowIdClassName}`,
							name: "element-background-color",
							value: row.style.backgroundColor
						});
					}

					if (row.style?.foregroundColor) {
						rowStyleSheet.addColorProperties({
							selector: `.${rowIdClassName}`,
							name: "element-foreground-color",
							value: row.style.foregroundColor
						});
					}

					const _rowClickHandler = isEventEnabled(componentInstance.eventEnabled.rowClick, componentInstance.componentMode) ?
						() => {
							return componentInstance.eventTriggers.rowClick(() => row.scope);
						} : undefined;

					styleSheetRegistry.add(rowIdClassName, rowStyleSheet);

					return (
						<tr key={rowKey} className={rowClassList.toClassName()} onClick={_rowClickHandler}>
							{
								row.columns.map((columnItem, columnIndex) => {
									if (isNil(columnItem.components)) {
										return null;
									}

									const cellKey = `${rowKey}-${columnIndex}`;

									// eslint-disable-next-line max-len
									let componentListEditProps: Omit<IHAEComponentListProps, "components"|"componentPath"|"componentMode"> = {};

									if (editRow) {
										componentListEditProps = {
											...componentListEditBaseProps,
											// eslint-disable-next-line max-len
											modelNode: (componentInstance.modelNode?.props as ISchemaConstObjectModel<typeof HAEComponentTable_Props>).props.columns.items[columnIndex].props.content.template,
										};
									}

									return (
										<HAEComponentList
											key={cellKey}
											tagName="td"
											components={columnItem.components}
											componentPath={[ ...rowComponentPath, cellKey ]}
											componentMode={rowComponentMode}
											classList={columnsClassLists[columnIndex]}
											childClassList={new ClassList("table__cell-item")}
											// eslint-disable-next-line max-len
											childComponentClassList={new ClassList("table__cell-item-component")}
											{...componentListEditProps}
										/>
									);
								})
							}
						</tr>
					);
				})
			}
		</tbody>
	);
}
