/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstDataOpts, SchemaConstData, ISchemaConstData } from "../const/SchemaConstData";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueDataOpts extends ISchemaValueOpts<ISchemaConstData>, ISchemaConstDataOpts {}

/**
 * Schema: Data value
 *
 * @param opts Schema options
 */
export function SchemaValueData(opts: ISchemaValueDataOpts): ISchemaValue<ISchemaConstData> {

	return SchemaValue(SchemaConstData(opts), opts);

}