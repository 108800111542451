/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTScalar
} from "../../IDT/ISchemaIDT";
import { IBlueprintSchemaScalar, IBlueprintSchemaOpts, TBlueprintSchemaParentNode 	} from "../../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../../Schema/IModelNode";
import {
	applyRuntimeValidators,
	cloneModelNode,
	compileRuntimeValidators,
	compileScalarNodeRender,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	renderScalarNode,
	validateDefaultValue,
	validateParsedValueAndReport,
	validateValueAndUpdateModel
} from "../../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { DesignContext } from "../../Context/DesignContext";
import { IBlueprintSchemaValidationError, IBlueprintSchemaValidatorHandler } from "../../Validator/IBlueprintSchemaValidator";
import { ValidatorDate, IValidatorDateOpts } from "../../validators/ValidatorDate";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { TypeDescDate } from "../../Shared/ITypeDescriptor";

/**
 * Schema value type
 */
type TSpecType = Date;

/**
 * Schema options
 */
export interface ISchemaConstDateOpts extends IBlueprintSchemaOpts {
	/** Default value for a new node */
	default?: TSpecType;
	/** Base validation constraints */
	constraints?: IValidatorDateOpts;
	/** Custom validators */
	validators?: IBlueprintSchemaValidatorHandler<TSpecType>[];
	/** Fallback value to return when validation fails */
	fallbackValue?: TSpecType;
}

/**
 * Schema type
 */
export type ISchemaConstDate = IBlueprintSchemaScalar<ISchemaConstDateOpts, ISchemaConstDateModel, TSpecType>

/**
 * Schema model
 */
export interface ISchemaConstDateModel extends IModelNode<ISchemaConstDate> {
	value: TSpecType;
}

/**
 * Schema: Date scalar constant
 *
 * @param opts Schema options
 */
export function SchemaConstDate(opts: ISchemaConstDateOpts): ISchemaConstDate {

	const validators: IBlueprintSchemaValidatorHandler<TSpecType>[] = [
		ValidatorDate(opts.constraints || {})
	].concat(opts.validators || []);

	const errSeverity = opts.fallbackValue !== undefined ? DOC_ERROR_SEVERITY.WARNING : DOC_ERROR_SEVERITY.ERROR;

	const schema = createEmptySchema<ISchemaConstDate>("constDate", opts);

	const createModel = (dCtx: DesignContext, value: TSpecType, parent: TBlueprintSchemaParentNode,
		validationErrors: IBlueprintSchemaValidationError[]) => {

		return createModelNode(schema, dCtx, parent, validationErrors, {
			value: value
		});

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		const value = defaultValue !== null && defaultValue !== undefined ? defaultValue : opts.default !== undefined ? opts.default : null;
		const errors = validateDefaultValue(schema, validators, value);

		return createModel(
			dCtx,
			value,
			parent,
			errors
		);

	}

	schema.clone = (dCtx, modelNode, parent) => {
		return cloneModelNode(dCtx, modelNode, parent, {
			value: new Date(modelNode.value?.getTime())
		});
	};

	schema.destroy = (modelNode) => {

		modelNode.value = undefined;
		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent) => {

		// Check null
		if (!idtNode || (idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL)) {
			return schema.createDefault(dCtx, parent);
		}

		if (
			idtNode.type !== BP_IDT_TYPE.SCALAR || (
				idtNode.type == BP_IDT_TYPE.SCALAR &&
				idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.STRING &&
				idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.NULL
			)
		) {

			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.DATE_NOT_STRING,
					message: "Expecting a string",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);

		} else if (idtNode.value !== null && isNaN(Date.parse(idtNode.value as string))) {

			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.DATE_NOT_DATE,
					message: "Expecting a Date",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);

		}

		const value = idtNode.value !== null ? new Date(idtNode.value as string) : null;

		const errors = validateParsedValueAndReport(dCtx, idtNode, validators, value);

		return createModel(dCtx, value, parent, errors);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			return null;
		});

	};

	schema.serialize = (modelNode, path) => {

		if (modelNode.value !== null) {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.STRING,
				path: path,
				value: modelNode.value.toISOString()
			} as IBlueprintIDTScalar;

		} else {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.NULL,
				path: path,
				value: null
			} as IBlueprintIDTScalar;

		}

	};

	schema.render = (rCtx, modelNode, path, scope) => {

		return renderScalarNode(
			modelNode, scope,
			modelNode.value,
			opts.fallbackValue || opts.default || null
		);

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		return compileScalarNodeRender(
			cCtx, modelNode, path, errSeverity,
			modelNode.value !== null ? `new Date("${modelNode.value.toISOString()}")` : `null`,
			opts.fallbackValue || opts.default || null
		);

	};

	schema.validate = (rCtx, path, modelNodeId, value) => {
		return applyRuntimeValidators(rCtx, path, modelNodeId, validators, errSeverity, value);
	};

	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileRuntimeValidators(cCtx, path, modelNodeId, validators, errSeverity);
	};

	schema.castSpec = (_rCtx, _path, _modelNodeId, value) => {

		if (typeof value === "string") {

			const _v = Date.parse(value);

			if (!isNaN(_v)) {
				return new Date(_v);
			} else {
				return value;
			}

		} else if (typeof value === "number") {

			return new Date(value);

		} else {

			return value;

		}

	};

	schema.compileCastSpec = () => {

		/* eslint-disable indent, max-len */
		return `(v,pt)=>{${[
			`if(typeof v==="string"){`,
			`const _v=Date.parse(v);`,
			`return !isNaN(_v)?(new Date(_v)):v`,
			`}else if(typeof v==="number"){`,
			`return new Date(v)`,
			`}else{`,
			`return v`,
			`}`
		].join("")}}`
		/* eslint-enable indent, max-len */

	};

	schema.setValue = (modelNode, value, notify): void => {

		validateValueAndUpdateModel(modelNode, validators, value);
		modelNode.value = value;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}

	};

	schema.getValue = (modelNode) => {
		return modelNode.value;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaConstDate", [opts]);
	};

	schema.getTypeDescriptor = () => {
		return TypeDescDate({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = () => {
		return [];
	}

	/* @todo Disabled because of SchemaBuilder
	 * If user configures default value that is not validate due to constraints
	 * the error will be thrown and blueprints becomes invalid.
	 * This could be resolved when schema builder will be able to dynamically validate
	 * the value field against configured constraints.
	 */
	// if (opts.default !== null && opts.default !== undefined) {
	// 	validateDefaultValue(schema, validators, opts.default);
	// }

	return schema;

}
