/**
 * QR Code HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useCallback, useState } from "react";

import {
	Type,
	defineElementaryComponent,
	BP,
	createSubScope,
} from "@hexio_io/hae-lib-blueprint";

import { termsEditor } from "../../terms";
import {
	propGroups,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";

import BarcodeScannerComponent from "react-qr-barcode-scanner";

interface HAEComponentQrCodeReader_State {
	value: string;
	clearValue: () => void;
}

const HAEComponentQrCodeReader_Props = {

	valueData: BP.Prop(BP.OneOf({
		...termsEditor.schemas.qrBarReader.valueData,
		typeValueOpts: {
			...termsEditor.schemas.qrBarReader.valueData
		},
		defaultType: "QRBAR",
		types: {
			"QRBAR": {
				...termsEditor.schemas.qrBarReader.valueDataValues.qrbar,
				value: BP.Void({})
			},
			"QR": {
				...termsEditor.schemas.qrBarReader.valueDataValues.qr,
				value: BP.Void({})
			},
			"BAR": {
				...termsEditor.schemas.qrBarReader.valueDataValues.bar,
				value: BP.Void({})
			}
		},
		constraints: {
			required: true
		}
	}), 0, propGroups.common),

	enabled: BP.Prop(BP.Boolean({
		...termsEditor.schemas.field.enabled,
		default: true,
		fallbackValue: true,
		constraints: {
			required: true
		}
	}), 10, propGroups.state),

};

const HAEComponentQrCodeReader_Events = {
	scaned: {
		...termsEditor.schemas.qrBarReader.events.scaned
	}
};

const HAEComponentQrCode_Definition = defineElementaryComponent<
	typeof HAEComponentQrCodeReader_Props,
	HAEComponentQrCodeReader_State,
	typeof HAEComponentQrCodeReader_Events
>({

	...termsEditor.components.qrBarReader.component,

	name: "codereader",

	category: "form",

	icon: "mdi/qrcode-scan",

	events: HAEComponentQrCodeReader_Events,

	docUrl: "...",

	order: 220,

	props: HAEComponentQrCodeReader_Props,

	resolve: (spec, state, updateStateAsync) => {

		return state ?? {
			value: null,
			clearValue: () => {
				updateStateAsync((prevState) => ({ ...prevState, value: null }));
			}
		};
	},

	getScopeData: (spec, state) => {
		return {
			value: state.value,
			clearValue: state.clearValue
		};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}

});

const HAEComponentQrCodeReader_React: THAEComponentReact<typeof HAEComponentQrCode_Definition> = ({
	props,
	componentInstance
}) => {
	const { valueData, enabled } = props;

	const onUpdate = useCallback((err, result) => {
		if(
			(valueData.type === 'QRBAR' && result)
			|| (valueData.type === 'QR' && result?.format === 11)
			|| (valueData.type === 'BAR' && result?.format === 7)
		) {

			componentInstance.setState((prevState) => ({...prevState, value: result?.text || null}))

			componentInstance.eventTriggers.scaned((parentScope) => createSubScope(parentScope, { result: result?.text }))

		}
	}, [componentInstance])

	return <>
		{!enabled && <div>QR scanner is disabled</div>}
		{enabled && <BarcodeScannerComponent
			torch={true}
			onUpdate={onUpdate}
		/>}
	</>;
};

export const HAEComponentQrCodeReader: THAEComponentDefinition<typeof HAEComponentQrCode_Definition> = {
	...HAEComponentQrCode_Definition,
	reactComponent: HAEComponentQrCodeReader_React
};
