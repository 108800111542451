/**
 * Base events
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { termsEditor } from "@hexio_io/hae-lib-components";

export const BaseEvents = {
	click: {
		...termsEditor.schemas.events.click,
		icon: "mdi/gesture-tap"
	}
};
