/**
 * Button schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	TSchemaConstObjectPropsSpec,
} from "@hexio_io/hae-lib-blueprint";

import { TextProps, TextSchema, TextValueSchema } from "./Text";
import { termsEditor } from "../terms";
import { IconProps, IconSchema } from "./Icon";
import { propGroups } from "./propGroups";
import { ForegroundColorSchema } from "../SharedSchemas/Color";

/**
 * Defaults
 */
export const ENABLED_default = true;
export const ACTIVE_default = false;
export const WORKING_default = false;

/**
 * Button props
 */
export const ButtonProps = {

	labelText: BP.Prop(TextSchema({
		alias: "text_buttonLabel",
		//...termsEditor.schemas.button.labelText,
		props: {
			value: BP.Prop(TextValueSchema({
				alias: "textValue_buttonLabel",
				default: "Button"
			}), TextProps.value.order, TextProps.value.group),

			foregroundColor: BP.Prop(ForegroundColorSchema({
				alias: "foregroundColor_buttonLabelText",
				default: "INHERIT"
			}), TextProps.foregroundColor.order, TextProps.foregroundColor.group),

			overflow: BP.Prop(BP.Void({}))
		}
	}), 0, propGroups.common),

	labelIcon: BP.Prop(IconSchema({
		alias: "icon_buttonLabel",
		props: {
			foregroundColor: BP.Prop(ForegroundColorSchema({
				alias: "foregroundColor_buttonLabelIcon",
				default: "INHERIT"
			}), IconProps.foregroundColor.order, IconProps.foregroundColor.group),
		}
	}), 10, propGroups.common),

	tooltip: BP.Prop(BP.String({
		...termsEditor.schemas.common.tooltip,
		default: ""
	}), 20, propGroups.common),

	enabled: BP.Prop(BP.Boolean({
		...termsEditor.schemas.button.enabled,
		default: ENABLED_default,
		fallbackValue: ENABLED_default,
		constraints: {
			required: true
		}
	}), 0, propGroups.state),

	active: BP.Prop(BP.Boolean({
		...termsEditor.schemas.button.active,
		default: ACTIVE_default,
		fallbackValue: ACTIVE_default,
		constraints: {
			required: true
		}
	}), 10, propGroups.state),

	working: BP.Prop(BP.Boolean({
		...termsEditor.schemas.button.working,
		default: WORKING_default,
		fallbackValue: WORKING_default,
		constraints: {
			required: true
		}
	}), 20, propGroups.state)

};

/**
 * Button props type
 */
export type TButtonProps = Partial<TSchemaConstObjectPropsSpec<typeof ButtonProps>>;
