/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	TEditorApiDeploymentEnvironmentsResponse,
	TEditorApiDeploymentResponse,
	TEditorApiDeploymentsResponse,
	TEditorApiDeploymentStatusResponse
} from "../api";
import { AppStatusEnum } from "../api/editor/BeApi";
import {
	MOCKED_APP_ENV_ID,
	MOCKED_APP_NAME,
	MOCKED_APP_URL,
	MOCKED_CERTIFICATE,
	MOCKED_EMAIL,
	MOCKED_IMAGE,
	MOCKED_IMAGE_ID,
	MOCKED_URL
} from "./MockedConst";

export const MOCKED_DEPLOYMENT_STATUS = {
	podName: "a-demo-pod",
	resourceName: "a-demo",
	envVars: [],
	appEnvId: MOCKED_APP_ENV_ID,
	environmentName: "production",
	currentVersion: "v3",
	lastModified: new Date().toISOString(),
	startTime: new Date().toISOString(),
	runningTime: "00:10:00",
	image: MOCKED_IMAGE,
	imageId: MOCKED_IMAGE_ID,
	appUrl: MOCKED_APP_URL,
	url: MOCKED_URL,
	certificate: "Unknown",
	isProtected: false,
	isDnsChecked: true,
	restartCount: 0,
	stopped: false,
	idle: false,
	name: "production",
	appName: MOCKED_APP_NAME,
	isEditor: false,
	status: AppStatusEnum.Running
} as TEditorApiDeploymentStatusResponse;

export const MOCKED_DEPLOYMENT = {
	deploymentId: 'demo',
	appUrl: MOCKED_APP_URL,
	appEngineImage: 'registry.gitlab.com/hexio-a-s/app-engine/hae/app:v-demo',
	repositoryUrl: 'repo.adapptio.cloud/demo/demo.git',
	version: 'v3',
	appEnvironmentName: 'production',
	status: 'Success',
	errorMessage: null,
	created: new Date().toISOString(),
	createdBy: MOCKED_EMAIL,
	modified: new Date().toISOString(),
	modifiedBy: MOCKED_EMAIL
} as TEditorApiDeploymentResponse;

export const MOCKED_DEPLOYMENTS = {
	items: [MOCKED_DEPLOYMENT],
	limit: 1,
	offset: 0,
	count: 0
} as TEditorApiDeploymentsResponse;

export const MOCKED_DEPLOYMENT_ENVIRONMENT = {
	appEnvId: MOCKED_APP_ENV_ID,
	environmentName: "production",
	currentVersion: "v3",
	lastModified: new Date().toISOString(),
	startTime: new Date().toISOString(),
	runningTime: "00:10:00",
	image: MOCKED_IMAGE,
	imageId: MOCKED_IMAGE_ID,
	appUrl: MOCKED_APP_URL,
	url: MOCKED_URL,
	certificate: MOCKED_CERTIFICATE,
	isProtected: false,
	isDnsChecked: true,
	restartCount: 0,
	stopped: false,
	idle: false,
	name: "production",
	appName: MOCKED_APP_NAME,
	isEditor: false,
	status: "Running"
};

export const MOCKED_DEPLOYMENT_ENVIRONMENTS = [MOCKED_DEPLOYMENT_ENVIRONMENT] as TEditorApiDeploymentEnvironmentsResponse;