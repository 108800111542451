/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	TBlueprintIDTNode,
	TBlueprintIDTNodePath
} from "../../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts, TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaOpts,
	TGetBlueprintSchemaSpec
} from "../../Schema/IBlueprintSchema";
import { IModelNode, IModelNodeCompileResult, IModelNodeInfo, MODEL_CHANGE_TYPE, TGenericModelNode } from "../../Schema/IModelNode";
import { ModelNodeManipulationError } from "../../Schema/ModelNodeManipulationError";
import {
	applyRuntimeValidators,
	cloneModelNode,
	compileRuntimeValidators,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	logParseValidationErrors
} from "../../Schema/SchemaHelpers";
import { applyCodeArg, escapeString, inlineValue } from "../../Context/CompileUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { DesignContext } from "../../Context/DesignContext";
import { RuntimeContext } from "../../Context/RuntimeContext";
import { TModelPath } from "../../Shared/TModelPath";
import { SchemaConstString, ISchemaConstString } from "./SchemaConstString";
import { IBlueprintSchemaValidationError, IBlueprintSchemaValidatorHandler } from "../../Validator/IBlueprintSchemaValidator";
import { IValidatorMapOpts, ValidatorMap } from "../../validators/ValidatorMap";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { TypeDescMap } from "../../Shared/ITypeDescriptor";
import { onEvent } from "@hexio_io/hae-lib-shared";
import { IScope } from "../../Shared/Scope";

/**
 * Schema spec
 */
export type ISchemaConstMapSpec<TValueSchema extends TGenericBlueprintSchema>
	= { [K: string]: TGetBlueprintSchemaSpec<TValueSchema> }

/**
 * Schema model - single mapping entry
 */
export interface ISchemaConstMapModelEntry<
	TValueSchema extends TGenericBlueprintSchema
> {
	key: TGetBlueprintSchemaModel<ISchemaConstString>
	value: TGetBlueprintSchemaModel<TValueSchema>,
	hasDuplicateKey: boolean;
}

/**
 * Schema model
 */
export interface ISchemaConstMapModel<
	TValueSchema extends TGenericBlueprintSchema
> extends IModelNode<ISchemaConstMap<TValueSchema>> {
	items: ISchemaConstMapModelEntry<TValueSchema>[];
	hasDuplicateKeys: boolean;
}

/**
 * Schema options
 */
export interface ISchemaConstMapOpts<
	TValueSchema extends TGenericBlueprintSchema
> extends IBlueprintSchemaOpts {
	/** Schema representing a map value */
	value: TValueSchema;
	/** Options for a schema representing a map key */
	keyOpts?: TGetBlueprintSchemaOpts<ISchemaConstString>;
	/** Default value for a new node */
	default?: TSchemaConstMapDefault<TValueSchema>;
	/** Base validation constraints */
	constraints?: IValidatorMapOpts;
	/** Custom validators */
	validators?: IBlueprintSchemaValidatorHandler<ISchemaConstMapSpec<TValueSchema>>[];
	/** Fallback value to return when validation fails */
	fallbackValue?: ISchemaConstMapSpec<TValueSchema>;
	/** If specified the key name will be propagated to child scope's metadata under property specified by this option */
	provideKeyToScopeMetaAs?: string;
	/** If and how to display child nodes in editor outline */
	outlineOptions?: {
		displayChildren?: boolean;
		allowAddElement?: boolean;
	};
	/** Returns custom meta-data for an outline view */
	getElementModelNodeInfo?: (pair: ISchemaConstMapModelEntry<TValueSchema>, index: number) => IModelNodeInfo;
}

/**
 * Default value
 */
export type TSchemaConstMapDefault<TValueSchema extends TGenericBlueprintSchema>
	= { [K: string]: TGetBlueprintSchemaDefault<TValueSchema> };

export interface ISchemaConstMap<
	TValueSchema extends TGenericBlueprintSchema
> extends IBlueprintSchema<
	ISchemaConstMapOpts<TValueSchema>,
	ISchemaConstMapModel<TValueSchema>,
	ISchemaConstMapSpec<TValueSchema>,
	TSchemaConstMapDefault<TValueSchema>
> {
	addElement: (
		modelNode: ISchemaConstMapModel<TValueSchema>,
		keyDefault?: string,
		itemDefault?: TGetBlueprintSchemaDefault<TValueSchema>,
		notify?: boolean
	) => ISchemaConstMapModelEntry<TValueSchema>;
	addElementModel: (
		modelNode: ISchemaConstMapModel<TValueSchema>,
		keyDefault: string,
		valueModel: TGetBlueprintSchemaModel<TValueSchema>,
		notify?: boolean
	) => ISchemaConstMapModelEntry<TValueSchema>;
	removeElement: (modelNode: ISchemaConstMapModel<TValueSchema>, index: number, notify?: boolean) => void;
	moveElement: (modelNode: ISchemaConstMapModel<TValueSchema>, currentIndex: number, newIndex: number, notify?: boolean) => void;
	getUniqueKey: (modelNode: ISchemaConstMapModel<TValueSchema>, baseName: string, alwaysAddNumber?: boolean) => string;
	getElementModelNodeInfo: (modelNode: ISchemaConstMapModel<TValueSchema>, index: number) => IModelNodeInfo;
}

/**
 * Schema: Map
 *
 * @param opts Schema options
 */
export function SchemaConstMap<
	TValueSchema extends TGenericBlueprintSchema
>(
	opts: ISchemaConstMapOpts<TValueSchema>
): ISchemaConstMap<TValueSchema> {

	type TValueModel = TGetBlueprintSchemaModel<TValueSchema>;
	type TValueSpec = TGetBlueprintSchemaSpec<TValueSchema>;
	type TModel = ISchemaConstMapModel<TValueSchema>;
	type TSpec = ISchemaConstMapSpec<TValueSchema>;

	const defaultValidator = ValidatorMap(opts.constraints || {});
	const validators: IBlueprintSchemaValidatorHandler<TSpec>[] = opts.validators || [];

	const errSeverity = opts.fallbackValue !== undefined ? DOC_ERROR_SEVERITY.WARNING : DOC_ERROR_SEVERITY.ERROR;

	const schema = createEmptySchema<ISchemaConstMap<TValueSchema>>("constMap", opts);
	const keySchema = SchemaConstString({
		// @todo Translate
		label: "Key",
		...opts.keyOpts
	});

	function checkDuplicateKeys(modelNode: TModel): boolean {

		const keySet = new Set<string>();
		let isDuplicate = false;

		for (let i = 0; i < modelNode.items.length; i++) {
			if (keySet.has(modelNode.items[i].key.value)) {
				isDuplicate = true;
				modelNode.items[i].hasDuplicateKey = true;
			} else {
				modelNode.items[i].hasDuplicateKey = false;
				keySet.add(modelNode.items[i].key.value);
			}
		}

		return isDuplicate;

	}

	const assignParentToChildrenOf = (srcModel) => {
		const mapItems = srcModel.items

		for(let i = 0; i < mapItems.length; i++){
			const mapChildItemModelNode = mapItems[i];

			const mapItemKey = mapChildItemModelNode.key
			mapItemKey.schema.assignParent(mapItemKey, srcModel, false)

			const mapItemValue = mapChildItemModelNode.value
			mapItemValue.schema.assignParent(mapItemValue, srcModel, false)
		}
		return srcModel
	}

	function bindKeyUpdateListener(modelNode: TModel, item: ISchemaConstMapModelEntry<TValueSchema>) {

		onEvent(item.key.changeEvent, () => {
			checkDuplicateKeys(modelNode);
		});

	}

	const createModel = (
		dCtx: DesignContext,
		items: ISchemaConstMapModelEntry<TValueSchema>[],
		parent: TBlueprintSchemaParentNode,
		validationErrors: IBlueprintSchemaValidationError[]
	) => {

		const modelNode = createModelNode(schema, dCtx, parent, validationErrors, {
			items: items,
			hasDuplicateKeys: null,
		});

		const model = assignParentToChildrenOf(modelNode)

		model.items.forEach((item) => bindKeyUpdateListener(model, item));

		model.hasDuplicateKeys = checkDuplicateKeys(model);

		return model;

	}

	schema.createDefault = (dCtx, parent, defaultValue: TSchemaConstMapDefault<TValueSchema>): TModel => {

		const validationErrors: IBlueprintSchemaValidationError[] = defaultValidator.validate(defaultValue);
		const items: ISchemaConstMapModelEntry<TValueSchema>[] = [];

		Object.entries(defaultValue || {}).forEach(([propName, propValue]) => {
			items.push({
				key: keySchema.createDefault(dCtx, null, propName),
				value: opts.value.createDefault(dCtx, null, propValue) as TValueModel,
				hasDuplicateKey: null
			})
		});

		return createModel(dCtx, items, parent, validationErrors);

	}

	schema.clone = (dCtx, modelNode: TModel, parent): TModel => {

		const items: ISchemaConstMapModelEntry<TValueSchema>[] = [];

		for (let i = 0; i < modelNode.items.length; i++) {
			items.push({
				key: keySchema.clone(dCtx, modelNode.items[i].key, null),
				value: opts.value.clone(dCtx, modelNode.items[i].value, null) as TValueModel,
				hasDuplicateKey: null
			})
		}

		const modelClone = cloneModelNode(dCtx, modelNode, parent, {
			items: items,
			hasDuplicateKeys: modelNode.hasDuplicateKeys
		});

		const clone = assignParentToChildrenOf(modelClone)

		clone.items.forEach((item) => bindKeyUpdateListener(clone, item));

		return clone;

	};

	schema.destroy = (modelNode: TModel): void => {

		modelNode.items.forEach((item) => {
			keySchema.destroy(item.key);
			opts.value.destroy(item.value);
		});

		modelNode.items = [];
		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx: DesignContext, idtNode: TBlueprintIDTNode, parent): TModel => {

		// Check null
		if (!idtNode || (idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL)) {
			return schema.createDefault(dCtx, parent);
		}

		if (!idtNode || idtNode.type !== BP_IDT_TYPE.MAP) {

			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.MAP_NOT_MAP,
					message: "Expecting a map",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);

		}

		const items: ISchemaConstMapModelEntry<TValueSchema>[] = [];

		for (let i = 0; i < idtNode.items.length; i++) {
			const element = idtNode.items[i];

			const keyModel = keySchema.createDefault(dCtx, null);
			keySchema.setValue(keyModel, element.key.value as string, false);

			if (keyModel.validationErrors.length > 0) {
				logParseValidationErrors(dCtx, element.key, keyModel.validationErrors);
				continue;
			}

			if (element.key && opts.value.provideCompletion && idtNode.parseInfo && element.key?.parseInfo) {
				opts.value.provideCompletion(
					dCtx,
					{
						uri: idtNode.parseInfo.loc.uri,
						range: {
							start: {
								line: element.key.parseInfo.loc.range.end.line,
								col: element.key.parseInfo.loc.range.end.col + 2
							},
							end: element.parseInfo.loc.range.end
						}
					},
					idtNode.parseInfo.loc.range.start.col + 1,
					element.value
				);
			}

			items.push({
				key: keyModel,
				value: opts.value.parse(dCtx, element.value, parent) as TValueModel,
				hasDuplicateKey: null
			});
		}

		return createModel(dCtx, items, parent, []);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			return null;
		});

	};

	schema.serialize = (modelNode: TModel, path: TBlueprintIDTNodePath): TBlueprintIDTNode => {

		return {
			type: BP_IDT_TYPE.MAP,
			path: path,

			items: modelNode.items.map((item) => ({
				type: BP_IDT_TYPE.MAP_ELEMENT,
				path: path.concat([`[${item.key.value}]`]),
				key: {
					type: BP_IDT_TYPE.SCALAR,
					subType: BP_IDT_SCALAR_SUBTYPE.STRING,
					path: path.concat([`{${item.key.value}}`]),
					value: item.key.value
				},
				value: opts.value.serialize(item.value, path.concat([item.key.value]))
			}))
		} as IBlueprintIDTMap;

	};

	schema.render = (
		rCtx: RuntimeContext,
		modelNode: TModel,
		path: TModelPath,
		scope: IScope,
		prevSpec?: TSpec
	): TSpec => {

		modelNode.lastScopeFromRender = scope;

		const value = {};

		for (let i = 0; i < modelNode.items.length; i++) {
			const _key = modelNode.items[i].key.value;

			const _scope: IScope = opts.provideKeyToScopeMetaAs
				? { ...scope, metaData: { ...scope.metaData, [opts.provideKeyToScopeMetaAs]: _key } }
				: scope;

			value[_key] = opts.value.render(
				rCtx,
				modelNode.items[i].value,
				path.concat([`{${_key}}`]),
				_scope,
				prevSpec && typeof prevSpec === "object" ? prevSpec[_key] : undefined
			) as TValueSpec;
		}

		const isValid = applyRuntimeValidators<TSpec>(rCtx, path, modelNode.nodeId, validators, errSeverity, value);

		if (isValid) {
			return value;
		} else {
			return opts.fallbackValue || {};
		}

	};

	schema.compileRender = (cCtx, modelNode: TModel, path: TModelPath): IModelNodeCompileResult => {

		// Pre-validate - no need to do this here because existing map is always a map

		let isScoped = false;

		const propsCmp: { [K: string]: IModelNodeCompileResult } = {};

		for (let i = 0; i < modelNode.items.length; i++) {

			const key = modelNode.items[i].key.value;
			propsCmp[key] = opts.value.compileRender(cCtx, modelNode.items[i].value, path.concat([`{${key}}`]));

		}

		const propsCode = Object.entries(propsCmp).map(([propName, cmpValue]) => {

			isScoped = isScoped || cmpValue.isScoped;
			const _k = escapeString(propName);

			if (opts.provideKeyToScopeMetaAs) {
				// eslint-disable-next-line max-len
				return `"${_k}":((s)=>(${applyCodeArg(cmpValue, `pv?pv["${_k}"]:undefined`, `pt.concat(["${_k}"])`)}))({...s,metaData:{...s.metaData,"${escapeString(opts.provideKeyToScopeMetaAs)}":"${_k}"}})`;
			} else {
				return `"${_k}":(${applyCodeArg(cmpValue, `pv?pv["${_k}"]:undefined`, `pt.concat(["${_k}"])`)})`;
			}

		});

		const valueCode = `{${propsCode.join(",")}}`;

		const additionalValidators = compileRuntimeValidators(cCtx, path, modelNode.nodeId, validators, errSeverity)

		if (additionalValidators !== null) {

			return {
				isScoped: true,
				// eslint-disable-next-line max-len
				code: `(s,pv,pt)=>{const _v=${valueCode};const _iv=${additionalValidators}(_v,pt);return _iv?_v:${inlineValue(opts.fallbackValue || {})}}`
			};

		} else {

			return {
				isScoped,
				code: isScoped ? `(s,pv,pt) => (${valueCode})` : `${valueCode}`
			};

		}

	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {

		// Validate base
		const selfValid = applyRuntimeValidators<TSpec>(rCtx, path, modelNodeId, [defaultValidator].concat(validators), errSeverity, value);
		let childValid = true;

		// Call child validators
		if (validateChildren && value instanceof Object) {
			for (const k in value) {
				childValid = childValid && keySchema.validate(rCtx, path.concat([`{${k}}`]), modelNodeId, k, false); // Validate key
				// eslint-disable-next-line max-len
				childValid = childValid && opts.value.validate(rCtx, path.concat([k]), modelNodeId, value[k], validateChildren); // Validate value
			}
		}

		return selfValid && childValid;

	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {

		const expressions = [];

		const selfValidator = compileRuntimeValidators(cCtx, path, modelNodeId, [defaultValidator].concat(validators), errSeverity);

		if (selfValidator !== null) {
			expressions.push(`(${selfValidator})(v,pt)`);
		}

		if (validateChildren) {

			const keyValidator = keySchema.compileValidate(cCtx, path.concat(["$key"]), modelNodeId, validateChildren);
			const valueValidator = opts.value.compileValidate(cCtx, path.concat(["$value"]), modelNodeId, validateChildren);

			const itemExpressions = [];

			if (keyValidator) {
				itemExpressions.push(`(${keyValidator})(k,pt.concat(["{"+k+"}"]))`);
			}

			if (valueValidator) {
				itemExpressions.push(`(${valueValidator})(v[k],pt.concat([k]))`);
			}

			if (itemExpressions.length > 0) {
				// eslint-disable-next-line max-len
				expressions.push(`(v instanceof Object?Object.keys(v).map((k)=>(${itemExpressions.join("&&")})).reduce((s,c)=>s&&c,true):true)`);
			}

		}

		if (expressions.length === 0) {
			return null;
		} else {
			return cCtx.addGlobalValue(`(v,pt)=>${expressions.join("&&")}`);
		}

	};

	schema.addElement = (
		modelNode: TModel, keyDefault?: string, valueDefault?: TGetBlueprintSchemaDefault<TValueSchema>, notify?: boolean
	) => {

		const item = {
			key: keySchema.createDefault(modelNode.ctx, modelNode, keyDefault),
			value: opts.value.createDefault(modelNode.ctx, modelNode, valueDefault) as TValueModel,
			hasDuplicateKey: null
		};

		modelNode.items.push(item);
		bindKeyUpdateListener(modelNode, item);

		modelNode.hasDuplicateKeys = checkDuplicateKeys(modelNode);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return item;

	}

	schema.addElementModel = (modelNode: TModel, keyDefault: string, valueModel: TValueModel, notify?: boolean) => {
		const item = {
			key: keySchema.createDefault(modelNode.ctx, modelNode, keyDefault),
			value: valueModel,
			hasDuplicateKey: null
		};

		valueModel.schema.assignParent(valueModel, modelNode, false);
		modelNode.items.push(item);
		bindKeyUpdateListener(modelNode, item);

		modelNode.hasDuplicateKeys = checkDuplicateKeys(modelNode);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

		return item;
	}

	schema.removeElement = (modelNode: TModel, index: number, notify?: boolean) => {

		if (index < 0 || index >= modelNode.items.length) {
			throw new ModelNodeManipulationError(schema.name, schema.opts, `index ${index} out of range`);
		}

		keySchema.destroy(modelNode.items[index].key);
		opts.value.destroy(modelNode.items[index].value);
		modelNode.items.splice(index, 1);

		modelNode.hasDuplicateKeys = checkDuplicateKeys(modelNode);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	}

	schema.moveElement = (modelNode: TModel, currentIndex: number, newIndex: number, notify?: boolean): void => {

		if (currentIndex < 0 || currentIndex >= modelNode.items.length) {
			throw new ModelNodeManipulationError(schema.name, schema.opts, `currentIndex ${currentIndex} out of range`);
		}

		if (newIndex < 0 || newIndex >= modelNode.items.length) {
			throw new ModelNodeManipulationError(schema.name, schema.opts, `newIndex ${newIndex} out of range`);
		}

		const element = modelNode.items.splice(currentIndex, 1);
		modelNode.items.splice(newIndex, 0, element[0]);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	}

	schema.getUniqueKey = (modelNode, baseName, alwaysAddNumber) => {
		let i = 0;
		let identifier: string;

		const identifierIndex = new Set(modelNode.items.map(item => item.key.value));

		do {
			identifier = baseName + (i > 0 ? i : (alwaysAddNumber ? 1 : ""));
			i++;
		} while (identifierIndex.has(identifier));

		return identifier;
	};

	schema.getTypeDescriptor = (modelNode) => {

		const itemsTypeDesc = {};
		if(modelNode) {
			modelNode.items.forEach((item) => {
				itemsTypeDesc[item.key.value] = item.value.schema.getTypeDescriptor(item.value);
			});
		}else{
			itemsTypeDesc["_key"] = opts.value.getTypeDescriptor()
		}


		return TypeDescMap({
			items: itemsTypeDesc,
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaConstMap", [opts]);
	};

	schema.getChildNodes = (modelNode) => {
		const children = [];

		for (let i = 0; i < modelNode.items.length; i++) {
			children.push({
				key: "items." + i + ".key",
				node: modelNode.items[i].key
			}, {
				key: "items." + i + ".value",
				node: modelNode.items[i].value
			});
		}

		return children;
	}

	schema.getElementModelNodeInfo = (modelNode, index) => {
		const item = modelNode.items[index];

		if (!item) {
			throw new Error("Item index out of range.");
		}

		if (opts.getElementModelNodeInfo) {
			return opts.getElementModelNodeInfo(item, index);
		} else {
			return {
				icon: "mdi/arrow-right",
				label: item.key.value || "#" + (index + 1)
			};
		}
	};

	return schema;

}
