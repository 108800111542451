/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { DesignContext } from "../../Context/DesignContext";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { ISchemaImportExport } from "../../ExportImportSchema/ExportTypes";
import {
	IBlueprintSchema,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchemaScalarWithValue,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel
} from "../../Schema/IBlueprintSchema";
import {
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	validateAsNotSupported,
	assignParentToModelProps
} from "../../Schema/SchemaHelpers";
import { ISchemaConstBoolean, SchemaConstBoolean } from "../const/SchemaConstBoolean";
import { ISchemaConstObject, ISchemaConstObjectOptsProp, Prop, SchemaConstObject } from "../const/SchemaConstObject";
import { ISchemaBuilderOpts, SchemaBuilderBaseProps, TCompareSchemaBuilderProps, TSchemaBuilderBaseProps } from "./SchemaBuilderShared";
import { IModelNode } from "../../Schema/IModelNode";
import { TypeDescAny } from "../../Shared/ITypeDescriptor";
import { applyCodeArg, escapeString } from "../../Context/CompileUtil";
import { ISchemaConstString, SchemaConstString } from "../const/SchemaConstString";
import { ISchemaConstInteger, SchemaConstInteger } from "../const/SchemaConstInteger";
import { ISchemaConstFloat, SchemaConstFloat } from "../const/SchemaConstFloat";
import { ISchemaConstArray, SchemaConstArray } from "../const/SchemaConstArray";
import { ISchemaConstEnumOpts } from "../const/SchemaConstEnum";
import { SchemaDeclarationError } from "../../Schema/SchemaDeclarationError";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY, IDocumentError } from "../../Shared/IDocumentError";

type TSchemaBuilderEnumValueConst = ISchemaConstString|ISchemaConstInteger|ISchemaConstFloat;

export type TSchemaBuilderEnumPropsSchema = ISchemaConstObject<
	TSchemaBuilderBaseProps & {
		options: ISchemaConstObjectOptsProp<
			ISchemaConstArray<ISchemaConstObject<{
				value: ISchemaConstObjectOptsProp<TSchemaBuilderEnumValueConst>;
				label: ISchemaConstObjectOptsProp<ISchemaConstString>;
				description: ISchemaConstObjectOptsProp<ISchemaConstString>;
				icon: ISchemaConstObjectOptsProp<ISchemaConstString>;
			}>>
		>;
		default: ISchemaConstObjectOptsProp<TSchemaBuilderEnumValueConst>;
		fallbackValue: ISchemaConstObjectOptsProp<TSchemaBuilderEnumValueConst>;
		constraints: ISchemaConstObjectOptsProp<ISchemaConstObject<{
			required: ISchemaConstObjectOptsProp<ISchemaConstBoolean>;
		}>>
	}
>;

/**
 * Development helper to check types - when resolves to "true" then the props schema is correct and up to date.
 * Otherwise you have an error somewhere. It compares if SchemaBuilder...PropsSchema output (spec) equals to the interface
 * that describes options of a target schema the builder represents.
 */
type _TypeCheck = TCompareSchemaBuilderProps<
	ISchemaConstEnumOpts<TGenericBlueprintSchemaScalarWithValue<string | number>>,
	TSchemaBuilderEnumPropsSchema,
	"value"|"options"|"default"
>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __HERE_SHOULD_BE_NO_TYPE_ERRORS__: _TypeCheck = true;

export enum SCHEMA_BUILDER_ENUM_VALUE_TYPE {
	STRING = "string",
	INTEGER = "integer",
	FLOAT = "float"
}

/**
 * Opts Schema
 */
export interface ISchemaBuilderEnumOpts extends ISchemaBuilderOpts {
	valueType: SCHEMA_BUILDER_ENUM_VALUE_TYPE;
}

/**
 * Schema Model
 */
export interface ISchemaBuilderEnumModel extends IModelNode<ISchemaBuilderEnum> {
	props: TGetBlueprintSchemaModel<TSchemaBuilderEnumPropsSchema>;
}

export type TSchemaBuilderEnumSpec = ISchemaImportExport;
export type TSchemaBuilderEnumDefault = TGetBlueprintSchemaDefault<TSchemaBuilderEnumPropsSchema>;

/**
 * Schema Builder Boolean Schema
 */
export interface ISchemaBuilderEnum extends IBlueprintSchema<
	ISchemaBuilderEnumOpts,
	ISchemaBuilderEnumModel,
	ISchemaImportExport,
	TSchemaBuilderEnumDefault
> { }

/**
 * Schema Builder: Boolean
 *
 * @param opts Schema options
 */
export function SchemaBuilderEnum(opts: ISchemaBuilderEnumOpts): ISchemaBuilderEnum {

	type TPropsModel = TGetBlueprintSchemaModel<TSchemaBuilderEnumPropsSchema>;

	const schema = createEmptySchema<ISchemaBuilderEnum>("builderEnum", opts);

	let valueConstSchema;

	switch(opts.valueType) {
		case SCHEMA_BUILDER_ENUM_VALUE_TYPE.STRING:
			valueConstSchema = SchemaConstString;
			break;
		case SCHEMA_BUILDER_ENUM_VALUE_TYPE.INTEGER:
			valueConstSchema = SchemaConstInteger;
			break;
		case SCHEMA_BUILDER_ENUM_VALUE_TYPE.FLOAT:
			valueConstSchema = SchemaConstFloat;
			break;
		default:
			throw new SchemaDeclarationError(schema.name, schema.opts, `Unsupported value schema builder: ${opts.valueType}`);
	}

	const propsSchema: TSchemaBuilderEnumPropsSchema = SchemaConstObject({
		constraints: opts.constraints,
		props: {
			...SchemaBuilderBaseProps,
			options: Prop(SchemaConstArray({
				label: "Enum Options",
				constraints: {
					required: true,
					minItems: 1
				},
				items: SchemaConstObject({
					label: "Option configuration",
					constraints: {
						required: true
					},
					props: {
						value: Prop(valueConstSchema({
							label: "Option Value",
							constraints: {
								required: true
							}
						}), 10),
						label: Prop(SchemaConstString({
							label: "Option Label",
							constraints: {
								required: true
							}
						}), 20),
						description: Prop(SchemaConstString({
							label: "Option Description"
						}), 30),
						icon: Prop(SchemaConstString({
							label: "Option Icon"
						}), 40)
					}
				})
			}), 50),
			default: Prop(valueConstSchema({
				label: "Default Value",
				description: "Default field value."
			}), 60),
			fallbackValue: Prop(valueConstSchema({
				label: "Fallback value",
				description: "Value which will be used when a field value is not valid."
			}), 70),
			constraints: Prop(SchemaConstObject({
				label: "Constraints",
				description: "Validation rules.",
				props: {
					required: Prop(SchemaConstBoolean({
						label: "Required",
						description: "If a value is required."
					}), 10)
				}
			}), 80)
		}
	});

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, ["props"])
	}

	const createModel = (
		dCtx: DesignContext,
		propsModel: TPropsModel,
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx, parent,[], {
			props: propsModel
		});

		const model = assignParentToChildrenOf(modelNode)

		model.initRequiredValid = propsModel.initRequiredValid;

		return model;

	};

	const validateModel = (propsModel: TPropsModel): IDocumentError[] => {

		const errors = [];

		const defaultValue = propsModel.props.default.value;
		const fallbackValue = propsModel.props.fallbackValue.value;

		const optionList = propsModel.props.options.items.map((item) => {
			return item.props.value.value
		});

		// Validate props
		const validDefault = defaultValue === null ||
			(defaultValue !== null && optionList.includes(defaultValue));

		const validFallback = fallbackValue === null ||
			(fallbackValue !== null && optionList.includes(fallbackValue));

		if (!validDefault) {
			errors.push({
				severity: DOC_ERROR_SEVERITY.ERROR,
				name: DOC_ERROR_NAME.ENUM_OUT_OF_RANGE,
				// eslint-disable-next-line max-len
				message: `Schema default value is out of range. Expecting value to be one of [ ${optionList.join(", ")} ]`
			});
		}

		if (!validFallback) {
			errors.push({
				severity: DOC_ERROR_SEVERITY.ERROR,
				name: DOC_ERROR_NAME.ENUM_OUT_OF_RANGE,
				// eslint-disable-next-line max-len
				message: `Schema fallback value is out of range. Expecting value to be one of [ ${optionList.join(", ")} ]`
			});
		}

		return errors;

	}

	schema.createDefault = (dCtx, parent, defaultValue) => {

		const propsModel = propsSchema.createDefault(dCtx, null, defaultValue);
		return createModel(dCtx, propsModel, parent);

	};

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedPropsModel = propsSchema.clone(dCtx, modelNode.props, null);

		const clone = cloneModelNode(dCtx, modelNode, parent,{
			props: clonedPropsModel
		})

		return assignParentToChildrenOf(clone)

	};

	schema.destroy = (modelNode) => {

		propsSchema.destroy(modelNode.props);
		modelNode.props = undefined;
		destroyModelNode(modelNode);

	};

	schema.parse = (dCtx, idtNode, parent) => {

		const propsModel = propsSchema.parse(dCtx, idtNode, parent);
		return createModel(dCtx, propsModel, parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		if (propsSchema.provideCompletion) {
			propsSchema.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}

	};

	schema.serialize = (modelNode, path) => {

		return propsSchema.serialize(modelNode.props, path);

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		// Validate model
		const modelErrors = validateModel(modelNode.props);

		if (modelErrors.length > 0) {
			modelErrors.forEach((err) => rCtx.logRuntimeError({
				...err,
				modelPath: path,
				modelNodeId: modelNode.nodeId
			}));
		}

		// Determine value schema
		let valueSchemaName;

		switch(opts.valueType) {
			case SCHEMA_BUILDER_ENUM_VALUE_TYPE.STRING:
				valueSchemaName = "SchemaConstString";
				break;
			case SCHEMA_BUILDER_ENUM_VALUE_TYPE.INTEGER:
				valueSchemaName = "SchemaConstInteger";
				break;
			case SCHEMA_BUILDER_ENUM_VALUE_TYPE.FLOAT:
				valueSchemaName = "SchemaConstFloat";
				break;
		}

		const prevPropsSpec = prevSpec?._type === "SchemaValue" ? prevSpec._values[0]._values[0] : prevSpec?._values?.[0];
		const propsSpec = propsSchema.render(rCtx, modelNode.props, path, scope, prevPropsSpec);

		const schemaSpec = {
			...propsSpec,
			value: {
				_type: "Schema",
				_name: valueSchemaName,
				_values: [{
					default: propsSpec.default
				}]
			}
		}

		const exportData = {
			_type: "Schema",
			_name: "SchemaConstEnum",
			_values: [schemaSpec]
		};

		if (opts.constantOnly === true) {
			return exportData;
		} else {
			return {
				_type: "Schema",
				_name: "SchemaValue",
				_values: [exportData, schemaSpec]
			};
		}

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		// Validate model
		const modelErrors = validateModel(modelNode.props);

		if (modelErrors.length > 0) {
			modelErrors.forEach((err) => cCtx.logCompileError({
				...err,
				modelPath: path,
				modelNodeId: modelNode.nodeId
			}));
		}

		// Determine value schema
		let valueSchemaName;

		switch(opts.valueType) {
			case SCHEMA_BUILDER_ENUM_VALUE_TYPE.STRING:
				valueSchemaName = "SchemaConstString";
				break;
			case SCHEMA_BUILDER_ENUM_VALUE_TYPE.INTEGER:
				valueSchemaName = "SchemaConstInteger";
				break;
			case SCHEMA_BUILDER_ENUM_VALUE_TYPE.FLOAT:
				valueSchemaName = "SchemaConstFloat";
				break;
		}

		const propsCmp = propsSchema.compileRender(cCtx, modelNode.props, path);

		return {
			isScoped: true,
			code: `(s,pv,pt)=>{${[
				// Previous props
				`const _pp=pv&&pv._type==="SchemaValue"?pv._values[0]._values[0]:pv&&pv._values?pv._values[0]:undefined;`,
				// Opts
				`const _op=${applyCodeArg(propsCmp, `_pp`, `pt`)};`,
				// Schema
				`const _sc={..._op,value:{_type:"Schema",_name:"${escapeString(valueSchemaName)}",_values:[{default:_op.default}]}};`,
				// Export data
				`const _ed={_type:"Schema",_name:"SchemaConstEnum",_values:[_sc]};`,
				// Return
				opts.constantOnly
					? `return _ed`
					: `return {_type:"Schema",_name:"SchemaValue",_values:[_ed,_sc]}`
			].join("")}}`
		}

	};

	/** Schema value cannot be validated because spec is ExportImport type which should not be constructed in any manuall way */
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	schema.compileValidate = (cCtx, path, modelNodeId) => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	schema.export = () => {
		return exportSchema("SchemaBuilderEnum", [opts]);
	};

	/** Is any because export data are internals and should not be exposed. */
	schema.getTypeDescriptor = () => {
		return TypeDescAny({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = (modelNode) => {
		return [{
			key: "props",
			node: modelNode.props
		}]
	}

	return schema;

}
