/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAppServer } from "../app";
import { IServiceFactory } from "../services/IServiceFactory";
import { ISecretsProvider, ISecretsProviderOptions } from "./ISecretsProvider";

export function createSecretsProviderFactory<
	TOptions extends ISecretsProviderOptions,
	TClass extends ISecretsProvider
>(
	entity: new (
		config: TOptions,
		app: IAppServer
	) => TClass
): IServiceFactory<TClass, TOptions> {

	return {
		factory: (
			config: TOptions,
			app: IAppServer
		) => new entity(config, app)
	}

}