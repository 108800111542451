/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportSchema } from "../ExportImportSchema/ExportSchema";
import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar,
	TBlueprintIDTNodePath
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts, TBlueprintSchemaParentNode,
} from "../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE, TGenericModelNode } from "../Schema/IModelNode";
import {
	applyRuntimeValidators, assignParentToModelProps,
	cloneModelNode,
	compileRuntimeValidators,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateDefaultValue
} from "../Schema/SchemaHelpers";
import { applyCodeArg, escapeString, inlineValue } from "../Context/CompileUtil";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import { INamedConfigItem, INamedConfigResolver } from "../Resolvers/INamedConfigResolver";
import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import {
	extractAndValidateIDTMapProperties,
	provideIDTMapPropertyCompletions,
	provideIDTMapRootCompletions,
	validateIDTNode
} from "../Context/ParseUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { TypeDescNull, TypeDescObject, TypeDescString } from "../Shared/ITypeDescriptor";
import { ModelNodeManipulationError } from "../Schema/ModelNodeManipulationError";
import { ValidatorObject } from "../validators/ValidatorObject";
import { ValidatorString } from "../validators/ValidatorString";
import { IBlueprintSchemaValidationError, SCHEMA_VALIDATION_ERROR_TYPE } from "../Validator/IBlueprintSchemaValidator";
import { IDocumentLocation } from "../Shared/IDocumentLocation";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";

/**
 * Schema model
 */
export interface ISchemaNamedConfigModel extends IModelNode<ISchemaNamedConfig> {
	/** Config name - can be null! */
	name: string;
	/** Config options - can be null! */
	opts: TGenericModelNode;
}

/**
 * Schema options
 */
export interface ISchemaNamedConfigOpts extends IBlueprintSchemaOpts {
	/** Name of the DesignContext resolver to resolve opts schema - resolver must implement INamedConfigResolver */
	resolverName: string;

	/** Default value for a new node */
	default?: ISchemaNamedConfigSpec;

	/** Validation constraints */
	constraints?: {
		/** If required */
		required?: boolean;
	}
}

/**
 * Default value
 */
export interface ISchemaNamedConfigDefault {
	name: string;
	// We don't know the options in advance
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	opts: any;
}

/**
 * Schema spec
 */
export interface ISchemaNamedConfigSpec {
	/** Config name - can be null! */
	name: string;
	/** Config options - can be null! */
	// We don't know opts it until schema is resolved by name
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	opts: any;
}

/**
 * Schema type
 */
export interface ISchemaNamedConfig extends IBlueprintSchema<
	ISchemaNamedConfigOpts,
	ISchemaNamedConfigModel,
	ISchemaNamedConfigSpec,
	ISchemaNamedConfigDefault
> {
	/**
	 * Sets a config name
	 *
	 * @param modelNode Model node
	 * @param name New config name
	 */
	setName: (modelNode: ISchemaNamedConfigModel, name: string, notify?: boolean) => void;

	/**
	 * Returns a list of available configuration names
	 */
	getNameList: (modelNode: ISchemaNamedConfigModel) => INamedConfigItem[];
}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaNamedConfig(
	opts: ISchemaNamedConfigOpts,
): ISchemaNamedConfig {

	const rootValidator = ValidatorObject({ required: opts.constraints?.required });
	const nameValidator = ValidatorString({ required: true, min: 1 });

	const keyPropRules = {
		name: {
			required: true,
			idtType: BP_IDT_TYPE.SCALAR,
			idtScalarSubType: BP_IDT_SCALAR_SUBTYPE.STRING,
			provideCompletion: (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {

				const nameList = dCtx.getResolver<INamedConfigResolver>(opts.resolverName).getNameList();

				dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
					const items: ICompletionItem[] = nameList.map((item) => ({
						kind: CMPL_ITEM_KIND.Reference,
						label: item.label,
						insertText: item.name,
						detail: item.description
					}));

					return items;
				});

			}
		},
		opts: {
			required: true
		}
	};

	const schema = createEmptySchema<ISchemaNamedConfig>("namedConfig", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, "opts")
	}

	const createModel = (
		dCtx: DesignContext,
		name: string,
		optsModel: TGenericModelNode,
		validationErrors: IBlueprintSchemaValidationError[],
		parent: TBlueprintSchemaParentNode
	) => {

		const model = createModelNode(schema, dCtx, parent, validationErrors, {
			name: name,
			opts: optsModel
		});

		return assignParentToChildrenOf(model)
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const errors = validateDefaultValue(schema, [rootValidator as any], defaultValue);
		const initialValue = defaultValue ?? opts.default;

		if (initialValue) {

			if (!(initialValue instanceof Object)) {
				throw new SchemaDeclarationError(schema.name, schema.opts, `Default value must be an object.`);
			}

			if (!initialValue.name) {
				throw new SchemaDeclarationError(schema.name, schema.opts, `Default value must has a 'name' property.`);
			}

			const optsSchema = dCtx.getResolver<INamedConfigResolver>(opts.resolverName).getOptsSchemaByName(initialValue.name);

			if (!optsSchema) {
				throw new SchemaDeclarationError(schema.name, schema.opts, `Options for name '${initialValue.name}' cannot be resolved.`);
			}

			const optsModel = optsSchema.createDefault(dCtx, null,initialValue.opts);

			return createModel(dCtx, initialValue.name, optsModel, errors, parent);

		}

		return createModel(dCtx, null, null, errors, parent);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedOpts = modelNode.opts
			? modelNode.opts.schema.clone(dCtx, modelNode.opts, null)
			: null;

		const clone = cloneModelNode(dCtx, modelNode, parent,{
			name: modelNode.name,
			opts: clonedOpts
		});

		return assignParentToChildrenOf(clone)
	}

	schema.destroy = (modelNode) => {

		if (modelNode.opts) {
			modelNode.opts.schema.destroy(modelNode.opts);
		}

		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent) => {

		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return schema.createDefault(dCtx, parent);
		}

		// Extract keys
		const { keys, keysValid: keysValid } = extractAndValidateIDTMapProperties(dCtx, rootNode, keyPropRules);

		if (!keysValid.name) {
			return schema.createDefault(dCtx, parent);
		}

		const configName = (keys["name"].value as IBlueprintIDTScalar).value as string;
		const optsSchema = dCtx.getResolver<INamedConfigResolver>(opts.resolverName).getOptsSchemaByName(configName);

		if (!optsSchema) {
			if (keys["name"].value.parseInfo) {
				dCtx.logParseError(keys["name"].value.parseInfo.loc.uri, {
					range: keys["name"].value.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.INVALID_VALUE,
					message: `Cannot resolve configuration for '${configName}'.`,
					parsePath: keys["name"].value.path
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		provideIDTMapPropertyCompletions(dCtx, rootNode, {
			opts: keys.opts
		}, {
			opts: optsSchema.provideCompletion
		});

		if (!keysValid.opts) {
			return schema.createDefault(dCtx, parent);
		}

		const optsModel = optsSchema.parse(dCtx, keys["opts"].value, null);

		return createModel(dCtx, configName, optsModel, [], parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);

	}

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {

		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// name
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[name]"]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["{name}"]),
						value: "name"
					} as IBlueprintIDTScalar,
					value: modelNode.name
						? {
							type: BP_IDT_TYPE.SCALAR,
							subType: BP_IDT_SCALAR_SUBTYPE.STRING,
							path: path.concat(["name"]),
							value: modelNode.name
						} as IBlueprintIDTScalar
						: {
							type: BP_IDT_TYPE.SCALAR,
							subType: BP_IDT_SCALAR_SUBTYPE.NULL,
							path: path.concat(["name"]),
							value: null
						} as IBlueprintIDTScalar
				} as IBlueprintIDTMapElement,
				// opts
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[opts]"]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["{opts}"]),
						value: "opts"
					} as IBlueprintIDTScalar,
					value: modelNode.opts
						? modelNode.opts.schema.serialize(modelNode.opts, path.concat(["opts"]))
						: {
							type: BP_IDT_TYPE.SCALAR,
							subType: BP_IDT_SCALAR_SUBTYPE.NULL,
							path: path.concat(["opts"]),
							value: null
						} as IBlueprintIDTScalar
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		modelNode.lastScopeFromRender = scope;

		return {
			name: modelNode.name,
			opts: modelNode.opts
				? modelNode.opts.schema.render(rCtx, modelNode.opts, path.concat(["opts"]), scope, prevSpec?.opts)
				: null
		};

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		const optsCode = modelNode.opts
			? modelNode.opts.schema.compileRender(cCtx, modelNode.opts, path.concat(["opts"]))
			: {
				isScoped: false,
				code: "null"
			};

		return {
			isScoped: true,
			// eslint-disable-next-line max-len
			code: `(s,pv,pt)=>({name:${inlineValue(modelNode.name)},opts:${applyCodeArg(optsCode, `typeof pv==="object"&&pv!==null?pv.opts:undefined`, `pt.concat(["opts"])`)}})`
		}

	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {

		let isValid = true;

		// Validate self as object
		isValid = applyRuntimeValidators<{ [K: string]: unknown }>(
			rCtx, path, modelNodeId, [rootValidator], DOC_ERROR_SEVERITY.ERROR, value as unknown as { [K: string]: unknown }
		) && isValid;

		if (validateChildren && value instanceof Object) {

			isValid = applyRuntimeValidators<string>(
				rCtx, path.concat(["name"]), modelNodeId, [nameValidator], DOC_ERROR_SEVERITY.ERROR, value.name
			) && isValid;

			if (isValid) {

				let optsSchema;
				const resolver = rCtx.getResolver<INamedConfigResolver>(opts.resolverName);

				if (resolver) {
					optsSchema = resolver.getOptsSchemaByName(value.name);
				} else {
					console.warn(`Cannot get resolver '${opts.resolverName}'.`);
				}

				if (optsSchema) {

					isValid = optsSchema.validate(rCtx, path.concat(["opts"]), modelNodeId, value.opts, true) && isValid;

				} else {
					rCtx.logValidationErrors(path, modelNodeId, DOC_ERROR_SEVERITY.ERROR, [{
						type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
						message: `Configuration for '${value.name}' does not exists.`,
						metaData: {},
					}])

					isValid = false;
				}

			}

		}

		return isValid;

	};

	// Always returns false because cannot be inlined as dynamic value
	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {

		const expressions = [];

		const rootValidatorCmp = compileRuntimeValidators(cCtx, path, modelNodeId, [rootValidator], DOC_ERROR_SEVERITY.ERROR);

		if (rootValidatorCmp !== null) {
			expressions.push(`_vd=(${rootValidatorCmp})(v,pt)&&_vd;`);
		}

		if (validateChildren) {

			expressions.push(`if(typeof v==="object"&&v!==null){`);

			const nameValidatorCmp
				= compileRuntimeValidators(cCtx, path.concat(["name"]), modelNodeId, [nameValidator], DOC_ERROR_SEVERITY.ERROR);

			if (nameValidatorCmp !== null) {
				expressions.push(`_vd=(${nameValidatorCmp})(v.name,pt.concat(["name"]))&&_vd;`);
			}

			expressions.push(
				/* eslint-disable indent */
				`if(_vd){`,
				`let _os;`,
				`const _rs=rCtx.getResolver("${escapeString(opts.resolverName)}");`,
				// eslint-disable-next-line max-len
				`if(_rs){_os=_rs.getOptsSchemaByName(v.name);}else{console.warn("Cannot get resolver '${escapeString(opts.resolverName)}'.")}`,
				`if(_os){`,
				`_vd=_os.validate(rCtx,pt.concat(["opts"]),${inlineValue(modelNodeId)},v.opts,true)&&_vd`,
				`}else{`,
				`_vd=false;`,
				`rCtx.logValidationErrors(pt.concat(["opts"]),${inlineValue(modelNodeId)},${inlineValue(DOC_ERROR_SEVERITY.ERROR)},[`,
				// eslint-disable-next-line max-len
				`{type:${inlineValue(SCHEMA_VALIDATION_ERROR_TYPE.RANGE)},message:"Configuration for '"+v.name+"' does not exists.",metaData:{}}`,
				`])`,
				`}`,
				`}`
				/* eslint-enable indent */
			);

			expressions.push(`}`);

		}

		if (expressions.length === 0) {
			return null;
		} else {
			return cCtx.addGlobalValue(`(v,pt)=>{let _vd=true;${expressions.join("")}return _vd}`);
		}

	};

	schema.export = (): ISchemaImportExport => {

		return exportSchema("SchemaNamedConfig", [opts]);

	};

	schema.getTypeDescriptor = (modelNode) => {

		return TypeDescObject({
			label: opts.label,
			description: opts.description,
			props: {
				name: TypeDescString({
					label: "Name"
				}),
				opts: modelNode?.opts
					? modelNode.opts.schema.getTypeDescriptor(modelNode.opts)
					: TypeDescNull({
						label: "Options",
						description: "No options are available."
					})
			},
			example: opts.example,
			tags: opts.tags
		})

	};

	schema.setName = (modelNode: ISchemaNamedConfigModel, name: string, notify?: boolean) => {

		const optsSchema = modelNode.ctx.getResolver<INamedConfigResolver>(opts.resolverName).getOptsSchemaByName(name);

		if (!optsSchema) {
			throw new ModelNodeManipulationError(schema.name, schema.opts, `Options for name '${name}' cannot be resolved.`);
		}

		if (modelNode.opts) {
			modelNode.opts.schema.destroy(modelNode.opts);
		}

		const optsModel = optsSchema.createDefault(modelNode.ctx, modelNode);

		modelNode.name = name;
		modelNode.opts = optsModel;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	}

	schema.getNameList = (modelNode: ISchemaNamedConfigModel) => {

		return modelNode.ctx.getResolver<INamedConfigResolver>(opts.resolverName).getNameList();

	}

	schema.getChildNodes = (modelNode) => {
		return modelNode.opts
			? [{
				key: "opts",
				node: modelNode.opts
			}]
			: [];
	}

	// Validate default value
	if (opts.default) {
		if (!(opts.default instanceof Object)) {
			throw new SchemaDeclarationError(schema.name, schema.opts, `Default value must be an object.`);
		}

		if (!opts.default.name) {
			throw new SchemaDeclarationError(schema.name, schema.opts, `Default value must has a 'name' property.`);
		}
	}

	return schema;

}
