/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, declareFunction, SCHEMA_CONST_ANY_VALUE_TYPE, Type } from "@hexio_io/hae-lib-blueprint";

export const roundFunc = declareFunction({
	name: "ROUND",
	category: "math",
	label: "Round",
	description: "Rounds a number",
	argRequiredCount: 1,
	argSchemas: [
		BP.Float({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: 0
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({
		label: "Rounded value"
	}),
	render: (_rCtx, args) => {
		return Math.round(args[0]());
	},
});

export const floorFunc = declareFunction({
	name: "FLOOR",
	category: "math",
	label: "Rounds number down",
	description: "Rounds a number",
	argRequiredCount: 1,
	argSchemas: [
		BP.Float({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: 0
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({
		label: "Rounded value"
	}),
	render: (_rCtx, args) => {
		return Math.floor(args[0]());
	},
});

export const ceilFunc = declareFunction({
	name: "CEIL",
	category: "math",
	label: "Rounds number up",
	description: "Rounds a number",
	argRequiredCount: 1,
	argSchemas: [
		BP.Float({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: 0
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({
		label: "Rounded value"
	}),
	render: (_rCtx, args) => {
		return Math.ceil(args[0]());
	},
});

export const toFixedFunc = declareFunction({
	name: "TO_FIXED",
	category: "math",
	label: "Convert number to fixed decimals",
	description: "Rounds the number to given count of decimal places.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Float({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: 0
		}),
		BP.Integer({
			label: "Decimals",
			description: "Number of decimal places",
			constraints: {
				required: true
			},
			fallbackValue: 0
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({
		label: "Rounded value"
	}),
	render: (_rCtx, args) => {
		const value = args[0]();
		const decimals = args[1]();

		if (typeof value === "number") {
			return value.toFixed(decimals);
		} else {
			return NaN;
		}
	},
});

export const minFunc = declareFunction({
	name: "MIN",
	category: "math",
	label: "Minimum value",
	description: "Returns the minimum value.",
	argRequiredCount: 1,
	argSchemas: [],
	argRestSchema: BP.Float({
		label: "Value",
		constraints: {
			required: true
		},
		fallbackValue: 0
	}),
	returnType: Type.Integer({
		label: "Minimal value"
	}),
	render: (_rCtx, _args, restArgs) => {
		const fnArgs = restArgs.map((x) => x());
		return Math.min.apply(undefined, fnArgs)
	},
});

export const maxFunc = declareFunction({
	name: "MAX",
	category: "math",
	label: "Maximum value",
	description: "Returns the maximum value.",
	argRequiredCount: 1,
	argSchemas: [],
	argRestSchema: BP.Float({
		label: "Value",
		constraints: {
			required: true
		},
		fallbackValue: 0
	}),
	returnType: Type.Integer({
		label: "Maximum value"
	}),
	render: (_rCtx, _args, restArgs) => {
		const fnArgs = restArgs.map((x) => x());
		return Math.max.apply(undefined, fnArgs)
	},
});

export const absFunc = declareFunction({
	name: "ABS",
	category: "math",
	label: "Absolute value",
	description: "Returns an absolute value.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Float({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: 0
		})
	],
	argRestSchema: null,
	returnType: Type.Float({
		label: "Absolute value"
	}),
	render: (_rCtx, args) => {
		return Math.abs(args[0]());
	},
});

export const formatNumber = declareFunction({
	name: "FORMAT_NUMBER",
	category: "math",
	label: "Format number",
	description: "Formating number",
	argRequiredCount: 4,
	argSchemas: [
		BP.Float({
			label: "Number",
			constraints: {
				required: true
			},
		}),
		BP.Integer({
			label: "Decimals",
			description: "Number of decimal places",
			default: 0,
		}),
		BP.String({
			label: "Thousands delimiter",
			default: ` `,
		}),
		BP.String({
			label: "Decimal point",
			default: `.`,
		})
	],
	argRestSchema: null,
	returnType: Type.String({
		label: "Formating number"
	}),
	render: (_rCtx, args) => {
		let number = args[0]()
		const decimals = +args[1]()
		const delimiter = args[2]()
		const decPoint = args[3]()

		if (typeof number === "number") {

			// remove sign if negative
			let sign = 1;
			if (number < 0) {
				sign = -1;
				number = -number;
			}

			number = number.toFixed(decimals)

			// trim the number decimal point if it exists
			const num = number.toString().includes('.') ? number.toString().split('.')[0] : number.toString();
			const len = num.toString().length;
			let result = '';
			let count = 1;

			for (let i = len - 1; i >= 0; i--) {
				result = num.toString()[i] + result;
				if (count % 3 === 0 && count !== 0 && i !== 0) {
					result = delimiter + result;
				}
				count++;
			}

			// add number after decimal point
			if (number.toString().includes('.')) {
				result = result + decPoint + number.toString().split('.')[1];
			}

			return sign < 0 ? '-' + result : result;

		} else {
			return NaN;
		}

	},
});
