/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { serializeIDTToData } from "../../IDT/IDTToData";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts, TBlueprintSchemaParentNode
} from "../../Schema/IBlueprintSchema";
import {
	IBlueprintSchemaValidationError,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../../Validator/IBlueprintSchemaValidator";
import { IModelNode, MODEL_CHANGE_TYPE } from "../../Schema/IModelNode";
import { TypeDescAny } from "../../Shared/ITypeDescriptor";
import {
	cloneModelNode,
	compileRuntimeValidators,
	compileScalarNodeRender,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange
} from "../../Schema/SchemaHelpers";
import { DesignContext } from "../../Context/DesignContext";
import { DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { RuntimeContext } from "../../Context/RuntimeContext";
import { TModelPath } from "../../Shared/TModelPath";
import { dataToIDT } from "../../IDT/DataToIDT";
import { inlineValue } from "../../Context/CompileUtil";

/**
 * Schema value type
 */
type TSpecType = null | string | number | boolean | TSpecType[] | { [K: string]: TSpecType;[K: number]: TSpecType };

/**
 * Schema model
 */
export interface ISchemaConstDataModel extends IModelNode<ISchemaConstData> {
	value: TSpecType;
	jsonValue: string;
}

/**
 * Schema options
 */
export interface ISchemaConstDataOpts extends IBlueprintSchemaOpts {
	/** Default value for a new node */
	default?: TSpecType;
	/** Fallback value to return when validation fails */
	fallbackValue?: TSpecType;
}

/**
 * Schema spec
 */
export interface ISchemaConstData extends IBlueprintSchema<ISchemaConstDataOpts, ISchemaConstDataModel, TSpecType, TSpecType> {
	setValue(modelNode: ISchemaConstDataModel, value: TSpecType, notify?: boolean): void;
	setJSONValue(modelNode: ISchemaConstDataModel, value: string, notify?: boolean): void;
}

/**
 * Schema: Data
 *
 * @param opts Schema options
 */
export function SchemaConstData(opts: ISchemaConstDataOpts): ISchemaConstData {

	const validateValue = (value: TSpecType) => {

		const errors = [];
		try {
			JSON.stringify(value);
		} catch (error) {
			errors.push({
				type: SCHEMA_VALIDATION_ERROR_TYPE.FORMAT,
				message: "Can't stringify value"
			})
		}

		return errors;

	};

	const applyValidator = (rCtx: RuntimeContext, path: TModelPath, modelNodeId: number, value: TSpecType) => {

		const errors = validateValue(value);

		if (errors.length > 0) {
			rCtx.logValidationErrors(path, modelNodeId, errSeverity, errors);
		}

		return errors.length === 0;

	}

	const errSeverity = opts.fallbackValue !== undefined ? DOC_ERROR_SEVERITY.WARNING : DOC_ERROR_SEVERITY.ERROR;

	const schema = createEmptySchema<ISchemaConstData>("constData", opts);

	const createModel = (
		dCtx: DesignContext,
		value: TSpecType,
		parent: TBlueprintSchemaParentNode,
		errors: IBlueprintSchemaValidationError[]) => {

		return createModelNode(schema, dCtx, parent, errors, {
			value,
			jsonValue: JSON.stringify(value)
		});

	};

	schema.createDefault = (dCtx, parent,  defaultValue) => {

		const _defaultValue = defaultValue !== undefined ? defaultValue : opts.default !== undefined ? opts.default : null;
		const errors = validateValue(_defaultValue);
		return createModel(dCtx, _defaultValue, parent, errors);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		return cloneModelNode(dCtx, modelNode, parent, {
			value: modelNode.value,
			jsonValue: modelNode.jsonValue
		});

	};

	schema.destroy = (modelNode): void => {

		modelNode.value = undefined;
		modelNode.jsonValue = undefined;
		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent) => {

		const value = serializeIDTToData(idtNode);
		const errors = validateValue(value);

		return createModel(dCtx, value, parent,errors);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			return null;
		});

	};

	schema.serialize = (modelNode, path) => {

		return dataToIDT(modelNode.value, null, path);

	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	schema.render = (rCtx, modelNode, path, scope, prevSpec): TSpecType => {
		return modelNode.value;
	};

	schema.compileRender = (cCtx, modelNode, path) => {

		return compileScalarNodeRender(
			cCtx, modelNode, path, errSeverity,
			inlineValue(modelNode.value),
			opts.fallbackValue !== undefined ? opts.fallbackValue : null
		);

	};

	schema.validate = (rCtx, path, modelNodeId, value) => {

		return applyValidator(rCtx, path, modelNodeId, value);

	};

	schema.compileValidate = (cCtx, path, modelNodeId): string => {

		return compileRuntimeValidators(cCtx, path, modelNodeId, [], errSeverity);

	};

	schema.setValue = (modelNode, value, notify) => {

		try {

			modelNode.jsonValue = JSON.stringify(value);
			modelNode.value = value;

		} catch (error) {

			modelNode.isValid = false;
			modelNode.validationErrors.push({
				type: SCHEMA_VALIDATION_ERROR_TYPE.FORMAT,
				message: "Can't stringify value",
				metaData: {}
			});

		}

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}

	};

	schema.setJSONValue = (modelNode, value, notify) => {

		try {

			modelNode.value = JSON.parse(value);
			modelNode.jsonValue = value;

		} catch (error) {

			modelNode.isValid = false;
			modelNode.validationErrors.push({
				type: SCHEMA_VALIDATION_ERROR_TYPE.FORMAT,
				message: "Can't parse value",
				metaData: {}
			});

		}

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}

	};

	schema.getTypeDescriptor = () => {
		return TypeDescAny({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = () => {
		return [];
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaConstData", [opts]);
	};

	return schema;

}
