/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { RuntimeContext } from "../Context/RuntimeContext";
import { TTypeDesc, TypeDescAny } from "../Shared/ITypeDescriptor";
import { ISchemaFlowNodeSpec, ISchemaFlowNodeTypeDefinitionMap } from "../schemas/SchemaFlowNode";
import { ISchemaFlowNodeListModel, ISchemaFlowNodeListSpec } from "../schemas/SchemaFlowNodeList";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { createSubScope, IScope } from "../Shared/Scope";
import { TModelPath } from "../Shared/TModelPath";
import { TEventTrigger, TSchemaResolveEventSpecFn } from "./EventTypes";
import { IEventResolver } from "../Resolvers/IEventResolver";

export async function handleEvent(
	rCtx: RuntimeContext,
	resolverName: string,
	nodes: ISchemaFlowNodeListSpec<ISchemaFlowNodeTypeDefinitionMap>,
	initialScope: IScope,
	eventName: string,
	senderId: string,
	senderPath: TModelPath,
	senderModelNodeId: number,
	senderInstance?: unknown,
	flowNodeListModel?: ISchemaFlowNodeListModel<ISchemaFlowNodeTypeDefinitionMap>
): Promise<void> {

	type TNodeSpec = ISchemaFlowNodeSpec<ISchemaFlowNodeTypeDefinitionMap>;

	const resolver = rCtx.getResolver<IEventResolver>(resolverName);
	const nodeIndex: { [K: string]: TNodeSpec } = {};

	for (let i = 0; i < nodes.length; i++) {
		nodeIndex[ nodes[i].id ] = nodes[i];
	}

	const processNode = async (id: string, localScope: IScope, executionOrder: number) => {
		
		const node = nodeIndex[id];

		if (!node) {
			throw new Error(`Node '${id}' does not exist.`);
		}

		const opts = node.opts(localScope);
		const outputsConnected: { [K: string]: boolean } = {};

		for (const k in node.outputs) {
			outputsConnected[k] = node.outputs[k].length > 0;
		}

		const handler = resolver.getEventHandler(node.type as string);

		const res = await handler(opts, {
			eventName: eventName,
			nodeId: id,
			inputScope: localScope,
			executionOrder: executionOrder,
			outputsConnected: outputsConnected,
			senderId: senderId,
			senderPath: senderPath,
			senderModelNodeId: senderModelNodeId,
			senderInstance: senderInstance
		});

		// Assign debug info
		if (flowNodeListModel) {
			const nodeModel = flowNodeListModel.items.filter((item) => item.nodeId === node.__modelNodeId)[0];

			if (nodeModel) {
				nodeModel.schema.setExecutionResult(nodeModel, {
					scope: localScope,
					resolvedOpts: opts,
					activeOutput: res.outputName,
					outputData: res.data,
					outputType: res.type
				}, true);
			}
		}

		// Activate output
		if (res.outputName && node.outputs[res.outputName]) {

			const targetNodes = node.outputs[res.outputName];
			const nextPromises = [];

			const nextScope = createSubScope(localScope);

			if (node.varName) {
				nextScope.localData[node.varName] = nextScope.globalData[node.varName] = res.data;
				nextScope.localType[node.varName] = nextScope.globalType[node.varName] = res.type;
			}

			nextScope.localData["prevNodeResult"] = nextScope.globalData["prevNodeResult"] = res.data;
			nextScope.localType["prevNodeResult"] = nextScope.globalType["prevNodeResult"] = {
				...(res.type ? res.type : TypeDescAny({})),
				// @todo Use translation table
				label: "Previous node result"
			} as TTypeDesc;

			for (let i = 0; i < targetNodes.length; i++) {
				nextPromises.push(processNode(targetNodes[i], nextScope, executionOrder + 1));
			}

			await Promise.all(nextPromises);

		}

	};
	
	await processNode("eventStart", initialScope, 0);

}

/**
 * Creates a component event trigger function
 *
 * @param rCtx Runtime Context the component instance is created by
 * @param cmpInstance Component instance
 * @param eventName Event name
 */
export function createEventTrigger(
	rCtx: RuntimeContext,
	resolverName: string,
	getEventSpec: TSchemaResolveEventSpecFn,
	eventName: string,
	senderId: string,
	senderPath: TModelPath,
	senderModelNodeId: number,
	senderInstance?: unknown,
	flowNodeListModel?: ISchemaFlowNodeListModel<ISchemaFlowNodeTypeDefinitionMap>
): TEventTrigger {

	const processEvent = async (
		nodes: ISchemaFlowNodeListSpec<ISchemaFlowNodeTypeDefinitionMap>,
		initialScope: IScope
	) => {

		try {
			await handleEvent(
				rCtx, resolverName, nodes, initialScope, eventName,
				senderId, senderPath, senderModelNodeId, senderInstance,
				flowNodeListModel
			);
		} catch(err) {
			rCtx.logRuntimeError({
				severity: DOC_ERROR_SEVERITY.WARNING,
				name: DOC_ERROR_NAME.EVENT_ERROR,
				message: `Failed to handle event '${eventName}' of component at '${senderPath.join("->")}': ${String(err)}`,
				modelPath: senderPath,
				modelNodeId: senderModelNodeId,
				metaData: {
					// @todo add to translation table
					translationTerm: "runtimeContext:errors.failedToHandleComponentEvent",
					args: {
						eventName: eventName,
						componentPath: senderPath.join("->"),
						errorMessage: String(err)
					},
					senderId: senderId,
					error: err
				}
			});
		}

	};

	return async (scope: IScope|((parentScope: IScope) => IScope)): Promise<void> => {
		
		const eventSpec = getEventSpec(eventName);

		if (!eventSpec) {
			return;
		}

		let initialScope: IScope;

		// Get nodes
		const nodes = eventSpec.nodes((parentScope) => {
			return initialScope = (typeof scope === "function" ? scope(parentScope) : scope);
		});

		// Clear execution results
		if (flowNodeListModel) {
			flowNodeListModel.items.forEach((item) => {
				item.schema.clearExecutionResult(item, true);
			});
		}

		const handlePromise = processEvent(nodes, initialScope);
		rCtx.__addAsyncOperation(handlePromise);

		return handlePromise;

	};

}