/**
 * Spacing pattern
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { cssLengthUnits } from "./cssUnits";

// eslint-disable-next-line max-len
export const spacingPattern = `^(((([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)(${cssLengthUnits})\\s*|[A-Z_]+\\s*)( (([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)(${cssLengthUnits})|[A-Z_]+){0,3})$`;