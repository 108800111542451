/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TErrors } from "../IError";

export const POSTGRESQL_ERRORS: TErrors = {
	"00000": "Successful completion.",
	"01000": "Warning.",
	"0100C": "Warning: dynamic_result_sets_returned.",
	"01008": "Warning: implicit_zero_bit_padding.",
	"01003": "Warning: null_value_eliminated_in_set_function.",
	"01007": "Warning: privilege_not_granted.",
	"01006": "Warning: privilege_not_revoked.",
	"01004": "Warning: string_data_right_truncation.",
	"01P01": "Warning: deprecated_feature.",
	"02000": "No data",
	"02001": "No data: no_additional_dynamic_result_sets_returned.",
	"03000": "SQL Statement Not Yet Complete.",
	"08000": "Connection Exception.",
	"08003": "Connection Exception: connection_does_not_exist.",
	"08006": "Connection Exception: connection_failure.",
	"08001": "Connection Exception: sqlclient_unable_to_establish_sqlconnection.",
	"08004": "Connection Exception: sqlserver_rejected_establishment_of_sqlconnection.",
	"08007": "Connection Exception: transaction_resolution_unknown.",
	"08P01": "Connection Exception: protocol_violation.",
	"09000": "Triggered Action Exception.",
	"0A000": "Feature Not Supported.",
	"0B000": "Invalid Transaction Initiation.",
	"0F000": "Locator Exception.",
	"0F001": "Locator Exception: invalid_locator_specification.",
	"0L000": "Invalid Grantor.",
	"0LP01": "Invalid Grantor: invalid_grant_operation.",
	"0P000": "Invalid Role Specification.",
	"0Z000": "Diagnostics Exception.",
	"0Z002": "Diagnostics Exception: stacked_diagnostics_accessed_without_active_handler.",
	"20000": "Case Not Found.",
	"21000": "Cardinality Violation.",
	"22000": "Data Exception.",
	"2202E": "Data Exception: array_subscript_error.",
	"22021": "Data Exception: character_not_in_repertoire.",
	"22008": "Data Exception: datetime_field_overflow.",
	"22012": "Data Exception: division_by_zero.",
	"22005": "Data Exception: error_in_assignment.",
	"2200B": "Data Exception: escape_character_conflict.",
	"22022": "Data Exception: indicator_overflow.",
	"22015": "Data Exception: interval_field_overflow.",
	"2201E": "Data Exception: invalid_argument_for_logarithm.",
	"22014": "Data Exception: invalid_argument_for_ntile_function.",
	"22016": "Data Exception: invalid_argument_for_nth_value_function.",
	"2201F": "Data Exception: invalid_argument_for_power_function.",
	"2201G": "Data Exception: invalid_argument_for_width_bucket_function.",
	"22018": "Data Exception: invalid_character_value_for_cast.",
	"22007": "Data Exception: invalid_datetime_format.",
	"22019": "Data Exception: invalid_escape_character.",
	"2200D": "Data Exception: invalid_escape_octet.",
	"22025": "Data Exception: invalid_escape_sequence.",
	"22P06": "Data Exception: nonstandard_use_of_escape_character.",
	"22010": "Data Exception: invalid_indicator_parameter_value.",
	"22023": "Data Exception: invalid_parameter_value.",
	"22013": "Data Exception: invalid_preceding_or_following_size.",
	"2201B": "Data Exception: invalid_regular_expression.",
	"2201W": "Data Exception: invalid_row_count_in_limit_clause.",
	"2201X": "Data Exception: invalid_row_count_in_result_offset_clause.",
	"2202H": "Data Exception: invalid_tablesample_argument.",
	"2202G": "Data Exception: invalid_tablesample_repeat.",
	"22009": "Data Exception: invalid_time_zone_displacement_value.",
	"2200C": "Data Exception: invalid_use_of_escape_character.",
	"2200G": "Data Exception: most_specific_type_mismatch.",
	"22004": "Data Exception: null_value_not_allowed.",
	"22002": "Data Exception: null_value_no_indicator_parameter.",
	"22003": "Data Exception: numeric_value_out_of_range.",
	"2200H": "Data Exception: sequence_generator_limit_exceeded.",
	"22026": "Data Exception: string_data_length_mismatch.",
	"22001": "Data Exception: string_data_right_truncation.",
	"22011": "Data Exception: substring_error.",
	"22027": "Data Exception: trim_error.",
	"22024": "Data Exception: unterminated_c_string.",
	"2200F": "Data Exception: zero_length_character_string.",
	"22P01": "Data Exception: floating_point_exception.",
	"22P02": "Data Exception: invalid_text_representation.",
	"22P03": "Data Exception: invalid_binary_representation.",
	"22P04": "Data Exception: bad_copy_file_format.",
	"22P05": "Data Exception: untranslatable_character.",
	"2200L": "Data Exception: not_an_xml_document.",
	"2200M": "Data Exception: invalid_xml_document.",
	"2200N": "Data Exception: invalid_xml_content.",
	"2200S": "Data Exception: invalid_xml_comment.",
	"2200T": "Data Exception: invalid_xml_processing_instruction.",
	"22030": "Data Exception: duplicate_json_object_key_value.",
	"22031": "Data Exception: invalid_argument_for_sql_json_datetime_function.",
	"22032": "Data Exception: invalid_json_text.",
	"22033": "Data Exception: invalid_sql_json_subscript.",
	"22034": "Data Exception: more_than_one_sql_json_item.",
	"22035": "Data Exception: no_sql_json_item.",
	"22036": "Data Exception: non_numeric_sql_json_item.",
	"22037": "Data Exception: non_unique_keys_in_a_json_object.",
	"22038": "Data Exception: singleton_sql_json_item_required.",
	"22039": "Data Exception: sql_json_array_not_found.",
	"2203A": "Data Exception: sql_json_member_not_found.",
	"2203B": "Data Exception: sql_json_number_not_found.",
	"2203C": "Data Exception: sql_json_object_not_found.",
	"2203D": "Data Exception: too_many_json_array_elements.",
	"2203E": "Data Exception: too_many_json_object_members.",
	"2203F": "Data Exception: sql_json_scalar_required.",
	"23000": "Integrity Constraint Violation.",
	"23001": "Integrity Constraint Violation: restrict_violation.",
	"23502": "Integrity Constraint Violation: not_null_violation.",
	"23503": "Integrity Constraint Violation: foreign_key_violation.",
	"23505": "Integrity Constraint Violation: unique_violation.",
	"23514": "Integrity Constraint Violation: check_violation.",
	"23P01": "Integrity Constraint Violation: exclusion_violation.",
	"24000": "Invalid Cursor State.",
	"25000": "Invalid Transaction State.",
	"25001": "Invalid Transaction State: active_sql_transaction.",
	"25002": "Invalid Transaction State: branch_transaction_already_active.",
	"25008": "Invalid Transaction State: held_cursor_requires_same_isolation_level.",
	"25003": "Invalid Transaction State: inappropriate_access_mode_for_branch_transaction.",
	"25004": "Invalid Transaction State: inappropriate_isolation_level_for_branch_transaction.",
	"25005": "Invalid Transaction State: no_active_sql_transaction_for_branch_transaction.",
	"25006": "Invalid Transaction State: read_only_sql_transaction.",
	"25007": "Invalid Transaction State: schema_and_data_statement_mixing_not_supported.",
	"25P01": "Invalid Transaction State: no_active_sql_transaction.",
	"25P02": "Invalid Transaction State: in_failed_sql_transaction.",
	"25P03": "Invalid Transaction State: idle_in_transaction_session_timeout.",
	"26000": "Invalid SQL Statement Name.",
	"27000": "Triggered Data Change Violation.",
	"28000": "Invalid Authorization Specification.",
	"28P01": "Invalid Authorization Specification: invalid_password.",
	"2B000": "Dependent Privilege Descriptors Still Exist.",
	"2BP01": "Dependent Privilege Descriptors Still Exist: dependent_objects_still_exist.",
	"2D000": "Invalid Transaction Termination.",
	"2F000": "SQL Routine Exception.",
	"2F005": "SQL Routine Exception: function_executed_no_return_statement.",
	"2F002": "SQL Routine Exception: modifying_sql_data_not_permitted.",
	"2F003": "SQL Routine Exception: prohibited_sql_statement_attempted.",
	"2F004": "SQL Routine Exception: reading_sql_data_not_permitted.",
	"34000": "Invalid Cursor Name.",
	"38000": "External Routine Exception.",
	"38001": "External Routine Exception: containing_sql_not_permitted.",
	"38002": "External Routine Exception: modifying_sql_data_not_permitted.",
	"38003": "External Routine Exception: prohibited_sql_statement_attempted.",
	"38004": "External Routine Exception: reading_sql_data_not_permitted.",
	"39000": "External Routine Invocation Exception.",
	"39001": "External Routine Invocation Exception: invalid_sqlstate_returned.",
	"39004": "External Routine Invocation Exception: null_value_not_allowed.",
	"39P01": "External Routine Invocation Exception: trigger_protocol_violated.",
	"39P02": "External Routine Invocation Exception: srf_protocol_violated.",
	"39P03": "External Routine Invocation Exception: event_trigger_protocol_violated.",
	"3B000": "Savepoint Exception.",
	"3B001": "Savepoint Exception: invalid_savepoint_specification.",
	"3D000": "Invalid Catalog Name.",
	"3F000": "Invalid Schema Name.",
	"40000": "Transaction Rollback.",
	"40002": "Transaction Rollback: transaction_integrity_constraint_violation.",
	"40001": "Transaction Rollback: serialization_failure.",
	"40003": "Transaction Rollback: statement_completion_unknown.",
	"40P01": "Transaction Rollback: deadlock_detected.",
	"42000": "Syntax Error or Access Rule Violation.",
	"42601": "Syntax Error or Access Rule Violation: syntax_error.",
	"42501": "Syntax Error or Access Rule Violation: insufficient_privilege.",
	"42846": "Syntax Error or Access Rule Violation: cannot_coerce.",
	"42803": "Syntax Error or Access Rule Violation: grouping_error.",
	"42P20": "Syntax Error or Access Rule Violation: windowing_error.",
	"42P19": "Syntax Error or Access Rule Violation: invalid_recursion.",
	"42830": "Syntax Error or Access Rule Violation: invalid_foreign_key.",
	"42602": "Syntax Error or Access Rule Violation: invalid_name.",
	"42622": "Syntax Error or Access Rule Violation: name_too_long.",
	"42939": "Syntax Error or Access Rule Violation: reserved_name.",
	"42804": "Syntax Error or Access Rule Violation: datatype_mismatch.",
	"42P18": "Syntax Error or Access Rule Violation: indeterminate_datatype.",
	"42P21": "Syntax Error or Access Rule Violation: collation_mismatch.",
	"42P22": "Syntax Error or Access Rule Violation: indeterminate_collation.",
	"42809": "Syntax Error or Access Rule Violation: wrong_object_type.",
	"428C9": "Syntax Error or Access Rule Violation: generated_always.",
	"42703": "Syntax Error or Access Rule Violation: undefined_column.",
	"42883": "Syntax Error or Access Rule Violation: undefined_function.",
	"42P01": "Syntax Error or Access Rule Violation: undefined_table.",
	"42P02": "Syntax Error or Access Rule Violation: undefined_parameter.",
	"42704": "Syntax Error or Access Rule Violation: undefined_object.",
	"42701": "Syntax Error or Access Rule Violation: duplicate_column.",
	"42P03": "Syntax Error or Access Rule Violation: duplicate_cursor.",
	"42P04": "Syntax Error or Access Rule Violation: duplicate_database.",
	"42723": "Syntax Error or Access Rule Violation: duplicate_function.",
	"42P05": "Syntax Error or Access Rule Violation: duplicate_prepared_statement.",
	"42P06": "Syntax Error or Access Rule Violation: duplicate_schema.",
	"42P07": "Syntax Error or Access Rule Violation: duplicate_table.",
	"42712": "Syntax Error or Access Rule Violation: duplicate_alias.",
	"42710": "Syntax Error or Access Rule Violation: duplicate_object.",
	"42702": "Syntax Error or Access Rule Violation: ambiguous_column.",
	"42725": "Syntax Error or Access Rule Violation: ambiguous_function.",
	"42P08": "Syntax Error or Access Rule Violation: ambiguous_parameter.",
	"42P09": "Syntax Error or Access Rule Violation: ambiguous_alias.",
	"42P10": "Syntax Error or Access Rule Violation: invalid_column_reference.",
	"42611": "Syntax Error or Access Rule Violation: invalid_column_definition.",
	"42P11": "Syntax Error or Access Rule Violation: invalid_cursor_definition.",
	"42P12": "Syntax Error or Access Rule Violation: invalid_database_definition.",
	"42P13": "Syntax Error or Access Rule Violation: invalid_function_definition.",
	"42P14": "Syntax Error or Access Rule Violation: invalid_prepared_statement_definition.",
	"42P15": "Syntax Error or Access Rule Violation: invalid_schema_definition.",
	"42P16": "Syntax Error or Access Rule Violation: invalid_table_definition.",
	"42P17": "Syntax Error or Access Rule Violation: invalid_object_definition.",
	"44000": "WITH CHECK OPTION Violation.",
	"53000": "Insufficient Resources.",
	"53100": "Insufficient Resources: disk_full.",
	"53200": "Insufficient Resources: out_of_memory.",
	"53300": "Insufficient Resources: too_many_connections.",
	"53400": "Insufficient Resources: configuration_limit_exceeded.",
	"54000": "Program Limit Exceeded.",
	"54001": "Program Limit Exceeded: statement_too_complex.",
	"54011": "Program Limit Exceeded: too_many_columns.",
	"54023": "Program Limit Exceeded: too_many_arguments.",
	"55000": "Object Not In Prerequisite State.",
	"55006": "Object Not In Prerequisite State: object_in_use.",
	"55P02": "Object Not In Prerequisite State: cant_change_runtime_param.",
	"55P03": "Object Not In Prerequisite State: lock_not_available.",
	"55P04": "Object Not In Prerequisite State: unsafe_new_enum_value_usage.",
	"57000": "Operator Intervention.",
	"57014": "Operator Intervention: query_canceled.",
	"57P01": "Operator Intervention: admin_shutdown.",
	"57P02": "Operator Intervention: crash_shutdown.",
	"57P03": "Operator Intervention: cannot_connect_now.",
	"57P04": "Operator Intervention: database_dropped.",
	"58000": "System Error.",
	"58030": "System Error: io_error.",
	"58P01": "System Error: undefined_file.",
	"58P02": "System Error: duplicate_file.",
	"72000": "Snapshot Failure: snapshot_too_old.",
	"F0000": "Configuration File Error: config_file_error.",
	"F0001": "Configuration File Error: lock_file_exists.",
	"HV000": "Foreign Data Wrapper Error: fdw_error.",
	"HV005": "Foreign Data Wrapper Error: fdw_column_name_not_found.",
	"HV002": "Foreign Data Wrapper Error: fdw_dynamic_parameter_value_needed.",
	"HV010": "Foreign Data Wrapper Error: fdw_function_sequence_error.",
	"HV021": "Foreign Data Wrapper Error: fdw_inconsistent_descriptor_information.",
	"HV024": "Foreign Data Wrapper Error: fdw_invalid_attribute_value.",
	"HV007": "Foreign Data Wrapper Error: fdw_invalid_column_name.",
	"HV008": "Foreign Data Wrapper Error: fdw_invalid_column_number.",
	"HV004": "Foreign Data Wrapper Error: fdw_invalid_data_type.",
	"HV006": "Foreign Data Wrapper Error: fdw_invalid_data_type_descriptors.",
	"HV091": "Foreign Data Wrapper Error: fdw_invalid_descriptor_field_identifier.",
	"HV00B": "Foreign Data Wrapper Error: fdw_invalid_handle.",
	"HV00C": "Foreign Data Wrapper Error: fdw_invalid_option_index.",
	"HV00D": "Foreign Data Wrapper Error: fdw_invalid_option_name.",
	"HV090": "Foreign Data Wrapper Error: fdw_invalid_string_length_or_buffer_length.",
	"HV00A": "Foreign Data Wrapper Error: fdw_invalid_string_format.",
	"HV009": "Foreign Data Wrapper Error: fdw_invalid_use_of_null_pointer.",
	"HV014": "Foreign Data Wrapper Error: fdw_too_many_handles.",
	"HV001": "Foreign Data Wrapper Error: fdw_out_of_memory.",
	"HV00P": "Foreign Data Wrapper Error: fdw_no_schemas.",
	"HV00J": "Foreign Data Wrapper Error: fdw_option_name_not_found.",
	"HV00K": "Foreign Data Wrapper Error: fdw_reply_handle.",
	"HV00Q": "Foreign Data Wrapper Error: fdw_schema_not_found.",
	"HV00R": "Foreign Data Wrapper Error: fdw_table_not_found.",
	"HV00L": "Foreign Data Wrapper Error: fdw_unable_to_create_execution.",
	"HV00M": "Foreign Data Wrapper Error: fdw_unable_to_create_reply.",
	"HV00N": "Foreign Data Wrapper Error: fdw_unable_to_establish_connection.",
	"P0000": "PL/pgSQL Error: plpgsql_error.",
	"P0001": "PL/pgSQL Error: raise_exception.",
	"P0002": "PL/pgSQL Error: no_data_found.",
	"P0003": "PL/pgSQL Error: too_many_rows.",
	"P0004": "PL/pgSQL Error: assert_failure.",
	"XX000": "Internal Error: internal_error.",
	"XX001": "Internal Error: data_corrupted.",
	"XX002": "Internal Error: index_corrupted."
};