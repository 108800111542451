/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import { Breadcrumb, BreadcrumbHint } from "@sentry/types";

function processFetchBreadcrumb(breadcrumb: Breadcrumb, hint?: BreadcrumbHint): Breadcrumb | null {
	if (breadcrumb?.data?.url?.endsWith(".svg")) {
		return null;
	}

	const updatedBreadcrumb = {
		...breadcrumb,
		data: {
			...(breadcrumb.data || {})
		}
	};

	if (hint.error) {
		updatedBreadcrumb.data.reason = hint.error.name || "Unknown";
	}

	if (hint.input && hint.input[1] && hint.input[1].body) {
		try {
			const body = JSON.parse(hint.input[1].body);
			updatedBreadcrumb.data = {
				id: body.id,
				variables: body.variables,
				...updatedBreadcrumb.data
			};
		} catch (e) {
			return updatedBreadcrumb;
		}
	}

	return updatedBreadcrumb;
}

function getNodeName(node: Node): string {
	return node?.nodeName?.toLowerCase();
}

function getNodeText(node: Node): string {
	const childText = [].reduce.call(
		node.childNodes,
		(acc, childNode) => {
			if (childNode && childNode.nodeType === 3) {
				return acc + childNode.textContent;
			}
			return acc;
		},
		""
	);
	const nodeText = childText || node.textContent;
	return nodeText.trim();
}

export function processReactBreadcrumb(breadcrumb: Breadcrumb, hint?: BreadcrumbHint): Breadcrumb | null {
	if (breadcrumb.category === "fetch") {
		return processFetchBreadcrumb(breadcrumb, hint);
	}

	if (breadcrumb.level === "debug") {
		return null;
	}

	let message = "";

	const addCrumbToMessage = (crumb) => {
		if (crumb) {
			if (message) {
				message += " > ";
			}
			message += crumb;
		}
	};

	const addNodeToMessage = (node) => {
		if (node && node.nodeName) {
			let crumb = "";
			const nodeName = getNodeName(node);
			crumb += nodeName;

			const addNodePropToCrumb = (propName) => {
				const propValue = node[propName];
				crumb += `[${propName}="${propValue}"]`;
			};

			const addNodeTextToCrumb = () => {
				const text = getNodeText(node);
				if (text) {
					crumb += `[${text}]`;
				}
			};

			if (nodeName === "button" && node.childNodes) {
				let text;
				[].forEach.call(node.childNodes, (node) => {
					text = text || getNodeText(node);
					return text;
				});
				text = text || node.textContent;

				crumb += `[${text}]`;
			}

			if (node.parentNode && getNodeName(node.parentNode) === "button") {
				return addNodeToMessage(node.parentNode);
			}

			if (node.form) {
				addNodeToMessage(node.form);
			}

			if (nodeName === "span") {
				addNodeTextToCrumb();
			}

			const { id, type, name, labels } = node;

			if (labels && labels[0]) {
				crumb += `[${labels[0].textContent}]`;
			}

			if (id) {
				crumb += `#${id}`;
			}

			if (name) {
				addNodePropToCrumb("name");
			}

			if (type && nodeName !== "textarea") {
				addNodePropToCrumb("type");
			}

			if (type === "checkbox") {
				addNodePropToCrumb("checked");
			}

			const nodeNamesToAdd = [ "a", "span", "strong" ];
			if (crumb === nodeName && nodeNamesToAdd.includes(nodeName)) {
				addNodeTextToCrumb();
			}

			addCrumbToMessage(crumb);
		}
	};

	const target = hint && hint.event && hint.event.target;

	if (target) {
		addNodeToMessage(target);
	}

	if (message && message !== getNodeName(target)) {
		return {
			...breadcrumb,
			message
		};
	}

	return breadcrumb;
}
