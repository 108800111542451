/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaFlowNodeTypeOutputDefinitionMap } from "@hexio_io/hae-lib-blueprint";
import { FLOW_NODE_TYPES } from "./BlueprintNode";

export const BlueprintFlowNodeTypeStart = {
	name: FLOW_NODE_TYPES.START,
	label: "Start",
	icon: "mdi/play",
	opts: BP.Const.Object({ props: {} }),
	editorOptions: {
		displayInPalette: false
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		onStart: { label: "On Start", icon: "mdi/play" }
	})
};