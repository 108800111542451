/**
 * hae-ext-components-pro
 *
 * Hexio App Engine: Set of pro components
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { customChart as customChartEditorComponent } from "./editor/components/customChart";
import { customComponent as customComponentComponent } from "./editor/components/customComponent";
import { customHtml as customHtmlEditorComponent } from "./editor/components/customHtml";

import { customChart as customChartEditorSchema } from "./editor/schemas/customChart";
import { customHtml as customHtmlEditorSchema } from "./editor/schemas/customHtml";

export const termsEditor = {
	components: {
		customChart: customChartEditorComponent,
		customHtml: customHtmlEditorComponent,
		customComponent: customComponentComponent
	},

	schemas: {
		customChart: customChartEditorSchema,
		customHtml: customHtmlEditorSchema
	}
};

export const termsRuntime = {
	components: {}
};