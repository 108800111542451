/**
 * Table HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	SCHEMA_VALUE_TYPE,
} from "@hexio_io/hae-lib-blueprint";

import {
	SORT_ORDER_MODE,
	SORT_ORDER_MODE_default,
	BackgroundColorSchema,
	ForegroundColorSchema,
	termsEditor as HAELibComponentsTerms,
	getValuesFromStringEnum,
	propGroups,
	TextValueSchema,
	NameSchema
} from "@hexio_io/hae-lib-components";

import { isFunction } from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";
import { TableBaseProps, TableColumnBaseProps } from "./baseProps";

export const HAEComponentTable_Props = {

	...TableBaseProps,

	// Has to be Const.Array because of scoped template or something
	columns: BP.Prop(BP.Const.Array({
		...termsEditor.schemas.table.columns,
		/*
			@todo David & Jirka; doesn't work, why?

			default: [{
			titles: [],
			horizontalAlign: TABLE_DEFAULT_HORIZONTAL_ALIGN,
			content: [],
			width: "",
			minWidth: "",
			visible: true,
			render: true
		}],*/
		fallbackValue: [],
		constraints: {
			required: true
		},
		items: BP.Const.Object({
			props: {
				...TableColumnBaseProps,

				titles: BP.Prop(BP.Array({
					...termsEditor.schemas.table.columnTitles,
					default: [],
					fallbackValue: [],
					items: BP.Object({
						props: {
							textValue: BP.Prop(TextValueSchema({
								alias: "textValue_tableTitle",
								...termsEditor.schemas.table.columnTitle,
								editorOptions: {
									layoutType: ""
								}
							}), 0),

							sortKey: BP.Prop(BP.String({
								...termsEditor.schemas.table.columnSortKey
							}), 10),

							sortOrderMode: BP.Prop(BP.Enum.String({
								...termsEditor.schemas.table.columnSortOrderMode,
								options: getValuesFromStringEnum(SORT_ORDER_MODE, HAELibComponentsTerms.schemas.common.sortOrderModeValues),
								default: SORT_ORDER_MODE_default
							}), 20)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					}),
					constraints: {
						required: true
					},
					outlineOptions: {
						displayChildren: true,
						allowAddElement: true
					},
					getElementModelNodeInfo: (modelNode) => {
						return {
							label: modelNode.type === SCHEMA_VALUE_TYPE.CONST &&
								modelNode.constant.props.textValue.type === SCHEMA_VALUE_TYPE.CONST &&
								modelNode.constant.props.textValue.constant.value,
							icon: "mdi/format-title"
						};
					}
				}), 10),

				content: BP.Prop(BP.ScopedTemplate({
					...termsEditor.schemas.table.columnContent,
					template: BP.ComponentList({
						/*inheritedProps: {
							itemWidth: ItemInheritedProps.itemWidth,

							itemHeight: ItemInheritedProps.itemHeight
						}*/
					})
				}), 90)
			},
			outlineOptions: {
				displayChildren: true,
				displayOnlyProps: [ "titles", "content" ]
			}
		}),
		outlineOptions: {
			displayChildren: true,
			allowAddElement: true
		},
		getElementModelNodeInfo: (modelNode) => {
			if (modelNode.props.titles.type === SCHEMA_VALUE_TYPE.CONST) {
				const { getElementModelNodeInfo } = modelNode.props.titles.constant.schema.opts;

				if (isFunction(getElementModelNodeInfo)) {
					return {
						label: modelNode.props.titles.constant.items.map((item, itemIndex) => {
							return item.type === SCHEMA_VALUE_TYPE.CONST &&
								getElementModelNodeInfo(item, itemIndex)?.label
						}).filter(Boolean).join(", "),
						icon: "mdi/table-columns"
					};
				}
			}
		}
	}), 0, propGroups.common),

	rowOptions: BP.Prop(BP.ScopedTemplate({
		...termsEditor.schemas.table.rowOptions,
		template: BP.Object({
			props: {
				key: BP.Prop(BP.String({
					...termsEditor.schemas.table.rowOptionsKey,
					default: null
				}), 0),

				highlight: BP.Prop(BP.Array({
					...termsEditor.schemas.table.rowOptionsHighlight,
					items: BP.Object({
						props: {
							name: BP.Prop(NameSchema(), 0),

							condition: BP.Prop(BP.Boolean({
								...termsEditor.schemas.table.rowOptionsHighlightCondition,
								default: false,
								fallbackValue: false
							}), 10),

							backgroundColor: BP.Prop(BackgroundColorSchema(), 20),

							foregroundColor: BP.Prop(ForegroundColorSchema(), 30)
						},
						editorOptions: {
							layoutType: "noHeader"
						}
					}),
					getElementModelNodeInfo: (modelNode) => {
						return {
							label:
								modelNode.type === SCHEMA_VALUE_TYPE.CONST &&
								modelNode.constant.props.name.type === SCHEMA_VALUE_TYPE.CONST &&
								modelNode.constant.props.name.constant.value,
							icon: "mdi/tune"
						};
					}
				}), 10)
			},
		}),
		editorOptions: {
			layoutType: "noHeader"
		}
	}), 0, {
		...termsEditor.schemas.table.rowOptions,
		id: "rowOptions",
		order: propGroups.common.order + 1,
		defaultExpanded: true
	})

};