/**
 * Button Group component terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const buttonGroup = {
	component: {
		label: "component:buttonGroup#label",
		description: "component:buttonGroup#description"
	},
	dropText: {
		label: "component:buttonGroup.dropText#label"
	}
};
