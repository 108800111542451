/**
 * Data Table component runtime terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const dataTable = {
	items: {
		label: "component:dataTable.items#label"
	},
	export: {
		label: "component:dataTable.export#label"
	}
};
