/**
 * Table HAE component base props
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	SCHEMA_CONST_ANY_VALUE_TYPE
} from "@hexio_io/hae-lib-blueprint";

import {
	SPACING,
	TextSchema,
	BorderColorSchema,
	termsEditor as HAELibComponentsTerms,
	getValuesFromStringEnum,
	propGroups,
	spacingPattern,
	HORIZONTAL_ALIGN,
	dimensionPattern,
	SORT_ORDER,
	SORT_ORDER_default,
	HORIZONTAL_ALIGN_opts,
	SPACING_default,
	HORIZONTAL_ALIGN_stretch} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";

/**
 * Defaults
 */
export const TABLE_DEFAULT_HORIZONTAL_ALIGN = HORIZONTAL_ALIGN_stretch;

/**
 * Table base props
 */
export const TableBaseProps = {

	items: BP.Prop(BP.Array({
		...termsEditor.schemas.table.items,
		default: null,
		fallbackValue: null,
		constraints: {
			required: true
		},
		items: BP.Any({
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
		}),
		/*getElementLabel: (modelNode, index) => {
			//return modelNode.type === SCHEMA_VALUE_TYPE.CONST &&
			return "";
		}*/
	}), 10, propGroups.common),

	sort: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.table.sort,
		enabledOpts: {
			default: false
		},
		value: BP.Object({
			props: {
				key: BP.Prop(BP.String({
					...termsEditor.schemas.table.sortKey,
				}), 0),
	
				order: BP.Prop(BP.Enum.String({
					...termsEditor.schemas.table.sortOrder,
					options: getValuesFromStringEnum(SORT_ORDER, termsEditor.schemas.table.sortOrderValues),
					default: SORT_ORDER_default,
					fallbackValue: SORT_ORDER_default,
					constraints: {
						required: true
					}
				}), 10),

				client: BP.Prop(BP.Boolean({
					...termsEditor.schemas.table.sortClient,
					default: true,
					fallbackValue: true,
					constraints: {
						required: true
					}
				}), 20)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 30, propGroups.common),

	borderData: BP.Prop(BP.OneOf({
		...termsEditor.schemas.table.borderData,
		defaultType: "ROWS",
		types: {
			"NONE": {
				...termsEditor.schemas.table.borderDataValues.none,
				value: BP.Void({})
			},
			"ROWS": {
				...termsEditor.schemas.table.borderDataValues.rows,
				value: BP.Object({
					props: {
						borderColor: BP.Prop(BorderColorSchema())
					},
					editorOptions: {
						layoutType: "passthrough"
					}
				})
			},
			"CELLS": {
				...termsEditor.schemas.table.borderDataValues.cells,
				value: BP.Object({
					props: {
						borderColor: BP.Prop(BorderColorSchema())
					},
					editorOptions: {
						layoutType: "passthrough"
					}
				})
			}
		},
		constraints: {
			required: true
		}
	}), 0, propGroups.style),

	spacing: BP.Prop(BP.StringWithConst({
		...termsEditor.schemas.table.spacing,
		constants: getValuesFromStringEnum(SPACING, HAELibComponentsTerms.schemas.common.spacingValues),
		default: SPACING_default,
		constraints: {
			...HAELibComponentsTerms.schemas.common.spacingConstraints,
			pattern: spacingPattern,
		}
	}), 10, propGroups.style),

	emptyText: BP.Prop(TextSchema({
		...termsEditor.schemas.table.emptyText,
		props: {
			overflow: BP.Prop(BP.Void({}))
		},
		editorOptions: {
			layoutType: "noHeader"
		}
	}), 0, {
		...termsEditor.schemas.table.emptyText,
		id: "emptyText",
		order: propGroups.style.order + 2,
		defaultExpanded: true
	})

};

/**
 * Table Column base props
 */
export const TableColumnBaseProps = {

	horizontalAlign: BP.Prop(BP.Enum.String({
		...HAELibComponentsTerms.schemas.common.horizontalAlign,
		options: getValuesFromStringEnum(
			HORIZONTAL_ALIGN,
			HAELibComponentsTerms.schemas.common.horizontalAlignValues,
			HORIZONTAL_ALIGN_opts
		),
		default: TABLE_DEFAULT_HORIZONTAL_ALIGN,
		fallbackValue: TABLE_DEFAULT_HORIZONTAL_ALIGN,
		editorOptions: {
			controlType: "buttons"
		},
		constraints: {
			required: true
		}
	}), 30),

	width: BP.Prop(BP.String({
		...termsEditor.schemas.table.columnWidth,
		constraints: {
			...HAELibComponentsTerms.schemas.common.dimensionConstraints,
			pattern: dimensionPattern
		}
	}), 40),

	minWidth: BP.Prop(BP.String({
		...termsEditor.schemas.table.columnMinWidth,
		constraints: {
			...HAELibComponentsTerms.schemas.common.dimensionConstraints,
			pattern: dimensionPattern
		}
	}), 50),

	visible: BP.Prop(BP.Boolean({
		...termsEditor.schemas.table.columnVisible,
		default: true,
		fallbackValue: true,
		constraints: {
			required: true
		}
	}), 100),

	render: BP.Prop(BP.Boolean({
		...termsEditor.schemas.table.columnRender,
		default: true,
		fallbackValue: true,
		constraints: {
			required: true
		}
	}), 110)

};