/*
 * generateId HAE component
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { v4 } from "uuid";

export function generateId(): string {
	return v4();
}
