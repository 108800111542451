/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAppServer } from "../app";
import { IIntegrationOptions } from "./IIntegrationOptions";
import { TDecoratorIntegrationDefinition, TIntegrationFunctionsDefinitions } from "./IIntegrationDefinition";
import { IIntegrationFactory } from "./IIntegrationFactory";
import { IntegrationBase } from "./IntegrationBase";

/**
 * Creates a integration factory for a integration based on a class IntegrationBase
 */
export function createIntegrationFactory<
	TOptions extends IIntegrationOptions,
	TClass extends IntegrationBase<TOptions>
>(
	entity: new (
		integrationName: string,
		config: TOptions,
		app: IAppServer
	) => TClass,
	integrationDefinition: TDecoratorIntegrationDefinition,
	functionDefinitions: TIntegrationFunctionsDefinitions
): IIntegrationFactory<TClass, TOptions> {

	return {
		factory: (
			integrationName: string,
			config: TOptions,
			app: IAppServer
		) => {

			const instance = new entity(integrationName, config, app);
			instance.setDefinitions(
				integrationDefinition,
				functionDefinitions
			);
			return instance;

		}
	}

}
