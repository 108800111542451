/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaFlowNodeTypeOutputDefinitionMap } from "@hexio_io/hae-lib-blueprint";
import { FLOW_NODE_TYPES } from "./BlueprintNode";

export const BlueprintFlowNodeTypeStartViewToHtml = {
	name: FLOW_NODE_TYPES.VIEWTOHTML,
	label: "View to HTML",
	icon: "mdi/language-html5",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.ViewRef({
		constraints: { required: true }
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		onSuccess: { label: "On Success", icon: "mdi/check" },
		onError: { label: "On Error", icon: "mdi/alert" }
	})
};