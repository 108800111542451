/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export type TConstantType = string | number | boolean | null;

export enum CONSTANTS_TYPES {
	string = "string",
	number = "number",
	boolean = "boolean"
}

export interface IConstantEnvVar {
	name: string;
	type: CONSTANTS_TYPES;
	default: TConstantType;
	public: boolean;
}

export interface IConstant {
	value: TConstantType;
	public: boolean;
}

export interface IConstantsManifest {
	[K: string]: {
		type: "constant" | "envvar";
		value: {
			constant: IConstant;
			envvar: IConstantEnvVar;
		}
	}
}

export interface IConstants {
	[K: string]: TConstantType;
}