/**
 * Composed Chart Line Series Style enum
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum COMPOSED_CHART_LINE_SERIES_STYLE {
	LINEAR = "linear",
	SMOOTH = "smooth",
	STEP = "step"
}

export type COMPOSED_CHART_LINE_SERIES_STYLE_string = keyof typeof COMPOSED_CHART_LINE_SERIES_STYLE;

export const COMPOSED_CHART_LINE_SERIES_STYLE_default: COMPOSED_CHART_LINE_SERIES_STYLE_string = "LINEAR";
