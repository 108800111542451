/**
 * StyleSheet class
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

import { isFunction, isNonEmptyObject } from "@hexio_io/hae-lib-shared";

import { COLOR } from "../Enums/COLOR";
import { IResolvedResponsiveValueProperty, TResolvedResponsiveValue } from "../Functions/responsiveValueHelpers";
import { getStringEnumCssValue } from "../Functions/enumHelpers";
import { MEDIA_RESOLUTIONS_string } from "@hexio_io/hae-lib-blueprint";

/**
 * StyleSheet type
 */
export type IStyleSheet = Array<string>;

/**
 * StyleSheet Color Property
 */
export interface IStyleSheetColorProperty {
	/** Selector */
	selector: string;

	/** Property name */
	name: "element-background-color" | "element-foreground-color" | "element-border-color";

	/** Property value */
	value: string;

	/** Property value suffix */
	valueSuffix?: string;
}

/**
 * StyleSheet class
 *
 * Simple class for handling classLists
 */
export class StyleSheet extends Array implements IStyleSheet {
	/**
	 * StyleSheet constructor
	 *
	 * @param styleSheets multiple StyleSheets
	 */
	constructor(...styleSheets: IStyleSheet) {
		super();

		this.add(...styleSheets);
	}

	/**
	 * Converts style object to css text
	 *
	 * @param styleObject Style object
	 */
	static objectToCssText(styleObject: unknown): string {
		if (!isNonEmptyObject(styleObject)) {
			return "";
		}

		return Object.entries(styleObject).map(([ key, value ]) => {
			return `${key.replace(/[A-Z]/g, (match: string) => `-${match.toLowerCase()}`)}: ${value};`;
		}).join(" ");
	}

	/**
	 * Adds StyleSheet (merge)
	 *
	 * @param styleSheets StyleSheets to add
	 */
	public add(...styleSheets: IStyleSheet): StyleSheet {
		if (styleSheets && styleSheets.length > 0) {
			styleSheets.forEach((item) => {
				this.addString(item);
			});
		}

		return this;
	}

	/**
	 * Adds string styleSheet
	 *
	 * @param styleSheetOrSelector StyleSheet or selector
	 * @param properties StyleSheet properties
	 */
	public addString(styleSheetOrSelector: string, properties?: string): StyleSheet {
		const styleSheet = typeof properties === "string" ? `${styleSheetOrSelector} { ${properties} }` : styleSheetOrSelector;

		this.push(styleSheet);

		return this;
	}

	/**
	 * Adds responsive value properties
	 *
	 * @param responsiveValue Resolved responsive value
	 * @param selector Selector
	 * @param propertyNameOrPropertiesCallback CSS property name
	 * @param media Media selector base
	 */
	public addResponsiveValue(
		responsiveValue: TResolvedResponsiveValue,
		selector: string,
		propertyNameOrPropertiesCallback: string | ((value: unknown, mediaQuery: string) => string),
		media?: MEDIA_RESOLUTIONS_string
	): StyleSheet {
		responsiveValue.forEach((item: IResolvedResponsiveValueProperty) => {
			let styleSheet = "";

			if (isFunction(propertyNameOrPropertiesCallback)) {
				const properties = propertyNameOrPropertiesCallback(item.value, item.mediaQuery);

				if (properties) {
					styleSheet = `${selector} { ${properties} }`;
				}
			}
			else {
				// eslint-disable-next-line max-len
				styleSheet = `${selector} { ${propertyNameOrPropertiesCallback}: ${typeof item.value === "object" ? Object.values(item.value).join(" ") : item.value}; }`;
			}

			if (styleSheet) {
				if (item.mediaQuery) {
					if (!media) {
						styleSheet = `@media ${item.mediaQuery} { ${styleSheet} }`;
					}
					else if (media !== item.name) {
						return;
					}
				}

				this.addString(styleSheet);
			}
		});

		return this;
	}

	/**
	 * Adds color properties
	 *
	 * @param properties Properties
	 */
	public addColorProperties(properties: IStyleSheetColorProperty | IStyleSheetColorProperty[]): StyleSheet {
		const propertiesArray = Array.isArray(properties) ? properties : [ properties ];

		propertiesArray.forEach((item) => {
			const colorValue = getStringEnumCssValue(COLOR, item.value, "color-", item.valueSuffix);

			this.addString(item.selector, `--${item.name}: ${colorValue} !important;`);
		});

		return this;
	}

	/**
	 * Whether or not is StyleSheet empty
	 */
	public isEmpty(): boolean {
		return !this.length;
	}

	/**
	 * Creates string from the StyleSheet
	 */
	public toString(): string {
		return this.join("\r\n").replace(/\t/g, "").replace(/  +/g, " ");
	}

	/**
	 * Renders StyleSheet
	 */
	public render(): unknown {
		if (this.isEmpty()) {
			return null;
		}

		return React.createElement("style", { dangerouslySetInnerHTML: { __html: this.toString() } });
	}

}
