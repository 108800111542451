/**
 * Grid schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const grid = {
	content: {
		label: "schema:grid.content#label"
	},
	contentItemPosition: {
		label: "schema:grid.content#inheritedProps.itemPosition#label",
		description: "schema:grid.content#inheritedProps.itemPosition#description"
	},
	contentItemPositionX: {
		label: "schema:grid.content#inheritedProps.itemPosition#props.x#label",
		description: "schema:grid.content#inheritedProps.itemPosition#props.x#description"
	},
	contentItemPositionY: {
		label: "schema:grid.content#inheritedProps.itemPosition#props.y#label",
		description: "schema:grid.content#inheritedProps.itemPosition#props.y#description"
	},
	contentItemPositionWidth: {
		label: "schema:grid.content#inheritedProps.itemPosition#props.width#label",
		description: "schema:grid.content#inheritedProps.itemPosition#props.width#description"
	},
	contentItemPositionHeight: {
		label: "schema:grid.content#inheritedProps.itemPosition#props.height#label",
		description: "schema:grid.content#inheritedProps.itemPosition#props.height#description"
	},
	contentItemSpacing: {
		label: "schema:grid.content#inheritedProps.itemSpacing#label",
		description: "schema:grid.content#inheritedProps.itemSpacing#description"
	},
	columns: {
		label: "schema:grid.columns#label",
		description: "schema:grid.columns#description"
	},
	padding: {
		label: "schema:grid.padding#label",
		description: "schema:grid.padding#description"
	},
	spacing: {
		label: "schema:grid.spacing#label",
		description: "schema:grid.spacing#description"
	},
	role: {
		label: "schema:grid.role#label",
		description: "schema:grid.role#description"
	},
	roleValues: {
		none: {
			label: "schema:grid.role#values.none#label"
		},
		form: {
			label: "schema:grid.role#values.form#label"
		}
	}
};
