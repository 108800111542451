/**
 * Vertical Alignment enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum VERTICAL_ALIGN {
	TOP = "top",
	MIDDLE = "middle",
	BOTTOM = "bottom",
	STRETCH = "stretch"
}

export type VERTICAL_ALIGN_string = keyof typeof VERTICAL_ALIGN;

export const VERTICAL_ALIGN_default: VERTICAL_ALIGN_string = "MIDDLE";

export const VERTICAL_ALIGN_top: VERTICAL_ALIGN_string = "TOP";

export const VERTICAL_ALIGN_bottom: VERTICAL_ALIGN_string = "BOTTOM";

export const VERTICAL_ALIGN_opts = {
	top: {
		icon: "mdi/format-vertical-align-top"
	},
	middle: {
		icon: "mdi/format-vertical-align-center"
	},
	bottom: {
		icon: "mdi/format-vertical-align-bottom"
	},
	stretch: {
		icon: "mdi/arrow-expand-vertical"
	}
};
