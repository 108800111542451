/**
 * Position Helpers
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Computes absolute element position which is relative to a parent
 * @param targetEl 
 * @param parentEl 
 * @returns 
 */
export function getAbsElementPositionWithinParent(targetEl: HTMLElement, parentEl: HTMLElement): {
	x: number,
	y: number
} {

	const targetRect = targetEl.getBoundingClientRect();
	const parentRect = parentEl.getBoundingClientRect();

	return {
		x: targetRect.x - parentRect.x,
		y: targetRect.y - parentRect.y
	};

}