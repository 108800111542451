/**
 * useUuid HAE hook
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { useMemo } from "react";
import { generateId } from "../Functions/generateId";

export function useUuid(): string {
	return useMemo(() => generateId(), []);
}
