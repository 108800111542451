/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { DesignContext } from "../../Context/DesignContext";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { ISchemaImportExport } from "../../ExportImportSchema/ExportTypes";
import {
	IBlueprintSchema, TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel
} from "../../Schema/IBlueprintSchema";
import {
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	validateAsNotSupported,
	assignParentToModelProps
} from "../../Schema/SchemaHelpers";
import { ISchemaConstBoolean, SchemaConstBoolean } from "../const/SchemaConstBoolean";
import {
	ISchemaConstObject,
	ISchemaConstObjectOpts,
	ISchemaConstObjectOptsProp,
	Prop,
	SchemaConstObject,
	TSchemaConstObjectProps
} from "../const/SchemaConstObject";
import { ISchemaBuilderOpts, SchemaBuilderBaseProps, TCompareSchemaBuilderProps, TSchemaBuilderBaseProps } from "./SchemaBuilderShared";
import { IModelNode } from "../../Schema/IModelNode";
import { TypeDescAny } from "../../Shared/ITypeDescriptor";
import { applyCodeArg, escapeString } from "../../Context/CompileUtil";
import { ISchemaConstAny, SchemaConstAny, SCHEMA_CONST_ANY_VALUE_TYPE } from "../const/SchemaConstAny";
import { ISchemaBuilderAny, SchemaBuilderAny, SCHEMA_BUILDER_ANY_VALUE_TYPE } from "./SchemaBuilderAny";
import { ISchemaConstMap, SchemaConstMap } from "../const/SchemaConstMap";

type TSchemaBuilderObjectPropsSchema = ISchemaConstObject<
	TSchemaBuilderBaseProps & {
		props: ISchemaConstObjectOptsProp<ISchemaConstMap<ISchemaBuilderAny>>;
		default: ISchemaConstObjectOptsProp<ISchemaConstMap<ISchemaConstAny>>;
		fallbackValue: ISchemaConstObjectOptsProp<ISchemaConstMap<ISchemaConstAny>>;
		constraints: ISchemaConstObjectOptsProp<ISchemaConstObject<{
			required?: ISchemaConstObjectOptsProp<ISchemaConstBoolean>;
		}>>
	}
>;

/**
 * Development helper to check types - when resolves to "true" then the props schema is correct and up to date.
 * Otherwise you have an error somewhere. It compares if SchemaBuilder...PropsSchema output (spec) equals to the interface
 * that describes options of a target schema the builder represents.
 */
type _TypeCheck = TCompareSchemaBuilderProps<
	ISchemaConstObjectOpts<TSchemaConstObjectProps>,
	TSchemaBuilderObjectPropsSchema,
	"props"|"outlineOptions"|"getElementModelNodeInfo"
>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __HERE_SHOULD_BE_NO_TYPE_ERRORS__: _TypeCheck = true;

/**
 * Opts Schema
 */
export interface ISchemaBuilderObjectOptsSchema extends ISchemaBuilderOpts {}

/**
 * Schema Model
 */
export interface ISchemaBuilderObjectModel extends IModelNode<ISchemaBuilderObject> {
	props: TGetBlueprintSchemaModel<TSchemaBuilderObjectPropsSchema>
}

export type TSchemaBuilderObjectSpec = ISchemaImportExport;
export type TSchemaBuilderObjectDefault = TGetBlueprintSchemaDefault<TSchemaBuilderObjectPropsSchema>;

/**
 * Schema Builder Boolean Schema
 */
export interface ISchemaBuilderObject extends IBlueprintSchema<
	ISchemaBuilderOpts,
	ISchemaBuilderObjectModel,
	ISchemaImportExport,
	TSchemaBuilderObjectDefault
> { }

/**
 * Schema Builder: Boolean
 *
 * @param opts Schema options
 */
export function SchemaBuilderObject(opts: ISchemaBuilderOpts): ISchemaBuilderObject {

	type TPropsModel = TGetBlueprintSchemaModel<TSchemaBuilderObjectPropsSchema>;

	const propsSchema: TSchemaBuilderObjectPropsSchema = SchemaConstObject({
		constraints: opts.constraints,
		props: {
			...SchemaBuilderBaseProps,
			props: Prop(SchemaConstMap({
				label: "Properties",
				constraints: {
					required: true
				},
				default: {},
				fallbackValue: {},
				keyOpts: {
					placeholder: "Property name"
				},
				value: SchemaBuilderAny({
					defaultType: SCHEMA_BUILDER_ANY_VALUE_TYPE.STRING,
					constantOnly: opts.constantOnly,
					constraints: {
						required: true
					}
				})
			}), 10),
			constraints: Prop(SchemaConstObject({
				label: "Constraints",
				description: "Validation rules.",
				props: {
					required: Prop(SchemaConstBoolean({
						label: "Required",
						description: "If a value is required."
					}), 10)
				},
				editorOptions: {
					hidden: true
				}
			}), 20),
			default: Prop(SchemaConstMap({
				label: "Default value",
				description: "Default field value.",
				value: SchemaConstAny({
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
				}),
				editorOptions: {
					hidden: true
				}
			}), 60),
			fallbackValue: Prop(SchemaConstMap({
				label: "Fallback value",
				description: "Value which will be used when a field value is not valid.",
				value: SchemaConstAny({
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
				}),
				editorOptions: {
					hidden: true
				}
			}), 70)
		}
	});

	const schema = createEmptySchema<ISchemaBuilderObject>("builderObject", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, ["props"])
	}

	const createModel = (
		dCtx: DesignContext,
		propsModel: TPropsModel,
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx, parent,[], {
			props: propsModel
		});

		const model = assignParentToChildrenOf(modelNode)

		model.initRequiredValid = propsModel.initRequiredValid;

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		const propsModel = propsSchema.createDefault(dCtx, null, defaultValue);
		return createModel(dCtx, propsModel, parent);

	};

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedPropsModel = propsSchema.clone(dCtx, modelNode.props, null);

		const clone = cloneModelNode(dCtx, modelNode, parent,{
			props: clonedPropsModel
		})

		return assignParentToChildrenOf(clone)

	};

	schema.destroy = (modelNode) => {

		propsSchema.destroy(modelNode.props);
		modelNode.props = undefined;
		destroyModelNode(modelNode);

	};

	schema.parse = (dCtx, idtNode, parent) => {

		const propsModel = propsSchema.parse(dCtx, idtNode, parent);
		return createModel(dCtx, propsModel, parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		if (propsSchema.provideCompletion) {
			propsSchema.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}

	};

	schema.serialize = (modelNode, path) => {

		return propsSchema.serialize(modelNode.props, path);

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		const prevPropsSpec = prevSpec?._type === "SchemaValue" ? prevSpec._values[0]._values[0] : prevSpec?._values?.[0];
		const propsSpec = propsSchema.render(rCtx, modelNode.props, path, scope, prevPropsSpec);

		const objPropItems = modelNode.props.props.props.items;
		const objPropsDef: { [K: string]: { schema: ISchemaImportExport, order: number } }  = {};

		for (let i = 0; i < objPropItems.length; i++) {

			const propName = objPropItems[i].key.value;

			objPropsDef[propName] = {
				schema: propsSpec.props[propName],
				order: i
			};

		}

		const propsBase = {
			...propsSpec
		};

		delete propsBase.props;

		const exportData = {
			_type: "Schema",
			_name: "SchemaConstObject",
			_values: [
				{
					...propsBase,
					props: objPropsDef
				}
			]
		};

		if (opts.constantOnly === true) {
			return exportData;
		} else {
			return {
				_type: "Schema",
				_name: "SchemaValue",
				_values: [exportData, propsBase]
			}
		}

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		const propsCmp = propsSchema.compileRender(cCtx, modelNode.props, path);

		const basePropsCode = applyCodeArg(propsCmp, `_pp`, `pt`);

		const objPropItems = modelNode.props.props.props.items;
		const objPropsCmp: { [K: string]: string }  = {};

		for (let i = 0; i < objPropItems.length; i++) {

			const propName = objPropItems[i].key.value;
			const propValue = objPropItems[i].value;

			const valueCode = applyCodeArg(
				propValue.schema.compileRender(cCtx, propValue, path.concat(["props", `${i}`])),
				`_pp&&_pp.props?_pp.props["${escapeString(propName)}"]:undefined`,
				`pt.concat(["props","${escapeString(String(i))}"])`
			);

			objPropsCmp[propName] = `"${escapeString(propName)}":{schema:${valueCode},order:${i}}`;

		}

		const objPropsAssignCode = `{${Object.keys(objPropsCmp).map((k) => objPropsCmp[k]).join(",")}}`;

		return {
			isScoped: true,
			code: `(s,pv,pt)=>{${[
				// Previous props
				`const _pp=pv&&pv._type==="SchemaValue"?pv._values[0]._values[0]:pv&&pv._values?pv._values[0]:undefined;`,
				// opts
				`const _op=${basePropsCode};`,
				`const _bp={..._op};`,
				`delete _bp.props;`,
				// Export data
				`const _ed={_type:"Schema",_name:"SchemaConstObject",_values:[{..._bp,props:${objPropsAssignCode}}]};`,
				// Return
				opts.constantOnly
					? `return _ed`
					: `return {_type:"Schema",_name:"SchemaValue",_values:[_ed,_bp]}`
			].join("")}}`
		}

	};

	/** Schema value cannot be validated because spec is ExportImport type which should not be constructed in any manuall way */
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	schema.compileValidate = (cCtx, path, modelNodeId) => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	schema.export = () => {
		return exportSchema("SchemaBuilderObject", [opts]);
	};

	/** Is any because export data are internals and should not be exposed. */
	schema.getTypeDescriptor = () => {
		return TypeDescAny({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = (modelNode) => {
		return [{
			key: "props",
			node: modelNode.props
		}]
	}

	return schema;

}
