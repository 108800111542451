/**
 * Hexio App Engine Core
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ActionDelegate, IActionDelegateSerializedState, IActionParams } from "@hexio_io/hae-lib-core";
import { valueToNormalSignature } from "@hexio_io/hae-lib-shared";
import { TAppState_ActionDelegateList } from "../../shared/IAppState";
import { ApiApp } from "./ApiApp";

const CHECK_TIMEOUT_MS = 1000;
const DELEGATE_CACHE_TIMEOUT_MS = 10000;

/**
 * Action Repository Configuration Options
 */
export interface ActionRepositoryOpts {
	actionOpts: {
		withTypeDescriptor?: boolean,
		debug?: boolean
	}
}

/**
 * Action Repository
 * Manages Action Delegates
 */
export class ActionRepository {

	/** Repository config */
	private config: ActionRepositoryOpts;

	/** Application API client */
	private api: ApiApp;

	/** Index of existing delegate instances */
	private delegateInstances: {
		[K: string]: ActionDelegate;
	} = {};

	private reloadTimer: ReturnType<typeof setTimeout>;

	/**
	 * Repository constructor
	 *
	 * @param apiApp ApiApp Instance
	 */
	public constructor(
		apiApp: ApiApp,
		config: ActionRepositoryOpts
	) {

		this.api = apiApp;
		this.config = config;

		this.scheduleCheck();

	}

	/**
	 * Returns or creates a delegate if not exists
	 *
	 * @param actionId Action ID
	 * @param params Action params
	 * @param initialState Initial state
	 */
	private getOrCreateDelegate(actionId: string, params: IActionParams, initialState?: IActionDelegateSerializedState) {

		const hash = valueToNormalSignature({
			actionId: actionId,
			params: params
		});

		if (this.delegateInstances[hash]) {
			return this.delegateInstances[hash];
		} else {
			return this.delegateInstances[hash] = new ActionDelegate(
				actionId,
				params,
				() => this.api.invokeAction(actionId, params, {
					withTypeDescriptor: this.config.actionOpts.withTypeDescriptor,
					debug: this.config.actionOpts.debug
				}),
				initialState
			);
		}

	}

	/**
	 * Returns action delegate
	 *
	 * @param actionId Action ID
	 * @param params Action params
	 */
	public getDelegate(actionId: string, params: IActionParams): ActionDelegate {

		return this.getOrCreateDelegate(actionId, params);

	}

	/**
	 * Injects app state
	 *
	 * @param state View instances state
	 */
	public injectState(state: TAppState_ActionDelegateList): void {
		
		for (let i = 0; i < state.length; i++) {

			this.getOrCreateDelegate(state[i].actionId, state[i].params, state[i]);

			if (window.APP_DEBUG) {
				console.debug(
					"[ActionRepository] Action Delegate '%s' injected from state with params:",
					state[i].actionId,
					state[i].params
				);
			}

		}

	}

	/**
	 * Sets all delegate as ready after injection
	 */
	public setReadyAfterInjection(): void {

		for (const k in this.delegateInstances) {
			this.delegateInstances[k].setReadyAfterInjection();
		}

	}

	/**
	 * Performs check on delegates
	 * Does reloads and disposes unused delegates
	 */
	private doCheck() {
		
		const now = Date.now();

		for (const k in this.delegateInstances) {

			const delegate = this.delegateInstances[k];

			if (delegate.isUsed()) {

				delegate.checkReload();

			} else {
				
				const lastInvoke = delegate.getLastInvocationTimestamp();
				const expired = !lastInvoke || lastInvoke < (now - DELEGATE_CACHE_TIMEOUT_MS) ? true : false;

				if (expired) {

					if (window.APP_DEBUG) {
						console.debug(
							"[ActionRepository] Disposing unused action delegate '%s' with params:",
							delegate.getActionId(),
							delegate.getParams()
						);
					}

					delegate.dispose();
					delete this.delegateInstances[k];

				}

			}

		}

	}

	/**
	 * Schedules check process
	 */
	private scheduleCheck() {

		// Prevent double-schedule
		if (this.reloadTimer) {
			return;
		}

		this.reloadTimer = setTimeout(() => {
			this.reloadTimer = null;
			this.scheduleCheck();
			this.doCheck();
		}, CHECK_TIMEOUT_MS);

	}

}
