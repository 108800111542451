/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTScalar
} from "../../IDT/ISchemaIDT";
import {IBlueprintSchemaScalar, IBlueprintSchemaOpts, TBlueprintSchemaParentNode} from "../../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../../Schema/IModelNode";
import {
	applyRuntimeValidators,
	cloneModelNode,
	compileRuntimeValidators,
	compileScalarNodeRender,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	renderScalarNode,
	validateDefaultValue,
	validateParsedValueAndReport,
	validateValueAndUpdateModel
} from "../../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { DesignContext } from "../../Context/DesignContext";
import { ValidatorBoolean, IValidatorBooleanOpts } from "../../validators/ValidatorBoolean";
import { IBlueprintSchemaValidationError, IBlueprintSchemaValidatorHandler } from "../../Validator/IBlueprintSchemaValidator";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { TypeDescBoolean } from "../../Shared/ITypeDescriptor";
import { inlineValue } from "../../Context/CompileUtil";
import { CMPL_ITEM_KIND, ICompletionItem } from "../../Shared/ICompletionItem";

/**
 * Schema value type
 */
type TSpecType = boolean;

/**
 * Schema options
 */
export interface ISchemaConstBooleanOpts extends IBlueprintSchemaOpts {
	/** Default value for a new node */
	default?: TSpecType;
	/** Base validation constraints */
	constraints?: IValidatorBooleanOpts;
	/** Custom validators */
	validators?: IBlueprintSchemaValidatorHandler<TSpecType>[];
	/** Fallback value to return when validation fails */
	fallbackValue?: TSpecType;
}

/**
 * Schema type
 */
export type ISchemaConstBoolean = IBlueprintSchemaScalar<ISchemaConstBooleanOpts, ISchemaConstBooleanModel, TSpecType>

/**
 * Schema model
 */
export interface ISchemaConstBooleanModel extends IModelNode<ISchemaConstBoolean> {
	value: TSpecType;
}

/**
 * Schema: Boolean scalar constant
 *
 * @param opts Schema options
 */
export function SchemaConstBoolean(opts: ISchemaConstBooleanOpts): ISchemaConstBoolean {

	const validators: IBlueprintSchemaValidatorHandler<TSpecType>[] = [
		ValidatorBoolean(opts.constraints || {})
	].concat(opts.validators || []);

	const errSeverity = opts.fallbackValue !== undefined ? DOC_ERROR_SEVERITY.WARNING : DOC_ERROR_SEVERITY.ERROR;

	const schema = createEmptySchema<ISchemaConstBoolean>("constBoolean", opts);

	const createModel = (dCtx: DesignContext, value: TSpecType, parent: TBlueprintSchemaParentNode,
		validationErrors: IBlueprintSchemaValidationError[]) => {

		return createModelNode(schema, dCtx, parent, validationErrors, {
			value: value
		});
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		// Must be checked explicitly, because when using || it will always resolves to the most positive value
		const value = defaultValue !== undefined && defaultValue !== null
			? defaultValue
			: opts.default !== undefined
				? opts.default
				: null;

		const errors = validateDefaultValue(schema, validators, value);

		return createModel(dCtx, value, parent,errors);

	};

	schema.clone = (dCtx, modelNode, parent) => {
		return cloneModelNode(dCtx, modelNode, parent, {
			value: modelNode.value
		});
	};

	schema.destroy = (modelNode) => {

		modelNode.value = undefined;
		destroyModelNode(modelNode);

	};

	schema.parse = (dCtx, idtNode, parent) => {

		// Check null
		if (!idtNode || (idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL)) {
			return schema.createDefault(dCtx, parent);
		}

		if (idtNode.type !== BP_IDT_TYPE.SCALAR || (
			idtNode.type == BP_IDT_TYPE.SCALAR &&
			idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.BOOLEAN &&
			idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.NULL
		)) {
			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.BOOL_NOT_BOOLEAN,
					message: "Expecting a boolean",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		const errors = validateParsedValueAndReport(dCtx, idtNode, validators, idtNode.value as TSpecType);

		return createModel(dCtx, idtNode.value as TSpecType, parent, errors);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			const items: ICompletionItem[] = [{
				kind: CMPL_ITEM_KIND.Value,
				label: "true",
				insertText: "true"
			}, {
				kind: CMPL_ITEM_KIND.Value,
				label: "false",
				insertText: "false"
			}];

			if (opts.constraints?.required !== true) {
				items.push({
					kind: CMPL_ITEM_KIND.Value,
					label: "null",
					insertText: "null"
				});
			}

			return items;
		});

	};

	schema.serialize = (modelNode, path) => {

		if (modelNode.value !== null) {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.BOOLEAN,
				path: path,
				value: modelNode.value
			} as IBlueprintIDTScalar;

		} else {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.NULL,
				path: path,
				value: null
			} as IBlueprintIDTScalar;

		}

	};

	schema.render = (rCtx, modelNode, path, scope) => {

		return renderScalarNode(
			modelNode, scope,
			modelNode.value,
			opts.fallbackValue !== undefined && opts.fallbackValue !== null
				? opts.fallbackValue
				: opts.default !== undefined ? opts.default : null
		);

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		return compileScalarNodeRender(
			cCtx, modelNode, path, errSeverity,
			inlineValue(modelNode.value),
			opts.fallbackValue !== undefined && opts.fallbackValue !== null
				? opts.fallbackValue
				: opts.default !== undefined ? opts.default : null
		);

	};

	schema.validate = (rCtx, path, modelNodeId, value) => {
		return applyRuntimeValidators(rCtx, path, modelNodeId, validators, errSeverity, value);
	};

	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileRuntimeValidators(cCtx, path, modelNodeId, validators, errSeverity);
	};

	schema.castSpec = (_rCtx, _path, _modelNodeId, value) => {

		return (
			value === true ||
			value === "true" ||
			value === "1" ||
			value === "on" ||
			value === "yes" ||
			(typeof value === "number" && value > 0)
		)
			? true
			: (
				value === false ||
				value === "false" ||
				value === "0" ||
				value === "off" ||
				value === "no" ||
				(typeof value === "number" && value <= 0)
			)
				? false
				: value;

	};

	schema.compileCastSpec = () => {

		/* eslint-disable indent, max-len */
		return `(v,pt)=>${[
			`(v===true||v==="true"||v==="1"||v==="on"||v==="yes"||(typeof v==="number"&&v>0))`,
			`?true`,
			`:(v===false||v==="false"||v==="0"||v==="off"||v==="no"||(typeof v==="number"&&v<=0))`,
			`?false`,
			`:v`
		].join("")}`
		/* eslint-enable indent, max-len */

	};

	schema.getValue = (modelNode) => {
		return modelNode.value;
	};

	schema.setValue = (modelNode, value, notify) => {

		validateValueAndUpdateModel(modelNode, validators, value);
		modelNode.value = value;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}

	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaConstBoolean", [opts]);
	};

	schema.getTypeDescriptor = () => {
		return TypeDescBoolean({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = () => {
		return [];
	}

	/* @todo Disabled because of SchemaBuilder
	 * If user configures default value that is not validate due to constraints
	 * the error will be thrown and blueprints becomes invalid.
	 * This could be resolved when schema builder will be able to dynamically validate
	 * the value field against configured constraints.
	 */
	// if (opts.default !== null && opts.default !== undefined) {
	// 	validateDefaultValue(schema, validators, opts.default);
	// }

	return schema;

}

