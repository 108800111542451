/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, SCHEMA_CONST_ANY_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";
import { BlueprintEndpointFlowNodeTypes } from "../BlueprintEndpoint";
import { termsEditor } from "../../terms";

export enum FLOW_NODE_TYPES {
	START = "start",
	INTEGRATION = "integration",
	ACTION = "action",
	TRANSFORM = "transform",
	CONDITION = "condition",
	OUTPUT = "output",
	ERROR = "error",
	VIEWTOHTML = "viewToHtml",
	REQUEST = "request",
	LOGIN = "login",
	LOGOUT = "logout",
	RESPONSE = "response",
	REDIRECT = "redirect",
	SETSESSION = "setSession",
	MAP = "map",
	REDUCE = "reduce",
	VAR = "variable",
	TIMEOUT = "timeout"
}

export type TEndpointFlowNodeTypes = keyof typeof BlueprintEndpointFlowNodeTypes;
export type TBlueprintEndpointNodeTypes = typeof BlueprintEndpointFlowNodeTypes;

export const BodyBlueprintSchema = BP.Any({
	label: termsEditor.nodes.common.body.label,
	description: termsEditor.nodes.common.body.description,
	defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
	fallbackValue: ""
});

export const HeadersBlueprintSchema = BP.Map({
	label: termsEditor.nodes.common.headers.label,
	description: termsEditor.nodes.common.headers.description,
	value: BP.String({
		label: termsEditor.nodes.common.header.label,
		description: termsEditor.nodes.common.header.description,
		default: ""
	})
});
