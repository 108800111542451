/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import { ExportTypes, ISchemaImportExport } from "./ExportTypes";

/**
 * Exports value
 *
 * @param value Value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function exportValue(value: any, name: string): any {

	if (value === null) {

		return value;

	} else if (typeof value === "boolean") {

		return value;

	} else if (typeof value === "number") {

		if (isNaN(value)) {
			return {
				_type: ExportTypes.NAN
			}
		}

		return value;

	} else if (typeof value === "string") {

		return value;

	} else if (typeof value === "object") {

		if (value instanceof Date) {

			return {
				_type: ExportTypes.DATE,
				_value: value.toISOString()
			};

		} else if (Array.isArray(value)) {

			return value.map((element) => exportValue(element, name));

		} else if (typeof value.export === "function") {

			return value.export();

		} else {

			const exportedObject = {};

			for (const propName of Object.keys(value)) {

				if (value[propName] !== undefined) {
					exportedObject[propName] = exportValue(value[propName], name);
				}

			}

			return exportedObject;

		}

	} else {
		throw new SchemaDeclarationError(name, {}, `Can't export: unsupported type`);
	}

}

/**
 * Exports schema
 *
 * @param schemaName Schema name
 * @param args Construction arguments
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function exportSchema(schemaName: string, args = []): ISchemaImportExport {

	const exportedValues = [];

	args.map((arg) => {
		const val = exportValue(arg, schemaName);
		exportedValues.push(val);
	});

	return {
		_type: ExportTypes.SCHEMA,
		_name: schemaName,
		_values: exportedValues
	};

}

/**
 * Exports validator
 *
 * @param validatorName Validator name
 * @param args Construction arguments
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function exportValidator(validatorName: string, args = []): ISchemaImportExport {

	const exportedValues = [];

	args.map((arg) => {
		exportedValues.push(exportValue(arg, validatorName));
	});

	return { _type: ExportTypes.VALIDATOR, _name: validatorName, _values: exportedValues };

}
