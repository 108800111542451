/**
 * Text Field HAE component base props
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, SCHEMA_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";

import {
	dimensionPattern,
	propGroups,
	termsEditor as HAELibComponentsTerms
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { FieldBaseProps, FieldLengthProps } from "./props";

/**
 * Text Field base props
 */
export const TextFieldBaseProps = {

	...FieldBaseProps,

	...FieldLengthProps,

	value: BP.Prop(BP.String({
		...termsEditor.schemas.textField.value,
		default: "",
		fallbackValue: null,
		constraints: {
			required: true
		}
	}), 0, propGroups.common),

	placeholder: BP.Prop(BP.String({
		...termsEditor.schemas.field.placeholder
	}), 20, propGroups.common),

	prefix: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.field.prefix,
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: {
				value: BP.Prop(BP.String({
					...termsEditor.schemas.field.prefixValue,
					default: ""
				}), 10),

				width: BP.Prop(BP.String({
					...termsEditor.schemas.field.prefixWidth,
					default: "100px",
					fallbackValue: "100px",
					constraints: {
						...HAELibComponentsTerms.schemas.common.dimensionConstraints,
						pattern: dimensionPattern,
						required: true
					}
				}), 20),

				/*includeInValue: BP.Prop(BP.Boolean({
					...termsEditor.schemas.field.prefixIncludeInValue,
					default: false,
					fallbackValue: false,
					constraints: {
						required: true
					}
				}), 30)*/
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 30, propGroups.common),

	suffix: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.field.suffix,
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: {
				value: BP.Prop(BP.String({
					...termsEditor.schemas.field.suffixValue,
					default: ""
				}), 10),

				width: BP.Prop(BP.String({
					...termsEditor.schemas.field.suffixWidth,
					default: "100px",
					fallbackValue: "100px",
					constraints: {
						...HAELibComponentsTerms.schemas.common.dimensionConstraints,
						pattern: dimensionPattern,
						required: true
					}
				}), 20),

				/*includeInValue: BP.Prop(BP.Boolean({
					...termsEditor.schemas.field.suffixIncludeInValue,
					default: false,
					fallbackValue: false,
					constraints: {
						required: true
					}
				}), 30)*/
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 40, propGroups.common),

	pattern: BP.Prop(BP.String({
		...termsEditor.schemas.field.pattern
	}), 200, propGroups.validation),

	autocomplete: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.textField.autocomplete,
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: {
				items: BP.Prop(BP.Array({
					...termsEditor.schemas.textField.autocompleteItems,
					items: BP.String({}),
					getElementModelNodeInfo: (modelNode) => {
						return {
							label: modelNode.type === SCHEMA_VALUE_TYPE.CONST &&
								modelNode.constant.value,
							icon: "mdi/text-short"
						};
					}
				}), 0),

				minValueLength: BP.Prop(BP.Integer({
					...termsEditor.schemas.textField.autocompleteMinValueLength,
					default: 2,
					fallbackValue: 2,
					constraints: {
						min: 1,
						required: true
					}
				}), 10)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 210, propGroups.validation),

	htmlAutocomplete: BP.Prop(BP.String({
		...termsEditor.schemas.field.htmlAutocomplete
	}), 0, propGroups.advanced)

};
