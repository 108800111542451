/**
 * hae-ext-components-base
 *
 * Hexio App Engine: Set of base components
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { ITranslationManager } from "@hexio_io/hae-lib-core";
import {
	termsEditor as libTermsEditor,
	termsRuntime as libTermsRuntime,
	TGenericHAEComponentDefinition
} from "@hexio_io/hae-lib-components";

import { termsEditor, termsRuntime } from "./terms";

import { HAEComponentAppHeader } from "./Components/AppHeader/AppHeader";
import { HAEComponentBox } from "./Components/Box/Box";
import { HAEComponentButton } from "./Components/Button/Button";
import { HAEComponentButtonGroup } from "./Components/ButtonGroup/ButtonGroup";
import { HAEComponentCheckboxField } from "./Components/Field/CheckboxField";
import { HAEComponentColorField } from "./Components/Field/ColorField";
import { HAEComponentComposedChart } from "./Components/ComposedChart/ComposedChart";
import { HAEComponentContainer } from "./Components/Container/Container";
import { HAEComponentDataTable } from "./Components/DataTable/DataTable";
import { HAEComponentDatetimeField } from "./Components/Field/DatetimeField";
import { HAEComponentFileField } from "./Components/Field/FileField";
import { HAEComponentGaugeChart } from "./Components/GaugeChart/GaugeChart";
import { HAEComponentGrid } from "./Components/Grid/Grid";
import { HAEComponentIcon } from "./Components/Icon/Icon";
import { HAEComponentIframe } from "./Components/Iframe/Iframe";
import { HAEComponentImage } from "./Components/Image/Image";
import { HAEComponentLabel } from "./Components/Label/Label";
import { HAEComponentMenuButton } from "./Components/MenuButton/MenuButton";
import { HAEComponentNumberField } from "./Components/Field/NumberField";
import { HAEComponentOneOf } from "./Components/OneOf/OneOf";
import { HAEComponentOptionsField } from "./Components/Field/OptionsField";
import { HAEComponentPagination } from "./Components/Pagination/Pagination";
import { HAEComponentPixelGrid } from "./Components/PixelGrid/PixelGrid";
import { HAEComponentPropertyItem } from "./Components/PropertyItem/PropertyItem";
import { HAEComponentQrCode } from "./Components/QrCode/QrCode";
import { HAEComponentRangeField } from "./Components/Field/RangeField";
import { HAEComponentRepeat } from "./Components/Repeat/Repeat";
import { HAEComponentRoutes } from "./Components/Routes/Routes";
import { HAEComponentSidebarLayout } from "./Components/SidebarLayout/SidebarLayout";
import { HAEComponentSpacer } from "./Components/Spacer/Spacer";
import { HAEComponentTable, HAEComponentAdvancedTable } from "./Components/Table/Table";
import { HAEComponentText } from "./Components/Text/Text";
import { HAEComponentTextAreaField } from "./Components/Field/TextAreaField";
import { HAEComponentTextField } from "./Components/Field/TextField";
import { HAEComponentTextField_v2 } from "./Components/Field/TextField_v2";
import { HAEComponentTreeNavigation } from "./Components/TreeNavigation/TreeNavigation";
import { HAEComponentView } from "./Components/View/View";
import { HAEComponentQrCodeReader } from "./Components/QrCodeReader/QrCodeReader";

// Export registration functions
export function registerComponents(
	registry: ItemRegistry<TGenericHAEComponentDefinition>,
	translationManager: ITranslationManager
): void {
	// Layout components
	registry.register(HAEComponentAppHeader);
	registry.register(HAEComponentBox);
	registry.register(HAEComponentButtonGroup);
	registry.register(HAEComponentContainer);
	registry.register(HAEComponentGrid);
	registry.register(HAEComponentSidebarLayout);

	// Logic components
	registry.register(HAEComponentOneOf);
	registry.register(HAEComponentRoutes);
	registry.register(HAEComponentRepeat);

	// Navigation components
	registry.register(HAEComponentPagination);
	registry.register(HAEComponentTreeNavigation);

	// Content components
	registry.register(HAEComponentAdvancedTable);
	registry.register(HAEComponentButton);
	registry.register(HAEComponentDataTable);
	registry.register(HAEComponentIcon);
	registry.register(HAEComponentIframe);
	registry.register(HAEComponentImage);
	registry.register(HAEComponentLabel);
	registry.register(HAEComponentMenuButton);
	registry.register(HAEComponentQrCode);
	registry.register(HAEComponentQrCodeReader);
	registry.register(HAEComponentText);
	registry.register(HAEComponentView);
	registry.register(HAEComponentPropertyItem);
	registry.register(HAEComponentSpacer);

	// Field components
	registry.register(HAEComponentCheckboxField);
	registry.register(HAEComponentColorField);
	registry.register(HAEComponentDatetimeField);
	registry.register(HAEComponentFileField);
	registry.register(HAEComponentTextField_v2);
	registry.register(HAEComponentTextAreaField);
	registry.register(HAEComponentNumberField);
	registry.register(HAEComponentRangeField);
	registry.register(HAEComponentOptionsField);

	// Charts components
	registry.register(HAEComponentComposedChart);
	registry.register(HAEComponentGaugeChart);
	registry.register(HAEComponentPixelGrid);

	// Deprecated (hidden) components
	registry.register(HAEComponentTable);
	registry.register(HAEComponentTextField);

	// Register translation terms
	translationManager.registerTerms("editor", termsEditor, "hae-ext-components-base");
	translationManager.registerTerms("runtime", termsRuntime, "hae-ext-components-base");
	translationManager.registerTerms("editor", libTermsEditor, "hae-ext-components-base#lib");
	translationManager.registerTerms("runtime", libTermsRuntime, "hae-ext-components-base#lib");
}
