/**
 * Text HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	Type,
	defineElementaryComponent,
	createSubScope,
	BP,
	TGetBlueprintSchemaModel,
	ISchemaConstObject,
} from "@hexio_io/hae-lib-blueprint";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	Text,
	TextProps,
	getValuesFromStringEnum,
	termsEditor as HAELibComponentsTerms,
	VERTICAL_ALIGN,
	VERTICAL_ALIGN_default,
	ClassList,
	TextValueSchema,
	getStringEnumValue,
	propGroups,
	VERTICAL_ALIGN_opts,
	isEventEnabled,
	TTextPropsModelNode,
} from "@hexio_io/hae-lib-components";
import { BaseEvents } from "../../base/BaseEvents";
import { termsEditor } from "../../terms";

interface HAEComponentText_State {}

const HAEComponentText_Props = {

	value: BP.Prop(TextValueSchema({
		alias: "textValue_text",
		default: "Text"
	}), TextProps.value.order, propGroups.common),

	align: BP.Prop(TextProps.align.schema, TextProps.align.order, propGroups.common),

	style: BP.Prop(TextProps.style.schema, TextProps.style.order, propGroups.common),

	fontSize: BP.Prop(TextProps.fontSize.schema, TextProps.fontSize.order, propGroups.common),

	foregroundColor: BP.Prop(TextProps.foregroundColor.schema, TextProps.foregroundColor.order, propGroups.common),

	overflow: BP.Prop(TextProps.overflow.schema, TextProps.overflow.order, propGroups.common),

	markdown: BP.Prop(TextProps.markdown.schema, TextProps.markdown.order, propGroups.common),

	html: BP.Prop(TextProps.html.schema, TextProps.html.order, propGroups.common),

	verticalAlign: BP.Prop(BP.Enum.String({
		...HAELibComponentsTerms.schemas.common.verticalAlign,
		options: getValuesFromStringEnum(
			VERTICAL_ALIGN,
			HAELibComponentsTerms.schemas.common.verticalAlignValues,
			VERTICAL_ALIGN_opts
		),
		default: VERTICAL_ALIGN_default,
		editorOptions: {
			controlType: "buttons"
		},
	}), 100, propGroups.common)

};

const HAEComponentText_Events = {
	...BaseEvents
};

const HAEComponentText_Definition = defineElementaryComponent<
	typeof HAEComponentText_Props,
	HAEComponentText_State,
	typeof HAEComponentText_Events
>({

	...termsEditor.components.text.component,

	name: "text",

	category: "content",

	icon: "mdi/text",

	docUrl: "...",

	order: 10,

	props: HAEComponentText_Props,

	events: HAEComponentText_Events,

	initialWidth: 400,

	initialHeight: 36,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}

});

const HAEComponentText_React: THAEComponentReact<typeof HAEComponentText_Definition> = ({
	props, componentInstance, reactComponentClassList
}) => {
	const _clickHandler = React.useMemo(() => {
		if (isEventEnabled(componentInstance.eventEnabled.click, componentInstance.componentMode)) {
			return () => componentInstance.eventTriggers.click((parentScope) => createSubScope(parentScope));
		}

		return;
	}, [ componentInstance.componentMode, componentInstance.eventEnabled.click, componentInstance.eventTriggers.click ]);

	const classList = new ClassList();

	if (reactComponentClassList) {
		classList.add(...reactComponentClassList);
	}

	const verticalAlignValue = getStringEnumValue(VERTICAL_ALIGN, props.verticalAlign, VERTICAL_ALIGN_default);

	classList.add(`text--vertical-align-${verticalAlignValue}`);

	return (
		<Text
			{...props}
			componentPath={componentInstance.safePath}
			componentMode={componentInstance.componentMode}
			classList={classList}
			forceRender={true}
			propsModelNode={componentInstance.modelNode?.props as TTextPropsModelNode}
			onClick={_clickHandler}
		/>
	);
};

export const HAEComponentText: THAEComponentDefinition<typeof HAEComponentText_Definition> = {
	...HAEComponentText_Definition,
	reactComponent: HAEComponentText_React
};
