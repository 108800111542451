/**
 * Grid Role enum
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum GRID_ROLE {
	NONE = "none",
	FORM = "form"
}

export type GRID_ROLE_string = keyof typeof GRID_ROLE;

export const GRID_ROLE_default: GRID_ROLE_string = "NONE";
