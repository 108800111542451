/**
 * Constants
 *
 * @package hae-lib-blueprint
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const SCHEMA_EXPRESSION_PREFIX = "=";
export const SCHEMA_TRANSLATE_KEY = "=#";

export const OBJECT_TYPE_PROP_NAME = "__objectType";

export enum OBJECT_TYPE {
	COMPONENT = "component",
	DATASOURCE_INSTANCE = "dataSourceInstance",
	DATASOURCE = "dataSource",
	VARIABLE = "variable",
	SCOPE = "scope",
	FUNCTION = "function",
	METHOD = "method"
}

export const SCOPE_KEY_INDEX_PROP_NAME = "__idIndex";

export const MEDIA_RESOLUTIONS = {
	screenBase: {
		label: "Base screen",
		order: 10,
		required: true
	},
	screenLarge: {
		label: "Large screen",
		order: 20,
	},
	screenTablet: {
		label: "Tablet screen",
		order: 30
	},
	screenPhone: {
		label: "Phone screen",
		order: 40
	},
	print: {
		label: "Print",
		order: 50
	}
};

export type MEDIA_RESOLUTIONS_string = keyof typeof MEDIA_RESOLUTIONS;

export const MEDIA_RESOLUTIONS_default: MEDIA_RESOLUTIONS_string = "screenBase";

export type TMapMediaResolutions<TTarget> = { [K in keyof typeof MEDIA_RESOLUTIONS]: TTarget };
