/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGenericBlueprintSchema } from "../Schema/IBlueprintSchema";

/**
 * Descriptor Types enum
 */
export enum TYPE_DESC_TYPES {
	ARRAY = "array",
	BOOLEAN = "boolean",
	DATE = "date",
	FLOAT = "float",
	INTEGER = "integer",
	MAP = "map",
	OBJECT = "object",
	STRING = "string",
	ANY = "any",
	METHOD = "method",
	FUNCTION = "function",
	NULL = "null",
	VOID = "void",
	RECURSIVE = "recursive"
}

/**
 * Base Type Descriptor interface
 */
export interface ITypeDescBase<T extends TYPE_DESC_TYPES> {
	type: T;
	label?: string;
	description?: string;
	example?: string;
	tags?: string[];
}

/**
 * Array Type Descriptor interface
 */
export interface ITypeDescArray extends ITypeDescBase<TYPE_DESC_TYPES.ARRAY> {
	type: TYPE_DESC_TYPES.ARRAY;
	items: TTypeDesc[];
}

/**
 * Boolean Type Descriptor interface
 */
export interface ITypeDescBoolean extends ITypeDescBase<TYPE_DESC_TYPES.BOOLEAN> {
	type: TYPE_DESC_TYPES.BOOLEAN;
}

/**
 * Date Type Descriptor interface
 */
export interface ITypeDescDate extends ITypeDescBase<TYPE_DESC_TYPES.DATE> {
	type: TYPE_DESC_TYPES.DATE;
}

/**
 * Float Type Descriptor interface
 */
export interface ITypeDescFloat extends ITypeDescBase<TYPE_DESC_TYPES.FLOAT> {
	type: TYPE_DESC_TYPES.FLOAT;
}

/**
 * Integer Type Descriptor interface
 */
export interface ITypeDescInteger extends ITypeDescBase<TYPE_DESC_TYPES.INTEGER> {
	type: TYPE_DESC_TYPES.INTEGER;
}

/**
 * Map Type Descriptor interface
 */
export interface ITypeDescMap extends ITypeDescBase<TYPE_DESC_TYPES.MAP> {
	type: TYPE_DESC_TYPES.MAP;
	items: { [K: string]: TTypeDesc };
}

/**
 * Object Type Descriptor interface
 */
export interface ITypeDescObject extends ITypeDescBase<TYPE_DESC_TYPES.OBJECT> {
	type: TYPE_DESC_TYPES.OBJECT;
	props: { [K: string]: TTypeDesc };
}

/**
 * String Type Descriptor interface
 */
export interface ITypeDescString extends ITypeDescBase<TYPE_DESC_TYPES.STRING> {
	type: TYPE_DESC_TYPES.STRING;
}

/**
 * Any Type Descriptor interface
 */
export interface ITypeDescAny extends ITypeDescBase<TYPE_DESC_TYPES.ANY> {
	type: TYPE_DESC_TYPES.ANY;
}

/**
 * Method Type Descriptor interface
 */
export interface ITypeDescMethod extends ITypeDescBase<TYPE_DESC_TYPES.METHOD> {
	type: TYPE_DESC_TYPES.METHOD;
	argRequiredCount: number;
	argSchemas: TGenericBlueprintSchema[];
	argRestSchema: TGenericBlueprintSchema;
	returnType: TTypeDesc;
}

/**
 * Function Type Descriptor interface
 */
export interface ITypeDescFunction extends ITypeDescBase<TYPE_DESC_TYPES.FUNCTION> {
	type: TYPE_DESC_TYPES.FUNCTION;
	category: string;
	argRequiredCount: number;
	argSchemas: TGenericBlueprintSchema[];
	argRestSchema: TGenericBlueprintSchema;
	returnType: TTypeDesc;
}

/**
 * Recursive Type Descriptor interface
 */
export interface ITypeDescRecursive extends ITypeDescBase<TYPE_DESC_TYPES.RECURSIVE> {
	type: TYPE_DESC_TYPES.RECURSIVE;
	props?: ITypeDescObject;
	items?: ITypeDescRecursive[];
}

/**
 * Null Type Descriptor interface
 */
export interface ITypeDescNull extends ITypeDescBase<TYPE_DESC_TYPES.NULL> {
	type: TYPE_DESC_TYPES.NULL;
}

/**
 * Void Type Descriptor interface
 */
export interface ITypeDescVoid extends ITypeDescBase<TYPE_DESC_TYPES.VOID> {
	type: TYPE_DESC_TYPES.VOID;
}

/**
 * Union Descriptor Types
 */
export type TTypeDesc = ITypeDescArray |
	ITypeDescBoolean |
	ITypeDescDate |
	ITypeDescFloat |
	ITypeDescInteger |
	ITypeDescMap |
	ITypeDescObject |
	ITypeDescString |
	ITypeDescAny |
	ITypeDescMethod |
	ITypeDescFunction |
	ITypeDescRecursive |
	ITypeDescNull |
	ITypeDescVoid;

/**
 * Returns Array Type Descriptor
 * @param opts
 */
export function TypeDescArray(opts: Omit<ITypeDescArray, "type">): ITypeDescArray {
	return { ...opts, type: TYPE_DESC_TYPES.ARRAY };
}

/**
 * Returns Boolean Type Descriptor
 * @param opts
 */
export function TypeDescBoolean(opts: Omit<ITypeDescBoolean, "type">): ITypeDescBoolean {
	return { ...opts, type: TYPE_DESC_TYPES.BOOLEAN };
}

/**
 * Returns Date Type Descriptor
 * @param opts
 */
export function TypeDescDate(opts: Omit<ITypeDescDate, "type">): ITypeDescDate {
	return { ...opts, type: TYPE_DESC_TYPES.DATE };
}

/**
 * Returns Float Type Descriptor
 * @param opts
 */
export function TypeDescFloat(opts: Omit<ITypeDescFloat, "type">): ITypeDescFloat {
	return { ...opts, type: TYPE_DESC_TYPES.FLOAT };
}

/**
 * Returns Integer Type Descriptor
 * @param opts
 */
export function TypeDescInteger(opts: Omit<ITypeDescInteger, "type">): ITypeDescInteger {
	return { ...opts, type: TYPE_DESC_TYPES.INTEGER };
}

/**
 * Returns Map Type Descriptor
 * @param opts
 */
export function TypeDescMap(opts: Omit<ITypeDescMap, "type">): ITypeDescMap {
	return { ...opts, type: TYPE_DESC_TYPES.MAP };
}

/**
 * Returns Object Type Descriptor
 * @param opts
 */
export function TypeDescObject(opts: Omit<ITypeDescObject, "type">): ITypeDescObject {
	return { ...opts, type: TYPE_DESC_TYPES.OBJECT };
}

/**
 * Returns String Type Descriptor
 * @param opts
 */
export function TypeDescString(opts: Omit<ITypeDescString, "type">): ITypeDescString {
	return { ...opts, type: TYPE_DESC_TYPES.STRING };
}

/**
 * Returns Any Type Descriptor
 * @param opts
 */
export function TypeDescAny(opts: Omit<ITypeDescAny, "type">): ITypeDescAny {
	return { ...opts, type: TYPE_DESC_TYPES.ANY };
}

/**
 * Returns Method Type Descriptor
 * @param opts
 */
export function TypeDescMethod(opts: Omit<ITypeDescMethod, "type">): ITypeDescMethod {
	return { ...opts, type: TYPE_DESC_TYPES.METHOD };
}

/**
 * Returns Function Type Descriptor
 * @param opts
 */
export function TypeDescFunction(opts: Omit<ITypeDescFunction, "type">): ITypeDescFunction {
	return { ...opts, type: TYPE_DESC_TYPES.FUNCTION };
}

/**
 * Returns Recursive Type Descriptor
 * @param opts
 */
export function TypeDescRecursive(opts: Omit<ITypeDescRecursive, "type">): ITypeDescRecursive {
	return { ...opts, type: TYPE_DESC_TYPES.RECURSIVE };
}

/**
 * Returns Null Type Descriptor
 * @param opts
 */
export function TypeDescNull(opts: Omit<ITypeDescNull, "type">): ITypeDescNull {
	return { ...opts, type: TYPE_DESC_TYPES.NULL };
}

/**
 * Returns Void Type Descriptor
 * @param opts
 */
export function TypeDescVoid(opts: Omit<ITypeDescVoid, "type">): ITypeDescVoid {
	return { ...opts, type: TYPE_DESC_TYPES.VOID };
}

/**
 * Type descriptors
 */
export const Type = {
	Any: TypeDescAny,
	Array: TypeDescArray,
	Boolean: TypeDescBoolean,
	Date: TypeDescDate,
	Float: TypeDescFloat,
	Integer: TypeDescInteger,
	Map: TypeDescMap,
	Object: TypeDescObject,
	String: TypeDescString,
	Method: TypeDescMethod,
	Function: TypeDescFunction,
	Null: TypeDescNull,
	Void: TypeDescVoid,
	Recursive: TypeDescRecursive,
}
