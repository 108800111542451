/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP, ISchemaFlowNodeTypeDefinition,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	SCHEMA_CONST_ANY_VALUE_TYPE, TGenericBlueprintSchema,
	TGetSchemaFlowNodeOptsSpec,
} from "@hexio_io/hae-lib-blueprint";
import {
	BackgroundColorSchema,
	ForegroundColorSchema,
	IconSchema,
	IconSizeSchema,
	LinkLocationSchema,
	TextFontSizeSchema,
	TextSchema,
	TextValueSchema,
	TextProps,
	IconProps,
	OVERLAY_CLOSABLE_default,
	getValuesFromStringEnum,
	OVERLAY_CLOSABLE,
	OVERLAY_SIZE,
	OVERLAY_SIZE_default
} from "@hexio_io/hae-lib-components";

// @todo Translate all terms in this file!!!

const OverlayBaseProps = {
	id: BP.Prop(BP.String({
		label: "Overlay ID",
		description: "Unique overlay ID. Can be used in a Close Overlay event. If not specified the random ID will be assigned.",
		constraints: {
			required: false,
			min: 1
		},
		default: null,
		fallbackValue: null
	})),
	size: BP.Prop(BP.StringWithConst({
		label: "Size",
		constants: getValuesFromStringEnum(OVERLAY_SIZE, {
			small: { label: "Small" },
			medium: { label: "Medium" },
			large: { label: "Large" },
		}),
		default: OVERLAY_SIZE_default,
		fallbackValue: OVERLAY_SIZE_default,
		constraints: {
			required: false
		},
	})),
	headerText: BP.Prop(TextSchema({
		alias: "text_overlayHeader",
		label: "Overlay header title",
		props: {
			value: BP.Prop(TextValueSchema({
				alias: "textValue_overlayHeader",
				default: "Overlay Title"
			}), TextProps.value.order, TextProps.value.group),

			fontSize: BP.Prop(TextFontSizeSchema({
				alias: "textFontSize_overlayHeader",
				default: "MEDIUM"
			}), TextProps.fontSize.order, TextProps.fontSize.group),

			overflow: BP.Prop(BP.Void({}))
		}
	})),
	headerIcon: BP.Prop(IconSchema({
		label: "Overlay header icon",
		props: {
			size: BP.Prop(IconSizeSchema({
				default: "MEDIUM"
			}), IconProps.size.order, IconProps.size.group)
		}
	}))
}

const OVERLAY_BUTTON_DEFAULT_WIDTH = "140px";

const OverlayButtonBaseProps = {
	labelText: BP.Prop(TextSchema({
		props: {
			value: BP.Prop(TextValueSchema({
				alias: "textValue_overlayButtonLabel",
				default: "Button"
			}), TextProps.value.order, TextProps.value.group),

			foregroundColor: BP.Prop(ForegroundColorSchema({
				alias: "foregroundColor_overlayButtonLabelText",
				default: "INHERIT"
			}), TextProps.foregroundColor.order, TextProps.foregroundColor.group),

			overflow: BP.Prop(BP.Void({}))
		}
	}), 10),

	labelIcon: BP.Prop(IconSchema({
		props: {
			foregroundColor: BP.Prop(ForegroundColorSchema({
				alias: "foregroundColor_overlayButtonLabelIcon",
				default: "INHERIT"
			}), IconProps.foregroundColor.order, IconProps.foregroundColor.group),
		}
	}), 20),

	backgroundColor: BP.Prop(BackgroundColorSchema({
		alias: "backgroundColor_overlayButton",
		default: "PRIMARY"
	}), 30),

	foregroundColor: BP.Prop(ForegroundColorSchema({
		alias: "foregroundColor_overlayButton",
		default: "WHITE"
	}), 40),

	width: BP.Prop(BP.String({
		label: "Width",
		default: OVERLAY_BUTTON_DEFAULT_WIDTH
	}), 50)
}

/**
 * Component event node types
 */
// @todo Add translations
export const CoreComponentEventNodeTypes: Record<string, ISchemaFlowNodeTypeDefinition<TGenericBlueprintSchema>> = {
	"action": {
		name: "action",
		label: "Call Action",
		icon: "mdi/motion-play",
		editorOptions: {
			displayInQuickMenu: true,
			displayInPalette: true
		},
		opts: BP.ActionRef({}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onSuccess: {
				label: "On Success",
				icon: "mdi/check"
			},
			onError: {
				label: "On Error",
				icon: "mdi/alert"
			},
			onRequestFail: {
				label: "On Request Fail",
				icon: "mdi/alert-circle",
				description: "When request fails due to network connection issues, invalid parameters or unauthorized access."
			}
		})
	},
	"auth": {
		name: "auth",
		label: "Authorize",
		icon: "mdi/shield-lock",
		editorOptions: {
			displayInQuickMenu: false,
			displayInPalette: false
		},
		opts: BP.Object({
			label: "Options",
			props: {
				condition: BP.Prop(BP.Boolean({
					label: "Check condition",
					constraints: {
						required: true
					},
					default: false,
					fallbackValue: false
				}))
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onAuthorize: { label: "On Authorize", icon: "mdi/check" },
			onError: { label: "On Error", icon: "mdi/cancel" }
		})
	},
	"cmpMethod": {
		name: "cmpMethod",
		label: "Call Method",
		description: "Call method above the item in scope.",
		icon: "mdi/function",
		editorOptions: {
			displayInQuickMenu: true,
			displayInPalette: true
		},
		opts: BP.Object({
			props: {
				method: BP.Prop(BP.Expression({
					label: "Method",
					description: "Component or data source method to call."
				})),
				args: BP.Prop(BP.Array({
					label: "Arguments",
					items: BP.Any({
						defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
					}),
					constraints: {
						required: false
					}
				}))
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onSuccess: { label: "On Success", icon: "mdi/check" },
			onError: { label: "On Error", icon: "mdi/alert" },
		})
	},
	"condition": {
		name: "condition",
		label: "Condition",
		icon: "mdi/help-rhombus",
		editorOptions: {
			displayInQuickMenu: false,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {
				condition: BP.Prop(BP.Boolean({
					label: "Condition",
					constraints: {
						required: true
					},
					default: false,
					fallbackValue: false
				}))
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onTrue: { label: "On True", icon: "mdi/check" },
			onFalse: { label: "On False", icon: "mdi/close" }
		})
	},
	"delay": {
		name: "delay",
		label: "Delay",
		icon: "mdi/clock-outline",
		editorOptions: {
			displayInQuickMenu: false,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {
				timeout: BP.Prop(BP.Integer({
					label: "Timeout",
					constraints: {
						required: true,
						min: 0
					},
					default: 1000,
					fallbackValue: 0
				}))
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onTimeout: { label: "On Timeout", icon: "mdi/clock" },
		})
	},
	"eventStart": {
		name: "eventStart",
		label: "Event Start",
		icon: "play",
		editorOptions: {
			displayInQuickMenu: false,
			displayInPalette: false
		},
		opts: BP.Void({}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onEvent: { label: "On Event", icon: "mdi/play" }
		})
	},
	"navigate": {
		name: "navigate",
		label: "Navigate",
		icon: "mdi/link-variant",
		editorOptions: {
			displayInQuickMenu: true,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {
				link: BP.Prop(LinkLocationSchema({
					defaultType: "URL",
					constraints: {
						required: true
					}
				})),
				openInNew: BP.Prop(BP.Boolean({
					label: "Open in new window",
					default: false,
					fallbackValue: true
				}))
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({})
	},
	"navigateBack": {
		name: "navigateBack",
		label: "Navigate Back",
		icon: "mdi/undo",
		editorOptions: {
			displayInQuickMenu: true,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({})
	},
	"reloadSession": {
		name: "reloadSession",
		label: "Reload Session",
		icon: "mdi/account-convert",
		editorOptions: {
			displayInQuickMenu: false,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onReload: { label: "On Reload", icon: "mdi/check" }
		})
	},
	"openDialog": {
		name: "openDialog",
		label: "Open Info Dialog",
		icon: "mdi/message-text",
		editorOptions: {
			displayInQuickMenu: true,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {
				...OverlayBaseProps,
				text: BP.Prop(TextSchema({
					label: "Text",
					description: "Dialog contents.",
					default: {
						value: "Your dialog text."
					},
					props: {
						overflow: BP.Prop(BP.Void({}))
					}
				})),
				closable: BP.Prop(BP.Enum.String({
					label: "Can be closed",
					// eslint-disable-next-line max-len
					description: "If the dialog can be closed by clicking on the close button or by cliking outside the dialog area. If false you must close the dialog yourself by its ID using the Dialog Close event node.",
					options: getValuesFromStringEnum(OVERLAY_CLOSABLE),
					default: OVERLAY_CLOSABLE_default,
					fallbackValue: OVERLAY_CLOSABLE_default
				}), 20),
				buttons: BP.Prop(BP.Array({
					label: "Buttons",
					description: "Buttons to display below text.",
					items: BP.Object({
						label: "Button",
						props: {
							...OverlayButtonBaseProps,
							id: BP.Prop(BP.String({
								label: "Button ID",
								description: "When the dialog is closed by this button the button ID is available to the next event node.",
								constraints: {
									required: false,
									min: 1
								},
								default: null,
								fallbackValue: null
							}), 0),
							enabled: BP.Prop(BP.Boolean({
								label: "Enabled",
								description: "If the button is enabled.",
								constraints: {
									required: false
								},
								default: true,
								fallbackValue: true
							}), 100)
						},
						editorOptions: {
							defaultExpanded: true
						}
					}),
					constraints: {
						required: false
					},
					default: [],
					fallbackValue: []
				}))
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onClose: {
				label: "On Close",
				description: "When dialog is closed. The button ID is available.",
				icon: "mdi/close"
			}
		})
	},
	"openConfirmationDialog": {
		name: "openConfirmationDialog",
		label: "Open Confirmation Dialog",
		icon: "mdi/message-question",
		editorOptions: {
			displayInQuickMenu: true,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {
				...OverlayBaseProps,
				text: BP.Prop(TextSchema({
					label: "Text",
					description: "Dialog contents.",
					default: {
						value: "Your dialog text."
					},
					props: {
						overflow: BP.Prop(BP.Void({}))
					}
				})),
				confirmValue: BP.Prop(BP.String({
					label: "Confirm value",
					// eslint-disable-next-line max-len
					description: "If specified the user will be asked to enter a value. Then confirmation button will be enabled only if entered text matches the Confirm value. Usefull for more error-prone delete confirmations.",
					constraints: {
						required: false
					},
					default: "",
					fallbackValue: ""
				})),
				confirmButton: BP.Prop(BP.Object({
					label: "Confirm Button",
					props: OverlayButtonBaseProps,
					default: {
						labelText: {
							value: "Confirm"
						},
						labelIcon: undefined,
						backgroundColor: "PRIMARY",
						foregroundColor: undefined,
						width: OVERLAY_BUTTON_DEFAULT_WIDTH
					},
					editorOptions: {
						defaultExpanded: true
					}
				})),
				cancelButton: BP.Prop(BP.Object({
					label: "Cancel Button",
					props: OverlayButtonBaseProps,
					default: {
						labelText: {
							value: "Cancel"
						},
						labelIcon: undefined,
						backgroundColor: "SECONDARY",
						foregroundColor: undefined,
						width: OVERLAY_BUTTON_DEFAULT_WIDTH
					},
					editorOptions: {
						defaultExpanded: true
					}
				}))
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onConfirm: {
				label: "On Confirm",
				description: "When dialog is closed by the confirm button",
				icon: "mdi/check"
			},
			onCancel: {
				label: "On Cancel",
				description: "When dialog is closed by the cancel button",
				icon: "mdi/close"
			}
		})
	},
	"openViewDialog": {
		name: "openViewDialog",
		label: "Open View in a Dialog",
		icon: "mdi/message-bookmark",
		editorOptions: {
			displayInQuickMenu: true,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {
				...OverlayBaseProps,
				view: BP.Prop(BP.ViewRef({
					label: "View",
					description: "View to display inside the dialog.",
				}), 10),
				closable: BP.Prop(BP.Enum.String({
					label: "Can be closed",
					// eslint-disable-next-line max-len
					description: "If the dialog can be closed by clicking on the close button or by cliking outside the dialog area. If false you must close the dialog yourself by its ID using the Dialog Close event node.",
					options: getValuesFromStringEnum(OVERLAY_CLOSABLE),
					default: OVERLAY_CLOSABLE_default,
					fallbackValue: OVERLAY_CLOSABLE_default
				}), 20)
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onClose: {
				label: "On Close",
				description: "When dialog is closed.",
				icon: "mdi/close"
			}
		})
	},
	"openViewSidebar": {
		name: "openViewSidebar",
		label: "Open View in a Sidebar",
		icon: "mdi/page-layout-sidebar-right",
		editorOptions: {
			displayInQuickMenu: true,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {
				...OverlayBaseProps,
				view: BP.Prop(BP.ViewRef({
					label: "View",
					description: "View to display inside the sidebar.",
				}), 10),
				closable: BP.Prop(BP.Enum.String({
					label: "Can be closed",
					// eslint-disable-next-line max-len
					description: "If the dialog can be closed by clicking on the close button or by cliking outside the dialog area. If false you must close the dialog yourself by its ID using the Dialog Close event node.",
					options: getValuesFromStringEnum(OVERLAY_CLOSABLE),
					default: OVERLAY_CLOSABLE_default,
					fallbackValue: OVERLAY_CLOSABLE_default
				}), 20)
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
			onClose: {
				label: "On Close",
				description: "When sidebar is closed.",
				icon: "mdi/close"
			}
		})
	},
	"closeOverlay": {
		name: "closeOverlay",
		label: "Close Overlay",
		icon: "mdi/message-off",
		editorOptions: {
			displayInQuickMenu: true,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {
				id: BP.Prop(BP.String({
					label: "Overlay ID",
					description: "Dialog or sidebar ID to close.",
					fallbackValue: "",
					constraints: {
						required: true,
						min: 1
					}
				}), 0),
				buttonId: BP.Prop(BP.String({
					label: "Button ID",
					description: "Custom button ID passed as a result of open dialog/sidebar event.",
					default: null,
					fallbackValue: null,
					constraints: {
						required: false,
					}
				}), 10),
				customData: BP.Prop(BP.Any({
					label: "Custom data",
					description: "Custom data.",
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
					constraints: {
						required: false
					}
				}), 20),
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({})
	},
	"showMessage": {
		name: "showMessage",
		label: "Show Toast Message",
		icon: "mdi/card-text",
		editorOptions: {
			displayInQuickMenu: true,
			displayInPalette: true
		},
		opts: BP.Object({
			label: "Options",
			props: {
				id: BP.Prop(BP.String({
					label: "ID",
					constraints: {
						required: false
					},
					default: null,
					fallbackValue: null
				})),
				type: BP.Prop(BP.Enum.String({
					label: "Type",
					options: [{
						label: "Info",
						value: "INFO"
					}, {
						label: "Success",
						value: "SUCCESS"
					}, {
						label: "Warning",
						value: "WARNING"
					}, {
						label: "Error",
						value: "ERROR"
					}],
					constraints: {
						required: true
					},
					default: "INFO",
					fallbackValue: "INFO"
				})),
				message: BP.Prop(BP.String({
					label: "Message",
					constraints: {
						required: true
					},
					default: "",
					fallbackValue: ""
				})),
				details: BP.Prop(BP.String({
					label: "Details",
					constraints: {
						required: false
					},
					default: null,
					fallbackValue: null
				})),
				duration: BP.Prop(BP.Float({
					label: "Duration (sec)",
					// eslint-disable-next-line max-len
					description: "Duration in seconds after which the message will automatically disappear. If set to zero the message will not be hidden automatically.",
					constraints: {
						required: false,
						min: 0
					},
					default: 0,
					fallbackValue: 0
				}))
			}
		}),
		resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({})
	},
};

/**
 * Type of component event node types
 */
export type TCoreComponentEventNodeTypes = typeof CoreComponentEventNodeTypes;

/**
 * Type helper to get options of a component event node type
 */
export type TCoreComponentEventNodeOpts<TKey extends keyof TCoreComponentEventNodeTypes>
	= TGetSchemaFlowNodeOptsSpec<TCoreComponentEventNodeTypes, TKey>;
