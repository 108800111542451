/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Responsive Values
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { MEDIA_RESOLUTIONS_string, TGenericBlueprintSchema, TSchemaResponsiveValueSpec } from "@hexio_io/hae-lib-blueprint";
import { isValidObject } from "@hexio_io/hae-lib-shared";

import { mediaResolutions, IMediaResolution } from "../mediaResolutions";

/**
 * Checks if responsive value is set
 *
 * @param property Responsive property to check
 * @param multiple At least 2 values set
 */
export function isResponsiveValueSet(property: TSchemaResponsiveValueSpec<TGenericBlueprintSchema>, multiple = false): boolean {
	const valueLength = Object.values(property).filter((item) => !!item).length;

	return multiple ? valueLength > 1 : valueLength > 0;
}

/**
 * Filters out set responsive values
 * 
 * @param property Responsive property
 * @param includeBase Include base, defaults to true
 */
export function filterResponsiveValue(
	property: TSchemaResponsiveValueSpec<TGenericBlueprintSchema>,
	includeBase = true
): [ MEDIA_RESOLUTIONS_string, IMediaResolution ][] {
	const validObject = isValidObject(property);

	return [ ...mediaResolutions.entries() ].filter(([ key, value ]) => validObject && !!property[key] && (includeBase || !!value.query));
}

/**
 * Resolved Responsive Value
 */
export interface IResolvedResponsiveValueProperty {
	name: MEDIA_RESOLUTIONS_string;
	value: any;
	mediaQuery: string;
}

/**
 * Resolved Responsive Value Array
 */
export type TResolvedResponsiveValue = IResolvedResponsiveValueProperty[];

/**
 * Map responsive value
 * 
 * @param property Responsive property
 * @param callback Callback function
 * @param includeBase Include base, defaults to true
 */
export function mapResponsiveValue(
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	property: any,
	callback?: (resolvedResponsiveValue: IResolvedResponsiveValueProperty, index: number) => any,
	includeBase?: boolean,
): TResolvedResponsiveValue /*| any[]*/ {
	return filterResponsiveValue(property, includeBase).map(([ key, value ], index) => {
		const resolvedResponsiveValue: IResolvedResponsiveValueProperty = {
			name: key,
			value: property[key],
			mediaQuery: value.query
		};

		return typeof callback === "function" ? callback(resolvedResponsiveValue, index) : resolvedResponsiveValue;
	});
}
