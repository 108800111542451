/**
 * Root Component List Schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { RuntimeContext } from "@hexio_io/hae-lib-blueprint";
import { IViewParamsSpec } from "@hexio_io/hae-lib-shared";
import { ContainerContentSchema } from "../SharedSchemas/Container";

export const RootComponentListSchema = ContainerContentSchema();
export type TRootComponentListSchema = typeof RootComponentListSchema;

/**
 * Type for functions that creates child view
 */
export type TChildViewFactoryFn = (viewName: string, viewParams: IViewParamsSpec) => RuntimeContext<TRootComponentListSchema>;