/**
 * Checkbox Style HAE enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum CHECKBOX_STYLE {
	DEFAULT = "default",
	SWITCH = "switch"
}

export type CHECKBOX_STYLE_string = keyof typeof CHECKBOX_STYLE;
