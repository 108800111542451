/**
 * Loading Info React component
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";
import { ClassList } from "../Classes/ClassList";
import { ICON_SIZE_CLASS, ICON_SIZE_CLASS_default, ICON_SIZE_CLASS_string } from "../Enums/ICON_SIZE_CLASS";
import { getStringEnumValue } from "../Functions/enumHelpers";

/**
 * Loading Info props
 */
export interface ILoadingInfoProps {
	/** Loading icon size */
	iconSizeClass?: ICON_SIZE_CLASS_string;

	/** Custom ClassList to add to component */
	classList?: ClassList;

	/** Children */
	children?: unknown;
}

/**
 * Loading Info component
 */
export const LoadingInfo: React.FunctionComponent<ILoadingInfoProps> = (props) => {
	const { iconSizeClass, children = null } = props;

	const classList = new ClassList("loading-info");
	const iconClassList = new ClassList("loading-info__icon");

	if (props.classList) {
		classList.add(...props.classList);
	}

	const iconSizeClassValue = getStringEnumValue(ICON_SIZE_CLASS, iconSizeClass, ICON_SIZE_CLASS_default);

	iconClassList.add(`loading-info__${iconSizeClassValue}`);

	return (
		<div className={classList.toClassName()}>
			<span className={iconClassList.toClassName()}>
				<span className="loading-info__icon-inner">
					<span className="loading-info__icon-inner-2" />
				</span>
			</span>
			{children}
		</div>
	);
}
