/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE, IBlueprintIDTScalar, TBlueprintIDTNodePath } from "../../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts, TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec,
} from "../../Schema/IBlueprintSchema";
import { IModelNode, IModelNodeCompileResult, MODEL_CHANGE_TYPE } from "../../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange
} from "../../Schema/SchemaHelpers";
import { DesignContext } from "../../Context/DesignContext";
import { SCHEMA_EXPRESSION_PREFIX, SCHEMA_TRANSLATE_KEY } from "../../constants";
import { applyCodeArg, inlineValue } from "../../Context/CompileUtil";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { ISchemaStringTranslate, SchemaStringTranslate, TSchemaStringTranslateDefault } from "../SchemaStringTranslate";
import { ModelNodeManipulationError } from "../../Schema/ModelNodeManipulationError";
import { TypeDescAny } from "../../Shared/ITypeDescriptor";
import { ISchemaExpression, SchemaExpression } from "../SchemaExpression";

/**
 * Enum of value types
 */
export enum SCHEMA_VALUE_TYPE {
	CONST = "const",
	EXPRESSION = "expression",
	TRANSLATE = "translate"
}

/**
 * Schema spec
 */
export type TSchemaValueSpec<TSchema extends TGenericBlueprintSchema>
	= null | TGetBlueprintSchemaSpec<TSchema>;

/**
 * Schema model
 */
export interface ISchemaValueModel<
	TSchema extends TGenericBlueprintSchema
> extends IModelNode<ISchemaValue<TSchema>> {
	type: SCHEMA_VALUE_TYPE;
	constant: TGetBlueprintSchemaModel<TSchema>;
	expression: TGetBlueprintSchemaModel<ISchemaExpression>;
	translate: TGetBlueprintSchemaModel<ISchemaStringTranslate>;
}

/**
 * Schema options
 */
export interface ISchemaValueOpts<
	TSchema extends TGenericBlueprintSchema
> extends IBlueprintSchemaOpts {
	/** Fallback value to return when validation fails */
	fallbackValue?: TSchemaValueSpec<TSchema>;
	/** If value can be an expression, default: true */
	allowExpression?: boolean;
	/** If value can be a translated string, default: false */
	allowTranslate?: boolean;
	/** If to allow casting of dynamic value to a required spec type, default: true */
	allowTypeCast?: boolean;
}

/**
 * Default value
 */
export type ISchemaValueDefault<
	TSchema extends TGenericBlueprintSchema
> = TGetBlueprintSchemaDefault<TSchema> | string | {
	"=#": TGetBlueprintSchemaDefault<ISchemaStringTranslate>
} | {
	[K: string]: Array<unknown>
};

/**
 * Schema type
 */
export interface ISchemaValue<
	TSchema extends TGenericBlueprintSchema
> extends IBlueprintSchema<
	ISchemaValueOpts<TSchema>,
	ISchemaValueModel<TSchema>,
	TSchemaValueSpec<TSchema>,
	ISchemaValueDefault<TSchema>
> {
	/** Configured constant schema */
	constSchema?: TSchema;

	/** Expression schema */
	expressionSchema?: ISchemaExpression;

	/** Translate schema */
	translateSchema?: ISchemaStringTranslate;

	/**
	 * Sets value type
	 */
	setType: (modelNode: ISchemaValueModel<TSchema>, type: SCHEMA_VALUE_TYPE, notify?: boolean) => void;
}

/**
 * Schema: Generic value
 *
 * @param constSchema Schema to use for constant value
 * @param opts Schema options
 */
export function SchemaValue<
	TSchema extends TGenericBlueprintSchema
>(
	constSchema: TSchema,
	opts: ISchemaValueOpts<TSchema>,
): ISchemaValue<TSchema> {

	// type TModel = ISchemaValueModel<TSchema>;
	type TSpec = TSchemaValueSpec<TSchema>;
	type TConstModel = TGetBlueprintSchemaModel<TSchema>;
	type TExpressionModel = TGetBlueprintSchemaModel<ISchemaExpression>;
	type TTranslateModel = TGetBlueprintSchemaModel<ISchemaStringTranslate>;

	const allowTypeCast = opts.allowTypeCast === false ? false : true;

	const expressionSchema = SchemaExpression({});

	const translateSchema = SchemaStringTranslate({
		placeholder: opts.placeholder
	});

	const determineTypeFromString = (value: string) => {

		if (value.substr(0, SCHEMA_EXPRESSION_PREFIX.length) === SCHEMA_EXPRESSION_PREFIX) {
			return {
				type: SCHEMA_VALUE_TYPE.EXPRESSION,
				value: value.substr(SCHEMA_EXPRESSION_PREFIX.length)
			};
		}

		// Handle escape
		if (value.substr(0, 1) === "\\") {
			return {
				type: SCHEMA_VALUE_TYPE.CONST,
				value: value.substr(1)
			};
		}

		return {
			type: SCHEMA_VALUE_TYPE.CONST,
			value: value
		};

	}

	const schema = createEmptySchema<ISchemaValue<TSchema>>("value", opts);

	schema.constSchema = constSchema;
	schema.expressionSchema = expressionSchema;
	schema.translateSchema = translateSchema;

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, ["constant", "expression", "translate"])
	}


	const createModel = (
		dCtx: DesignContext,
		type: SCHEMA_VALUE_TYPE,
		constant: TConstModel,
		expression: TExpressionModel,
		translate: TTranslateModel,
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx, parent, [], {
			type: type,
			constant: constant,
			expression: expression,
			translate: translate
		});

		const model = assignParentToChildrenOf(modelNode)

		if (constant) {
			model.initRequiredValid = constant.initRequiredValid;
		}

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		let type: SCHEMA_VALUE_TYPE;
		let constant: TConstModel = null;
		let expression: TExpressionModel = null;
		let translate: TTranslateModel = null;

		// Translate?
		if (defaultValue instanceof Object && Object.keys(defaultValue).length === 1) {

			const key = Object.keys(defaultValue)[0];

			// Translate
			if (key === SCHEMA_TRANSLATE_KEY && opts.allowTranslate === true) {
				type = SCHEMA_VALUE_TYPE.TRANSLATE;
				translate = translateSchema.createDefault(dCtx, null, defaultValue[key] as TSchemaStringTranslateDefault);
			} else {
				type = SCHEMA_VALUE_TYPE.CONST;
				constant = constSchema.createDefault(dCtx, null, defaultValue as unknown) as TConstModel;
			}

		// Check for expression
		} else if (typeof defaultValue === "string") {

			const typeCheck = determineTypeFromString(defaultValue);
			type = typeCheck.type;

			if (typeCheck.type === SCHEMA_VALUE_TYPE.EXPRESSION) {
				expression = expressionSchema.createDefault(dCtx, null, typeCheck.value);
			} else {
				constant = constSchema.createDefault(dCtx, null, typeCheck.value as unknown) as TConstModel;
			}

		// Otherwise const
		} else {
			type = SCHEMA_VALUE_TYPE.CONST;
			constant = constSchema.createDefault(dCtx, null, defaultValue as unknown) as TConstModel;
		}

		return createModel(dCtx, type, constant, expression, translate, parent);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			type: modelNode.type,
			constant: modelNode.constant ? constSchema.clone(dCtx, modelNode.constant, null) as TConstModel : null,
			expression: modelNode.expression ? expressionSchema.clone(dCtx, modelNode.expression, null) : null,
			translate: modelNode.translate ? translateSchema.clone(dCtx, modelNode.translate, null) : null
		});

		return assignParentToChildrenOf(clone)
	}

	schema.destroy = (modelNode) => {

		if (modelNode.constant) {
			constSchema.destroy(modelNode.constant);
		}

		if (modelNode.expression) {
			expressionSchema.destroy(modelNode.expression);
		}

		if (modelNode.translate) {
			translateSchema.destroy(modelNode.translate);
		}

		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent) => {

		let type: SCHEMA_VALUE_TYPE;
		let constant: TConstModel = null;
		let expression: TExpressionModel = null;
		let translate: TTranslateModel = null;

		// Is null? Create default
		if (idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL) {

			type = SCHEMA_VALUE_TYPE.CONST;
			constant = constSchema.parse(dCtx, idtNode, null) as TConstModel;

		// Could be a translate?
		} else if (idtNode && idtNode.type === BP_IDT_TYPE.MAP && idtNode.items.length === 1) {

			const itemNode = idtNode.items[0];
			const itemKey = itemNode.key.value as string;

			// Translate
			if (itemKey === SCHEMA_TRANSLATE_KEY && opts.allowTranslate === true) {
				type = SCHEMA_VALUE_TYPE.TRANSLATE;
				translate = translateSchema.parse(dCtx, idtNode, null);
			} else {
				type = SCHEMA_VALUE_TYPE.CONST;
				constant = constSchema.parse(dCtx, idtNode, null) as TConstModel;
			}

		// Could be an expression?
		} else if (idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.STRING) {

			const typeCheck = determineTypeFromString(String(idtNode.value));
			type = typeCheck.type;

			// We need to modify value in order to handle escaping
			// and then we MUST revert it back or we corrupt the blueprint
			const oldValue = idtNode.value;
			idtNode.value = typeCheck.value;

			if (typeCheck.type === SCHEMA_VALUE_TYPE.EXPRESSION) {
				expression = expressionSchema.parse(dCtx, idtNode, null);
			} else {
				constant = constSchema.parse(dCtx, idtNode, null) as TConstModel;
			}

			idtNode.value = oldValue;

		// Otherwise const
		} else {

			type = SCHEMA_VALUE_TYPE.CONST;
			constant = constSchema.parse(dCtx, idtNode, null) as TConstModel;

		}

		return createModel(dCtx, type, constant, expression, translate, parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		if (constSchema.provideCompletion) {
			constSchema.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}

	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {

		switch (modelNode.type) {
			case SCHEMA_VALUE_TYPE.CONST: {
				return constSchema.serialize(modelNode.constant, path);
			}
			case SCHEMA_VALUE_TYPE.EXPRESSION: {
				if (modelNode.expression.value !== null) {
					return {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path,
						value: SCHEMA_EXPRESSION_PREFIX + modelNode.expression.value
					} as IBlueprintIDTScalar;
				} else {
					return {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.NULL,
						path: path,
						value: null
					} as IBlueprintIDTScalar;
				}
			}
			case SCHEMA_VALUE_TYPE.TRANSLATE: {
				return translateSchema.serialize(modelNode.translate, path);
			}
		}

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		modelNode.lastScopeFromRender = scope;

		let value: TSpec;
		let isValid;

		switch (modelNode.type) {
			case SCHEMA_VALUE_TYPE.CONST: {
				value = constSchema.render(rCtx, modelNode.constant, path, scope, prevSpec) as TSpec;
				break;
			}
			case SCHEMA_VALUE_TYPE.EXPRESSION: {
				value = expressionSchema.render(rCtx, modelNode.expression, path, scope, prevSpec) as TSpec;
				break;
			}
			case SCHEMA_VALUE_TYPE.TRANSLATE: {
				value = translateSchema.render(rCtx, modelNode.translate, path, scope, prevSpec) as TSpec;
				break;
			}
		}

		// Cast spec
		if (allowTypeCast && constSchema.castSpec) {
			value = constSchema.castSpec(rCtx, path, modelNode.nodeId, value);
		}

		// Already validated by constant itself
		if (modelNode.type === SCHEMA_VALUE_TYPE.CONST) {
			isValid = true;
			// Validate manually
		} else {
			isValid = constSchema.validate(rCtx, path, modelNode.nodeId, value, true);
		}

		if (isValid) {
			return value as TSpec;
		} else {
			return (opts.fallbackValue !== undefined ? opts.fallbackValue : null) as TSpec;
		}

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		// Is const, delegate
		if (modelNode.type === SCHEMA_VALUE_TYPE.CONST) {
			return constSchema.compileRender(cCtx, modelNode.constant, path);
		}

		//Otherwise it's dynamic
		let value: IModelNodeCompileResult;

		switch (modelNode.type) {
			case SCHEMA_VALUE_TYPE.EXPRESSION: {
				value = expressionSchema.compileRender(cCtx, modelNode.expression, path) as TSpec;
				break;
			}
			case SCHEMA_VALUE_TYPE.TRANSLATE: {
				value = translateSchema.compileRender(cCtx, modelNode.translate, path) as TSpec;
				break;
			}
		}

		if (allowTypeCast && constSchema.compileCastSpec) {

			const castCode = cCtx.addGlobalValue(constSchema.compileCastSpec(cCtx, path, modelNode.nodeId));

			value = {
				isScoped: true,
				code: `(s,pv,pt)=>(${castCode})(${applyCodeArg(value, `pv`, `pt`)},pt)`
			};

		}

		const constValidator = constSchema.compileValidate(cCtx, path, modelNode.nodeId, true);

		if (constValidator !== null) {
			return {
				isScoped: true,
				// eslint-disable-next-line max-len
				code: `(s,pv,pt)=>{const _v=${applyCodeArg(value)};const _iv=${constValidator}(_v,pt);return _iv?_v:${inlineValue(opts.fallbackValue !== undefined ? opts.fallbackValue : null)}}`
			};
		} else {
			return value;
		}

	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {

		// Delegate to const
		return constSchema.validate(rCtx, path, modelNodeId, value, validateChildren);

	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {

		// Delegate to const
		return constSchema.compileValidate(cCtx, path, modelNodeId, validateChildren);

	};

	schema.setType = (modelNode, type,  notify) => {

		modelNode.type = type;

		if (type === SCHEMA_VALUE_TYPE.EXPRESSION && opts.allowExpression === false) {
			throw new ModelNodeManipulationError(schema.name, schema.opts, "Expression type is not allowed.");
		}

		if (type === SCHEMA_VALUE_TYPE.TRANSLATE && opts.allowTranslate !== true) {
			throw new ModelNodeManipulationError(schema.name, schema.opts, "Translate type is not allowed.");
		}

		if (modelNode.type === SCHEMA_VALUE_TYPE.CONST && !modelNode.constant) {
			modelNode.constant = constSchema.createDefault(modelNode.ctx, modelNode) as TConstModel;
		} else if (modelNode.type === SCHEMA_VALUE_TYPE.EXPRESSION && !modelNode.expression) {
			modelNode.expression = expressionSchema.createDefault(modelNode.ctx, modelNode);
		} else if (modelNode.type === SCHEMA_VALUE_TYPE.TRANSLATE && !modelNode.translate) {
			modelNode.translate = translateSchema.createDefault(modelNode.ctx, modelNode);
		}

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaValue", [constSchema, opts]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		if (modelNode?.constant) {
			return modelNode.constant.schema.getTypeDescriptor(modelNode.constant);
		} else {
			try {
				return constSchema.getTypeDescriptor(null);
			} catch (_err) {
				return TypeDescAny({
					label: opts.label,
					description: opts.description,
					example: opts.example,
					tags: opts.tags
				});
			}

		}
	}

	schema.getChildNodes = (modelNode) => {
		const children = [];

		if (modelNode.constant) {
			children.push({
				key: "constant",
				node: modelNode.constant
			});
		}

		if (modelNode.expression) {
			children.push({
				key: "expression",
				node: modelNode.expression
			});
		}

		if (modelNode.translate) {
			children.push({
				key: "translate",
				node: modelNode.translate
			});
		}

		return children;
	}

	return schema;

}
