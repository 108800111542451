/**
 * Table HAE component events
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { termsEditor } from "../../terms";

export const HAEComponentTable_Events = {
	rowClick: {
		...termsEditor.schemas.table.events.rowClick
	}
};
