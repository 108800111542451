/**
 * DOM helpers
 *
 * @package hae-lib-shared
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

export function getWindowFromElement(el: Element): typeof window {
	return el && el.ownerDocument ? el.ownerDocument.defaultView : window;
}

export interface IRect {
	top: number;
	right: number;
	bottom: number;
	left: number;
	width: number;
	height: number;
	x: number;
	y: number;
	center: {
		x: number;
		y: number;
	};
}

export interface ISpacing {
	top: number;
	right: number;
	bottom: number;
	left: number;
}

export function getRect({ top, right, bottom, left }: ISpacing): IRect {
	const width = right - left;
	const height = bottom - top;

	return {
		// ClientRect
		top,
		right,
		bottom,
		left,
		width,
		height,
		// DOMRect
		x: left,
		y: top,
		// Rect
		center: {
			x: (right + left) / 2,
			y: (bottom + top) / 2
		}
	};
}

export function parseCssPxValue(raw: string): number {
	const value = raw.slice(0, -2);
	const suffix = raw.slice(-2);

	// ## Used values vs computed values
	// `getComputedStyle` will return the * used values * if the
	// element has `display: none` and the *computed values* otherwise
	// *used values* can include 'rem' etc.
	// Rather than throwing we are returning `0`.
	// Given that the element is _not visible_ it takes up no visible space and so `0` is correct
	// ## `jsdom`
	// The `raw` value can also not be populated in jsdom
	if (suffix !== "px") {
		return 0;
	}

	return Number(value);
}

function expandSpacing(target: ISpacing, expandBy: ISpacing): ISpacing {
	return {
		// pulling back to increase size
		top: target.top - expandBy.top,
		left: target.left - expandBy.left,
		// pushing forward to increase size
		bottom: target.bottom + expandBy.bottom,
		right: target.right + expandBy.right
	};
}

function shrinkSpacing(target: ISpacing, shrinkBy: ISpacing): ISpacing {
	return {
		// pushing forward to decrease size
		top: target.top + shrinkBy.top,
		left: target.left + shrinkBy.left,
		// pulling backwards to decrease size
		bottom: target.bottom - shrinkBy.bottom,
		right: target.right - shrinkBy.right
	};
}

export interface IBoxModel {
	// content + padding + border + margin
	marginBox: IRect;
	// content + padding + border
	borderBox: IRect;
	// content + padding
	paddingBox: IRect;
	// content
	contentBox: IRect;
	// for your own consumption
	border: ISpacing;
	padding: ISpacing;
	margin: ISpacing;
}

const noSpacing: ISpacing = {
	top: 0,
	right: 0,
	bottom: 0,
	left: 0
};

function createBox({
	borderBox,
	margin,
	border,
	padding
}: {
	borderBox: ISpacing;
	margin?: ISpacing;
	border?: ISpacing;
	padding?: ISpacing;
}): IBoxModel {
	// marginBox = borderBox + margin
	const marginBox = getRect(expandSpacing(borderBox, margin || noSpacing));
	// paddingBox = borderBox - border
	const paddingBox = getRect(shrinkSpacing(borderBox, border || noSpacing));
	// contentBox = paddingBox - padding
	const contentBox = getRect(shrinkSpacing(paddingBox, padding || noSpacing));

	return {
		marginBox,
		borderBox: getRect(borderBox),
		paddingBox,
		contentBox,
		margin,
		border,
		padding
	};
}

export function calculateBox(borderBox: ClientRect | DOMRect, styles: CSSStyleDeclaration): IBoxModel {
	const margin: ISpacing = {
		top: parseCssPxValue(styles.marginTop),
		right: parseCssPxValue(styles.marginRight),
		bottom: parseCssPxValue(styles.marginBottom),
		left: parseCssPxValue(styles.marginLeft)
	};
	const padding: ISpacing = {
		top: parseCssPxValue(styles.paddingTop),
		right: parseCssPxValue(styles.paddingRight),
		bottom: parseCssPxValue(styles.paddingBottom),
		left: parseCssPxValue(styles.paddingLeft)
	};
	const border: ISpacing = {
		top: parseCssPxValue(styles.borderTopWidth),
		right: parseCssPxValue(styles.borderRightWidth),
		bottom: parseCssPxValue(styles.borderBottomWidth),
		left: parseCssPxValue(styles.borderLeftWidth)
	};

	return createBox({
		borderBox,
		margin,
		padding,
		border
	});
}
