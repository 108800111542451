/**
 * Data Table HAE component content
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { Key } from "ts-key-enum";

import {
	ClassList,
	ICON_NAME,
	Icon,
	isEventEnabled,
	isUserInteractionEnabled
} from "@hexio_io/hae-lib-components";

import { DATA_TABLE_RESOLVED_ITEM_STATE, IResolvedItem, TResolvedItemValue } from "./state";
import { TTableItemIds } from "../Table/types";
import {
	COMPONENT_MODE,
	createSubScope,
	ISchemaConstObject, TGetBlueprintSchemaSpec, TGetComponentInstanceFromDefinition, Type
} from "@hexio_io/hae-lib-blueprint";
import { DataTableColumnProps } from "./props";
import { HAEComponentDataTable_Definition, mapResolvedItemValues } from "./definition";
import { isString, toNumber } from "@hexio_io/hae-lib-shared";

/**
 * Editable Cell
 */
export interface IEditableCell {
	id: string;
	key: string;
}

const initialEditableCell: IEditableCell = { id: "", key: "" };

function createFieldName(editableCell: IEditableCell): string {
	return `cmp-data-table-field-id-${editableCell.id}-key-${editableCell.key}`;
}

type TSetResolvedItemValue = (editableCell: IEditableCell, value: TResolvedItemValue) => void;

/**
 * Data Table Content props
 */
export interface IDataTableContentProps {
	/** Resolved items */
	resolvedItems: IResolvedItem[];

	/** Current page order */
	order: TTableItemIds;

	/** Columns */
	columns: TGetBlueprintSchemaSpec<ISchemaConstObject<typeof DataTableColumnProps>>[];

	/** Columns class lists */
	columnsClassLists: ClassList[];

	/** Component instance */
	componentInstance: TGetComponentInstanceFromDefinition<typeof HAEComponentDataTable_Definition>;

	/** Set resolved item value */
	setResolvedItemValue: TSetResolvedItemValue;
}

/**
 * Data Table Content component
 */
export const DataTableContent: React.FunctionComponent<IDataTableContentProps> = (props) => {
	const { resolvedItems, order, columns, columnsClassLists, componentInstance, setResolvedItemValue } = props;

	const { safePath: componentPath, componentMode } = componentInstance;

	const normalComponentMode = componentInstance.componentMode === COMPONENT_MODE.NORMAL;

	const [ activeEditableCell, setActiveEditableCell ] = React.useState(initialEditableCell);
	const clearActiveEditableCell = React.useCallback(() => {
		setActiveEditableCell(initialEditableCell);
	}, [ setActiveEditableCell, initialEditableCell ]);

	const elementRef = React.useRef<HTMLTableSectionElement>();

	// Fix focus

	React.useEffect(() => {
		if (!activeEditableCell.id || !activeEditableCell.key || !elementRef.current) {
			return;
		}

		const input = elementRef.current.querySelector<HTMLInputElement>(`input[name="${createFieldName(activeEditableCell)}"]`);

		if (input && input !== document.activeElement) {
			input.focus();
		}

	}, [ activeEditableCell.id, activeEditableCell.key ]);

	// Functions

	const resolveBlur = React.useCallback(() => {
		setTimeout(() => {
			if (!elementRef.current?.contains(document.activeElement)) {
				clearActiveEditableCell();
			}
		});
	}, [ clearActiveEditableCell ]);

	const resolveKeyDown = React.useCallback((key: string, shiftKey: boolean, editableCell: IEditableCell) => {
		if (key === Key.Enter) {
			if (order.length > 1) {
				const targetActiveEditableCell = { ...editableCell };
				const index = order.indexOf(editableCell.id);

				targetActiveEditableCell.id = !shiftKey ? (order[index + 1] || order[0]) : (order[index - 1] || order[order.length - 1]);

				setActiveEditableCell(targetActiveEditableCell);
			}
			else {
				clearActiveEditableCell();
			}

			return true;
		}

		if (key === Key.Escape) {
			clearActiveEditableCell();
		}

		return false;
	}, [ order.join(","), setActiveEditableCell, clearActiveEditableCell ]);

	return (
		<tbody ref={elementRef} className="table__content">
			{
				order.map((item) => {
					const id = item;
					const resolvedItem = resolvedItems.find((resolvedItem) => resolvedItem.id === id);

					if (!resolvedItem) {
						return null;
					}

					const rowKey = `id-${id}`;
					const { state } = resolvedItem;

					const _rowClickHandler = isEventEnabled(componentInstance.eventEnabled.rowClick, componentMode) ? () => {
						return componentInstance.eventTriggers.rowClick((parentScope) => {
							return createSubScope(
								parentScope,
								{ item: resolvedItem.originalData, row: mapResolvedItemValues(resolvedItem) },
								{ item: Type.Any({}), row: Type.Any({}) }
							);
						});
					} : undefined;

					return (
						<tr key={rowKey} className="table__row" onClick={_rowClickHandler}>
							{
								columns.map((columnItem, columnIndex) => {
									if (!columnItem.render && normalComponentMode) {
										return null;
									}

									const editableCell: IEditableCell = { id, key: columnItem.key };

									const cellKey = `${rowKey}-${columnIndex}`;

									const { value, formattedValue } = resolvedItem.data[columnItem.key];

									const { editable } = columnItem;

									const editableAllowed = editable && normalComponentMode;
									const editableActive = editableAllowed &&
										activeEditableCell.id === editableCell.id &&
										activeEditableCell.key === editableCell.key;

									let editableCursor: string;
									let cellContent = null;

									const editableFocusable = editableAllowed && !editableActive;

									switch (columnItem.typeData.type) {
										case "STRING": {
											const valueString = value as string;

											if (editable) {
												editableCursor = "text";
											}

											cellContent = !editableActive ?
												(isString(formattedValue) ? formattedValue : valueString) :
												<DataTableContentTextField
													value={valueString}
													editableCell={editableCell}
													state={state}
													componentMode={componentMode}
													setResolvedItemValue={setResolvedItemValue}
												/>;

											break;
										}

										case "NUMBER": {
											const valueNumber = !Number.isNaN(value) ?
												value as number :
												"";

											if (editable) {
												editableCursor = "text";
											}

											cellContent = !editableActive ?
												(isString(formattedValue) ? formattedValue : `${valueNumber}`) :
												<DataTableContentNumberField
													value={valueNumber}
													editableCell={editableCell}
													state={state}
													componentMode={componentMode}
													setResolvedItemValue={setResolvedItemValue}
												/>;

											break;
										}

										case "BOOLEAN": {
											const valueBoolean = value as boolean;
											const formattedValueDefined = isString(formattedValue);

											if (!editableActive && formattedValueDefined) {
												if (editable) {
													editableCursor = "pointer";
												}

												cellContent = formattedValue;
											}
											else if (!editable) {
												const iconName = value === true ? ICON_NAME.CHECK : ICON_NAME.CLOSE;

												cellContent = <Icon
													source={iconName}
													componentPath={[ ...componentPath, "content", cellKey, "boolean-icon" ]}
													componentMode={componentMode}
												/>
											}
											else {
												//editableFocusable = false;

												cellContent = <DataTableContentBooleanField
													value={valueBoolean}
													editableCell={editableCell}
													state={state}
													//autoFocus={formattedValueDefined}
													componentMode={componentMode}
													setResolvedItemValue={setResolvedItemValue}
												/>;
											}

											break;
										}
									}

									const cellClassList = new ClassList(
										"cmp-data-table__cell",
										...columnsClassLists[columnIndex]
									);

									if (editable) {
										cellClassList.addModifiers({
											"editable": true,
											"editable-cursor": editableCursor,
											"editable-active": editableActive
										});
									}

									return (
										<td
											key={cellKey}
											className={cellClassList.toClassName()}
											tabIndex={editableFocusable ? 0 : undefined}
											onFocus={editableFocusable ? () => setActiveEditableCell(editableCell) : undefined}
											onBlurCapture={editableActive ? () => resolveBlur() : undefined}
											onKeyDownCapture={
												editableActive ?
													(event: React.KeyboardEvent<HTMLTableCellElement>) => {
														if (resolveKeyDown(event.key, event.shiftKey, editableCell)) {
															event.preventDefault();
														}
													} :
													undefined
											}
										>
											<div className="table__cell-item cmp-data-table__cell-item">
												{cellContent}
											</div>
										</td>
									);
								})
							}
						</tr>
					);
				})
			}
		</tbody>
	);
}

/**
 * Data Table Content Field base props
 */
interface IDataTableContentFieldProps<TValue = unknown> {
	/** Editable cell */
	editableCell: IEditableCell;

	/** Field value */
	value: TValue;

	/** State */
	state: DATA_TABLE_RESOLVED_ITEM_STATE;

	/** Component mode */
	componentMode: COMPONENT_MODE;

	/** Set resolved item value */
	setResolvedItemValue: TSetResolvedItemValue;
}

/**
 * Data Table Content Field hook
 */
function useDataTableContentFieldState<TValue = unknown>(props: IDataTableContentFieldProps<TValue>): [
	value: TValue,
	setValue: React.Dispatch<React.SetStateAction<TValue>>
] {
	const [ value, setValue ] = React.useState(props.value);

	React.useEffect(() => {
		if (props.state !== DATA_TABLE_RESOLVED_ITEM_STATE.EDITED_BY_USER) {
			setValue(props.value);
		}
	}, [ props.value ]);

	return [ value, setValue ];
}

/**
 * Data Table Content Text Field props
 */
interface IDataTableContentTextFieldProps extends IDataTableContentFieldProps<string> {}

/**
 * Data Table Content Text Field component
 */
const DataTableContentTextField: React.FunctionComponent<IDataTableContentTextFieldProps> = (props) => {
	const { editableCell, componentMode, setResolvedItemValue } = props;

	const [ value, setValue ] = useDataTableContentFieldState<typeof props.value>(props);

	return (
		<input
			type="text"
			name={createFieldName(editableCell)}
			value={value}
			disabled={!isUserInteractionEnabled(componentMode)}
			className="input input--type-text table__cell-input"
			autoFocus={true}
			onChange={(event) => {
				const newValue = event.currentTarget.value;

				setValue(newValue);
				setResolvedItemValue(editableCell, newValue);
			}}
		/>
	);
}

/**
 * Data Table Content Number Field props
 */
interface IDataTableContentNumberFieldProps extends IDataTableContentFieldProps<number | ""> {}

/**
 * Data Table Content Number Field component
 */
const DataTableContentNumberField: React.FunctionComponent<IDataTableContentNumberFieldProps> = (props) => {
	const { editableCell, componentMode, setResolvedItemValue } = props;

	const [ value, setValue ] = useDataTableContentFieldState<typeof props.value>(props);

	return (
		<input
			type="number"
			name={createFieldName(editableCell)}
			value={value}
			disabled={!isUserInteractionEnabled(componentMode)}
			className="input input--type-number table__cell-input"
			autoFocus={true}
			onChange={(event) => {
				const newValue = toNumber(event.currentTarget.value);
				const noNaN = !Number.isNaN(newValue);

				setValue(noNaN ? newValue : "");
				setResolvedItemValue(editableCell, noNaN ? newValue : null);
			}}
		/>
	);
}

/**
 * Data Table Content Boolean Field props
 */
interface IDataTableContentBooleanFieldProps extends IDataTableContentFieldProps<boolean> {}

/**
 * Data Table Content Boolean Field component
 */
const DataTableContentBooleanField: React.FunctionComponent<IDataTableContentBooleanFieldProps> = (props) => {
	const { editableCell, componentMode, setResolvedItemValue } = props;

	const [ value, setValue ] = useDataTableContentFieldState<typeof props.value>(props);

	return (
		<input
			type="checkbox"
			name={createFieldName(editableCell)}
			checked={value}
			className="input input--type-checkbox table__cell-input"
			onChange={(event) => {
				if (!isUserInteractionEnabled(componentMode)) {
					return;
				}

				const newValue = event.currentTarget.checked;

				setValue(newValue);
				setResolvedItemValue(editableCell, newValue);
			}}
		/>
	);
}
