/**
 * Hexio App Engine Core
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { RoutingContext } from "@hexio_io/hae-lib-components";
import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import React, { useEffect, useMemo, useState } from "react";
import { IRoutingManager } from "./IRoutingManager";

/**
 * Component props
 */
interface IRouterComponentProps {
	routingManager: IRoutingManager;
}

/**
 * Render root components returned by the RuntimeContext
 *
 * @param props Component props
 */
export const RouterComponent: React.FunctionComponent<IRouterComponentProps> = ({ routingManager, children }) => {

	const [ location, setLocation ] = useState(routingManager.getCurrentLocation());

	const resolveLink = useMemo(() => routingManager.resolveLink.bind(routingManager), [ routingManager ]);
	const navigate = useMemo(() => routingManager.navigate.bind(routingManager), [ routingManager ]);

	useEffect(() => {

		const handleNavigate = () => setLocation(routingManager.getCurrentLocation());

		onEvent(routingManager.onNavigate, handleNavigate);

		return () => {
			offEvent(routingManager.onNavigate, handleNavigate);
		};

	}, [ routingManager ]);

	return <RoutingContext.Provider value={{
		location: location,
		resolveLink: resolveLink,
		navigate: navigate
	}}>
		{children}
	</RoutingContext.Provider>;

};