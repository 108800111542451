/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstDateOpts, SchemaConstDate, ISchemaConstDate } from "../const/SchemaConstDate";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueDateOpts extends ISchemaValueOpts<ISchemaConstDate>, ISchemaConstDateOpts {}

/**
 * Schema: Date value
 *
 * @param opts Schema options
 */
export function SchemaValueDate(opts: ISchemaValueDateOpts): ISchemaValue<ISchemaConstDate> {

	return SchemaValue(SchemaConstDate(opts), opts);

}