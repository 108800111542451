
/**
 * Get Timestamp function
 *
 * @package hae-lib-shared
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { isNumber } from "../Functions/isValidValue";

/**
 * Returns timestamp (when no value is passed, it will return current timestamp)
 * 
 * @param value Optional value
 */
export function getTimestamp(value?: number | string | Date): number {
	if (!!value || value === 0) {
		const result = isNumber(value) ? value : new Date(value).getTime();

		if (Number.isFinite(result)) {
			return result;
		}
	}

	return Date.now();
}
