/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaFlowNodeTypeOutputDefinitionMap, SCHEMA_CONST_ANY_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";
import { FLOW_NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintFlowNodeTypeSetSession = {
	name: FLOW_NODE_TYPES.SETSESSION,
	label: "Set session",
	icon: "mdi/account-arrow-down",
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			userIdentityKey: BP.Prop(BP.Any({
				label: termsEditor.nodes.setSession.userIdentityKey.label,
				description: termsEditor.nodes.setSession.userIdentityKey.description,
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			})),
			meta: BP.Prop(BP.Map({
				label: termsEditor.nodes.setSession.meta.label,
				description: termsEditor.nodes.setSession.meta.description,
				value: BP.Any({
					label: termsEditor.nodes.setSession.meta.value.label,
					description: termsEditor.nodes.setSession.meta.value.description,
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
				})
			}))
		}
	}),
	editorOptions: {
		displayInPalette: true
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		onSuccess: { label: "On Success", icon: "mdi/check" },
		onError: { label: "On Error", icon: "mdi/alert" }
	})
};