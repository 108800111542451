/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGenericBlueprintSchema } from "../../Schema/IBlueprintSchema";
import { ISchemaConstArrayOpts, SchemaConstArray, ISchemaConstArray } from "../const/SchemaConstArray";
import { ISchemaValue, ISchemaValueOpts, SchemaValue } from "./SchemaValue";

export interface ISchemaValueArrayOpts<
	TItemsSchema extends TGenericBlueprintSchema
> extends ISchemaValueOpts<ISchemaConstArray<TItemsSchema>>, ISchemaConstArrayOpts<TItemsSchema> {}

/**
 * Schema: Array value
 *
 * @param opts Schema options
 */
export function SchemaValueArray<
	TItemsSchema extends TGenericBlueprintSchema
>(opts: ISchemaValueArrayOpts<TItemsSchema>): ISchemaValue<ISchemaConstArray<TItemsSchema>> {

	return SchemaValue(SchemaConstArray(opts), opts);

}
