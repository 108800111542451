/**
 * Box Shadow enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum BOX_SHADOW {
	NONE = "none",
	THEME = "theme",
	XSMALL = "x-small",
	SMALL = "small",
	MEDIUM = "medium",
	LARGE = "large",
	XLARGE = "x-large"
}

export type BOX_SHADOW_string = keyof typeof BOX_SHADOW;

export const BOX_SHADOW_default: BOX_SHADOW_string = "NONE";
