/**
 * Button Group Edit Mode functions
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	ISchemaComponentModel,
	ISchemaConstObjectModel,
	MEDIA_RESOLUTIONS_default,
	MEDIA_RESOLUTIONS_string,
	SCHEMA_VALUE_TYPE,
	TGenericComponentInstance
} from "@hexio_io/hae-lib-blueprint";

import {
	IAllowedResizeDimensions,
	IHAEComponentListElementPlaceholder,
	TResizeAfterRenderHandler,
	IResizeOffset,
	THAEComponentListElement,
	getStringEnumCssValue,
	SPACING,
} from "@hexio_io/hae-lib-components";

import { isValidObject } from "@hexio_io/hae-lib-shared";

import { TButtonGroupItemInheritedProps } from "./props";

/**
 * Resolves child inline style
 */
export function resolveChildInlineStyle(
	element: THAEComponentListElement<TButtonGroupItemInheritedProps>,
	index: number,
	media: MEDIA_RESOLUTIONS_string
): React.CSSProperties | undefined {
	if (element.type !== "placeholder") {
		return;
	}

	const result: React.CSSProperties = {};

	const inheritedProps = (element.inheritedProps as Record<string, unknown>);

	return result;
}
