/**
 * Image Fit enum
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum IMAGE_FIT {
	CONTAIN = "contain",
	COVER = "cover",
	FILL = "fill"
}

export type IMAGE_FIT_string = keyof typeof IMAGE_FIT;

export const IMAGE_FIT_default = "CONTAIN";
