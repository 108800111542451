/**
 * Grid Edit Mode functions
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	ISchemaComponentModel,
	ISchemaConstObjectModel,
	MEDIA_RESOLUTIONS_default,
	MEDIA_RESOLUTIONS_string,
	SCHEMA_VALUE_TYPE,
	TGenericComponentInstance
} from "@hexio_io/hae-lib-blueprint";

import {
	IAllowedResizeDimensions,
	IHAEComponentListElementPlaceholder,
	TResizeAfterRenderHandler,
	IResizeOffset,
	THAEComponentListElement,
	getStringEnumCssValue,
	SPACING,
} from "@hexio_io/hae-lib-components";

import { isValidObject } from "@hexio_io/hae-lib-shared";

import { TGridItemInheritedProps } from "./props";
import { getGridArea, IGridCellDimensions } from "./helpers";

/**
 * Item position
 */
interface IItemPosition {
	x: number;
	y: number;
	width: number;
	height: number;
}

/**
 * Resize initial state
 */
export interface IResizeInitialState extends IItemPosition {
	cellWidth: number;
	cellHeight: number;
}

/**
 * Reposition floor
 */
function repositionFloor(value: number, dimension: number): number {
	return Math.floor((value + (dimension / 2)) / dimension) * dimension;
}

/**
 * Reposition ceil
 */
function repositionCeil(value: number, dimension: number): number {
	return Math.ceil((value - (dimension / 2)) / dimension) * dimension;
}

/**
 * Returns grid position offset value
 * 
 * @param value Value
 * @param dimension Dimension
 */
function getGridPositionOffsetValue(value: number, dimension: number): number {
	return Math.round(repositionFloor(value, dimension) / dimension);
}

/**
 * Returns grid position dimension value
 * 
 * @param value Value
 * @param dimension Dimension
 */
function getGridPositionDimensionValue(value: number, dimension: number): number {
	return Math.round(repositionCeil(value, dimension) / dimension);
}

/**
 * Returns grid position value
 * 
 * @param name Value name
 * @param resizeOffset Resize offset
 * @param initialState Initial state
 * @param min Limit min value
 */
function getGridPositionValue(
	name: "x" | "y" | "width" | "height",
	resizeOffset: IResizeOffset,
	initialState: IResizeInitialState
): number {
	switch (name) {
		case "x":
			return getGridPositionOffsetValue(initialState.x + resizeOffset.left, initialState.cellWidth);

		case "y":
			return getGridPositionOffsetValue(initialState.y + resizeOffset.top, initialState.cellHeight);

		case "width":
			return getGridPositionDimensionValue(initialState.width + resizeOffset.width, initialState.cellWidth);
		
		case "height":
			return getGridPositionDimensionValue(initialState.height + resizeOffset.height, initialState.cellHeight);
	}
}

/**
 * Returns safe X value
 */
function sanitizeXValue(value: number, maxOrInitialState: number | IResizeInitialState): number {
	let max: number;

	if (typeof maxOrInitialState === "number") {
		max = maxOrInitialState;
	}
	else {
		const { x, width, cellWidth } = maxOrInitialState;

		max = getGridPositionOffsetValue(x, cellWidth) + getGridPositionDimensionValue(width, cellWidth) - 1;
	}

	return Math.max(Math.min(value, max), 0);
}

/**
 * Returns safe Y value
 */
function sanitizeYValue(value: number): number {
	return Math.max(value, 0);
}

/**
 * Returns safe Width value
 */
function sanitizeWidthValue(value: number, columns: number, x = 0, offset = 0): number {
	return Math.max((offset < 0 ? (value + offset) : Math.min(value, columns - x)), 1);
}

/**
 * Returns safe Height value
 */
function sanitizeHeightValue(value: number, offset = 0): number {
	return Math.max((offset < 0 ? (value + offset) : value), 1);
}

/**
 * Resolves child inline style
 */
export function resolveChildInlineStyle(
	element: THAEComponentListElement<TGridItemInheritedProps>,
	index: number,
	media: MEDIA_RESOLUTIONS_string,
	cellDimensions: IGridCellDimensions,
	columns: number
): React.CSSProperties | undefined {
	if (element.type !== "placeholder") {
		return;
	}

	const result: Record<string, unknown> = {};

	const inheritedProps = (element.inheritedProps as Record<string, unknown>);
	const isGridArea = isValidObject(inheritedProps.griditemposition);

	// Grid Item position

	const { width: cellWidth, height: cellHeight, baseHeight: baseCellHeight } = cellDimensions;

	let xValue = getGridPositionOffsetValue((isGridArea ? element.offset?.parentx : element.offset?.x) || 0, cellWidth);
	let yValue = getGridPositionOffsetValue((isGridArea ? element.offset?.parenty : element.offset?.y) || 0, cellHeight);
	let widthValue = getGridPositionDimensionValue(
		isGridArea ? element.dimensions.parentwidth : (element.dimensions.width || 100), // 100 should be temporary
		cellWidth
	);
	let heightValue = getGridPositionDimensionValue(
		isGridArea ? element.dimensions.parentheight : (element.dimensions.height || 50), // 50 should be temporary
		isGridArea ? cellHeight : baseCellHeight
	);

	xValue = sanitizeXValue(xValue, columns - widthValue);
	yValue = sanitizeYValue(yValue);
	widthValue = sanitizeWidthValue(widthValue, columns, xValue);
	heightValue = sanitizeHeightValue(heightValue);

	result.gridArea = getGridArea(xValue, yValue, widthValue, heightValue);

	// Grid Item spacing

	if (typeof inheritedProps.griditemspacing === "string") {
		const gridItemSpacingValue = getStringEnumCssValue(
			SPACING,
			inheritedProps.griditemspacing.toUpperCase(),
			"spacing-",
			undefined,
			inheritedProps.griditemspacing
		);

		if (gridItemSpacingValue) {
			result["--element-spacing"] = gridItemSpacingValue;
		}
	}

	return result;
}

/**
 * Resolves allow resize
 */
export function resolveAllowResize(
	componentInstance: TGenericComponentInstance<TGridItemInheritedProps>,
	index: number
): IAllowedResizeDimensions | null {
	if (!componentInstance.modelNode) {
		return null;
	}
	
	return {
		left: true,
		top: true,
		width: true,
		height: true
	} as IAllowedResizeDimensions;
}

/**
 * Handles resize begin
 */
export function handleResizeBegin(
	subject: TGenericComponentInstance<TGridItemInheritedProps>,
	resizeOffset: IResizeOffset,
	media: MEDIA_RESOLUTIONS_string,
	cellDimensions: IGridCellDimensions
): IResizeInitialState | null {
	if (!subject.modelNode) {
		return null;
	}

	const { width: cellWidth, height: cellHeight } = cellDimensions;

	const gridItemPosition = subject.inheritedProps.gridItemPosition[media];
	const baseGridItemPosition = media !== MEDIA_RESOLUTIONS_default ?
		subject.inheritedProps.gridItemPosition[MEDIA_RESOLUTIONS_default] :
		null;

	let x = gridItemPosition?.x ?? baseGridItemPosition?.x;
	let y = gridItemPosition?.y ?? baseGridItemPosition?.y;
	let width = gridItemPosition?.width ?? baseGridItemPosition?.width;
	let height = gridItemPosition?.height ?? baseGridItemPosition?.height;

	x = Number.isFinite(x) ? (x * cellWidth) : 0;
	y = Number.isFinite(y) ? (y * cellHeight) : 0;
	width = Number.isFinite(width) ? (width * cellWidth) : cellWidth;
	height = Number.isFinite(height) ? (height * cellHeight) : cellHeight;

	return {
		x,
		y,
		width,
		height,
		cellWidth,
		cellHeight
	};
}

/**
 * Handles resize update
 */
export function handleResizeUpdate(
	_subject: TGenericComponentInstance<TGridItemInheritedProps>,
	element: HTMLElement,
	resizeOffset: IResizeOffset,
	initialState: IResizeInitialState,
	columns: number
): void {
	const xOffsetValue = getGridPositionValue("x", resizeOffset, initialState);
	const yOffsetValue = getGridPositionValue("y", resizeOffset, initialState);

	const xValue = sanitizeXValue(xOffsetValue, initialState);
	const yValue = sanitizeYValue(yOffsetValue);
	const widthValue = sanitizeWidthValue(getGridPositionValue("width", resizeOffset, initialState), columns, xValue, xOffsetValue);
	const heightValue = sanitizeHeightValue(getGridPositionValue("height", resizeOffset, initialState), yOffsetValue);

	element.parentElement.style.gridArea = getGridArea(xValue, yValue, widthValue, heightValue);
}

/**
 * Handles resize end
 */
export function handleResizeEnd(
	subject: TGenericComponentInstance<TGridItemInheritedProps>,
	resizeOffset: IResizeOffset,
	initialState: IResizeInitialState,
	media: MEDIA_RESOLUTIONS_string,
	columns: number,
): void | TResizeAfterRenderHandler {
	if (!subject.modelNode) {
		return null;
	}

	const inheritedProps = (subject.modelNode.inheritedProps as ISchemaConstObjectModel<TGridItemInheritedProps>).props;
	const gridItemPositionProps = inheritedProps.gridItemPosition.value.constant.props[media].constant.props;	

	const { x: xProp, y: yProp, width: widthProp, height: heightProp } = gridItemPositionProps;

	// X

	const xValue = sanitizeXValue(getGridPositionValue("x", resizeOffset, initialState), initialState);

	xProp.constant.schema.setValue(xProp.constant, xValue, true);

	// Y

	const yValue = sanitizeYValue(getGridPositionValue("y", resizeOffset, initialState));

	yProp.constant.schema.setValue(yProp.constant, yValue, true);

	// Width

	const xOffsetValue = getGridPositionValue("x", resizeOffset, initialState);
	const widthValue = sanitizeWidthValue(
		getGridPositionValue("width", resizeOffset, initialState),
		columns,
		xProp.constant.value,
		xOffsetValue
	);

	widthProp.constant.schema.setValue(widthProp.constant, widthValue, true);

	// Height

	const yOffsetValue = getGridPositionValue("y", resizeOffset, initialState);
	const heightValue = sanitizeHeightValue(getGridPositionValue("height", resizeOffset, initialState), yOffsetValue);

	heightProp.constant.schema.setValue(heightProp.constant, heightValue, true);

	return (element: HTMLElement) => {
		element.parentElement.style.gridArea = "";
	};
}

/**
 * Modifies model on drop
 */
export function modifyModelOnDrop(
	modelNode: ISchemaComponentModel,
	element: IHAEComponentListElementPlaceholder<TGridItemInheritedProps>,
	media: MEDIA_RESOLUTIONS_string,
	cellDimensions: IGridCellDimensions,
	columns: number
): void {
	const inheritedProps = (modelNode.inheritedProps as ISchemaConstObjectModel<TGridItemInheritedProps>).props;
	const gridItemPositionValue = inheritedProps.gridItemPosition.value.constant.props[media];

	const isGridArea = isValidObject((element.inheritedProps as Record<string, unknown>).griditemposition);

	if (gridItemPositionValue.type === SCHEMA_VALUE_TYPE.CONST) {
		const { width: cellWidth, height: cellHeight, baseHeight: baseCellHeight } = cellDimensions;

		const yValue = sanitizeYValue(
			getGridPositionOffsetValue((isGridArea ? element.offset?.parenty : element.offset?.y) || 0, cellHeight)
		);
		const heightValue = sanitizeHeightValue(
			getGridPositionDimensionValue(
				isGridArea ? element.dimensions.parentheight : (element.dimensions.height || 50),
				isGridArea ? cellHeight : baseCellHeight
			)
		);

		let xValue = getGridPositionOffsetValue((isGridArea ? element.offset?.parentx : element.offset?.x) || 0, cellWidth);
		let widthValue = getGridPositionDimensionValue(
			isGridArea ? element.dimensions.parentwidth : (element.dimensions.width || 100),
			cellWidth
		);

		// Little bit complicated sanitization because element can be dropped from both current grid or some external container/grid
		xValue = sanitizeXValue(xValue, columns - widthValue);
		widthValue = sanitizeWidthValue(widthValue, columns, xValue);

		const { x: xProp, y: yProp, width: widthProp, height: heightProp } = gridItemPositionValue.constant.props;

		if (xProp.type === SCHEMA_VALUE_TYPE.CONST) {
			xProp.constant.schema.setValue(xProp.constant, xValue, true);
		}

		if (yProp.type === SCHEMA_VALUE_TYPE.CONST) {
			yProp.constant.schema.setValue(yProp.constant, yValue, true);
		}

		if (widthProp.type === SCHEMA_VALUE_TYPE.CONST) {
			widthProp.constant.schema.setValue(widthProp.constant, widthValue, true);
		}

		if (heightProp.type === SCHEMA_VALUE_TYPE.CONST) {
			heightProp.constant.schema.setValue(heightProp.constant, heightValue, true);
		}
	}

	return;
}
