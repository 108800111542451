/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IBlueprintSchemaChildNode } from "./IBlueprintSchema";
import { TGenericModelNode } from "./IModelNode";

export interface IModelQueryResultItem extends IBlueprintSchemaChildNode {
	path: IBlueprintSchemaChildNode[];
}

export interface IModelQueryResult extends Array<IModelQueryResultItem> {}

export interface IModelQueryTreeResultItem extends IModelQueryResultItem {
	children: IModelQueryTreeResult;
}

export interface IModelQueryTreeResult extends Array<IModelQueryTreeResultItem> {}

/**
 * Query function result operation
 */
export enum QUERY_FN_OP {
	/** Continues traversal */
	CONTINUE,
	/** Stops traversal of children, continue to sibling nodes */
	STOP,
	/** Terminates entire query execution */
	BREAK
}

export interface IQueryFunctionResult {
	/** If current node should be added to result */
	match: boolean;
	/** What operation should be taken next */
	op: QUERY_FN_OP;
}

/**
 * Traverses model and returns all nodes that matches queryFn
 *
 * @param entryNode Entry model node
 * @param queryFn Optional query function - if not specified, all nodes will be returned.
 * @param maxDepth Max. number of levels - default -1 = no limit
 */
export function queryModel(
	entryNode: TGenericModelNode,
	queryFn?: (item: IModelQueryResultItem) => IQueryFunctionResult,
	maxDepth = -1
): IModelQueryResult {

	const result: IModelQueryResult = [];

	let searchStack: IModelQueryResultItem[] = [{
		key: null,
		node: entryNode,
		path: []
	}];

	let currentDepth = 0;
	let hasBreaked = false;

	while (!hasBreaked && searchStack.length > 0 && (maxDepth < 0 || currentDepth <= maxDepth)) {

		const nextStack: IModelQueryResultItem[] = [];

		for (let i = 0; i < searchStack.length; i++) {
			const item = searchStack[i];
			const queryResult = queryFn ? queryFn(item) : { match: true, op: QUERY_FN_OP.CONTINUE };

			if (queryResult.match) {
				result.push(item);
			}

			// Break execution
			if (queryResult.op === QUERY_FN_OP.BREAK) {
				hasBreaked = true;
				break;
			}

			// Continue to siblings
			if (queryResult.op === QUERY_FN_OP.STOP) {
				continue;
			}

			// Traverse children
			const children = item.node.schema.getChildNodes(item.node);
			const _searchPath = item.path.concat(item);

			for (let j = 0; j < children.length; j++) {
				nextStack.push({
					...children[j],
					path: _searchPath
				})
			}
		}

		searchStack = nextStack;
		currentDepth++;

	}

	return result;

}

/**
 * Traverses model and returns all nodes that matches queryFn as a tree structure
 * If deeply nested children matches query they are added to the nearest children list.
 *
 * @param entryNode Entry model node
 * @param queryFn Optional query function - if not specified, all nodes will be returned.
 * @param maxDepth Max. number of levels - default -1 = no limit
 */
export function queryModelTree(
	entryNode: TGenericModelNode,
	queryFn?: (item: IModelQueryResultItem) => IQueryFunctionResult,
	maxDepth = -1
): IModelQueryTreeResult {

	const result: IModelQueryTreeResult = [];

	let searchStack: Array<{ item: IModelQueryResultItem, resultList: IModelQueryTreeResult }> = [{
		item: {
			key: null,
			node: entryNode,
			path: []
		},
		resultList: result
	}];

	let currentDepth = 0;
	let hasBreaked = false;

	while (!hasBreaked && searchStack.length > 0 && (maxDepth < 0 || currentDepth <= maxDepth)) {

		const nextStack: Array<{ item: IModelQueryResultItem, resultList: IModelQueryTreeResult }> = [];

		for (let i = 0; i < searchStack.length; i++) {
			const item = searchStack[i].item;
			let _resultList = searchStack[i].resultList;

			const queryResult = queryFn ? queryFn(item) : { match: true, op: QUERY_FN_OP.CONTINUE };

			if (queryResult.match) {
				// Create array for children
				const _children = [];

				// Add to current result list
				_resultList.push({
					...item,
					children: _children
				});

				// Set new result list for child traversal
				_resultList = _children;
			}

			// Break execution
			if (queryResult.op === QUERY_FN_OP.BREAK) {
				hasBreaked = true;
				break;
			}

			// Continue to siblings
			if (queryResult.op === QUERY_FN_OP.STOP) {
				continue;
			}

			// Traverse children
			const children = item.node.schema.getChildNodes(item.node);
			const _searchPath = item.path.concat(item);

			for (let j = 0; j < children.length; j++) {
				nextStack.push({
					item: {
						...children[j],
						path: _searchPath
					},
					resultList: _resultList
				})
			}
		}

		searchStack = nextStack;
		currentDepth++;

	}

	return result;

}

/**
 * Tries to find model node by nodeID starting at entryNode
 * Returns null if model was not found.
 *
 * @param entryNode Entry node
 * @param nodeId Node ID to search for
 */
export function findModelNodeById(entryNode: TGenericModelNode, nodeId: number): IModelQueryResultItem {

	const queryRes = queryModel(entryNode, (item) => {
		if (item.node.nodeId === nodeId) {
			return {
				match: true,
				op: QUERY_FN_OP.BREAK
			};
		} else {
			return {
				match: false,
				op: QUERY_FN_OP.CONTINUE
			}
		}
	});

	return queryRes[0] || null;

}
