/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { DesignContext } from "../../Context/DesignContext";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { ISchemaImportExport } from "../../ExportImportSchema/ExportTypes";
import {
	IBlueprintSchema, TBlueprintSchemaParentNode,
	TGenericBlueprintSchema
} from "../../Schema/IBlueprintSchema";
import {
	assignParentToModelProps,
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateAsNotSupported
} from "../../Schema/SchemaHelpers";
import { ISchemaBuilderOpts } from "./SchemaBuilderShared";
import { IModelNode, MODEL_CHANGE_TYPE, TGenericModelNode } from "../../Schema/IModelNode";
import { SchemaDeclarationError } from "../../Schema/SchemaDeclarationError";
import { SchemaBuilderBoolean } from "./SchemaBuilderBoolean";
import { BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE, IBlueprintIDTMap, IBlueprintIDTMapElement, IBlueprintIDTScalar } from "../../IDT/ISchemaIDT";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { SchemaBuilderArray } from "./SchemaBuilderArray";
import { SchemaBuilderString } from "./SchemaBuilderString";
import { SchemaBuilderFloat } from "./SchemaBuilderFloat";
import { SchemaBuilderInteger } from "./SchemaBuilderInteger";
import { SchemaBuilderMap } from "./SchemaBuilderMap";
import { SchemaBuilderDate } from "./SchemaBuilderDate";
import { SchemaBuilderEnum, SCHEMA_BUILDER_ENUM_VALUE_TYPE } from "./SchemaBuilderEnum";
import { SchemaBuilderObject } from "./SchemaBuilderObject";
import { SchemaBuilderData } from "./SchemaBuilderData";
import {
	extractAndValidateIDTMapProperties,
	provideIDTMapPropertyCompletions,
	provideIDTMapRootCompletions,
	validateIDTNode
} from "../../Context/ParseUtil";
import { IDocumentLocation } from "../../Shared/IDocumentLocation";
import { CMPL_ITEM_KIND, ICompletionItem } from "../../Shared/ICompletionItem";
import { SchemaBuilderPassword } from "./SchemaBuilderPassword";
import { SchemaBuilderConstAny } from "./SchemaBuilderConstAny";

/**
 * Any value types
 */
export enum SCHEMA_BUILDER_ANY_VALUE_TYPE {
	BOOLEAN = "boolean",
	INTEGER = "integer",
	FLOAT = "float",
	STRING = "string",
	DATA = "data",
	DATE = "date",
	ARRAY = "array",
	MAP = "map",
	ENUM_STRING = "enum_string",
	ENUM_INTEGER = "enum_integer",
	ENUM_FLOAT = "enum_float",
	OBJECT = "object",
	PASSWORD = "password",
	ANY = "any"
}

/**
 * Schema Model
 */
export interface ISchemaBuilderAnyModel extends IModelNode<ISchemaBuilderAny> {
	type: SCHEMA_BUILDER_ANY_VALUE_TYPE;
	value: TGenericModelNode;
}

/**
 * Schema spec
 */
export type TSchemaBuilderAnySpec = ISchemaImportExport;

/**
 * Schema default value
 */
export type TSchemaBuilderAnyDefault = {
	type: SCHEMA_BUILDER_ANY_VALUE_TYPE,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	opts: any;
};

/**
 * Schema opts
 */
export interface ISchemaBuilderAnyOpts extends ISchemaBuilderOpts {
	defaultType: SCHEMA_BUILDER_ANY_VALUE_TYPE;
}

/**
 * Schema Builder Boolean Schema
 */
export interface ISchemaBuilderAny extends IBlueprintSchema<
	ISchemaBuilderAnyOpts,
	ISchemaBuilderAnyModel,
	ISchemaImportExport,
	TSchemaBuilderAnyDefault
> {
	setType: (
		modelNode: ISchemaBuilderAnyModel,
		valueType: SCHEMA_BUILDER_ANY_VALUE_TYPE,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		defaultVaue?: any,
		notify?: boolean
	) => void;
}

/**
 * Schema Builder: Boolean
 *
 * @param opts Schema options
 */
export function SchemaBuilderAny(opts: ISchemaBuilderAnyOpts): ISchemaBuilderAny {

	let valueSchemaListInstance: { [K: string]: TGenericBlueprintSchema } = null;

	const schema = createEmptySchema<ISchemaBuilderAny>("builderAny", opts);

	/**
	 * Returns schema list - must be resolved as a function to prevent infinite instatiation loop
	 * Caches instances
	 */
	const getSchemaList = () => {

		if (valueSchemaListInstance) {
			return valueSchemaListInstance;
		} else {
			return valueSchemaListInstance = {
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.ARRAY]: SchemaBuilderArray(opts),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.BOOLEAN]: SchemaBuilderBoolean(opts),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.FLOAT]: SchemaBuilderFloat(opts),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.INTEGER]: SchemaBuilderInteger(opts),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.MAP]: SchemaBuilderMap(opts),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.PASSWORD]: SchemaBuilderPassword(opts),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.STRING]: SchemaBuilderString(opts),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.DATA]: SchemaBuilderData(opts),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.DATE]: SchemaBuilderDate(opts),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.ENUM_STRING]: SchemaBuilderEnum({
					...opts, valueType: SCHEMA_BUILDER_ENUM_VALUE_TYPE.STRING
				}),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.ENUM_INTEGER]: SchemaBuilderEnum({
					...opts, valueType: SCHEMA_BUILDER_ENUM_VALUE_TYPE.INTEGER
				}),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.ENUM_FLOAT]: SchemaBuilderEnum({
					...opts, valueType: SCHEMA_BUILDER_ENUM_VALUE_TYPE.FLOAT
				}),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.OBJECT]: SchemaBuilderObject(opts),
				[SCHEMA_BUILDER_ANY_VALUE_TYPE.ANY]: SchemaBuilderConstAny(opts),
			}
		}

	};

	function getValueSchema(type: SCHEMA_BUILDER_ANY_VALUE_TYPE): TGenericBlueprintSchema {

		const schemaList = getSchemaList();
		const valueSchema = schemaList[type];

		if (!valueSchema) {
			throw new SchemaDeclarationError(schema.name, schema.opts, `Unsupported value type schema '${type}'.`);
		}

		return valueSchema;

	}

	const keyPropRules = {
		type: {
			required: true,
			idtType: BP_IDT_TYPE.SCALAR,
			idtScalarSubType: BP_IDT_SCALAR_SUBTYPE.STRING,
			provideCompletion: (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {

				dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
					const items: ICompletionItem[] = Object.keys(SCHEMA_BUILDER_ANY_VALUE_TYPE).map((key) => ({
						kind: CMPL_ITEM_KIND.EnumMember,
						label: SCHEMA_BUILDER_ANY_VALUE_TYPE[key],
						insertText: SCHEMA_BUILDER_ANY_VALUE_TYPE[key]
					}));

					return items;
				});

			}
		},
		opts: {
			required: true
		}
	};

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, ["value"])
	}

	const createModel = (
		dCtx: DesignContext,
		type: SCHEMA_BUILDER_ANY_VALUE_TYPE,
		valueModel: TGenericModelNode,
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx, parent, [], {
			type: type,
			value: valueModel
		});

		const model = assignParentToChildrenOf(modelNode)

		model.initRequiredValid = valueModel.initRequiredValid;

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		const valueSchemaName = defaultValue?.type || opts.defaultType;
		const valueSchema = getValueSchema(valueSchemaName);
		const valueModel = valueSchema.createDefault(dCtx, null,defaultValue?.opts);

		return createModel(dCtx, valueSchemaName, valueModel, parent);

	};

	schema.clone = (dCtx, modelNode, parent) => {

		const clonedValueModel = modelNode.value.schema.clone(dCtx, modelNode.value, null);

		const clone = cloneModelNode(dCtx, modelNode, parent,{
			type: modelNode.type,
			value: clonedValueModel
		})

		return assignParentToChildrenOf(clone)
	};

	schema.destroy = (modelNode) => {

		modelNode.value.schema.destroy(modelNode.value);
		modelNode.value = undefined;
		destroyModelNode(modelNode);

	};

	schema.parse = (dCtx, idtNode, parent) => {

		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return schema.createDefault(dCtx, parent);
		}

		// Extract keys
		const { keys, keysValid: keysValid } = extractAndValidateIDTMapProperties(dCtx, rootNode, keyPropRules);

		if (!keysValid.type) {
			return schema.createDefault(dCtx, parent);
		}

		if (!Object.values(SCHEMA_BUILDER_ANY_VALUE_TYPE).includes(
			(keys["type"].value as IBlueprintIDTScalar).value as SCHEMA_BUILDER_ANY_VALUE_TYPE
		)) {
			if (keys["type"].parseInfo) {
				dCtx.logParseError(keys["type"].parseInfo.loc.uri, {
					range: keys["type"].parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.ENUM_OUT_OF_RANGE,
					message: `Expecting value to be one of [ ${Object.values(SCHEMA_BUILDER_ANY_VALUE_TYPE).join(", ")} ]`,
					parsePath: keys["type"].path
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		const valueSchemaName = (keys["type"].value as IBlueprintIDTScalar).value as SCHEMA_BUILDER_ANY_VALUE_TYPE;
		const valueSchema = getValueSchema(valueSchemaName);

		// Provide completions
		provideIDTMapPropertyCompletions(dCtx, rootNode, keys, {
			opts: valueSchema.provideCompletion
		});

		const valueModel = keys["opts"] && keys["opts"].value
			? valueSchema.parse(dCtx, keys["opts"].value, null)
			: valueSchema.createDefault(dCtx, null);

		return createModel(dCtx, valueSchemaName, valueModel, parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {

		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);

	}

	schema.serialize = (modelNode, path) => {

		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// type
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[type]"]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["{type}"]),
						value: "type"
					} as IBlueprintIDTScalar,
					value: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["type"]),
						value: modelNode.type
					} as IBlueprintIDTScalar
				} as IBlueprintIDTMapElement,
				// opts
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat(["[opts]"]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat(["{opts}"]),
						value: "opts"
					} as IBlueprintIDTScalar,
					value: modelNode.value.schema.serialize(modelNode.value, path.concat(["opts"]))
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		return modelNode.value.schema.render(rCtx, modelNode.value, path, scope, prevSpec);

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		return modelNode.value.schema.compileRender(cCtx, modelNode.value, path);

	};

	/** Schema value cannot be validated because spec is ExportImport type which should not be constructed in any manual way */
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	schema.compileValidate = (cCtx, path, modelNodeId) => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	schema.export = () => {
		return exportSchema("SchemaBuilderAny", [opts]);
	};

	/** Is any because export data are internals and should not be exposed. */
	schema.getTypeDescriptor = (modelNode) => {

		if(modelNode){
			return modelNode.value.schema.getTypeDescriptor(modelNode.value);
		}else{
			const valueSchema = getValueSchema(opts.defaultType);
			return valueSchema.getTypeDescriptor();
		}


	}

	schema.setType = (modelNode, valueType, defaultValue, notify) => {

		if (valueType === modelNode.type) {
			return;
		}

		const valueSchema = getValueSchema(valueType);

		if (modelNode.value) {
			modelNode.value.schema.destroy(modelNode.value);
		}

		modelNode.type = valueType;
		modelNode.value = valueSchema.createDefault(modelNode.ctx, defaultValue);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	};

	schema.getChildNodes = (modelNode) => {
		return [{
			key: "value",
			node: modelNode.value
		}]
	}

	return schema;

}
