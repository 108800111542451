/**
 * hae-lib-components
 *
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { useEffect, useState } from "react";

import {
	TGenericComponentDefinition,
	TGenericComponentInstance,
	TGetComponentInstanceFromDefinition
} from "@hexio_io/hae-lib-blueprint";
import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";

/**
 * React component props interface for a HAE Component implementation
 */
export interface IHAEComponentReactWrapperProps<TComponentDefinition extends TGenericComponentDefinition> {
	componentInstance: TGetComponentInstanceFromDefinition<TComponentDefinition>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	additionalProps?: any;
}

/**
 * HAE Component state data passed from a hook to a React component
 */
export interface HAEComponentStateData<TComponentInstance extends TGenericComponentInstance> {
	props: TComponentInstance["props"];
	state: TComponentInstance["state"];
	setState: TComponentInstance["setState"];
	changeRev?: number;
}

/**
 * Helper function to extract state data from a blueprint component instance
 *
 * @param componentInstance Component instance
 */
function getStateFromComponentInstance<TComponentDefinition extends TGenericComponentDefinition>(
	componentInstance: TGetComponentInstanceFromDefinition<TComponentDefinition>
) {

	return {
		props: componentInstance.props,
		state: componentInstance.state,
		setState: componentInstance.setState
	};

}

/**
 * Hook to get state data from a blueprint component instance and to bind to change event
 *
 * @param reactProps React props
 */
export function useHAEComponent<TComponentDefinition extends TGenericComponentDefinition>(
	reactProps: IHAEComponentReactWrapperProps<TComponentDefinition>
): HAEComponentStateData<
	TGetComponentInstanceFromDefinition<TComponentDefinition>
> {

	const [ data, setData ] = useState(() => ({
		...getStateFromComponentInstance(reactProps.componentInstance),
		changeRev: 0
	}));

	useEffect(() => {

		const handleChange = () => {
			setData((prevData) => ({
				...getStateFromComponentInstance(reactProps.componentInstance),
				changeRev: prevData.changeRev + 1
			}));
		};

		onEvent(reactProps.componentInstance.onChange, handleChange);

		return () => {
			offEvent(reactProps.componentInstance.onChange, handleChange);
		};

	}, [ reactProps.componentInstance ]);

	return data;

}
