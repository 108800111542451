/**
 * To Number function
 *
 * @package hae-lib-shared
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { isNumber, isString } from "../Functions/isValidValue";

/**
 * Converts any value to number using fastest method
 * 
 * @param value Value
 * @param strict Strict (parsing strings containing other characters than number will always return NaN), defaults to "false"
 */
export function toNumber(value: unknown, strict = false): number {
	if (!strict) {
		return isString(value) ? parseFloat(value) : (isNumber(value) ? value : NaN);
	}

	return Number(value);
}
