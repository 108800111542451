/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, declareFunction, Type } from "@hexio_io/hae-lib-blueprint";

export const jsonStringify = declareFunction({
	name: "JSON_STRINGIFY",
	category: "json",
	label: "JSON Stringify",
	description: "Serializes arbitary data to a JSON format.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Data({
			label: "Data",
		}),
		BP.Integer({
			label: "Spaces",
			constraints: {
				required: false
			}
		})
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {

		const data = args[0]();
		const spaces = args[1]() ?? 0;
		
		return JSON.stringify(data, null, spaces as number);

	}
});

export const jsonParse = declareFunction({
	name: "JSON_PARSE",
	category: "json",
	label: "JSON Parse",
	// eslint-disable-next-line max-len
	description: "Parses string as a JSON into data.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "String",
			constraints: {
				required: true
			},
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {

		const str = args[0]();
		return JSON.parse(str);

	}
});

export const jsonIsValid = declareFunction({
	name: "JSON_ISVALID",
	category: "json",
	label: "Is valid JSON",
	// eslint-disable-next-line max-len
	description: "Returns if a string contains a valid JSON data.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "String",
			constraints: {
				required: false
			},
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.Boolean({}),
	render: (_rCtx, args) => {

		const str = args[0]();

		if (typeof str !== "string") {
			return false;
		}

		try {
			JSON.parse(str);
			return true;
		} catch (err) {
			return false;
		}

	}
});
