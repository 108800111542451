/**
 * hae-lib-components
 *
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { CSSProperties, useRef, useEffect, useState } from "react";
import {
	COMPONENT_MODE,
	ISchemaComponentList,
	ISchemaComponentListSpec,
	ISchemaComponentModel,
	ISchemaConstObject,
	TGenericComponentInstance,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec,
	TSchemaConstObjectProps
} from "@hexio_io/hae-lib-blueprint";
import { isFunction, isNonEmptyArray, isValidObject, offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { HAEComponent } from "./HAEComponent";
import { ClassList } from "../Classes/ClassList";
import { IAllowedResizeDimensions, TResizeBeginHandler, TResizeEndHandler, TResizeUpdateHandler } from "../Editor/IResize";
import { DROP_ZONE_MODE, useComponentListDnD } from "../Editor/useComponentListDnD";
import { IElementDimensions, IElementOffset } from "../Editor/IEditCommon";
import { TCanDropFunction } from "../Editor/EditDnD";
import { useEditContext } from "../Editor/EditContext";
import { useDnDStateChangeHandler } from "../Hooks/useDnDStateChangeHandler";
import { IBaseProps } from "../ReactComponents/props";
import { CSS_MEDIA_classNames } from "../Enums/CSS_MEDIA_CLASS";
import { useComponentMainContext } from "./HAEComponentContext";

/**
 * Component type list element
 */
export interface IHAEComponentListElementComponent<
	TInheritedProps extends TSchemaConstObjectProps
> {
	type: "component",
	cmpInstance: TGenericComponentInstance<TInheritedProps>;
	srcIndex: number;
	dropIndex?: number;
}

/**
 * Placeholder type list element
 */
export interface IHAEComponentListElementPlaceholder<
	TInheritedProps extends TSchemaConstObjectProps,
	TPlaceholderMetaData = never
> {
	type: "placeholder",
	nodeId: number,
	inheritedProps: TGetBlueprintSchemaSpec<ISchemaConstObject<TInheritedProps>>,
	dimensions: IElementDimensions;
	offset: IElementOffset;
	srcIndexRef?: number;
	metaData?: TPlaceholderMetaData;
}

/**
 * Component list element
 */
export type THAEComponentListElement<
	TInheritedProps extends TSchemaConstObjectProps,
	TPlaceholderMetaData = never
> = IHAEComponentListElementComponent<TInheritedProps>|IHAEComponentListElementPlaceholder<TInheritedProps, TPlaceholderMetaData>;

/**
 * HAE Component list properties
 */
export interface IHAEComponentListProps<
	TInheritedProps extends TSchemaConstObjectProps = Record<string, never>,
	TPlaceholderMetaData = never,
> extends IBaseProps {
	/** Component instances */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	components: ISchemaComponentListSpec<TInheritedProps>;

	/** React tag to wrap component into (defaults to <div>) */
	tagName?: "div"|"span"|"ol"|"ul"|"td";

	/** Override tag property passed to child components */
	childTagName?: "div"|"span"|"li";

	/** ClassList to pass to children */
	childClassList?: ClassList
		| ((element?: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>, index?: number) => ClassList);

	/** ClassList to pass to child components */
	childComponentClassList?: ClassList
		| ((element: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>, index: number) => ClassList);

	/** Custom style properties to pass to child wrappers */
	childInlineStyle?: CSSProperties
		| ((
				element: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>,
				index: number
			) => CSSProperties);

	/** Custom style properties to pass to child components */
	childComponentInlineStyle?: CSSProperties
		| ((
				element: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>,
				index: number
			) => CSSProperties);

	/** Child additional properties */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	childComponentAdditionalProps?: any
		| ((
			element: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>,
			index: number
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		) => any);

	/** If element can be resized in edit mode */
	allowResize?: IAllowedResizeDimensions
		| ((componentInstance: TGenericComponentInstance<TInheritedProps>, index: number) => IAllowedResizeDimensions);

	/** Callback to handle component resize begin */
	onResizeBegin?: TResizeBeginHandler<TGenericComponentInstance<TInheritedProps>, unknown>;

	/** Callback to handle component resize update on mouse move (should update element style) */
	onResizeUpdate?: TResizeUpdateHandler<TGenericComponentInstance<TInheritedProps>, unknown>;

	/** Callback to handle component resize end (should write changes to model) */
	onResizeEnd?: TResizeEndHandler<TGenericComponentInstance<TInheritedProps>, unknown>;

	/** Function which can modify item model when a component is being dropped */
	modifyModelOnDrop?: (
		itemModel: ISchemaComponentModel, element: IHAEComponentListElementPlaceholder<TInheritedProps, TPlaceholderMetaData>
	) => void

	/* Function to determine if a new target is a valid drop zone */
	canDrop?: TCanDropFunction;

	/** How to calculate drop position with respect to sibling elements */
	dropZoneMode?: DROP_ZONE_MODE;

	/** Drop text */
	dropText?: string;

	/** Component list model node */
	modelNode?: TGetBlueprintSchemaModel<ISchemaComponentList<TInheritedProps>>;
}

/**
 * Renders a list of HAE Components
 *
 * @param reactProps React component props
 */
export function HAEComponentList<
	TInheritedProps extends TSchemaConstObjectProps = Record<string, never>,
	TPlaceholderMetaData = never
>(reactProps: IHAEComponentListProps<TInheritedProps, TPlaceholderMetaData>): React.ReactElement {
	const componentMainContext = useComponentMainContext();
	const inEditor = componentMainContext.rCtx.isInEditor();

	const editCtx = useEditContext();
	const elementRef = useRef<HTMLElement>();

	const {
		tagName = "div",
		componentPath,
		componentMode,
		dropText = "Drop components here."
	} = reactProps;

	const editComponentMode = componentMode === COMPONENT_MODE.EDIT;

	// Use DnD

	const {
		handleDragOver,
		handleItemDragOver,
		handleDrop,
		elements
	} = useComponentListDnD<TInheritedProps, TPlaceholderMetaData>({
		components: reactProps.components,
		listElementRef: elementRef,
		dropZoneMode: reactProps.dropZoneMode || DROP_ZONE_MODE.APPEND,
		modelNode: reactProps.modelNode,
		modifyModelOnDrop: reactProps.modifyModelOnDrop,
		canDrop: reactProps.canDrop
	});

	// Handle selection

	const [, setSelectionRev ] = useState(0); // Just dummy state to trigger re-render on selection change

	useEffect(() => {
		if (!editCtx) {
			return;
		}

		const handleSelectionChange = () => setSelectionRev((x) => x + 1);

		onEvent(editCtx.selection.onChange, handleSelectionChange);

		return () => {
			offEvent(editCtx.selection.onChange, handleSelectionChange);
		};
	}, [ elements, editCtx ])

	// Create classList

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"hae-component-list", componentPath, { componentClassList: reactProps.classList, componentMode }
	);

	// Content items

	const content = elements.map((element, index) => {
		const key = element.type === "component" ? `cmp_${element.cmpInstance.uid}` : `ph_${element.nodeId}`;

		return (
			<HAEComponentListItem<TInheritedProps, TPlaceholderMetaData>
				key={key}
				element={element}
				index={index}
				tagName={reactProps.childTagName}
				classList={reactProps.childClassList}
				componentClassList={reactProps.childComponentClassList}
				inlineStyle={reactProps.childInlineStyle}
				componentInlineStyle={reactProps.childComponentInlineStyle}
				componentAdditionalProps={reactProps.childComponentAdditionalProps}
				inEditor={inEditor}
				allowResize={reactProps.allowResize}
				onResizeBegin={reactProps.onResizeBegin}
				onResizeUpdate={reactProps.onResizeUpdate}
				onResizeEnd={reactProps.onResizeEnd}
				handleItemDragOver={handleItemDragOver}
				handleDrop={handleDrop}
			/>
		);
	});

	const contentEmpty = !content.length;

	// Drop text

	const [ dropActive, setDropActive ] = useState(editComponentMode && contentEmpty);

	useDnDStateChangeHandler(editCtx, (active) => {
		if (!elementRef.current) {
			return;
		}

		setDropActive(editComponentMode && active);
	});

	if (editComponentMode && (dropActive || contentEmpty)) {
		const dropClassList = new ClassList("hae-component-list__drop");

		if (ClassList.isClassList(reactProps.childClassList)) {
			dropClassList.add(...reactProps.childClassList);
		}
		else if (isFunction(reactProps.childClassList)) {
			dropClassList.add(...reactProps.childClassList());
		}

		content.push(
			<div key="empty" className={dropClassList.toClassName()}>
				<div className="hae-component-list__drop-text">
					{dropText}
				</div>
			</div>
		);
	}

	return React.createElement(tagName, {
		ref: elementRef,
		className: classList.toClassName(),
		onDragOver: handleDragOver,
		onDrop: handleDrop
	}, content);
}



/**
 * HAE Component List Item props
 */
interface IHAEComponentListItemProps<
	TInheritedProps extends TSchemaConstObjectProps = Record<string, never>,
	TPlaceholderMetaData = never
> {
	/** Element */
	element: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>;

	/** Index */
	index: number;

	/** Tag name */
	tagName?: "div"|"span"|"li";

	/** ClassList */
	classList?: ClassList
		| ((element?: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>, index?: number) => ClassList);

	/** ClassList to component */
	componentClassList?: ClassList
		| ((element: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>, index: number) => ClassList);

	/** Custom style properties */
	inlineStyle?: CSSProperties
		| ((
				element: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>,
				index: number
			) => CSSProperties);

	/** Custom style properties to pass to component */
	componentInlineStyle?: CSSProperties
		| ((
				element: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>,
				index: number
			) => CSSProperties);

	/** Component additional properties */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	componentAdditionalProps?: any
		| ((
			element: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>,
			index: number
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		) => any);

	/** In editor */
	inEditor?: boolean;

	/** If element can be resized in edit mode */
	allowResize?: IAllowedResizeDimensions
		| ((componentInstance: TGenericComponentInstance<TInheritedProps>, index: number) => IAllowedResizeDimensions);

	/** Callback to handle component resize begin */
	onResizeBegin?: TResizeBeginHandler<TGenericComponentInstance<TInheritedProps>, unknown>;

	/** Callback to handle component resize update on mouse move (should update element style) */
	onResizeUpdate?: TResizeUpdateHandler<TGenericComponentInstance<TInheritedProps>, unknown>;

	/** Callback to handle component resize end (should write changes to model) */
	onResizeEnd?: TResizeEndHandler<TGenericComponentInstance<TInheritedProps>, unknown>;

	/** Item drag over handler */
	// eslint-disable-next-line max-len
	handleItemDragOver: (element: THAEComponentListElement<TInheritedProps, never>, ev: React.DragEvent<HTMLElement>, elRef: React.MutableRefObject<HTMLElement>) => void;

	/** Drop handler */
	handleDrop: (ev: React.DragEvent<HTMLElement>) => boolean;
}

/**
 * HAE Component List Item
 */
export function HAEComponentListItem<
	TInheritedProps extends TSchemaConstObjectProps = Record<string, never>,
	TPlaceholderMetaData = never
>(reactProps: IHAEComponentListItemProps<TInheritedProps, TPlaceholderMetaData>): React.ReactElement {
	const {
		element,
		index,
		tagName = "div",
		inEditor = false
	} = reactProps;

	const editContext = useEditContext();

	const elementRef = useRef<HTMLElement>();

	const [,setRev] = useState(0)

	useEffect(() => {
		if (element?.type === "component" && element.cmpInstance) {
			const handleChange = () => {
				setRev(v => v + 1);
			};

			onEvent(element.cmpInstance.onChange, handleChange);

			return () => {
				offEvent(element.cmpInstance.onChange, handleChange);
			};
		}
	}, [ element ]);

	const classList = new ClassList("hae-component-list__item");

	if (ClassList.isClassList(reactProps.classList)) {
		classList.add(...reactProps.classList);
	}
	else if (isFunction(reactProps.classList)) {
		classList.add(...reactProps.classList(element, index));
	}

	classList.addModifiers({
		application: !inEditor,
		editor: inEditor
	}, "element");

	switch (element.type) {
		case "component":
			if (
				editContext && editContext.selection.isComponentSelectedWithRefUpdate(element.cmpInstance as TGenericComponentInstance)
			) {
				classList.add("hae-component-list__item--selected");
			}
			break;

		case "placeholder":
			classList.add("hae-component-list__placeholder");
			break;
	}

	const componentClassList = ClassList.isClassList(reactProps.componentClassList) ?
		reactProps.componentClassList :
		(
			isFunction(reactProps.componentClassList) ?
				reactProps.componentClassList(element, index) :
				new ClassList()
		);

	const inlineStyle = isFunction(reactProps.inlineStyle) ?
		reactProps.inlineStyle(element, index) :
		reactProps.inlineStyle;

	const componentInlineStyle = isFunction(reactProps.componentInlineStyle) ?
		reactProps.componentInlineStyle(element, index) :
		reactProps.componentInlineStyle;

	const componentAdditionalProps = isFunction(reactProps.componentAdditionalProps) ?
		reactProps.componentAdditionalProps(element, index) :
		reactProps.componentAdditionalProps;

	let onDragOver: (ev: React.DragEvent<HTMLElement>) => void;

	let content = null;

	if (element.type === "component") {
		onDragOver = (ev) => reactProps.handleItemDragOver(element, ev, elementRef);

		const editComponentMode = element.cmpInstance.componentMode === COMPONENT_MODE.EDIT;

		const { mediaResolution } = element.cmpInstance.display;

		// Resolve media query classes

		const mediaResolutionKeys = isValidObject(mediaResolution) ? Object.keys(mediaResolution) : null;

		if (isNonEmptyArray(mediaResolutionKeys)) {
			const selectedMediaResolutions = mediaResolutionKeys.filter((item) => mediaResolution[item]);

			if (mediaResolutionKeys.length !== selectedMediaResolutions.length) {
				const mediaResolutionClassNames = selectedMediaResolutions.map((item) => CSS_MEDIA_classNames[item]);

				if (mediaResolutionClassNames.length) {
					classList.add(...mediaResolutionClassNames);
				}
				else {
					classList.add(CSS_MEDIA_classNames.none);
				}
			}
		}

		content = <HAEComponent
			componentInstance={element.cmpInstance as TGenericComponentInstance}
			classList={componentClassList}
			inlineStyle={componentInlineStyle}
			additionalProps={componentAdditionalProps}
			allowResize={
				editComponentMode
					? (typeof reactProps.allowResize === "function"
						? reactProps.allowResize(element.cmpInstance, index)
						: reactProps.allowResize)
					: null
			}
			onResizeBegin={reactProps.onResizeBegin as TResizeBeginHandler<TGenericComponentInstance, unknown>}
			onResizeUpdate={reactProps.onResizeUpdate as TResizeUpdateHandler<TGenericComponentInstance, unknown>}
			onResizeEnd={reactProps.onResizeEnd as TResizeEndHandler<TGenericComponentInstance, unknown>}
			allowDrag={editComponentMode}
		/>;
	}
	else if (element.type === "placeholder") {
		onDragOver = (ev) => reactProps.handleItemDragOver(element as THAEComponentListElement<TInheritedProps>, ev, elementRef);

		content = <div
			className={componentClassList.toClassName()}
			style={componentInlineStyle}
		/>;
	}

	if (content) {
		return React.createElement(
			tagName,
			{
				ref: elementRef,
				className: classList.toClassName(),
				style: inlineStyle,
				onDragOver,
				onDrop: reactProps.handleDrop
			},
			content
		);
	}

	return null;
}
