/**
 * Chart schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const chart = {
	data: {
		label: "schema:chart.data#label",
		description: "schema:chart.data#description"
	},
	name: {
		label: "schema:chart.name#label",
		description: "schema:chart.name#description"
	},
	values: {
		label: "schema:chart.values#label",
		description: "schema:chart.values#description"
	},
	xaxis: {
		label: "schema:chart.xaxis#label",
		description: "schema:chart.xaxis#description"
	},
	labels: {
		label: "schema:chart.labels#label",
		description: "schema:chart.labels#description"
	},
	show: {
		label: "schema:chart.show#label",
		description: "schema:chart.show#description"
	},
	tickAmount: {
		label: "schema:chart.tickAmount#label",
		description: "schema:chart.tickAmount#description"
	},
	rotate: {
		label: "schema:chart.rotate#label",
		description: "schema:chart.rotate#description"
	},
	offsetX: {
		label: "schema:chart.offsetX#label",
		description: "schema:chart.offsetX#description"
	},
	offsetY: {
		label: "schema:chart.offsetY#label",
		description: "schema:chart.offsetY#description"
	}
};
