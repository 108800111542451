/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

export function downloadData(blob: Blob, fileName: string): void {
	const url  = URL.createObjectURL(blob);

	const a = document.createElement('a');
	a.href = url;
	a.download = fileName;
	a.click();
}
