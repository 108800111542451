/**
 * File Field HAE component helpers
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	createSubScope,
	IScope,
	TGetBlueprintSchemaSpec,
	Type
} from "@hexio_io/hae-lib-blueprint";

import { RuntimeContext, TGenericBlueprintSchema, TGenericRuntimeContextResolvers } from "@hexio_io/hae-lib-blueprint";
import { getStringEnumKeyByValue, getStringEnumValue, TOAST_MESSAGE_TYPE } from "@hexio_io/hae-lib-components";
import { ACTION_DELEGATE_STATE, HTTP_METHOD, IActionDelegateResolver, IActionParams } from "@hexio_io/hae-lib-core";
import { isFunction } from "@hexio_io/hae-lib-shared";
import { FILE_FIELD_URL, FILE_FIELD_URL_default } from "../../Enums/FILE_FIELD_URL";
import { HAEComponentFileField_Props, TStaticUrlProps } from "./fileFieldProps";
import { HAEComponentFileField_State, IFileFieldFileContainer, IRequestOptions, TFileFieldMessageMap } from "./fileFieldState";

/**
 * Returns request settings
 */
export async function getRequestSettings(
	urlData: TGetBlueprintSchemaSpec<(typeof HAEComponentFileField_Props)["urlData"]["schema"]>,
	file: File,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rCtx: RuntimeContext<TGenericBlueprintSchema<any, any>, TGenericRuntimeContextResolvers>
): Promise<IRequestOptions> {
	const urlValue = getStringEnumValue(FILE_FIELD_URL, urlData.type, FILE_FIELD_URL_default);
	const urlDataValue = urlData.value[urlData.type];

	switch (urlValue) {
		case FILE_FIELD_URL.STATIC: {
			const staticUrlDataValue = urlDataValue as TStaticUrlProps;

			const method = getStringEnumKeyByValue(HTTP_METHOD, staticUrlDataValue.method);

			if (!method) {
				return null;
			}

			const result: IRequestOptions = {
				url: staticUrlDataValue.url,
				method,
				headers: staticUrlDataValue.headers,
				objectName: null
			};

			if (staticUrlDataValue.formDataKey) {
				result.formDataKey = staticUrlDataValue.formDataKey;
			}

			return result;
		}

		case FILE_FIELD_URL.ACTION: {
			if (!isFunction(urlDataValue)) {
				return null;
			}

			const { actionId, params } = (urlDataValue as (parentScope: unknown) => {
				actionId: string;
				params: IActionParams;
			})((parentScope: IScope) => {
				return createSubScope(parentScope, {
					file: {
						name: file.name,
						type: file.type,
						size: file.size
					}
				}, { file: Type.Any({}) });
			});

			const actionDelegate = rCtx.getResolver<IActionDelegateResolver>("actionDelegate").getDelegate(actionId, params);
			await actionDelegate.invoke(true);

			const actionDelegateData = actionDelegate.getData();
			const actionDelegateState = actionDelegate.getState();

			if (actionDelegateState === ACTION_DELEGATE_STATE.LOADED) {
				return actionDelegateData as IRequestOptions;
			}

			return {
				method: null,
				url: null,
				error: actionDelegateData,
				objectName: null
			};
		}
	}
}

/**
 * Update file in state
 */
export function updateFile(state: HAEComponentFileField_State, file: IFileFieldFileContainer): HAEComponentFileField_State {
	const { id } = file;

	const files = new Map(state.files);

	if (files.has(id)) {
		files.set(id, file);
	}

	return {
		...state,
		files
	};
}

/**
 * Adds message
 */
export function addMessage(messages: TFileFieldMessageMap, message: string, type = TOAST_MESSAGE_TYPE.WARNING): void {
	messages.set(message, { id: message, type, message });
}

