/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts, TBlueprintSchemaParentNode,
	TGenericBlueprintSchema
} from "../../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE, TGenericModelNode } from "../../Schema/IModelNode";
import { SchemaDeclarationError } from "../../Schema/SchemaDeclarationError";
import {
	assignParentToModelProps,
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange
} from "../../Schema/SchemaHelpers";
import { DesignContext } from "../../Context/DesignContext";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { SchemaConstArray } from "./SchemaConstArray";
import { SchemaConstBoolean } from "./SchemaConstBoolean";
import { SchemaConstDate } from "./SchemaConstDate";
import { SchemaConstFloat } from "./SchemaConstFloat";
import { SchemaConstMap } from "./SchemaConstMap";
import { SchemaConstString } from "./SchemaConstString";
import { BP_IDT_SCALAR_SUBTYPE, BP_IDT_TYPE } from "../../IDT/ISchemaIDT";
import { SchemaValueAny } from "../value/SchemaValueAny";
import { SchemaConstPassword } from "./SchemaConstPassword";

/**
 * Any value types
 */
export enum SCHEMA_CONST_ANY_VALUE_TYPE {
	BOOLEAN = "boolean",
	NUMBER = "number",
	STRING = "string",
	DATE = "date",
	ARRAY = "array",
	MAP = "map",
	PASSWORD = "password",
}

/**
 * Schema spec
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ISchemaConstAnySpec = any;

/**
 * Schema default
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TSchemaConstAnyDefault = any;

/**
 * Schema model
 */
export interface ISchemaConstAnyModel extends IModelNode<ISchemaConstAny> {
	valueType: SCHEMA_CONST_ANY_VALUE_TYPE;
	value: IModelNode<TGenericBlueprintSchema>;
}

/**
 * Schema options
 */
export interface ISchemaConstAnyOpts extends IBlueprintSchemaOpts {
	/** Default value type */
	defaultType: SCHEMA_CONST_ANY_VALUE_TYPE;
	/** Default value type */
	childDefaultType?: SCHEMA_CONST_ANY_VALUE_TYPE;
	/** If to allow only constants as values */
	constOnly?: boolean;
	/** If to allow string interpolation */
	allowInterpolation?: boolean;
	/** Validation constraints */
	constraints?: {
		/** Required value */
		required?: boolean;
	};
	/** A map of allowed types - if not provided, all types are allowed */
	allowedTypes?: Partial<Record<SCHEMA_CONST_ANY_VALUE_TYPE, boolean>>;
}

/**
 * Schema Any
 */
export interface ISchemaConstAny extends IBlueprintSchema<
	ISchemaConstAnyOpts,
	ISchemaConstAnyModel,
	ISchemaConstAnySpec,
	TSchemaConstAnyDefault
> {
	setType: (
		modelNode: ISchemaConstAnyModel,
		valueType: SCHEMA_CONST_ANY_VALUE_TYPE,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		defaultVaue?: any,
		notify?: boolean
	) => void;
}

/**
 * Schema: Any
 *
 * @param opts Schema options
 */
export function SchemaConstAny(opts: ISchemaConstAnyOpts): ISchemaConstAny {

	let valueSchemaListInstance_Const: { [K: string]: TGenericBlueprintSchema } = null;
	let valueSchemaListInstance_Value: { [K: string]: TGenericBlueprintSchema } = null;

	const schema = createEmptySchema<ISchemaConstAny>("constAny", opts);

	/**
	 * Returns schema list - must be resolved as a function to prevent infinite instatiation loop
	 * Caches instances
	 */
	const getSchemaList = () => {

		if (opts.constOnly === true) {

			if (valueSchemaListInstance_Const) {
				return valueSchemaListInstance_Const;
			} else {
				return valueSchemaListInstance_Const = {
					[SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN]: SchemaConstBoolean({
						// default: false,
						...opts
					}),
					[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: SchemaConstFloat({
						// default: 0,
						...opts
					}),
					[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: SchemaConstString({
						// default: "",
						...opts
					}),
					[SCHEMA_CONST_ANY_VALUE_TYPE.DATE]: SchemaConstDate(opts),
					[SCHEMA_CONST_ANY_VALUE_TYPE.PASSWORD]: SchemaConstPassword({
						// default: "",
						...opts
					}),
					[SCHEMA_CONST_ANY_VALUE_TYPE.ARRAY]: SchemaConstArray({
						...opts,
						items: SchemaConstAny({
							defaultType: opts.childDefaultType ?? opts.defaultType,
							constOnly: opts.constOnly,
							allowInterpolation: opts.allowInterpolation,
							allowedTypes: opts.allowedTypes
						})
					}),
					[SCHEMA_CONST_ANY_VALUE_TYPE.MAP]: SchemaConstMap({
						...opts,
						value: SchemaConstAny({
							defaultType: opts.childDefaultType ?? opts.defaultType,
							constOnly: opts.constOnly,
							allowInterpolation: opts.allowInterpolation,
							allowedTypes: opts.allowedTypes
						})
					})
				}
			}

		} else {

			if (valueSchemaListInstance_Value) {
				return valueSchemaListInstance_Value;
			} else {
				return valueSchemaListInstance_Value = {
					[SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN]: SchemaConstBoolean({
						// default: false,
						...opts
					}),
					[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: SchemaConstFloat({
						// default: 0,
						...opts
					}),
					[SCHEMA_CONST_ANY_VALUE_TYPE.PASSWORD]: SchemaConstPassword({
						// default: "",
						...opts
					}),
					[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: SchemaConstString({
						// default: "",
						...opts,
						allowInterpolation: opts.allowInterpolation ?? true
					}),
					[SCHEMA_CONST_ANY_VALUE_TYPE.DATE]: SchemaConstDate(opts),
					[SCHEMA_CONST_ANY_VALUE_TYPE.ARRAY]: SchemaConstArray({
						...opts,
						items: SchemaValueAny({
							defaultType: opts.childDefaultType ?? opts.defaultType,
							constOnly: opts.constOnly,
							allowInterpolation: opts.allowInterpolation,
							allowedTypes: opts.allowedTypes
						})
					}),
					[SCHEMA_CONST_ANY_VALUE_TYPE.MAP]: SchemaConstMap({
						...opts,
						value: SchemaValueAny({
							defaultType: opts.childDefaultType ?? opts.defaultType,
							constOnly: opts.constOnly,
							allowInterpolation: opts.allowInterpolation,
							allowedTypes: opts.allowedTypes
						})
					})
				}
			}

		}

	};

	function getValueSchema(type: SCHEMA_CONST_ANY_VALUE_TYPE): TGenericBlueprintSchema {

		const schemaList = getSchemaList();
		const valueSchema = schemaList[type];

		if (!valueSchema) {
			throw new SchemaDeclarationError(schema.name, schema.opts, `Unsupported value type schema '${type}'.`);
		}

		return valueSchema;

	}

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, "value")
	}

	const createModel = (
		dCtx: DesignContext,
		valueType: SCHEMA_CONST_ANY_VALUE_TYPE,
		value: TGenericModelNode,
		parent: TBlueprintSchemaParentNode
	) => {

		const modelNode = createModelNode(schema, dCtx, parent, [], {
			valueType,
			value,
		});

		const model = assignParentToChildrenOf(modelNode)

		model.initRequiredValid = value.initRequiredValid;

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		let schemaType: SCHEMA_CONST_ANY_VALUE_TYPE;

		if (typeof defaultValue === "boolean") {
			schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN;
		} else if (typeof defaultValue === "number") {
			schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER;
		} else if (typeof defaultValue === "string") {
			schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.STRING;
		} else if (defaultValue instanceof Date) {
			schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.DATE;
		} else if (defaultValue instanceof Array) {
			schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.ARRAY;
		} else if (defaultValue instanceof Object) {
			schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.MAP;
		} else if (defaultValue === undefined || defaultValue === null) {
			schemaType = opts.defaultType
		} else {
			throw new SchemaDeclarationError(schema.name, schema.opts, `Unsupported value type '${typeof defaultValue}'.`);
		}

		if (opts.allowedTypes && !opts.allowedTypes[schemaType]) {
			throw new SchemaDeclarationError(schema.name, schema.opts, `Value type '${schemaType}' is not allowed.`);
		}

		const valueSchema = getValueSchema(schemaType);
		const valueModel = valueSchema.createDefault(dCtx, null,defaultValue);

		return createModel(dCtx, schemaType, valueModel, parent);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		const valueSchema = getValueSchema(modelNode.valueType);
		const clonedValue = valueSchema.clone(dCtx, modelNode.value, null);

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			valueType: modelNode.valueType,
			value: clonedValue,
		});

		return assignParentToChildrenOf(clone)
	};

	schema.destroy = (modelNode): void => {

		const valueSchema = getValueSchema(modelNode.valueType);

		valueSchema.destroy(modelNode.value);

		modelNode.value = undefined;
		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent) => {

		// Check root node type
		if (!idtNode) {
			return schema.createDefault(dCtx, parent);
		}

		let schemaType: SCHEMA_CONST_ANY_VALUE_TYPE;

		switch (idtNode.type) {
			case BP_IDT_TYPE.SCALAR: {
				switch (idtNode.subType) {
					case BP_IDT_SCALAR_SUBTYPE.BOOLEAN: {
						schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN;
						break;
					}
					case BP_IDT_SCALAR_SUBTYPE.INTEGER: {
						schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER;
						break;
					}
					case BP_IDT_SCALAR_SUBTYPE.FLOAT: {
						schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER;
						break;
					}
					case BP_IDT_SCALAR_SUBTYPE.DATE: {
						schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.DATE;
						break;
					}
					case BP_IDT_SCALAR_SUBTYPE.STRING: {
						schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.STRING;
						break;
					}
					case BP_IDT_SCALAR_SUBTYPE.NULL: {
						schemaType = opts.defaultType;
						break;
					}
					default: {
						schemaType = opts.defaultType;

						if (idtNode.parseInfo) {
							dCtx.logParseError(idtNode.parseInfo.loc.uri, {
								range: idtNode.parseInfo.loc.range,
								severity: DOC_ERROR_SEVERITY.ERROR,
								name: DOC_ERROR_NAME.INVALID_NODE,
								message: `Unsupported scalar type.`,
								parsePath: idtNode.path,
							});
						}

						break;
					}
				}

				break;
			}

			case BP_IDT_TYPE.LIST: {
				schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.ARRAY;
				break;
			}

			case BP_IDT_TYPE.MAP: {
				schemaType = SCHEMA_CONST_ANY_VALUE_TYPE.MAP;
				break;
			}

			default: {
				if (idtNode.parseInfo) {
					dCtx.logParseError(idtNode.parseInfo.loc.uri, {
						range: idtNode.parseInfo.loc.range,
						severity: DOC_ERROR_SEVERITY.ERROR,
						name: DOC_ERROR_NAME.INVALID_NODE,
						message: `Unsupported node type.`,
						parsePath: idtNode.path,
					});
				}

				return schema.createDefault(dCtx, parent);
			}
		}

		if (opts.allowedTypes && !opts.allowedTypes[schemaType]) {
			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.INVALID_NODE,
					message: `Value type '${schemaType}' is not allowed.`,
					parsePath: idtNode.path,
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		const valueSchema = getValueSchema(schemaType);
		const valueModel = valueSchema.parse(dCtx, idtNode, null);

		return createModel(dCtx, schemaType, valueModel, parent);

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			return null;
		});

	};

	schema.serialize = (modelNode, path) => {

		const valueSchema = getValueSchema(modelNode.valueType);
		return valueSchema.serialize(modelNode.value, path);

	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {

		const valueSchema = getValueSchema(modelNode.valueType);
		return valueSchema.render(rCtx, modelNode.value, path, scope, prevSpec);

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		const valueSchema = getValueSchema(modelNode.valueType);
		return valueSchema.compileRender(cCtx, modelNode.value, path);

	};

	/** Any schema has no validators */
	schema.validate = () => {

		return true;

	};

	schema.compileValidate = () => {

		return "(v,pt)=>true";

	};

	schema.export = () => {

		return exportSchema("SchemaConstAny", [opts]);

	};

	schema.getTypeDescriptor = (modelNode) => {

		const valueSchema = getValueSchema(modelNode?.valueType ? modelNode.valueType : opts.defaultType);
		return valueSchema.getTypeDescriptor(modelNode?.value);

	}

	schema.setType = (modelNode, valueType, defaultValue, notify) => {

		if (valueType === modelNode.valueType) {
			return;
		}

		const valueSchema = getValueSchema(valueType);

		if (modelNode.value) {
			modelNode.value.schema.destroy(modelNode.value);
		}

		modelNode.valueType = valueType;
		modelNode.value = valueSchema.createDefault(modelNode.ctx, modelNode, defaultValue);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}

	};

	schema.getChildNodes = (modelNode) => {
		return [{
			key: "value",
			node: modelNode.value
		}]
	}

	if (opts.defaultType && opts.allowedTypes && !opts.allowedTypes[opts.defaultType]) {
		// eslint-disable-next-line max-len
		throw new SchemaDeclarationError(schema.name, schema.opts, `Default type '${opts.defaultType}' is not enabled in allowed types config.`);
	}

	return schema;

}
